export const STEP_INITIAL = 'FlightBoard'
export const STEP_SELECT_PROCEDURE = 'SelectProcedure'
export const STEP_SELECT_THAW_PROCEDURE = 'SelectThawProcedure'
export const STEP_SELECT_SPECIMEN_TYPE = 'SelectSpecimenType'
export const STEP_SELECT_SPECIMENS = 'SelectSpecimens'
export const STEP_SELECT_SPECIMENS_NEW_TICKET = 'SelectSpecimensForNewTicket'
export const STEP_SELECT_REASON_TO_EXPORT = 'SelectReasonToExport'
export const STEP_SELECT_PATIENT = 'SelectPatient'
export const STEP_CALENDAR = 'CalendarBoard'
export const STEP_HOW_MANY = 'HowMany'
export const STEP_DETAILS = 'Details'
export const STEP_SCAN_BEACON = 'ScanBeacon'
export const STEP_LABEL_SCAN = 'LabelScan'
export const STEP_ADD_EXISTING_BEACON = 'AddExistingBeacon'
export const STEP_CRYO_BEACON = 'CryoBeacon'
export const STEP_FINAL = 'FinalTicket'
export const STEP_BATCH_TICKET_TYPE = 'BatchTicketType'
export const STEP_NEW_BATCH_TICKET = 'NewBatchTicket'
export const STEP_SELECTED_TICKET = 'SelectedTicket'
