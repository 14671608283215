<template>
  <MainContainer gridSlots="3">
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">What is the reason for Export?</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          ref="btn-select-reason-next"
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!hasValidReasonForExport()"
          @click="handleSelectSpecimens"
        />
      </template>
    </ActionBar>
    <div class="main-container main-container--actions">
      <div class="main-container__body">
        <BreadCrumbs :items="breadcrumbsItems" />
        <select-reason
          :exportType="metadata.exportReason.type"
          :exportSubType="metadata.exportReason.details.subType"
          :handleReasonData="handleReasonData"
        />
      </div>
    </div>
  </MainContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  TRANSFER_OUT_VALUE,
  DISCARD_VALUE,
  DONATION_UPPERCASE,
  OTHER_VALUE,
  SHIPPING_VALUE,
  HAND_CARRY_VALUE,
  DONATE_TO_RESEARCH_VALUE,
  DONATE_TO_PATIENT_VALUE
  // METHOD_DONATE
} from '@/constants'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import SelectReason from '@/components/SelectReason/SelectReason.vue'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'

const metaDataPreset = {
  exportReason: {
    details: {
      metadata: {
        carrierId: '',
        destination: '',
        notes: '',
        shippingId: ''
      },
      subType: null
    },
    type: '',
    notes: ''
  }
}

export default {
  name: 'select-reason-to-export',
  components: {
    DynamicTitle,
    TopHeader,
    HeaderInfo,
    BreadCrumbs,
    ButtonDynamic,
    SelectReason,
    MainContainer,
    ActionBar
  },
  data() {
    return {
      metadata: {
        ...metaDataPreset
      }
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod']),
    ...mapGetters('patientsModule', ['donatePatient']),
    breadcrumbsItems() {
      return [
        {
          label: 'Specimens',
          active: false
        },
        {
          label: 'Reason',
          active: true
        },
        {
          label: 'Confirm',
          active: false
        }
      ]
    }
  },
  methods: {
    async handleSelectSpecimens() {
      const { details } = this.metadata.exportReason

      // TODO: Remove this type
      // ss.setFieldSessionStorage('type', null, 'newTicket')

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        metadata: this.metadata,
        isDonateToPatient: details.subType === DONATE_TO_PATIENT_VALUE
      })

      this.$router.push({ name: nextStepPath })
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })

      this.$router.replace({ name: previousStepPath })
    },
    handleReasonData(data) {
      const {
        type, subType, destination, shippingId, carrierId, notes, other
      } = data
      const [valueKey] = Object.keys(data)
      switch (valueKey) {
        case 'type':
          this.resetMetaData()
          this.setMetaDataType(type)
          break
        case 'subType': {
          this.resetMetaDataDetails()
          this.setMetaDataSubType(subType)
          break
        }
        case 'destination':
          this.setMetaDataDetails({ detailKey: 'destination', value: destination })
          break
        case 'shippingId':
          this.setMetaDataDetails({ detailKey: 'shippingId', value: shippingId })
          break
        case 'carrierId':
          this.setMetaDataDetails({ detailKey: 'carrierId', value: carrierId })
          break
        case 'notes':
          this.setMetaDataDetails({ detailKey: 'notes', value: notes })
          break
        case 'other':
          this.setMetaDataNotes(other)
          break
        default:
          break
      }
    },
    hasValidReasonForExport() {
      const { type, notes } = this.metadata.exportReason
      switch (type) {
        case TRANSFER_OUT_VALUE:
          return this.hasValidTransferOutValue()
        case DISCARD_VALUE:
          return true
        case DONATION_UPPERCASE: {
          return this.hasValidDonationValue()
        }
        case OTHER_VALUE: {
          return notes !== ''
        }
        default:
          return false
      }
    },
    hasValidTransferOutValue() {
      const { details } = this.metadata.exportReason
      const isShipping = details.subType === SHIPPING_VALUE

      if (isShipping) {
        const hasShippingInfo = details.metadata.destination !== ''
          && details.metadata.shippingId !== ''
          && details.metadata.carrierId !== ''

        return hasShippingInfo
      }

      return details.subType === HAND_CARRY_VALUE
    },
    hasValidDonationValue() {
      const { details } = this.metadata.exportReason
      const isDonateToPatient = details.subType === DONATE_TO_PATIENT_VALUE

      if (isDonateToPatient) {
        return (
          details.subType === DONATE_TO_PATIENT_VALUE && this.donatePatient.identificationNumber
        )
      }

      return details.subType === DONATE_TO_RESEARCH_VALUE
    },
    resetMetaData() {
      this.metadata = {
        ...metaDataPreset
      }
    },
    resetMetaDataDetails() {
      const resetedMetaDataDetails = {
        exportReason: {
          ...this.metadata.exportReason,
          details: {
            metadata: {
              carrierId: '',
              destination: '',
              notes: '',
              shippingId: ''
            },
            subType: null
          }
        }
      }

      this.metadata = resetedMetaDataDetails
    },
    setMetaDataType(type) {
      const updatedMetaData = {
        exportReason: {
          ...this.metadata.exportReason,
          type
        }
      }

      this.metadata = updatedMetaData
    },
    setMetaDataSubType(subType) {
      const updatedMetaData = {
        exportReason: {
          ...this.metadata.exportReason,
          details: {
            ...this.metadata.exportReason.details,
            subType
          }
        }
      }

      this.metadata = updatedMetaData
    },
    setMetaDataDetails({ detailKey, value }) {
      const updatedMetaData = {
        exportReason: {
          ...this.metadata.exportReason,
          details: {
            ...this.metadata.exportReason.details,
            metadata: {
              ...this.metadata.exportReason.details.metadata,
              [detailKey]: value
            }
          }
        }
      }

      this.metadata = updatedMetaData
    },
    setMetaDataNotes(notes) {
      const updatedMetaData = {
        exportReason: {
          ...this.metadata.exportReason,
          notes
        }
      }

      this.metadata = updatedMetaData
    }
  }
}
</script>
