import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { embryoTypeValues, SPECIMEN_TYPE_OOCYTE, SPECIMEN_TYPE_EMBRYO } from '@/constants'

export const replaceStringFilter = (value = '') => {
  if (!value) {
    return ''
  }
  const stringValue = value.toString()
  let updatedValue = stringValue
  embryoTypeValues.forEach((replacements) => {
    const { previousValue, newValue } = replacements
    const previousValueUpperCase = previousValue.toUpperCase()
    const previousValueLowerCase = previousValue.toLowerCase()
    const regEx = new RegExp(`\\b${previousValue}\\b`)
    if (regEx.test(stringValue)) {
      updatedValue = stringValue.replace(regEx, newValue)
    }
    const regExUpper = new RegExp(`\\b${previousValueUpperCase}\\b`)
    if (regExUpper.test(stringValue)) {
      updatedValue = stringValue.replace(regExUpper, newValue.toUpperCase())
    }
    const regExLower = new RegExp(`\\b${previousValueLowerCase}\\b`)
    if (regExLower.test(stringValue)) {
      updatedValue = stringValue.replace(regExLower, newValue.toLowerCase())
    }
  })
  return updatedValue
}

dayjs.extend(utc)
type Filter = {
  (value: string, item?: any): string | number
}

const timestampToUTCFilter: Filter[] = [
  (value) => Number(value as string),
  (value) => dayjs.utc(value).format('DDMMMYYYY'),
  (value) => value.toUpperCase()
]

const timestampToLocalDateFilter: Filter[] = [
  (value) => Number(value as string),
  (value) => dayjs(value).format('DDMMMYYYY'),
  (value) => value.toUpperCase()
]

export const timeStampInventoryCryoDateFilter = (date: string): string => {
  const parsedDate = date.includes('-') ? date : Number(date)
  return dayjs.utc(parsedDate).format('DDMMMYYYY').toUpperCase()
}

export const specimenCountFilter: Filter[] = [
    (value, item) => `${value} x ${
        item.embryo ?
          item.embryo.embryoType ?
            `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO
        : item.oocyte.maturityLevel
          ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE}`
]

export { timestampToUTCFilter, timestampToLocalDateFilter }
