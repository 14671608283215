<template>
  <ButtonDynamic
    v-if="!hidden"
    btnStyle="icon-button"
    btnType="button"
    :iconTitle="title"
    :float-to="float"
    @click="onClick"
    :showIcon="true"
    :fontAwesomeIconClass="icon"
    :hasNoXPadding="hasNoXPadding"
  />
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'scroll-table-icon-button-field',
  components: {
    ButtonDynamic
  },
  emits: ['onButtonClicked'],
  props: {
    value: {
      type: [String, Number, Object],
      required: false
    },
    options: {
      type: Object,
      required: true,
      validator: (value) => {
        const { icon } = value
        if (!icon) return false
        return true
      }
    },
    columnOptions: {
      type: Object,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    hasNoXPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('onButtonClicked', this.value, this.columnOptions)
    }
  },
  computed: {
    title() {
      return this.options.title
    },
    icon() {
      return this.options.icon
    },
    float() {
      return this.options.float || 'right'
    },
    hidden() {
      const { shouldHide } = this.options
      if (shouldHide) {
        return shouldHide(this.item)
      }
      return false
    }
  }
}
</script>
