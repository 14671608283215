<template>
  <div class="flex flex-row justify-center items-center">
    <div class="w-96 px-16 py-8 flex-row border-solid border-r-1 border-tmrw-green-light" data-test="select-procedure__store-procedures">
      <img width="28" :src="storeIcon" alt="Store" class="m-auto mb-4" data-testid="select-procedure__store-procedure-icon">
      <p class="px-2 mt-3 w-full text-center text-tmrw-green-light text-xl mr-16" data-test="select-procedure__procedure-type-title">Store</p>
      <ButtonDynamic
        :key="method"
        v-for="method in ticketMethods.store.methods"
        :data-test="getProcedureButtonDataTest(method)"
        class="mt-8"
        btnType="button"
        btnStyle="primary"
        btnSize="large"
        :isDisabled="!ticketMethods.store.allowed"
        :btnText="method"
        @click="onSelectProcedure(method)"
      />
    </div>
    <div class="w-96 px-16 py-8 flex-row" data-test="select-procedure__retrieve-procedures">
      <img width="28" :src="retrieveIcon" alt="Retrieve" class="m-auto mb-4" data-testid="select-procedure__retrieve-procedure-icon">
      <p class="px-2 mt-3 w-full text-center text-tmrw-green-light text-xl" data-test="select-procedure__procedure-type-title">Retrieve</p>
      <ButtonDynamic
        :key="method"
        v-for="method in ticketMethods.retrieve.methods"
        :data-test="getProcedureButtonDataTest(method)"
        class="mt-8"
        btnType="button"
        btnStyle="primary"
        btnSize="large"
        :isDisabled="!ticketMethods.retrieve.allowed"
        :btnText="method"
        @click="onSelectProcedure(method)"
      />
    </div>
  </div>
</template>
<script setup>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import store from '@/assets/process/store.svg'
import retrieve from '@/assets/process/retrieve.svg'
import { ref } from 'vue'

const storeIcon = ref(store)
const retrieveIcon = ref(retrieve)

const getProcedureButtonDataTest = (method) => {
  return `select-procedure__button-${method.toLowerCase()}`
}

defineProps({
  ticketMethods: {
    type: Object,
    required: true
  },
  onSelectProcedure: {
    type: Function,
    required: true
  }
})

</script>
