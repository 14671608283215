<template>
  <div class="flex flex-wrap -mx-4">
    <label
      class="block w-3/12"
      :class="[{ 'arrow-down': ($attrs as any).onIsSelected(item.value) && item.label === 'Other' }]"
      v-for="(item, index) in (items as any)"
      :key="index"
      :for="item.value"
    >
      <div
        class="font-exo font-semibold text-xl leading-6 p-4 mx-4 cursor-pointer rounded-lg my-2 reason"
        :class="{
          'bg-tmrw-blue-light text-white': ($attrs as any).onIsSelected(item.value),
          'bg-white text-tmrw-blue': !($attrs as any).onIsSelected(item.value),
        }"
        data-test="reason"
      >
        <input
          class="mr-1 w-4 h-4"
          type="radio"
          name="reason"
          :value="item.value"
          :id="item.value"
          @change="($attrs as any).onSyncData(item.value)"
        />
        {{ item.label }}
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import { toRefs } from 'vue'

export default {
  name: 'reasons',
  setup(props) {
    const { items } = toRefs(props)
    return items
  },
  props: {
    items: {
      type: [Array, String],
      required: true
    }
  }
}
</script>
