<template>
  <div :data-test="dataTestValue" class="w-full">
    <Tooltip
      :visible="visibility"
      placement="bottom"
      shadow
      :showArrow="false"
      background-color="#fff"
    >
      <div
        :class="[
          'bg-tmrw-white relative text-tnrw-blue py-1.5 leading-5 border-b border-solid border-tmrw-blue truncate w-full block h-8 pr-5',
          {
            'cursor-pointer': edit
          },
          alignToLeft,
          fieldClasses
        ]"
        :tabindex="tabKey"
        data-test="data-field-modal-content"
        ref="containerRef"
        @keyup.enter="toggleModal"
        @mouseover="showToolTip"
        @mouseleave="hideToolTip"
        @click="toggleModal"
      >
        {{ tooltipMessage }}
        <div
          class="absolute top-0 right-0 h-full flex items-center justify-center"
          data-test="data-field-modal-icon"
          v-if="!isDisabled && edit"
        >
          <i class="text-tmrw-blue far fa-edit" />
        </div>
      </div>
      <template #content>
        <section class="tooltip-content">{{ value }}</section>
      </template>
    </Tooltip>
    <ModalWindow
      :modalTitle="`Updating ${name === 'Result' ? 'Biopsy Result' : name}`"
      modalSize="md"
      :isModalOpen="isModalOpen"
      @closeModalWindow="closeModalWindow"
    >
      <textarea
        :data-test="`data-field-modal-textarea-${dataTestValue}`"
        class="w-full outline-none text-tmrw-blue font-exo p-2 bg-tmrw-blue-light"
        ref="textareaRef"
        :class="[
          {
            'mb-4': !maxlength
          }
        ]"
        :type="fieldType"
        v-model="inputValue"
        :maxlength="maxlength || 1000000"
        :placeholder="placeholder"
        rows="8"
      />
      <p
        data-test="data-field-modal-counter"
        class="text-right font-exo text-sm text-tmrw-blue mb-4 py-4 border-t border-solid border-tmrw-blue"
        v-if="maxlength"
      >
        {{ lengthOfCharacters }}
      </p>
      <ButtonDynamicVue
        btnText="Update"
        btnType="button"
        btnStyle="primary"
        showIcon
        fontAwesomeIconClass="edit-regular"
        @click="updateValue"
      />
      <ButtonDynamicVue
        btnText="Cancel"
        btnType="button"
        btnStyle="secondary"
        @click="closeModalWindow"
        addMarginRight
      />
    </ModalWindow>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, useAttrs } from 'vue'

import { SPECIMEN_TYPE_EGGS, SPECIMEN_TYPE_EGG } from '@/constants'
import { replaceStringFilter } from '@/filters'
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import Tooltip from '../TooltipComponent/Tooltip.vue'
import ButtonDynamicVue from '../ButtonDynamic/ButtonDynamic.vue'

const props = defineProps({
  value: {
    type: [String, Number],
    required: false,
    default: ''
  },
  tabKey: {
    type: Number,
    required: false,
    default: 0
  },
  edit: {
    type: Boolean,
    required: false,
    default: true
  },
  size: {
    type: String,
    required: false,
    default: 'medium'
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  maxlength: {
    type: Number,
    required: false
  },
  maxValue: {
    type: Number,
    required: false
  },
  fieldType: {
    type: String,
    default: 'text',
    required: false
  },
  alignContentToLeft: {
    type: Boolean,
    required: false,
    default: false
  },
  fieldClasses: {
    type: String,
    required: false,
    default: ''
  },
  block: {
    type: Boolean,
    required: false,
    default: false
  },
  SetTooltip: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: false,
    default: 'Field'
  },
  dataTest: {
    type: String,
    required: false,
    default: 'data-field-modal'
  },
  item: {
    type: Object,
    required: false,
    default: () => {}
  }
})
const emit = defineEmits(['changeValue'])
const inputValue = ref<string | number>(props.value)
const visibility = ref(false)
const textareaRef = ref<HTMLElement | null>(null)
const containerRef = ref<HTMLElement | null>(null)
const attrs: any = useAttrs()
const MAX_LENGTH = 10

const tooltipMessage = computed(() => {
  let message = replaceStringFilter(
    props.value === SPECIMEN_TYPE_EGGS ? SPECIMEN_TYPE_EGG : `${props.value}`
  )

  return message
})

const extendedLength = computed(() => {
  const formatted = replaceStringFilter(
    props.value === SPECIMEN_TYPE_EGGS ? SPECIMEN_TYPE_EGG : `${props.value}`
  )
  return `${formatted}`.length > MAX_LENGTH
})

const lengthOfCharacters = computed(() => {
  if (props.maxlength) {
    return `${String(inputValue.value).length}  /  ${props.maxlength}`
  }

  return 0
})

const isDisabled = computed(() => attrs?.columnOptions?.disabled && attrs.columnOptions.disabled(props.item))

const isModalOpen = ref(false)
const showToolTip = () => {
  visibility.value = extendedLength.value
}
const hideToolTip = () => {
  visibility.value = false
}

const toggleModal = () => {
  if (isDisabled.value) return
  isModalOpen.value = props.edit
}
const closeModalWindow = () => {
  inputValue.value = props.value
  isModalOpen.value = false
}
const updateValue = () => {
  emit('changeValue', inputValue.value)
  isModalOpen.value = false
}

const alignToLeft = computed(() => {
  if (props.alignContentToLeft) {
    return 'data-field__align-left'
  }
  return null
})

const dataTestValue = computed(() => {
  if (props.dataTest) {
    return props.dataTest.replaceAll(' ', '-').toLowerCase()
  }
  return ''
})
watch(isModalOpen, (isOpen) => {
  if (!isOpen && containerRef.value) {
    containerRef.value.focus()
  }
})
watch(textareaRef, () => {
  if (textareaRef.value) {
    textareaRef.value.focus()
  }
})
watch(isDisabled, (newDisabled) => {
  if(newDisabled) {
    inputValue.value = ''
    emit('changeValue', '')
  }

})
</script>
<style lang="scss" scoped>
span:focus {
  outline: 0
}
textarea:focus, span:focus {
  @apply bg-tmrw-gray-light;
}
.tooltip-content {
  @apply text-base text-tmrw-blue;
}
</style>
