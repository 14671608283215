<template>
  <div class="flex w-2/5 scroll-table-image-field-element">
    <span v-for="(fileName, index) in statusImageSrc" :key="index" class="mr-2">
      <img
        :data-test="`scroll-table-status-field-img-${getSourceFileName(fileName)}`"
        :src="fileName"
        :altText="label"
        width="24"
        height="24"
        class="min-w-6"
        :class="{ 'filter-white': item.selected }"
      />
    </span>
    {{ getStatusImageLabel(options) }}
  </div>
</template>

<script>

export default {
  name: 'scroll-table-image-field',
  props: {
    value: {
      type: [Object, String],
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  methods: {
    getSourceFileName(source) {
      const fileNameRegex = new RegExp('(?:overdue|lock-emr|in-progress|green|import-cryo-beacon|empty)')
      return source.match(fileNameRegex) ? source.match(fileNameRegex)[0] : ''
    },
    getStatusImageLabel(options) {
      return options.showLabel ? this.label : ''
    }
  },
  computed: {
    label() {
      return typeof this.value === 'string' ? this.value : this.value.label || ''
    },
    statusImageSrc() {
      const { value: { sources } } = this
      return sources
    }
  }
}
</script>
