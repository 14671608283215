import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { timestampToUTCFilter } from '@/filters/index'
import addLabelToHeader from '@/helpers/addLabelToHeader'
import {
  SPECIMEN_TYPE_EMBRYO_LOWERCASE,
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  MAX_LABEL_LENGTH
} from '@/constants'

dayjs.extend(utc)

const specimenId = [
  {
    name: '',
    key: 'specimenId',
    unique: true,
    visible: false
  }
]

const showCurrentLabelID = {
  name: 'Current Label ID',
  key: 'currentLabelId',
  cssClasses: ['w-32']
}

export const embryo = [
  ...specimenId,
  {
    name: 'Cryodevice Id',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-32', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      theme: 'light',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'center',
    filters: [
      (value, item) =>
        `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ],
    key: 'specimenCount',
    sort: true
  },
  {
    name: 'Embryo #',
    key: 'embryo',
    filters: [(value) => (value && value ? value.embryoNumber : '')]
  },
  {
    name: 'Grade',
    key: 'embryo',
    filters: [(value) => (value && value ? value.grade : '')],
    sort: true
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    sort: true
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    component: 'screeningStatus',
    sort: true,
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: 'Biopsy',
    key: 'embryo',
    filters: [(value) => (value && value ? value.biopsy : '')],
    sort: true
  },
  {
    name: 'Result',
    key: 'embryo.biopsyResult',
    sort: true,
    component: 'tooltip',
    componentOptions: {
      maxlength: 10,
      alignment: 'left'
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      displayDotsIcon: false,
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: '',
    key: 'isImport'
  }
]

export const egg = [
  ...specimenId,
  {
    name: 'Cryodevice Id',
    key: 'cryodeviceBarcode',
    cssClasses: ['whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      theme: 'light',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'center',
    key: 'specimenCount',
    filters: [
      (value, item) =>
        `${value} x ${
          item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    ],
    sort: true
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    sort: true
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    component: 'screeningStatus',
    sort: true,
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      displayDotsIcon: false,
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: '',
    key: 'isImport'
  }
]

export const specimenImportHeaders = (specimenType) => {
  if (specimenType === SPECIMEN_TYPE_EMBRYO_LOWERCASE) {
    return addLabelToHeader(embryo, showCurrentLabelID)
  }
  return addLabelToHeader(egg, showCurrentLabelID)
}
