<template>
  <div
    v-if="shouldShowModal"
    class="w-screen h-screen bg-black fixed bg-opacity-50 top-0 left-0 flex items-center justify-center z-100">
    <div class="bg-white rounded-lg w-5/12 p-4">
      <h3 class="text-tmrw-blue-dark text-2xl font-bold mb-4">Readonly</h3>
      <div class="text-tmrw-blue-dark">
        Tickets in different state that In Progress are readonly
      </div>
      <div class="flex items-center justify-end mt-4">
        <button-dynamic
          class="inventory-update__btn-ok"
          btnText="Ok"
          btnType="button"
          btnStyle="primary"
          @click="$emit('ok-clicked')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'ReadOnlyTicketModal',
  components: {
    ButtonDynamic
  },
  emits: ['on-clicked'],
  props: {
    shouldShowModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
