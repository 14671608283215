<template>
  <div
    class="loading-ui items-center flex justify-center"
    data-test="loading-ui"
    :class="[
      { [`loading-ui--${size}`]: availableSizes.includes(size) },
      { 'bg-tmrw-blue bg-opacity-75 w-screen h-screen fixed z-100 left-0 top-0': modal }
    ]"
  >
    <div class="loading-ui__boxes flex flex-col items-center justify-center relative">
      <img
        :class="[
        { 'w-72 h-72': size === 'large' },
        { 'w-44 h-44': size === 'medium' },
        { 'w-24 h-24': size === 'small' }
        ]"
        src="@/assets/loader.svg"
      />
      <div
        data-test="loading-ui-message"
        class="loading-ui__message leading-tight text-center text-lg font-semibold text-tmrw-green-light w-full"
      >
        {{message}}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const availableSizes = ref(['large', 'medium','small'])
type Props = {
  size?: 'large' | 'medium' | 'small'
  message?: string
  modal?: boolean
}

withDefaults(defineProps<Props>(), {
  size: 'medium',
  message: '',
  modal: false
})

</script>
