/* eslint-disable no-unsafe-optional-chaining */
import { GridStack } from 'gridstack'
import {
  MODULAR_LABEL_FIELDS_TEXT,
  MODULAR_LABEL_BLANK_SPACE,
  MODULAR_LABEL_X_ELEMENT,
  MODULAR_LABEL_MATURITY,
  MODULAR_LABEL_EMBRYO_TYPE,
  MODULAR_LABEL_COUNT,
  LEFT_X,
  RIGHT_X
} from '@/constants/modular-cryolabel'
import {
  Coordinates,
  AddGridElement,
  ModularLabelFieldsText,
  LabelGridData
} from './types'

export const getPlaceholderElement = (
  columns: number = 32,
  wrapperClassName: string = 'cell-content'
) => {
  const spans = new Array(columns).fill('<span>X</span>')

  return `<div class="${wrapperClassName}">${spans.join('')}
  <div class="cell-content-edit"></div>
  </div>`
}

export const getSpaceElement = (x?: number) => {
  const el = {
    id: MODULAR_LABEL_BLANK_SPACE,
    h: 1,
    locked: true,
    maxH: 1,
    w: 1,
    x,
    y: 0,
    noResize: true,
    noMove: true,
    content: '<div class="space-holder"> </div>'
  }
  return el
}

export const getXElement = (x?: number) => {
  const el = {
    id: MODULAR_LABEL_X_ELEMENT,
    h: 1,
    locked: true,
    maxH: 1,
    w: 1,
    x,
    y: 0,
    noResize: true,
    noMove: true,
    content: '<div class="x-element">x</div>'
  }
  return el
}

export const getParsedData = (data: LabelGridData, key: string) => {
  const parsedData = data[key].map((value) => {
    const formattedValue = value.map((item) => {
      const { id, x, w } = item
      return {
        id,
        x,
        w,
        y: 0
      }
    })
    return formattedValue
  })

  return { [key]: parsedData }
}

interface GetApiPayload {
  data: LabelGridData
  key: string
  property: string
}

export const getApiPayload = ({ data, key, property }: GetApiPayload) => ({
  property,
  data: { type: 'JSON_STRING', value: JSON.stringify(getParsedData(data, key)) }
})

export const areClose = (type: string, specimenCount: string) => Number(type) - Number(specimenCount) <= 3 && Number(type) - Number(specimenCount) >= -3
export const getTypeElementPosition = (type: string, gridId: string) => document.querySelector(`#${gridId} [gs-id="${type}"]`)?.getAttribute('gs-x')
export const isMovingSpecimentCountMaturity = (x: string, gridId: string) => {
  const maturityLevelPosition = getTypeElementPosition(MODULAR_LABEL_MATURITY, gridId) as string | undefined
  const embryoTypePosition = getTypeElementPosition(MODULAR_LABEL_EMBRYO_TYPE, gridId) as string | undefined
  const specimenCountPosition = getTypeElementPosition(MODULAR_LABEL_COUNT, gridId) as string | undefined
  const xElement = document.querySelector(`#${gridId} .x-element`)

  if (xElement) {
    if (specimenCountPosition && maturityLevelPosition || specimenCountPosition && embryoTypePosition) {
      return maturityLevelPosition === x || embryoTypePosition === x || specimenCountPosition === x
    }

    return true
  }

  return false
}

export const validateCloseValuesAndSide = (valueMoving: string, valueFixed: string) => {
  if (areClose(valueMoving, valueFixed)) {
    return valueMoving > valueFixed ? LEFT_X : RIGHT_X
  }

  return null
}

export const canAddXElement = (gridId: string, x: number) => {
  const elementMoving = document.querySelector(`[gs-x='${x}']`)
  const elementMovingId = elementMoving?.getAttribute('gs-id')
  const elementMovingPosition = elementMoving?.getAttribute('gs-x')
  const specimenCountPosition = getTypeElementPosition(MODULAR_LABEL_COUNT, gridId) as string | undefined

  if (elementMovingId && specimenCountPosition && elementMovingPosition) {
    switch (elementMovingId) {
      case MODULAR_LABEL_MATURITY:
      case MODULAR_LABEL_EMBRYO_TYPE:
        return validateCloseValuesAndSide(elementMovingPosition, specimenCountPosition)

      case MODULAR_LABEL_COUNT: {
        const maturityLevelPosition = getTypeElementPosition(MODULAR_LABEL_MATURITY, gridId) as string | undefined
        const embryoTypePosition = getTypeElementPosition(MODULAR_LABEL_EMBRYO_TYPE, gridId) as string | undefined

        if (maturityLevelPosition) {
          return validateCloseValuesAndSide(elementMovingPosition, maturityLevelPosition)
        }

        if (embryoTypePosition) {
          return validateCloseValuesAndSide(elementMovingPosition, embryoTypePosition)
        }

        return null
      }

      default:
        return null
    }
  }

  return null
}

const replaceXelementBySpace = (grid: GridStack, x: number, w: number) => {
  const gridId = grid.el.id
  const elementRightParent = document.querySelector(`#${gridId} [gs-x="${x + w}"]`)
  const hasXelementHolderRightChild = elementRightParent && elementRightParent.querySelector('.x-element')
  if (hasXelementHolderRightChild) {
    const spaceRight = getSpaceElement(x + w)
    grid.removeWidget(`#${gridId} [gs-x="${x + w}"]`)
    grid.addWidget(spaceRight)
  } else {
    const spaceLeft = getSpaceElement(x - 1)
    grid.removeWidget(`#${gridId} [gs-x="${x - 1}"]`)
    grid.addWidget(spaceLeft)
  }
}

const handleSpacesOnStartChange = (grid: GridStack, { w, x, y }: Coordinates) => {
  const gridId = grid.el.id
  const blankSpaceRightParent = document.querySelector(`#${gridId} [gs-x="${x + w}"]`)
  const blankSpaceNextRightParent = document.querySelector(`#${gridId} [gs-x="${x + w + 1}"]`)
  const blankSpaceLeftParent = document.querySelector(`#${gridId} [gs-x="${x - 1}"]`)
  const blankSpaceNextLeftParent = document.querySelector(`#${gridId} [gs-x="${x - 2}"]`)

  const hasSpaceplaceholderRightChild = blankSpaceRightParent && blankSpaceRightParent.querySelector('.space-holder')
  const hasSpaceplaceholderNextRightChild = blankSpaceNextRightParent && blankSpaceNextRightParent.querySelector('.space-holder')
  const hasSpaceplaceholderLeftChild = blankSpaceLeftParent && blankSpaceLeftParent.querySelector('.space-holder')
  const hasSpaceplaceholderNextLeftChild = blankSpaceNextLeftParent && blankSpaceNextLeftParent.querySelector('.space-holder')

  if (isMovingSpecimentCountMaturity(x.toString(), gridId)) {
    replaceXelementBySpace(grid, x, w)
  }

  if (
    x + w < 32
    && hasSpaceplaceholderRightChild
    && (grid.isAreaEmpty(x + w + 1, y, 1, 1) || hasSpaceplaceholderNextRightChild)
  ) {
    grid.removeWidget(`#${gridId} [gs-x="${x + w}"]`)
  }

  if (
    x + w < 32
    && x > 0
    && hasSpaceplaceholderLeftChild
    && (hasSpaceplaceholderNextLeftChild || grid.isAreaEmpty(x - 2, y, 1, 1))
  ) {
    grid.removeWidget(`#${gridId} [gs-x="${x - 1}"]`)
  }

  // When the item is at the end of the row and it doesnt have a neighbor item at left side, it deletes the left blank space for the item dragged
  if (x + w >= 32 && hasSpaceplaceholderLeftChild && grid.isAreaEmpty(x - 2, y, 1, 1)) {
    grid.removeWidget(`#${gridId} [gs-x="${x - 1}"]`)
  }
}

const handleSpacesOnEndChange = (grid: GridStack, { w, x, y }: Coordinates) => {
  let characterRight = getSpaceElement(x + w)
  let characterLeft = getSpaceElement(x - 1)
  const gridId = grid.el.id
  const XElement = canAddXElement(gridId, x)

  if (XElement) {
    const blankSpaceNextRightParent = document.querySelector(`#${gridId} [gs-x="${x + w}"]`)
    const hasSpaceplaceholderNextRightChild = blankSpaceNextRightParent && blankSpaceNextRightParent.querySelector('.space-holder')
    const blankSpaceLeftParent = document.querySelector(`#${gridId} [gs-x="${x - 1}"]`)
    const hasSpaceplaceholderLeftChild = blankSpaceLeftParent && blankSpaceLeftParent.querySelector('.space-holder')

    if (blankSpaceNextRightParent && hasSpaceplaceholderNextRightChild && XElement === RIGHT_X) {
      const characterX = getXElement(x + w)
      grid.removeWidget(`#${gridId} [gs-x="${x + 2}"]`)
      characterRight = characterX
    }

    if (blankSpaceLeftParent && hasSpaceplaceholderLeftChild && XElement === LEFT_X) {
      const characterX = getXElement(x - 1)
      grid.removeWidget(`#${gridId} [gs-x="${x - 1}"]`)
      characterLeft = characterX
    }
  }

  if (grid.isAreaEmpty(x + w, y, 1, 1) && x + w < 32) {
    grid.addWidget(characterRight)
  }

  if (grid.isAreaEmpty(x - 1, y, 1, 1) && x + w >= 32 && w < 32) {
    grid.addWidget(characterLeft)
  }

  if (x + w < 32 && x > 0 && grid.isAreaEmpty(x - 1, y, 1, 1)) {
    grid.addWidget(characterLeft)
  }
}

const removeCounterElement = () => {
  const widgetWidthCounter = document.querySelector('.modular-label-resize-counter')
  widgetWidthCounter && widgetWidthCounter.remove()
}

const updateCounterElement = (el: any, { w, maxW }: Coordinates) => {
  const counter = el?.querySelector('.modular-label-resize-counter')

  // check if the counter already has a counter
  if (!counter) {
    const counterElement = document.createElement('div')
    counterElement.classList.add('modular-label-resize-counter')
    el.appendChild(counterElement)
  }

  const widgetWidthCounter = el?.querySelector('.modular-label-resize-counter')
  if (maxW && w >= maxW) {
    widgetWidthCounter.classList.add('disabled')
  }
  widgetWidthCounter.innerHTML = w
}

export const addGridEvents = (grid: GridStack, id: number | string, callBack: Function) => {
  grid.on('dragstart', (event: any) => {
    const { w, x, y } = event?.target?.gridstackNode
    handleSpacesOnStartChange(grid, { w, x, y })
  })

  grid.on('resizestart', (event: any, el: any) => {
    const {
      w, x, y, maxW
    } = event?.target?.gridstackNode
    handleSpacesOnStartChange(grid, { w, x, y })
    updateCounterElement(el, {
      x,
      y,
      w,
      maxW
    })
  })

  grid.on('dragstop', (event: any) => {
    const { w, x, y } = event?.target?.gridstackNode
    callBack()
    handleSpacesOnEndChange(grid, { w, x, y })
  })

  grid.on('resizestop', (event: any) => {
    const { w, x, y } = event?.target?.gridstackNode
    callBack()
    handleSpacesOnEndChange(grid, { w, x, y })
    removeCounterElement()
  })

  grid.on('resize', (event: any, el: any) => {
    const {
      x, y, w, maxW
    } = event?.target?.gridstackNode
    updateCounterElement(el, {
      x,
      y,
      w,
      maxW
    })
  })

  grid.on('removed', (event: any, items: any) => {
    const currentElement = items[0]
    if (currentElement) {
      const { w, x, y } = currentElement
      handleSpacesOnStartChange(grid, { w, x, y })
      callBack()
    }
  })

  grid.on('dropped', (event: Event, previousWidget: any, newWidget: any) => {
    const { w, x, y } = newWidget
    const titleElement = newWidget.el.querySelector('.modular-label__field-item__title')
    titleElement && titleElement.remove()
    newWidget.el.classList.remove('modular-label__field-item')
    removeCounterElement()
    handleSpacesOnEndChange(grid, { w, x, y })
    callBack()
  })
}

export const addGridElement = ({
  grid, item
}: AddGridElement) => {
  switch (item.id) {
    case MODULAR_LABEL_BLANK_SPACE:
      // if it is a space holder, just add the widget without the title
      grid.addWidget(getSpaceElement(item?.x))
      break

    case MODULAR_LABEL_X_ELEMENT:
      grid.addWidget(getXElement(item?.x))
      break

    default:
      grid.addWidget({
        ...item,
        content: `<div class="modular-label__tooltip" data-tooltip-text="${MODULAR_LABEL_FIELDS_TEXT[item.id as keyof ModularLabelFieldsText]}">
                      <div class="modular-label__title">${MODULAR_LABEL_FIELDS_TEXT[item.id as keyof ModularLabelFieldsText]}</div>
                    </div>
                    ${getPlaceholderElement()}`
      })
      break
  }
}
