import { typeEmbryo } from '@/constants'

export default function replaceEggToOocyte(valueToFilter) {
  const { egg, replace } = typeEmbryo
  const eggUpperCase = egg.toUpperCase()
  const eggLowerCase = egg.toLowerCase()

  if (typeof valueToFilter === 'string' && valueToFilter.includes(egg)) {
    return valueToFilter.replace(egg, replace)
  }
  if (typeof valueToFilter === 'string' && valueToFilter.includes(eggUpperCase)) {
    return valueToFilter.replace(eggUpperCase, replace.toUpperCase())
  }
  if (typeof valueToFilter === 'string' && valueToFilter.includes(eggLowerCase)) {
    return valueToFilter.replace(eggLowerCase, replace.toLowerCase())
  }
  return valueToFilter
}
