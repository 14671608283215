import toast from '@/config/toast'
import { updateTicketApi, deleteTicketApi, fetchTicketData } from '@/services/tickets'
import { CANCEL_TICKET_SUCCESS_MESSAGE } from '@/constants/index'

// TODO: The following two actions does pretty much the same
export const fetchTicketsInfo = async ({ commit, rootState }, ids) => {
  const {
    appBaseConfig: { collectionProtocolId }
  } = rootState.authModule
  commit('spinnerModule/displaySpinner', true, { root: true })
  try {
    const resultTickets = await fetchTicketData(collectionProtocolId, ids)
    commit('addNewTicketInfo', resultTickets)
  } catch (error) {
    throw new Error(error.message)
  } finally {
    commit('spinnerModule/displaySpinner', false, { root: true })
  }
}

export const fetchTicketsDetail = async ({ commit, rootState }, ids) => {
  try {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    const resultTickets = await fetchTicketData(collectionProtocolId, ids)
    commit('setTicketDetail', resultTickets)
  } catch (error) {
    toast.error({ title: error.message })
    throw new Error(error.message)
  }
}

export const updateTickets = async ({ commit, rootState }, { tickets, ticketId }) => {
  /**
   * Since 'source' is a mandatory property,
   * this block validates if 'source' is in every ticket
   * Since is a new requirement not all tickets has it
   * Once we are sure every ticket has the property, this can be removed
   */
  const ticketsArray = tickets.map((ticket) => ({
    ...ticket,
    source: ticket.source || 1
  }))

  const {
    appBaseConfig: { siteId, clinicId },
    token
  } = rootState.authModule
  commit('loading')

  try {
    await updateTicketApi({
      clinicId,
      siteId,
      ticketsArray,
      ticketId,
      token
    })
    commit('success')
  } catch (error) {
    commit('error', error)
    throw new Error(error.message)
  }
}
export const deleteTicket = async ({ commit }, { ticketId, userId, cancelReason, router }) => {
  const customError = {
    message: 'Something went wrong cancelling the ticket',
    data: {
      path: { responseURL: 'The server is not responding, please try again.' }
    }
  }
  commit('loading')

  try {
    const deleteTicketCall = await deleteTicketApi({
      ticketId,
      cancelReason,
      userId
    })
    if (deleteTicketCall.data === undefined) throw new Error('error call')
    commit('success')
    if (router) {
      router.push('/flight-board')
    }
    toast.success(CANCEL_TICKET_SUCCESS_MESSAGE)
    return deleteTicketCall
  } catch (error) {
    commit('error', error)
    toast.error({ title: customError })
    return error
  }
}

export const setSelectedTickets = ({ commit }, selectedTickets) => {
  commit('setSelectedTickets', selectedTickets)
}

export const pushSelectedTickets = ({ commit }, selectedTickets) => {
  commit('pushSelectedTickets', selectedTickets)
}

export const popSelectedTickets = ({ commit }) => {
  commit('popSelectedTickets')
}

export const setSelectedViewMode = async ({ commit }, viewMode) => {
  commit('setSelectedViewMode', viewMode)
}

export const pushSelectedViewMode = async ({ commit }, viewMode) => {
  commit('pushSelectedViewMode', viewMode)
}

export const popSelectedViewMode = async ({ commit }) => {
  commit('popSelectedViewMode')
}

export const setViewTicket = async ({ commit }, viewTicket) => {
  commit('setViewTicket', viewTicket)
}

export const pushViewTicket = async ({ commit }, viewTicket) => {
  commit('pushViewTicket', viewTicket)
}

export const popViewTicket = async ({ commit }) => {
  commit('popViewTicket')
}

export const popAll = async ({ commit }) => {
  commit('popSelectedTickets')
  commit('popSelectedViewMode')
  commit('popViewTicket')
}
export const clearSelectedTickets = ({ commit }) => {
  commit('clearSelectedTickets')
}

export default {
  fetchTicketsInfo,
  fetchTicketsDetail,
  updateTickets,
  deleteTicket,
  setSelectedTickets,
  pushSelectedTickets,
  popSelectedTickets,
  setSelectedViewMode,
  pushSelectedViewMode,
  popSelectedViewMode,
  setViewTicket,
  pushViewTicket,
  popViewTicket,
  popAll,
  clearSelectedTickets
}
