// import defaultState from '../state'

import { BiorepositoryTicketState } from '../state'
import { LocationItem, TicketMode } from '../types'

const mutations = {
  setTicketMode(state: BiorepositoryTicketState, ticketMode: TicketMode) {
    state.ticketMode = ticketMode
  },
  setCryoShipperTickets(state: BiorepositoryTicketState, cryoShipperTickets) {
    state.cryoShipperTickets = cryoShipperTickets
  },
  setsourceLocation(state: BiorepositoryTicketState, sourceLocation: LocationItem) {
    state.sourceLocation = sourceLocation
  },
  setdestinationLocation(state: BiorepositoryTicketState, destinationLocation: LocationItem) {
    state.destinationLocation = destinationLocation
  },
  setSelectedPatient(state: BiorepositoryTicketState, selectedPatient) {
    state.selectedPatient = selectedPatient
  },
  setSelectedBeacons(state: BiorepositoryTicketState, selectedBeacons) {
    state.selectedBeacons = selectedBeacons
  },
  setdestinationCryorobot(state: BiorepositoryTicketState, destinationCryorobot: LocationItem) {
    state.destinationCryorobot = destinationCryorobot
  }
}

export type BiorepositoryTicketMutations = typeof mutations

export default mutations
