<template>
  <div
    class="flex border-b border-solid"
    :class="[isDisabled ? 'border-gray-300' : 'border-tmrw-blue']">
    <div
      class="bg-white w-full flex justify-around items-center relative"
    >
      <input
        data-testid="scroll-table-biopsy-input"
        class="inputContainer w-full bg-white
              text-lg font-medium leading-tight
              text-tmrw-blue-dark p-1.5 focus:outline-none pr-8"
        :class="{ 'opacity-50': isDisabled }"
        @input="handleInput"
        :value="value"
        :disabled="isDisabled"
        :maxlength="20"
        :placeholder="placeholder"
        type="text"
      />
      <i
        class="fa fa-edit absolute right-1"
        :class="[isDisabled ? 'text-gray-300' : 'text-tmrw-blue']"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'scroll-table-biopsy-input-field',
  emits: ['valueFromCustomFieldChanged'],
  props: {
    value: {
      type: [String, Number, Object],
      required: false
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    columnOptions: {
      type: Object,
      required: false,
      default: () => {}
    },
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('valueFromCustomFieldChanged', event.target.value, this.columnOptions, this.options)
    }
  },
  computed: {
    isDisabled() {
      return this.item.embryo?.biopsy === 'No'
    }
  }
}
</script>
