import * as ss from '@/config/session-storage-help'

type cryoTypes = {
  cryoDeviceType: {
    id: string,
    value: string
  },
  type: {
    value: string,
    id: string
  }[]
}

// TODO: Type handling in this helper is incorrect. CryoDevice Type ID is a string!!!
// Needs to be changed!
const getCryodeviceType = (id?: number) => {
  try {
    const cryoDevicesTypes: [] = ss.getFieldSessionStorage('cryoDevicesTypes', '') || []

    if (cryoDevicesTypes.length && !!id) {
      const cryoDeviceType = cryoDevicesTypes.filter((cryoDevicesType: cryoTypes['cryoDeviceType']) => Number(cryoDevicesType.id) === Number(id))
      if (cryoDeviceType.length) {
        const [type]: cryoTypes['type'] = cryoDeviceType
        return type.value
      }
    }

    return ''
  } catch (error) {
    throw new Error(`getCryodeviceType Error --> ${error}`)
  }
}

export {
  getCryodeviceType
}
