<template>
  <div>
    <div data-test="radio-button-search-box" class="w-full flex flex-row items-center">
      <h3 class="font-exo text-2xl font-bold text-tmrw-blue">Search by:</h3>
      <span class="pl-10" v-for="(option, index) in inputs" :key="index">
        <input
          class="w-4 h-4"
          type="radio"
          :id="option.id"
          name="searchBy"
          @change="setSearchBy"
          :value="option.value"
          :disabled="option.disabled"
          :data-test="option.dataTest"
          v-model="searchByValue"
        />
        <label
          data-test="search-by-id-label"
          class="w-28 pl-2 text-2xl font-bold text-tmrw-blue"
          :for="option.id"
          >{{ option.label }}</label
        >
      </span>
    </div>
    <div v-if="searchByValue" class="flex flex-row items-end w-full">
      <div class="flex flex-row items-end w-full">
        <CustomSelect
          v-if="searchByValue !== 'byLocation'"
          class="w-32 mr-4"
          data-testid="add_cryobeacon_includes_dropdown"
          dataTest="add_cryobeacon_includes_dropdown"
          :options="includeExcludeOptions"
          hasLargePadding
          hasFontInconsolata
          has-large-font
          @update:modelValue="handleIncludeExcludeDropdown"
          isDefaultOptionDisabled="true"
          :modelValue="getModeDropdownValue"
        />
        <component
          :is="getComponent"
          ref="componentRef"
          :filterValue="filterValue.data"
          :searchFieldPlaceholder="searchFieldPlaceholder"
          :allContainers="allContainers"
          :sourceLocation="sourceLocation"
          placeholderColor="tmrw-blue"
          :isBlueOnWhite="true"
          @handleInput="handleInput"
          @handleEnter="handleAddSearchCriteria"
          @update:modelValue="handleInput"
        />
        <ButtonDynamic
          data-test="add_cryobeacon-add_search_button"
          btn-type="button"
          btn-style="secondary"
          btn-text="Add Search Criteria"
          iconPosition="right"
          show-icon
          :addClasses="['ml-6', 'mt-6']"
          font-awesome-icon-class="plus"
          @click="handleAddSearchCriteria"
        />
      </div>
      <ButtonDynamic
        data-test="add_cryobeacon-search_button"
        btn-type="button"
        btn-style="primary"
        btn-text="Search"
        show-icon
        :addClasses="['self-end']"
        font-awesome-icon-class="search"
        :isDisabled="!searchCriterias.length"
        @click="handleSearch"
      />
    </div>
    <div
      class="max-h-44 inline-block overflow-y-auto"
      v-if="searchCriterias.length"
      data-test="add_cryobeacon-search-criteria"
    >
      <ButtonDynamic
        btn-type="button"
        btn-text="Reset Filters"
        isTextUnderlined
        btnSize="small-regular"
        addMarginTop
        btnStyle="gray"
        show-icon
        font-awesome-icon-class="sync-alt"
        @click="handleResetFilters"
      />
      <ButtonDynamic
        v-for="rule in searchCriterias"
        addMarginRight
        addMarginTop
        :key="rule.key"
        btn-type="button"
        btnSize="small-regular"
        :btn-text="capitalize(rule.label)"
        show-icon
        :data-test="`criteria-${rule.label}`"
        :btnStyle="searchCriteriasColor(rule.mode)"
        @click="removeSingleCriteria(rule.label)"
        iconPosition="right"
        font-awesome-icon-class="times"
      />
    </div>
    <IntroductionPills v-if="!searchCriterias.length && searchByValue" />
  </div>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent } from 'vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import IntroductionPills from '@/components/SearchBy/IntroductionPills/IntroductionPills.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import {
  ISearchByComponents,
  ISearchModeOptions,
  DefaultSearchCriteriaModel
} from '@/types/massCreate'
import { EXCLUDE, EXCLUDE_LOWER, INCLUDE, INCLUDE_LOWER } from '@/constants'
import { useSearchCriteria } from './useSearchCriteria'
import { Destination } from '@/types/destinationOption'
import { capitalize } from '@/helpers/stringHelper'
import { ISearchCriteria } from '@/types/massCreate'

const emit = defineEmits(['getSearchCriteria'])
const props = defineProps<{
  prevSearch: Array<ISearchCriteria> | undefined
  allContainers: Array<Destination>
  sourceLocation: number
}>()
const includeExcludeOptions: ISearchModeOptions = {
  include: {
    value: INCLUDE_LOWER,
    label: INCLUDE
  },
  exclude: {
    value: EXCLUDE_LOWER,
    label: EXCLUDE
  }
}

// Components Async imported
const PatientDataSearchComponent = defineAsyncComponent(
  () => import('@/components/SearchBy/PatientDataSearch/PatientDataSearch.vue')
)
const SpecimenTypeSearchComponent = defineAsyncComponent(
  () => import('@/components/SearchBy/SpecimenTypeSearch/SpecimenTypeSearch.vue')
)
const LocationSearchComponent = defineAsyncComponent(
  () => import('@/components/SearchBy/LocationSearch/LocationSearch.vue')
)
const CryoDateSearchComponent = defineAsyncComponent(
  () => import('@/components/SearchBy/CryoDateSearch/CryoDateSearch.vue')
)
const inputs = ref<Array<ISearchByComponents>>([
  {
    id: 'patient_by_id',
    name: 'patient_by_id',
    value: 'byPatientIds',
    label: 'Patient ID',
    dataTest: 'select-patient__radio-by-id',
    component: PatientDataSearchComponent,
    disabled: false
  },
  {
    id: 'patient_by_lastname',
    name: 'patient_by_lastname',
    value: 'byLastNames',
    label: 'Last Name',
    dataTest: 'select-patient__radio-by-lastname',
    component: PatientDataSearchComponent,
    disabled: false
  },
  {
    id: 'location',
    name: 'location',
    value: 'byLocation',
    label: 'Location',
    dataTest: 'select-patient__radio-by-location',
    component: LocationSearchComponent,
    disabled: false
  },
  {
    id: 'specimen_type',
    name: 'specimen_type',
    value: 'bySpecimenTypes',
    label: 'Specimen Type',
    dataTest: 'select-patient__radio-by-specimen-type',
    component: SpecimenTypeSearchComponent,
    disabled: false
  },
  {
    id: 'cryodate',
    name: 'cryodate',
    value: 'byCryoDates',
    label: 'CryoDate',
    dataTest: 'select-patient__radio-by-cryodate',
    component: CryoDateSearchComponent,
    disabled: false
  }
])

const getDefaultSearchValue = (): DefaultSearchCriteriaModel => {
  return {
    defaultSearchInputTextValue: { data: '', label: '' },
    defaultSearchByValue: props.prevSearch?.[0]?.key || '',
    defaultModeSearchValue: INCLUDE_LOWER,
    defaultSearchCriterias: props.prevSearch || []
  }
}

const {
  searchByValue,
  filterValue,
  searchCriterias,
  getComponent,
  searchFieldPlaceholder,
  getModeDropdownValue,
  searchCriteriasColor,
  setSearchBy,
  handleIncludeExcludeDropdown,
  handleInput,
  handleAddSearchCriteria,
  resetFilters,
  removeCriteria,
  componentRef
} = useSearchCriteria(inputs.value, includeExcludeOptions, getDefaultSearchValue)

const handleSearch = () => {
  emit('getSearchCriteria', searchCriterias.value)
}

const handleResetFilters = () => {
  resetFilters()
  emit('getSearchCriteria', [])
}

const removeSingleCriteria = (label: string) => {
  removeCriteria(label)
  if (!searchCriterias.value.length) {
    emit('getSearchCriteria', [])
  }
}
</script>
