<template>
  <section
    data-test="patient-search__actions"
    class="flex bg-white p-6 rounded-t-lg h-36"
    :class="[!hasPatients ? 'rounded-b-lg' : '']"
  >
    <div class="w-1/2">
      <PatientsSearchField
        type="text"
        name="patient-search-box"
        v-model="searchText"
        :hasError="isSearchSubmitted && !hasPatients"
        addOpacity
        isBlueOnWhite
        @handlePatientsSearch="handleSearch"
        @resetSearchValue="resetSearch"
      />
    </div>
    <div
      v-if="canCreatePatient"
      class="w-1/2 flex justify-end items-center">
      <ButtonDynamic
        btnType="button"
        btnText="Add a New Patient"
        btnStyle="transparent-blue"
        addUnderline
        showIcon
        fontAwesomeIconClass="plus-circle"
        @click="openNewPatientModal"
      />
    </div>
  </section>
  <loading-ui modal v-if="loading" :message="loadingMessage" />
  <section
    data-test="patient-search__results"
    v-if="hasPatients"
    class="bg-white pb-1 pl-4 pr-4 mb-6 pt-0 rounded-b-lg"
  >
    <ScrollTableComponent
      v-if="hasPatients && isSearchSubmitted"
      minHeight="h-96"
      v-model="patientsModel"
      :headers="tableHeaders"
      :selectionMode="selectionMode"
      dataTest="select-patient__scroll-table-patients"
      @onButtonClicked="handleAddPatient"
    />
  </section>
  <ModalNewPatient
    :key="modalKey"
    :isModalOpen="isNewPatientModalOpen"
    @closeModalWindow="hideNewPatientModal"
    @fetchPatients="handleSearch"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ModalNewPatient from '@/views/ModalNewPatient/ModalNewPatient.vue'
import PatientsSearchField from '@/components/SearchField/PatientsSearchField.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import toast from '@/config/toast'
import {
  MAXIMUM_PATIENTS_ERROR,
  MAX_PATIENTS,
  SEARCHING_PATIENTS,
  PATIENT_IMPORT_ERROR
} from '@/constants'
import { tableHeaderPatientsWithAdd } from '@/constants/table-headers/patient'
import { patientWithFullName } from '@/modules/patient-group'

export default {
  name: 'patient-search',
  data() {
    return {
      isNewPatientModalOpen: false,
      loading: false,
      modalKey: 0,
      patients: [],
      patientsModel: {},
      isSearchSubmitted: false,
      searchText: '',
      loadingMessage: SEARCHING_PATIENTS
    }
  },
  emits: ['setPatients'],
  props: {
    canCreatePatient: {
      type: Boolean,
      required: false,
      default: true
    },
    canImportPatient: {
      type: Boolean,
      required: false,
      default: false
    },
    selectionMode: {
      type: String,
      required: true
    },
    selectedPatients: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  computed: {
    ...mapGetters('authModule', ['appBaseConfig']),
    tableHeaders() {
      const selectedPatientIds = this.selectedPatients?.map((patient) => patient.globalPatientNumber)
      return tableHeaderPatientsWithAdd(selectedPatientIds)
    },
    hasPatients() {
      const value = this.patients.length
      return value
    }
  },
  methods: {
    ...mapActions('patientsModule', ['searchPatients']),
    ...mapActions('patientGroupModule', ['addPatient']),
    ...mapActions('createPatientModule', ['createPatient']),
    async createExternalPatient(patientData) {
      const newPatient = await this.createPatient(patientData)
      const { clinicId, siteId } = this.appBaseConfig
      const patient = {
        ...patientWithFullName(newPatient),
        embryoCount: 0,
        oocyteCount: 0,
        specimenCount: 0,
        clinicId,
        siteId
      }
      const newPatients = [...this.patients]
      const externalPatientIndex = newPatients.findIndex(({ identificationNumber}) => identificationNumber === patientData.identificationNumber)
      newPatients[externalPatientIndex] = patient
      this.patients = newPatients
      return patient
    },
    async handleAddPatient({ value }) {
      let patient
      if(value.external && this.canImportPatient) {
        try {
          this.showLoader('Importing Patient...')
          patient = await this.createExternalPatient(value.externalInfo)
        } catch(e) {
          toast.error({ title: PATIENT_IMPORT_ERROR })
        }
        this.hideLoader()
      } else {
        patient = this.findPatient(value.id)
      }
      if (patient) {
        const patientsCount = this.selectedPatients.length
        const willExceedLimit = patientsCount === MAX_PATIENTS
        if (willExceedLimit) {
          toast.error({ title: MAXIMUM_PATIENTS_ERROR })
        } else {
          this.addPatient(patient)
        }
      }
    },
    async handleSearch(searchBy, value) {
      try {
        this.showLoader(SEARCHING_PATIENTS)
        await this.fetchPatients(searchBy, value)
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.hideLoader()
        this.isSearchSubmitted = true
      }
    },
    async fetchPatients(searchBy, value) {
      const params = {
        [searchBy]: value
      }
      const { data: allFoundPatients } = await this.searchPatients(params)
      // Filters out external patients if canImportPatient is false (only true on freeze and import tickets)
      const foundPatients = this.canImportPatient ? allFoundPatients : allFoundPatients.filter(patient => !patient.external)
    
      const patientsWithFullName = foundPatients.map((patient) => patientWithFullName(patient))
      this.patients = patientsWithFullName
    },
    findPatient(id) {
      return this.patients.find((patient) => patient.globalPatientNumber === id)
    },
    showLoader(message) {
      this.loadingMessage = message
      this.loading = true
    },
    hideLoader() {
      this.loading = false
    },
    hideNewPatientModal() {
      this.modalKey++
      this.isNewPatientModalOpen = false
    },
    openNewPatientModal() {
      this.isNewPatientModalOpen = true
      this.modalKey++
    },
    resetSearch() {
      this.isSearchSubmitted = false
      this.searchText = ''
      this.patients = []
    }
  },
  watch: {
    patients(newValue) {
      this.patientsModel = {
        sort: {
          orderBy: 'globalPatientNumber',
          direction: 'desc'
        },
        options: newValue
      }
      this.$emit('setPatients', newValue)
    },
    patientsModel(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('setPatients', newValue.options)
      }
    }
  },
  components: {
    ButtonDynamic,
    LoadingUi,
    ModalNewPatient,
    PatientsSearchField,
    ScrollTableComponent
  }
}
</script>
