<template>
  <header class="header flex items-start justify-between bg-opacity-75">
    <div data-test="header__left-side" class="flex items-end justify-start w-8/12">
      <button-dynamic
        v-if="backBtn"
        data-test="arrowBackButton"
        data-testid="header-back-button"
        btnType="button"
        btnText=""
        btnStyle="back-button"
        addMarginRight
        showIcon
        fontAwesomeIconClass="arrow-left"
        @click="onClickBack"
        :isDisabled="isDisabled"
      />
      <tmrw-logo
        @onClickAppLogo="showAppInfoModal"
        class="cursor-pointer pr-2 mr-2 mb-2"
        :width="'220px'"
      />
      <h2 data-test="header__title" class="border-l border-white text-tmrw-gray leading-8">
        <slot class="leading-3" v-if="currentRouteName !== '/flight-board'"
          ><!--Child content --></slot
        >
        <span class="leading-3" v-if="shouldShowWelcomeUsername"
          >Welcome {{ getCurrentUserName() }}!</span
        >
      </h2>
    </div>
    <div
      data-test="header__right-side"
      class="flex items-center min-w-500 justify-end whitespace-nowrap overflow-hidden text-ellipsis"
    >
      <div class="mr-6">
        <ul class="header__admin flex justify-end">
          <li data-test="header-logged-username" class="z-10 mr-4 text-white">
            {{ loggedUserInfo.loginName }}
          </li>
          <li data-test="header-logged-user-type" class="z-10 text-tmrw-blue-light">
            <i data-test="header-logged-user-type-icon" class="fas fa-cog text-sm" />
            {{ loggedUserInfo.userType }}
          </li>
        </ul>
        <ul class="mt-2">
          <li data-test="header-clinic-name" class="text-white float-left text-xs">
            {{ appBaseConfig.clinicName }}
          </li>
          <li class="text-white px-2 float-left text-xs">|</li>
          <li data-test="header-site-name" class="text-white float-left text-xs">
            {{ appBaseConfig.siteName }}
          </li>
          <li data-test="header-timezone-info" class="text-white ml-2 float-left text-xs">
            <i data-test="header-timezone-icon" class="far fa-clock" />
            {{ userTimezone }}
          </li>
        </ul>
      </div>
      <button-dynamic
        btn-data-test="header-hamburger-button"
        btnStyle="hamburguer"
        btnType="button"
        show-icon
        fontAwesomeIconClass="bars"
        @click="openMenu"
      />
      <side-menu :showMenu="showMenu" :closeMenu="closeMenu" />
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import SideMenu from '@/components/SideMenu/SideMenu.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import * as ss from '@/config/session-storage-help'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'top-header',
  data() {
    return {
      showMenu: false,
      isUserTester: false
    }
  },
  props: {
    backBtn: {
      type: [String, Boolean],
      default: true,
      required: false
    },
    isDisabled: {
      type: [Boolean],
      default: false,
      required: false
    },
    onBackStep: {
      type: Function,
      required: false
    }
  },
  // VUE 3 makes it difficult for the component to tell if the component has an event listener
  // Therefore it was changed from emit to a normal prop onBackStep
  // emits: ['backStep'],
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    currentRouteName() {
      return this.$router.currentRoute.value.path
    },
    userTimezone() {
      const timezoneName = dayjs.tz.guess()
      const offset = Math.abs(dayjs().utcOffset() / 60)
      const sign = offset < 0 ? '+' : '-'
      return `${timezoneName} (GMT ${sign}${offset})`
    },
    shouldShowWelcomeUsername() {
      return (
        (this.currentRouteName === '/flight-board' ||
          this.currentRouteName === '/biorepository/flight-board') &&
        this.getCurrentUserName()
      )
    }
  },
  methods: {
    ...mapActions('appInfoModule', ['toggleAppInfoModal']),
    onClickBack() {
      if (this.onBackStep) {
        this.onBackStep()
      } else if (typeof this.backBtn === 'string') {
        this.$router.replace(`/${this.backBtn}`)
      } else {
        this.$router.go(-1)
      }
    },
    openMenu() {
      this.showMenu = true
    },
    closeMenu() {
      this.showMenu = false
    },
    showAppInfoModal() {
      this.toggleAppInfoModal()
    },
    getCurrentUserName() {
      return ss.getFieldSessionStorage('user', 'firstName')
    }
  },
  components: {
    ButtonDynamic,
    TmrwLogo,
    SideMenu
  }
}
</script>
