<template>
  <div data-test="additional-patients" class="flex">
    <Tooltip placement="right" v-if="(patientCount >0)">
      <p data-test="additional-patients-label" class="text-tmrw-blue underline text-base font-semibold">{{ patientCount }}</p>
      <template #content>
        <ul data-test="additional-patients-list">
          <li v-for="patient in patients" :key="patient.globalPatientNumber">{{ patient.firstName}} {{ patient.lastName}}</li>
        </ul>
      </template>

    </Tooltip>
    <p data-test="additional-patients-empty" v-else class="text-tmrw-blue text-base font-semibold">-</p>
  </div>
</template>
<script setup lang="ts">

import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import { fetchPatientsById } from '@/services/patients'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps({
  value: {
    type: [Object, String],
    required: true
  },
  item: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const loading = ref(false)
const patients = ref([] as any[])

onMounted(() => {
  if(props?.options.patientsTooltip) {
    fetchPatients(props.item?.sharedPatientIds)
  }
})

const fetchPatients = async (patientIds) => {
  loading.value = true
  patients.value = await fetchPatientsById(patientIds)
  loading.value = false
}
const patientCount = computed(() =>
  patients.value.length
)
watch(props, (newProps) => {
  if(newProps?.options.patientsTooltip) {
    fetchPatients(newProps.item?.sharedPatientIds)
  }
})


</script>
