export default {
  error: '',
  beaconIdCheck: '',
  beaconIdValidate: false,
  beaconIdCheckMessage: '',
  singleBeaconReaderReads: [],
  beaconStep: 'newBeacon',
  beaconsList: [],
  beaconsSpecimens: [],
  currentBeacon: {},
  isCompleted: false,
  beaconBarcodeNumber: false,
  scannedBeaconBarcodes: []
}
