<template>
  <MainContainer gridSlots="2">
    <TopHeader>Modular {{ CRYOLABEL }} Format</TopHeader>
    <ModularLabel />
  </MainContainer>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import ModularLabel from '@/components/ModularLabel/ModularLabel.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { isFeatureEnabled, CRYOLABEL_ENABLED_FF } from '@/helpers/featureFlags'
import { CRYOLABEL } from '@/constants'

const router = useRouter()

onMounted(() => {
  if (!isFeatureEnabled(CRYOLABEL_ENABLED_FF)) {
    router.push('/flight-board')
  }
})
</script>
