import * as sessionStorage from '@/config/session-storage-help'
import { METHOD_NOT_SELECTED } from '@/constants'
import { STEP_INITIAL } from '@/constants/ticketSteps'

export default {
  // TODO: remove old vars
  ticketStep: 1, // replaced by currentTicketStep
  method: '', // replaced by selectedMethod
  type: '', // replaced by selectedSpecimenType
  typeEmbryo: '', // replaced by selectedTypeEmbryo
  patient: {}, // replaced by selectedPatient
  error: null,
  ticketCompletionStatus: '',
  ticket: [],
  batchSharedPatientIds: [],
  // TODO: There may be a better way to hydrate the store upon loading
  beacons: sessionStorage.getFieldSessionStorage('newTicket', 'beacons') || [],
  currentTicketStep: sessionStorage.getFieldSessionStorage('newTicket', 'currentTicketStep') || STEP_INITIAL,
  procedureDateFrom: sessionStorage.getFieldSessionStorage('newTicket', 'procedureDateFrom') || '',
  procedureDateTo: sessionStorage.getFieldSessionStorage('newTicket', 'procedureDateTo') || '',
  procedureTime: sessionStorage.getFieldSessionStorage('newTicket', 'procedureTime') || '',
  selectedCryoDeviceType: sessionStorage.getFieldSessionStorage('newTicket', 'selectedCryoDeviceType'),
  selectedMethod: sessionStorage.getFieldSessionStorage('newTicket', 'selectedMethod') || METHOD_NOT_SELECTED,
  selectedPatient: sessionStorage.getFieldSessionStorage('newTicket', 'selectedPatient'),
  selectedSpecimenType: sessionStorage.getFieldSessionStorage('newTicket', 'selectedSpecimenType'),
  source: sessionStorage.getFieldSessionStorage('newTicket', 'source'),
  metadata: sessionStorage.getFieldSessionStorage('newTicket', 'metadata'),
  robotTransfer: sessionStorage.getFieldSessionStorage('newTicket', 'robotTransfer'),
  shippingSourceId: sessionStorage.getFieldSessionStorage('newTicket', 'shippingSourceId'),
  donorId: sessionStorage.getFieldSessionStorage('newTicket', 'donorId'),
  selectedTypeEmbryo: sessionStorage.getFieldSessionStorage('newTicket', 'selectedTypeEmbryo'),
  subType: sessionStorage.getFieldSessionStorage('newTicket', 'subType') || '',
  batch: sessionStorage.getFieldSessionStorage('batch', '') || []
}
