<template>
  <router-view />
</template>

<script>
export default {
  name: 'main-layout',
  data() {
    return {
      route: this.$router.currentRoute.value.path
    }
  }
}
</script>
