import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

class DateFactory {
  private date: string

  public constructor(date: string) {
    this.date = date
    dayjs.extend(utc)
  }

  procedureTime(): string {
    try {
      // cloning the value to avoid data mutation
      const clonedDate = this.date
      return dayjs(clonedDate).format('DDMMMYYYY - h:mm A').toUpperCase()
    } catch (error: any) {
      return error
    }
  }

  patientDob(): string {
    try {
      // cloning the value to avoid data mutation
      const clonedDate = this.date
      return dayjs(clonedDate).format('DDMMMYYYY').toUpperCase()
    } catch (error: any) {
      return error
    }
  }

  procedureTimeToTicket(): string {
    try {
      // cloning the value to avoid data mutation
      const clonedDate = this.date
      return dayjs(clonedDate).format('h:mm A').toUpperCase()
    } catch (error: any) {
      return error
    }
  }
}

export {
  DateFactory
}
