import {
  FIELD_IS_NUMERIC,
  FIELD_IS_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  MAX_LENGTH_MESSAGE,
  MIN_LENGTH_MESSAGE,
  SPECIAL_CHARACTER_NOT_ALLOWED,
  FIELD_IS_LOWER_CASE,
  ALPHA_SPACE,
  ALPHA_SPACE_DASH,
  ALPHANUM_SPACE_DASH,
  ALPHANUM_DASH_UNDER,
  ALPHA_ONLY,
  INVALID_ZIP
} from '@/constants'

import { validateZipCode } from '@/utils/zip-codes'

import {
  helpers
} from '@vuelidate/validators'

const getErrorMessage = (field) => {
  
  let errorMessage: any = null
  try {
    const requiredError = field.$validator === 'required'
    if (requiredError) throw FIELD_IS_REQUIRED

    const specialCharError = field.$validator === 'alphaNum'
      || field.$validator === 'lettersNumberBlankSpaces'
      || field.$validator === 'alphaNumericHyphenBlankSpaces'
      || field.$validator === 'alphaNumericHyphen'
    if (specialCharError) throw SPECIAL_CHARACTER_NOT_ALLOWED

    if(field.$validator === 'alpha') throw ALPHA_ONLY

    const alphaOrSpace = field.$validator === 'alphaSpace' 
    if (alphaOrSpace) throw ALPHA_SPACE

    const alphaSpaceDash = field.$validator === 'alphaSpaceDash' 
    if (alphaSpaceDash) throw ALPHA_SPACE_DASH
    const alphaNumSpaceDash = field.$validator === 'alphaNumSpaceDash' 
    if (alphaNumSpaceDash) throw ALPHANUM_SPACE_DASH

    const alphaNumDashUnder = field.$validator === 'alphaNumSpaceDashUnder' 
    if (alphaNumDashUnder) throw ALPHANUM_DASH_UNDER

    const mailAddressError = field.$validator === 'email' || field.$validator === 'emailValidation'

    const acceptsOnlyLowerCaseLettersError = field.$validator === 'acceptsOnlyLowerCaseLetters'

    if (mailAddressError) throw INVALID_EMAIL_ADDRESS

    if (acceptsOnlyLowerCaseLettersError) throw FIELD_IS_LOWER_CASE

    const numericError = field.$validator === 'numeric'
    if (numericError) throw FIELD_IS_NUMERIC

    const maxLengthError = field.$validator === 'maxLength'
    if (maxLengthError) throw MAX_LENGTH_MESSAGE(field.$params.max)

    const minLengthError = field.$validator === 'minLength'
    if (minLengthError) throw MIN_LENGTH_MESSAGE(field.$params.min)

    throw field.$message
  } catch (e: any) {
    errorMessage = e
  }
  return errorMessage
}

/**
 * @requires Array requiredFields
 * @requires form this.v$.form -> vuelidate
 * @requires formErrorMsg component reference for handling msg
 * @callback 1: asserts on field is required
 * @callback 2: asserts on field is alphaNumeric
 * @callback 3: asserts on field is email
 * @callback 4: asserts on field is numeric
 * @callback 5: asserts on field match maxLength
 * @callback 6: asserts on field match minLength
 * @callback 7: asserts on field match lettersNumberBlankSpaces
 * @callback 8: asserts on field match alphaNumericHyphen
 * @callback default: return a null error msg
 */
export const validateFields = <T>(form: T, requiredFields: any[], formErrorMsg: object) => requiredFields.map((field: number) => {
  let errorMessage = null
  if (form[field].$errors?.length) {
    errorMessage = getErrorMessage(form[field].$errors[0])
  }

  const response = {
    ...formErrorMsg,
    [field]: errorMessage
  }
  return Object.assign(formErrorMsg, response)
})

export const alphaSpace = helpers.withMessage(ALPHA_SPACE, (value: string) => /^[a-zA-Z\s]*$/.test(value))
export const alphaSpaceDash = helpers.withMessage(ALPHA_SPACE_DASH, (value: string) => /^[a-zA-Z\s-]*$/.test(value))
export const alphaNumSpaceDash = helpers.withMessage(ALPHANUM_SPACE_DASH, (value: string) => /^[a-zA-Z0-9\s-]*$/.test(value))
export const alphaNumDashUnder = helpers.withMessage(ALPHANUM_DASH_UNDER, (value: string) => /^[a-zA-Z0-9-_]*$/.test(value))
export const zipCode = helpers.withMessage(INVALID_ZIP, (value, siblings) => {
  if(!value) { // Ignores if no value, must be validated by 'required' validator
    return true
  }
  return validateZipCode(value, siblings.country)
})
