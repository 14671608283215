<template>
  <div
    :class="[
      hasTabs ? 'rounded-tl-0 rounded-tr-lg rounded-br-lg rounded-bl-lg' : 'rounded-lg',
      isBackgroundTransparent ? 'bg-transparent' : 'bg-white',
      {
        'h-14': isTableDataEmpty,
        'overflow-hidden': overflowHidden
      }
    ]"
  >
    <slot name="table-container-header" />
    <div
      :data-test="dataTest"
      class="scroll-table-component w-full leading-none"
      :class="[
        minHeight,
        tableWrapperClasses,
        {
          'overflow-hidden': isTableDataEmpty,
          'overflow-y-scroll': !hasScroll && overflowHidden,
          'overflow-x-scroll': !isTableDataEmpty && !loading,
          'scroll-table-component--tick-scroll': tickScroll
        }
      ]"
    >
      <table
        class="w-full border px-2 md:table block"
        :class="[
          tableEmptyMessage && isTableDataEmpty ? minHeight : '',
          isBackgroundTransparent ? 'bg-transparent' : 'bg-white',
          {
            'overflow-y-scroll': !hasScroll && overflowHidden
          }
        ]"
      >
        <ScrollTableHeader
          :headers="headersToDisplay"
          :selectionMode="selectionMode"
          :sort="modelValue.sort || {}"
          :options="modelValue.options || []"
          :areAllSelected="areAllSelected"
          :hideSelectAllCheckbox="hideSelectAllCheckbox"
          :isTableDataEmpty="isTableDataEmpty"
          :isBackgroundTransparent="isBackgroundTransparent"
          :isRelocationView="isRelocationView"
          :headerInfo="headerInfo"
          @onSortColumn="onSortColumn"
          @onSelectAll="onSelectAll"
        />
        <tbody v-if="isTableDataEmpty && tableEmptyMessage && !loading">
          <tr>
            <td :colspan="headersToDisplay.length + 1" class="text-center">
              <span
                class="font-inconsolata font-semibold text-2xl text-tmrw-blue"
                v-html="$sanitize(tableEmptyMessage)"
              ></span>
            </td>
          </tr>
        </tbody>
        <tbody class="block md:table-row-group w-full max-w-full md:w-auto" v-if="loading">
          <tr>
            <td class="py-20" :colspan="headersToDisplay.length + 1"><loading-ui /></td>
          </tr>
        </tbody>
        <tbody
          class="block md:table-row-group w-full max-w-full md:w-auto"
          data-test="table-body"
          v-if="dataIsArrayInArray && !loading"
        >
          <slot v-for="(groupOfOptions, keyGroup) in getItemsOrdered">
            <tr
              v-if="groupHeaders && groupHeaders[keyGroup] && getItemsOrdered[keyGroup].length > 0"
              :key="`${keyGroup}-title`"
              :class="`bg-${groupHeaders[keyGroup].colorBg}`"
              class="cursor-pointer"
              @click="collapseGroupHeaders(keyGroup)"
            >
              <td class="border-b border-solid border-tmrw-gray">
                <i
                  class="px-6 py-4"
                  :class="[groupHeaders[keyGroup].icon, `text-${groupHeaders[keyGroup].colorText}`]"
                ></i>
              </td>
              <td
                :data-test="getGroupHeaderDataTest(groupHeaders, keyGroup)"
                class="border-b border-solid border-tmrw-gray h-2 text-tmrw-blue text-left py-4 whitespace-nowrap text-sm font-bold pl-3"
                :colspan="headersToDisplay.length"
                :class="`text-${groupHeaders[keyGroup].colorText}`"
              >
                {{ groupHeaders[keyGroup].title }}
                ({{ groupOfOptions.length }})
                <span v-if="groupHeadersCollapse[keyGroup].collapsed">
                  — <span class="underline">Click to View</span></span
                >
                <i
                  class="fas ml-4"
                  :class="[
                    `text-${groupHeaders[keyGroup].colorText}`,
                    groupHeadersCollapse[keyGroup].collapsed ? 'fa-chevron-down' : 'fa-chevron-up'
                  ]"
                ></i>
              </td>
            </tr>
            <template v-if="!groupHeadersCollapse[keyGroup].collapsed">
              <ScrollTableRow
                v-for="(item, key) in groupOfOptions"
                :item="item"
                :data-test="scrollTableRowDataTest(item)"
                :key="`${keyGroup}-${key}`"
                :uniqueKey="uniqueKey"
                :headers="headersToDisplay"
                :selectionMode="selectionMode"
                :isRelocationView="isRelocationView"
                @onToggleSelectRow="onToggleSelectRow"
                @onToggleFieldChanged="onToggleFieldChanged"
              />
            </template>
          </slot>
        </tbody>
        <tbody
          class="block md:table-row-group w-full max-w-full md:w-auto"
          data-test="scroll-table-component-table-body"
          v-else-if="!dataIsArrayInArray && !loading"
        >
          <ScrollTableRow
            v-for="(item, key) in getItemsOrdered"
            :item="item"
            :key="key"
            :uniqueKey="uniqueKey"
            :headers="headersToDisplay"
            :selectionMode="selectionMode"
            @onToggleSelectRow="onToggleSelectRow"
            @onToggleFieldChanged="onToggleFieldChanged"
            :isRelocationView="isRelocationView"
            @onButtonClicked="onButtonClicked"
          />
        </tbody>
        <tfoot v-if="footerColumns.length > 0">
          <tr>
            <td class="px-3 py-4" v-for="(item, key) in footerColumns" :key="key">
              <strong>{{ item }}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import setProp from 'lodash.set'
import cloneDeep from 'lodash.clonedeep'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import { CUSTOM_SELECTION_PROCEDURES, CUSTOM_SELECTION_PROCEDURES_OPTIONS } from '@/constants'
import { sortOptions } from '@/utils'
import ScrollTableRow from './ScrollTableRow.vue'
import ScrollTableHeader from './ScrollTableHeader.vue'
import selectionModes from './selectionModes'

export default {
  name: 'scroll-table-component',
  data() {
    return {
      customSelections: [...CUSTOM_SELECTION_PROCEDURES],
      customSelectionOptions: { ...CUSTOM_SELECTION_PROCEDURES_OPTIONS },
      groupHeadersCollapse: { ...this.groupHeaders }
    }
  },
  emits: [
    'update:modelValue',
    'onSortColumn',
    'onSelectAllCheckbox',
    'onButtonClicked',
    'onToggleSelectRow',
    'onToggleFieldChanged'
  ],
  methods: {
    onSortColumn(sort) {
      this.$emit('update:modelValue', { ...this.modelValue, sort })
      this.$emit('onSortColumn', { ...this.modelValue, sort })
    },
    onSelectAll(selectAction) {
      const options = this.mapOptionsData((item) =>
        !item.disabled &&
        !this.isCustomProcedure(item.procedureName && item.procedureName.toLowerCase())
          ? {
              ...item,
              selected: selectAction
            }
          : item
      )
      this.$emit('update:modelValue', { ...this.modelValue, options })
      this.$emit('onSelectAllCheckbox', { ...this.modelValue, options })
    },
    onButtonClicked(value, column) {
      this.$emit('onButtonClicked', { value, column })
    },
    onToggleSelectRow(item, selectAction, radioButton) {
      let { options } = this.modelValue
      if (radioButton) {
        options = this.mapOptionsData((option) =>
          option[this.uniqueKey] === item[this.uniqueKey]
            ? { ...option, selected: true }
            : { ...option, selected: false }
        )
      }
      if (!radioButton && !this.singleSelection) {
        options = this.mapOptionsData((option) =>
          option[this.uniqueKey] === item[this.uniqueKey]
            ? { ...option, selected: selectAction }
            : option
        )
      }
      if (!radioButton && this.singleSelection) {
        options = this.mapOptionsData((option) =>
          option.cryodeviceBarcode === item.cryodeviceBarcode
            ? { ...option, selected: selectAction }
            : { ...option }
        )
      }
      this.$emit('update:modelValue', { ...this.modelValue, options })
      this.$emit('onToggleSelectRow', { ...this.modelValue, options })
    },
    onToggleFieldChanged(item, value, column, componentOptions, fieldValue) {
      const { selectAll, immutableKey } = componentOptions
      let options = []
      if (immutableKey) {
        options = [...this.modelValue.options]
      } else {
        if (selectAll) {
          options = this.mapOptionsData((option) => {
            const newOption = cloneDeep(option)
            return setProp(newOption, column.key, value)
          })
        } else {
          options = this.mapOptionsData((option) => {
            const newOption = cloneDeep(option)
            return option[this.uniqueKey] === item[this.uniqueKey]
              ? setProp(newOption, column.key, value)
              : option
          })
        }
      }
      this.$emit('update:modelValue', { ...this.modelValue, options })
      this.$emit('onToggleFieldChanged', {
        ...this.modelValue,
        options,
        column,
        fieldValue
      })
    },
    mapOptionsData(mapFunction) {
      const options = [...this.modelValue.options]
      if (this.dataIsArrayInArray) {
        return options.map((option) => option.map(mapFunction))
      }
      return options.map(mapFunction)
    },
    sortColumn() {
      const {
        options,
        sort: { direction, orderBy }
      } = this.modelValue

      const sortParams = {
        options,
        direction,
        orderBy,
        headers: this.headers
      }
      return sortOptions(sortParams)
      // Moved sorting logic to a util function, so it can be reused elsewhere
    },
    isCustomProcedure(procedureName) {
      return this.customSelections.filter(() => !!this.customSelectionOptions[procedureName]).length
    },
    scrollTableRowDataTest({ ticketId }) {
      return `scroll-table__${this.dataTest}-row-${ticketId}`
    },
    getGroupHeaderDataTest(groupHeaders, keyGroup) {
      const headerGroupTitle = groupHeaders[keyGroup].title.toLowerCase()
      return `scroll-table__${headerGroupTitle}`
    },
    collapseGroupHeaders(groupHeader) {
      this.groupHeadersCollapse[groupHeader].collapsed =
        !this.groupHeadersCollapse[groupHeader].collapsed
    }
  },
  computed: {
    /**
     * @getItemsOrdered
     * This is to get the
     * items in the value.option
     * prop ordered all the time
     */
    getItemsOrdered() {
      if (this.handleSorting) {
        return this.sortColumn()
      }
      return this.modelValue.options
    },
    /**
     * @headersToDisplay
     * Filtering only the fields
     * the user want to be displayed
     */
    headersToDisplay() {
      return this.headers.filter(
        (item) => !item.unique || item.visible || item.visible === undefined
      )
    },

    /**
     * @areAllSelected
     * We want to know if all the
     * enabled fields are selcted or not
     */
    areAllSelected() {
      const flatAnArray = (items, depth = 1) =>
        items.reduce((arrayToFlat, toFlatten) =>
          arrayToFlat.concat(
            Array.isArray(toFlatten) && depth > 1 ? flatAnArray(toFlatten, depth - 1) : toFlatten
          )
        )
      const options = this.dataIsArrayInArray
        ? [...flatAnArray(this.modelValue.options)]
        : this.modelValue.options
      const selectedItems = options.filter((item) => item.selected).length
      const enabledItems = options.filter((option) => {
        if (this.ignoreSelectAllProcedureCheck) {
          return !option.disabled
        }
        const hasNormalProcedure =
          option &&
          option.procedureName &&
          !this.isCustomProcedure(option.procedureName.toLowerCase())
        return !option.disabled && hasNormalProcedure
      }).length
      return selectedItems > 0 && selectedItems === enabledItems
    },

    /**
     * @dataIsArrayInArray
     * With this computed we want to identify
     * if the data is an array of arrays that
     * need to be displayed in a grouped way
     * like hapends in the dashboard on clinic
     */
    dataIsArrayInArray() {
      return (
        this.modelValue &&
        this.modelValue.options &&
        this.modelValue.options.length &&
        this.modelValue.options[0].constructor === Array
      )
    },

    /**
     * @uniqueKey
     * This helper computed
     * to pick up the field selected as the primary
     * key in the table if there is not a unique
     * field in the headers we're gonna try to use one called id
     */
    uniqueKey() {
      const [uniqueKey] = this.headers.filter((header) => header.unique)
      if (uniqueKey && uniqueKey.key) {
        return uniqueKey.key
      }
      return 'id'
    },
    isSelectionModeNone() {
      return selectionModes.NONE === this.selectionMode
    },
    isSelectionModeRadio() {
      return selectionModes.RADIO === this.selectionMode
    },
    isSelectionModeCheckbox() {
      return selectionModes.CHECKBOX === this.selectionMode
    },
    isTableDataEmpty() {
      let items = this.getItemsOrdered
      if (this.dataIsArrayInArray) {
        items = this.getItemsOrdered.filter((localItem) => localItem.length)
      }
      return !items.length
    }
  },
  props: {
    /**
     * @value
     * This prop is to handle the v-model
     * attribute sent from the parent component
     * so that we can keep the component data updated by
     * calling the @input event with the new values for v-model
     *
     * Using v-model is equivalent to have this two props
     * from the parent component so the following code:
     *
     * ```<ScrollTableComponent
     *      v-model="specimensModel"
     *      :headers="specimensHeaders" />````
     *
     * Does the same as:
     * ```<ScrollTableComponent
     *      :value="specimensModel"
     *      @input="specimensModel = $event"
     *      :headers="specimensHeaders" />````
     *
     * Note: in the options arraiy in this prop
     * you can define wether a list of arrays or and array
     *  [[{name: 'Pedro'}, {name: 'Pedro'}], [{name: 'Pedro'}]]
     * or
     *  [{name: 'Pedro'}, {name: 'Pedro'}, {name: 'Pedro'}]
     *  you can define also what fields are selected by the `selected` field as true
     *  and define also what fields are disabled by the `disabled` field as true
     *  [{name: 'Pedro', selected: true}, {name: 'Pedro', selected: true}, {name: 'Pedro', disabled: true}]
     *
     * @see https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
     */
    modelValue: {
      type: Object,
      required: false,
      default: () => ({
        sort: {
          orderBy: '',
          direction: 'asc'
        },
        options: []
      })
    },

    /**
     * @loading
     * This displays a spinner
     * while its value is true
     * @Boolean
     */
    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @overflowHidden
     * Set if the container should
     * hide the overflow
     * @Boolean
     */
    overflowHidden: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * @hideSelectAllCheckbox
     * Hides Select All checkbox
     * when its value is true
     * @Boolean
     */
    hideSelectAllCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @hasScroll
     * Setting this value as true
     * you can make the table to flow
     * vertically in the page until
     * all its conetent is displayed.
     *
     * Take into account that with this
     * value as false which is its default value
     * the table it's gonna use the vertical space
     * available in its parent element *if this element has
     * has height values differents to percentajes
     * or if you're using a CSS grid that uses auto height
     * on the `grid-template-rows: auto 1fr;` attribute
     * @Boolean
     */
    hasScroll: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * @minHeight
     * The min height the table is gonna use
     * when the parent element for this table
     * has not a heigh on acountable measurments
     * make sure that if you want your
     * table to use the height of it's
     * container set up a height for the container
     * or use a grid with CSS grid with the
     * `grid-template-rows: auto 1fr;` attribute
     * on auto in the table container
     * This attribute is a CSS class with the min height
     * you want the table to have before showing the scroll table
     * the default value `h-56` is a tailwind class
     * @String
     */
    minHeight: {
      type: String,
      required: false,
      default: 'h-56'
    },

    /**
     * @headers
     * This attribute define the
     * table data structure and behavior
     * by defining a list of columns to
     * display in the table and it's features.
     *
     * The most simple example of a column is
     * when you want to display a text inside the column
     *
     *  ```
     *  [
     *    {
     *      name: 'Name',
     *      key: 'name'
     *    }
     *  ]
     *
     * By setting a field as unique, your defining
     * the key field for this table
     *
     *  ```
     *  [
     *    {
     *      name: 'Id',
     *      key: 'userId',
     *      unique: true,
     *      visible: false // <- you can also prevent this field to be displayed
     *    }
     *  ]
     *
     *  ```
     *  If you want to modify the text you want
     *  from the parent component, you'll want to set up an
     *  array of filters that will be applyed in its order
     *  ```
     *  [
     *    {
     *      name: 'Name',
     *      key: 'name',
     *      filters: [
     *        value => Number(value),
     *        value => dayjs(value).format('DDMMMYYYY'),
     *        value => value.toUpperCase()
     *      ]
     *    }
     *  ]
     *  ```
     *  If you want to modify your field to
     *  do something more amazing that will create HTML code
     *  into your table field, you'll want to
     *  use the component attribute and componentOptions
     *  ```
     *  [
     *    {
     *      name: 'Notes',
     *      key: 'specimenInfo',
     *      component: 'tooltip'
     *    },
     *    // You can also send params to your target component.
     *
     *    // To see the component availables
     *    // and its available props, take a look to the
     *    // @ScrollTableRow.getComponentName in the ScrollTableRow
     *    {
     *      name: 'Infectious Screening Status',
     *      key: 'screeningStatus',
     *      component: 'screeningStatus',
     *      componentOptions: {
     *        icon: true,
     *        label: true,
     *        color: 'blue'
     *      }
     *    },
     *  ]
     *  ```
     *
     * @Array
     */
    headers: {
      type: Array,
      required: true
    },

    /**
     * @selectionMode
     * For this prop we can pick up
     * one of these three selectionModes:
     * `radio`: this allows the user to select one
     *    row in the table and display a new column
     *    at the beginin of the table where the user can see the
     *    radiobuttons
     * `checkbox`: it allows the user to select more than
     *    one row in the table and a new column with checkbox
     *    will be displayed at the begining of the columns
     * `none`: doesnt display any selection column
     *
     * @String
     */
    selectionMode: {
      type: String,
      required: false,
      default: selectionModes.NONE,
      validator: (value) =>
        [
          selectionModes.NONE,
          selectionModes.RADIO,
          selectionModes.CHECKBOX,
          selectionModes.VIEW
        ].includes(value)
    },

    /**
     * @tickScroll
     * Define the tick of
     * the scroll bar, by default is
     * 15px tick, with this as true
     * it'll be 50px
     *
     * @Boolean
     */
    tickScroll: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * @tickScroll
     * Define the tick of
     * the scroll bar, by default is
     * 15px tick, with this as true
     * it'll be 50px
     *
     * @Boolean
     */
    groupHeaders: {
      type: Array,
      required: false
    },
    /**
     * Allows us to select one specimen at the time
     */
    singleSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Classes to be applied to the table wrapper
     */
    tableWrapperClasses: {
      type: String,
      default: 'rounded-lg'
    },
    /**
     * Classes to be applied to the table wrapper
     */
    hasTabs: {
      type: Boolean,
      default: false,
      required: false
    },
    dataTest: {
      type: String,
      default: 'scroll-table-component'
    },
    /**
     * Set if the table BG is Transparent
     */
    isBackgroundTransparent: {
      type: Boolean,
      default: false,
      required: false
    },
    /*
     * Used Determines if custom procedure check will
     * be applied and selecting/deselecting all items in
     * the table
     */
    ignoreSelectAllProcedureCheck: {
      type: Boolean,
      default: false
    },
    /**
     * List of columns to be displayed at the bottom of the table
     */
    footerColumns: {
      type: Array,
      default: () => [],
      required: false
    },
    isRelocationView: {
      type: Boolean,
      default: false,
      required: false
    },
    // wether sorting should be done here or handled elsewhere (eg: parent component)
    handleSorting: {
      type: Boolean,
      default: true,
      required: false
    },
    headerInfo: {
      type: String,
      required: false
    },
    tableEmptyMessage: {
      type: String,
      required: false
    }
  },
  components: {
    LoadingUi,
    ScrollTableRow,
    ScrollTableHeader
  }
}
</script>

<style lang="scss" scoped>
.scroll-table-component {
  &::-webkit-scrollbar {
    @apply w-3;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-tmrw-blue;
    @apply rounded-lg;
  }
}
</style>
