import { TICKET_MODULE_DEFAULT_STATE } from '@/constants'

export default {
  setTicket(state, payload) {
    state.ticket = payload
  },
  setTicketId(state, payload) {
    state.ticket.ticketId = payload
  },
  setState(state, payload) {
    state.ticket.state = payload
  },
  setSubState(state, payload) {
    state.ticket.subState = payload
  },
  setProcedureName(state, payload) {
    state.ticket.procedureName = payload
  },
  setProcedureType(state, payload) {
    state.ticket.procedureType = payload
  },
  setTicketRobotTransfer(state, payload) {
    state.ticket.robotTransfer = payload
  },
  setProcedureTime(state, payload) {
    state.ticket.procedureTime = payload
  },
  setEmbryologistId(state, payload) {
    state.ticket.embryologistId = payload
  },
  setPatientDob(state, payload) {
    state.ticket.patientDob = payload
  },
  setSource(state, payload) {
    state.ticket.source = payload
  },
  setInventoryUpdate(state, payload) {
    state.ticket.inventoryUpdate = payload
  },
  setIdentificationNumber(state, payload) {
    state.ticket.identificationNumber = payload
  },
  setPatientName(state, payload) {
    state.ticket.patientName = payload
  },
  setBeaconBarcode(state, payload) {
    state.ticket.beaconBarcode = payload
  },
  setSelectedTicketMethod(state, payload) {
    state.ticket.selectedTicketMethod = payload
  },
  setSpecimenType(state, payload) {
    state.ticket.specimenType = payload
  },
  setPatient(state, payload) {
    state.ticket.patient = payload
  },
  setVisit(state, payload) {
    state.ticket.visit = payload
  },
  setBeacons(state, payload) {
    state.ticket.beacons = payload
  },
  // TODO: create a method that sets data inside the beacons cryodevice, since this is done multiple time inside here
  // we could event create an action.
  setSelectedCryoDeviceType(state, payload) {
    state.ticket.beacons = state.ticket.beacons.map((beacon) => {
      const updatedCryoDevice = beacon.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        cryodeviceTypeId: payload.id
      }))
      return {
        ...beacon,
        cryoDevice: updatedCryoDevice
      }
    })
    state.ticket.selectedCryoDeviceType = payload
  },
  setMetadata(state, payload) {
    state.ticket.metadata = payload
  },
  setCollectionProtocolId(state, payload) {
    state.ticket.cpId = payload
  },
  setUserId(state, payload) {
    state.ticket.userId = payload
  },
  setSelectedSpecimenType(state, payload) {
    state.ticket.selectedSpecimenType = payload
  },
  setGlobalPatientNumber(state, payload) {
    state.ticket.globalPatientNumber = payload
  },
  setCryoDeviceSourceDonorId(state, payload) {
    // TODO: create a method that sets data inside the beacons cryodevice, since this is done multiple time inside here
    state.ticket.beacons = state.ticket.beacons.map((beacon) => {
      const updatedCryoDevice = beacon.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        source: {
          ...cryoDevice.source,
          donorId: payload
        }
      }))
      return {
        ...beacon,
        cryoDevice: updatedCryoDevice
      }
    })
  },
  setCryoDeviceSourceShippingSourceId(state, payload) {
    state.ticket.beacons = state.ticket.beacons.map((beacon) => {
      const updatedCryoDevice = beacon.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        source: {
          ...cryoDevice.source,
          shippingSourceId: payload
        }
      }))
      return {
        ...beacon,
        cryoDevice: updatedCryoDevice
      }
    })
  },
  setCryoDeviceShippingSource(state, payload) {
    state.intermediateTicketData.cryoDeviceShippingSource = payload
  },
  setCryoDeviceSourceTypeId(state, payload) {
    state.ticket.beacons = state.ticket.beacons.map((beacon) => {
      const updatedCryoDevice = beacon.cryoDevice.map((cryoDevice) => ({
        ...cryoDevice,
        source: {
          ...cryoDevice.source,
          sourceTypeId: payload
        }
      }))
      return {
        ...beacon,
        cryoDevice: updatedCryoDevice
      }
    })
  },
  clearTicketState(state) {
    state.ticket = TICKET_MODULE_DEFAULT_STATE()
  }
}
