<template>
  <ScanField
    :errorMessage="beaconIdCheckMessage"
    :indexKey="indexKey"
    :isForceModalClose="isProcessCompleted"
    :isLoading="isProcessingValidations"
    :label="SCAN_BEACON"
    :modalTitle="SCAN_BEACON_TITLE"
    :mode="scanMode"
    :onCancel="$_handleCancel"
    :onScan="$_handleScan"
    :placeholder="SCAN_BEACON_PLACEHOLDER"
    :scannedValue="scannedValue"
    :value="value"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as ss from '@/config/session-storage-help'
import ScanField from '@/components/ScanField/ScanField.vue'
import ScanMixin from '@/mixins/ScanMixin'
import {
  BEACON,
  SCAN_BEACON,
  SCAN_BEACON_PLACEHOLDER,
  SCAN_BEACON_TITLE,
  SCAN_VALID
} from '@/constants'

export default {
  components: { ScanField },
  name: 'scan-beacon',
  mixins: [ScanMixin],
  created() {
    this.SCAN_BEACON = SCAN_BEACON
    this.SCAN_BEACON_PLACEHOLDER = SCAN_BEACON_PLACEHOLDER
    this.SCAN_BEACON_TITLE = SCAN_BEACON_TITLE
  },
  methods: {
    ...mapActions('beaconsModule', ['checkBeaconId', 'setBeaconIdCheckMessage', 'setSingleBeaconReaderReads']),
    $_resetBeaconIdCheckMessage() {
      this.setBeaconIdCheckMessage('')
    },
    $_handleReadingValidation() {
      ss.setFieldSessionStorage('lastScannedBeacon', this.temporaryValue)
      this.setBeaconIdCheckMessage('')

      if (!this.singleBeaconReaderReads || !this.singleBeaconReaderReads.includes(this.temporaryValue)) {
        this.setSingleBeaconReaderReads(this.temporaryValue)
        this.checkBeaconId([this.temporaryValue, BEACON])
          .then((response) => {
            const status = response?.data?.status || 'INVALID'
            if (status === SCAN_VALID) {
              this.$_completeProcess()
            } else {
              this.isProcessingValidations = false
            }
          })
      } else {
        this.setBeaconIdCheckMessage(`The ID ${this.temporaryValue} already exists! Please use another CryoBeacon.`)
        this.isProcessingValidations = false
      }
    }
  },
  computed: {
    ...mapGetters('beaconsModule', ['beaconIdCheckMessage', 'beaconBarcodeNumber'])
  }
}
</script>
