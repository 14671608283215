<template>
  <MainContainer data-test="users-list" gridSlots="3">
    <top-header :back-btn="false">Create, Edit or Lock Users</top-header>
    <ActionBar
      v-if="areUsersLoaded"
      data-test="patient-view__action-bar"
      colsDistribution="6/6"
      setLeftSideToBottom
    >
      <template v-slot:left-side>
        <Tabs :options="options" :active="filter" @changeValue="handleTabChange" />
      </template>
      <template v-slot:right-side>
        <users-search-field
          type="text"
          name="userSearch"
          v-model="userSearch"
          :placeholder="getSearchPlaceHolder"
          class="h-8"
          inputColor="text-white"
        />
        <button-dynamic
          btnType="button"
          btnText="View User"
          btnStyle="secondary"
          addMarginLeft
          :isDisabled="!selectedUser.length"
          @click="handleViewUser"
        />
        <button-dynamic
          btnType="button"
          btnText="Create User"
          btnStyle="primary"
          addMarginLeft
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="handleCreateUserClick"
        />
      </template>
    </ActionBar>
    <ScrollTableComponent
      v-if="areUsersLoaded"
      minHeight="h-full"
      v-model="filteredListModel"
      :headers="headers"
      :selectionMode="'radio'"
      :loading="isProcessing"
      :hasTabs="true"
    />
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import UsersSearchField from '@/components/SearchField/UsersSearchField.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import Tabs from '@/components/Tabs/Tabs.vue'

export default {
  name: 'users-list',
  components: {
    ScrollTableComponent,
    TopHeader,
    UsersSearchField,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    Tabs
  },
  data() {
    return {
      areUsersLoaded: false,
      filteredListModel: {
        sort: {
          orderBy: 'usersId',
          direction: 'desc'
        },
        options: []
      },
      filter: 'ACTIVE',
      canBeShown: false,
      localUsers: [],
      userSearch: '',
      backBtnUrl: 'flight-board',
      selectedUser: [],
      headers: [
        {
          name: '',
          key: 'id',
          visible: false,
          unique: true
        },
        {
          name: 'Login Name',
          key: 'loginName',
          sort: true
        },
        {
          name: 'First Name',
          key: 'firstName',
          sort: true
        },
        {
          name: 'Last Name',
          key: 'lastName',
          sort: true
        },
        {
          name: 'Email',
          key: 'emailAddress',
          sort: true,
          size: 'medium'
        },
        {
          name: '',
          key: 'role',
          size: 'medium',
          cssClasses: ['w-32'],
          component: 'userRoleIcon'
        },
        {
          name: '',
          key: 'locked',
          size: 'medium',
          cssClasses: ['w-8'],
          component: 'userRoleLocked'
        }
      ],
      options: [
        {
          label: 'Active',
          value: 'ACTIVE'
        },
        {
          label: 'Archived',
          value: 'ARCHIVE'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('usersModule', ['usersList', 'userStatus']),
    isProcessing() {
      return this.userStatus === 'loading'
    },
    filteredList() {
      if (this.userSearch) {
        const userSearch = this.userSearch.toLowerCase()
        return this.localUsers.filter(
          (user) =>
            user.firstName.toLowerCase().includes(userSearch) ||
            user.lastName.toLowerCase().includes(userSearch) ||
            user.loginName.toLowerCase().includes(userSearch) ||
            user.emailAddress.toLowerCase().includes(userSearch)
        )
      }
      return this.localUsers
    },
    getSearchPlaceHolder() {
      const filterText = this.filter.toLowerCase()
      const updatedFilter = filterText === 'archive' ? 'archived' : filterText
      return `Filter ${updatedFilter} users`
    }
  },
  methods: {
    ...mapActions('usersModule', ['fetchUsers']),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    handleCreateUserClick() {
      this.$router.push('/user/create')
    },
    handleViewUser() {
      const [{ id }] = this.selectedUser
      this.$ss.setFieldSessionStorage('selectedUser', id)
      this.$router.push('/user/details')
    },
    async handleTabChange() {
      if (this.filter === 'ARCHIVE') {
        this.filter = 'ACTIVE'
      } else {
        this.filter = 'ARCHIVE'
      }
      this.displaySpinner('Loading Users')
      this.hideSpinner()
    }
  },
  watch: {
    usersList(newValue) {
      if (newValue && newValue.length) {
        this.localUsers = this.usersList.map((user) => ({
          ...user,
          role: user.userRoles[0]?.name || 'Unknown'
        }))
      }
    },
    filter(newValue) {
      let { options, sort } = this.filteredListModel
      let newOptions = []
      if (newValue === 'ACTIVE') {
        newOptions = this.usersList.filter(
          (user) => user.activityStatus === 'Active' || user.activityStatus === 'Locked'
        )
      } else {
        newOptions = this.usersList.filter((user) => user.activityStatus === 'Archived')
      }
      options = newOptions
      this.filteredListModel = { sort, options }
    },
    filteredListModel(newValue, oldValue) {
      if (newValue.options !== oldValue.options) {
        const selectedUser = this.filteredListModel.options.filter((option) => option.selected)
        this.selectedUser = selectedUser || []
      }
    },
    filteredList(newValue) {
      if (newValue) {
        const { sort } = this.filteredListModel
        let options = []
        if (this.filter === 'ACTIVE') {
          options = this.filteredList.filter(
            (user) => user.activityStatus === 'Active' || user.activityStatus === 'Locked'
          )
        } else {
          options = this.filteredList.filter((user) => user.activityStatus === 'Archived')
        }
        this.filteredListModel = { sort, options }
      }
    }
  },
  async created() {
    this.displaySpinner('Loading Users')
    await this.fetchUsers()
    this.areUsersLoaded = true
    this.hideSpinner()
  }
}
</script>
