import dayjs from 'dayjs'
import { notify } from '@kyvg/vue3-notification'
import * as ss from '@/config/session-storage-help'

export default {
  error: (err) => {
    const { message = '', title = '' } = err
    const currentPath = window.location.href
    const errorLog = ss.getFieldSessionStorage('errorLog')
    errorLog.push({
      error: err,
      errorTime: dayjs().format('MM/DD/YYYY HH:ss'),
      errorTimestamp: dayjs().valueOf(),
      currentPath
    })
    ss.setFieldSessionStorage('errorLog', errorLog)
    notify({
      group: 'error',
      type: 'error',
      title,
      text: `${message}`,
      duration: 5000 // temporary
    })
  },
  success: (text) => {
    notify({
      group: 'success',
      type: 'success',
      text
    })
  },
  warn: (text) => {
    const errorLog = ss.getFieldSessionStorage('errorLog')
    const currentPath = window.location.href
    errorLog.push({
      error: text,
      errorTime: dayjs().format('MM/DD/YYYY HH:ss'),
      errorTimestamp: dayjs().valueOf(),
      currentPath
    })
    ss.setFieldSessionStorage('errorLog', errorLog)
    notify({
      group: 'warn',
      type: 'warn',
      text
    })
  },
  info: (text) => {
    notify({
      group: 'info',
      text,
      type: 'info',
      duration: 5000
    })
  },
  custom: (group, type, text) => {
    // group: success, warn, info
    // type: success, warn, error
    const errorLog = ss.getFieldSessionStorage('errorLog')
    const currentPath = window.location.href
    if ((group === 'error' && type === 'error') || (group === 'error' && type === 'custom-warn')) {
      errorLog.push({
        error: text,
        errorTime: dayjs().format('MM/DD/YYYY HH:ss'),
        errorTimestamp: dayjs().valueOf(),
        currentPath
      })
      ss.setFieldSessionStorage('errorLog', errorLog)
    }
    notify({
      group,
      type,
      text,
      title: type === 'custom-warn' ? '' : null
    })
  },
  customError: (err) => {
    const { message = '', title = '', icon = '', customClasses = [] } = err
    notify({
      group: 'customError',
      type: 'customError',
      title,
      text: `${message}`,
      duration: 15000,
      data: {
        icon,
        customClasses
      }
    })
  }
}
