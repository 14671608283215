import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { setFieldSessionStorage } from '@/config/session-storage-help'
import { createTicketsApi } from '@/services/tickets'
import {
  PROCEDURE_TYPE_BEACON_MOVE,
  PROCEDURE_TYPE_CRYOSHIPPER,
  TEMPORARY_STORAGE
} from '@/constants'

import { BiorepositoryTicketMutations } from '../mutations'
import { StoreActionContext } from '@/store/store-types'

import { Locations, TicketMode } from '../types'
import { CreateTicketResponse } from '@/services/tickets/types'
import { UpdateShipmentBody } from '@/services/shipments/types'
import { updateShipment } from '@/services/shipments'

type BiorepositoryTicketActionContext = StoreActionContext<BiorepositoryTicketMutations>

dayjs.extend(utc)

const sourceOptions = {
  INTERNAL: 1,
  EMR: 2,
  BULK_SPECIMEN: 3
}

type TicketData = {
  eventId: number
  procedureType: string
  specimenType: string
  beaconBarcode: string
  userId: number
  destinationSiteId: number
  cpId: number
  visit: {
    procedureDateFrom: number
    procedureDateTo: number
  }
  patient: any
  source: number
  tempStorageRelocation?: boolean
  unitId?: number
}

type CryoShipperTicketData = {
  sourceSiteId: number
  destinationSiteId: number
  children: number[]
}

/*
                  - procedureType: 'CRYOSHIPPER'
                    visit:
                      procedureDateFrom: 1715343486689
                    source: 1
                    children:
                      - 428542
                      - 428543
                    destinationSiteId: 42
*/
const actions = {
  async createCryoShipperTicket(
    { rootState },
    { children, sourceSiteId: siteId, destinationSiteId }: CryoShipperTicketData
  ): Promise<CreateTicketResponse[]> {
    try {
      const procedureType = PROCEDURE_TYPE_CRYOSHIPPER
      const procedureDateFrom = dayjs.utc().valueOf()

      const {
        appBaseConfig: { clinicId },
        loggedUserInfo: { userId }
      } = rootState.authModule
      // TODO: Change for the real stuff
      // TODO CRYOSHIPPER: Remove mock
      const response = await createTicketsApi({
        clinicId,
        siteId,
        ticketsArray: [
          {
            procedureType,
            visit: {
              procedureDateFrom
            },
            children,
            userId,
            siteId,
            destinationSiteId,
            source: sourceOptions.INTERNAL
          }
        ]
      })
      return response.data
    } catch (err: any) {
      const errorMessage = err.response?.data?.[0]?.message
      throw Error(errorMessage || err)
    }
  },
  async updateCryoShipper(_, shipment: UpdateShipmentBody): Promise<number> {
    try {
      const response = await updateShipment(shipment)

      return response.data
    } catch (err: any) {
      const errorMessage = err.response?.data?.[0]?.message
      throw Error(errorMessage || err)
    }
  },
  async createBiorepoTicket(
    {
      // commit,
      rootState
    }: BiorepositoryTicketActionContext,
    { selectedBeacons, selectedPatient, destinationLocation, destinationCryorobot }
  ) {
    const {
      appBaseConfig: { clinicId, siteId },
      // token,
      loggedUserInfo: { userId }
    } = rootState.authModule

    const DEFAULT_EVENT_ID = 0

    const procedureDateFrom = dayjs.utc().valueOf()
    const procedureDateTo = dayjs.utc().valueOf()
    const ticketsArray = selectedBeacons.map((beacon) => {
      let basicData: TicketData = {
        eventId: DEFAULT_EVENT_ID,
        procedureType: PROCEDURE_TYPE_BEACON_MOVE,
        specimenType: beacon.specimens?.[0].specimenType,
        beaconBarcode: beacon.beaconId,
        userId,
        destinationSiteId: destinationLocation.id,
        cpId: beacon.specimens?.[0].cpId,
        visit: {
          procedureDateFrom,
          procedureDateTo
        },
        patient: selectedPatient,
        source: sourceOptions.INTERNAL
      }

      if (destinationCryorobot) {
        if (destinationCryorobot.name === TEMPORARY_STORAGE) {
          basicData = { ...basicData, tempStorageRelocation: true }
        } else {
          basicData = { ...basicData, unitId: destinationCryorobot.id }
        }
      }

      return basicData
    })

    try {
      const response = await createTicketsApi({
        clinicId,
        siteId,
        ticketsArray
        // token
      })
      // commit('createTicket', response.data)
      // commit('success')
      return response
    } catch (err: any) {
      const errorMessage = err.response?.data?.[0]?.message
      // commit('error', errorMessage || err)
      throw Error(errorMessage || err)
    }
  },
  setSelectedPatient({ commit }: BiorepositoryTicketActionContext, value) {
    setFieldSessionStorage('selectedPatient', value, 'biorepositoryTicket')
    commit('setSelectedPatient', value)
  },
  setSelectedBeacons({ commit }: BiorepositoryTicketActionContext, value) {
    setFieldSessionStorage('selectedBeacons', value, 'biorepositoryTicket')
    commit('setSelectedBeacons', value)
  },
  setLocations({ commit }: BiorepositoryTicketActionContext, payload: Locations) {
    setFieldSessionStorage(
      'destinationLocation',
      payload.destinationLocation,
      'biorepositoryTicket'
    )
    setFieldSessionStorage('sourceLocation', payload.sourceLocation, 'biorepositoryTicket')
    setFieldSessionStorage('destinationCryorobot', payload?.cryorobot, 'biorepositoryTicket')
    commit('setdestinationLocation', payload.destinationLocation)
    commit('setsourceLocation', payload.sourceLocation)
    commit('setdestinationCryorobot', payload?.cryorobot)
  },
  setTicketMode({ commit }: BiorepositoryTicketActionContext, value: TicketMode) {
    setFieldSessionStorage('ticketMode', value, 'biorepositoryTicket')
    commit('setTicketMode', value)
  },
  setCryoShipperTickets({ commit }: BiorepositoryTicketActionContext, cryoShipperTickets) {
    setFieldSessionStorage('ticketMode', cryoShipperTickets, 'biorepositoryTicket')
    commit('setCryoShipperTickets', cryoShipperTickets)
  }
}

export type BiorepositoryTicketActions = typeof actions

export default actions
