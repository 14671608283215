<template>
  <TicketCardDetail
    :title="title"
    :patient-data="patient"
    :shared-patient-ids="sharedPatientIds"
    :ticketId="ticketId"
    data-test="ticket-to-print__ticket-card"
  >
    <template v-slot:list>
      <TicketListDetail
        :beaconId="beaconId"
        :date="date"
        :time="time"
        :headers="specimensList.headers || []"
        :specimen-data="specimensList.data || []"
        :title="specimensList.title"
        :state="ticket.state"
        :ticketProcedureType="ticket.procedureName"
        :robotLocations="ticket.robotLocations"
        :robotTransfer="ticket.robotTransfer"
        :screeningStatus="screeningStatus"
        :ticket="ticket"
        :showTicketActionButtons="false"
      />
    </template>
  </TicketCardDetail>
</template>

<script>
/**
 * To Do
 * Redesign architechture
 * This component is similar to -> src/components/TicketToPrint/TicketToPrint.vue
 */
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TicketCardDetail from '@/components/TicketCardDetail/TicketCardDetail.vue'
import TicketListDetail from '@/components/TicketListDetail/TicketListDetail.vue'
import { selectEmbryoTypeLabel } from '@/utils/'
import * as tableHeadersConstants from '@/constants/table-headers/ticket'
import { typeEmbryo } from '@/constants'

dayjs.extend(utc)

export default {
  name: 'ticket-info',
  props: ['ticket'],
  emits: ['onClickEditList', 'onClickEditTicket', 'onClickDelete'],
  computed: {
    ...mapGetters('specimensModule', ['embryoTypes']),
    title() {
      return `${
        this.ticket.procedureName.includes(typeEmbryo.egg)
          ? this.ticket.procedureName.replace(typeEmbryo.egg, typeEmbryo.replace)
          : this.ticket.procedureName || ''
      }`
    },
    patient() {
      const [firstName, ...lastName] = this.ticket.patientName.split(' ')
      return {
        patientDob: this.ticket.patientDob,
        globalPatientNumber: this.ticket.globalPatientNumber,
        identificationNumber: this.ticket.identificationNumber,
        firstName,
        lastName: lastName?.join(' ') || ''
      }
    },
    sharedPatientIds() {
      return this.ticket.sharedPatientIds
    },
    ticketId() {
      return `${this.ticket.ticketId}`
    },
    beaconId() {
      return this.ticket.beaconBarcode
    },
    date() {
      return this.ticket.procedureTime && dayjs(this.ticket.procedureTime).format('DDMMMYYYY')
    },
    time() {
      return this.ticket.procedureTime && dayjs(this.ticket.procedureTime).format('hh:mm A')
    },
    thereAreSpecimens() {
      return !!(this.ticket.specimens && this.ticket.specimens.length > 0)
    },
    tableHeaders() {
      // eslint-disable-next-line no-prototype-builtins
      const specimenType = this.ticket.specimens[0]?.hasOwnProperty('embryo') ? 'embryo' : 'egg'
      return tableHeadersConstants[specimenType]
    },
    specimensList() {
      return (
        this.thereAreSpecimens && {
          title: `${this.ticket.specimens.length} Cryodevice${
            this.ticket.specimens.length > 1 ? 's' : ''
          }`,
          headers: this.tableHeaders,
          data: this.ticket.specimens.map((specimen) => ({
            ...specimen,
            embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes)
          }))
        }
      )
    },
    screeningStatus() {
      if (this.ticket && this.ticket.screeningStatus) {
        return this.ticket.screeningStatus
      }
      if (this.ticket && this.ticket.cryoDevice && this.ticket.cryoDevice.length) {
        return this.ticket.cryoDevice[0].screeningStatus
      }
      if (this.ticket && this.ticket.specimens && this.ticket.specimens.length) {
        return this.ticket.specimens[0].screeningStatus
      }
      return 0
    }
  },
  methods: {
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    onClickEditList() {
      this.$emit('onClickEditList', this.ticket)
    },
    onClickEditTicket() {
      this.$emit('onClickEditTicket', this.ticket)
    },
    onClickDelete() {
      this.$emit('onClickDelete', this.ticket)
    }
  },
  created() {
    this.fetchEmbryoTypes()
  },
  components: {
    TicketCardDetail,
    TicketListDetail
  }
}
</script>
