<template>
  <span
    class="tooltip-multiple-patients"
    :class="alignment"
  >
    {{this.content}}
  </span>
</template>

<script>
export default {
  name: 'tooltip',
  props: {
    content: {
      type: String,
      required: true
    },
    alignment: {
      type: String,
      required: false,
      default: 'right'
    }
  }
}
</script>

<style lang="scss">
  .tooltip-multiple-patients {
    @apply w-max absolute bg-tmrw-blue-light text-white whitespace-pre-line p-3 pt-2 font-medium rounded-lg leading-6 transform z-10;
    border-spacing: 22px 8px;
    min-height: 32px;

    &:before {
      @apply empty-content block absolute w-0;
      @apply border-b-10 border-r-10 border-t-10 border-solid border-transparent absolute;
      border-right-color: theme('colors.tmrw-blue.light');
      transform: translateY(-50%);
    }

    &.right {
      @apply ml-4 bottom-1/2 translate-y-2/4;

      &:before {
        @apply top-1/2;
        left: -10px;
      }
    }

    &.bottom {
      @apply top-full left-0 mt-2;

      &:before {
        @apply transform rotate-90;
        top: -10px;
      }
    }
  }
</style>
