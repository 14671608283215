<template>
  <paginate
    data-test="paginator-container"
    v-model="activePage"
    :page-count="totalItemsToDisplay"
    :page-range="maxPaginatorsCountPerView"
    :margin-pages="0"
    :click-handler="onChangePageToView"
    prev-text="＜"
    next-text="＞"
    class="paginator-container ml-4 relative z-0"
    container-class="flex"
    :prev-class="totalItemsToDisplay <= maxPaginatorsCountPerView ? 'hidden' : 'navigation-button'"
    :next-class="totalItemsToDisplay <= maxPaginatorsCountPerView ? 'hidden' : 'navigation-button'"
    page-class="paginator-button"
    page-link-class="paginator-button__link"
    prev-link-class="navigation-button__link"
    next-link-class="navigation-button__link"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import Paginate from 'vuejs-paginate-next'

const emit = defineEmits(['handlePageChange'])
const props = defineProps({
  totalItemsToDisplay: {
    type: Number,
    required: true
  },
  maxPaginatorsCountPerView: {
    type: Number,
    required: true
  },
  currentActivePage: {
    type: Number,
    required: true
  }
})

const activePage = ref(0)

const onChangePageToView = (page) => {
  emit('handlePageChange', page)
}
onMounted(() => {
  activePage.value = props.currentActivePage
})

watch(
  () => props.currentActivePage,
  (newPage) => {
    activePage.value = newPage
  }
)
</script>

<style lang="scss" scoped>
.paginator-container::v-deep > li.navigation-button {
  @apply bg-tmrw-gray flex items-center text-tmrw-blue-dark rounded-tl-sm rounded-tr-sm;
}

.paginator-container::v-deep > li.navigation-button > a.navigation-button__link {
  @apply px-4 py-2 select-none;
}

.paginator-container::v-deep > li.paginator-button {
  @apply transition duration-200 ease-in;
}

.paginator-container::v-deep > li.paginator-button:not(.active) {
  @apply bg-tmrw-gray;
}

.paginator-container::v-deep > li.paginator-button.active {
  @apply bg-white;
  @apply transform;
  @apply scale-110;
  @apply origin-bottom;
}

.paginator-container::v-deep > li.paginator-button > a.paginator-button__link {
  @apply px-5 py-2 select-none;
}

.paginator-container::v-deep > li.paginator-button:not(.active):hover {
  @apply bg-tmrw-green;
  @apply transform;
  @apply scale-110;
  @apply origin-bottom;
}

.paginator-container::v-deep > li.paginator-button {
  @apply py-1 mx-1 text-tmrw-blue-dark rounded-tl-sm rounded-tr-sm font-bold flex items-center;
}
</style>
