<template>
  <div class="border-b border-solid border-tmrw-blue">
    <div
      class="bg-white w-full h-8 flex justify-around items-center"
      :class="{ 'cursor-pointer': edit }"
      data-test="dateField-dateBox"
      @click="showDateModal"
      @keyup.enter="showDateModal"
      :tabindex="tabKey"
      ref="containerRef"
    >
      <div v-if="!formattedDate" class="flex items-center justify-between px-2">
        <span class="text-tmrw-blue font-normal text-sm">Click to add it</span>
        <i class="fa fa-plus text-tmrw-blue text-sm ml-2" />
      </div>
      <div v-else class="flex items-center justify-between px-2">
        <span class="text-tmrw-blue-dark font-normal text-sm tracking-wider uppercase">{{
          getFormattedDate
        }}</span>
        <i class="far fa-edit text-tmrw-blue ml-6 text-sm" />
      </div>
    </div>
    <div
      v-if="isDateModalVisible"
      data-test="add-cryo-date-modal-bg"
      class="fixed z-50 w-screen h-screen top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div class="w-4/12 bg-white p-6 rounded-lg" data-test="add-cryo-date-modal">
        <div class="w-full flex mb-10">
          <h1
            data-test="add-cryo-date-modal-title"
            class="w-10/12 flex justify-start items-center font-bold text-tmrw-blue-dark font-exo text-3xl"
          >
            Add the CryoDate
          </h1>
          <div class="w-2/12 flex justify-end items-center">
            <span
              @click="hideDateModal"
              data-test="dateField-closeModal"
              class="w-4 h-4 flex justify-center items-center cursor-pointer p-2 border-2 border-tmrw-blue-dark rounded-full border-solid"
            >
              <i class="fa fa-times text-tmrw-blue-dark text-xs" />
            </span>
          </div>
        </div>
        <CustomDate
          class="mt-5"
          :noFuture="true"
          :date="date"
          @onValueChange="handleDateChange"
          @dateError="handleDateError"
        />
        <div class="flex w-full mt-4">
          <div class="w-full h-4 text-white">
            <input
              type="checkbox"
              name="applyToAll"
              id="applyToAll"
              :value="applyToAllCryodevices"
              @input="setApplyToAllCryodevices($event)"
              data-test="dateField-apply"
              v-model="applyToAllCryodevices"
            />
            <label
              for="applyToAll"
              data-test="apply-to-all-cryodevices"
              class="ml-2 text-tmrw-blue-dark text-xl font-bold"
              >Apply to all {{ cryoDevices }}</label>
          </div>
        </div>
        <div class="flex justify-end items-center mt-10">
          <button-dynamic
            class="mr-3"
            btnText="Cancel"
            btnType="button"
            btnStyle="secondary"
            @click="hideDateModal"
          />
          <button-dynamic
            btnText="Select"
            btnType="button"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="arrow-circle-right"
            @click="setCryoDate"
            data-test="dateField-select"
            class="disabled:opacity-50"
            :isDisabled="!date || dateErrorMsg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { INVENTORY_ITEM, INVENTORY_ITEM_SINGULAR } from '@/constants'
import CustomDate from '@/components/CustomDate/CustomDate.vue'
import dateTime from '@/helpers/dateAndTime'

dayjs.extend(utc)

export default {
  name: 'date-field',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isDateModalVisible: false,
      date: null,
      applyToAllCryodevices: false,
      formattedDate: this.value,
      isDateValid: true,
      cryoDevice: INVENTORY_ITEM_SINGULAR,
      cryoDevices: INVENTORY_ITEM,
      dateErrorMsg: false
    }
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    tabKey: {
      type: Number,
      required: false,
      default: 0
    },
    edit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['changeValue'],
  created() {
    this.value && this.setFieldsValue(this.value)
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formattedDate = newValue
        this.setFieldsValue(newValue)
      }
    },
    isDateModalVisible(newValue) {
      if (!newValue && this.$refs.containerRef) {
        this.$refs.containerRef.focus()
      }
    }
  },
  methods: {
    handleDateChange(newDate) {
      this.date = newDate
      this.dateErrorMsg = false
    },
    handleDateError(error) {
      this.dateErrorMsg = error
    },
    showDateModal() {
      if (!this.edit) {
        return
      }
      this.isDateModalVisible = true
    },
    hideDateModal() {
      this.isDateModalVisible = false
    },
    setApplyToAllCryodevices(e) {
      this.applyToAllCryodevices = e.target.checked
    },
    setCryoDate() {
      if (!this.date) {
        this.dateValidation = 'All inputs in date are required'
        return
      }
      const dateObj = dayjs.utc(this.date)
      if (dayjs.utc(this.date, 'YYYY-MM-DD', true).isValid()) {
        this.isDateValid = true
        const selectedDate = dateObj.valueOf()
        const dateData = {
          selectedDate,
          applyToAllCryodevices: this.applyToAllCryodevices
        }
        this.formattedDate = dateObj.format('DDMMMYYYY')
        this.$emit('changeValue', dateData)
        this.isDateModalVisible = false
      } else {
        this.isDateValid = false
      }
      this.$forceUpdate()
    },
    setFieldsValue(value) {
      const dateObj = typeof value === 'string' ? dayjs.utc(value, 'YYYY-MM-DD', true) : dayjs.utc(value)
      const dtMonth = dateObj.month() + 1
      const month = `${dtMonth < 10 ? '0' : ''}${dateObj.month() + 1}`
      const day = dateObj.date().toString()
      const year = dateObj.year().toString()
      const formattedDate = dateTime.toBackendFormat({ day, month, year })
      if (formattedDate) {
        this.date = formattedDate
      }
    },
    dateError() {
      let dateValidation = ''
      let futureDateError = false
      if (this.date) {
        const currentDate = dayjs.utc().valueOf()
        const selectedDate = dayjs.utc(this.date).valueOf()
        if (selectedDate > currentDate) {
          futureDateError = true
          dateValidation = 'The Cryodate cannot be in the future'
        }
        if (futureDateError) {
          this.dateValidation = dateValidation
          return
        }
        this.dateValidation = ''
        return
      }
      this.dateValidation = ''
    }
  },
  computed: {
    getFormattedDate() {
      return dayjs.utc(Number(this.value)).format('DDMMMYYYY')
    }
  },
  validations: {
    date: {
      required
    }
  },
  components: {
    ButtonDynamic,
    CustomDate
  }
}
</script>
<style lang="scss" scoped>
div:focus {
  outline: 0;
  @apply bg-tmrw-gray-light;
}
</style>
