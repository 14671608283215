<template>
  <div class="mt-4">
    <p data-test="please-select-option" class="py-4 text-xl text-white leading-6">
      Please select an option:
    </p>
    <div data-test="transfer-out-options" class="flex">
      <div
        v-for="(item, index) in items"
        :key="index"
        :data-test="`transfer-out-option-wrapper-${item.value.toLowerCase()}`"
      >
        <div
          class="text-xl text-tmrw-blue font-bold leading-6 w-72 bg-white p-4 mr-4 cursor-pointer rounded-lg"
          @click="handleClick(item.value)"
        >
          <label :for="item.value" class="flex items-center">
            <input
              :data-test="`transfer-out-option-${item.value.toLowerCase()}`"
              class="mr-4"
              type="radio"
              name="transfer-out"
              :value="item.value"
              :id="item.value"
              v-model="transferOutReason"
              @change="syncData"
            />
            {{ item.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHIPPING, HAND_CARRY, SHIPPING_VALUE, HAND_CARRY_VALUE } from '@/constants'

export default {
  name: 'transfer-out',
  data() {
    return {
      transferOutReason: '',
      items: [
        {
          label: SHIPPING,
          value: SHIPPING_VALUE
        },
        {
          label: HAND_CARRY,
          value: HAND_CARRY_VALUE
        }
      ]
    }
  },
  emits: ['dataSync'],
  methods: {
    syncData() {
      this.$emit('dataSync', { subType: this.transferOutReason })
    },
    isSelected(data) {
      return this.transferOutReason === data
    },
    handleClick(value) {
      this.transferOutReason = value
      this.syncData()
    }
  }
}
</script>
