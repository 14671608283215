export default {
  displayModal(state, value) {
    state.displayModal = value
  },
  modalMessage(state, message) {
    state.modalMessage = message
  },
  modalTitle(state, title) {
    state.modalTitle = title
  }
}
