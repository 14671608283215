export default {
  setCalendarMinDate(state, calendarMinDate) {
    state.calendarMinDate = calendarMinDate
  },
  setCalendarMaxDate(state, calendarMaxDate) {
    state.calendarMaxDate = calendarMaxDate
  },
  setPerformedOnCalendarDate(state, performedOnCalendarDate) {
    state.performedOnCalendarDate = performedOnCalendarDate
  },
  setTicketToUpdate(state, ticketToUpdate) {
    state.ticketToUpdate = ticketToUpdate
  }
}
