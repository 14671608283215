import dayjs from 'dayjs'
import { SPECIMEN_TYPE_EMBRYO, SPECIMEN_TYPE_EGG, SPECIMEN_TYPE_OOCYTE, MAX_LABEL_LENGTH } from '@/constants'
import { trimString } from '@/utils'

import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const SPECIMEN_TYPES = {
  egg: SPECIMEN_TYPE_EGG,
  embryo: SPECIMEN_TYPE_EMBRYO
}



export const HEADERS = [{
  label: () => 'Cryodevice ID',
  value: (item: any) => trimString(item.cryodeviceBarcode, MAX_LABEL_LENGTH, 'start'),
  classes: () => ['w-52']
}, {
  label: () => 'Count x Type',
  value: (item: any) => {
    if(item.specimenType === SPECIMEN_TYPE_EMBRYO) {
      return `${item.specimenCount} x ${item.embryo?.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    } else {
      return `${item.specimenCount} x ${item.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE }`
    }
  },
  classes: () => []
}, {
  label: () => 'CryoDate',
  value: (item: any) => dayjs.utc(item.cryoDate * 1).format('DDMMMYYYY').toUpperCase(),
  classes: () => []
},
{
  label: (item: any) => item.specimenType === SPECIMEN_TYPE_EMBRYO ? 'Embryo #' : '',
  value: (item: any) => {
    if(item.specimenType === SPECIMEN_TYPE_EMBRYO ) {
      return item.embryo?.embryoNumber || '-'
    } else {
      return ''
    }
  },
  classes: () => ['w-24']
},
{
  label: (item: any) => item.specimenType === SPECIMEN_TYPE_EMBRYO ? 'Grade' : '',
  value: (item: any) => {
    if(item.specimenType === SPECIMEN_TYPE_EMBRYO ) {
      return item.embryo?.grade || '-'
    } else {
      return ''
    }
  },
  classes: () => ['w-24']
}
]

