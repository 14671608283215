import {
  ICON,
  REMOVE_BUTTON,
  RADIO_BUTTON
} from '@/constants/scroll-table-fields'
import {
  PRIMARY_PATIENT,
  GLOBAL_PATIENT_NUMBER,
  REMOVE_PATIENT,
  CANT_EDIT_PRIMARY_PATIENT
} from '@/constants'
import { tableHeaderPatients } from '../table-headers/patient'

type PrimaryPatientColumnProps = {
  changeable: boolean
  primaryPatientId: number
}

const linkHeader = {
  name: '',
  key: 'seeMore',
  cssClasses: ['w-16'],
  component: 'link',
  alignment: 'left',
  forceEnabled: true
}

const primaryPatientIcon = (primaryPatientId: number) => ({
  name: PRIMARY_PATIENT,
  cssClasses: ['w-32'],
  alignment: 'center',
  key: 'globalPatientNumber',
  component: ICON,
  content: (item: any) => ({
    iconClass: 'far fa-star',
    tooltip: CANT_EDIT_PRIMARY_PATIENT,
    hidden: item.globalPatientNumber !== primaryPatientId
  })
})

const primaryPatientSwitcher = (primaryPatientId: number) => ({
  name: PRIMARY_PATIENT,
  key: GLOBAL_PATIENT_NUMBER,
  cssClasses: ['w-32'],
  component: RADIO_BUTTON,

  // Will be used to check if radio button is selected
  value: primaryPatientId,
  componentOptions: {
    // Prevents globalPatientNumber to be replaced by primaryPatientId in the row
    immutableKey: true
  }
})

export const primaryPatientColumn = (props: PrimaryPatientColumnProps) => {
  const { changeable, primaryPatientId } = props
  if (changeable) {
    return primaryPatientSwitcher(primaryPatientId)
  }
  return primaryPatientIcon(primaryPatientId)
}

export const removePatientColumn = ({
  changeable,
  primaryPatientId
}: PrimaryPatientColumnProps) => ({
  name: '',
  key: GLOBAL_PATIENT_NUMBER,
  cssClasses: ['w-16'],
  component: REMOVE_BUTTON,
  componentOptions: {
    label: 'Remove',
    title: REMOVE_PATIENT,
    shouldHide: (item: any) =>
      !changeable && item.globalPatientNumber === primaryPatientId
  }
})

type PatientGroupHeaderProps = PrimaryPatientColumnProps & {
  canRemovePatient: boolean
  showViewPatientLink: boolean
}

const headers = ({
  changeable,
  canRemovePatient,
  primaryPatientId,
  showViewPatientLink
}: PatientGroupHeaderProps) => {
  const props: PrimaryPatientColumnProps = {
    changeable,
    primaryPatientId
  }
  const primaryPatient = primaryPatientColumn(props)
  // TODO: Change type of header object (create new ScrollTableColumn type)
  const base: any[] = [...tableHeaderPatients, primaryPatient]
  if (showViewPatientLink) {
    base.push(linkHeader)
  }
  if (canRemovePatient) {
    base.push(removePatientColumn(props))
  }
  return base
}
export default headers
