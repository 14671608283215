import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  CRYOROBOT,
  SCREENING_STATUSES,
  SPECIMEN_TYPE_EMBRYOS,
  SPECIMEN_TYPE_OOCYTES,
  REMAINING_IN_CRYOBEACON,
  LAYOUT_CRS
} from '@/constants/index'
import { LOGO_IVFOS } from '@/assets/pdf/logos-base64'
import {
  selectedTicketsParamsType,
  staticTypes,
  ticketType,
  externalsClinicsType,
  selectedTicketSpecimensHeadersType
} from '@/helpers/printPDF/types/index'
import { cryoBeaconText, shippingSourceText, textElipsis } from '../helpers/index'

dayjs.extend(utc)

/** *************************
 * Static Headers
 ************************** */

const TMRWlogo = () => [
  {
    image: LOGO_IVFOS,
    width: 132.54,
    heigth: 63.43,
    margin: [0, 63, 0, 24]
  },
  { text: '', margin: [0, 0, 0, 0] }
]

const ticketHeaderDescription = (tableParams: {
  ticket: ticketType
  config: staticTypes['config']
  params: selectedTicketsParamsType
  externalClinics?: externalsClinicsType
}) => [
  {
    text: `${tableParams.ticket.procedureName} for ${tableParams.ticket.patientName || '--'}`,
    alignment: 'left',
    fontSize: 12,
    margin: [0, 0, 0, 3.5],
    bold: true
  },
  {
    text: `ID ${tableParams.ticket.identificationNumber || '--'} - DOB: ${
      dayjs(tableParams.ticket.patientDob).format('DDMMMYYYY').toUpperCase() || '--'
    }`,
    alignment: 'left',
    fontSize: 12,
    margin: [0, 0, 0, 3.5],
    bold: true
  },
  {
    text: `${CRYOROBOT} ${tableParams.ticket.robotLocations[0].robotNumber || '--'}${
      tableParams.ticket.robotLocations[0].robotLayoutTypeId === LAYOUT_CRS
        ? ''
        : `| Rack ${tableParams.ticket.robotLocations[0].rack || '--'} | Shelf ${
            tableParams.ticket.robotLocations[0].shelf
          }`
    } | Position ${tableParams.ticket.robotLocations[0].row || '--'}${
      tableParams.ticket.robotLocations[0].column || '--'
    }`,
    alignment: 'left',
    fontSize: 9,
    margin: [0, 0, 0, 15]
  }
]

const clinicHeaderDescription = (tableParams: {
  ticket: ticketType
  config: staticTypes['config']
  params: selectedTicketsParamsType
  externalClinics?: externalsClinicsType
}) => [
  {
    text: `${dayjs(tableParams.ticket.procedureTime).format('DDMMMYYYY')}  ${dayjs(
      tableParams.ticket.procedureTime
    ).format('hh:mm A')}`,
    alignment: 'right',
    fontSize: 9,
    margin: [0, 0, 0, 3.5],
    bold: true
  },
  {
    text: cryoBeaconText(tableParams.ticket),
    alignment: 'right',
    fontSize: 9,
    margin: [0, 0, 0, 3.5],
    bold: true
  },
  {
    text: `${tableParams.config.clinicName} | ${tableParams.config.siteName} | Clinic ID ${tableParams.config.siteId}`,
    alignment: 'right',
    fontSize: 9,
    margin: [0, 0, 0, 3.5],
    bold: true
  },
  {
    text: `Ticket Number: ${tableParams.ticket.ticketId}`,
    alignment: 'right',
    fontSize: 9,
    margin: [0, 0, 0, 15],
    bold: true
  }
]

const infectionScreeningAndShippingSourceHeader = (tableParams: {
  ticket: ticketType
  config: staticTypes['config']
  params: selectedTicketsParamsType
  externalClinics?: externalsClinicsType
}) => [
  {
    table: {
      widths: [725],
      body: [
        [
          {
            // @ts-ignore
            text: `Infectious Screening Status: ${
              SCREENING_STATUSES[tableParams.ticket.screeningStatus]
            }   ${shippingSourceText(
              // @ts-ignore
              tableParams.ticket.specimens[0]?.sources,
              // @ts-ignore
              tableParams.params.externalClinics
            )}`,
            alignment: 'left',
            fontSize: 9,
            margin: [-3, 15, 0, 0],
            border: [false, true, false, true]
          }
        ]
      ]
    }
  },
  ''
]

const bodyHeaders: object[] = [
  {
    text: 'Date & Time',
    style: 'tableHeader'
  },
  {
    text: 'Patient Name',
    style: 'tableHeader'
  },
  {
    text: 'Identification #',
    style: 'tableHeader'
  },
  {
    text: 'Date of Birth',
    style: 'tableHeader'
  },
  {
    text: 'Ticket #',
    style: 'tableHeader'
  },
  {
    text: 'Origin',
    style: 'tableHeader'
  },
  {
    text: 'Destination',
    style: 'tableHeader'
  },
  {
    text: 'Procedure',
    style: 'tableHeader'
  }
]

const bodyHeadersCryoShipperTop: object[] = [
  {
    text: 'Date & Time',
    style: 'tableHeader'
  },
  {
    text: 'Patient Name',
    style: 'tableHeader'
  },
  {
    text: 'Ticket #',
    style: 'tableHeader'
  },
  {
    text: 'Date of Birth',
    style: 'tableHeader'
  },
  {
    text: 'Origin',
    style: 'tableHeader'
  },
  {
    text: 'Destination',
    style: 'tableHeader'
  },
  {
    text: 'Non-Tmrw Location Notes',
    style: 'tableHeader'
  }
]
const bodyHeadersCryoShipperBottom: object[] = [
  {
    text: 'Date & Time',
    style: 'tableHeader'
  },
  {
    text: 'Patient Name',
    style: 'tableHeader'
  },
  {
    text: 'Ticket #',
    style: 'tableHeader'
  },
  {
    text: 'Date of Birth',
    style: 'tableHeader'
  },
  {
    text: 'Origin',
    style: 'tableHeader'
  },
  {
    text: 'Destination',
    style: 'tableHeader'
  },
  {
    text: 'Non-Tmrw Location Notes',
    style: 'tableHeader'
  }
]

const embryoHeaders = (defaultLeftMargin = -5) => [
  {
    table: {
      widths: [83, 95, 59, 46, 71, 61, 124, 124], // w: 663
      heights: [10],
      border: [false, false, false, true],
      body: [
        [
          {
            text: 'Cryodevice ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Count x Type',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Embryo #',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Grade',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Cryodate',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Biopsy',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Results',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Notes',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          }
        ]
      ]
    }
  },
  ''
]

const embryoSingleImportHeaders = (defaultLeftMargin = -5) => [
  {
    table: {
      widths: [60, 72, 36, 23, 50, 39, 91, 91, 91, 91],
      heights: [10],
      border: [false, false, false, true],
      body: [
        [
          {
            text: 'Cryodevice ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Count x Type',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Embryo #',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Grade',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Cryodate',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Biopsy',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Results',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Notes',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Donor ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Current Label ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          }
        ]
      ]
    }
  },
  ''
]

const oocyteHeaders = (defaultLeftMargin = -5) => [
  {
    table: {
      widths: [83, 180, 76, 360], // w: 663
      heights: [10],
      border: [false, false, false, true],
      body: [
        [
          {
            text: 'Cryodevice ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Count x Type',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Cryodate',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Notes',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          }
        ]
      ]
    }
  },
  ''
]

const oocyteSingleImportHeaders = (defaultLeftMargin = -5) => [
  {
    table: {
      widths: [83, 95, 95, 56, 95, 257], // w: 663
      heights: [10],
      border: [false, false, false, true],
      body: [
        [
          {
            text: 'Cryodevice ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Count x Type',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Cryodate',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Current Label ID',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          },
          {
            text: 'Notes',
            border: [false, false, false, true],
            fontSize: 9,
            margin: [defaultLeftMargin, 0, 0, 0]
          }
        ]
      ]
    }
  },
  ''
]

const embryoDataHeaders = ({
  cryodeviceId,
  ticket: { specimens },
  specimenNumber,
  cryoDateMilisValue
}: selectedTicketSpecimensHeadersType) => [
  {
    table: {
      widths: [83, 95, 59, 46, 71, 61, 124, 124],
      heights: [20],
      body: [
        [
          {
            text: `${cryodeviceId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenCount} x D${
              specimens[specimenNumber]?.embryo?.embryoType || ''
            }`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.embryoNumber || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.grade || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase() || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.biopsy || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            // @ts-ignore
            text: `${textElipsis(specimens[specimenNumber].embryo?.biopsyResult, 23) || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${textElipsis(specimens[specimenNumber].specimenInfo, 23) || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          }
        ]
      ]
    }
  }
]

const embryoSingleImportDataHeaders = ({
  cryodeviceId,
  ticket: { specimens },
  specimenNumber,
  cryoDateMilisValue
}: selectedTicketSpecimensHeadersType) => [
  {
    table: {
      widths: [60, 72, 36, 23, 50, 39, 95.75, 95.75, 95.75, 95.75],
      body: [
        [
          {
            text: `${cryodeviceId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenCount} x D${
              specimens[specimenNumber]?.embryo?.embryoType || ''
            }`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.embryoNumber || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.grade || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase() || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.biopsy || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].embryo?.biopsyResult || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenInfo || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber]?.sources[0]?.donorId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber]?.currentLabelId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          }
        ]
      ]
    }
  }
]

const oocyteDataHeaders = ({
  cryodeviceId,
  ticket: { specimens },
  specimenNumber,
  cryoDateMilisValue
}: selectedTicketSpecimensHeadersType) => [
  {
    table: {
      widths: [83, 180, 76, 360], // w: 663
      heights: [20],
      body: [
        [
          {
            text: `${cryodeviceId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenCount} x ${
              specimens[specimenNumber]?.oocyte?.maturityLevel || ''
            }`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase() || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenInfo || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          }
        ]
      ]
    }
  }
]

const oocyteSingleImportDataHeaders = ({
  cryodeviceId,
  ticket: { specimens },
  specimenNumber,
  cryoDateMilisValue
}: selectedTicketSpecimensHeadersType) => [
  {
    table: {
      widths: [83, 95, 95, 56, 95, 257], // w: 663
      heights: [20],
      body: [
        [
          {
            text: `${cryodeviceId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenCount} x ${
              specimens[specimenNumber]?.oocyte?.maturityLevel || ''
            }`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase() || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber]?.currentLabelId || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          },
          {
            text: `${specimens[specimenNumber].specimenInfo || '--'}`,
            fontSize: 9,
            border: [false, false, false, false]
          }
        ]
      ]
    }
  }
]

const embryosRemainingInCryoBeacon = () => [
  {
    text: SPECIMEN_TYPE_EMBRYOS,
    margin: [5, 0, 0, 0],
    bold: true,
    border: [false, false, false, true]
  },
  ''
]

const oocytesRemainingInCryoBeacon = () => [
  {
    text: SPECIMEN_TYPE_OOCYTES,
    margin: [5, 0, 0, 0],
    bold: true,
    border: [false, false, false, true]
  },
  ''
]

const remainingInCryobeconText = () => [
  {
    text: REMAINING_IN_CRYOBEACON,
    margin: [5, 10, 0, 15],
    bold: true
  },
  ''
]

export {
  bodyHeaders,
  bodyHeadersCryoShipperTop,
  bodyHeadersCryoShipperBottom,
  embryoHeaders,
  TMRWlogo,
  ticketHeaderDescription,
  clinicHeaderDescription,
  infectionScreeningAndShippingSourceHeader,
  embryoDataHeaders,
  embryoSingleImportHeaders,
  embryoSingleImportDataHeaders,
  oocyteHeaders,
  oocyteDataHeaders,
  oocyteSingleImportHeaders,
  oocyteSingleImportDataHeaders,
  embryosRemainingInCryoBeacon,
  oocytesRemainingInCryoBeacon,
  remainingInCryobeconText
}
