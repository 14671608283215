<template>
  <div class="mt-4">
    <p data-test="please-select-option" class="py-4 text-xl text-white leading-6">
      Please select an option:
    </p>
    <div data-test="donation-options" class="flex">
      <div v-for="(item, index) in items" :key="index">
        <div
          class="text-xl text-tmrw-blue font-bold leading-6 w-64 bg-white p-4 mr-4 cursor-pointer rounded-lg"
          :class="[{ 'cursor-pointer': !item.disabled }, { 'text-opacity-50': item.disabled }]"
          @click="handleClick(item)"
        >
          <label :for="item.value" class="flex items-center">
            <input
              :data-test="`donation-option-${item.value.toLowerCase()}`"
              class="mr-4"
              type="radio"
              name="donation"
              :value="item.value"
              :id="item.value"
              v-model="donateReason"
              :disabled="item.disabled"
              @change="syncData"
            />
            {{ item.label }}
          </label>
        </div>
      </div>
    </div>
    <SearchPatient v-if="isDonateToPatient()" class="mt-12" @syncData="syncData" />
  </div>
</template>

<script setup>
import {
  DONATION_TO_RESEARCH,
  DONATE_TO_PATIENT,
  DONATE_TO_RESEARCH_VALUE,
  DONATE_TO_PATIENT_VALUE
} from '@/constants'
import SearchPatient from '@/components/SearchPatient/SearchPatient.vue'
import { computed, ref } from 'vue'
import {
  isFeatureEnabled,
  DONATE_TO_PATIENT_ENABLED_FF,
  DONATE_TO_RESEARCH_ENABLED_FF
} from '@/helpers/featureFlags'

const emit = defineEmits(['dataSync'])
const donateReason = ref('')
const items = computed(() => {
  const _items = []
  if (isFeatureEnabled(DONATE_TO_RESEARCH_ENABLED_FF)) {
    _items.push({
      label: DONATION_TO_RESEARCH,
      value: DONATE_TO_RESEARCH_VALUE,
      disabled: false
    })
  }
  if (isFeatureEnabled(DONATE_TO_PATIENT_ENABLED_FF)) {
    _items.push({
      label: DONATE_TO_PATIENT,
      value: DONATE_TO_PATIENT_VALUE,
      disabled: false
    })
  }
  return _items
})

const handleClick = (item) => {
  if (!item.disabled) {
    donateReason.value = item.value
    syncData()
  }
}

const syncData = () => {
  emit('dataSync', { subType: donateReason.value })
}
const isDonateToPatient = () => {
  return donateReason.value === DONATE_TO_PATIENT_VALUE
}
</script>
