<template>
  <TicketCardDetail
    :title="title"
    :patient-data="patientData"
    :shared-patient-ids="ticket.sharedPatientIds"
    :isErasable="isErasable"
    @onEditSpecimens="onClickEditSpecimens"
    :deleteButton="deleteButton"
    :isExport="isExport"
    :isEMRTicket="isEMRTicket"
    :ticketId="ticketId"
    :refTicketId="refTicketID"
    data-test="ticket-to-print__ticket-card"
  >
    <template v-slot:list>
      <TicketListDetail
        :beaconId="beaconId"
        :date="date"
        :time="time"
        :headers="specimensList.headers || []"
        :specimen-data="specimensList.data || []"
        :title="specimensList.title"
        :state="ticket.state"
        :isEMRTicket="isEMRTicket"
        :ticketProcedureType="ticketProcedureType"
        :newTicketData="getTicketData"
        :robotLocations="getTicketData?.robotLocations || []"
        :robotTransfer="getTicketData?.robotTransfer || ''"
        :screeningStatus="screeningStatus"
        :ticketType="ticketType"
        :ticket="getTicketData"
        :showTicketActionButtons="false"
        :destinationLocation="destinationLocation"
        :sourceLocation="sourceLocation"
        :ticketId="ticketId"
      />
    </template>
  </TicketCardDetail>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import TicketCardDetail from '@/components/TicketCardDetail/TicketCardDetail.vue'
import TicketListDetail from '@/components/TicketListDetail/TicketListDetail.vue'
import {
  typeEmbryo,
  SINGLE_IMPORT_CONST,
  SINGLE_IMPORT,
  DONATE_TO_PATIENT_VALUE,
  METHOD_EXPORT_UPPER,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  METHOD_DONATE,
  METHOD_THAW,
  METHOD_EXPORT
} from '@/constants'
import * as tableHeadersConstants from '@/constants/table-headers/ticket'
import { SortByItemParam } from '@/factories/index'
import * as ss from '@/config/session-storage-help'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'

export default {
  name: 'ticket-to-finish',
  data() {
    return {
      isBatchTicket: false,
      isThawTicket: false,
      isExportTicket: false
    }
  },
  props: {
    ticket: {
      required: false
    },
    newTicketData: {
      type: Object,
      required: false
    },
    ticketId: {
      type: [String, Boolean],
      required: false
    },
    selectedTicketIndex: {
      type: Number,
      required: false
    },
    destinationLocation: {
      type: Object,
      required: false
    },
    sourceLocation: {
      type: Object,
      required: false
    }
  },
  created() {
    this.patient = this.selectedPatient || ss.getFieldSessionStorage('newTicket', 'patient')
    /*
     * On export we're not adding a specimen type till this moment
     * this call is identifying the speciment type on the first cryodevice
     * */
    if (this.getTicketData.specimenType === null) {
      // eslint-disable-next-line vue/no-mutating-props
      this.getTicketData.specimenType = this.getTicketData.cryoDevice[0].specimenType
    }

    const newTicket = ss.getFieldSessionStorage('newTicket')
    let newBeaconsArray = []
    if (ss.getFieldSessionStorage('newBeaconsArray')) {
      newBeaconsArray = JSON.parse(ss.getFieldSessionStorage('newBeaconsArray'))
    }
    newTicket.beacons.forEach((beacon, index) => {
      beacon.cryoDevice.forEach((cd) => {
        // eslint-disable-next-line no-param-reassign
        cd.beaconBarcode = newBeaconsArray[index]
      })
    })
    ss.setFieldSessionStorage('newTicket', newTicket)
    this.isBatchTicket =
      newTicket.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE ||
      newTicket.method === PROCEDURE_TYPE_BATCH_UPPERCASE
    this.isThawTicket = newTicket.selectedMethod === METHOD_THAW
    this.isExportTicket = newTicket.selectedMethod === METHOD_EXPORT
    if (this.isBatchTicket) this.setBatchProcedureDate()
  },
  computed: {
    ...mapGetters('newTicketModule', ['selectedPatient']),
    ...mapGetters('patientGroupModule', ['primaryPatient']),
    title() {
      if (this.getTicketData.procedureType === METHOD_DONATE) {
        return 'Export for Donation'
      }
      if (this.getTicketData.procedureType !== PROCEDURE_TYPE_BATCH_UPPERCASE) {
        // Note: prioritize procedureName if exists (it should after ticket creation)
        return (
          this.getTicketData.procedureName ||
          `${
            this.getTicketData.specimenType === typeEmbryo.egg
              ? typeEmbryo.replace
              : this.getTicketData.specimenType
          } ${
            this.getTicketData.procedureType === SINGLE_IMPORT_CONST
              ? SINGLE_IMPORT
              : this.getTicketData.procedureType
          }`
        )
      }
      // getting cache data from localstorage
      const batchCache = ss.getFieldSessionStorage('batch', 'cache')
      // sorting data
      const sortProcedure = new SortByItemParam()
      const response = sortProcedure.sortByFlag(batchCache, this.getTicketData, 'procedureName')
      return response
    },
    refTicketID() {
      if (this.isBatchTicket) {
        // getting cache data from localstorage
        const batchCache = ss.getFieldSessionStorage('batch', 'cache')
        // sorting data
        const sortProcedure = new SortByItemParam()
        const response = sortProcedure.sortByFlag(batchCache, this.getTicketData, 'ticketId')
        return response
      }
      return false
    },
    beaconId() {
      if (this.getTicketData.cryoDevice?.length > 0) {
        const beaconId =
          this.getTicketData.cryoDevice[0].beaconBarcode ||
          this.getTicketData.cryoDevice[0].cryodeviceBarcode

        return beaconId
      }

      return ''
    },
    date() {
      this.isBatch()
      return (
        this.getTicketData.visit?.procedureDateFrom &&
        dayjs(this.getTicketData.visit.procedureDateFrom).format('DDMMMYYYY')
      )
    },
    time() {
      this.isBatch()
      return (
        this.getTicketData.visit?.procedureDateFrom &&
        dayjs(this.getTicketData.visit.procedureDateFrom).format('hh:mm A')
      )
    },
    thereAreSpecimens() {
      return !!(this.getTicketData.cryoDevice?.length > 0)
    },
    tableHeaders() {
      let ticketSrc = ''
      switch (this.getTicketData.procedureType) {
        case PROCEDURE_TYPE_BATCH_UPPERCASE:
          ticketSrc = this.ticket.cryoDevice[0]
          break
        default:
          ticketSrc = this.getTicketData
          break
      }
      let { specimenType } = ticketSrc
      const currentSpecimenType = specimenType || this.getTicketData.cryoDevice[0].specimenType

      if (currentSpecimenType && this.getTicketData.procedureType === SINGLE_IMPORT_CONST) {
        specimenType = currentSpecimenType.toLowerCase()
        return tableHeadersConstants.specimenImportHeaders(specimenType)
      }
      if (currentSpecimenType) {
        specimenType = currentSpecimenType.toLowerCase()
        return tableHeadersConstants[specimenType]
      }
      return []
    },
    specimensList() {
      return (
        this.thereAreSpecimens && {
          title: `${this.getTicketData.cryoDevice.length} Cryodevice${
            this.getTicketData.cryoDevice.length > 1 ? 's' : ''
          }`,
          headers: this.tableHeaders,
          data: this.getTicketData.cryoDevice
        }
      )
    },
    isExport() {
      const newTicket = ss.getFieldSessionStorage('newTicket', 'metadata')
      const isExportUpperType = this.getTicketData.procedureType === METHOD_EXPORT_UPPER

      if (isExportUpperType) {
        const newTicketExportDetails = {
          display: true,
          type: newTicket.exportReason.type,
          subType: newTicket.exportReason.details.subType
        }
        return Object.freeze(newTicketExportDetails)
      }

      return Object.freeze({ display: false })
    },
    isDonateToPatient() {
      const { method, selectedMethod } = ss.getFieldSessionStorage('newTicket')
      if (method === METHOD_DONATE || selectedMethod === METHOD_DONATE) {
        const { subType } = this.getTicketData.metadata.exportReason.details
        return subType === DONATE_TO_PATIENT_VALUE
      }
      return false
    },
    screeningStatus() {
      if (this.getTicketData?.screeningStatus) {
        return this.getTicketData.screeningStatus
      }
      if (this.getTicketData.cryoDevice?.length) {
        return this.getTicketData.cryoDevice[0].screeningStatus
      }
      return ''
    },
    patientData() {
      const isMultiplePatientsEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
      // Will display the patient for the ticket
      // In case of multiple patients it must show only the primary patient
      // Does not apply for batch or relocation tickets
      // TODO: This can be improved by better cleanup of state after ticket was created
      if (
        isMultiplePatientsEnabled && // If the clinic has enabled multiple patient feature
        !!this.primaryPatient && // Checks if there is an existing primary patient
        !this.isThawTicket &&
        !this.isExportTicket
        // Note: With the improved cleanup, we should not need to validate the ticket type here
        // The data should be cleaned and contain only the data related to ticket to be created.
      ) {
        return this.primaryPatient
      }
      // Otherwise just show the patient related to this ticket
      return this.getTicketData?.patient
    },
    isEMRTicket() {
      // getting cache data from localstorage
      const batchCache = ss.getFieldSessionStorage('batch')
        ? ss.getFieldSessionStorage('batch', 'cache')
        : []
      // sorting data
      const sortProcedure = new SortByItemParam()
      // getting ticket source
      const response = sortProcedure.sortByFlag(batchCache, this.getTicketData, 'source')
      // if response === 2 means is a EMR ticket
      return response === 2
    },
    getTicketData() {
      if (this.newTicketData && this.ticketId) {
        return { ...this.ticket, ...this.newTicketData }
      }

      return this.ticket
    }
  },
  methods: {
    setBatchProcedureDate() {
      /**
       * @constant batchCache
       * getting batchCache data
       * @constant firstSpecimenInCriodevice
       * it's used for getting the first specimen in the cryodevice in order to get an static value to be filtered against batchCache
       * @function batchCache.map
       * mapping over the different batch tickets
       * @constant batchTicket
       * single batchTicket to be filtered
       * @function batchTicket.specimens.filter
       * mapping over the cryodevice specimens
       * @return
       * we're overwriting this.ticket.visit.procedureDateFrom as the correct data is not coming inside the this.ticket response
       */
      const batchCache = ss.getFieldSessionStorage('batch', 'cache')
      const firstSpecimenInCriodevice = this.getTicketData?.cryoDevice[0]?.cryodeviceBarcode
      batchCache.map((batchTicket) =>
        batchTicket?.specimens?.filter((specimen) => {
          if (specimen?.cryodeviceBarcode === firstSpecimenInCriodevice) {
            // eslint-disable-next-line vue/no-mutating-props
            this.getTicketData.visit.procedureDateFrom = batchTicket.procedureTime
            return this.getTicketData.visit.procedureDateFrom
          }
          return true
        })
      )
    },
    isBatch() {
      const newTicket = ss.getFieldSessionStorage('newTicket')
      if (
        newTicket?.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE ||
        newTicket?.method === PROCEDURE_TYPE_BATCH_UPPERCASE
      )
        this.setBatchProcedureDate()
      return this.getTicketData
    }
  },
  components: {
    TicketCardDetail,
    TicketListDetail
  }
}
</script>
