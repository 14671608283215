export default {
  ticketStep: (state) => state.ticketStep,
  method: (state) => state.method,
  type: (state) => state.type,
  typeEmbryo: (state) => state.typeEmbryo,
  newTicketPatient: (state) => state.patient,
  newTicketError: (state) => state.error,
  newTicketstatus: (state) => state.ticketCompletionStatus,

  procedureDateFrom: (state) => state.procedureDateFrom,
  procedureDateTo: (state) => state.procedureDateTo,
  procedureTime: (state) => state.procedureTime,

  beacons: (state) => state.beacons,
  selectedMethod: (state) => state.selectedMethod,
  currentTicketStep: (state) => state.currentTicketStep,
  selectedCryoDeviceType: (state) => state.selectedCryoDeviceType,
  selectedSpecimenType: (state) => state.selectedSpecimenType,
  selectedPatient: (state) => state.selectedPatient,
  source: (state) => state.source,
  metadata: (state) => state.metadata,
  robotTransfer: (state) => state.robotTransfer,
  shippingSourceId: (state) => state.shippingSourceId,
  donorId: (state) => state.donorId,
  selectedTypeEmbryo: (state) => state.selectedTypeEmbryo,
  subType: (state) => state.subType,
  batch: (state) => state.batch,
  batchSharedPatientIds: (state) => state.batchSharedPatientIds
}
