<!-- eslint-disable vue/no-mutating-props -->
<template>
  <vue-final-modal
    class="bg-black bg-opacity-50"
    name="modal-multiple-robots"
    height="auto"
    width="430"
    :modelValue="show"
    classes="modal--container"
    content-class="modal--content"
    :content-style="{ height: 'auto', width: '430px' }"
  >
    <div class="bg-tmrw-blue h-100 py-6 px-5">
      <div class="my-4">
        <p class="text-xl text-white leading-6 font-medium" v-if="robots">
          The {{ CryoBeacon }} located on {{ CryoRobot }} {{ robots[0] }} will need to be
          transported to {{ CryoRobot }} {{ robots[1] }}.
        </p>
        <p class="text-xl text-white leading-6 font-medium my-6">
          Please be ready with the necessary equipment to transport the {{ CryoBeacon }}.
        </p>
      </div>
      <div class="flex align-center justify-end">
        <button-dynamic
          btnText="Ok"
          btnType="button"
          btnStyle="primary"
          :btnPixelsWidth="'87px'"
          @click="closeModal"
          :addClasses="['btn btn--warning btn--bold text-xl leading-6']"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { CRYOBEACON, CRYOROBOT } from '@/constants'

export default {
  components: {
    ButtonDynamic
  },
  name: 'modal-multiple-robots',
  methods: {
    closeModal() {
      return this.$emit('onHideModal')
    }
  },
  emits: ['onHideModal'],
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    robots: {
      type: [Array],
      required: false
    }
  },
  data() {
    return {
      CryoBeacon: CRYOBEACON,
      CryoRobot: CRYOROBOT
    }
  }
}
</script>
