<template>
  <div>
    <!-- Needed in order to compute work -->
    <div class="hidden">
      {{ value }}
    </div>
    <div class="grid grid-cols-3 gap-2">
      <InputField
        addPaddingBottom
        borderColor="tmrw-blue"
        data-test="custom-date__day"
        dontDisplayErrors
        inputName="DD"
        inputPlaceholder="DD"
        labelColor="tmrw-blue-dark"
        placeholderColor="tmrw-blue-dark"
        v-model.trim="v$.day.$model"
        :inputValue="v$.day.$model"
        hasFontInconsolata
        has-large-padding
        ref="dayRef"
        right-icon="edit-regular"
        @focus="setFocusComponent(DAY)"
        @blur="setFocusComponent(undefined)"
      />
      <SelectField
        borderColor="tmrw-blue"
        data-test="custom-date__month"
        data-testid="custom-date__month"
        defaultOptionLabel="MMM"
        dontDisplayErrors
        name="month"
        labelColor="tmrw-blue-dark"
        placeholderColor="tmrw-blue-dark"
        v-model="v$.month.$model"
        :inputValue="v$.month.$model"
        :options="monthOptions"
        hasFontInconsolata
      />
      <InputField
        addPaddingBottom
        borderColor="tmrw-blue"
        data-test="custom-date__year"
        dontDisplayErrors
        inputName="YYYY"
        inputPlaceholder="YYYY"
        labelColor="tmrw-blue-dark"
        placeholderColor="tmrw-blue-dark"
        v-model.trim="v$.year.$model"
        :inputValue="v$.year.$model"
        hasFontInconsolata
        has-large-padding
        right-icon="edit-regular"
        @focus="setFocusComponent(YEAR)"
        @blur="setFocusComponent(undefined)"
      />
    </div>
    <div
      class="text-sm text-left flex text-tmrw-error"
      data-test="custom-date__error-msg"
      v-if="dateErrorMsgs.length > 0"
    >
      <p v-for="dateErrorMsg in dateErrorMsgs" :key="dateErrorMsg">{{ dateErrorMsg }}</p>
    </div>
  </div>
</template>

<script>
import {
  required, maxLength, numeric
} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import dayjs from 'dayjs'
import {
  DATE_CANNOT_BE_IN_FUTURE,
  ENTER_VALID_DATE,
  ENTER_VALID_DAY,
  ENTER_VALID_YEAR,
  MIN_LENGTH_INPUT_MESSAGE,
  MONTH_OPTIONS,
  DAY,
  MONTH,
  YEAR
} from '@/constants'
import dateHelper from '@/helpers/dateAndTime'
import InputField from '@/components/InputField/InputField.vue'
import SelectField from '@/components/CustomSelect/SelectField.vue'
import { capitalize } from '@/helpers/stringHelper'
import { validateFields } from '@/helpers/formsFieldsValidation/index'

export default {
  name: 'custom-date',
  components: {
    InputField,
    SelectField
  },
  emits: ['dateError', 'onValueChange'],
  props: {
    date: {
      type: String,
      required: false,
      default: null
    },
    noFuture: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.setFocusComponent(DAY)
    if(this.$refs.dayRef?.$refs.DD) {
      this.$refs.dayRef.$refs.DD.focus()
    }

  },
  created() {
    if(this.date) {
      this.setupDate(this.date)
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  watch: {
    date(newValue) {
      if(newValue) {
        this.setupDate(newValue)
      }
    }
  },
  methods: {
    setupDate(date) {
      const dateObj = dayjs(date)
      if (dateObj.isValid()) {
        const [year, month, day] = date.split('-')
        this.month = month
        this.day = day
        this.year = year
      }
    },
    formatErrorTo(field) {
      if (this.errorMsg[field].includes('This field')) {
        return this.errorMsg[field].replace('This field', capitalize(field))
      }
      return `${this.errorMsg[field]} for ${field}`
    },
    setFocusComponent(component) {
      this.componentOnFocus = component
    },
    hasStandardError(field) {
      if (this.errors && this.errorMsg[field]) {
        return true
      }

      return false
    }
  },
  computed: {
    isDayFocused() {
      return this.componentOnFocus === DAY
    },
    isYearFocused() {
      return this.componentOnFocus === YEAR
    },
    dayErrorMessage() {
      if (this.isDayFocused) return undefined
      if (this.hasStandardError(DAY)) return this.formatErrorTo(DAY)
      if (!this.v$.day.validDay) return ENTER_VALID_DAY
      if (this.day?.length < 2) return MIN_LENGTH_INPUT_MESSAGE(DAY, 2)
      return undefined
    },
    monthErrorMessage() {
      if (this.hasStandardError(MONTH)) {
        return this.formatErrorTo('month')
      }
      return undefined
    },
    yearErrorMessage() {
      if (this.isYearFocused) return undefined
      if (this.hasStandardError(YEAR)) return this.formatErrorTo(YEAR)
      if (!this.v$.year.validYear) return ENTER_VALID_YEAR
      if (!this.isYearFocused && this.year?.length < 4) return MIN_LENGTH_INPUT_MESSAGE(YEAR, 4)
      return undefined
    },
    dateErrorMsgs() {
      const errors = []
      if (this.dayErrorMessage) errors.push(this.dayErrorMessage)
      if (this.monthErrorMessage) errors.push(this.monthErrorMessage)
      if (this.yearErrorMessage) errors.push(this.yearErrorMessage)

      if (errors.length > 0) return errors

      const isAllTouched = this.v$.day.$model && this.v$.month.$model && this.v$.year.$model
      const formattedDate = dateHelper.toBackendFormat(this.fullDate)
      const isYearComplete = this.year?.length === 4
      if (isAllTouched && isYearComplete && !this.isDayFocused && !formattedDate
      ) {
        return [ENTER_VALID_DATE]
      }

      if (this.noFuture && formattedDate) {
        const currentDate = dayjs().valueOf()
        const selectedDate = dayjs(formattedDate).valueOf()
        // can't be before 1 Jan 1901
        const minDate = dayjs('1900-12-31').valueOf()
        if (selectedDate <= minDate) {
          this.$emit('dateError', true)
          return [ENTER_VALID_DATE]
        }
        if (selectedDate > currentDate) {
          this.$emit('dateError', true)
          return [DATE_CANNOT_BE_IN_FUTURE]
        }
      }

      return []
    },
    errors() {
      const fields = [DAY, MONTH, YEAR]
      return validateFields(this.v$, fields, this.errorMsg)
    },
    fullDate() {
      return {
        day: this.day,
        month: this.month,
        year: this.year
      }
    },
    value() {
      const formattedDate = dateHelper.toBackendFormat(this.fullDate)
      this.$emit('onValueChange', formattedDate)
      return formattedDate
    }
  },
  data() {
    return {
      monthOptions: MONTH_OPTIONS,
      day: null,
      month: null,
      year: null,
      errorMsg: {
        day: null,
        month: null,
        year: null
      },
      componentOnFocus: undefined,
      DAY,
      MONTH,
      YEAR
    }
  },
  validations: {
    day: {
      required,
      maxLength: maxLength(2),
      validDay(_value, vm) {
        if (!vm.day) return true
        const day = Number(vm.day)
        return (day > 0 && day <= 31)
      },
      numeric
    },
    month: {
      required
    },
    year: {
      required,
      maxLength: maxLength(4),
      validYear(_value, vm) {
        if (!vm.year) return true
        const year = Number(vm.year)
        return (vm.year.length !== 4) || (year >= 1800)
      },
      numeric
    }
  }
}
</script>
