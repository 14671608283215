<template>
  <div class="font-exo text-md text-tmrw-gray">
    <!-- ALL -->
    <span data-test="header__title" v-if="internalStepValue === 0">Create a New Ticket</span>

    <!-- FREEZE -->
    <span v-if="isFreezeMethod" data-testid="header-freeze">
      <span data-testid="header-method">{{formattedMethodText}}</span>

      <span v-if="internalStepValue > 2">
          <a
            data-testid="header-ticket-type"
            @click="handleClickBack(selectSpecimenType)"
            class="header-link-text cursor-pointer underline text-tmrw-green"
          >{{specimenCount}} {{ticketTypePlural}}</a> for

          <span v-if="internalStepValue > 3">
            <a
              data-testid="header-patient"
              @click="handleClickBack(selectPatient)"
              class="relative header-link-text cursor-pointer underline text-tmrw-green"
              @mouseenter="toggleMultiplePatientsTooltipVisibility"
              @mouseleave="toggleMultiplePatientsTooltipVisibility"
              v-if="patients && patients.length > 1 && isMultiplePatientsEnabled"
            >
              {{patients.length}} {{ PATIENTS }}
              <tooltip v-if="showMultiplePatientsTooltip" :content="multiplePatientsList" alignment="bottom" />
            </a>

            <a
              v-else
              data-testid="header-patient"
              @click="handleClickBack(selectPatient)"
              class="header-link-text cursor-pointer underline text-tmrw-green"
            >{{ selectedPatient[0].firstName }} {{ selectedPatient[0].lastName }}</a>

            {{' '}}
          </span>
          <span v-if="internalStepValue > 4">
            <span v-if="isMultiplePatientsEnabled && beacons && beacons.length">
              with
              <a
                data-testid="header-cryodevices"
                @click="handleClickBack(howMany)"
                class="header-link-text cursor-pointer underline text-tmrw-green"
              >{{beacons[0].cryoDevice.length}} {{ beacons[0].cryoDevice.length > 1 ? CRYODEVICES : CRYODEVICE }}</a>

              {{' '}}
            </span>

            <span v-if="isRange">from </span>
            <span v-else>for </span>
            <a
              data-testid="header-calendar-date"
              @click="handleClickBack(calendar)"
              class="header-link-text cursor-pointer underline text-tmrw-green"
            >{{ computedDate }}</a>:
          </span>
      </span>
    </span>

    <!-- THAW -->
    <span v-if="isThawMethod" data-testid="header-thaw">
      <span data-testid="header-method">{{formattedMethodText}} </span>

      <span v-if="internalStepValue >= 3">
        <a
          data-testid="header-ticket-type"
          @click="handleClickBack(selectSpecimenType)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{specimenCount}} {{ticketTypePlural}}</a> for
      </span>

      <span v-if="internalStepValue >= 4 && isEmbryoSelected">
        <a
          data-testid="header-type-embryo"
          @click="handleClickBack(selectThawProcedure)"
          class="header-link-text cursor-pointer underline text-tmrw-green">{{selectedTypeEmbryo}}</a> for
      </span>

      <span v-if="internalStepValue > 4">
        <a
          data-testid="header-patient"
          @click="handleClickBack(selectPatient)"
          class="relative header-link-text cursor-pointer underline text-tmrw-green"
          @mouseenter="toggleMultiplePatientsTooltipVisibility"
          @mouseleave="toggleMultiplePatientsTooltipVisibility"
          v-if="patients && patients.length > 1 && isMultiplePatientsEnabled"
        >
          {{patients.length}} {{ PATIENTS }}
          <tooltip v-if="showMultiplePatientsTooltip" :content="multiplePatientsList" alignment="bottom" />
        </a>
        <a
          data-testid="header-patient"
          v-else
          @click="handleClickBack(selectPatient)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ selectedPatient[0].firstName }} {{ selectedPatient[0].lastName }}</a> {{' '}}
      </span>

      <span v-if="internalStepValue > 5">
        <span v-if="isRange">from </span>
        <span v-else>for </span>
        <a
          data-testid="header-calendar-date"
          @click="handleClickBack(calendar)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ computedDate }}</a>:
      </span>
    </span>

    <!-- IMPORT -->
    <span v-if="isImportMethod" data-testid="header-import">
     <span data-testid="header-method">{{formattedMethodText}} </span>

      <span v-if="internalStepValue >= 3">
        <a
          data-testid="header-ticket-type"
          @click="handleClickBack(selectSpecimenType)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{specimenCount}} {{ticketTypePlural}}</a>
      </span>

      <span v-if="internalStepValue > 3">
        for
        <a
          data-testid="header-patient"
          @click="handleClickBack(selectPatient)"
          class="relative header-link-text cursor-pointer underline text-tmrw-green"
          @mouseenter="toggleMultiplePatientsTooltipVisibility"
          @mouseleave="toggleMultiplePatientsTooltipVisibility"
          v-if="patients && patients.length > 1 && isMultiplePatientsEnabled"
        >
          {{patients.length}} {{ PATIENTS }}
          <tooltip v-if="showMultiplePatientsTooltip" :content="multiplePatientsList" alignment="bottom" />
        </a>
        <a
          data-testid="header-patient"
          @click="handleClickBack(selectPatient)"
          v-else
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ selectedPatient[0].firstName }} {{ selectedPatient[0].lastName }}</a> {{' '}}
      </span>

      <span v-if="internalStepValue > 4">
        <span v-if="isRange">from </span>
        <span v-else>for </span>
        <a
          data-testid="header-calendar-date"
          @click="handleClickBack(calendar)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ computedDate }}</a>:
      </span>
    </span>

    <!-- EXPORT -->
    <span v-if="isExportMethod || isDonateToPatientMethod" data-testid="header-export">
     <span data-testid="header-method">{{formattedMethodText}} </span>

      <span
        data-testid="header-specimens-label"
        v-if="internalStepValue <= 2">
        for
      </span>

      <span v-if="internalStepValue > 4">
        <a
          data-testid="header-export-embryo-count"
          v-if="embryoSpecimensCount > 0"
          @click="handleClickBack(SelectSpecimensForNewTicket)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ specimenCount }} {{ SPECIMEN_TYPE_EMBRYOS }}</a> {{' '}}

        <span v-if="embryoSpecimensCount > 0 && oocyteSpecimensCount > 0"> and </span>

        <a
          data-testid="header-export-oocyte-count"
          v-if="oocyteSpecimensCount > 0"
          @click="handleClickBack(SelectSpecimensForNewTicket)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ oocyteSpecimensCount }} {{ SPECIMEN_TYPE_OOCYTES }}</a> {{' '}}
      </span>

      <span v-if="internalStepValue > 2">
        for
        <a
          data-testid="header-patient"
          @click="handleClickBack(selectPatient)"
          class="relative header-link-text cursor-pointer underline text-tmrw-green"
          @mouseenter="toggleMultiplePatientsTooltipVisibility"
          @mouseleave="toggleMultiplePatientsTooltipVisibility"
          v-if="patients && patients.length > 1 && isMultiplePatientsEnabled"
        >
          {{patients.length}} {{ PATIENTS }}
          <tooltip v-if="showMultiplePatientsTooltip" :content="multiplePatientsList" alignment="bottom" />
        </a>
        <a
          data-testid="header-patient"
          @click="handleClickBack(selectPatient)"
          v-else
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ selectedPatient[0].firstName }} {{ selectedPatient[0].lastName }}</a> {{' '}}
      </span>

      <span v-if="internalStepValue > 5">
        for
        <a
          data-testid="header-export-reason"
          @click="handleClickBack(SelectReasonToExport)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ exportReasonMessage }}</a> {{' '}}
      </span>

      <span v-if="internalStepValue > 3">
        <span v-if="isRange">from </span>
        <span v-else>for </span>
        <a
          data-testid="header-calendar-date"
          @click="handleClickBack(calendar)"
          class="header-link-text cursor-pointer underline text-tmrw-green"
        >{{ computedDate }}</a>:
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import {
  METHOD_FREEZE,
  METHOD_THAW,
  METHOD_IMPORT,
  SPECIMEN_TYPE_EMBRYO,
  METHOD_EXPORT,
  REDESIGN_METHODS,
  METHOD_DONATE,
  SHIPPING,
  HAND_CARRY,
  DISCARD,
  DONATION_VALUE,
  DONATION_TO_RESEARCH,
  DONATE_TO_PATIENT,
  OTHER,
  SHIPPING_VALUE,
  HAND_CARRY_VALUE,
  DISCARD_VALUE,
  DONATE_TO_RESEARCH_VALUE,
  DONATE_TO_PATIENT_VALUE,
  OTHER_VALUE,
  TRANSFER_OUT_VALUE,
  METHOD_NOT_SELECTED,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  CRYODEVICE,
  CRYODEVICES,
  PATIENTS,
  SPECIMEN_TYPE_EMBRYOS,
  SPECIMEN_TYPE_OOCYTES
} from '@/constants'
import {
  STEP_INITIAL,
  STEP_SELECT_PROCEDURE,
  STEP_SELECT_THAW_PROCEDURE,
  STEP_SELECT_SPECIMEN_TYPE,
  STEP_SELECT_PATIENT,
  STEP_CALENDAR,
  STEP_HOW_MANY,
  STEP_DETAILS,
  STEP_FINAL,
  STEP_LABEL_SCAN,
  STEP_SCAN_BEACON,
  // STEP_SELECT_SPECIMENS,
  STEP_ADD_EXISTING_BEACON,
  STEP_SELECT_SPECIMENS_NEW_TICKET,
  STEP_SELECT_REASON_TO_EXPORT
} from '@/constants/ticketSteps'
import { executePreviousTicketStep } from '@/helpers/manageTicket'
import { replaceStringFilter } from '@/filters'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'
import { mountMultiplePatientsStringList } from '@/helpers/multiplePatients/mountMultiplePatientsStringList'

dayjs.extend(isToday)

export default {
  name: 'header-info',
  data() {
    return {
      selectSpecimenType: STEP_SELECT_SPECIMEN_TYPE,
      selectPatient: STEP_SELECT_PATIENT,
      calendar: STEP_CALENDAR,
      selectThawProcedure: STEP_SELECT_THAW_PROCEDURE,
      howMany: STEP_HOW_MANY,
      CRYODEVICE,
      CRYODEVICES,
      PATIENTS,
      SPECIMEN_TYPE_OOCYTES,
      SPECIMEN_TYPE_EMBRYOS,
      isMultiplePatientsEnabled: false,
      showMultiplePatientsTooltip: false
    }
  },
  created() {
    this.isMultiplePatientsEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
    this.internalSteps = {
      [METHOD_NOT_SELECTED]: 0,
      [METHOD_FREEZE]: {
        [STEP_INITIAL]: 0,
        [STEP_SELECT_PROCEDURE]: 1,
        [STEP_SELECT_SPECIMEN_TYPE]: 2,
        [STEP_SELECT_PATIENT]: 3,
        [STEP_CALENDAR]: 4,
        [STEP_HOW_MANY]: 5,
        [STEP_DETAILS]: 6,
        [STEP_LABEL_SCAN]: 7,
        [STEP_ADD_EXISTING_BEACON]: 8,
        [STEP_SCAN_BEACON]: 9,
        [STEP_FINAL]: 10
      },
      [METHOD_THAW]: {
        [STEP_INITIAL]: 0,
        [STEP_SELECT_PROCEDURE]: 1,
        [STEP_SELECT_SPECIMEN_TYPE]: 2,
        [STEP_SELECT_THAW_PROCEDURE]: 3,
        [STEP_SELECT_PATIENT]: 4,
        [STEP_CALENDAR]: 5,
        [STEP_SELECT_SPECIMENS_NEW_TICKET]: 6,
        [STEP_FINAL]: 7
      },
      [METHOD_IMPORT]: {
        [STEP_INITIAL]: 0,
        [STEP_SELECT_PROCEDURE]: 1,
        [STEP_SELECT_SPECIMEN_TYPE]: 2,
        [STEP_SELECT_PATIENT]: 3,
        [STEP_CALENDAR]: 4,
        [STEP_HOW_MANY]: 5,
        [STEP_DETAILS]: 6,
        [STEP_SCAN_BEACON]: 7,
        [STEP_FINAL]: 8
      },
      [METHOD_EXPORT]: {
        [STEP_INITIAL]: 0,
        [STEP_SELECT_PROCEDURE]: 1,
        [STEP_SELECT_PATIENT]: 2,
        [STEP_CALENDAR]: 3,
        [STEP_SELECT_SPECIMENS_NEW_TICKET]: 4,
        [STEP_SELECT_REASON_TO_EXPORT]: 5,
        [STEP_FINAL]: 6
      },
      [METHOD_DONATE]: {
        [STEP_INITIAL]: 0,
        [STEP_SELECT_PROCEDURE]: 1,
        [STEP_SELECT_PATIENT]: 2,
        [STEP_CALENDAR]: 3,
        [STEP_SELECT_SPECIMENS_NEW_TICKET]: 4,
        [STEP_SELECT_REASON_TO_EXPORT]: 5,
        [STEP_SCAN_BEACON]: 6,
        [STEP_FINAL]: 7
      },
      [PROCEDURE_TYPE_BATCH_UPPERCASE]: {
        [STEP_FINAL]: 1
      }
    }
    this.exportReason = {
      [TRANSFER_OUT_VALUE]: {
        [SHIPPING_VALUE]: SHIPPING,
        [HAND_CARRY_VALUE]: HAND_CARRY
      },
      [DISCARD_VALUE]: DISCARD,
      [DONATION_VALUE]: {
        [DONATE_TO_RESEARCH_VALUE]: DONATION_TO_RESEARCH,
        [DONATE_TO_PATIENT_VALUE]: DONATE_TO_PATIENT
      },
      [OTHER_VALUE]: OTHER
    }
  },
  computed: {
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedSpecimenType',
      'selectedPatient',
      'beacons',
      'procedureDateFrom',
      'procedureDateTo',
      'procedureTime',
      'metadata',
      'selectedTypeEmbryo']),
    ...mapGetters('patientGroupModule', ['patients']),
    multiplePatientsList() {
      if (!this.isMultiplePatientsEnabled) return []
      // returns the fullName of the patient + breakline \n
      return mountMultiplePatientsStringList(this.patients)
    },
    formattedMethodText() {
      return `${REDESIGN_METHODS[this.selectedMethod]} `
    },
    isFreezeMethod() {
      return this.selectedMethod === METHOD_FREEZE
    },
    isThawMethod() {
      return this.selectedMethod === METHOD_THAW
    },
    isEmbryoSelected() {
      return this.selectedSpecimenType === SPECIMEN_TYPE_EMBRYO
    },
    isImportMethod() {
      return this.selectedMethod === METHOD_IMPORT
    },
    isExportMethod() {
      return this.selectedMethod === METHOD_EXPORT
    },
    isDonateToPatientMethod() {
      return this.selectedMethod === METHOD_DONATE
    },
    computedDate() {
      if (this.procedureDateFrom) {
        // RANGE
        if (this.procedureDateFrom && this.procedureDateTo && this.procedureDateFrom !== this.procedureDateTo) {
          return `${dayjs(this.procedureDateFrom).format('DDMMMYYYY').toUpperCase()} to ${dayjs(this.procedureDateTo).format('DDMMMYYYY').toUpperCase()}`
        }
        // TODAY
        if (dayjs(this.procedureDateFrom).isToday()) {
          return 'today'
        }
        // SPECIFIC SINGLE DATE
        return `${dayjs(this.procedureDateFrom).format('DDMMMYYYY').toUpperCase()}`
      }

      return null
    },
    isRange() {
      return this.procedureDateFrom && this.procedureDateTo && this.procedureDateFrom !== this.procedureDateTo
    },
    ticketTypePlural() {
      const type = replaceStringFilter(this.selectedSpecimenType)
      /**
       * Since the ticket type for 'Oocyte' is already plural,
       * we only change the 'Embryo' ticket type
       */
      return `${type}s`
    },
    internalStepValue() {
      if (this.selectedMethod === METHOD_NOT_SELECTED) {
        return this.internalSteps[this.selectedMethod]
      }
      return this.internalSteps[this.selectedMethod][this.currentTicketStep]
    },
    exportReasonMessage() {
      const message = this.exportReason[this.metadata.exportReason.type]
      const { subType } = this.metadata.exportReason.details
      return subType ? message[subType] : message
    },
    embryoSpecimensCount() {
      const embryo = []

      const cryoDevicesArray = this.beacons.reduce((acc, curr) => [...acc, ...curr.cryoDevice], [])

      cryoDevicesArray.map((specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO && embryo.push(+specimen.specimenCount))

      const embryoCount = embryo.reduce((a, b) => a + b, 0)

      return embryoCount
    },
    oocyteSpecimensCount() {
      const oocyte = []

      const cryoDevicesArray = this.beacons.reduce((acc, curr) => [...acc, ...curr.cryoDevice], [])

      cryoDevicesArray.map((specimen) => specimen.specimenType !== SPECIMEN_TYPE_EMBRYO && oocyte.push(+specimen.specimenCount))

      const oocyteCount = oocyte.reduce((a, b) => a + b, 0)

      return oocyteCount
    },
    specimenCount() {
      if (this.embryoSpecimensCount) {
        return this.embryoSpecimensCount
      }

      if (this.oocyteSpecimensCount) {
        return this.oocyteSpecimensCount
      }

      return ''
    }
  },
  methods: {
    handleClickBack(goToRoute) {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        fromRouteName: goToRoute
      })

      this.$router.replace({ name: previousStepPath })
    },
    toggleMultiplePatientsTooltipVisibility() {
      this.showMultiplePatientsTooltip = !this.showMultiplePatientsTooltip
    }
  },
  components: {
    Tooltip
  }
}
</script>
