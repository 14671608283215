/* eslint-disable */
export default {
  checkBeaconId(state, beacon) {
    state.beaconIdCheck = beacon
  },
  setBeaconIdValidate(state, valid) {
    state.beaconIdValidate = valid
  },
  setBeaconIdCheckMessage(state, message) {
    state.beaconIdCheckMessage = message
  },
  setSingleBeaconReaderReads(state, beaconId) {
    if(!state.singleBeaconReaderReads) {
      state.singleBeaconReaderReads = [beaconId]
    } else {
      state.singleBeaconReaderReads = [...state.singleBeaconReaderReads, beaconId]
    }
  },
  setBeaconStep(state, step) {
    state.beaconStep = step
  },
  setBeacons(state, beacons) {
    state.beaconsList = beacons
  },
  setBeaconsSpecimens(state, beaconsSpecimens) {
    state.beaconsSpecimens = beaconsSpecimens
  },
  setStatus(state, error) {
    state.error = error
  },
  selectBeacon(state, beacon) {
    state.currentBeacon = beacon
  },
  finallyBeacons(state) {
    state.isCompleted = true
  },
  setBeaconValid(state, beaconBarcode) {
    state.beaconBarcodeNumber = beaconBarcode
  },
  resetBeaconReaderReads(state) {
    state.singleBeaconReaderReads = []
  },
  addScannedBeaconBarcodeNumber(state, beaconBarcode) {
    state.scannedBeaconBarcodes = [...state.scannedBeaconBarcodes, beaconBarcode]
  },
  clearScannedBeaconBarcodeNumbers(state) {
    state.scannedBeaconBarcodes = []
  }
}
