<!-- eslint-disable vue/no-mutating-props -->
<template>
  <vue-final-modal
    name="modal-break-up-batch"
    height="auto"
    width="430"
    class="bg-black bg-opacity-50"
    :modelValue="show"
    classes="modal--container"
    content-class="modal--content"
    :content-style="{ height: 'auto', width: '430px' }"
  >
    <main class="bg-white p-4">
      <section>
        <p class="text-xl text-tmrw-blue-dark leading-6 font-medium">
          Are you sure you want to break up the Batch Ticket?
        </p>
        <div class="flex align-center justify-between pt-5 px-3">
          <button-dynamic
            btnText="Cancel"
            btnType="button"
            btnStyle="primary"
            @click="closeModal"
          />
          <button-dynamic
            btnText="Break Up"
            btnType="button"
            btnStyle="secondary"
            @click="submitButtonModal"
          />
        </div>
      </section>
    </main>
  </vue-final-modal>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  components: {
    ButtonDynamic
  },
  name: 'cancel-ticket-modal',
  emits: ['onHideModal', 'breakUpBatchTicket'],
  props: {
    ticketId: {
      type: [Number, String],
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('onHideModal')
    },
    submitButtonModal() {
      this.$emit('breakUpBatchTicket')
    }
  }
}
</script>
