<template>
  <MainContainer data-test="patient-inventory" :gridSlots="loadingPatientInventory ? '2' : '3'">
    <top-header>Update Specimens for <span>
        <a
          @click="handlePatient"
          class="font-exo underline text-tmrw-green cursor-pointer"
        >
          {{ patientFullName }}
        </a>
      </span>
    </top-header>
    <loading-ui modal v-if="creatingTicket" :message="loaderMessage" />
    <loading-ui v-if="loadingPatientInventory" message="Processing selected specimens" />
    <div v-else>
      <ActionBar data-test="patient-form__action-bar" class="my-6" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h2" floatTo="left">{{ displayBubbleMessage }}</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnText="Cancel"
            btnType="button"
            btnStyle="secondary"
            isFontBold
            addMarginRight
            @click="handleCancel"
          />
          <button-dynamic
            btnText="Update"
            btnType="button"
            btn-style="primary"
            isFontBold
            fontAwesomeIconClass="check-circle-regular"
            showIcon
            :isDisabled="!canUpdateData"
            :isLoading="updatingData"
            @click="handleUpdate"
          />
        </template>
      </ActionBar>
      <p data-test="cryobeacon-id" class="font-semibold text-white mb-4 text-2xl">{{ cryoBeacon }} ID {{ currentBeaconBarcode }}</p>
      <template v-if="hasEmbryo">
        <div class="flex justify-between my-8 text-white text-2xl font-inconsolata">
          <span data-test="specimen-count">{{ embryoSpecimenCountLabel }}</span>
          <span data-test="cryodevice-count" class="text-tmrw-green-light">{{ embryoDevicesCountLabel }}</span>
        </div>
        <ScrollTableComponent
          data-testid="patient-inventory-embryo"
          v-model="inventoryModels[SPECIMEN_TYPE_EMBRYO]"
          :headers="embryoHeaders"
          hasScroll
          :minHeight="null"
          @update:modelValue="handleRowChange"
        />
      </template>
      <template v-if="hasEgg">
        <div class="flex justify-between my-8 text-white text-2xl font-inconsolata">
          <span data-test="specimen-count">{{ oocyteSpecimentCountLabel }}</span>
          <span data-test="cryodevice-count" class="text-tmrw-green-light">{{ oocyteDevicesCountLabel }}</span>
        </div>
        <ScrollTableComponent
          data-testid="patient-inventory-embryo"
          v-model="inventoryModels[SPECIMEN_TYPE_EGG]"
          :headers="eggHeaders"
          hasScroll
          :minHeight="null"
          @update:modelValue="handleRowChange"
        />
      </template>
      <AcknowledgeModal
        v-if="isAcknowledgeModalOpen"
        v-model="isAcknowledgeModalOpen"
        :title="SCREENING_STATUS_UPDATE_ACKNOWLEDGE_TITLE"
        :buttonText="ACKNOWLEDGE_DONE"
        @confirmAcknowledge="confirmAcknowledge"
      />
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getProp from 'lodash.get'
import dayjs from 'dayjs'

import toast from '@/config/toast'
import {
  CRYOBEACON,
  CRYODEVICES,
  CRYODEVICE,
  EMBRYO_BIOPSY,
  EMBRYO_BIOPSY_RESULT,
  HEADER_SCREENING_STATUS_MESSAGE,
  BUBBLE_SCREENING_STATUS_OOCYTES_MESSAGE,
  BUBBLE_SCREENING_STATUS_EMBRYO_MESSAGE,
  SCREENING_STATUS,
  SCREENING_STATUS_NEGATIVE,
  SCREENING_STATUS_POSITIVE,
  SCREENING_STATUS_UNSCREENED,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_NOTES,
  SCREENING_STATUS_UPDATE_ACKNOWLEDGE_TITLE,
  ACKNOWLEDGE_DONE
} from '@/constants'
import * as updateScreeningStatusHeaders from '@/constants/table-headers/update-screening-status'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import AcknowledgeModal from '@/components/AcknowledgeModal/AcknowledgeModal.vue'
import { selectEmbryoTypeLabel } from '@/utils'
import { historyRoutesHelper } from '@/helpers/routerHistoryHelper'

export default {
  name: 'update-screening-status',
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    MainContainer,
    ScrollTableComponent,
    TopHeader,
    AcknowledgeModal
  },
  data() {
    const baseModel = {
      sort: {
        orderBy: 'cryoDate',
        direction: 'desc'
      },
      options: []
    }

    return {
      beaconBarcode: this.$route.params.beaconBarcode,
      creatingTicket: false,
      cryoBeacon: CRYOBEACON,
      headerMessage: HEADER_SCREENING_STATUS_MESSAGE,
      isAcknowledgeModalOpen: false,
      inventoryModels: {
        [SPECIMEN_TYPE_EMBRYO]: baseModel,
        [SPECIMEN_TYPE_EGG]: baseModel
      },
      eggsModel: baseModel,
      loaderMessage: 'Updating Infectious Screening Status',
      loadingPatientInventory: true,
      patientId: this.$route.params.patientId,
      updatingData: false,
      SPECIMEN_TYPE_EGG,
      SPECIMEN_TYPE_EMBRYO,
      SPECIMEN_TYPE_OOCYTE,
      SCREENING_STATUS_UPDATE_ACKNOWLEDGE_TITLE,
      ACKNOWLEDGE_DONE
    }
  },
  computed: {
    ...mapGetters('patientsModule', [
      'patientInventory',
      'patient',
      'container',
      'updatedTicketId'
    ]),
    ...mapGetters('authModule', ['loggedUserInfo']),
    ...mapGetters('specimensModule', ['embryoTypes']),
    embryoSpecimenCountLabel() {
      const specimensCount = this.inventoryModels[SPECIMEN_TYPE_EMBRYO].options.reduce((counter, { specimenCount }) => counter + specimenCount, 0)
      const label = specimensCount > 1 ? `${SPECIMEN_TYPE_EMBRYO}s` : SPECIMEN_TYPE_EMBRYO
      return `${specimensCount} ${label}`
    },
    oocyteSpecimentCountLabel() {
      const specimensCount = this.inventoryModels[SPECIMEN_TYPE_EGG].options.reduce((counter, { specimenCount }) => counter + specimenCount, 0)
      const label = specimensCount > 1 ? `${SPECIMEN_TYPE_OOCYTE}s` : SPECIMEN_TYPE_OOCYTE
      return `${specimensCount} ${label}`
    },
    embryoDevicesCountLabel() {
      const cryoDevicesCount = this.inventoryModels[SPECIMEN_TYPE_EMBRYO].options.length
      return `${cryoDevicesCount} ${cryoDevicesCount > 1 ? CRYODEVICES : CRYODEVICE}`
    },
    oocyteDevicesCountLabel() {
      const cryoDevicesCount = this.inventoryModels[SPECIMEN_TYPE_EGG].options.length
      return `${cryoDevicesCount} ${cryoDevicesCount > 1 ? CRYODEVICES : CRYODEVICE}`
    },
    patientFullName() {
      return this.patient?.patientName || `${this.patient?.firstName} ${this.patient?.lastName}`
    },
    currentBeaconBarcode() {
      return this.beaconBarcode
    },
    displayBubbleMessage() {
      return this.hasEmbryo
        ? BUBBLE_SCREENING_STATUS_EMBRYO_MESSAGE
        : BUBBLE_SCREENING_STATUS_OOCYTES_MESSAGE
    },
    getHeaders() {
      if (this.currentBeaconDevices?.length) {
        const [{ specimenType }] = this.currentBeaconDevices
        const specimenTypeLowercase = specimenType.toLowerCase()

        return updateScreeningStatusHeaders[specimenTypeLowercase]
      }
      return updateScreeningStatusHeaders.embryo
    },
    embryoHeaders() {
      return updateScreeningStatusHeaders[SPECIMEN_TYPE_EMBRYO.toLowerCase()]
    },
    eggHeaders() {
      return updateScreeningStatusHeaders[SPECIMEN_TYPE_EGG.toLowerCase()]
    },
    hasEgg() {
      return this.inventoryModels[SPECIMEN_TYPE_EGG].options.length > 0
    },
    hasEmbryo() {
      return this.inventoryModels[SPECIMEN_TYPE_EMBRYO].options.length > 0
    },
    currentBeaconDevices() {
      const filteredData = this.patientInventory.filter(
        (el) => el.beaconBarcode === this.beaconBarcode
      )
      return filteredData
    },
    canUpdateData() {
      return this.hasChange(SPECIMEN_TYPE_EMBRYO) || this.hasChange(SPECIMEN_TYPE_EGG)
    },
    inventoryModel() {
      const options = Object.keys(this.inventoryModels)
        .map((key) => this.inventoryModels[key].options)
        .flat()
      const model = this.parseCryoDevicesToInventoryModel(options)
      return model
    }
  },
  watch: {
    currentBeaconDevices(newValue) {
      if (newValue) {
        const localPatientInventory = this.currentBeaconDevices.map((specimen) => ({
          ...specimen,
          embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes)
        }))

        this.inventoryModel = {
          ...this.inventoryModel,
          options: localPatientInventory
        }
      }
    }
  },
  methods: {
    ...mapActions('patientsModule', [
      'fetchPatientInventory',
      'updateScreeningStatus',
      'fetchPatient',
      'getBeaconIdFromBarcode'
    ]),
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    async handleUpdate() {
      try {
        const [inventory] = this.currentBeaconDevices
        const [inventoryModel] = this.inventoryModel.options
        const newTicket = this.createTicket()
        if (inventory.screeningStatus === inventoryModel.screeningStatus) {
          await this.updateScreeningStatus(newTicket)
          const { patientId } = this.$route.params
          const successMsg = `<b class="text-sm">The Biopsy was successfully updated</b>
          <br>You edited ${this.inventoryModel.options.length} CryoDevices`

          this.$router.meta = { biopsy: { successMsg } }
          this.$router.push(`/patients/${patientId}`)
        }
        this.updatingData = false
        this.createNewTicket()
      } catch (err) {
        this.updatingData = false
      }
    },
    handlePatient() {
      const { patientId } = this.$route.params
      this.$router.push(`/patients/${patientId}`)
    },
    async createNewTicket() {
      const customError = {
        message: 'Something went wrong',
        data: {
          path: {
            responseURL: 'The server is not responding, please try again.'
          }
        }
      }
      try {
        const newTicket = this.createTicket()

        this.creatingTicket = true
        this.updatingData = false

        await this.updateScreeningStatus(newTicket)
        this.creatingTicket = false
        this.isAcknowledgeModalOpen = true

      } catch (err) {
        this.creatingTicket = false
        this.updatingData = false
        toast.error({title: customError})
        return err
      }
    },
    createTicket() {
      this.updatingData = true
      const {
        birthDate, firstName, globalPatientNumber, identificationNumber, lastName
      } = this.patient

      const mappedSpecimens = this.inventoryModel.options.map((option) => ({
        ...option,
        cryoDate: dayjs(Number(option.cryoDate)).format('DDMMMYYYY').toUpperCase(),
        screeningStatus: option.screeningStatus.toString()
      }))

      const { userId } = this.loggedUserInfo
      const [inventory] = this.currentBeaconDevices
      const newData = {
        beaconBarcode: inventory.beaconBarcode,
        globalPatientNumber,
        identificationNumber,
        patientDob: birthDate,
        patientName: `${firstName} ${lastName}`,
        specimens: mappedSpecimens,
        userId
      }

      const newTicket = {
        specimens: newData,
        beaconId: this.container.containerId,
        patient: {
          firstName,
          lastName,
          identificationNumber,
          birthDate
        }
      }

      return newTicket
    },
    handleCancel() {
      return historyRoutesHelper({ thisData: this })
    },
    settingDataModel({ options }) {
      if (options.length > 0) {
        const [{ beaconBarcode }] = options
        const [{ screeningStatus }] = options
        const screeningValues = {
          beaconBarcode,
          count: options.length
        }

        switch (screeningStatus) {
          case 1:
            return {
              ...screeningValues,
              screenStatus: SCREENING_STATUS_POSITIVE
            }
          case 2:
            return {
              ...screeningValues,
              screenStatus: SCREENING_STATUS_NEGATIVE
            }
          case 3:
          default:
            return {
              ...screeningValues,
              screenStatus: SCREENING_STATUS_UNSCREENED
            }
        }
      }
      return {
        count: 0,
        beaconBarcode: '',
        screenStatus: ''
      }
    },
    confirmAcknowledge() {
      // Perform routing after user acknowledgment
      const { patientId } = this.$route.params
      const ticketsArr = this.updatedTicketId.map((el) => el.ticketId)
      if (ticketsArr.length) {
        this.$router.push(`/tickets/${ticketsArr.join()}`)
      } else {
        this.$router.push(`/patients/${patientId}`)
      }
    },
    parseCryoDevicesToInventoryModel(devices) {
      return {
        options: devices || [],
        sort: {
          orderBy: 'cryoDate',
          direction: 'desc'
        }
      }
    },
    fieldChanged(specimenType) {
      const changeableFields = [
        SCREENING_STATUS,
        EMBRYO_BIOPSY,
        EMBRYO_BIOPSY_RESULT,
        SPECIMEN_NOTES
      ]
      const updatedDevices = this.inventoryModels[specimenType].options
      const oldDevices = this.patientInventory.filter(
        (device) => device.specimenType === specimenType
      )

      let fieldName = null
      changeableFields.forEach((field) => {
        updatedDevices.forEach((newData) => {
          const result = oldDevices.find(
            (data) => newData.cryodeviceBarcode === data.cryodeviceBarcode
              && getProp(newData, field, '') !== getProp(data, field, '')
          )
          if (result) {
            fieldName = field
          }
        })
      })
      return fieldName
    },
    hasChange(specimenType) {
      return this.fieldChanged(specimenType) !== null
    },
    updateAllScreeningStatus(screeningStatus) {
      Object.keys(this.inventoryModels).forEach((specimenType) => {
        let { options } = this.inventoryModels[specimenType]
        const hasOptions = options.length > 0
        if (hasOptions) {
          options = options.map((item) => ({ ...item, screeningStatus }))
        }
        this.inventoryModels[specimenType].options = options
      })
    },
    handleRowChange({ options }) {
      const { screeningStatus } = options[0]
      this.updateAllScreeningStatus(screeningStatus)
    }
  },
  async created() {
    await this.fetchEmbryoTypes()
    await this.fetchPatientInventory(this.patientId)

    const [inventory] = this.currentBeaconDevices
    if (inventory) {
      this.getBeaconIdFromBarcode({
        beaconBarcode: inventory.beaconBarcode,
        unitId: inventory.unitId
      })
    }
    this.loadingPatientInventory = false
    this.fetchPatient(this.patientId)

    const populateInventoryModel = (specimenType) => {
      const devices = this.currentBeaconDevices?.filter(
        (device) => device.specimenType === specimenType
      )
      this.inventoryModels[specimenType] = this.parseCryoDevicesToInventoryModel(devices)
    }
    populateInventoryModel(SPECIMEN_TYPE_EGG)
    populateInventoryModel(SPECIMEN_TYPE_EMBRYO)
  }
}
</script>
