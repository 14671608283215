<template>
  <loading-ui v-if="isLoading" modal message="Loading Cryodevices" />
  <section v-else data-test="cryobeacons-list" class="bg-white rounded-lg my-6 px-6 py-4">
    <StatusTableHeader :showBorder="false" :ticketDetails="ticketDetail" class="ml-2 pb-6" />
    <section
      v-for="beacon in orderedBeacons"
      :key="beacon.containerId"
      :data-test="`cryobeacon-${beacon.barcode}`"
    >
      <CryoBeaconHeader
        :data-test="`cryobeacon-header-${beacon.barcode}`"
        :beacon="beacon"
        isSelectable
        @selected="selectBeacon"
        class="mx-4"
      />
      <TicketList
        :data-test="`specimens-list-${beacon.barcode}`"
        :class="getOpacityClass(beacon)"
        :specimensData="getSpecimens(beacon)"
        :headers="[]"
        patients-tooltip
      />
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CryoBeaconHeader from '@/components/CryoBeaconHeader/CryoBeaconHeader.vue'
import TicketList from '@/components/TicketList/TicketList.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import StatusTableHeader from '@/components/StatusTableHeader/StatusTableHeader.vue'

// TODO: Include CY Tests for Add to existing cryobeacon

export default {
  name: 'cryobeacons-list',
  components: {
    CryoBeaconHeader,
    LoadingUi,
    StatusTableHeader,
    TicketList
  },
  props: {
    beacons: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true
    const { beacons } = this
    await this.fetchBeaconsSpecimens({ beacons })
    this.isLoading = false
  },
  computed: {
    ...mapGetters('newTicketModule', ['selectedPatient']),
    ...mapGetters('beaconsModule', ['beaconsSpecimens']),
    orderedBeacons() {
      const { beacons } = this
      const cryoBeaconsWithNewestSpecimenCryoDate = beacons.map((beacon) => {
        const beaconSpecimens = this.beaconsSpecimens.find(
          (device) => device.barcode === beacon.barcode
        )
        if (beaconSpecimens) {
          const { specimens } = beaconSpecimens
          const newestSpecimen = specimens.sort((a, b) => Number(b.cryoDate) - Number(a.cryoDate))
          // eslint-disable-next-line no-param-reassign
          beacon.newestSpecimenCryoDate = newestSpecimen.length ? newestSpecimen[0].cryoDate : 0
        }
        return beacon
      })
      const cryoBeaconsOrderedByCryoDate = cryoBeaconsWithNewestSpecimenCryoDate.sort((a, b) => {
        // Compare newest specimen CryoDate and sort in descending order.
        return b.newestSpecimenCryoDate - a.newestSpecimenCryoDate
      })
      const orderedCryoBeacons = cryoBeaconsOrderedByCryoDate.sort((a, b) => {
        // Compare disabled status and sort in ascending order.
        return a.disabled - b.disabled
      })
      return orderedCryoBeacons
    },
    ticketDetail() {
      const [{ identificationNumber, firstName, lastName }] = this.selectedPatient
      return {
        identificationNumber,
        patientName: `${firstName} ${lastName}`
      }
    }
  },
  methods: {
    ...mapActions('beaconsModule', ['fetchBeaconsSpecimens', 'selectBeacon']),
    getSpecimens(beacon) {
      const beaconSpecimens = this.beaconsSpecimens.find(
        (device) => device.barcode === beacon.barcode
      )

      if (beaconSpecimens) {
        const { specimens } = beaconSpecimens
        const orderedSpecimens = specimens.sort((a, b) => Number(b.cryoDate) - Number(a.cryoDate))
        return orderedSpecimens
      }

      return []
    },
    getOpacityClass(beacon) {
      const { disabled } = beacon
      return disabled ? 'opacity-25' : ''
    }
  }
}
</script>
