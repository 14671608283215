<template>
  <h1
    :data-test="`dynamic-title-${currentRouteName}`"
    :class="[
      'flex items-center',
      `float-${floatTo}`,
      {
        'ml-4': addMarginLeft,
        'mr-4': addMarginRight,
        'mt-4': addMarginTop,
        'mb-4': addMarginBottom,
        'font-bold': isBold,
        'font-semibold': isSemiBold,
        'text-xl text-tmrw-blue': titleType === 'h4-blue',
        'text-2xl text-tmrw-gray font-semibold': titleType === 'h3',
        'text-2xl text-white font-bold': titleType === 'h3-bold',
        'text-2xl text-tmrw-blue': titleType === 'h3-blue',
        'text-2xl text-tmrw-blue-dark': titleType === 'h3-blue-dark',
        'text-3xl text-white font-bold': titleType === 'h2',
        'text-3xl text-tmrw-blue': titleType === 'h2-blue',
        'text-3xl text-tmrw-blue-dark': titleType === 'h2-blue-dark',
        'text-xl text-tmrw-blue-light': titleType === 'h1-blue-light-medium-bold',
        'text-3.5xl': titleType === 'h1',
        'text-tmrw-error': isError,
        'text-tmrw-warning': isWarning,
        'text-tmrw-success': isSuccess,
      },
    ]"
  >
    <slot />
  </h1>
</template>

<script lang="ts">
  export default {
    name: 'dynamic-title'
  }
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
defineProps({
    // Define title size acording to design rules.
    titleType: {
      type: String,
      default: 'h1',
      validator: (value: string) => [
        'h1',
        'h1-blue',
        'h1-blue-dark',
        'h1-blue-small',
        'h1-blue-small-bold',
        'h1-blue-light-medium-bold',
        'h2',
        'h2-blue',
        'h2-semiBold'
      ].includes(value),
      required: false
    },
    // Float title to left or right.
    floatTo: {
      type: String,
      default: 'left',
      validator: (value: string) => ['left', 'right', 'none'].includes(value),
      required: false
    },
    // Add margin to the left.
    addMarginLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    isBold: {
      type: Boolean,
      default: false,
      required: false
    },
    // Set Font as Semi Bold.
    isSemiBold: {
      type: Boolean,
      default: false,
      required: false
    },
    isError: {
      type: Boolean,
      default: false,
      required: false
    },
    isWarning: {
      type: Boolean,
      default: false,
      required: false
    },
    isSuccess: {
      type: Boolean,
      default: false,
      required: false
    }
  })
  // TODO Should update all test specs that has this component to mock useRouter instead of this.$router
  const currentRouteName = computed(() => router?.currentRoute.value.path.split('/')[1]?.toLowerCase())

</script>
