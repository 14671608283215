<template>
  <div class="flex w-[320px] cryolabel-container">
    <div class="w-full bg-white text-black my-2 pt-1">
      <barcode
        :data-testid="`cryoDeviceBarcode${labelIndex}`"
        role="img"
        class="w-full h-6 mb-1"
        :value="getCryodeviceBarcode"
        :options="{ displayValue: false }"
      />
      <div
        class="uppercase text-sm w-full text-center overflow-hidden h-4 -mt-4 relative z-10 flex items-center justify-center barcode-container"
      >
        <div
          :data-testid="`cryoDeviceBarcodeLabel${labelIndex}`"
          class="bg-white p-1 rounded-3xl font-inconsolata barcode"
        >{{ getCryodeviceBarcode() }}</div>
      </div>
      <div class="px-2 h-5 leading-5">
        <template v-if="isModulaCryoLabelEnabled">
          <div
            v-for="(value, index) in getSchemaCryoLabel[0]"
            :key="value.id + index"
            :class="getValueClass(index, value)"
            :style="getValueStyle(index, value)"
          >
            {{ getLabelValue(value, label) }}
          </div>
        </template>
        <template v-else>
          <div
            data-test="label-patient-id"
            class="uppercase text-sm"
            :class="[isEmbryo() ? 'w-4/12' : 'w-8/12']"
          >
            ID #{{ getDefaultLabelValue('identificationNumber') }}
          </div>
          <div
            data-test="label-specimen-count"
            class="w-2/12 text-center text-sm"
            v-if="!isEmbryo()"
          >
            {{ getDefaultLabelValue('specimenCount') }}
          </div>
          <div
            data-test="label-specimen-count"
            class="w-4/12 text-center text-sm embryo-type"
            v-if="isEmbryo()"
          >
            {{ getDefaultLabelValue('specimenCount') }}
          </div>
          <div
            data-test="label-embryo-number"
            class="w-4/12 text-right text-sm embryo-number"
            v-if="isEmbryo()"
          >
            {{ getDefaultLabelValue('embryoNumber') }}
          </div>
          <div
            data-test="label-maturity-label"
            class="w-2/12 text-right maturity-level"
            v-if="!isEmbryo()"
          >
            {{ getMaturityLevel() || '--' }}
          </div>
        </template>
      </div>
      <div class="px-2 pb-1">
        <template v-if="isModulaCryoLabelEnabled">
          <div
            v-for="(value, index) in getSchemaCryoLabel[1]"
            v-bind:key="value.id + index"
            :class="getValueClass(index, value)"
            :style="getValueStyle(index, value)"
          >
            {{ getLabelValue(value, label) }}
          </div>
        </template>
        <template v-else>
          <div class="w-7/12 capitalize text-sm full-name">
            {{
              `${getDefaultLabelValue('firstName').charAt(0)} ${getDefaultLabelValue('lastName')}`
            }}
          </div>
          <div class="w-5/12 text-right text-sm procedure-date-from">
            {{ getDefaultLabelValue('procedureDateFrom') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { formatLabelValue, getLengthValue } from '@/modules/printer'
import {
  MODULAR_LABEL_EGG,
  MODULAR_LABEL_EMBRYO,
  MODULAR_LABEL_SITE_PROPERTY_EGG,
  MODULAR_LABEL_SITE_PROPERTY_EMBRYO,
  MODULAR_LABEL_WIDTH_SIZE
} from '@/constants/modular-cryolabel'
import Barcode from '@/components/Barcode'
import * as ss from '@/config/session-storage-help'
import { SPECIMEN_TYPE_EMBRYO } from '@/constants'
import { CRYOLABEL_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'

const props = defineProps({
  label: {
    type: Object,
    required: true
  },
  labelIndex: {
    type: Number,
    required: true
  }
})

const isEmbryo = () => props.label.specimenType === SPECIMEN_TYPE_EMBRYO

const getSchemaCryoLabel = computed(() => {
  const isEmbryoCryodevice = isEmbryo()
  const schemaForEmbryoOrEgg = isEmbryoCryodevice
    ? MODULAR_LABEL_SITE_PROPERTY_EMBRYO
    : MODULAR_LABEL_SITE_PROPERTY_EGG
  const schema = ss.getFieldSessionStorage('siteProperties', schemaForEmbryoOrEgg)
  const parseSchema = JSON.parse(schema)[isEmbryoCryodevice ? MODULAR_LABEL_EMBRYO : MODULAR_LABEL_EGG]
  return parseSchema
})

const isModulaCryoLabelEnabled = computed(() => isFeatureEnabled(CRYOLABEL_ENABLED_FF))

const getValueClass = (_, value) => {
  return `inline-block text-sm font-inconsolata whitespace-nowrap text-left ${value.id} `
}
const getValueStyle = (_, value) => {
  return `width: ${value.w * MODULAR_LABEL_WIDTH_SIZE}rem;`
}
const getLabelValue = (schemaValue, label) => formatLabelValue(label, schemaValue.id)?.slice(0, getLengthValue(schemaValue)) || ''
const getDefaultLabelValue = (id) => formatLabelValue(props.label, id)
const getMaturityLevel = () => props.label.oocyte.maturityLevel
const getCryodeviceBarcode = () => props.label.cryodeviceBarcode
</script>
