import { computed } from 'vue'
import { useStore } from 'vuex'
import { StoreModuleNames } from '@/store'
import { StoreGetters } from '@/store/store-types'

const useGetters = <T = any>(namespace: StoreModuleNames | null = null) => {
  const store = useStore()
  const mapName = (getter) => {
    if (!namespace) {
      return getter
    } else {
      return getter.replace(`${namespace}/`, '')
    }
  }

  return Object.fromEntries(
    Object.keys(store.getters)
      .filter((getter) => {
        if (namespace) {
          return getter.includes(`${namespace}/`)
        }
        return true
      })
      .map((getter) => [mapName(getter), computed(() => store.getters[getter])])
  ) as StoreGetters<T>
}

export default useGetters
