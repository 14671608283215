<template>
  <div class="border border-solid border-tmrw-gray rounded-lg p-2 my-2">
    <table class="w-full">
        <tr>
          <th class="p-2 text-left text-tmrw-blue text-base" v-for="(header, index) in headers" :key="`header_${index}`" >{{ header.label(cryoDevice) }}</th>
          <th rowspan="2" class="p-2 text-right align-middle scan-button">
            <div class="w-80 ml-auto">
              <ScanCryodevice
                :item="cryoDevice"
                :value="cryoDevice"
                skip-check
                scan-label="Scan CryoLabel"
                scan-icon="barcode"
                @changeValue="handleChange"
                :valid-scan-icon-class="['!text-tmrw-success', '!text-4xl']"
                :valid-scan-class="['!text-3.5xl', '!font-exo', '!justify-end']"
                :button-classes="['!text-3.5xl', '!py-3']"
                :button-icon-classes="['!text-3.5xl']"
              />
            </div>
          </th>
        </tr>
        <tr>
          <td
            v-for="(header, index) in headers" :key="`row_${index}`"
            :class="[
              'p-2',
              'text-left',
              'text-xl',
              'font-inconsolata',
              'w-44',
              ...header.classes()
            ]">
            {{ header.value(cryoDevice) }}
          </td>
        </tr>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

import ScanCryodevice from '@/components/ScanCryodevice/ScanCryodevice.vue'

import { HEADERS } from './helpers'

const emit = defineEmits([
'scanLabel'
])
const props = defineProps({
  cryoDevice: {
    type: Object,
    required: true
  },
  specimenType: {
    type: String,
    required: true
  }
})

const headers = ref(HEADERS)

const handleChange = (_, { cryodeviceBarcodeValidation }) => {
  if(cryodeviceBarcodeValidation) {
    emit('scanLabel', props.cryoDevice)

  }
}

</script>
<style lang="scss">
  .scan-button {
    min-width: 320px;
  }
</style>

