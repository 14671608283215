export default {
  displaySpinner({ commit }, message = '') {
    commit('displaySpinner', true)
    commit('spinnerMessage', message)
  },
  hideSpinner({ commit }) {
    commit('displaySpinner', false)
    commit('spinnerMessage', '')
  }
}
