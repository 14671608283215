import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ExternalClinic } from '@/types/externalClinic'
import { PatientViewSpecimen } from '@/types/specimen'
import { Sort } from '@/types/table'
import { buildSpecimenSourcesValueObject } from '@/helpers/specimenHelpers'

dayjs.extend(utc)

interface GetShippingSourceNameInput {
  externalClinics: ExternalClinic[]
  shippingSourceId: number
}

export const getShippingSourceName = ({
  externalClinics,
  shippingSourceId
}: GetShippingSourceNameInput): string => {
  const shippingSource: ExternalClinic = externalClinics.filter((clinic: ExternalClinic) => clinic.id === shippingSourceId)[0]
  return shippingSource?.name || ''
}

interface CheckSpecimenImportInput {
  externalClinics: ExternalClinic[]
  hasLocalSpecimen?: boolean
  image?: File | string
  specimen: PatientViewSpecimen
}

export const checkSpecimenImport = ({
  hasLocalSpecimen = true,
  specimen
}: CheckSpecimenImportInput): PatientViewSpecimen => {
  if (specimen.sources && hasLocalSpecimen) {
    const sourcesValue = buildSpecimenSourcesValueObject(specimen)

    return {
      ...specimen,
      sourcesValue
    }
  }

  const sourcesValue = { items: [] }
  return {
    ...specimen,
    sourcesValue
  }
}

interface UpdateSpecimenModalInput {
  getSpecimenImport?: Function
  options: PatientViewSpecimen[]
  optionsFilter: string
  sort: Sort
  shouldCheckSpecimenImport?: boolean
}

interface UpdateSpecimenModalOutput {
  options: PatientViewSpecimen[]
  sort: Sort
}

export const updateSpecimenModal = ({
  getSpecimenImport,
  options,
  optionsFilter,
  sort,
  shouldCheckSpecimenImport = false
}: UpdateSpecimenModalInput): UpdateSpecimenModalOutput => {
  const filteredOptions: PatientViewSpecimen[] = options.filter((specimen: PatientViewSpecimen) => specimen.specimenType === optionsFilter)
  const updatedOptions = shouldCheckSpecimenImport && getSpecimenImport
    ? filteredOptions.map((specimen, index) => getSpecimenImport(specimen, index))
    : filteredOptions

  return {
    sort,
    options: updatedOptions
  }
}
