<template>
  <img
    data-test="tmrw-logo"
    class="tmrw-logo"
    @click="onClickAppLogo"
    src="@/assets/ivfos-tmrw-logo.svg"
    :style="width ? `width: ${width}` : ''"
    :class="['tmrw-logo__img', color ? `tmrw-logo__img--${color}` : '']"
    alt="TMRW Logo"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'tmrw-logo',
  methods: {
    onClickAppLogo() {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(
        'Click on TMRW Logo',
        {
          component: 'TMRW Logo',
          'current-route': currentRoute,
          'view-name': viewName,
          'redirected-from': redirectedFrom,
          'clinic-name': this.appBaseConfig.clinicName,
          'site-name': this.appBaseConfig.siteName,
          'user-name': this.loggedUserInfo.loginName,
          'user-type': this.loggedUserInfo.userType,
          hostname
        }
      )
      this.$emit('onClickAppLogo')
    }
  },
  emits: ['onClickAppLogo'],
  props: {
    color: {
      required: false,
      type: [String, Boolean],
      validator(value) {
        return ['blue', '', false].indexOf(value) !== -1
      }
    },
    width: {
      required: false,
      type: String,
      // Validate if the widht passed has the right unit for css
      validator(value) {
        const cssUnits = [
          'em', 'ex', '%', 'px', 'cm', 'mm', 'in', 'pt', 'pc', 'ch', 'rem', 'vh', 'vw', 'vmin', 'vmax'
        ]
        const unit = value.replace(/[0-9]/g, '')
        return cssUnits.includes(unit)
      }
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig'])
  }
}
</script>

<style lang="scss">
  .tmrw-logo {
    &__img {
      &--blue {
        // Color blue generated using a css filter since the svg is loaded in an img element
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(183.6deg) brightness(0.84);
      }
    }
  }
</style>
