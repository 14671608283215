<template>
  <MainContainer data-test="add-shared-patient" gridSlots="select-patient">
    <TopHeader :back-btn="false">{{ topHeaderMessage }}</TopHeader>
    <loading-ui modal v-if="loading" />
    <ActionBar data-test="add-shared-patient__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>
        <MessageCard class="float-left">
          <span>{{ title }}</span>
        </MessageCard>
      </template>
      <template v-slot:right-side>
        <ButtonDynamic
          btnType="button"
          btnText="Close"
          btnStyle="secondary"
          @click="navigateBack"
          :isDisabled="loading"
        />
        <ButtonDynamic
          btnType="button"
          btnText="Done"
          btnStyle="primary"
          fontAwesomeIconClass="check-circle"
          showIcon
          addMarginLeft
          :isDisabled="doneDisabled"
          @click="handleAddSharedPatients"
        />
      </template>
    </ActionBar>
    <PatientSearch
      data-test="add-shared-patient__patient-search"
      :canCreatePatient="true"
      :selectedPatients="currentSelectedPatients"
      @setPatients="updatePatients"
      selectionMode="none"
    />
    <SelectedPatients v-if="hasSelectedPatients" :hidePrimaryPatient="true" />
  </MainContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { WHICH_PATIENT_WOULD_YOU_LIKE_TO_ADD } from '@/constants'
import toast from '@/config/toast'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import PatientSearch from '@/components/PatientSearch/PatientSearch.vue'
import SelectedPatients from '@/components/SelectedPatients/SelectedPatients.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'

export default {
  name: 'add-shared-patient',
  data() {
    return {
      cryodevicePatients: [],
      currentSelectedPatients: [],
      foundPatients: [],
      loading: false,
      title: WHICH_PATIENT_WOULD_YOU_LIKE_TO_ADD
    }
  },
  created() {
    this.cryodevicePatients = this.selectedPatients
    this.setPatients([])
  },
  methods: {
    ...mapActions('patientGroupModule', ['setPatients']),
    ...mapActions('specimensModule', ['addSharedPatients']),
    async handleAddSharedPatients() {
      const props = {
        specimenId: this.specimenId,
        patientIds: this.patientIds
      }
      await this.addSharedPatients(props)
      if (this.status === 'ok') {
        this.navigateBack()
      }
    },
    navigateBack() {
      this.$router.back()
    },
    updatePatients(patients) {
      this.foundPatients = patients
    }
  },
  computed: {
    ...mapGetters('patientGroupModule', { selectedPatients: 'patients' }),
    ...mapGetters('specimensModule', { status: 'statusSpecimens', errorMessage: 'errorMessage' }),
    cryodeviceBarcode() {
      return this.$route.params.cryodeviceBarcode
    },
    doneDisabled() {
      return this.loading || !this.hasSelectedPatients
    },
    hasSelectedPatients() {
      return this.selectedPatients.length
    },
    specimenId() {
      return this.$route.params.specimenId
    },
    patientIds() {
      return this.selectedPatients.map((patient) => patient.globalPatientNumber)
    },
    topHeaderMessage() {
      return `Adding Shared Patient to Cryodevice ID ${this.cryodeviceBarcode}`
    }
  },
  watch: {
    selectedPatients(newValue) {
      this.currentSelectedPatients = [...this.cryodevicePatients, ...newValue]
    },
    status(newStatus) {
      switch (newStatus) {
        case 'loading':
          this.loading = true
          break

        case 'error':
          this.loading = false
          if (this.errorMessage) {
            toast.error({ title: this.errorMessage })
          }
          break

        default:
          this.loading = false
          break
      }
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    MainContainer,
    MessageCard,
    LoadingUi,
    PatientSearch,
    SelectedPatients,
    TopHeader
  }
}
</script>
