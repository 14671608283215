// Taken from
// https://download.geonames.org/export/dump/countryInfo.txt

const ZIP_DATA = {
  AND: '^(?:AD)*(\\d{3})$',
  ALB: '^(\\d{4})$',
  ARM: '^(\\d{6})$',
  ARG: '^[A-Z]?\\d{4}[A-Z]{0,3}$',
  ASM: '96799',
  AUT: '^(\\d{4})$',
  AUS: '^(\\d{4})$',
  ALA: '^(?:FI)*(\\d{5})$',
  AZE: '^(?:AZ)*(\\d{4})$',
  BIH: '^(\\d{5})$',
  BRB: '^(?:BB)*(\\d{5})$',
  BGD: '^(\\d{4})$',
  BEL: '^(\\d{4})$',
  BGR: '^(\\d{4})$',
  BHR: '^(\\d{3}\\d?)$',
  BLM: '^(\\d{5})$',
  BMU: '^([A-Z]{2}\\d{2})$',
  BRN: '^([A-Z]{2}\\d{4})$',
  BRA: '^\\d{5}-\\d{3}$',
  BLR: '^(\\d{6})$',
  CAN: '^([ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\\d[ABCEGHJKLMNPRSTVWXYZ]\\d)$',
  CHE: '^(\\d{4})$',
  CHL: '^(\\d{7})$',
  CHN: '^(\\d{6})$',
  COL: '^(\\d{6})$',
  CRI: '^(\\d{5})$',
  CUB: '^(?:CP)*(\\d{5})$',
  CPV: '^(\\d{4})$',
  CXR: '^(\\d{4})$',
  CYP: '^(\\d{4})$',
  CZE: '^\\d{3}\\s?\\d{2}$',
  DEU: '^(\\d{5})$',
  DNK: '^(\\d{4})$',
  DOM: '^(\\d{5})$',
  DZA: '^(\\d{5})$',
  ECU: '^([a-zA-Z]\\d{4}[a-zA-Z])$',
  EST: '^(\\d{5})$',
  EGY: '^(\\d{5})$',
  ESP: '^(\\d{5})$',
  ETH: '^(\\d{4})$',
  FIN: '^(?:FI)*(\\d{5})$',
  FSM: '^(\\d{5})$',
  FRO: '^(?:FO)*(\\d{3})$',
  FRA: '^(\\d{5})$',
  GBR: '^([Gg][Ii][Rr]\\s?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2})$',
  GEO: '^(\\d{4})$',
  GUF: '^((97|98)3\\d{2})$',
  GGY: '^((?:(?:[A-PR-UWYZ][A-HK-Y]\\d[ABEHMNPRV-Y0-9]|[A-PR-UWYZ]\\d[A-HJKPS-UW0-9])\\s\\d[ABD-HJLNP-UW-Z]{2})|GIR\\s?0AA)$',
  GRL: '^(\\d{4})$',
  GLP: '^((97|98)\\d{3})$',
  GRC: '^(\\d{5})$',
  GTM: '^(\\d{5})$',
  GUM: '^(969\\d{2})$',
  GNB: '^(\\d{4})$',
  HND: '^([A-Z]{2}\\d{4})$',
  HRV: '^(?:HR)*(\\d{5})$',
  HTI: '^(?:HT)*(\\d{4})$',
  HUN: '^(\\d{4})$',
  IDN: '^(\\d{5})$',
  IRL: '^(D6W|[AC-FHKNPRTV-Y][0-9]{2})\\s?([AC-FHKNPRTV-Y0-9]{4})',
  ISR: '^(\\d{7}|\\d{5})$',
  IMN: '^((?:(?:[A-PR-UWYZ][A-HK-Y]\\d[ABEHMNPRV-Y0-9]|[A-PR-UWYZ]\\d[A-HJKPS-UW0-9])\\s\\d[ABD-HJLNP-UW-Z]{2})|GIR\\s?0AA)$',
  IND: '^(\\d{6})$',
  IRQ: '^(\\d{5})$',
  IRN: '^(\\d{10})$',
  ISL: '^(\\d{3})$',
  ITA: '^(\\d{5})$',
  JEY: '^((?:(?:[A-PR-UWYZ][A-HK-Y]\\d[ABEHMNPRV-Y0-9]|[A-PR-UWYZ]\\d[A-HJKPS-UW0-9])\\s\\d[ABD-HJLNP-UW-Z]{2})|GIR\\s?0AA)$',
  JOR: '^(\\d{5})$',
  JPN: '^\\d{3}-\\d{4}$',
  KEN: '^(\\d{5})$',
  KGZ: '^(\\d{6})$',
  KHM: '^(\\d{5})$',
  PRK: '^(\\d{6})$',
  KOR: '^(\\d{5})$',
  KWT: '^(\\d{5})$',
  KAZ: '^(\\d{6})$',
  LAO: '^(\\d{5})$',
  LBN: '^(\\d{4}(\\d{4})?)$',
  LIE: '^(\\d{4})$',
  LKA: '^(\\d{5})$',
  LBR: '^(\\d{4})$',
  LSO: '^(\\d{3})$',
  LTU: '^(?:LT)*(\\d{5})$',
  LUX: '^(?:L-)?\\d{4}$',
  LVA: '^(?:LV)*(\\d{4})$',
  MAR: '^(\\d{5})$',
  MCO: '^(\\d{5})$',
  MDA: '^MD-\\d{4}$',
  MNE: '^(\\d{5})$',
  MAF: '^(\\d{5})$',
  MDG: '^(\\d{3})$',
  MHL: '^969\\d{2}(-\\d{4})$',
  MKD: '^(\\d{4})$',
  MMR: '^(\\d{5})$',
  MNG: '^(\\d{6})$',
  MNP: '^9695\\d{1}$',
  MTQ: '^(\\d{5})$',
  MLT: '^[A-Z]{3}\\s?\\d{4}$',
  MDV: '^(\\d{5})$',
  MWI: '^(\\d{6})$',
  MEX: '^(\\d{5})$',
  MYS: '^(\\d{5})$',
  MOZ: '^(\\d{4})$',
  NCL: '^(\\d{5})$',
  NER: '^(\\d{4})$',
  NFK: '^(\\d{4})$',
  NGA: '^(\\d{6})$',
  NIC: '^(\\d{7})$',
  NLD: '^(\\d{4}\\s?[a-zA-Z]{2})$',
  NOR: '^(\\d{4})$',
  NPL: '^(\\d{5})$',
  NZL: '^(\\d{4})$',
  OMN: '^(\\d{3})$',
  PER: '^(\\d{5})$',
  PYF: '^((97|98)7\\d{2})$',
  PNG: '^(\\d{3})$',
  PHL: '^(\\d{4})$',
  PAK: '^(\\d{5})$',
  POL: '^\\d{2}-\\d{3}$',
  SPM: '^(97500)$',
  PRI: '^00[679]\\d{2}(?:-\\d{4})?$',
  PRT: '^\\d{4}-\\d{3}\\s?[a-zA-Z]{0,25}$',
  PLW: '^(96940)$',
  PRY: '^(\\d{4})$',
  REU: '^((97|98)(4|7|8)\\d{2})$',
  ROU: '^(\\d{6})$',
  SRB: '^(\\d{6})$',
  RUS: '^(\\d{6})$',
  SAU: '^(\\d{5})$',
  SDN: '^(\\d{5})$',
  SWE: '^(?:SE)?\\d{3}\\s\\d{2}$',
  SGP: '^(\\d{6})$',
  SHN: '^(STHL1ZZ)$',
  SVN: '^(?:SI)*(\\d{4})$',
  SJM: '^(\\d{4})$',
  SVK: '^\\d{3}\\s?\\d{2}$',
  SMR: '^(4789\\d)$',
  SEN: '^(\\d{5})$',
  SOM: '^([A-Z]{2}\\d{5})$',
  SLV: '^(?:CP)*(\\d{4})$',
  SWZ: '^([A-Z]\\d{3})$',
  TCA: '^(TKCA 1ZZ)$',
  THA: '^(\\d{5})$',
  TJK: '^(\\d{6})$',
  TKM: '^(\\d{6})$',
  TUN: '^(\\d{4})$',
  TUR: '^(\\d{5})$',
  TWN: '^(\\d{5})$',
  UKR: '^(\\d{5})$',
  USA: '^\\d{5}(-\\d{4})?$',
  URY: '^(\\d{5})$',
  UZB: '^(\\d{6})$',
  VAT: '^(\\d{5})$',
  VEN: '^(\\d{4})$',
  VIR: '^008\\d{2}(?:-\\d{4})?$',
  VNM: '^(\\d{6})$',
  WLF: '^(986\\d{2})$',
  MYT: '^(\\d{5})$',
  ZAF: '^(\\d{4})$',
  ZMB: '^(\\d{5})$',
  SCG: '^(\\d{5})$'
}

// Generic Regex, accepting alphanumeric and dashes
const GENERIC_REGEX = `^[a-z0-9][a-z0-9\\- ]{0,10}[a-z0-9]$`

export const validateZipCode = (zipcode, country) => {
  const regex = ZIP_DATA[country] || GENERIC_REGEX
  try {
    const re = new RegExp(regex)
    return re.test(zipcode)
  } catch(e) {
    return false
  }

}
