import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { setTicketIcons } from '@/helpers/setTicketIcons'
import overdue from '@/assets/images/ticketStates/overdue.svg'
import green from '@/assets/images/ticketStates/green.svg'
import inProgress from '@/assets/images/ticketStates/in-progress.svg'
import lock from '@/assets/images/ticketStates/lock-emr.svg'
import importCryoBeacon from '@/assets/images/ticketStates/import-cryo-beacon-blue.svg'
import emptyIcon from '@/assets/images/ticketStates/empty-icon.svg'
import batchIcon from '@/assets/images/ticketStates/batching.svg'

import { EMR_SOURCE_ID } from '@/constants'

dayjs.extend(utc)

const formatTickets = (ticket) => {
  const { procedureTime, state, source, procedureName } = ticket

  const isEmrTicket = source && source === EMR_SOURCE_ID
  const isSingleImport = procedureName.toLowerCase().includes('single import')
  const isBatchTicket = procedureName.toLowerCase().includes('batch')

  return {
    ...ticket,
    statusImage: {
      sources: setTicketIcons({
        procedureTime,
        state,
        icons: {
          overdue,
          green,
          inProgress,
          lock,
          importCryoBeacon,
          emptyIcon,
          batchIcon
        },
        isEmrTicket,
        isSingleImport,
        isBatchTicket
      })
    }
  }
}
/*
  Section OVERDUE - (can be schedule or in progress)
  Section IN PROGRESS - The rest of the tickets that are not overdue (can be schedule or inprogress)
  Section COMPLETE - Ticket that were executed today and were completed
*/

export default {
  tickets: (state) => state.tickets,
  moveLocationTickets: (state) => state.moveLocationTickets.map(formatTickets),
  ticketStatus: (state) => state.status,
  filter: (state) => state.filter,
  filterBiorepo: (state) => state.filterBiorepo,
  destinationOptions: (state) => state.destinationOptions,
  selectedDestination: (state) => state.selectedDestination
}
