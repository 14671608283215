import * as ss from '@/config/session-storage-help'
import { AxiosError } from 'axios'
import { textElipsis } from '../printPDF/helpers'

const errorsHandler = (error: AxiosError): AxiosError => {
  let message = error.message || 'An Error occurred'
  if (error?.response && error.response?.data) {
    const { status } = error.response
    const resMessage = error.response.data[0]?.message || message
    const errorCode = error.response.data[0]?.code || ''
    message = `${status}:${errorCode} ${textElipsis(resMessage, 35)}`

    switch (status) {
      case 400:
      case 404:
      case 500:
      case 502:
        // No change to message
        break
      case 401:
      case 403:
        // Clear storage
        window.sessionStorage.removeItem('clinic')
        window.sessionStorage.removeItem('app-state')
        ss.removeFieldSessionStorage(null, null) // calling with null params resets sessionStorage to default values
        if (window.location.pathname !== '/') {
          window.location.href = '/'
        }
        break
      default:
        // Generic simple message
        message = 'An error occurred'
        break
    }
  } else if (error.request) {
    // Request error
    message = 'No response received'
  }

  // Same as original or message formatted
  return {
    ...error,
    message
  }
}

export { errorsHandler }
