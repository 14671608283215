export default {
  setDocuments(state, documents) {
    state.documents = documents
  },
  setError(state, error) {
    state.error = error
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  }
}
