<template>
  <div
    v-if="open"
    class="w-screen h-screen flex items-center justify-center bg-black bg-opacity-75 absolute top-0 left-0 z-50"
  >
    <div class="bg-white p-6 rounded-lg w-6/12">
      <div class="w-full flex">
        <div class="w-11/12 text-tmrw-blue-dark text-3xl">
          {{ title }}
        </div>
        <div class="w-1/12 flex items-start justify-end">
          <i
            @click="handleCancel"
            class="far fa-times-circle fa-lg text-tmrw-blue-dark cursor-pointer text-xl" />
        </div>
      </div>
      <div class="w-12/12 text-tmrw-blue text-xl py-7">
        {{ message }}
      </div>
      <div class="flex justify-end">
        <button-dynamic
          class="mr-4"
          btnType="button"
          :btnText="cancelButtonText"
          btnStyle="secondary"
          @click="handleCancel"
        />
        <button-dynamic
          btnType="button"
          :btnText="saveButtonText"
          btnStyle="primary"
          @click="handleSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'modal-with-action',
  data() {
    return {
      error: '',
      processingSubmit: false
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false
    },
    onSave: {
      type: Function,
      required: false
    },
    onCancel: {
      type: Function,
      required: false
    },
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    saveButtonText: {
      type: String,
      default: 'Save'
    }
  },
  methods: {
    handleCancel() {
      this.onCancel && this.onCancel()
    },
    handleSave() {
      this.onSave && this.onSave()
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>
