<template>
  <div data-test="scroll-table-ticket-number-field">
    <Tooltip :visible="visibility" placement="bottom">
      <template #content>
        <div class="flex flex-col">
          <p class="text-xs">{{ INFECTIOUS_SCREENING_STATUS }}</p>
          <ScreeningStatusLabel
            class="scroll-table-screening-status"
            :label="true"
            :id="Number(item.screeningStatus) || 0"
          />
        </div>
      </template>
      <div class="grid grid-cols-12 gap-2" @mouseover="showToolTip" @mouseleave="hideToolTip">
        <div class="col-span-3">
          <ScreeningStatusLabel
            class="scroll-table-screening-status -mt-0.5"
            :label="false"
            :id="Number(item.screeningStatus) || 0"
          />
        </div>
        <div>
          <p v-if="columnOptions.componentOptions.withScreeningStatusIcon" class="float-left">
            {{ item.ticketId }}
          </p>
        </div>
      </div>
    </Tooltip>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { INFECTIOUS_SCREENING_STATUS } from '@/constants'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'

defineProps({
  value: {
    type: [String, Number],
    default: '',
    required: true
  },
  options: {
    type: Object,
    default: () => {},
    required: false
  },
  columnOptions: {
    type: Object,
    default: () => {},
    required: false
  },
  item: {
    type: Object,
    default: () => {},
    required: false
  }
})

const visibility = ref(false)

const showToolTip = () => {
  visibility.value = true
}

const hideToolTip = () => {
  visibility.value = false
}
</script>
