<template>
  <div
  v-for="({ screeningStatus, key, label }, index) in gridScreeningStatus"
  :key="key"
  :class="['flex flex-col border-solid border-r-2 border-tmrw-gray pr-20', { 'pb-6': index === 0 }]"
>
    <div
      data-test="ticket-list-screening-status"
      class="mt-0 mb-1 font-bold text-xs text-tmrw-blue"
    >
      {{ infectiousScreeningStatus.toUpperCase() }}
    </div>
    <div data-test="ticket-list-screening-status" class="mb-1 font-bold text-xs text-tmrw-blue">
      {{ label }}
    </div>
    <div v-if="screeningStatus">
      <!-- @vue-ignore -->
      <ScreeningStatusLabel
        class="scroll-table-screening-status text-xl"
        icon
        label
        textColor="tmrw-blue-dark"
        iconColor="tmrw-blue-dark"
        align="left"
        paddingSmall
        :id="Number(screeningStatus) || 0"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { INFECTIOUS_SCREENING_STATUS } from '@/constants'
import { computed, ref } from 'vue'

const props = defineProps(['shipmentDetails'])

const infectiousScreeningStatus = ref(INFECTIOUS_SCREENING_STATUS)

const gridScreeningStatus = computed<any[]>(() => {
  const gridStatus: any = []
  const topGrid = props.shipmentDetails.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === 'TOP'
  )
  const bottomGrid = props.shipmentDetails.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === 'BOTTOM'
  )
  const smallGrid = props.shipmentDetails.cryoshipperGrids.find(
    ({ cryoshipperGridType }) => cryoshipperGridType === 'SMALL'
  )
  if (topGrid?.tickets.length > 0) {
    gridStatus.push({
      key: 'top',
      screeningStatus: topGrid.screeningStatus,
      label: '(Top CryoGrid)'
    })
  }
  if (bottomGrid?.tickets.length > 0) {
    gridStatus.push({
      key: 'bottom',
      screeningStatus: bottomGrid.screeningStatus,
      label: '(Bottom CryoGrid)'
    })
  }

  if (smallGrid?.tickets.length > 0) {
    gridStatus.push({
      key: 'medium',
      screeningStatus: smallGrid.screeningStatus,
      label: ''
    })
  }

  return gridStatus
})
</script>
