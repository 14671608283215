import {
  PROCEDURE_TYPE_BATCH,
  INVENTORY_UPDATE_REQUIRED,
  INVENTORY_UPDATE_DONE,
  TICKET_COMPLETE,
  ROBOT_TRANSFER_IN,
  PROCEDURE_TYPE_FREEZE,
  PROCEDURE_TYPE_DONATION_TO_PATIENT,
  PROCEDURE_MISSING_CRYOBEACON,
  PROCEDURE_TYPE_THAW,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  PROCEDURE_TYPE_EXPORT,
  IN_PROGRESS,
  TICKET_STATUS_PENDING,
  TICKET_SCHEDULED,
  PROCEDURE_TYPE_MOVE,
  INVENTORY_UPDATE_NOT_REQUIRED,
  REPLACE_CRYOBEACON,
  TICKET_COMPLETE_READY,
  EMR_SOURCE_ID,
  PROCEDURE_TYPE_BEACON_MOVE,
  TICKET_CANCELLED,
  DESTINATION,
  TICKET_COMPLETED,
  TICKET_IN_PROGRESS,
  ROBOT_TRANSFER_OPTIONS,
  PROCEDURE_TYPE_CRYOSHIPPER,
  PROCEDURE_TYPE_BATCH_UPPERCASE
} from '@/constants'
import { Ticket, TicketStatusIcon } from '@/types/ticket'
import { isUUID } from '@/utils/'
import { isDone, isNotReady, isReady } from './cryoBeaconLocation'
import {
  CHECK_CIRCLE_ICON,
  EDIT_ICON,
  EDIT_ICON_CIRCLE,
  EDIT_ICON_SUCCESS
} from '@/constants/icons'
import { replaceStringFilter } from '@/filters'
import { getCryoBeaconLocationByType } from '@/helpers/cryoBeaconLocation'

export const isInventoryUpdateDone = (ticket: Ticket) =>
  ticket?.inventoryUpdate === INVENTORY_UPDATE_DONE
export const isInventoryUpdateNotRequired = (ticket: Ticket) =>
  ticket?.inventoryUpdate === INVENTORY_UPDATE_NOT_REQUIRED
export const isInventoryUpdateRequired = (ticket: Ticket) =>
  ticket?.inventoryUpdate === INVENTORY_UPDATE_REQUIRED

export const isTicketCompleted = (ticket: Ticket) => ticket?.state === TICKET_COMPLETE
export const isTicketCancelled = (ticket: Ticket) => ticket?.state === TICKET_CANCELLED
export const isInProgress = (ticket: Ticket) => ticket?.state === IN_PROGRESS
export const isScheduled = (ticket: Ticket) => ticket?.state === TICKET_SCHEDULED
export const isStateCompleteReady = (ticket: Ticket) => ticket?.state === TICKET_COMPLETE_READY

export const isCryobeaconDiscarded = (ticket: Ticket) => ticket?.beaconDiscarded || false
export const isDestination = (location) => location.locationType === DESTINATION

export const isBeaconMoveProcedure = (ticket: Ticket) =>
  ticket?.procedureType === PROCEDURE_TYPE_BEACON_MOVE
export const isDonationToPatientProcedure = (ticket: Ticket) =>
  ticket?.procedureType === PROCEDURE_TYPE_DONATION_TO_PATIENT
export const isExportProcedure = (ticket: Ticket) => ticket?.procedureType === PROCEDURE_TYPE_EXPORT
export const isFreezeProcedure = (ticket: Ticket) => ticket?.procedureType === PROCEDURE_TYPE_FREEZE
export const isMoveProcedure = (ticket: Ticket) =>
  ticket?.procedureType?.includes(PROCEDURE_TYPE_MOVE)
export const isThawProcedure = (ticket: Ticket) => ticket?.procedureType === PROCEDURE_TYPE_THAW
export const isBatch = (ticket: Ticket) => ticket?.procedureName === PROCEDURE_TYPE_BATCH
export const isCryoShipper = (ticket: Ticket) =>
  ticket?.procedureType === PROCEDURE_TYPE_CRYOSHIPPER
export const isSingleImportProcedure = (ticket: Ticket) =>
  ticket?.procedureType === PROCEDURE_TYPE_SINGLE_IMPORT
export const isFreezeOrImportProcedure = (ticket: Ticket) =>
  isFreezeProcedure(ticket) || isSingleImportProcedure(ticket)
export const isReplaceCryoBeaconProcedure = (ticket: Ticket) =>
  ticket?.procedureType === REPLACE_CRYOBEACON

export const isMissingEmbryologist = (ticket: Ticket) => ticket?.embryologistId === 0

export const isRobotTransferIn = (ticket: Ticket) => ticket?.robotTransfer === ROBOT_TRANSFER_IN

// if the ticket has a ticket subState as pending will be non-editable and non-cancellable, please refer to the point
// 27 on Missing CryoBeacon PRD
export const isSubStatePending = (ticket: Ticket) => ticket?.substate === TICKET_STATUS_PENDING
export const isEMRTicket = (ticket: Ticket) => ticket?.source === EMR_SOURCE_ID
export const isEMRInProgressTicket = (ticket: Ticket) =>
  ticket?.source === EMR_SOURCE_ID && ticket.state === IN_PROGRESS

/*
 * @reference An EMR single import ticket is considered "Completed" when the beacon details have been added.
 * When the ticket gets first created the beaconBarcode is an UUID. Once the beacon details have been added the
 * beaconBarcode is no longer an UUID.
 */
export const emrTicketNeedBeaconScan = (ticket: Ticket) =>
  isEMRTicket(ticket) && isUUID(ticket?.beaconBarcode)

export function isMissingCryoBeaconProcedure(ticket: Ticket) {
  return ticket?.procedureType === PROCEDURE_MISSING_CRYOBEACON && !isTicketCompleted(ticket)
}

export function isBatchProcedure(viewTicket) {
  /*
    @refence viewTicketType : { batch || general } or null
    in case of batch ticket we should check the local reference
    in order to identify if it is a batch or a regular ticket
    as this reference is not coming inside of (batch) -> the child tickets
    in case of a regular tickets the reference comes as general
  */
  const viewTicketType = viewTicket?.ticketType || null
  return viewTicketType && viewTicketType === PROCEDURE_TYPE_BATCH.toLowerCase()
}

export function canUpdateInventory(ticket: Ticket, viewTicket) {
  switch (true) {
    // Special Cases
    case isTicketCompleted(ticket):
    case isBatchProcedure(viewTicket):
    case isDonationToPatientProcedure(ticket) && isInventoryUpdateDone(ticket):
    case isFreezeProcedure(ticket) && isMissingEmbryologist(ticket):
    case isMissingCryoBeaconProcedure(ticket):
      return false
    // Main case to decide if update invnetory is possible
    case isRobotTransferIn(ticket) &&
      (isInventoryUpdateRequired(ticket) || isInventoryUpdateDone(ticket)):
      return true
    default:
      return false
  }
}
export const canCompleteTicket = (ticket: Ticket, viewTicket) => {
  if (isBatchProcedure(viewTicket)) return false
  return (
    (isScheduled(ticket) &&
      isRobotTransferIn(ticket) &&
      isEMRTicket(ticket) &&
      isFreezeOrImportProcedure(ticket)) ||
    isMissingCryoBeaconProcedure(ticket) ||
    (isFreezeProcedure(ticket) && isScheduled(ticket))
  )
}

const canCancelAddToExisting = (ticket: Ticket) => isInventoryUpdateNotRequired(ticket)

export const canCancelFreezeOrImport = (ticket: Ticket) => {
  const isNewBeacon = isRobotTransferIn(ticket)
  if (isNewBeacon) {
    return isInventoryUpdateRequired(ticket) || isInventoryUpdateDone(ticket)
  }

  // This means that robotTransfer is OUT (Add to Existing Beacon)
  return canCancelAddToExisting(ticket)
}

export function isTicketCancellable(ticket: Ticket): boolean {
  return !!ticket.canCancel
}

const isTicketCompletedForIcon = (ticket, location) => {
  return isStepDone(ticket, location) || isCryobeaconDiscarded(ticket)
}

const isTicketInProgressForIcon = (ticket) => {
  return !isBeaconMoveProcedure(ticket) && isInventoryUpdateDone(ticket)
}

const isTicketScheduledForIcon = (ticket) => {
  return isInventoryUpdateRequired(ticket)
}

const getTicketScheduledIcon = (ticket, location): TicketStatusIcon => {
  if (
    isNotReady(location.transferStatus) ||
    (isReplaceCryoBeaconProcedure(ticket) && !isDestination(location))
  ) {
    return { icon: EDIT_ICON, name: TICKET_SCHEDULED }
  }
  return { icon: EDIT_ICON_CIRCLE, name: TICKET_SCHEDULED }
}

export const getTicketStatusIcon = (ticket, location): TicketStatusIcon | null => {
  if (isTicketCompletedForIcon(ticket, location)) {
    return { icon: CHECK_CIRCLE_ICON, name: TICKET_COMPLETED }
  }

  if (isTicketInProgressForIcon(ticket)) {
    return { icon: EDIT_ICON_SUCCESS, name: TICKET_IN_PROGRESS }
  }

  if (isTicketScheduledForIcon(ticket)) {
    return getTicketScheduledIcon(ticket, location)
  }
  return null
}

export const isStepDone = (ticket, location) =>
  isTicketCompleted(ticket) || isDone(location.transferStatus) // Check if the current location step is done

export const isStepDoneCryoShipper = (ticket) => ticket.state === 'DONE'

export const hasSourceAndDestination = (ticket) => {
  if (isCryoShipper(ticket)) {
    return true
  }
  return ticket.robotLocations.length > 1
}

export const getCircleClass = (ticket, location, baseCss = '') => {
  const circleBaseCss = `justify-center rounded-full items-center ${baseCss}`
  if (hasSourceAndDestination(ticket) && isNotReady(location.transferStatus)) {
    return `${circleBaseCss} border-tmrw-gray border-dashed`
  }

  if (
    (hasSourceAndDestination(ticket) &&
      !isDestination(location) &&
      isReady(location.transferStatus)) ||
    isBatch(ticket) ||
    emrTicketNeedBeaconScan(ticket) ||
    ((isMoveProcedure(ticket) || isCryoShipper(ticket)) && isReady(location.transferStatus))
  ) {
    return `${circleBaseCss} border-tmrw-blue border-solid shadow-md`
  }
  return ''
}

export const getTotalNumTickets = (items): Number =>
  items.reduce((acum, curr) => {
    if (curr?.children?.length) {
      return acum + curr.children.length
    }

    return acum + 1
  }, 0)

export const getTotalCryoShipperTickets = (items): number => {
  return items.filter((item) => item.procedure === PROCEDURE_TYPE_CRYOSHIPPER).length
}

export const getTotalRelocationTickets = (items): number => {
  return items.filter((item) => item.procedure === PROCEDURE_TYPE_BEACON_MOVE).length
}

export const getTotalBatchTickets = (items): number => {
  return items.filter((item) => item.procedure === PROCEDURE_TYPE_BATCH_UPPERCASE).length
}

export const setProcedureValue = (value, item) => {
  if (item.children && item.children.length) {
    const robotTransfer = ROBOT_TRANSFER_OPTIONS.find(
      (option) => item.robotTransfer === option.key
    ) as { key: string; value: string }
    return `<strong>${robotTransfer.value}</strong>`
  }
  return item.procedureName || value
}

export const sortProcedure = () => {
  const key = 'procedureName'
  const fieldAsc = (a, b) => {
    const strA = replaceStringFilter(a[key]).toLowerCase()
    const strB = replaceStringFilter(b[key]).toLowerCase()
    return strA.toLowerCase().localeCompare(strB.toLowerCase())
  }
  const fieldDesc = (a, b) => {
    const strA = replaceStringFilter(a[key]).toLowerCase()
    const strB = replaceStringFilter(b[key]).toLowerCase()
    return strB.toLowerCase().localeCompare(strA.toLowerCase())
  }

  return { fieldAsc, fieldDesc }
}

export const sortByNonTmrwLocation = () => {
  const fieldAsc = (a, b) => {
    const valueA = a.robotLocations.filter((x) => x.type === 'Source')[0]?.locationDetails || ''
    const valueB = b.robotLocations.filter((x) => x.type === 'Source')[0]?.locationDetails || ''

    const nameA = valueA
    const nameB = valueB

    if (nameA && typeof nameA === 'string') {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  const fieldDesc = (a, b) => {
    const valueA = a.robotLocations.filter((x) => x.type === 'Source')[0]?.locationDetails || ''
    const valueB = b.robotLocations.filter((x) => x.type === 'Source')[0]?.locationDetails || ''

    const nameA = valueB
    const nameB = valueA

    if (nameA && typeof nameA === 'string') {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }

  return { fieldAsc, fieldDesc }
}

export const sortByRobotLocations = (location) => {
  const fieldAsc = (a, b) => {
    const valueA = a.robotLocations.filter((x) => x.type === location)
    const valueB = b.robotLocations.filter((x) => x.type === location)
    const nameA = getCryoBeaconLocationByType(valueA, location)?.robotName ?? ''
    const nameB = getCryoBeaconLocationByType(valueB, location)?.robotName ?? ''

    if (nameA && typeof nameA === 'string') {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  const fieldDesc = (a, b) => {
    const valueA = a.robotLocations.filter((x) => x.type === location)
    const valueB = b.robotLocations.filter((x) => x.type === location)
    const nameA = getCryoBeaconLocationByType(valueB, location)?.robotName ?? ''
    const nameB = getCryoBeaconLocationByType(valueA, location)?.robotName ?? ''

    if (nameA && typeof nameA === 'string') {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  return { fieldAsc, fieldDesc }
}

export const sortByPatientName = () => {
  const fieldAsc = (a, b) => {
    const nameA = a.patient.lastName
    const nameB = b.patient.lastName

    if (nameA && typeof nameA === 'string') {
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  const fieldDesc = (a, b) => {
    const nameA = a.patient.lastName
    const nameB = b.patient.lastName

    if (nameB && typeof nameB === 'string') {
      return nameB.toLowerCase().localeCompare(nameA.toLowerCase())
    }
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  return { fieldAsc, fieldDesc }
}
