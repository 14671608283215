<template>
  <div class="flex w-2/5 scroll-table-status-field-element">
    <Tooltip
      v-for="icon in statusIcons"
      :content="icon.tooltip"
      placement="left"
      :key="icon.tooltip"
    >
      <div class="relative">
        <div v-if="icon.img" class="w-10 h-10 py-3">
          <img
            :src="icon.img"
            @click="(event: MouseEvent) => icon.onClick && icon.onClick(event)"
            class="w-5 h-5"
          />
        </div>

        <i v-else class="p-3 leading-10 text-xl" :class="icon.icon" @click="icon.onClick" />

        <i v-if="icon.subIcon" class="p-0.5 sub-icon" :class="icon.subIcon" />
      </div>
    </Tooltip>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TICKET_COMPLETE, EMR_SOURCE_ID, TICKET_IN_PROGRESS } from '@/constants'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import inProgress from '@/assets/ticketState/in-progress.svg'

dayjs.extend(utc)

type StatusIcon = {
  icon: string
  tooltip: string
  onClick?: Function
  subIcon?: string
  img?: string
}

const props = defineProps({
  value: {
    type: [Object, String],
    required: true
  },
  item: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const statusIcons = computed(() => {
  const icons: StatusIcon[] = []
  const ticket = props.item
  const { procedureTime, state, source, procedureName } = ticket

  const isEmrTicket = source && source === EMR_SOURCE_ID
  const isSingleImport = procedureName.toLowerCase().includes('single import')
  const isBatchTicket = procedureName.toLowerCase().includes('batch')
  const isComplete = state === TICKET_COMPLETE
  const begginingOfDay = dayjs().utcOffset(0).startOf('day').valueOf()

  // Incomplete Tickets from the day before and on are Overdue
  const isOverDue = procedureTime < begginingOfDay && !isComplete

  if (isOverDue) {
    icons.push({
      icon: 'far fa-clock text-tmrw-error',
      tooltip: 'Overdue'
    })
  }
  if (isEmrTicket) {
    icons.push({
      icon: 'fa fa-lock',
      tooltip: 'EMR'
    })
  }
  if (isSingleImport) {
    icons.push({
      icon: 'fa fa-file-medical',
      tooltip: 'Import'
    })
  }

  if (state === TICKET_IN_PROGRESS) {
    icons.push({
      icon: 'fa fa-history',
      tooltip: TICKET_IN_PROGRESS,
      img: inProgress
    })
  } else if (state === TICKET_COMPLETE) {
    icons.push({
      icon: 'fa fa-check-circle text-tmrw-success',
      tooltip: TICKET_COMPLETE
    })
  }

  if (isBatchTicket) {
    icons.push({
      icon: 'fa fa-link',
      tooltip: 'Batch'
    })
  }

  return icons
})
</script>
<style lang="scss">
.bg {
  @apply w-10 h-10 rounded-full bg-white shadow-icon;
}
.sub-icon {
  @apply w-4 h-4 shadow-icon leading-4 absolute text-xs -bottom-1 -right-1 rounded-full bg-white;
}
</style>
