/* eslint-disable no-param-reassign */
import getProp from 'lodash.get'
import {
  AVAILABLE_FOR_USE,
  TABLE_FIELDS_ALLOWS_EMPTY_VALUE,
  REASON,
  ROBOT_LOCATIONS,
  COMPLETE,
  DESTINATION,
  SOURCE,
  PROCEDURE_TYPE_BEACON_MOVE
} from '@/constants'

const evaluateContent = (column, value, item) => {
  const { content } = column
  if (content) {
    return content(item)
  }

  return value
}

const getPlaceholderValue = (key) => {
  if (key === REASON) {
    return AVAILABLE_FOR_USE
  }

  const allowEmptyValue = TABLE_FIELDS_ALLOWS_EMPTY_VALUE.includes(key)
  if (allowEmptyValue) return ''
  return '--'
}

export const handleRobotTransfer = (column, value, item) => {
  const { key } = column
  if (key === ROBOT_LOCATIONS && value?.length) {
    const locationType = item.status === COMPLETE ? DESTINATION : SOURCE
    const location =
      item.procedureType === PROCEDURE_TYPE_BEACON_MOVE
        ? value.find((field) => field.type === locationType)
        : value[0]

    if (location) {
      if (item?.robotTransfer) {
        location.robotTransfer = item.robotTransfer
      }
      location.isCurrentLocation = true
    }
  }
  return value
}

const processFilters = (column, value, item) => {
  const { filters } = column
  if (!filters) return value

  filters.forEach((filter) => {
    value = filter(value, item)
  })
  return value
}

export const getFieldValue = (column, item) => {
  const { key } = column
  let value = getProp(item, key, '')
  value = evaluateContent(column, value, item)
  value = processFilters(column, value, item)
  // TODO: Check if this actually needed
  value = handleRobotTransfer(column, value, item)

  if (!value) {
    value = getPlaceholderValue(key)
  }

  return value
}
