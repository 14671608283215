<template>
  <section
    data-test="action-bar"
    class="flex w-full"
    :class="{
      'ml-4': addMarginLeft,
      'mr-4': addMarginRight,
      'mt-4': addMarginTop,
      'mb-4': addMarginBottom,
    }"
  >
    <div
      :class="[
        `w-${leftSideCols}/12 flex justify-start`,
        setLeftSideToBottom ? 'items-end' : 'items-center',
      ]">
      <slot name="left-side" />
    </div>
    <div :class="`w-${rightSideCols}/12 flex items-center justify-end`">
      <slot name="right-side" />
    </div>
  </section>
</template>

<script lang="ts">
  export default {
    name: 'action-bar'
  }
</script>

<script lang="ts" setup>
import { computed } from 'vue'


const props = defineProps({
    colsDistribution: {
      type: String,
      default: '4/8',
      required: true
    },
    // Add margin to the left.
    addMarginLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    // Set Flex Items prop to Bottom.
    setLeftSideToBottom: {
      type: Boolean,
      default: false,
      required: false
    }
  })

  const leftSideCols = computed(() => props.colsDistribution.split('/')[0])
  const rightSideCols = computed(() => props.colsDistribution.split('/')[1])

</script>
