<template>
  <router-link
    :to="value.to"
    :title="label"
    v-if="!hidden"
    class="scroll-table-link-field inline-block text-center text-tmrw-blue border-tmrw-blue border border-solid px-4 py-3 rounded-lg"
  >
    <i v-if="value.icon" class="text-tmrw-blue text-sm" :class="['fas', `fa-${value.icon}`, 'mr-1']" />
    <span class="text-tmrw-blue font-bold font-exo ml-2 text-sm">
      {{ label }}
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'scroll-table-link-field',
  props: {
    /**
     * Used to pass in the "to" and the "icon" name values
     */
    value: {
      type: Object,
      default: () => ({
        icon: null,
        to: null
      })
    },
    /**
     * Label that goes beside icon as part of the link as well
     */
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    linkTarget() {
      if (typeof this.value === 'string') {
        return this.value
      }
      if (typeof this.value === 'object') {
        return this.value.to || ''
      }
      return ''
    },
    hidden() {
      return Boolean(this.value.hidden)
    },
    icon() {
      if (typeof this.value === 'string') {
        return this.value.toLowerCase()
      }
      if (typeof this.value === 'object') {
        return this.value.icon || ''
      }
      return ''
    }
  }
}
</script>
