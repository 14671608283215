/* eslint-disable */
export default {
  newPatient(state, patient) {
    state.patient = patient
  },
  success(state) {
    state.status = 'completed'
  },
  error(state, error) {
    state.status = 'error'
    state.error = error
  },
  validationErrors(state, validationErrors) {
    state.validationErrors = validationErrors
  },
  loading(state) {
    state.status = 'loading'
  }
}
