<template>
  <div
    v-if="isDisabled"
    class="modular-label__field-item__disabled">
    {{ MODULAR_LABEL_FIELDS_TEXT[item.id] }}
    <div :data-testid="`modular-label-remove-${item.id}`" class="modular-label__field-remove" @click="handleRemove(item.id)">X</div>
  </div>

  <div
    v-else
    class="grid-stack-item modular-label__field-item"
    gs-h="1"
    gs-max-h="1"
    draggable="true"
    :gs-id="item.id"
    :key="item.id"
    :gs-w="item.minW"
    :gs-max-w="item.maxW"
    :gs-min-w="item.minW"
    :gs-no-resize="item.minW === item.maxW"
  >
    <div class="grid-stack-item-content">
      <div
        class="modular-label__field-item__title">
        {{ MODULAR_LABEL_FIELDS_TEXT[item.id] }}
      </div>
      <div class="modular-label__tooltip" :data-tooltip-text="MODULAR_LABEL_FIELDS_TEXT[item.id]">
        <div class="modular-label__title">{{ MODULAR_LABEL_FIELDS_TEXT[item.id] }}</div>
      </div>
      <div class="cell-content">
        <span v-for="index in 32" :key="index">X</span>
      </div>
    </div>
  </div>
</template>

<script>
import { MODULAR_LABEL_FIELDS_TEXT } from '@/constants/modular-cryolabel'

export default {
  name: 'modular-label-list-item',
  data() {
    return {
      MODULAR_LABEL_FIELDS_TEXT
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean
    },
    onRemove: {
      type: Function
    }
  },
  computed: {
    isDisabled() {
      return !!this.isSelected
    }
  },
  methods: {
    handleRemove(itemId) {
      this.onRemove(itemId)
    }
  }
}
</script>
