<template>
  <div
    data-testid="reprint-label-modal"
    class="z-20 fixed w-screen h-screen flex bg-black bg-opacity-50 justify-center items-center top-0 left-0"
    v-if="showLabelModal"
  >
    <div class="bg-white rounded-md sm:w-4/5 md:w-6/12 lg:w-5/12 xl:w-6/12 p-6 relative">
      <ActionBar data-test="reprint-label__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left">Reprint</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            data-test="reprint-label__close-button"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="closeAlert"
          />
        </template>
      </ActionBar>
      <p class="text-tmrw-error mt-4" v-if="!isPrinterActive">
        <i class="fas fa-exclamation-circle text-2xl" />
        TMRW Label printer not detected. Label printing will default to OS print dialog.
      </p>
      <div class="flex mt-4">
        <custom-select
          :options="printerOptions"
          @update:modelValue="setSelectedPrinter"
          :modelValue="selectedPrinter"
          isDefaultOptionDisabled
          isDefaultOptionHidden
          class="mr-10"
          defaultOptionLabel="Default Printer"
        />
      </div>
      <div class="flex bg-tmrw-green-light rounded mt-4 items-center justify-center">
        <section id="labelToReprint" class="w-[320px] bg-white text-black my-2 pt-1 mx-4 shadow">
          <label-view
            :label="this.activeBeacon.cryoDevice[this.cryoDeviceIndex]"
            :labelIndex="cryoDeviceIndex"
            key="reprintLabel"
          />
        </section>
      </div>
      <div class="mt-6">
        <button-dynamic
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="print"
          btnText="Reprint"
          data-test="reprint-label__reprint-button"
          @click="reprintLabel"
        />
        <button-dynamic
          btnType="button"
          btnText="Cancel"
          btnStyle="transparent-blue"
          data-test="reprint-label__cancel-button"
          addMarginRight
          @click="closeAlert"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import LabelView from '@/components/LabelView/LabelView.vue'

import { ZEBRA_LABEL_PRINTERS_ID } from '@/constants'
import { getInstalledPrinters, setPrinterName } from '@/modules/printer'

export default {
  name: 'reprint-label',
  data() {
    return {
      labelPrinterActive: false,
      selectedPrinter: 'pdf',
      printerConfig: null,
      printerJobError: false,
      activePrinters: [],
      isPrinterActive: false,
      printerOptions: []
    }
  },
  emits: ['clickPrint', 'closeLabelModal'],
  props: {
    showLabelModal: {
      type: Boolean,
      required: true
    },
    cryoDeviceIndex: {
      type: Number,
      required: false
    },
    activeBeacon: {
      type: Object,
      required: true
    },
    activeTicket: {
      type: Object,
      required: true
    }
  },
  methods: {
    setSelectedPrinter(printerOption) {
      this.selectedPrinter = printerOption
    },
    getPrinterName(printerName) {
      return setPrinterName(printerName)
    },
    reprintLabel() {
      const dateTime = dayjs().utc().valueOf()
      this.$emit('clickPrint', {
        dateTime,
        reason: '',
        selectedPrinter: this.selectedPrinter
      })
    },
    closeAlert() {
      this.$emit('closeLabelModal')
    }
  },
  async mounted() {
    // Browser Print Setup
    const localPrinters = await getInstalledPrinters()
    if (localPrinters.printer.length) {
      this.isPrinterActive = true
    } else {
      this.selectedPrinter = 'pdf'
    }
    localPrinters.printer.forEach((printer) => {
      ZEBRA_LABEL_PRINTERS_ID.forEach((printerId) => {
        if (printer.name.includes(printerId)) {
          this.selectedPrinter = printer.uid
          this.activePrinters.push(printer)
        }
      })
    })
    const activePrintersFormated = []
    this.activePrinters.forEach((printer) => {
      const printerName = this.getPrinterName(printer.name)
      const printerUID = printer.uid
      activePrintersFormated.push({
        value: printerUID,
        label: printerName
      })
    })
    this.printerOptions = [
      {
        value: 'pdf',
        label: 'Print PDF'
      },
      ...activePrintersFormated
    ]
  },
  components: {
    ButtonDynamic,
    DynamicTitle,
    ActionBar,
    CustomSelect,
    LabelView
  }
}
</script>
