import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_INVENTORY_ACTION_PRESENT,
  SPECIMEN_INVENTORY_ACTION_NOT_PRESENT,
  SPECIMEN_INVENTORY_ACTION_DISCARDED,
  TICKET_COMPLETE_READY
} from '@/constants'
import {
  inventoryActionHeaders,
  donationToPatientInventoryActionHeaders
} from '@/constants/table-headers/new-inventory-update'
import {
  embryoHeaders,
  eggHeaders,
  matchingEggHeaders
} from '@/constants/table-headers/inventory/specimens'


const NOT_PRESENT_VALID_ACTIONS = [
  SPECIMEN_INVENTORY_ACTION_NOT_PRESENT,
  SPECIMEN_INVENTORY_ACTION_DISCARDED
]
const VALID_INVENTORY_ACTIONS = [
  SPECIMEN_INVENTORY_ACTION_PRESENT,
  ...NOT_PRESENT_VALID_ACTIONS
]

function getSpecimenHeadersByType({ specimenType, useMatchingEggHeaders }) {
  const eggHeadersToUse = useMatchingEggHeaders ? matchingEggHeaders : eggHeaders
  switch (true) {
    case (specimenType === SPECIMEN_TYPE_EMBRYO):
      return embryoHeaders
    case (specimenType === SPECIMEN_TYPE_EGG):
      return eggHeadersToUse
    default:
      return []
  }
}

export function getHeaders({ specimenType, useMatchingEggHeaders }) {
  const specimenHeaders = getSpecimenHeadersByType({ specimenType, useMatchingEggHeaders })
  return [...specimenHeaders, ...inventoryActionHeaders]
}

export function getDonationToPatientHeaders({
  specimenType, isInSelectedTicket, useMatchingEggHeaders, state
}) {
  const specimenHeaders = getSpecimenHeadersByType({ specimenType, useMatchingEggHeaders })
  const isSecondDonationToPatientTicket = state === TICKET_COMPLETE_READY

  if (isInSelectedTicket) {
    const { firstTicketHeaders, secondTicketHeaders } = donationToPatientInventoryActionHeaders
    const ticketHeader = isSecondDonationToPatientTicket ? secondTicketHeaders : firstTicketHeaders
    return [
      ...specimenHeaders,
      ...ticketHeader.selectedSpecimens
    ]
  }

  return [
    ...specimenHeaders,
    ...inventoryActionHeaders
  ]
}

export function getDiscardBeaconModalMessage() {
  return `You have marked all specimens as ${SPECIMEN_INVENTORY_ACTION_NOT_PRESENT} or ${SPECIMEN_INVENTORY_ACTION_DISCARDED}`
}

export default {
  getHeaders,
  getDonationToPatientHeaders,
  getDiscardBeaconModalMessage,
  remainsInInventoryAction: SPECIMEN_INVENTORY_ACTION_PRESENT,
  validInventoryActions: VALID_INVENTORY_ACTIONS,
  notPresentValidActions: NOT_PRESENT_VALID_ACTIONS
}
