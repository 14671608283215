import { postCreatePatientApi } from '@/services/patients'

export const createPatient = async ({ commit, rootState }, params) => {
  const { appBaseConfig: { collectionProtocolId } } = rootState.authModule

  commit('loading')
  try {
    const payload = { ...params, collectionProtocolId }
    const response = await postCreatePatientApi({ payload })

    commit('newPatient', response.data)
    commit('success')
    return response.data
  } catch (error) {
    commit('error', error)
    let validationErrors = []
    /* eslint-disable-next-line no-unused-expressions */
    error.response?.data.forEach((item) => {
      if (item.code === 'PATIENT_ALREADY_EXISTS') {
        validationErrors = [...validationErrors, `A patient with the same Identification Number '${params.identificationNumber}' already exists.`]
      } else if (item.message.indexOf('Cannot parse date') !== -1) {
        validationErrors = [...validationErrors, `${item.message}. Please enter the correct date format.`]
      } else {
        validationErrors = [...validationErrors, item.message]
      }
    })
    commit('validationErrors', validationErrors)
    throw error
  }
}

export const setValidationErrors = ({ commit }, validationErrors) => {
  commit('validationErrors', validationErrors)
}

export default {
  createPatient,
  setValidationErrors
}
