import toast from '@/config/toast'
import {
  getUsersApi,
  postResetPasswordRequestApi,
  postChangePasswordApi,
  postForgotPasswordApi,
  patchUpdateUserApi,
  postSetUserApi,
  getUserByIdApi,
  getUserRolesApi,
  getUserEmailAlertsApi,
  updateUserEmailAlertsApi,
  patchSetUserEmailAlertsApi,
  getActiveUsersApi
} from '@/services/user'

import {
  CreateUserBody,
  UpdateUserBody,
  UserAlert,
  ResetPasswordBody,
  ForgotPasswordBody,
  ChangePasswordBody
} from '@/services/user/types'

import { UsersMutations } from '../mutations'
import { StoreActionContext } from "@/store/store-types"


type UsersActionContext = StoreActionContext<UsersMutations>

const actions = {
  async resetPassword({ commit }: UsersActionContext, params: ResetPasswordBody) {
    commit('loadingResetPasswordStatus')
    try {
      const response = await postResetPasswordRequestApi(params)
      const { data } = response
      commit('successResetPasswordStatus')
      return data
    } catch (error: any) {
      /* eslint-disable-next-line no-unused-expressions */
      error.response?.data.forEach((item) => {
        commit('error', item.message)
      })
      throw new Error(error.message)
    }
  },
  async forgotPassword({ commit }: UsersActionContext, params: ForgotPasswordBody) {
    commit('loadingResetPasswordStatus')
    try {
      const response = await postForgotPasswordApi(params)

      const { data } = response
      commit('successResetPasswordStatus')
      return data
    } catch (error) {
      commit('errorResetPasswordStatus', error)
      throw error
    }
  },
  async changePassword({ commit }: UsersActionContext, params: ChangePasswordBody) {
    commit('loadingResetPasswordStatus')
    try {
      const response = await postChangePasswordApi(params)
      const { data } = response
      commit('successResetPasswordStatus')
      return data
    } catch (error: any) {
      commit('errorResetPasswordStatus', error)
      throw Error(error.response.data[0].message)
    }
  },
  async createUser({ commit }: UsersActionContext, [params]: [CreateUserBody]) {
    commit('loading')
    try {
      const response = await postSetUserApi(params)
      const { data } = response
      commit('createUser', data)
      commit('success')
      return data
    } catch (error) {
      commit('error', error)
      throw error
    }
  },
  async setUserEmailAlerts({ commit }: UsersActionContext, [userId, alerts]: [number, UserAlert[]]) {
    commit('loading')
    try {
      await patchSetUserEmailAlertsApi({ userId, alerts })
      commit('success')
    } catch (error) {
      commit('error', error)
      throw error
    }
  },
  async editUser({ commit }: UsersActionContext, params: UpdateUserBody) {
    commit('loading')
    try {
      const response = await patchUpdateUserApi(params)
      const { data } = response
      commit('editUser', data)
      commit('success')
      toast.success('User Updated Successfully')
      return data
    } catch (error) {
      commit('error', error)
      throw error
    }
  },
  async fetchUsers({ commit }: UsersActionContext) {
    commit('loading')
    try {
      const response = await getUsersApi()
      const { data } = response
      commit('fetchUsers', data)
      commit('success')
      return data
    } catch (error: any) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  async fetchActiveUsers({ commit }: UsersActionContext) {
    commit('loading')
    try {
      const response = await getActiveUsersApi()
      const { data } = response
      commit('fetchUsers', data)
      commit('success')
      return data
    } catch (error: any) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  async fetchUser({ commit }: UsersActionContext, userId: number) {
    commit('loading')
    try {
      const response = await getUserByIdApi({ userId })
      const { data } = response
      commit('fetchUser', data)
      commit('success')
      return data
    } catch (error: any) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  async fetchUserRoles({ commit }: UsersActionContext) {
    commit('loading')
    try {
      const response = await getUserRolesApi()
      const { data } = response
      commit('fetchUserRoles', data)
      commit('success')
      return data
    } catch (error: any) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  async fetchUserEmailAlerts({ commit }: UsersActionContext, userId: number) {
    commit('loading')
    try {
      const response = await getUserEmailAlertsApi({ userId })
      const { data } = response
      commit('fetchUserEmailAlerts', data)
      commit('success')
      return data
    } catch (error: any) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  async editUserEmailAlerts({ commit }: UsersActionContext, [userId, alerts]: [number, UserAlert[]]) {
    commit('loading')
    try {
      const response = await updateUserEmailAlertsApi({ userId, alerts })
      const { data } = response
      commit('editUserEmailAlerts', data)
      commit('success')
      toast.success('User Email Alerts Updated Successfully')
      return data
    } catch (error) {
      commit('error', error)
      throw error
    }
  }
}

export type UsersActions = typeof actions

export default actions
