<template>
  <MainContainer data-test="add-existing-beacon" gridSlots="8">
    <loading-ui v-if="isLoading" modal :message="`Processing Selected ${CRYOBEACON}`" />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <div class="grid grid-cols-12 my-4">
      <div class="col-span-8">
        <dynamic-title titleType="h2" floatTo="left">
          There is space in existing {{ CRYOBEACON }}(s) for this patient
        </dynamic-title>
      </div>
      <div class="col-span-4">
        <button-dynamic
          btnType="button"
          btnText="Select"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle"
          :is-disabled="!currentBeacon?.barcode"
          @click="onSelectButtonClicked"
        />
      </div>
    </div>
    <BreadCrumbs :items="getActiveBreadcrumbs" />
    <existing-cryo-beacons :handleAddNewCryoBeacon="handleAddNewCryoBeacon" />
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import ExistingCryoBeacons from '@/components/ExistingCryoBeacons/ExistingCryoBeacons.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import { ROBOT_TRANSFER_OUT, CRYOBEACON, ROBOT_TRANSFER_IN } from '@/constants'
import { useTicket } from '@/composables/useTicket'
import { applyBeaconBarcodeToCryoDevices } from '@/helpers/manageCryoDevice'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { getBreadcrumbs } from '@/helpers/newTicketHelpers'
import { handleTicketUpdate } from '@/helpers/updateTicket'
import toast from '@/config/toast'

export default {
  name: 'add-existing-beacon',
  data() {
    return {
      fromRouteName: null,
      CRYOBEACON,
      isLoading: false,
      metadata: null
    }
  },
  setup() {
    const {
      ticket,
      activeBeacon,
      replaceBeaconInBeaconList,
      saveTicket,
      isEditingTicket
    } = useTicket()
    return {
      ticket,
      activeBeacon,
      replaceBeaconInBeaconList,
      saveTicket,
      isEditingTicket
    }
  },
  components: {
    TopHeader,
    BreadCrumbs,
    ButtonDynamic,
    ExistingCryoBeacons,
    MainContainer,
    DynamicTitle,
    HeaderInfo,
    LoadingUi
  },
  beforeRouteEnter(to, from, next) {
    // we need to account for multiple screens that route to ScanBeacon, in
    // order to be able to route back (eg.: click back button)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next((vm) => {
      // access to component instance via `vm`
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod', 'selectedPatient']),
    ...mapGetters('newTicketModule', {
      selectedBeacons: 'beacons'
    }),
    ...mapGetters('beaconsModule', ['currentBeacon', 'beaconBarcodeNumber', 'scannedBeaconBarcodes']),
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })
      return currentBreadcrumbs
    }
  },
  methods: {
    ...mapActions('beaconsModule', ['setBeaconBarcode']),
    ...mapActions('newTicketModule', ['setRobotTransfer']),
    ...mapMutations('ticketModule', ['setTicketRobotTransfer', 'setMetadata']),
    async handleAddNewCryoBeacon() {
      // If we are using a new beacon, robot transfer is IN!
      // Therefore, set robot transfer
      this.setRobotTransfer(ROBOT_TRANSFER_IN)
      this.setTicketRobotTransfer(ROBOT_TRANSFER_IN)

      this.metadata = {
        addedNewBeaconHavingExistingSpace: true
      }
      this.setMetadata(this.metadata)

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        metadata: this.metadata
      })

      this.$router.push({ name: nextStepPath })
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })
      this.$router.replace({ name: previousStepPath })
    },
    async onSelectButtonClicked() {
      this.handleExistingBeacon({
        ticket: this.ticket,
        currentBeacon: this.currentBeacon,
        isEditingTicket: this.isEditingTicket()
      })
    },
    // eslint-disable-next-line no-shadow
    async handleExistingBeacon({ ticket, currentBeacon, isEditingTicket }) {
      const { barcode } = currentBeacon
      const { activeBeacon } = this
      const { cryoDevice } = activeBeacon
      this.isLoading = true

      try {
        this.setBeaconBarcode(barcode)
        // On Add to Existing Beacon procdedure, Transfer Type is always OUT.
        await this.setRobotTransfer(ROBOT_TRANSFER_OUT)
        await this.setTicketRobotTransfer(ROBOT_TRANSFER_OUT)

        const updatedCryoDevice = applyBeaconBarcodeToCryoDevices(cryoDevice, barcode)
        const updatedActiveBeacon = {
          ...activeBeacon,
          cryoDevice: updatedCryoDevice
        }
        const updatedBeacons = this.replaceBeaconInBeaconList(ticket.beacons, updatedActiveBeacon)
        const updatedTicket = {
          ...ticket,
          beacons: updatedBeacons,
          robotTransfer: ROBOT_TRANSFER_OUT
        }

        this.saveTicket(updatedTicket)

        if (isEditingTicket) {
          await handleTicketUpdate({
            router: this.$router,
            ticket: updatedTicket,
            collectionProtocolId: this.appBaseConfig.collectionProtocolId,
            beaconBarcodeNumber: this.beaconBarcodeNumber,
            loggedUserId: this.loggedUserInfo.userId
          })
        } else {
          const nextStepPath = await executeNextTicketStep({
            selectedMethod: this.selectedMethod,
            currentTicketStep: this.currentTicketStep
          })

          this.$router.push({ name: nextStepPath })
        }
      } catch(err) {
        toast.error({title: `Error processing selected ${CRYOBEACON}`})
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
