<template>
  <section
    data-test="patient-documents"
    class="flex flex-col"
    v-if="showPatientDocuments"
  >
    <ActionBar
      data-test="patient-documents__action-bar"
      cols-distribution="6/6"
    >
      <template v-slot:left-side>
        <dynamic-title
          title-type="h2"
          float-to="left"
          class="mb-2"
        >
          {{PATIENT_DOCUMENTS}}
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Add Document"
          showIcon
          btnStyle="secondary"
          addMarginBottom
          fontAwesomeIconClass="cloud-upload-alt"
          :isDisabled="!canAddDocument"
          @click="addDocument"
        />
      </template>
    </ActionBar>
    <ScrollTableComponent
      v-if="hasDocuments"
      data-test="documents__table"
      :headers="DOCUMENT_HEADERS"
      v-model="documentsModel"
      minHeight="h-full"
    />
    <EmptyListPlaceholder
      v-else
      message="No documents added yet"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import EmptyListPlaceholder from '@/components/EmptyListPlaceholder/EmptyListPlaceholder.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { PATIENT_DOCUMENTS } from '@/constants'
import { headers as DOCUMENT_HEADERS } from '@/constants/table-headers/documents'
// import {
//   isFeatureEnabled,
//   ADD_DOCUMENTS_ENABLED_FF
// } from '@/helpers/featureFlags'

export default {
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    EmptyListPlaceholder,
    ScrollTableComponent
  },
  data() {
    return {
      documentsModel: {
        options: [],
        sort: {
          orderBy: 'createdAt',
          direction: 'desc'
        }
      },
      showPatientDocuments: false,
      DOCUMENT_HEADERS,
      PATIENT_DOCUMENTS
    }
  },
  async created() {
    // const documentTypes = await this.fetchDocumentTypes()
    // this.showPatientDocuments = isFeatureEnabled(ADD_DOCUMENTS_ENABLED_FF)
    // this.$ss.setFieldSessionStorage('documentTypes', documentTypes)
    // await this.fetchPatientDocuments(this.patientId)
  },
  computed: {
    ...mapGetters('documentsModule', ['documents']),
    hasDocuments() {
      return Boolean(this.documents.length)
    },
    canAddDocument() {
      return this.documents.length < 10
    },
    patientId() {
      return this.$route.params.patientId
    }
  },
  methods: {
    ...mapActions('documentsModule', ['fetchDocumentTypes', 'fetchPatientDocuments'])
  },
  watch: {
    documents(newValue) {
      this.documentsModel.options = newValue
    }
  }
}
</script>
