<template>
  <section v-if="items" data-testid="breadcrumbs" data-test="breadcrumbs" class="w-full block h-12">
    <div
      v-for="(elem, index) in items"
      :key="index"
      data-test="breadcrumb"
      :class="[
        'font-bold float-left',
        size === 'large' ? 'text-xl' : 'text-base',
        elem.active ? 'text-tmrw-green-light' : 'text-tmrw-blue-light'
      ]"
    >
      {{ elem.label }}
      <span
        class="mx-4"
        :class="[
          elem.active && items.length - 1 === index
            ? 'text-tmrw-green-light'
            : 'text-tmrw-blue-light'
        ]"
        v-if="items.length - 1 !== index"
        >></span
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: {
    items: {
      type: Array,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    }
  }
}
</script>
