export default {
  patients: [{}],
  container: {},
  patientsFound: [],
  patientInventory: [],
  patient: {},
  donatePatient: {},
  specimensFromPatient: [],
  specimensClosedFromPatient: [],
  ticketsFromPatient: [],
  missingTicketsFromPatient: [],
  countryList: [],
  filter: '',
  error: null,
  statusPatient: 'completed',
  uploadId: 0,
  jobStatus: {
    status: null
  },
  updatedTicketId: [],
  donorId: null,
  shippingSource: null,
  newClinicName: null,
  newClinicAddress: null,
  externalClinics: [],
  patientTimestamp: null,
  createdClinic: null,
  specimenImportTypes: []
}
