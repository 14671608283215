/* eslint-disable */
export default {
  setFilter(state, filter) {
    state.filter = filter
  },
  resetState(state) {
    state.specimensFromPatient = []
    state.specimensClosedFromPatient = []
    state.ticketsFromPatient = []
  },
  setPatient(state, payload) {
    state.patient = Object.assign({}, payload.patient)
  },
  setDonatePatient(state, payload) {
    state.donatePatient = Object.assign({}, payload)
  },
  fetchSpecimens(state, payload) {
    state.specimensFromPatient = payload
  },
  fetchClosedSpecimens(state, payload) {
    state.specimensClosedFromPatient = payload
  },
  fetchPatientInventory(state, payload) {
    state.patientInventory = payload
  },
  getBeaconIdFromBarcode(state, payload) {
    state.container = payload
  },
  fetchDefaultSpecimens(state, payload) {
    state.specimensFromPatient = []
  },
  fetchPatients(state, patients) {
    state.patients = patients
  },
  searchPatients(state, patients) {
    state.patientsFound = patients
  },
  setPatientsEmpty(state) {
    state.patientsFound = []
  },
  fetchPatient(state, patient) {
    state.patient = patient
  },
  fetchOpenTicketsFromPatient(state, payload) {
    state.ticketsFromPatient = payload
  },
  fetchMissingTicketsFromPatient(state, payload) {
    state.missingTicketsFromPatient = payload
  },
  setCountries(state, value) {
    state.countryList = value
  },
  fetchUpload(state, value) {
    state.uploadId = value
  },
  success(state) {
    state.statusPatient = 'completed'
  },
  error(state, error) {
    state.statusPatient = 'error'
    state.error = error
  },
  loading(state) {
    state.statusPatient = 'loading'
  },
  setJobStatus(state, payload) {
    state.jobStatus = Object.assign({}, payload)
  },
  setDefaultJobStatus(state) {
    state.jobStatus = {}
  },
  updateScreeningStatus(state, payload) {
    state.updatedTicketId = payload
  },
  setDonorId(state,payload) {
    state.donorId = payload
  },
  setShippingSource(state, payload) {
    state.shippingSource = payload
  },
  setNewClinicName(state, payload) {
    state.newClinicName = payload
  },
  setNewClinicAddress(state, payload) {
    state.newClinicAddress = payload
  },
  getExternalClinics(state, payload) {
    state.externalClinics = payload
  },
  setCreatedClinic(state, payload) {
    state.createdClinic = payload
    state.externalClinics = [
      ...state.externalClinics,
      payload
    ]
  },
  setPatientTimestamp(state, payload) {
    state.patientTimestamp = payload
  },
  setSpecimenImportTypes(state, payload) {
    state.specimenImportTypes = payload
  }
}
