<template>
  <ul data-test="ticket-status-tag" class="flex items-end justify-end ticket-status-tags text-tmrw-warning h-9 mt-1">
    <slot v-if="showState">
      <li class="ticket-status-tags__item-status mr-3">
        <p class="flex items-center">
          <span data-test="ticket-status-tag-text" class="mr-1 font-medium text-lg">{{state}}</span>
          <img :width="statusIconWidth" :src="statusIconUrl" alt="" :class="statusIconClass">
        </p>
      </li>
    </slot>
    <li v-if="isEmr" class="ticket-status-tags__item ml-4">
      <p data-test="emr-tag" class="flex items-center mt-1">
        <span class="mr-1 font-medium text-lg">EMR Ticket</span>
        <i data-test="emr-icon" class="fas fa-lock w-4 h-4" />
      </p>
    </li>
  </ul>
</template>

<script>
import {
  TICKET_SCHEDULED,
  TICKET_IN_PROGRESS,
  TICKET_COMPLETE
} from '@/constants'
import lockIcon from '@/assets/images/ticketStates/lock-emr.svg'
import completeIcon from '@/assets/images/ticketStates/green.svg'
import scheduledIcon from '@/assets/images/ticketStates/scheduled.svg'
import inProgress from '@/assets/images/ticketStates/in-progress.svg'
import importCryoBeacon from '@/assets/images/ticketStates/import-cryo-beacon-blue.svg'

export default {
  name: 'ticket-status-tag',
  data() {
    return {
      lockIcon,
      importCryoBeacon,
      iconSettings: {
        [TICKET_IN_PROGRESS]: {
          url: inProgress,
          width: '24px',
          class: ''
        },
        [TICKET_SCHEDULED]: {
          url: scheduledIcon,
          width: '19px',
          class: 'svg-yellow'
        },
        [TICKET_COMPLETE]: {
          url: completeIcon,
          width: '23px',
          class: ''
        }
      }
    }
  },
  computed: {
    showState() {
      return !!this.state
    },
    statusIconUrl() {
      return this.iconSettings[this.state].url
    },
    statusIconWidth() {
      return this.iconSettings[this.state].width
    },
    statusIconClass() {
      return this.iconSettings[this.state].class
    }
  },
  props: ['state', 'isEmr']
}
</script>
