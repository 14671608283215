<template>
  <div
    :data-test="selectFieldDataTest"
  >
    <label
      v-if="label"
      :class="[
        'leading-tight text-base text-tmrw-blue-dark text-left font-medium',
      ]"
      data-test="field__test__label">{{label}}
    </label>
    <CustomSelect
      :name="fieldName"
      :data-test="customSelectDataTest"
      :modelValue="modelValue"
      :disabled="isDisabled"
      :options="options"
      :defaultOptionLabel="defaultOptionLabel"
      @update:modelValue="handleInput"
      addPaddingTop
      :hasFontInconsolata="hasFontInconsolata"
      addPaddingBottom
      addMarginTop
      addMarginBottom
    />
    <p
      data-test="select-error-alert"
      :class="[
        error ? 'opacity-100 text-tmrw-error' : 'hidden',
        'text-base text-left flex py-1',
      ]"
    >
      <span class="relative flex bg-white rounded-full w-4 h-4 justify-center items-center float-left mr-2">
        <i class="text-sm fas fa-exclamation-circle text-tmrw-error" />
      </span>
      <span>{{error}}</span>
    </p>
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'

export default {
  components: {
    CustomSelect
  },
  name: 'select-field',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    defaultOptionLabel: {
      type: String,
      default: 'Please select one'
    },
    addPaddingTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addPaddingBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    hasFontInconsolata: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    fieldName() { return (this.name || this.label || '').toLowerCase() },
    customSelectDataTest() { return `custom-select-${this.fieldName}` },
    selectFieldDataTest () { return `select-field-${this.fieldName}` }
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
