import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { timeStampInventoryCryoDateFilter } from '@/filters/index'
import { getCryodeviceType } from '@/helpers/cryodeviceType/index'
import { SPECIMEN_TYPE_EMBRYO, SPECIMEN_TYPE_OOCYTE, MAX_LABEL_LENGTH } from '@/constants'

dayjs.extend(utc)

const specimenId = [
  {
    name: '',
    key: 'specimenId',
    unique: true,
    visible: false
  }
]

const cryodeviceType = [
  {
    name: 'Cryodevice Type',
    key: 'cryodeviceTypeId',
    cssClasses: ['w-50'],
    sort: true,
    filters: [(value) => getCryodeviceType(value) || '']
  }
]

export const embryoHeaders = [
  ...specimenId,
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-44', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-32'],
    filters: [
      (value, item) => `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ]
  },
  {
    name: 'Embryo #',
    key: 'embryo',
    filters: [(value) => (value ? value.embryoNumber : '')],
    cssClasses: ['w-36']
  },
  {
    name: 'Grade',
    key: 'embryo',
    filters: [(value) => (value ? value.grade : '')],
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: 'Cryodate',
    key: 'cryoDate',
    filters: [timeStampInventoryCryoDateFilter],
    cssClasses: ['w-48'],
    sort: true
  },
  ...cryodeviceType,
  {
    name: 'Biopsy',
    key: 'embryo',
    filters: [(value) => (value ? value.biopsy : '')],
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: 'Results',
    key: 'embryo.biopsyResult',
    sort: true,
    cssClasses: ['w-32'],
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  }
]

export const eggHeaders = [
  ...specimenId,
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-44', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-32'],
    filters: [
      (value, item) => `${value} x ${item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    ]
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: [timeStampInventoryCryoDateFilter],
    cssClasses: ['w-48'],
    sort: true
  },
  ...cryodeviceType,
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  }
]

/*
 * Egg headers that match the same amount of headers as embryoHeaders have, to make both table look symetrical.
 */
export const matchingEggHeaders = [
  ...specimenId,
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-44', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-32'],
    filters: [
      (value, item) => `${value} x ${item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    ]
  },
  {
    name: '',
    alignment: 'left',
    key: '',
    sort: true,
    cssClasses: ['w-36']
  },
  {
    name: '',
    alignment: 'left',
    key: '',
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: [timeStampInventoryCryoDateFilter],
    cssClasses: ['w-48'],
    sort: true
  },
  {
    name: '',
    alignment: 'left',
    key: '',
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: '',
    alignment: 'left',
    key: '',
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  }
]
