import { httpRequest, httpRequestBulk } from '@/helpers/apiHelpers'
import {
  PostCreatePatientApi,
  GetPatientsApi,
  GetSpecimensApi,
  GetPatientByIdApi,
  GetPatientByCpIdApi,
  PostUploadApi,
  GetJobStatus,
  GetDownloadJobStatusApi,
  PostCreateExternalClinicApi,
  GetPatientCryoDevicesApi,
  PatchUpdatePatientApi,
  PatchUpdateScreeningStatusApi,
  GetBeaconIdFromBarcode
} from './types'

export const getPatientsApi = ({
  collectionProtocolId,
  params
}: GetPatientsApi) => httpRequest().get(`/patients?cpId=${collectionProtocolId}`, {
  params
})

export const getPatientByCpIdApi = ({
  id,
  collectionProtocolId
}: GetPatientByCpIdApi) => httpRequest().get(`/patients/${id}?cpId=${collectionProtocolId}`)

export const getPatientByIdApi = ({
  id,
  query
}: GetPatientByIdApi) => httpRequest().get(`/patients/${id}`, {
  params: query
})

export const getSpecimensApi = ({
  id,
  onlyInRobot,
  includeClosed,
  onlyClosed = false,
  onlyInClinic = true
}: GetSpecimensApi) => httpRequest().get(`/patients/${id}/specimens`, {
  params: {
    onlyInRobot,
    includeClosed,
    onlyClosed,
    onlyInClinic
  }
})

export const getPatientCryoDevicesApi = ({
  patientId,
  collectionProtocolId
}: GetPatientCryoDevicesApi) => httpRequest().get(`/patients/${patientId}/specimens?cpId=${collectionProtocolId}`)

export const postCreatePatientApi = ({
  payload
}: PostCreatePatientApi) => httpRequest().post('/patients', payload)

export const postUploadApi = ({
  formData, headers
}: PostUploadApi & {headers ?: {[key:string]:string } }) => httpRequest(true, headers).post('/patients/uploads', formData)

export const getJobStatusApi = ({
  jobId,
  collectionProtocolId
}: GetJobStatus) => httpRequest().get(`/patients/uploads/${jobId}?cpId=${collectionProtocolId}`)

export const getDownloadJobStatusApi = ({
  jobId
}: GetDownloadJobStatusApi) => httpRequest().get(`/patients/downloads/${jobId}`, {
  responseType: 'blob',
  headers: {
    'content-type': 'application/json',
    Accept: 'application/csv'
  }
})

export const getExternalClinicsApi = () => httpRequestBulk(false).get('/external-clinics')

export const postCreateExternalClinicApi = (
  data: PostCreateExternalClinicApi
) => httpRequestBulk(false).post('/external-clinics', data)

export const getCountriesApi = () => httpRequest(false).get('/countries')

export const patchUpdatePatientApi = ({
  params,
  collectionProtocolId,
  globalPatientNumber
}: PatchUpdatePatientApi) => httpRequest().patch(`/patients/${globalPatientNumber}`, {
  ...params, collectionProtocolId
})

export const patchUpdateScreeningStatusApi = ({
  beaconId,
  collectionProtocolId,
  specimens,
  patient
}: PatchUpdateScreeningStatusApi) => httpRequest().patch(
  `/beacons/${beaconId}/specimens?cpId=${collectionProtocolId}`,
  { ...specimens, patient }
)

export const getBeaconIdFromBarcode = ({
  unitId,
  beaconBarcode
}: GetBeaconIdFromBarcode) => httpRequest().get(
  `/units/${unitId}/containers?barcode=${beaconBarcode}`
)

export const fetchPatientsById = async (patientIds, query?) => {
  let patients: any[] = []
  if(!patientIds?.length) {
    // just return empty if there are no ids
    return []
  }
  try {
    const requests = patientIds.map((id) => getPatientByIdApi({ id, query }))
    const response = await Promise.all(requests)
    patients = response.map(({ data }) => data)
  } catch(e) {
    // patients error
  }
  return patients
}
