import {
  UNASSIGNED
} from '@/constants/index'

// NOTE: This mixin may become obsolete

export default {
  methods: {
    /*
    We need to pass cryoBeaconId, beacon id, etc as a param.
    Then we test that param against a RegEx to know if it matches the UUID Hash format.
    If so, we return an empty string, otherwise the Beacon ID.
    This magical method is to be used anywhere in the code, so please do not touch. It was very hard to write.
    */
    showIfValidCryoBeaconId(cryoBeaconId) {
      // eslint-disable-next-line no-nested-ternary
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(cryoBeaconId) ? UNASSIGNED : cryoBeaconId === '--' ? UNASSIGNED : cryoBeaconId
    },
    isUUID(cryoBeaconId) {
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(cryoBeaconId)
    }
  }
}
