<template>
  <section data-test="selected-patients">
    <loading-ui v-if="loading" :modal="true" :message="LOADING_SHARED_SPECIMENS" />
    <section v-else class="pb-4">
      <MessageCard data-test="selected-patients__title"><span>{{ SELECTED_PATIENTS }}</span></MessageCard>
      <PatientGroup
        data-test="selected-patients__patient-group"
        :key="patientGroupKey"
        :totalShared="totalSharedCryoDevices"
        :canChangePrimaryPatient="true"
        :canRemovePatient="true"
        :hidePrimaryPatient="hidePrimaryPatient"
        :patients="patients"
        :primaryPatientId="primaryPatientId"
        @changePrimaryPatient="handleChangePrimaryClick"
        @removePatient="handleRemovePatientClick"
      />
    </section>
    <ConfirmModal
      data-test="selected-patients__remove-modal"
      :confirmLabel="REMOVE"
      :message="removePatientMessage"
      :showModal="showRemoveModal"
      :title="REMOVE_PATIENT"
      @handleConfirmModal="removeSelectedPatient"
      @handleCloseModal="() => this.showRemoveModal = false"
    />
    <ConfirmModal
      data-test="selected-patients__warning-modal"
      :message="changePrimaryMessage"
      :showModal="showChangeModal"
      :title="WARNING"
      @handleConfirmModal="changePrimaryPatient"
      @handleCloseModal="() => this.showChangeModal = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  LOADING_SHARED_SPECIMENS,
  REMOVE,
  REMOVE_PATIENT,
  SELECTED_PATIENTS,
  WARNING
} from '@/constants'
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import PatientGroup from '@/components/PatientGroup/PatientGroup.vue'

export default {
  name: 'SelectedPatients',
  props: {
    hidePrimaryPatient: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      patientGroupKey: 0,
      selectedPatient: {}, // Will be used on confirm modals
      showChangeModal: false,
      showRemoveModal: false,

      LOADING_SHARED_SPECIMENS,
      REMOVE,
      REMOVE_PATIENT,
      SELECTED_PATIENTS,
      WARNING
    }
  },
  methods: {
    ...mapActions('patientGroupModule', ['removePatientFromGroup', 'setPrimaryPatientId']),
    changePrimaryPatient() {
      const { globalPatientNumber } = this.selectedPatient
      this.setPrimaryPatientId(globalPatientNumber)
      this.toggleChangeModal()
    },
    handleChangePrimaryClick(patientId) {
      this.selectPatient(patientId)
      this.toggleChangeModal()
    },
    handleRemovePatientClick(patientId) {
      this.selectPatient(patientId)
      this.toggleRemoveModal()
    },
    reloadPatientsTable() {
      this.patientGroupKey++
    },
    removeSelectedPatient() {
      const { globalPatientNumber } = this.selectedPatient
      this.removePatientFromGroup(globalPatientNumber)
      this.reloadPatientsTable()
      this.toggleRemoveModal()
    },
    selectPatient(patientId) {
      const selectedPatient = this.patients.find((patient) => patient.globalPatientNumber === patientId)
      this.selectedPatient = selectedPatient
    },
    toggleChangeModal() {
      this.showChangeModal = !this.showChangeModal
    },
    toggleRemoveModal() {
      this.showRemoveModal = !this.showRemoveModal
    }
  },
  computed: {
    ...mapGetters('patientGroupModule', ['loading', 'patients', 'primaryPatientId', 'totalSharedCryoDevices']),
    changePrimaryMessage() {
      const message = `
        From here you won't be able to change the Primary Patient.<br /><br />
        Are you sure that you want to assign <strong>${this.selectedPatient.fullName}</strong> as the primary patient?
      `
      return message
    },
    removePatientMessage() {
      const message = `
        Please confirm that you would like to remove the following patient:<br />
        <strong>${this.selectedPatient.fullName}.</strong>
      `
      return message
    }
  },
  components: {
    ConfirmModal,
    LoadingUi,
    MessageCard,
    PatientGroup
  }
}
</script>
