<template>
  <div
    v-if="showLabelModal"
    data-test="preview-labels"
    class="
      fixed
      top-0
      left-0
      bg-black bg-opacity-50
      w-screen
      h-screen
      flex
      justify-center
      items-center
      preview-labels
      z-1000
    "
  >
    <div data-test="preview-labels-body" class="bg-white p-4 rounded-md w-8/12">
      <ActionBar data-test="preview-labels__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left">Cryodevice Labels</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnDataTest="preview-labels__close-button"
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="closeAlert"
          />
        </template>
      </ActionBar>
      <div
        v-if="isMultiplePatientsWarningVisible"
        class="flex items-center justify-start w-full text-tmrw-warning font-bold py-2"
      >
        <i class="fa fa-exclamation-circle text-2xl mr-3" />
        Only the Primary Patient will appear on the Cryolabels
      </div>
      <div
        class="flex items-center justify-start w-full text-tmrw-error font-bold rounded py-2"
        v-if="!isPrinterActive"
      >
        <i class="fa fa-exclamation-circle text-2xl mr-3" /> TMRW Label printer not detected.
        Label printing will default to OS print dialog.
      </div>
      <div class="flex w-full">
        <div class="w-3/12 pr-4">
          <select data-test="label-printer-select" v-model="selectedPrinter" class="w-full border-b border-tmrw-blue border-solis pb-2 outline-none">
            <option value="pdf">Print PDF</option>
            <option v-for="(printer, index) in activePrinters" :key="index" :value="printer.uid">
              {{ getPrinterName(printer.name) }}
            </option>
          </select>
        </div>
      </div>
      <div data-test="preview-labels" id="preview-labels" class="flex flex-wrap my-4 bg-tmrw-green-light rounded-md">
        <div
          v-for="(label, labelIndex) in this.localLabels"
          :data-test="`single-label-wrapper-${label.cryodeviceBarcode}`"
          class="m-4 w-[320px] single-label"
          v-bind:key="label.cryodeviceBarcode"
        >
          <div :data-test="`preview-labels__cryodevice-id-print-${label.cryodeviceBarcode}`" class="font-inconsolata font-semibold cryo-label">Cryodevice ID {{label.cryodeviceBarcode}}</div>
          <label-view
            :data-test="`label-${label.cryodeviceBarcode}`"
            :label="label"
            :key="labelIndex"
            :labelIndex="labelIndex"
          />
        </div>
      </div>
      <ActionBar
        data-test="preview-labels__action-bar"
        colsDistribution="9/3"
      >
        <template v-slot:left-side>&nbsp;</template>
        <template v-slot:right-side>
          <button-dynamic
            data-test="dummy-print-labels-btn"
            btnType="button"
            btnText="Print"
            btnStyle="primary"
            :addClasses="['dummy-print-button', 'absolute']"
            customStyles="top: -99999px; left: -99999px;"
            @click="stubPrintJob"
          />
          <button-dynamic
            btnText="Cancel"
            btnType="button"
            btnStyle="transparent-blue"
            @click="closeAlert"
          />
          <button-dynamic
            btnDataTest="modal-print-btn"
            btnType="button"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="print"
            btnText="Print"
            addMarginLeft
            @click="sendPrintJob"
          />
        </template>
      </ActionBar>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import LabelView from '@/components/LabelView/LabelView.vue'
import { getInstalledPrinters, printMultipleLabels, setPrinterName } from '@/modules/printer'
import { SPECIMEN_TYPE_EMBRYO, ZEBRA_LABEL_PRINTERS_ID } from '@/constants'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'
import { labelToPdf } from '@/helpers/printPDF/labelToPdf'

export default {
  name: 'preview-labels',
  data() {
    return {
      isPrinterActive: false,
      labelPrinterActive: false,
      activePrinters: [],
      selectedPrinter: 'pdf',
      isMultiplePatientsWarningVisible: false
    }
  },
  components: {
    ButtonDynamic,
    DynamicTitle,
    ActionBar,
    LabelView
  },
  created() {
    this.isMultiplePatientsWarningVisible = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
  },
  computed: {
    localLabels() {
      return this.labels.map((label) => ({
        ...label,
        fullName: `${label.lastName.toLowerCase()},
        ${label.firstName.charAt(0).toLowerCase()}`,
        formattedCryodeviceBarcode: label.cryodeviceBarcode
          .replace(/(\d{8})/g, '$1 ')
          .replace(/(^\s+|\s+$)/, '')
      }))
    }
  },
  emits: ['closeLabelModal', 'clickPrint'],
  props: {
    labels: {
      type: Array,
      required: false
    },
    showLabelModal: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    // Browser Print Setup
    getInstalledPrinters().then((localPrinters) => {
      if (localPrinters.printer.length > 0) {
        this.isPrinterActive = true
      } else {
        this.selectedPrinter = 'pdf'
      }
      localPrinters.printer.forEach((printer) => {
        ZEBRA_LABEL_PRINTERS_ID.forEach((printerId) => {
          if (printer.name.includes(printerId)) {
            this.selectedPrinter = printer.uid
            this.activePrinters.push(printer)
          }
        })
      })
    })
  },
  methods: {
    getPrinterName(printerName) {
      return setPrinterName(printerName)
    },
    isEmbryo(specimenType) {
      return specimenType === SPECIMEN_TYPE_EMBRYO
    },
    stubPrintJob() {
      this.$emit('closeLabelModal', false)
      this.$emit('clickPrint')
    },
    sendPrintJob() {
      if (this.selectedPrinter !== 'pdf' && this.isPrinterActive) {
        this.activePrinters.forEach((printer) => {
          if (printer.uid === this.selectedPrinter) {
            printMultipleLabels(printer, this.localLabels).then(() => {
              this.$emit('closeLabelModal', false)
              this.$emit('clickPrint')
            })
          }
        })
      } else {
        this.printPDFLabel()
      }
    },
    printPDFLabel() {
      labelToPdf('preview-labels')
      this.$emit('closeLabelModal', false)
      this.$emit('clickPrint')
    },
    closeAlert() {
      this.$emit('closeLabelModal')
    }
  }
}
</script>
<style lang="scss">
.preview-labels {
  &__content {
    display: flex;
    justify-content: space-between;
    margin: 8px auto;
    width: 88%;

    &__label-title {
      padding: 10px;
    }

    &__name {
      text-align: left;
      width: 100px;
    }

    &__title {
      color: red;
      font-size: 40px;
      text-align: left;
      width: 100%;
    }

    &__cryodeviceBarcode {
      font-size: 1rem;
      line-height: 20px;
      padding-right: 5px;
      text-align: left;
      width: 100%;
    }

    &__cryodeviceBarcodeOocyte {
      font-size: 1rem;
      line-height: 20px;
      padding-right: 5px;
      text-align: left;
      width: 50%;
    }

    &__oocyte {
      font-size: 1.5rem;
      line-height: 20px;
      padding-right: 5px;
      text-align: right;
    }
  }

  &__cryodevice-id {
    font-size: 0.85rem;
    font-weight: bold;
  }
  &__image {
    height: 30px;
    margin: 10px 5px 0 5px;
    width: calc(100% - 10px);
  }
  &__content-name {
    width: 100px;
  }

  .modal-window {
    padding: 20px;
    &__wrapper {
      min-height: 320px;
      padding: 20px;
      width: 90%;
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      margin-right: 15px;
      width: calc(100% - 15px);
      .btn--clear {
        box-shadow: none;
      }
    }
    &__content {
      margin: 0px;
      &__display {
        width: 100%;
      }
      &__title {
        font-size: 40px;
        margin-top: 15px;
        text-align: left;
        width: calc(100% - 20px);
      }
      &__selectors {
        width: 100%;
        p {
          margin: 15px;
        }
      }
    }
  }
}
</style>
