export const ADD_EXISTING_BEACON_ROUTE = '/add-existing-beacon'
export const ADD_SHARED_PATIENT_ROUTE = (
  specimenId = ':specimenId',
  cryodeviceBarcode = ':cryodeviceBarcode'
) => `/specimen/${specimenId}/add-shared-patient/${cryodeviceBarcode}`

export const EDIT_DATE_ROUTE = '/edit-date'

export const INVENTORY_UPDATE_ROUTE = (
  globalPatientNumber = ':globalPatientNumber',
  ticketId = ':ticketId'
) => `/inventory-update/${globalPatientNumber}/${ticketId}`

export const PATIENT_VIEW_ROUTE = (patientId = ':patientId') => `/patients/${patientId}`

export const SCAN_BEACON_ROUTE = '/scan-beacon'
export const SELECT_SPECIMENS_ROUTE = '/select-specimens'
export const SELECT_SPECIMENS_TO_EXPORT_ROUTE = '/select-specimens-to-export'

export const SELECT_EMRBYOLOGIST_ROUTE = (ticketId = ':ticketId') =>
  `/select-embryologist/${ticketId}`

export const FLIGHT_BOARD_ROUTE = '/flight-board'

export const TICKETS_API_ENDPOINT = (parentTicketId, childTicketId) =>
  `/tickets/${parentTicketId}/children/${childTicketId}`
