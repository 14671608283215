import { Specimen } from '@/types'

export const calculateMaxSpecimen = (specimensFromPatient: Specimen[], globalPatientNumber: Number) => {
  const paddedGPN = globalPatientNumber.toString().padStart(8, '0')
  // Filters null/undefined (should never be the case) and outside labels
  const filteredCryodevices = specimensFromPatient.filter(({ cryodeviceBarcode }) => cryodeviceBarcode?.startsWith(paddedGPN))
  // Get the last 3 digits of the cryodevice barcode. Account for donated specimens that include "-donated-<timestamp>" at the end.
  const cryoDeviceBarcodes = filteredCryodevices.map(({ cryodeviceBarcode }) => Number(cryodeviceBarcode.split('-')[0].slice(-3)))

  /**
   * Get the greatest number of specimens as number, so we can operate on it.
   * This will return 0 if there is no specimens
   */
  const greatestSpecimenNumber = cryoDeviceBarcodes.length ? Math.max(...cryoDeviceBarcodes) : 0
  /**
   * If specimens from patient total count (including "Closed" specimens) is greater than
   * greatestSpecimenNumber, than we return the specimens array count.
   */
  if (specimensFromPatient.length > greatestSpecimenNumber) {
    return specimensFromPatient.length
  }

  return greatestSpecimenNumber
}
