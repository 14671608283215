<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section class="calendar py-2 w-full relative">
    <div v-if="isProcedureTypeSingleImport" class="w-full h-full absolute top-0 left-0 z-50">
      &nbsp;
    </div>
    <div class="flex flex-row bg-white rounded-t">
      <div data-test="calendar__header-title" class="w-3/12 text-left text-tmrw-blue p-4">
        Procedure Date
      </div>
      <div data-test="calendar__date-options" class="w-6/12 flex justify-center items-center">
        <div class="mr-2 flex flex-row items-center justify-center" v-if="calendarModes.now">
          <input
            type="radio"
            id="now"
            value="now"
            :checked="modelValue.mode === 'now'"
            @input="handleModeSelector"
            data-test="field__test__type"
            class="mr-1 h-4 w-4"
          />
          <label
            data-test="calendar__option-today"
            for="now"
            class="font-semibold font-exo text-tmrw-blue text-lg"
          >Today</label
          >
        </div>
        <div class="mx-2 flex flex-row items-center justify-center" v-if="calendarModes.single">
          <input
            type="radio"
            id="single"
            value="single"
            :checked="modelValue.mode === CALENDAR_MODE_SINGLE || isCalendarSingleModeSelected"
            @input="handleModeSelector"
            data-test="field__test__type"
            class="mr-1 h-4 w-4"
          />
          <label
            data-test="calendar__option-specific-date"
            for="single"
            class="font-semibold font-exo text-tmrw-blue text-lg"
          >Specific Date</label
          >
        </div>
        <div class="mx-2 flex flex-row items-center justify-center" v-if="calendarModes.range">
          <input
            type="radio"
            id="range"
            value="range"
            :checked="modelValue.mode === CALENDAR_MODE_RANGE"
            @input="handleModeSelector"
            data-test="field__test__type"
            class="mr-1 h-4 w-4"
          />
          <label
            data-test="calendar__option-date-range"
            for="range"
            class="font-semibold font-exo text-tmrw-blue text-lg"
          >Date Range</label
          >
        </div>
      </div>
      <div class="w-3/12">&nbsp;</div>
    </div>
    <div
      class="flex justify-center items-center bg-white rounded-b pb-4"
      :class="['calendar-date-picker', `calendar-date-picker--${modelValue.mode}`]"
    >
      <custom-calendar
        :value="modelValue.date"
        :mode="modelValue.mode"
        :minDate="new Date(minDate)"
        :maxDate="maximumDate"
        @onCalendarDateChange="handleDateInput"
      />
    </div>
    <!-- Time Picker-->
    <div
      class="bg-white rounded mt-4 p-4 flex"
      v-if="modelValue.mode === CALENDAR_MODE_SINGLE || isTimePickerVisible"
    >
      <div data-test="procedure-time" class="w-3/12 text-left text-tmrw-blue p-4">
        Procedure Time
      </div>
      <div class="w-6/12 flex justify-center items-center">
        <vue-timepicker
          data-test="time-picker"
          v-model="modelValue.time"
          @update:modelValue="($event) => $emit('update:modelValue', { ...modelValue, time: $event })"
          format="hh:mm A"
          class="timepicker"
          hide-clear-button
          placeholder="hh:mm a"
        />
      </div>
      <div class="w-3/12">&nbsp;</div>
    </div>
  </section>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CustomCalendar from '@/components/CustomCalendar/CustomCalendar.vue'
import {
  METHOD_IMPORT_LOWERCASE,
  CALENDAR_MODE_NOW,
  CALENDAR_MODE_SINGLE,
  CALENDAR_MODE_RANGE
} from '@/constants'

dayjs.extend(utc)

export default {
  name: 'calendar-card',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object
    },
    calendarModes: {
      type: Object,
      default: () => ({
        single: true,
        now: true,
        range: true
      })
    },
    isTimePickerVisible: {
      type: Boolean,
      default: false
    },
    procedureType: {
      type: String,
      default: '',
      required: false
    },
    minDate: {
      type: Number,
      required: true
    },
    maxDate: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      attribute: {
        highlight: {
          start: {
            style: {
              backgroundColor: 'rgba(22, 65, 112, var(--tw-text-opacity))'
            },
            contentStyle: {
              color: '#ffffff'
            }
          },
          base: {
            style: {
              backgroundColor: 'rgba(211, 255, 246, var(--tw-bg-opacity))'
            }
          },
          end: {
            style: {
              backgroundColor: 'rgba(22, 65, 112, var(--tw-text-opacity))'
            },
            contentStyle: {
              color: '#ffffff'
            }
          }
        }
      },
      CALENDAR_MODE_NOW,
      CALENDAR_MODE_SINGLE,
      CALENDAR_MODE_RANGE
    }
  },
  computed: {
    isCalendarSingleModeSelected() {
      return !this.calendarModes.now && this.calendarModes.single
    },
    isProcedureTypeSingleImport() {
      return this.procedureType.toLowerCase() === METHOD_IMPORT_LOWERCASE
    },
    maximumDate() {
      return this.modelValue.mode === 'now' && !this.maxDate ? dayjs() : this.maxDate
    }
  },
  methods: {
    handleDateInput(selectedDate) {
      let { mode } = this.modelValue
      let date = { start: null, end: null }

      if (mode === CALENDAR_MODE_NOW || mode === 'single') {
        date = { start: dayjs(selectedDate).startOf('day').valueOf(), end: null }
        mode = dayjs(selectedDate).startOf('day').valueOf() === dayjs().startOf('day').valueOf()
          ? CALENDAR_MODE_NOW
          : 'single'
      } else {
        // In case of 'range' the calendar send an Object {start: ..., end: ...}
        date = {
          start: selectedDate.start ? dayjs(selectedDate.start).startOf('day').valueOf() : null,
          end: selectedDate.end ? dayjs(selectedDate.end).startOf('day').valueOf() : null
        }
      }
      this.$emit('update:modelValue', {
        ...this.modelValue,
        date,
        mode
      })
    },
    handleModeSelector($event) {
      // eslint-disable-next-line
      let {
        time,
        date: { start }
      } = this.modelValue
      const { value: mode } = $event.target
      let date = {}

      // start = dayjs(start).startOf('day').valueOf()

      if (mode === 'now') {
        time = dayjs().format('hh:mm A')
      }

      if (mode === 'now') {
        date = { start: dayjs().startOf('day').valueOf(), end: null }
      } else if (mode === CALENDAR_MODE_RANGE) {
        // Pre select a range of 7 days
        date = {
          start: dayjs().add(1, 'day').valueOf(),
          end: dayjs(start).add(8, 'day').startOf('day').valueOf()
        }
      }
      this.$emit('update:modelValue', {
        date,
        mode,
        time
      })
    }
  },
  components: {
    VueTimepicker,
    CustomCalendar
  }
}
</script>

<style lang="scss">
.calendar:nth-child(1) {
  margin-right: 2rem !important;
}

.calendar:nth-child(2) {
  margin-left: 2rem !important;
}

.time-picker {
  @apply justify-center items-center relative;
  display: flex !important;
  width: 300px !important;

  &:before {
    content: '';
    @apply w-3 h-3 absolute right-3 transform top-1/2;
    transform: translateY(-50%);
    background: url('../../assets/arrow-down.svg') no-repeat;
    background-size: 12px;
  }

  input.display-time {
    width: 100% !important;
    border: 0 !important;
    border-bottom: 1px solid #164170 !important;
    @apply text-center font-inconsolata;
  }

  .dropdown {
    left: 50% !important;
    transform: translateX(-50%);
    @apply font-inconsolata;
  }
}

.calendar-date-picker {
  .vc-arrow {
    background: transparent;
    @apply rounded-full;
    @apply text-tmrw-blue;
    @apply shadow;
    &:hover {
      @apply bg-transparent;
    }
  }

  .vc-title {
    @apply font-inconsolata;
    @apply text-tmrw-blue;
    @apply font-semibold;
  }

  .date-picker {
    border: none;
    .vc-pane:start-child {
      @apply mr-8;
    }

    .vc-pane {
      @apply m-0;
      @apply relative;

      div.vc-highlight {
        @apply text-white;
        @apply rounded-full;
        @apply border;
        @apply border-tmrw-blue;
      }
      .vc-day-content[tabindex='-1']:hover {
        @apply bg-tmrw-green;
        @apply text-tmrw-blue;
        @apply rounded-full;
        @apply border;
        @apply border-tmrw-blue;
      }

      .vc-day-content {
        @apply mt-0;
        @apply font-inconsolata;
      }
      .vc-day-content.today {
        @apply text-white;
        @apply bg-tmrw-blue;
        &:before {
          @apply rounded-full;
        }
      }
      .vc-day-content.start {
        @apply text-tmrw-blue;
        &:before {
          @apply bg-tmrw-green;
          @apply rounded-full;
        }
      }

      .vc-weekday {
        @apply bg-transparent;
        @apply text-tmrw-gray;
        @apply border;
        @apply border-tmrw-gray;
        @apply font-normal;
      }
    }
  }
}
</style>
