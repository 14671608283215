<template>
  <MainContainer data-test="users-view" :gridSlots="loadingData ? '2' : '4'">
    <top-header>{{ pageTitle }}</top-header>
    <loading-ui v-if="loadingData" />
    <ActionBar v-if="!loadingData" data-test="patient-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">User Details</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnText="Close"
          btnType="button"
          btnStyle="secondary"
          @click="closeUser"
          addMarginRight
        />
        <button-dynamic
          test-id="user-details-reset-password"
          btnText="Reset Password"
          btnType="button"
          btnStyle="secondary"
          showIcon
          :isLoading="loadingResetPasswordStatus"
          fontAwesomeIconClass="key"
          :isDisabled="isUserLocked || isUserArchived"
          addMarginRight
          @click="handleResetPassword"
        />
        <button-dynamic
          v-if="!isUserArchived"
          btnText="Edit"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="edit"
          @click="updateUser"
        />
      </template>
    </ActionBar>
    <div data-test="user-details-block" v-if="!loadingData">
      <div class="bg-white rounded-md p-4 text-white mt-2">
        <div class="flex p-4" data-test="users-view__lock-status">
          <div
            v-if="!isUserArchived"
            data-test="user-lock-status"
            class="font-exo text-lg"
            :class="[isUserLocked ? 'text-tmrw-error' : 'text-tmrw-success']"
          >
            <i :class="[isUserLocked ? 'fa-user-lock' : 'fa-user']" class="fas text-lg" />
            {{ isUserLocked ? 'Locked User' : 'Unlocked User' }}
          </div>
          <div v-else class="font-exo text-lg text-gray-500">
            <i class="fa-user fas text-lg" /> Archived User
          </div>
          <button-dynamic
            :btnText="archiveButtonLabel"
            btnType="button"
            btnStyle="secondary"
            showIcon
            :addClasses="['text-lg text-tmrw-blue-dark ml-auto']"
            :fontAwesomeIconClass="archiveActionIcon"
            @click="handleArchiveAccount"
          />
          <button-dynamic
            :isDisabled="isUserArchived"
            :btnText="lockedButtonLabel"
            btnType="button"
            btnStyle="secondary"
            showIcon
            :addClasses="['text-lg text-tmrw-blue-dark ml-2']"
            :fontAwesomeIconClass="lockActionIcon"
            @click="handleLockAccount"
          />
        </div>
        <div
          class="grid gap-4 pl-4 sm:grid-cols lg:grid-cols-4"
          data-test="users-view__fields-info"
        >
          <div :key="key" v-for="(field, key) in userFields">
            <div
              :data-test="`user-field-${setFieldDataTest(field.label)}-header`"
              class="text-base text-tmrw-blue"
            >
              {{ field.label }}
            </div>
            <div
              :data-test="`user-field-${setFieldDataTest(field.label)}-value`"
              class="font-inconsolata mb-3 text-xl font-medium text-tmrw-blue-dark border-b border-solid py-2 h-10"
            >
              {{ field.value }}
            </div>
          </div>
        </div>
        <div
          class="flex text-tmrw-blue-light my-6 ml-4"
          data-test="users-view__last-login-and-attempts"
        >
          <p class="mr-8">Last Signed In: {{ lastSignedIn }}</p>
          <p>Last login Attempts: {{ failedLoginAttempts }}</p>
        </div>
      </div>
      <div class="w-full flex flex-row mt-4" v-if="!loadingData && !isUserArchived">
        <div class="w-2/3 pr-4" data-test="automated-alerts-section">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Automated Alerts</dynamic-title>
          <div
            v-if="!loadingData"
            class="bg-white rounded-md p-4 grid grid-cols-2 grid-rows-3 mt-4"
          >
            <div :key="key" v-for="(field, key) in alerts">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @change="updateToggleValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
        <div class="w-1/3 pl-4" data-test="reporting-section">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Reporting</dynamic-title>
          <div v-if="!loadingData" class="bg-white rounded-md p-4 grid grid-cols-1 mt-4">
            <div :key="key" v-for="(field, key) in reports">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @changeValue="updateToggleValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      confirmLabel="Yes"
      cancelLabel="No"
      confirmIcon="check-circle-regular"
      :confirmLoading="confirmLoading"
      :message="confirmModalMessage"
      :showModal="showLockModal"
      :title="confirmModalTitle"
      @handleCloseModal="handleCloseLockModal"
      @handleConfirmModal="handleToggleLockAccount"
    />
    <ConfirmModal
      confirmLabel="Yes"
      cancelLabel="No"
      confirmIcon="check-circle-regular"
      :confirmLoading="confirmLoading"
      :message="confirmArchiveModalMessage"
      :showModal="showArchiveModal"
      :title="confirmArchiveModalTitle"
      @handleCloseModal="handleCloseArchiveModal"
      @handleConfirmModal="handleToggleArchiveAccount"
    />
    <ConfirmModal
      :confirmLoading="confirmLoading"
      :confirmFeedback="true"
      :icon="renderFeedbackIcon"
      :message="feedbackMessage"
      :showModal="showFeedbackModal"
      @handleCloseModal="handleCloseFeedbackModal"
      @handleConfirmModal="handleCloseFeedbackModal"
    />
    <ConfirmModal
      confirmLabel="Reset"
      :confirmLoading="confirmLoading"
      :message="passwordResetConfirmationMessage"
      :showModal="showResetModal"
      @handleCloseModal="handleCloseResetModal"
      @handleConfirmModal="handleResetPasswordConfirm"
    />
    <AlertModal
      :message="alertModalResetErrorMessage"
      :showModal="showAlertModalResetError"
      :confirmLabel="'Close'"
      :title="'Reset Password'"
      :showIcon="true"
      :fontAwesomeIconClass="'times-circle'"
      @handleCloseModal="handleCloseAlertModalResetError"
    />
  </MainContainer>
</template>

<script setup lang="ts">
import dateAndTime from '@/helpers/dateAndTime'
import { API_LOADING } from '@/constants'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import AlertModal from '@/components/AlertModal/AlertModal.vue'
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import OnOffToggle from '@/components/OnOffToggle/OnOffToggle.vue'
import { useAlertsComputed } from '../alerts-computed'

import * as ss from '@/config/session-storage-help'
import { computed, onMounted, ref } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { UsersModule } from '@/store/modules/users'
import { Router, useRouter } from 'vue-router'

export interface IUserDetails {
  loadingData: boolean;
  renderFeedbackIcon: string;
  passwordReset: boolean;
  showLockModal: boolean;
  showArchiveModal: boolean;
  confirmLoading: boolean;
  showResetModal: boolean;
  closeUser: () => void;
  updateUser: () => void;
  handleLockAccount: () => void;
  handleArchiveAccount: () => void;
  handleResetPassword: () => void;
  handleResetPasswordConfirm: () => void;
  handleCloseResetModal: () => void;
  handleToggleLockAccount: () => void;
  router: Router;
}

const router = useRouter()

const confirmLoading = ref(false)
const loadingData = ref(true)
const alertModalResetErrorMessage = ref('')
const showAlertModalResetError = ref(false)
const showLockModal = ref(false)
const showArchiveModal = ref(false)
const showResetModal = ref(false)
const showFeedbackModal = ref(false)
const passwordReset = ref(false)
const selectedUser = ref<number | null>(null)
const previousUserState = ref('')

const { reports, alerts, userEmailAlertsFields } = useAlertsComputed()

const { user, userModuleError, resetPasswordStatus } =
  useGetters<UsersModule['getters']>('usersModule')

const pageTitle = computed(() => {
  return user.value.firstName
    ? `You're viewing ${user.value.firstName} ${user.value.lastName}`
    : ''
})
const lastSignedIn = computed(() => {
  return user.value.lastSignedIn
    ? dateAndTime.toLocalTimeZone({ procedureTime: user.value.lastSignedIn })
    : 'N/A'
})
const failedLoginAttempts = computed(() => {
  return user.value.failedLoginAttempts
})
const userFields = computed(() => {
  const { loginName, firstName, lastName, emailAddress, userRoles, clinicName, primarySite } =
    user.value
  const userRolesValues = userRoles?.map((role) => role.name).join(', ')
  return [
    {
      label: 'Login Name',
      value: loginName
    },
    {
      label: 'First Name',
      value: firstName
    },
    {
      label: 'Last Name',
      value: lastName
    },
    {
      label: 'Email',
      value: emailAddress
    },
    {
      label: 'Role',
      value: userRolesValues
    },
    {
      label: 'Clinic',
      value: clinicName
    },
    {
      label: 'Site',
      value: primarySite
    }
  ]
})
const isUserLocked = computed(() => {
  return user.value.activityStatus === 'Locked'
})
const loadingResetPasswordStatus = computed(() => {
  return resetPasswordStatus.value === API_LOADING
})
const lockedButtonLabel = computed(() => {
  return isUserLocked.value ? 'Unlock User' : 'Lock User'
})
const archiveButtonLabel = computed(() => {
  return isUserArchived.value ? 'Unarchive User' : 'Archive User'
})
const isUserArchived = computed(() => {
  return user.value.activityStatus === 'Archived'
})
const lockActionIcon = computed(() => {
  return !isUserLocked.value ? 'user-lock' : 'user-regular'
})
const archiveActionIcon = computed(() => {
  return !isUserArchived.value ? 'fas fa-archive' : 'fas fa-file-archive'
})

const confirmModalMessage = computed(() => {
  return user.value.activityStatus === 'Locked'
    ? 'Are you sure you want to unlock this User?'
    : 'Are you sure you want to lock this User?'
})
const confirmArchiveModalMessage = computed(() => {
  return user.value.activityStatus === 'Active' || user.value.activityStatus === 'Locked'
    ? 'Are you sure you want to archive this User?'
    : 'Are you sure you want to unarchive this User?'
})
const confirmModalTitle = computed(() => {
  return user.value.activityStatus === 'Locked' ? 'Unlock User' : 'Lock User'
})
const confirmArchiveModalTitle = computed(() => {
  return user.value.activityStatus === 'Archived' ? 'Unarchive User' : 'Archive User'
})
const renderFeedbackIcon = computed(() => {
  if (passwordReset.value) {
    return 'envelope'
  }
  if (user.value.activityStatus === 'Locked') {
    return 'user-lock'
  }
  return 'user'
})

const feedbackMessage = computed(() => {
  if (passwordReset.value) {
    return "You've been sent an Email to Reset your Password."
  }
  if (user.value.activityStatus === 'Locked') {
    return 'The User has been locked'
  }
  if (user.value.activityStatus === 'Archived') {
    return 'The User has been archived'
  }
  if (user.value.activityStatus === 'Active' && previousUserState.value === 'Archived') {
    return 'The User has been unarchived'
  }
  if (user.value.activityStatus === 'Active' && previousUserState.value === 'Locked') {
    return 'The User has been unlocked'
  }
  return ''
})
const passwordResetConfirmationMessage = computed(() => {
  return 'Are you sure you want to reset this user password?'
})

const {
  fetchUser,
  resetPassword,
  fetchUserRoles,
  fetchUserEmailAlerts,
  editUser,
  editUserEmailAlerts
} = useActions<UsersModule['actions']>('usersModule')

const updateToggleValue = (value) => {
  userEmailAlertsFields.value = userEmailAlertsFields.value.map((el) => ({
    ...el,
    enabled: el.id === value ? !el.enabled : el.enabled
  }))
  const filteredActiveEmailAlerts = userEmailAlertsFields.value.filter((ue) => ue.isActive)
  if (selectedUser.value) {
    editUserEmailAlerts([selectedUser.value, filteredActiveEmailAlerts])
  }
}

const setAutomatedAlertsValues = (alerts) => {
  userEmailAlertsFields.value = userEmailAlertsFields.value.map((alert) => ({
    ...alert,
    enabled: alerts
      .filter((currentAlert) => currentAlert.id === alert.id)
      .map((value) => value.enabled)[0]
  }))
}

const closeUser = () => {
  router.push('/users-list')
}

const updateUser = () => {
  router.push(`/user/update/${user.value.id}`)
}

const handleLockAccount = () => {
  showLockModal.value = true
}

const handleArchiveAccount = () => {
  showArchiveModal.value = true
}

const handleCloseLockModal = () => {
  showLockModal.value = false
}

const handleCloseArchiveModal = () => {
  showArchiveModal.value = false
}

const handleCloseResetModal = () => {
  showResetModal.value = false
}

const handleCloseAlertModalResetError = () => {
  showAlertModalResetError.value = false
  alertModalResetErrorMessage.value = ''
}

const handleCloseFeedbackModal = () => {
  showFeedbackModal.value = false
  passwordReset.value = false
}

const handleToggleLockAccount = async () => {
  confirmLoading.value = true
  const data = {
    ...user.value,
    activityStatus: user.value.activityStatus === 'Active' ? 'LOCKED' : 'ACTIVE'
  }
  previousUserState.value = user.value.activityStatus === 'Active' ? 'Active' : 'Locked'
  await editUser(data)
  showFeedbackModal.value = true
  showLockModal.value = false
  confirmLoading.value = false
  if (selectedUser.value) {
    await fetchUser(selectedUser.value)
  }
}

const handleToggleArchiveAccount = async () => {
  confirmLoading.value = true
  const data = {
    ...user.value,
    activityStatus: user.value.activityStatus === 'Archived' ? 'ACTIVE' : 'ARCHIVED'
  }
  previousUserState.value = user.value.activityStatus === 'Active' ? 'Active' : 'Archived'
  await editUser(data)
  showFeedbackModal.value = true
  showArchiveModal.value = false
  confirmLoading.value = false
  if (selectedUser.value) {
    await fetchUser(selectedUser.value)
  }
}

const handleResetPassword = () => {
  showResetModal.value = true
}

const handleResetPasswordConfirm = async () => {
  confirmLoading.value = true
  try {
    await resetPassword(user.value)
    confirmLoading.value = false
    showResetModal.value = false
    passwordReset.value = true
    showFeedbackModal.value = true
  } catch (error) {
    confirmLoading.value = false
    showResetModal.value = false
    alertModalResetErrorMessage.value = userModuleError.value
    showAlertModalResetError.value = true
  }
}

const setFieldDataTest = (label) => {
  return label.toLowerCase().replace(/\s/g, '-')
}

onMounted(async () => {
  try {
    await fetchUserRoles()
    selectedUser.value = ss.getFieldSessionStorage('selectedUser')
    if (selectedUser.value) {
      const userAutomatedAlerts = await fetchUserEmailAlerts(selectedUser.value)
      setAutomatedAlertsValues(userAutomatedAlerts)
      await fetchUser(selectedUser.value)
    }
    loadingData.value = false
  } catch (err) {
    loadingData.value = false
  }
})
</script>
