<template>
  <div class="error-box w-full rounded-lg" :class="[...addClasses, ...setBtnLook]">
    <div class="flex my-2 py-1">
      <div class="w-12/12 text-tmrw-warning text-center">
        <i
          v-if="showIcon"
          :class="showFontAwesomeIcon"
          class="text-xs"
        /> {{errorMessage}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-box',
  props: {
    // Error Message.
    errorMessage: {
      type: String,
      required: true
    },
    // Message specific style for look and feel (Different than AddClass, which adds custom classes).
    errorBoxStyleClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Add custom classes to the Error Message.
    addClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Show Icon. Any class linked to an Icon library (Currently Font Awesome).
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    // Name of the class that will show the Icon from selected library (Currently Font Awesome).
    fontAwesomeIconClass: {
      type: String,
      default: 'print',
      required: false
    }
  },
  computed: {
    // Add Icon name class to Button to show the proper Icon (Currently Font Awesome).
    // This should be refactored if any additional library is added
    showFontAwesomeIcon() {
      return `fa fa-${this.fontAwesomeIconClass}`
    },
    setBtnLook() {
      const setErrorBoxStyleClasses = []
      for (let i = 0, classesCount = this.errorBoxStyleClasses.length; i < classesCount; i++) {
        setErrorBoxStyleClasses.push(`text-tmrw-${this.errorBoxStyleClasses[i]}`)
      }
      return setErrorBoxStyleClasses
    }
  }
}
</script>
