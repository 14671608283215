<template>
  <a
    :id="linkId"
    :class="[
      'focus:outline-none px-4 py-2 text-lg flex justify-center items-center',
      isDisabled ? 'opacity-75 cursor-not-allowed' : 'cursor-pointer',
      {
        'bg-tmrw-green hover:bg-tmrw-green-light': linkStyle === 'primary',
        'bg-white text-tmrw-blue hover:text-black': linkStyle === 'secondary',
        'font-bold': isFontBold,
        'ml-4': addMarginLeft,
        'mr-4': addMarginRight,
        'mt-4': addMarginTop,
        'mb-4': addMarginBottom,
        'rounded-lg': hasRoundBorders,
      },
      ...addClasses,
    ]"
    data-test="link-dynamic"
    :style="linkStyles"
    :type="linkType"
    :disabled="isDisabled"
  >
    <div v-if="isLoading" class="w-16 h-6 flex items-center justify-center">
      <div class="dot-flashing" />
    </div>
    <i
      v-if="showIcon && !isLoading && iconPosition === 'left'"
      :class="[
        'is-icon-left',
        linkStyle === 'hamburguer' ? '' : linkStyle === 'closeMenu' ? 'text-2xl' : 'mr-3',
        showFontAwesomeIcon,
        isDisabled ? 'opacity-50' : '',
        ...addIconClasses,
      ]"
    />
    <span
      :class="[
        'h-6 flex items-center justify-center font-exo',
        isDisabled ? 'opacity-50' : '',
        isTextUnderlined ? 'underline' : '',
        linkSize === 'large' ? 'text-4xl' : 'font-semibold',
      ]"
      v-if="!isLoading && linkText">
      {{linkText}}
      <i
        v-if="showIcon && !isLoading && iconPosition === 'right'"
        :class="[
          'is-icon-right',
          linkStyle === 'hamburguer' ? '' : 'ml-3',
          showFontAwesomeIcon,
          isDisabled ? 'opacity-50' : '',
          ...addIconClasses,
        ]"
      />
    </span>
  </a>
</template>

<script>
import generateElementId from '@/helpers/elementIdGenerator'

export default {
  name: 'link-dynamic',
  props: {
    overrideDefaultClasses: {
      type: Boolean,
      default: false,
      required: false
    },
    // link Primary Text.
    linkText: {
      type: String,
      required: false
    },
    // link Type. Same as HTML element (button, reset, submit).
    linkType: {
      type: String,
      required: true
    },
    // Set Button Color.
    linkStyle: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'secondary',
        'transparent',
        'transparent-white',
        'transparent-blue',
        'closeMenu',
        'hamburguer'
      ].includes(value),
      required: false
    },
    // Define Button size
    linkSize: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'large'].includes(value),
      required: false
    },
    isFontBold: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add rounded border to button. True by default.
    hasRoundBorders: {
      type: Boolean,
      default: true,
      required: false
    },
    // Add underline to button text.
    isTextUnderlined: {
      type: Boolean,
      default: false,
      required: false
    },
    // link specific style for look and feel (Different than AddClass, which adds custom classes).
    linkStyleClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Display link loader is boolean is passed on this prop.
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add custom classes to the link.
    addClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Add custom classes to the icon.
    addIconClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Loading Spinner moving element color.
    spinnerHexForeColor: {
      type: String,
      default: '#3a8dde',
      required: false
    },
    // Loading Spinner Background color. Has to be RGBA with opacity.
    spinnerRgbaBgColor: {
      type: String,
      default: '90, 90, 90, .2',
      required: false
    },
    // link disabled when this prop is true.
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // Float button to left or right. Default is "right"
    floatTo: {
      type: String,
      default: 'right',
      required: false
    },
    // Show Icon. Any class linked to an Icon library (Currently Font Awesome).
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    // Name of the class that will show the Icon from selected library (Currently Font Awesome).
    fontAwesomeIconClass: {
      type: String,
      default: 'print',
      required: false
    },
    // Icon Position
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
      required: false
    },
    // Add custom styles to button.
    customStyles: {
      type: String,
      default: '',
      required: false
    },
    // Add data test to button
    linkDataTest: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    linkId() {
      const name = this.linkText && this.linkText.split(' ').join('-').toLowerCase()
      return generateElementId({
        route: this.$route,
        parent: this.$parent,
        type: 'link',
        name
      })
    },
    // Compose Spinner color and background-color from Props.
    spinnerStyle() {
      return `border-right-color: ${this.spinnerHexForeColor}; color: rgba(${this.spinnerRgbaBgColor});`
    },
    // Add Styles to float button. IMPORTANT: This should be refactored when using TailwindCSS.
    linkStyles() {
      return `float: ${this.floatTo}; ${this.customStyles}`
    },
    // Add Icon name class to Button to show the proper Icon (Currently Font Awesome).
    // This should be refactored if any additional library is added
    showFontAwesomeIcon() {
      return `fa fa-${this.fontAwesomeIconClass}`
    }
  }
}
</script>
