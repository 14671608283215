<template>
  <input
    ref="autocompleteRef"
    :id="id"
    type="text"
    :placeholder="placeholder"
    v-model="autocompleteText"
    :maxlength="maxlength"
    class="border-b border-dotted border-tmrw-blue bg-transparent leading-tight outline-none"
  />
</template>

<script setup lang="ts">
import { getGoogleInstance } from '@/utils/google-maps'
import { google } from 'google-maps'
import { onMounted, onUpdated, ref, watch } from 'vue'

const emit = defineEmits(['setClinicName', 'selectedPlace'])

type Props = {
  id: string
  placeholder: string
  placeType: string
  address?: {
    streetAddress: string
  }
  clinicName?: {
    name: string
  }
  maxlength?: number
}

const props = defineProps<Props>()

const autocompleteRef = ref<HTMLInputElement>()

const googleRef = ref<google>()
/**
 * The Autocomplete object.
 *
 * @type {Autocomplete}
 * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
 */
const autocomplete = ref<google.maps.places.Autocomplete>()

/**
 * Autocomplete input text
 * @type {String}
 */
const autocompleteText = ref<string>('')

/**
 * When searching for an establishment, trigger Google Autocomplete
 */
const onPlaceChanged = () => {
  const place = autocomplete.value?.getPlace()
  formatResult(place)
  // update autocompleteText then emit change event
  autocompleteText.value = props.placeType === 'address' ? place?.formatted_address! : place?.name!
}

/**
 * Format result from Geo google APIs
 * @param place
 * @returns {{formatted output}}
 */
const formatResult = (place) => {
  const formattedData = {}
  const ADDRESS_COMPONENTS = [
    'name',
    'street_number',
    'route',
    'locality',
    'sublocality',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'country',
    'postal_code',
    'postal_town'
  ]

  for (let i = 0; i < ADDRESS_COMPONENTS.length; i++) {
    for (let ii = 0; ii < place.address_components.length; ii++) {
      for (let iii = 0; iii < place.address_components[ii].types.length; iii++) {
        if (place.address_components[ii].types[iii] === ADDRESS_COMPONENTS[i]) {
          formattedData[ADDRESS_COMPONENTS[i]] = place.address_components[ii].long_name
        }
      }
    }
  }

  const establishmentData = {
    name: place.name,
    country: formattedData['country'],
    city: formattedData['locality'] || formattedData['sublocality'] || formattedData['postal_town'],
    streetAddress: formattedData['street_number']
      ? `${formattedData['street_number']} ${formattedData['route']}`
      : place.formatted_address,
    state: formattedData['administrative_area_level_1'],
    zip: formattedData['postal_code']
  }
  emit('selectedPlace', establishmentData)
  return establishmentData
}

onMounted(async () => {
  try {
    googleRef.value = await getGoogleInstance()
    autocomplete.value = new googleRef.value.maps.places.Autocomplete(autocompleteRef.value!, {
      types: [props.placeType]
    })
    autocomplete.value.addListener('place_changed', onPlaceChanged)
  } catch (e) {
    console.error(e)
  }
})

onUpdated(() => {
  if (props.placeType === 'address') {
    emit('setClinicName', autocompleteText.value)
  }
})

watch(
  () => props.address,
  (newAddress) => {
    if (newAddress) {
      autocompleteText.value = newAddress.streetAddress
    }
  }
)

watch(
  () => props.clinicName,
  (newClinicName) => {
    if (newClinicName) {
      autocompleteText.value = newClinicName.name
    }
  }
)
</script>
