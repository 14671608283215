<template>
  <span class="flex flex-row-reverse">
    <img :src="userRoleIcon" class="w-6 ml-2" :class="{ 'filter-white-2': item.selected }" />
  </span>
</template>

<script>
import { ARCHIVED_ROLE } from '@/constants/'
import UserLockedIcon from '@/assets/images/lock-light-blue.svg'

export default {
  name: 'ScrollTableUserRoleLocked',
  data() {
    return {
      UserLockedIcon
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    userRole() {
      if (this.isUserLocked()) {
        return ARCHIVED_ROLE.toUpperCase()
      }
      return ''
    },
    userRoleTextColor() {
      return this.item.selected ? 'text-tmrw-white' : 'text-tmrw-blue-light'
    },
    userRoleIcon() {
      if (this.isUserLocked()) {
        return UserLockedIcon
      }
      return ''
    }
  },
  methods: {
    isUserLocked() {
      return this.item.activityStatus === 'Locked'
    }
  }
}
</script>
<style></style>
