<template>
  <tr
    data-test="scroll-table-row"
    :data-testid="`scroll-table-row-${item[uniqueKey]}`"
    class="scroll-table-row sm:h-auto sm:py-2 sm:px-0 border-solid border-tmrw-gray border-t cursor-pointer"
    :class="{
      'bg-tmrw-blue': item.selected && !item.disabled,
      'text-tmrw-blue-dark': item.disabled,
      hidden: item.procedureName === TYPE_BATCH && !FF_BATCH
    }"
    @click="handleRowClick"
  >
    <td
      v-if="isSelectionModeCheckbox || isSelectionModeRadio || isCustomProcedureSelection"
      header-title="Selected"
      class="scroll-table-row__td text-center text-sm py-2 px-3 font-medium w-6 whitespace-nowrap text-tmrw-blue border-b border-solid border-tmrw-gray sm:block md:table-cell sm:w-full md:w-auto sm:max-w-full sm:text-left"
    >
      <template v-if="isCustomProcedureSelection">
        <template v-if="isCustomCryoShipper">
          <img
            :src="CryoshipperIcon"
            alt="Cryoshipper Icon"
            class="w-6 h-6"
            data-test="procedure-icon"
          />
        </template>
        <template v-else>
          <i :class="procedureIcon" class="text-xl" data-test="procedure-icon"></i>
        </template>
      </template>
      <template v-else>
        <Checkbox v-if="isSelectionModeCheckbox && !item.disabled" :modelValue="item.selected" />
        <RadioButton v-if="isSelectionModeRadio && !item.disabled" :modelValue="item.selected" />
      </template>
    </td>
    <td
      v-for="(column, key) in headersToDisplay"
      :key="key"
      :colspan="procedureColspan(column)"
      :data-test="displayDataTest(column)"
      :data-testid="displayDataTest(column)"
      :header-title="column.name"
      :class="[
        `td-${column.name.toLowerCase()}`,
        column.cssClasses,
        {
          'w-6 whitespace-nowrap': column.fitContent,
          'text-tmrw-blue-dark opacity-25': item.disabled && !column.forceEnabled,
          'text-white font-bold': item.selected && !(item.disabled && !column.forceEnabled),
          'text-tmrw-blue-dark': !item.selected,
          [`text-${column.alignment || 'left'}`]: hasValidColumnAlignment(column),
          'max-w-initial truncate': shouldTruncateColumnText(column)
        }
      ]"
      class="font-inconsolata text-xl scroll-table-row__td py-2 font-medium border-b border-solid border-tmrw-gray sm:w-full md:w-auto sm:max-w-full sm:text-left px-3"
    >
      <component
        v-if="column.component"
        :is="getComponentName(column)"
        :options="getComponentOptions(column, {})"
        :columnOptions="column || {}"
        :value="displayFieldContent(column)"
        :label="getColumnLabel(column)"
        :item="item"
        :hasNoXPadding="shouldHaveNoXPadding(column)"
        :maxlength="column.maxlength"
        :isRelocationView="isRelocationView"
        @valueFromCustomFieldChanged="onToggleFieldChanged"
        @onButtonClicked="onButtonClicked"
        @changeValue="(value) => onToggleFieldChanged(value, column, {}, undefined)"
      />
      <template v-else>
        <i
          v-if="displayIcon(column)"
          class="fas mr-1 w-5 h-5"
          :class="`fa-${displayIcon(column)}`"
        />
        <span
          data-test="scroll-table-row-value"
          :class="batchStyles(column)"
          v-html="
            column.name === 'batch-ticket-count'
              ? displayFieldContent(column)
              : $sanitize(displayFieldContent(column))
          "
        />
      </template>
    </td>
  </tr>
</template>

<script>
import CryoshipperIcon from '@/assets/database.svg'
import { mapGetters, mapActions } from 'vuex'
import { FeatureFlag } from '@/factories/index'
import Checkbox from '@/components/Checkbox/Checkbox.vue'
import RadioButton from '@/components/RadioButton/RadioButton.vue'
import {
  CUSTOM_SELECTION_PROCEDURES,
  CUSTOM_SELECTION_PROCEDURES_OPTIONS,
  PROCEDURE_TYPE_BATCH,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_CRYOSHIPPER,
  PROCEDURE_TYPE_BATCH_LOWERCASE
} from '@/constants'

import { trimString } from '@/utils'

import { getFieldValue } from '@/helpers/scrollTable/scrollTableRow'
import reprintLabelHeader from '@/components/reprintLabelHeader/reprintLabelHeader.vue'
import DataFieldModal from '@/components/EditableFields/DataFieldModal.vue'
import selectionModes from './selectionModes'
import ScrollTableImageField from './fields/ScrollTableImageField/ScrollTableImageField.vue'
import ScrollTableScreeningStatusField from './fields/ScrollTableScreeningStatusField/ScrollTableScreeningStatusField.vue'
import ScrollTableTooltipField from './fields/ScrollTableTooltipField/ScrollTableTooltipField.vue'
import ScrollTableRadioButtonField from './fields/ScrollTableRadioButtonField/ScrollTableRadioButtonField.vue'
import ScrollTableButtonField from './fields/ScrollTableButtonField/ScrollTableButtonField.vue'
import ScrollTableCustomTooltipField from './fields/ScrollTableCustomTooltipField/ScrollTableCustomTooltipField.vue'
import ScrollTableLinkField from './fields/ScrollTableLinkField/ScrollTableLinkField.vue'
import ScrollTableIconField from './fields/ScrollTableIconField/ScrollTableIconField.vue'
import ScrollTableScreeningStatusSelect from './fields/ScrollTableScreeningStatusSelect/ScrollTableScreeningStatusSelect.vue'
import ScrollTableSelectField from './fields/ScrollTableSelectField/ScrollTableSelectField.vue'
import ScrollTableBiopsyInputField from './fields/ScrollTableBiopsyInputField/ScrollTableBiopsyInputField.vue'
import ScrollTableRobotLocationsField from './fields/ScrollTableRobotLocationsField/ScrollTableRobotLocationsField.vue'
import ScrollTableIconButtonField from './fields/ScrollTableIconButtonField/ScrollTableIconButtonField.vue'
import ScrollTableStatusField from './fields/ScrollTableStatusField/ScrollTableStatusField.vue'
import ScrollTableShippingSource from './fields/ScrollTableShippingSource/ScrollTableShippingSource.vue'
import AdditionalPatientsTooltip from './fields/AdditionalPatientsTooltip/AdditionalPatientsTooltip.vue'
import ScrollTableDestinationSiteField from './fields/ScrollTableDestinationSiteField/ScrollTableDestinationSiteField.vue'
import ScrollTableSourceSiteField from './fields/ScrollTableSourceSiteField/ScrollTableSourceSiteField.vue'
import ScrollTableUserRole from './fields/ScrollTableUserRole/ScrollTableUserRole.vue'
import ScrollTableRobotLocationByType from './fields/ScrollTableRobotLocationByType/ScrollTableRobotLocationByType.vue'
import ScrollTableCryobeaconId from './fields/ScrollTableCryobeaconId/ScrollTableCryobeaconId.vue'
import ScrollTableTicketNumberField from './fields/ScrollTableTicketNumberField/ScrollTableTicketNumberField.vue'
import ScrollTableRemoveButtonField from './fields/ScrollTableRemoveButtonField/ScrollTableRemoveButtonField.vue'
import ScrollTableRelocationStatusField from './fields/ScrollTableRelocationStatusField/ScrollTableRelocationStatusField.vue'
import ScrollTableUserRoleLocked from './fields/ScrollTableUserRoleLocked/ScrollTableUserRoleLocked.vue'

export default {
  components: {
    Checkbox,
    RadioButton,
    ScrollTableImageField,
    ScrollTableScreeningStatusField,
    ScrollTableBiopsyInputField,
    ScrollTableTooltipField,
    DataFieldModal,
    ScrollTableRadioButtonField,
    ScrollTableButtonField,
    ScrollTableCustomTooltipField,
    ScrollTableIconField,
    ScrollTableLinkField,
    ScrollTableIconButtonField,
    ScrollTableRobotLocationsField,
    ScrollTableScreeningStatusSelect,
    ScrollTableSelectField,
    ScrollTableStatusField,
    reprintLabelHeader,
    ScrollTableShippingSource,
    AdditionalPatientsTooltip,
    ScrollTableDestinationSiteField,
    ScrollTableSourceSiteField,
    ScrollTableUserRole,
    ScrollTableRobotLocationByType,
    ScrollTableTicketNumberField,
    ScrollTableCryobeaconId,
    ScrollTableRemoveButtonField,
    ScrollTableRelocationStatusField,
    ScrollTableUserRoleLocked
  },
  name: 'scroll-table-row',
  emits: ['onToggleSelectRow', 'onButtonClicked', 'onToggleFieldChanged'],
  props: {
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    uniqueKey: {
      type: String,
      default: '',
      required: true
    },
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    selectionMode: {
      type: String,
      default: selectionModes.CHECKBOX,
      required: true
    },
    tableStyle: {
      type: String,
      default: '',
      required: false
    },
    rowSelectedClasses: {
      type: String,
      default: '',
      required: false
    },
    isRelocationView: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      customSelectionOptions: { ...CUSTOM_SELECTION_PROCEDURES_OPTIONS },
      customSelections: [...CUSTOM_SELECTION_PROCEDURES],
      headersToDisplay: [],
      procedureName: '',
      FF_BATCH: undefined,
      TYPE_BATCH: PROCEDURE_TYPE_BATCH,
      CryoshipperIcon: CryoshipperIcon
    }
  },
  created() {
    this.isCustomRow()
    // Feature Flag
    const Flag = new FeatureFlag(import.meta.env.VITE_BATCH_FF)
    this.FF_BATCH = Flag.getValue()
  },
  methods: {
    ...mapActions('selectedTicketsModule', [
      'pushSelectedTickets',
      'pushViewTicket',
      'pushSelectedViewMode'
    ]),
    handleRowClick() {
      if (!this.item.disabled && !this.isSelectionModeNone) {
        this.onToggleSelectRow(this.item)
      }
    },
    /*
     * @onToggleSelectRow
     * In this event we send info
     * to the parent component
     * notify it when the user select
     * or unselect a row in the table
     * @return Void
     */
    onToggleSelectRow(item) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location

      // Mode View only applies for Cryoshipper child tickets, and should only trigger on BATCH tickets
      if (this.isSelectionModeView && item.procedure !== PROCEDURE_TYPE_BATCH_UPPERCASE) {
        return
      }
      this.$mixpanel.track('Click on Row ', {
        component: 'Scroll Table Row',
        'row-data': item,
        'current-route': currentRoute,
        'view-name': viewName,
        'redirected-from': redirectedFrom,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
      // For Cryoshipper or Batch tickets
      if (this.isCustomProcedureSelection || this.isSelectionModeView) {
        const path = this.isSelectionModeView ? '/stack-selected-tickets' : '/selected-tickets'
        const ticketData = {
          ticketType: this.isCustomProcedureSelection ? this.procedureName : 'general',
          ticketId: this.item.ticketId
        }
        this.pushViewTicket(ticketData)
        this.pushSelectedViewMode('normal')
        switch (this.procedureName) {
          case PROCEDURE_TYPE_BATCH_LOWERCASE:
            this.pushSelectedTickets(this.item.children)
            break
          default:
            this.pushSelectedTickets([this.item.ticketId])
            break
        }
        this.$router.push({ path })
      } else {
        this.$emit('onToggleSelectRow', item, !item.selected, this.isSelectionModeRadio)
      }
    },
    onButtonClicked(value, column) {
      this.$emit('onButtonClicked', value, column)
    },
    onToggleFieldChanged(value, column, getComponentOptions, fieldValue) {
      if (this.item.disabled) {
        return
      }
      this.$emit(
        'onToggleFieldChanged',
        this.item,
        value,
        column,
        this.getComponentOptions(column) || getComponentOptions,
        fieldValue
      )
    },
    displayFieldContent(column) {
      const content = getFieldValue(column, this.item)
      const componentOptions = this.getComponentOptions(column)
      if (componentOptions?.maxLength) {
        return trimString(content, componentOptions.maxLength, componentOptions.ellipsis)
      }
      return content
    },
    getComponentOptions(column, defaultOptions) {
      if (column?.componentOptions instanceof Function) {
        return column.componentOptions(this.item)
      }
      return column?.componentOptions || defaultOptions
    },
    getColumnLabel(column) {
      const fieldValue = this.item[column.key] ?? ''
      return fieldValue?.label ?? ''
    },
    displayDataTest(column) {
      let fieldName = column.name.toLowerCase().replace(/\s/g, '-')
      if (fieldName === 'ticket-#') {
        fieldName = fieldName.replace('#', 'number')
      }
      return `scroll-table-row-${fieldName}`
    },
    displayIcon(column) {
      let iconValue = this.item[column.key] || ''
      if (column.key === 'isImport') {
        return 'file-medical'
      }
      if (typeof iconValue === 'object' && Object.keys(iconValue).length) {
        iconValue = iconValue.icon || null
      }
      return null
    },
    getComponentName(column) {
      const components = {
        input: 'ScrollTableBiopsyInputField',
        select: 'ScrollTableSelectField',
        image: 'ScrollTableImageField',
        screeningStatus: 'ScrollTableScreeningStatusField',
        robotLocations: 'ScrollTableRobotLocationsField',
        destinationSite: 'ScrollTableDestinationSiteField',
        sourceSite: 'ScrollTableSourceSiteField',
        tooltip: 'ScrollTableTooltipField',
        radioButton: 'ScrollTableRadioButtonField',
        button: 'ScrollTableButtonField',
        customTooltip: 'ScrollTableCustomTooltipField',
        link: 'ScrollTableLinkField',
        icon: 'ScrollTableIconField',
        iconButton: 'ScrollTableIconButtonField',
        screeningStatusSelect: 'ScrollTableScreeningStatusSelect',
        ticketStatus: 'ScrollTableStatusField',
        shippingSource: 'ScrollTableShippingSource',
        dataFieldModal: 'DataFieldModal',
        robotLocationByType: 'ScrollTableRobotLocationByType',
        cryobeaconId: 'ScrollTableCryobeaconId',
        ticketNumber: 'ScrollTableTicketNumberField',
        removeButton: 'ScrollTableRemoveButtonField',
        relocationTicketStatus: 'ScrollTableRelocationStatusField',
        userRoleIcon: 'ScrollTableUserRole',
        userRoleLocked: 'ScrollTableUserRoleLocked'
      }
      return components[column.component] || column.component
    },
    shouldHaveNoXPadding(column) {
      return this.getComponentName(column) === 'ScrollTableIconButtonField'
    },
    shouldTruncateColumnText(column) {
      const hasEllipsis = column.ellipsis === undefined || column.ellipsis
      const hasClasses = column.cssClasses
      return hasEllipsis && !hasClasses
    },
    hasValidColumnAlignment(column) {
      return ['center', 'left', 'right'].includes(column.alignment || 'left')
    },
    procedureColspan(column) {
      return column.colspan || 0
    },
    isCustomRow() {
      this.procedureName =
        this.item && this.item.procedureName && this.item.procedureName.toLowerCase()
      this.headersToDisplay = [...this.headers]
      if (this.isCustomProcedureSelection) {
        const headersKeyIndex = this.headersToDisplay
          .map((header) => header.key)
          .indexOf(this.procedureStartsOnKey)
        this.headersToDisplay = [
          ...this.getCustomProcedureOptions.headers,
          ...this.headersToDisplay.slice(headersKeyIndex)
        ]
      }
    },
    batchStyles(column) {
      if (
        (!column.noBatchStyles && this.item.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) ||
        this.item.procedureType == PROCEDURE_TYPE_CRYOSHIPPER ||
        this.item.procedureType == PROCEDURE_TYPE_BATCH
      ) {
        if (
          column.name !== 'batch-ticket-count' &&
          column.name !== 'cryoshipper-ticket-count' &&
          column.name !== 'Procedure' &&
          column.name !== 'Non-TMRW Location Notes'
        ) {
          return ['font-bold', 'underline', 'text-tmrw-blue']
        }
        return ['font-bold', 'text-tmrw-blue']
      } else {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    ...mapGetters('newTicketModule', ['selectedMethod']),
    ...mapGetters('siteModule', ['allSites']),
    /**
     * @isSelectionModeNone
     * @isSelectionModeRadio
     * @isSelectionModeCheckbox
     * Convenient computed
     * to access in a semantic
     * and significant way to
     * the selectionMode prop
     * @return Boolean
     */
    sanitizedContent() {
      return (column) => {
        return column.name === 'batch-ticket-count'
          ? this.displayFieldContent(column)
          : this.$sanitize(this.displayFieldContent(column))
      }
    },
    isSelectionModeNone() {
      return selectionModes.NONE === this.selectionMode
    },
    isCustomCryoShipper() {
      return this.procedureIcon === 'custom-cryoshipper'
    },
    isSelectionModeRadio() {
      return selectionModes.RADIO === this.selectionMode
    },
    isSelectionModeCheckbox() {
      return selectionModes.CHECKBOX === this.selectionMode
    },
    isSelectionModeView() {
      return selectionModes.VIEW === this.selectionMode
    },
    isCustomProcedureSelection() {
      return this.customSelections.filter(
        () => typeof this.customSelectionOptions[this.procedureName] !== 'undefined'
      ).length
    },
    getCustomProcedureOptions() {
      return this.customSelectionOptions[this.procedureName]
    },

    procedureIcon() {
      return (
        this.customSelectionOptions[this.procedureName] &&
        this.customSelectionOptions[this.procedureName].selectionIcon
      )
    },
    procedureStartsOnKey() {
      return (
        this.customSelectionOptions[this.procedureName] &&
        this.customSelectionOptions[this.procedureName].startsOnKey
      )
    },
    iconContent() {
      if (this.procedureIcon === 'custom-cryoshipper') {
        return CryoshipperIcon
      }
      return this.procedureIcon
    }
  },
  watch: {
    headers(newHeaders) {
      this.headersToDisplay = [...newHeaders]
    },
    // on data sorting the item should check if there is a batch ticket if so should render the proper row styling
    item() {
      this.isCustomRow()
    }
  }
}
</script>
