<template>
  <MainContainer data-test="patient-list" gridSlots="patients">
    <top-header>Ticket #{{ ticketId }}</top-header>

    <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          {{ BIOREPOSITORY }} Send Ticket
        </dynamic-title>
      </template>

      <template v-slot:right-side>
        <span class="text-white"> {{ cryobeaconsAmount }} {{ CRYOBEACONS }} </span>

        <button-dynamic
          addMarginLeft
          btnStyle="primary"
          btnText="Add CryoBeacons"
          btnType="button"
          fontAwesomeIconClass="edit"
          showIcon
        />

        <button-dynamic
          :isDisabled="!canRequestShipper"
          addMarginLeft
          btnStyle="primary"
          btnText="Request Shipper"
          btnType="button"
        />
      </template>
    </ActionBar>

    <div v-if="hasSpeciments">
      <div
        class="cryobeacon-detail__wrapper bg-white rounded-lg p-6 mb-5"
        v-for="beacon in composeBeacons"
        :key="beacon.beaconId"
      >
        <CryoBeaconDetail
          :beaconId="beacon.beaconId"
          :infectiousScreeningStatus="beacon.infectiousScreeningStatus"
          isRemovable
          :specimens="beacon.specimens"
        />
      </div>
    </div>

    <TicketEmptyState v-else />
  </MainContainer>
</template>

<script lang="ts">
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CryoBeaconDetail from '@/components/Biorepository/CryoBeaconDetail/CryoBeaconDetail.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TicketEmptyState from '@/components/Biorepository/TicketEmptyState/TicketEmptyState.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { BIOREPOSITORY, CRYOBEACONS } from '@/constants'

export default {
  name: 'biorepository-send-ticket',
  data() {
    return {
      BIOREPOSITORY,
      CRYOBEACONS,
      ticketId: 0
    }
  },
  methods: {},
  computed: {
    cryobeaconsAmount() {
      return (this as any).composeBeacons.length
    },
    hasSpeciments() {
      return !!(this as any).cryobeaconsAmount
    },
    canRequestShipper() {
      return false
    },
    composeBeacons() {
      return [] as any
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    CryoBeaconDetail,
    DynamicTitle,
    MainContainer,
    TicketEmptyState,
    TopHeader
  }
}
</script>

<style lang="scss" scoped>
.cryobeacon-detail__wrapper {
  height: fit-content;
}
</style>
