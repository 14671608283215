/* eslint-disable */
import axios from 'axios'
import packageJson from '../../package.json'
import * as ss from '@/config/session-storage-help'

const semverGreaterThan = ({ latestVersion, currentVersion }) => {
  const a = latestVersion.split(/\./g)
  const b = currentVersion.split(/\./g)
  for (let i = 0; i <= a.length; i += 1) {
    if (a[i] !== b[i]) return a > b
  }
  return false
}

const refreshCacheAndReload = () => {
  if (caches) {
    caches.keys()
      .then((names) => {
        names.map(name => caches.delete(name))
      })
  }
  return window.reload()
}

const checkVersion = async () => {
  try {
    const fetchMeta = await axios.get('/meta.json')
    const meta = fetchMeta.data
    const latestVersion = meta.version
    const currentVersion = packageJson.version
    const shouldForceRefresh = semverGreaterThan({ latestVersion, currentVersion })
    if (shouldForceRefresh) {
      return setTimeout(() => refreshCacheAndReload(), 1000)
    }
    ss.setFieldSessionStorage('currentAppVersion', currentVersion)
    return `You already have the latest version - ${latestVersion}. No cache refresh needed.`
  } catch (error) {
    return error
  }
}

export default checkVersion
