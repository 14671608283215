/**
 * New Inventory Actions (Present, Not Present and Discarder)
*/
export const inventoryActionHeaders = [
  {
    name: 'Present',
    key: 'inventoryAction',
    component: 'radioButton',
    cssClasses: ['w-28'],
    alignment: 'center',
    value: 'Present'
  },
  {
    name: 'Not Present',
    key: 'inventoryAction',
    component: 'radioButton',
    cssClasses: ['w-20'],
    alignment: 'center',
    value: 'Not Present'
  },
  {
    name: 'Discarded',
    key: 'inventoryAction',
    component: 'radioButton',
    cssClasses: ['w-20'],
    alignment: 'center',
    value: 'Discarded'
  }
]

export const donationToPatientInventoryActionHeaders = {
  firstTicketHeaders: {
    selectedSpecimens: inventoryActionHeaders.map((header) => {
      if (header.value !== 'Not Present') {
        return {
          ...header,
          disabled: true
        }
      }
      return header
    })
  },
  secondTicketHeaders: {
    selectedSpecimens: inventoryActionHeaders.map((header) => {
      if (header.value !== 'Present') {
        return {
          ...header,
          disabled: true
        }
      }
      return header
    })
  }
}
