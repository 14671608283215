<template>
  <section class="ticket-table">
    <!-- Header specimen list table -->
    <div
      class="ticket-table__header grid grid-cols-12 mt-4 py-5 text-tmrw-blue-dark border-solid border-t-2 border-tmrw-gray"
      v-if="isTicketDataVisible"
    >
      <div class="col-span-2">
        <CryoShipperInfectiousStatus
          v-if="isCryoShipperTicket"
          :shipment-details="shipmentDetails"
        />
        <div v-else class="flex flex-col border-solid border-r-2 border-tmrw-gray pr-20">
          <div
            class="mb-1 font-bold text-xs text-tmrw-blue uppercase"
            data-test="ticket-list-beaconID"
          >
            {{ CryoBeacon }} ID
          </div>
          <div>
            <div
              v-if="beaconId"
              data-test="cryobeacon-id"
              class="text-xl font-inconsolata leading-7 flex flex-row relative"
            >
              <p class="break-all">{{ showIfValidCryoBeaconId(beaconId) }}</p>
              <button-dynamic
                v-if="shouldAllowReplaceCryoBeaconButton()"
                btnDataTest="ticket-list-detail__replace-cryobeacon"
                :addClasses="['mt-1 !w-[30px] !h-[20px]']"
                btnStyle="icon-button-alt"
                btnText=""
                btnType="button"
                btnSize="xsmall"
                :hasRoundBorders="false"
                showIcon
                fontAwesomeIconClass="ellipsis-v"
                @click="onShowCryoBeaconReplaceClicked"
              />
              <button-dynamic
                v-if="shouldShowReplaceCryoBeaconButton"
                btnDataTest="ticket-list-detail__replace-cryobeacon"
                :btnText="`Replace ${CryoBeacon}`"
                btnType="button"
                btnStyle="secondary"
                showIcon
                fontAwesomeIconClass="sync-alt"
                :addClasses="[
                  'border-1 border-solid border-tmrw-blue-dark mt-1 absolute -right-60 -top-3 z-10'
                ]"
                @click="onReplaceCryoBeaconClicked"
              />
            </div>
            <div
              v-if="isDonateToPatient && newBeaconsArray"
              class="mt-2 font-bold text-xl font-inconsolata leading-7"
            >
              -> {{ newBeaconsArray[selectedTicketIndex] }}
            </div>
          </div>
          <div
            data-test="ticket-list-screening-status"
            class="mt-5 mb-1 font-bold text-xs text-tmrw-blue"
            v-if="screeningStatus"
          >
            {{ infectiousScreeningStatus.toUpperCase() }}
          </div>
          <div v-if="screeningStatus">
            <ScreeningStatusLabel
              class="scroll-table-screening-status text-xl"
              icon
              label
              textColor="tmrw-blue-dark"
              iconColor="tmrw-blue-dark"
              align="left"
              paddingSmall
              :id="Number(screeningStatus) || 0"
            />
          </div>
        </div>
      </div>
      <CryoBeaconStatus :ticket="getTicketData" :robotLocations="robotLocations" />
      <div class="flex justify-end items-start col-span-4">
        <TicketButtonsWrapper
          v-if="showTicketActionButtons"
          :editDate="editDate"
          :ticket="ticket"
          :isButtonDisabled="checkCancelDisabled"
          @complete-button-clicked="onCompleteButtonClicked"
          @add-details-button-clicked="onAddDetailsButtonClicked"
          @review-audit-button-clicked="onShouldReviewAuditButton"
          @edit-button-clicked="onEditButtonClicked"
          @remove-from-batch-button-clicked="onRemoveFromBatchButtonClicked"
          @cancel-button-clicked="onCancelTicket"
          @on-click-edit-date="onClickEditDate"
        />
      </div>
    </div>
    <!-- Specimens list table -->
    <div
      v-if="specimenData.length"
      data-test="ticket-list-specimens-list-table"
      id="ticket-list-specimens-list-table"
      class="flex flex-col relative mt-6"
    >
      <!-- content -->
      <span
        v-if="!specimenData.length"
        class="p-1"
        id="ticket-list-cryodevice-count"
        data-test="ticket-list-cryodevice-count"
        >{{ emptyCryodevices }}</span
      >
      <InventorySpecimenTables
        data-tests="ticket-table__editable__list"
        class="w-full"
        :ticketDetail="ticketDetailModel"
        :eggSpecimenHeaders="EGG_HEADERS"
        :eggSpecimens="eggSpecimens"
        :embryoSpecimenHeaders="EMBRYO_HEADERS"
        :embryoSpecimens="embryoSpecimens"
      />
    </div>
    <CryoShipperDetails v-else-if="isCryoShipperTicket" :shipment-details="shipmentDetails" />
  </section>
  <ModalGeneric
    v-if="isReplaceCryoBeaconModalOpen"
    data-test="ticket-details-cryobeacon-replace-modal"
    :title="`Replace ${CryoBeacon}`"
    :message="`Are you sure that you would like to replace the ${CryoBeacon}?`"
    confirm-label="Replace"
    modal-width="w-5/12"
    :title-classes="['text-2xl']"
    :message-classes="['text-xl pt-4']"
    @confirm="handleCryoBeaconReplace"
    @cancel="handleCryoBeaconReplaceCancel"
  />
</template>

<script>
import { mapActions } from 'vuex'
import inCryorobot from '@/assets/in-cryorobot.svg'
import outCryorobot from '@/assets/out-cryorobot.svg'
import {
  CRYOBEACON,
  EMPTY_CRYODEVICES,
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_BEACON_MOVE,
  ROBOT_TRANSFER_IN,
  ROBOT_TRANSFER_READY,
  TICKET_STATE_COMPLETE,
  UNASSIGNED,
  ROBOT_TRANSFER_OUT,
  VALIDATED_AT_SOURCE,
  VALIDATED_AT_DESTINATION
} from '@/constants'
import { SCAN_BEACON_ROUTE } from '@/constants/routes'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import ModalGeneric from '@/components/ModalGeneric/ModalGeneric.vue'
import TempCryoBeaconMixin from '@/mixins/TempCryoBeaconMixin'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { ApiCallsFactory } from '@/factories/index'
import * as ss from '@/config/session-storage-help'
import TicketButtonsWrapper from '@/components/TicketButtonsWrapper/TicketButtonsWrapper.vue'
import CryoBeaconStatus from '@/components/CryoBeaconStatus/CryoBeaconStatus.vue'
import { isTicketCancellable } from '@/helpers/ticketState'
import { TICKETS_API_ENDPOINT } from '@/constants/routes'
import { createDynamicHeadersFromSpecimens } from '@/helpers/dynamicTableHeaders'
import { STEP_INITIAL } from '@/constants/ticketSteps'
import toast from '@/config/toast'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CryoShipperInfectiousStatus from '@/components/Biorepository/CryoShipperInfectiousStatus/CryoShipperInfectiousStatus.vue'
import CryoShipperDetails from '@/components/Biorepository/CryoShipperDetails/CryoShipperDetails.vue'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation/index'

export default {
  name: 'ticket-list',
  data() {
    return {
      infectiousScreeningStatus: INFECTIOUS_SCREENING_STATUS,
      ticketDetailModel: {
        sort: {
          orderBy: 'cryodeviceBarcode',
          direction: 'asc'
        },
        options: []
      },
      CryoBeacon: CRYOBEACON,
      emptyCryodevices: EMPTY_CRYODEVICES,
      inCryorobot,
      outCryorobot,
      newBeaconsArray: null,
      appBaseConfig: {
        siteId: ss.getFieldSessionStorage('config', 'siteId') || null,
        clinicId: ss.getFieldSessionStorage('config', 'clinicId') || null,
        cpId: ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
        token: ss.getFieldSessionStorage('user', 'token') || null,
        userId: ss.getFieldSessionStorage('user', 'userId') || null
      },
      inCryorobotIcon: inCryorobot,
      outCryorobotIcon: outCryorobot,
      EGG_HEADERS: [],
      EMBRYO_HEADERS: [],
      batchData: [],
      firstTicketData: null,
      isReplaceCryoBeaconModalOpen: false,
      shouldShowReplaceCryoBeaconButton: false
    }
  },
  props: {
    beaconId: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    headers: {
      required: true,
      type: Array
    },
    specimenData: {
      required: true,
      type: Array
    },
    isEMRTicket: {
      required: false,
      type: Boolean
    },
    state: {
      required: false,
      type: String
    },
    ticketProcedureType: {
      required: false,
      type: String
    },
    robotLocations: {
      required: false,
      type: Array
    },
    robotTransfer: {
      required: false,
      type: String
    },
    screeningStatus: {
      type: [String, Number],
      required: false
    },
    isCancellable: {
      type: [Object, Boolean],
      default: () => ({ value: false })
    },
    ticketType: {
      type: String,
      required: false
    },
    isDonateToPatient: {
      type: Boolean,
      required: false
    },
    selectedTicketIndex: {
      type: Number,
      required: false
    },
    ticket: {
      type: Object,
      required: false
    },
    newTicketData: {
      type: Object,
      required: false
    },
    destinationLocation: {
      type: Object,
      required: false
    },
    sourceLocation: {
      type: Object,
      required: false
    },
    showTicketActionButtons: {
      type: Boolean,
      default: true
    },
    patientsTooltip: {
      type: Boolean,
      default: false
    },
    reprintDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    date: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    },
    editDate: {
      type: Boolean,
      required: false,
      default: false
    },
    ticketId: {
      type: [String, Number, Boolean],
      required: false
    },
    shipmentDetails: {
      type: Object,
      required: false,
      default: () => null
    },
    isCryoShipperTicket: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [TempCryoBeaconMixin],
  emits: [
    'onClickCancel',
    'add-details-button-clicked',
    'complete-button-clicked',
    'review-audit-button-clicked',
    'edit-button-clicked',
    'editDate'
  ],
  computed: {
    checkCancelDisabled() {
      if (!this.shipmentDetails) {
        return false
      }
      const siteId = this.appBaseConfig.siteId
      const destinationId = this.shipmentDetails.destinationSiteId
      const isSameSite = siteId === destinationId

      const isRobotTransferIn = this.robotTransfer === ROBOT_TRANSFER_IN
      const isRobotTransferOut = this.robotTransfer === ROBOT_TRANSFER_OUT

      const validationStatuses = [VALIDATED_AT_SOURCE, VALIDATED_AT_DESTINATION]
      const currentValidationStatus = this.shipmentDetails.cryoshipperGrids[0].validationStatus
      const isOutValidationStatus =
        isRobotTransferOut && validationStatuses.includes(currentValidationStatus)

      return isSameSite || isRobotTransferIn || isOutValidationStatus
    },
    isTicketDataVisible() {
      return this.robotLocations?.length || this.beaconId || this.screeningStatus
    },
    embryoSpecimens() {
      const embryosModel = {
        ...this.ticketDetailModel,
        options: this.specimenData.filter((item) => item.specimenType === SPECIMEN_TYPE_EMBRYO)
      }
      return embryosModel
    },
    eggSpecimens() {
      const eggsModel = {
        ...this.ticketDetailModel,
        options: this.specimenData.filter(
          (item) =>
            item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
        )
      }
      return eggsModel
    },
    getTicketData() {
      if (this.newTicketData && this.ticketId) {
        return this.newTicketData
      }
      return this.firstTicketData
    }
  },
  watch: {
    specimenData(newValue) {
      if (newValue) {
        this.ticketDetailModel = {
          ...this.ticketDetailModel,
          options: newValue
        }
        // Reprint Label --> need it on dynamic headers
        if (this.ticket.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) {
          const [ticketData] = this.batchData.filter(
            ({ specimens }) => specimens[0].cryodeviceBarcode === newValue[0].cryodeviceBarcode
          )
          const { embryoHeaders, oocyteHeaders } = createDynamicHeadersFromSpecimens({
            ticket: ticketData,
            patientsTooltip: this.patientsTooltip,
            reprintDisabled: this.reprintDisabled
          })

          this.EMBRYO_HEADERS = embryoHeaders
          this.EGG_HEADERS = oocyteHeaders
        }
      }
    }
  },
  mounted() {
    this.ticketDetailModel = {
      ...this.ticketDetailModel,
      options: this.specimenData
    }
    if (ss.getFieldSessionStorage('newBeaconsArray')) {
      this.newBeaconsArray = JSON.parse(ss.getFieldSessionStorage('newBeaconsArray'))
    }

    this.firstTicketData = this.ticket

    if (this.ticket?.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) {
      this.batchData = ss.getFieldSessionStorage('batch', 'cache')
      const [firstTicket] = this.batchData
      this.firstTicketData = {
        ...this.ticket,
        ...firstTicket
      }
    }

    const { embryoHeaders, oocyteHeaders } = createDynamicHeadersFromSpecimens({
      ticket: this.firstTicketData,
      patientsTooltip: this.patientsTooltip,
      reprintDisabled: this.reprintDisabled
    })

    this.EMBRYO_HEADERS = embryoHeaders
    this.EGG_HEADERS = oocyteHeaders
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['setSelectedTickets']),
    ...mapActions('beaconsModule', ['setBeaconBarcode']),
    getLastScannedBeacon() {
      return ss.getFieldSessionStorage('lastScannedBeacon')
    },
    onCancelTicket() {
      const isCancelable = isTicketCancellable(this.ticket)
      if (isCancelable) {
        const { ticketId } = this.ticket
        const action = this.isCryoShipperTicket ? 'cancel-modal' : 'reason-screen'
        this.$emit('onClickCancel', { action, ticketId })
      }
    },
    async onRemoveFromBatchButtonClicked() {
      try {
        const { ticketId, parentId, procedureType } = this.ticket
        const { token } = this.appBaseConfig
        const remove = new ApiCallsFactory(TICKETS_API_ENDPOINT(parentId, ticketId), token)
        await remove.deleteData()
        toast.success('Ticket successfully removed from batch!')
        return this.$router.push({
          name:
            procedureType === PROCEDURE_TYPE_BEACON_MOVE
              ? STEP_BIOREPOSITORY_FLIGHTBOARD
              : STEP_INITIAL
        })
      } catch (err) {
        toast.error({ title: err.message })
      }
    },
    onAddDetailsButtonClicked() {
      this.$emit('add-details-button-clicked')
    },
    onCompleteButtonClicked() {
      this.$emit('complete-button-clicked')
    },
    onShouldReviewAuditButton() {
      this.$emit('review-audit-button-clicked')
    },
    onEditButtonClicked() {
      this.$emit('edit-button-clicked')
    },
    onClickEditDate() {
      return this.editDate && this.$emit('editDate')
    },
    onReplaceCryoBeaconClicked() {
      if (this.$router.currentRoute.value.path.includes('biorepository')) {
        ss.setFieldSessionStorage('isRelocationTicket', 'true')
      }
      this.onShowCryoBeaconReplaceClicked()
      this.isReplaceCryoBeaconModalOpen = true
    },
    async handleCryoBeaconReplace() {
      await this.setBeaconBarcode(false)
      this.$router.push(`${SCAN_BEACON_ROUTE}?isReplaceCryoBeacon=true`)
    },
    handleCryoBeaconReplaceCancel() {
      this.isReplaceCryoBeaconModalOpen = false
    },
    onShowCryoBeaconReplaceClicked() {
      this.shouldShowReplaceCryoBeaconButton = !this.shouldShowReplaceCryoBeaconButton
    },
    shouldAllowReplaceCryoBeaconButton() {
      if (!this.robotLocations) {
        return false
      }
      const currentCryoBeaconLocation = getCurrentCryoBeaconLocation(this.robotLocations)
      if (this.ticket.state === TICKET_STATE_COMPLETE) return false
      if (this.ticket.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) return false
      if (this.ticket.parentId) return false
      if (this.showIfValidCryoBeaconId(this.beaconId) === UNASSIGNED) return false
      if (
        currentCryoBeaconLocation.transferType === ROBOT_TRANSFER_IN &&
        currentCryoBeaconLocation.transferStatus === ROBOT_TRANSFER_READY
      )
        return true
      return false
    }
  },
  components: {
    InventorySpecimenTables,
    ScreeningStatusLabel,
    TicketButtonsWrapper,
    CryoBeaconStatus,
    ModalGeneric,
    ButtonDynamic,
    // TODO: Support SMALL/MEDIUM Cryoshippers
    CryoShipperDetails,
    CryoShipperInfectiousStatus
  }
}
</script>
