<template>
  <div data-test="confirm-modal" class="alert-modal" v-if="showModal">
    <div class="bg-white rounded-md text-white p-8 min-w-1/4">
      <div class="relative">
        <div
          v-if="icon"
          class="flex justify-center m-auto border-solid border-4 border-white-600 bg-green-400 h-12 w-12 rounded-full"
        >
          <i :class="`fa fa-${this.icon} mt-3`" />
        </div>
        <div class="pr-6">
          <h3
            data-test="confirm-modal-title"
            v-if="title"
            class="text-tmrw-blue-dark text-3xl font-medium"
          >
            {{ title }}
          </h3>
          <p
            v-if="message"
            class="text-tmrw-blue pt-4"
            data-test="confirm-modal-message"
            v-html="message"
            :class="`${
              icon ? ' flex justify-center text-xl font-medium mt-2' : 'text-xl font-medium'
            }`"
          />
          <slot v-if="hasChild" />
        </div>
        <button class="alert-modal__close-icon" @click="handleCloseModal">
          <i class="far fa-times-circle" />
        </button>
      </div>
      <div v-if="confirmFeedback" class="flex justify-center m-5">
        <button-dynamic
          btnStyle="primary"
          btnText="Okay"
          btnType="button"
          :isLoading="confirmLoading"
          @click="handleConfirmModal"
        />
      </div>
      <div v-else class="flex justify-end mt-5">
        <button-dynamic
          addMarginRight
          btnStyle="secondary"
          btnType="button"
          :btnText="cancelLabel || 'Cancel'"
          :showIcon="!!cancelIcon"
          :fontAwesomeIconClass="cancelIcon"
          @click="handleCloseModal"
        />
        <button-dynamic
          btnType="button"
          btnStyle="primary"
          :btnText="confirmLabel || 'Confirm'"
          :showIcon="!!confirmIcon"
          :fontAwesomeIconClass="confirmIcon"
          :isLoading="confirmLoading"
          :isDisabled="disableConfirm"
          @click="handleConfirmModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  components: {
    ButtonDynamic
  },
  name: 'confirm-modal',
  emits: ['handleCloseModal', 'handleConfirmModal'],
  props: {
    message: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelLabel: {
      type: String,
      required: false
    },
    confirmLabel: {
      type: String,
      required: false
    },
    confirmLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmFeedback: {
      type: Boolean,
      required: false,
      default: false
    },
    disableConfirm: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmIcon: {
      type: String,
      required: false
    },
    cancelIcon: {
      type: String,
      required: false
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal')
    },
    handleConfirmModal() {
      this.$emit('handleConfirmModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-modal {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply w-full;
  @apply h-screen;
  @apply bg-black;
  @apply bg-opacity-50;
  @apply z-20;

  &__close-icon {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply text-lg;
    @apply text-tmrw-blue;
    @apply focus:outline-none;
    @apply cursor-pointer;
  }
}
</style>
