import state, { UsersState } from './state'
import getters, { UsersGetters } from './getters'
import mutations, { UsersMutations } from './mutations'
import actions, { UsersActions } from './actions'
import { StoreModule } from '@/store/store-types'

export type UsersModule = StoreModule<UsersState, UsersGetters, UsersMutations, UsersActions>


const usersModule: UsersModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default usersModule
