import {
  SCAN_MODE_SCAN,
  SCAN_MODE_SHOW
} from '@/constants'

export default {
  data() {
    return {
      isScanning: false,
      isProcessCompleted: false,
      isProcessingValidations: false,
      temporaryValue: null,
      scannedValue: ''
    }
  },
  emits: ['changeValue'],
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    indexKey: {
      type: Number,
      required: false
    },
    scanMode: {
      type: String,
      required: false,
      default: SCAN_MODE_SHOW,
      validator: (value) => [SCAN_MODE_SHOW, SCAN_MODE_SCAN].includes(value)
    }
  },
  created() {
    this.$_resetBeaconIdCheckMessage()
  },
  methods: {
    $_completeProcess() {
      const { temporaryValue, value } = this
      if (this.scanMode === SCAN_MODE_SCAN) {
        this.scannedValue = temporaryValue
        this.$emit('changeValue', value, temporaryValue === value)
      } else {
        this.$emit('changeValue', temporaryValue)
      }
      this.isScanning = false
      this.isProcessingValidations = false
      this.isProcessCompleted = true
    },
    $_handleCancel() {
      this.$_resetBeaconIdCheckMessage()
    },
    $_handleScan(scanResults) {
      const { temporaryValue } = scanResults

      this.isProcessCompleted = false
      this.isProcessingValidations = true
      this.temporaryValue = temporaryValue
      this.$_handleReadingValidation()
    }
  }
}
