import dayjs from 'dayjs'
import { MULTIPLE_PATIENTS_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'
import {
  DATE_OF_BIRTH,
  EMBRYO_COUNT,
  OOCYTE_COUNT,
  EMBRYO_TOTAL,
  PATIENT_ID,
  PATIENT_NAME,
  OOCYTE_TOTAL,
  TOTAL_SPECIMEN_COUNT
} from '@/constants'

const specimensCounter = [
  {
    name: EMBRYO_TOTAL,
    key: EMBRYO_COUNT,
    cssClasses: ['w-32']
  },
  {
    name: OOCYTE_TOTAL,
    key: OOCYTE_COUNT,
    cssClasses: ['w-32']
  }
]

const multiplePatientsAddButton = (selectedPatientIds: number[]) =>
  isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
    ? [
        {
          name: '',
          component: 'button',
          cssClasses: ['w-16', 'whitespace-nowrap'],
          // must return an object with disabled property
          content: (item: any) => ({
            id: item.globalPatientNumber,
            disabled: selectedPatientIds.includes(item.globalPatientNumber),
            external: item.external,
            externalInfo: item.external
              ? {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  identificationNumber: item.identificationNumber,
                  email: item.email,
                  phone: item.phone,
                  address: item.address,
                  birthDate: item.birthDate
                }
              : null
          }),
          key: 'globalPatientNumber',
          componentOptions: (item) => ({
            label: item.external ? 'Import & Add' : 'Add',
            dataTest: 'select-patient__add-button'
          })
        }
      ]
    : []

export const tableHeaderPatients = [
  {
    name: PATIENT_NAME,
    key: 'fullName',
    cssClasses: ['w-64'],
    sort: true
  },
  {
    name: PATIENT_ID,
    key: 'identificationNumber',
    cssClasses: ['w-64'],
    unique: true,
    sort: true
  },
  {
    name: DATE_OF_BIRTH,
    key: 'birthDate',
    cssClasses: ['w-96'],
    filters: [
      (value: string) => dayjs(value).format('DDMMMYYYY'),
      (value: string) => value.toUpperCase()
    ]
  },
  ...specimensCounter,
  {
    name: TOTAL_SPECIMEN_COUNT,
    key: 'specimenCount',
    cssClasses: ['w-32']
  }
]

export const tableHeaderPatientsWithAdd = (selectedPatientIds: number[]) => [
  ...tableHeaderPatients,
  ...multiplePatientsAddButton(selectedPatientIds)
]
