export default {
  saveState() {
    // const currentState = JSON.parse(window.sessionStorage.getItem('clinic'))
    // window.localStorage.setItem('sms_state', JSON.stringify(currentState))
  },
  resetState() {
    /*
    const savedState = JSON.parse(window.localStorage.getItem('sms_state'))
    if (savedState) {
      // eslint-disable-next-line
      state = savedState
      window.localStorage.removeItem('sms_state')
    }
    */
  }
}
