<template>
  <MainContainer data-test="flight-board" gridSlots="3">
    <top-header :back-btn="false">Welcome</top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="6/6" setLeftSideToBottom>
      <template v-slot:left-side>
        <Tabs :options="options" :active="filter" @changeValue="handleTabChange" />
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btn-data-test="print-board-button"
          btnType="button"
          btnText="Print View"
          btnStyle="transparent"
          isBgTransparent
          isTextUnderlined
          showIcon
          :addClasses="['print-tickets-btn']"
          fontAwesomeIconClass="print"
          @click="composeFlightBoardPDF"
        />
        <button-dynamic
          btn-data-test="new-batch-ticket-button"
          v-if="FF_BATCH"
          btnType="button"
          btnText="View Selected"
          btnStyle="secondary"
          addMarginLeft
          :isDisabled="!selectedTickets.length"
          @click="viewSelectedTickets"
        />
        <button-dynamic
          btn-data-test="new-batch-ticket-button"
          v-if="FF_BATCH"
          btnType="button"
          btnText="New Batch Ticket"
          btnStyle="secondary"
          addMarginLeft
          @click="onClickNewBatchTicket"
        />
        <button-dynamic
          btn-data-test="new-ticket-button"
          btnType="button"
          btnText="New Ticket"
          btnStyle="primary"
          addMarginLeft
          showIcon
          fontAwesomeIconClass="plus-circle"
          :isDisabled="!allowCreateTickets"
          @click="handleClickNewTicket"
        />
      </template>
    </ActionBar>
    <ScrollTableComponent
      :minHeight="flightBoardHasTickets ? 'h-full' : 'h-56'"
      v-model="flightBoardModel"
      :selectionMode="'checkbox'"
      :headers="headers"
      :headerInfo="headerInfo"
      :isRelocationView="false"
      tableWrapperClasses="overflow-hidden"
      :handle-sorting="false"
      :hasTabs="true"
    />
  </MainContainer>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { VFS } from '@/assets/pdf/vfs_fonts'
import {
  TICKET_OPEN,
  TICKET_COMPLETED,
  CRYOROBOT,
  SOURCE,
  DESTINATION,
  EXISTING_BEACON_MANUAL_SCAN,
  METHOD_NOT_SELECTED,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  TICKET_OPEN_CAPITALS,
  TICKET_COMPLETE_CAPITALS
} from '@/constants'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { getPrintersStatus } from '@/modules/printer'
import dateAndTime from '@/helpers/dateAndTime'
import { FligthBoardToPdf } from '@/helpers/printPDF/flightBoardToPdf'
import { FeatureFlag } from '@/factories/index'
import { STEP_INITIAL } from '@/constants/ticketSteps'
import { executeNextTicketStep } from '@/helpers/manageTicket'
import Tabs from '@/components/Tabs/Tabs.vue'
import {
  formingflightBoardModel,
  formingflightBoardHasTickets,
  checkSpecimenImportHelper,
  reversePatientName
} from '@/helpers/flightBoardHelpers'
import { sortOptions } from '@/utils'
import * as ss from '@/config/session-storage-help'
import {
  getTotalNumTickets,
  setProcedureValue,
  sortProcedure,
  sortByPatientName
} from '@/helpers/ticketState'

dayjs.extend(utc)
pdfMake.vfs = VFS

export default {
  name: 'flight-board',
  components: {
    ScrollTableComponent,
    TopHeader,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    Tabs
  },
  data() {
    return {
      flightBoardModel: {
        sort: {
          orderBy: 'procedureTime',
          direction: 'desc'
        },
        options: []
      },
      showAlertModal: true,
      cryoDevicesTypes: [],
      CryoRobot: CRYOROBOT,
      defaultSort: {
        direction: 'desc',
        key: 'procedureTime'
      },
      selectedTickets: [],
      FF_BATCH: undefined,
      userHasManualScanPermission: null,
      flightBoardHasTickets: false,
      allowCreateTickets: false,
      TICKET_OPEN_CAPITALS,
      TICKET_COMPLETE_CAPITALS,
      ticketState: TICKET_OPEN_CAPITALS,
      options: [
        {
          label: TICKET_OPEN,
          value: TICKET_OPEN_CAPITALS
        },
        {
          label: TICKET_COMPLETED,
          value: TICKET_COMPLETE_CAPITALS
        }
      ]
    }
  },
  computed: {
    ...mapGetters('modalModule', ['modalTitle', 'modalMessage']),
    ...mapGetters('specimensModule', ['embryoTypes']),
    ...mapGetters('ticketsModule', ['tickets', 'filter']),
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod']),
    ...mapGetters('patientsModule', ['externalClinics']),
    headers() {
      const headers = [
        {
          name: 'Id',
          key: 'ticketId',
          visible: false,
          unique: true
        },
        {
          name: 'Date and Time',
          key: 'procedureTime',
          cssClasses: ['w-64'],
          sort: true,
          filters: [(value) => dateAndTime.toLocalTimeZone({ procedureTime: value }).toUpperCase()]
        },
        {
          name: 'Patient Name',
          key: 'patient',
          cssClasses: ['w-40'],
          sort: true,
          sortFn: sortByPatientName(),
          filters: [(value) => reversePatientName(value)]
        },
        {
          name: 'Patient ID',
          key: 'identificationNumber',
          cssClasses: ['w-48'],
          sort: true
        },
        {
          name: 'Date of Birth',
          key: 'patientDob',
          cssClasses: ['w-40'],
          sort: true,
          filters: [
            (value) => dayjs(value).format('DDMMMYYYY'),
            (value) => (value ? value.toUpperCase() : '')
          ]
        },
        {
          name: 'Ticket #',
          key: 'ticketId',
          component: 'ticketNumber',
          cssClasses: ['w-32'],
          sort: true,
          componentOptions: {
            withScreeningStatusIcon: true
          }
        },
        {
          name: 'Origin',
          key: 'robotLocations',
          component: 'robotLocationByType',
          alignment: 'right',
          rightIcon: true,
          componentOptions: {
            locationType: SOURCE
          }
        },
        {
          name: 'Destination',
          key: 'robotLocations',
          component: 'robotLocationByType',
          leftIcon: true,
          componentOptions: {
            locationType: DESTINATION
          }
        },
        {
          name: 'Procedure',
          key: 'procedureName',
          cssClasses: ['w-64'],
          sort: true,
          sortFn: sortProcedure(),
          filters: [(value, item) => setProcedureValue(value, item)]
        },
        {
          name: 'Status',
          key: 'statusImage',
          component: 'ticketStatus',
          cssClasses: ['w-64'],
          componentOptions: {
            showLabel: false
          },
          sort: true
        }
      ]
      return headers
    },
    headerInfo() {
      const items = this.flightBoardModel.options.flat()
      const totalNumTickets = getTotalNumTickets(items)
      return `<span class="font-normal">Displaying </span>${totalNumTickets} Tickets`
    }
  },
  watch: {
    flightBoardModel(newValue, oldValue) {
      if (newValue.options !== oldValue.options) {
        const selectedTickets = this.flightBoardModel.options
          .flat()
          .filter((option) => option.selected)
        this.selectedTickets = selectedTickets || []
        this.setSelectedTickets(this.selectedTickets)
      }
      if (newValue.sort !== oldValue.sort) {
        const {
          options,
          sort: { direction, orderBy }
        } = newValue
        // This will trigger again this watch, but won't loop as it will not change the sort
        this.flightBoardModel = {
          ...newValue,
          options: sortOptions({ options, direction, orderBy, headers: this.headers })
        }
      }
    },
    async tickets(newValue) {
      const { sort } = this.flightBoardModel
      const value = await newValue
      const { filter, checkSpecimenImport } = this
      const newFlighBoardModel = formingflightBoardModel({
        filter,
        sort,
        value,
        checkSpecimenImport
      })
      const {
        options,
        sort: { direction, orderBy }
      } = newFlighBoardModel
      this.flightBoardModel = {
        ...newFlighBoardModel,
        options: sortOptions({ options, direction, orderBy, headers: this.headers })
      }

      this.flightBoardHasTickets = formingflightBoardHasTickets({
        filter,
        flightBoardModel: this.flightBoardModel
      })
    }
  },
  methods: {
    ...mapActions('modalModule', ['displayModal', 'hideModal']),
    ...mapActions('ticketsModule', ['fetchTickets', 'setFilter']),
    ...mapActions('newTicketModule', ['setRobotTransfer', 'setBeacons']),
    ...mapActions('selectedTicketsModule', [
      'setSelectedTickets',
      'setSelectedViewMode',
      'setViewTicket',
      'clearSelectedTickets'
    ]),
    ...mapActions('patientsModule', ['getExternalClinics']),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapActions('specimensModule', ['fetchEmbryoTypes', 'fetchCryoDevicesTypes']),
    ...mapActions('patientsModule', ['setDonatePatient', 'setPatientsEmpty']),
    ...mapActions('calendarModule', [
      'setCalendarMinDate',
      'setCalendarMaxDate',
      'setPerformedOnCalendarDate'
    ]),
    ...mapActions('beaconsModule', ['resetBeaconReaderReads']),
    viewSelectedTickets() {
      const ticketData = {
        ticketType: 'general',
        ticketId: ''
      }
      this.setViewTicket(ticketData)
      this.$router.push({ path: '/selected-tickets' })
    },
    checkSpecimenImport(ticket) {
      return checkSpecimenImportHelper({
        ticket,
        externalClinics: this.externalClinics
      })
    },
    async composeFlightBoardPDF() {
      const printingFlightBoardToPDF = await FligthBoardToPdf(
        this.flightBoardModel.options,
        this.ticketState
      )
      return printingFlightBoardToPDF
    },
    async handleTabChange(option) {
      this.ticketState = option.value
      this.setFilter(option.value)
      this.displaySpinner('Loading Tickets')
      await this.fetchTickets(option.value)
      this.hideSpinner()
    },
    async handleClickNewTicket() {
      // Clear Robot Transfer value when starting a new ticket.
      await this.setRobotTransfer(null)
      // Clear all beacons information
      await this.setBeacons([])

      // Execute initial step for ticket creation.
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: METHOD_NOT_SELECTED,
        currentTicketStep: this.currentTicketStep
      })
      this.clearSelectedTickets()

      this.$router.push({ name: nextStepPath })
    },
    async onClickNewBatchTicket() {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
        currentTicketStep: STEP_INITIAL
      })

      this.$router.push({ name: nextStepPath })
    },
    selectEmbryoTypeLabel(specimen) {
      if (this.embryoTypes && this.embryoTypes.length) {
        const [embryoType] = this.embryoTypes.filter(
          (embryo) =>
            specimen.embryo &&
            specimen.embryo.embryoType &&
            embryo.id === specimen.embryo.embryoType
        )
        return (embryoType && embryoType.name) || ''
      }
      return ''
    },
    setRobotLocationName(value) {
      let robotLocation = value
      const hasRobotLocations = robotLocation && robotLocation.length > 1
      if (hasRobotLocations) {
        robotLocation = value.filter((location) => location.type === SOURCE)
      }

      const robotLocationExists = Boolean(robotLocation && robotLocation[0])
      if (!robotLocationExists || !robotLocation[0].robotNumber) {
        return ''
      }

      return `${this.CryoRobot} ${robotLocation[0].robotNumber}`
    },
    resetViewTicketType() {
      const ticketData = {
        ticketType: 'general',
        ticketId: ''
      }
      this.setViewTicket(ticketData)
    }
  },
  async created() {
    try {
      // Display spinner
      this.displaySpinner('Loading Tickets')
      this.setSelectedTickets([])
      ss.removeFieldSessionStorage('process', 'activeSlideSelectedTickets')
      ss.removeFieldSessionStorage('process', 'isBiorepositoryRoute')
      this.setCalendarMinDate(null)
      this.setCalendarMaxDate(null)
      this.setPerformedOnCalendarDate(null)
      this.setFilter(TICKET_OPEN_CAPITALS)
      this.resetViewTicketType()
      this.setSelectedViewMode('normal')
      await this.getExternalClinics()
      await this.fetchEmbryoTypes()
      ss.setFieldSessionStorage('embryoTypes', this.embryoTypes)

      const response = await this.fetchCryoDevicesTypes()
      this.cryoDevicesTypes = response
      ss.setFieldSessionStorage('cryoDevicesTypes', this.cryoDevicesTypes)

      // Get printers status and set them up.
      getPrintersStatus()
      // Get Batch Feature Flag value
      const getBatchFlag = new FeatureFlag(import.meta.env.VITE_BATCH_FF)
      this.FF_BATCH = getBatchFlag.getValue()

      // Get User Permissions
      const userData = ss.getFieldSessionStorage('user')
      this.userHasManualScanPermission = userData?.permissions
        ? userData.permissions.includes(EXISTING_BEACON_MANUAL_SCAN)
        : false

      // If clinic is EMR enabled, does it allow ticket creation?
      this.allowCreateTickets =
        this.$isSiteEmrEnabled?.allowCreateStoreTickets ||
        this.$isSiteEmrEnabled?.allowCreateRetrieveTickets
      // Fecth tickets only after all other operations are performed.
      await this.fetchTickets(this.filter)
    } finally {
      // Add a timeout to allow tickets rendering in Flight Board
      setTimeout(() => {
        this.hideSpinner()
      }, 500)
    }
  }
}
</script>
