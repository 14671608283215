import toast from '@/config/toast'

const downloadBlob = ({ url }: { url: string }) =>
  new Promise((resolve, reject) => {
    try {
      if (!url) throw Error('Download Failed. Please try again.')
      const a: any = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.id = 'downloadLink'
      a.href = url
      a.download = 'audittrail.csv'
      a.click()
      const element: any = document.getElementById('downloadLink')
      element.remove()
      resolve(true)
    } catch (error: any) {
      toast.error({ title: error.message })
      reject(error)
    }
  })

export { downloadBlob }
