<template>
  <MainContainer gridSlots="2">
    <top-header>Biorepository ticket type</top-header>
    <select-procedure-buttons :methods="methods" :onSelectProcedure="handleSelectProcedure" />
  </MainContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import { METHOD_SEND, METHOD_REQUEST } from '@/constants'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import SelectProcedureButtons from '@/components/Biorepository/SelectProcedureButtons/SelectProcedureButtons.vue'

export default {
  name: 'select-procedure',
  data() {
    return {
      methods: [METHOD_SEND, METHOD_REQUEST]
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep'])
  },
  methods: {
    async handleSelectProcedure(method) {
      // TODO: Implement routing to next ticket creation step
      // eslint-disable-next-line no-console
      console.log('Procedure type: ', method)
    }
  },
  components: {
    TopHeader,
    SelectProcedureButtons,
    MainContainer
  }
}
</script>
