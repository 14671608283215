<template>
  <MainContainer gridSlots="3">
    <loading-ui v-if="isLoading" modal message="Retrieving Specimens Data" />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="select-specimens__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">{{ headerMessage }}</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="select-specimens-next-btn"
          class="select-specimens__btn-next"
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isSpecimenSelected"
          @click="handleSelectSpecimens"
        />
      </template>
    </ActionBar>
    <div class="main-container main-container--actions">
      <div class="main-container__body">
        <Breadcrumbs v-if="isExport" :items="breadcrumbsItems" />
        <h3
          class="
            no-specimens-title
            w-full
            h-full
            flex
            items-center
            justify-center
            text-2xl text-white
          "
          v-else-if="hasNoSpecimens"
        >
          This patient has no Specimens of this type stored. A {{ selectedMethod }} Ticket cannot be
          created.
        </h3>
        <h3
          class="
            no-specimens-title
            w-full
            h-full
            flex
            items-center
            justify-center
            text-2xl text-white
          "
          v-else-if="hasSpecimenErrorData"
        >
          There was an error requesting the data
        </h3>
        <div data-test="specimens-table-list" id="specimens-table-list" class="specimens-table-list" v-if="hasTableHeaders">
          <div
            v-if="isExport && (numberSpecimensOocytes > 0 || numberSpecimensEmbryos > 0)"
            data-test="tabs-container"
            class="flex justify-between"
          >
            <div data-test="tabs-container-left" class="flex items-end">
              <Tabs
                :options="openSpecimensOptions"
                :active="selectedSpecimenTypeTab"
                @changeValue="handleTabChange"
              />
            </div>
            <div data-test="export-specimen-count" class="text-xl text-white leading-6 mr-2">
              {{ specimenCountMessage }}
            </div>
          </div>
          <ScrollTableComponent
            data-test="specimens-table"
            v-model="specimenModel"
            :selectionMode="'checkbox'"
            :headers="headers"
            minHeight="h-full"
            singleSelection
            :hasTabs="isExport"
            hideSelectAllCheckbox
            ignore-select-all-procedure-check
            @update:modelValue="handleTableChange"
          />
          <p
            v-if="isExport && isSpecimenSelected"
            data-test="selected-specimens-count"
            class="text-white py-3"
          >
            {{ selectedSpecimensMessage }}
          </p>
        </div>
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import Breadcrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import * as specimensHeaders from '@/constants/table-headers/select-specimens'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import {
  CRYODEVICES,
  METHOD_EXPORT,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EGGS_UPPERCASE,
  SPECIMEN_TYPE_EGG_UPPERCASE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE_UPPERCASE,
  SPECIMEN_TYPE_OOCYTES,
  SPECIMEN_TYPE_EMBRYOS
} from '@/constants'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { buildSpecimenSourcesValueObject } from '@/helpers/specimenHelpers'

export default {
  name: 'select-specimens-for-new-ticket',
  components: {
    ScrollTableComponent,
    TopHeader,
    LoadingUi,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    DynamicTitle,
    HeaderInfo,
    Tabs,
    Breadcrumbs
  },
  data() {
    return {
      specimenModel: {
        sort: {
          orderBy: 'specimenId',
          direction: 'asc'
        },
        options: []
      },
      selectedSpecimens: [],
      allSpecimens: [],
      localSpecimens: [],
      selectedBeacons: [],
      specimenImportTypes: [],
      isLoadingImportTypes: false,
      isLoading: false,
      currentSpecimenType: SPECIMEN_TYPE_EMBRYO,
      selectedSpecimenTypeTab: null
    }
  },
  async created() {
    const specimenTypeUpperCase = this.selectedSpecimenType
      ? this.selectedSpecimenType.toUpperCase()
      : ''

    this.currentSpecimenType = specimenTypeUpperCase === SPECIMEN_TYPE_EGGS_UPPERCASE
      || specimenTypeUpperCase === SPECIMEN_TYPE_OOCYTE_UPPERCASE
      ? SPECIMEN_TYPE_EGG_UPPERCASE
      : specimenTypeUpperCase

    this.selectedSpecimenTypeTab = this.currentSpecimenType
      ? this.currentSpecimenType
      : SPECIMEN_TYPE_EMBRYO

    this.isLoading = true

    const patientIsNotNull = Boolean(Object.keys(this.selectedPatient[0]).length)
    if (patientIsNotNull) {
      const patientIds = this.selectedPatient.map((patient) => patient.globalPatientNumber)
      await this.fetchSpecimensEnabled({ patientIds, specimenType: this.currentSpecimenType, includeChildTickets: true  })
    }

    this.isLoading = false
  },
  computed: {
    ...mapGetters('specimensModule', ['specimens', 'embryoTypes']),
    ...mapGetters('authModule', ['appBaseConfig']),
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedPatient',
      'selectedSpecimenType'
    ]),
    breadcrumbsItems() {
      return [
        {
          label: 'Specimens',
          active: true
        },
        {
          label: 'Reason',
          active: false
        },
        {
          label: 'Confirm',
          active: false
        }
      ]
    },
    isExport() {
      return this.selectedMethod === METHOD_EXPORT
    },
    headerMessage() {
      return `Which Specimen(s) would you like to ${this.selectedMethod.toLowerCase()}?`
    },
    specimenCountMessage() {
      const totalSpecimens = this.numberSpecimensEmbryos + this.numberSpecimensOocytes

      return `${totalSpecimens} Specimens and ${this.allSpecimens.length} Cryodevices Total`
    },
    headers() {
      return specimensHeaders[this.selectedSpecimenTypeTab.toLowerCase()]
    },
    isSpecimenSelected() {
      return this.selectedSpecimens.length > 0
    },
    hasNoSpecimens() {
      return this.specimens.status === 'ok' && this.allSpecimens.length === 0
    },
    hasSpecimenErrorData() {
      return this.specimens.status === 'error'
    },
    hasTableHeaders() {
      return this.headers.length
    },
    selectedSpecimensMessage() {
      const embryos = this.selectedSpecimens.filter(
        (specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO
      )
      const embryoCount = embryos.reduce(
        (count, current) => count + Number(current.specimenCount),
        0
      )

      const oocytes = this.selectedSpecimens.filter(
        (specimen) => specimen.specimenType !== SPECIMEN_TYPE_EMBRYO
      )
      const oocyteCount = oocytes.reduce(
        (count, current) => count + Number(current.specimenCount),
        0
      )

      return `${embryoCount} Embryo${embryoCount === 1 ? '' : 's'} and ${oocyteCount} Oocyte${
        oocyteCount === 1 ? '' : 's'
      } Selected`
    },
    embryoCryodevices() {
      const embryoCryodevices = this.allSpecimens.filter(
        (specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO
      )

      return embryoCryodevices
    },
    oocyteCryodevices() {
      const oocyteCryodevices = this.allSpecimens.filter(
        (specimen) => specimen.specimenType !== SPECIMEN_TYPE_EMBRYO
      )

      return oocyteCryodevices
    },
    numberSpecimensEmbryos() {
      const embryoCount = this.embryoCryodevices.reduce(
        (count, current) => count + Number(current.specimenCount),
        0
      )

      return embryoCount
    },
    numberSpecimensOocytes() {
      const oocyteCount = this.oocyteCryodevices.reduce(
        (count, current) => count + Number(current.specimenCount),
        0
      )

      return oocyteCount
    },
    openSpecimensOptions() {
      const getSpecimensCountLabel = (specimens, devices) => {
        let label = `${specimens} Total`
        if (specimens) {
          label += ` on ${devices} ${CRYODEVICES}`
        }
        return label
      }

      const { numberSpecimensEmbryos, numberSpecimensOocytes } = this
      const embryoDevicesCount = this.embryoCryodevices.length
      const oocyteDevicesCount = this.oocyteCryodevices.length

      return [
        {
          embryoDevicesCount,
          value: SPECIMEN_TYPE_EMBRYO,
          label: SPECIMEN_TYPE_EMBRYOS,
          count: getSpecimensCountLabel(numberSpecimensEmbryos, embryoDevicesCount),
          disabled: !numberSpecimensEmbryos
        },
        {
          oocyteDevicesCount,
          value: SPECIMEN_TYPE_EGG,
          label: SPECIMEN_TYPE_OOCYTES,
          count: getSpecimensCountLabel(numberSpecimensOocytes, oocyteDevicesCount),
          disabled: !numberSpecimensOocytes
        }
      ]
    }
  },
  methods: {
    ...mapActions('specimensModule', ['fetchSpecimensEnabled']),
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        fromRouteName: this.fromRouteName
      })

      this.$router.replace({ name: previousStepPath })
    },
    async handleSelectSpecimens() {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        beacons: this.selectedBeacons
      })

      this.$router.push({ name: nextStepPath })
    },
    async handleTableChange(newValue) {
      const currentSpecimens = this.localSpecimens.map((localItem) => {
        const foundItem = newValue.options.find(
          (selectedItem) => localItem.cryodeviceBarcode === selectedItem.cryodeviceBarcode
        )

        if (foundItem) {
          return {
            ...localItem,
            ...foundItem
          }
        }

        return localItem
      })

      this.localSpecimens = currentSpecimens
      this.selectedSpecimens = currentSpecimens.filter(
        (selectedSpecimen) => selectedSpecimen.selected
      )

      const currentBeacons = this.selectedSpecimens.reduce((acc, specimen) => {
        const specimenWithTypeInfo = {
          ...specimen,
          ...(specimen.specimenType === SPECIMEN_TYPE_EMBRYO && { ...specimen.embryo }),
          ...(specimen.specimenType === SPECIMEN_TYPE_EGG && { ...specimen.oocyte })
        }

        const existingBeacon = acc.find((item) => item.beaconBarcode === specimen.beaconBarcode)

        if (existingBeacon) {
          const filteredAcc = acc.filter((beac) => beac.beaconBarcode !== specimen.beaconBarcode)

          return [
            ...filteredAcc,
            {
              ...existingBeacon,
              cryoDevice: [...existingBeacon.cryoDevice, specimenWithTypeInfo]
            }
          ]
        }

        return [
          ...acc,
          {
            beaconBarcode: specimen.beaconBarcode,
            cryoDevice: [specimenWithTypeInfo]
          }
        ]
      }, [])

      this.selectedBeacons = currentBeacons
    },
    handleTabChange(option) {
      this.selectedSpecimenTypeTab = option.value
    },
    applySourcesValueObjectToSpecimens(specimens) {
      return specimens.map((specimen) => ({
        ...specimen,
        sourcesValue: buildSpecimenSourcesValueObject(specimen)
      }))
    }
  },
  watch: {
    specimens() {
      const enabledSpecimens = this.specimens.all.filter((specimen) => !specimen.disabled)
      const formattedSpecimens = enabledSpecimens.map((specimen) => ({
        ...specimen,
        cryoDate: Number(specimen.cryoDate),
        embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes)
      }))

      this.allSpecimens = formattedSpecimens
      this.localSpecimens = formattedSpecimens
    },
    async allSpecimens(newValue) {
      if (newValue.length) {
        const filteredSpecimensByType = this.allSpecimens.filter(
          (specimen) => specimen.specimenType.toLowerCase() === this.selectedSpecimenTypeTab.toLowerCase()
        )

        this.specimenModel = {
          ...this.specimenModel,
          options: this.applySourcesValueObjectToSpecimens(filteredSpecimensByType)
        }
      }
    },
    selectedSpecimenTypeTab(newTabValue) {
      if (newTabValue) {
        const filteredSpecimensByType = this.localSpecimens.filter(
          (specimen) => specimen.specimenType.toLowerCase() === this.selectedSpecimenTypeTab.toLowerCase()
        )

        this.specimenModel = {
          ...this.specimenModel,
          options: this.applySourcesValueObjectToSpecimens(filteredSpecimensByType)
        }
      }
    }
  }
}
</script>
