<template>
  <div
    class="
      bg-white
      flex flex-1 flex-col
      font-inconsolata font-semibold
      justify-center
      h-full
      items-center
      p-2
      py-6
      rounded-md
      text-center
      w-full
      relative
    "
    data-test="new-cryobeacon"
  >
    <CryobeaconNumber
      :activeBeaconCounter="isReplaceCryoBeacon ? `${CryoBeacon} 1 of 1` : computeActiveBeaconCounterMsg"
      :isAtTheLeftCorner="true"
    />
    <div v-if="beaconBarcode" class="text-5xl p-2">
      <div class="text-base text-tmrw-blue" data-test="scan-result-title">{{ CryoBeacon }} ID</div>
      <div class="flex items-center" data-test="scan-result-beacon-id">
        {{ beaconBarcode }}
        <i
          class="text-2xl bg-white rounded-full border-solid border-white border ml-2 fa"
          :class="[
            isBeaconValid ? 'fa-check-circle text-green-400' : 'fa-exclamation-circle text-red-500',
          ]"
        />
      </div>
    </div>
    <p
      class="flex text-white rounded bg-red-500 text-2xl p-4 m-2 mb-6 leading-normal items-center"
      v-if="scanResultMessage"
    >
      <span v-html="scanResultMessage" data-test="scan-result-message" />
      <span
        v-if="!isBeaconReadTimedOut && !beaconBarcode"
        class="bg-white text-red-500 rounded-lg p-1 ml-3 w-8 h-8 flex flex-col justify-center"
      >
        <span>{{ singleVialReaderTimeoutCounter }}</span>
      </span>
    </p>
    <img
      data-test="registration-tray-animation"
      v-if="!beaconBarcode"
      class="h-auto w-1/4"
      src="@/assets/registration-tray-animation.gif"
      alt="Single CryoBeacon reader"
    />
    <p
      v-if="scanAgain"
      class="text-tmrw-blue-light"
      data-test="unused-cryobeacon-error"
    >
      Please scan an unused {{ CryoBeacon }}
    </p>

    <button-dynamic
      v-if="scanAgain"
      btnText="Scan Again"
      btnType="button"
      btnStyle="primary"
      data-test="new-cryobeacon__scan-again-button"
      showIcon
      fontAwesomeIconClass="sync-alt"
      floatTo="none"
      addMarginTop
      @click="handleScanAgainButtonClick"
    />
    <button-dynamic
      v-if="userHasManualScanPermission && !beaconBarcode && !isBeaconReadTimedOut"
      btnText="Scan with Vial Reader"
      btnType="button"
      btnStyle="primary"
      data-test="new-cryobeacon__scan-with-vial-reader-button"
      showIcon
      fontAwesomeIconClass="qrcode"
      floatTo="none"
      addMarginTop
      @click="listenToSingleVialReader"
    />
    <ScanBeacon
      v-if="!beaconBarcode && userHasManualScanPermission"
      class="mx-auto w-full m-2 mt-12 flex flex-col justify-center items-center"
      :value="beaconBarcode"
      scanMode="show"
      @changeValue="handleFieldChange"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CryobeaconNumber from '@/components/CryoBeaconNumber/CryoBeaconNumber.vue'
import ScanBeacon from '@/components/ScanBeacon/ScanBeacon.vue'
import {
  CRYOBEACON,
  NEW_BEACON_STEP,
  SCAN_VALID,
  EXISTING_BEACON_MANUAL_SCAN
} from '@/constants'
import { useTicket } from '@/composables/useTicket'
import * as ss from '@/config/session-storage-help'
import toast from '@/config/toast'
import { getScanBeaconIdApi, getRegistrationStatusApi } from '@/services/singleVialReader'
import { activeBeaconCounterMsg } from '@/helpers/newTicketHelpers'

export default {
  name: 'new-cryoBeacon',
  data() {
    return {
      CryoBeacon: CRYOBEACON,
      beaconBarcode: null,
      userHasManualScanPermission: true,
      singleVialReaderTimeoutCounter: 15,
      singleVialReaderTimeoutInterval: null,
      readBeaconBarcode: null,
      isBeaconValid: false,
      isBeaconReadTimedOut: false,
      tagData: null
    }
  },
  props: {
    isReplaceCryoBeacon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['isValidBeaconHandler'],
  setup() {
    const {
      ticket,
      activeBeacon,
      replaceBeaconInBeaconList,
      updateTicketBeacons,
      saveTicket
    } = useTicket()
    return {
      ticket,
      activeBeacon,
      replaceBeaconInBeaconList,
      updateTicketBeacons,
      saveTicket
    }
  },
  mounted() {
    const userData = ss.getFieldSessionStorage('user')
    this.userHasManualScanPermission = userData?.permissions
      ? userData.permissions.includes(EXISTING_BEACON_MANUAL_SCAN)
      : false
    if (!this.userHasManualScanPermission) {
      this.listenToSingleVialReader()
    }
  },
  beforeUnmount() {
    clearInterval(this.singleVialReaderTimeoutInterval)
  },
  components: {
    ButtonDynamic,
    ScanBeacon,
    CryobeaconNumber
  },
  methods: {
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapActions('beaconsModule', ['checkBeaconId', 'setBeaconBarcode', 'changeBeaconStep']),
    handleScanAgainButtonClick() {
      if (this.userHasManualScanPermission) {
        this.showScanLayout()
      } else {
        this.listenToSingleVialReader()
      }
    },
    handleFieldChange(value) {
      this.displaySpinner('Validating CryoBeacon')
      this.validateBeacon(value)
    },
    showScanLayout() {
      this.isBeaconValid = false
      this.isBeaconReadTimedOut = false
      this.beaconBarcode = null
      this.setBeaconBarcode(this.beaconBarcode)
    },
    async validateBeacon(beaconId) {
      try {
        const { data: { status } } = await this.checkBeaconId([beaconId, 'Beacon'])
        this.beaconBarcode = beaconId
        this.isBeaconValid = status === SCAN_VALID
        this.setBeaconBarcode(beaconId)

        if (!this.isBeaconValid) {
          const currentBeaconsArray = JSON.parse(ss.getFieldSessionStorage('newBeaconsArray') || '[]')
          const newBeaconsArray = currentBeaconsArray.filter((beacon) => beaconId !== beacon)
          ss.setFieldSessionStorage('newBeaconsArray', JSON.stringify(newBeaconsArray))
        } else {
          ss.setFieldSessionStorage('lastScannedBeacon', beaconId)
          if (Object.keys(this.activeBeacon) && !this.isReplaceCryoBeacon) {
            const updatedActiveBeacon = this.updateBeaconCryoDevice(this.activeBeacon, this.beaconBarcode)
            const updatedBeacons = this.replaceBeaconInBeaconList(this.ticket.beacons, updatedActiveBeacon)
            this.ticket = this.updateTicketBeacons(this.ticket, updatedBeacons)
            this.saveTicket(this.ticket)
          }
          this.changeBeaconStep(NEW_BEACON_STEP)
        }

        this.$emit('isValidBeaconHandler', this.isBeaconValid)
      } catch (error) {
        toast.error({ title: error.message })
      } finally {
        this.hideSpinner()
      }
    },
    updateBeaconCryoDevice(activeBeacon, beaconBarcode) {
      const { cryoDevice } = activeBeacon
      const updatedActiveBeacon = {
        ...activeBeacon,
        cryoDevice: cryoDevice.map((cryoDev) => ({
          ...cryoDev,
          beaconBarcode
        }))
      }
      return updatedActiveBeacon
    },
    async listenToSingleVialReader() {
      this.isBeaconValid = false
      this.beaconBarcode = null
      this.setBeaconBarcode(this.beaconBarcode)
      this.isBeaconReadTimedOut = false
      this.singleVialReaderTimeoutCounter = 15

      this.singleVialReaderTimeoutInterval = setInterval(() => {
        this.singleVialReaderTimeoutCounter--
        this.userHasManualScanPermission = false
        if (this.singleVialReaderTimeoutCounter === 0) {
          const userData = ss.getFieldSessionStorage('user')
          this.userHasManualScanPermission = userData?.permissions
            ? userData.permissions.includes(EXISTING_BEACON_MANUAL_SCAN)
            : false
          this.isBeaconReadTimedOut = true
          clearInterval(this.singleVialReaderTimeoutInterval)
        }
      }, 1000)

      try {
        this.tagData = await getScanBeaconIdApi()
        if (this.tagData.data.tagDetected) {
          this.readBeaconBarcode = this.tagData.data.id
          await this.validateBeacon(this.readBeaconBarcode)
          const newBeaconsArray = (
            ss.getFieldSessionStorage('newBeaconsArray') && JSON.parse(ss.getFieldSessionStorage('newBeaconsArray')))
          const beaconIndex = newBeaconsArray ? newBeaconsArray.indexOf(this.readBeaconBarcode) : -1
          if (beaconIndex < 0 && this.isBeaconValid) {
            getRegistrationStatusApi({ status: 'success' })
          } else {
            this.isBeaconValid = false
            getRegistrationStatusApi({ status: 'fail' })
          }
          this.$emit('isValidBeaconHandler', this.isBeaconValid)
          clearInterval(this.singleVialReaderTimeoutInterval)
        }
      } catch (err) {
        clearInterval(this.singleVialReaderTimeoutInterval)
        this.isBeaconReadTimedOut = true
      }
    }
  },
  computed: {
    titleIconClass() {
      return this.isBeaconValid
        ? 'fa-check-circle text-green-400'
        : 'fa-exclamation-circle text-red-500'
    },
    scanResultMessage() {
      if (this.beaconBarcode && !this.isBeaconValid) {
        return `This ${this.CryoBeacon} ID already exists!
          <br />Please use another ${this.CryoBeacon}.`
      }

      if (!this.beaconBarcode) {
        return this.isBeaconReadTimedOut
          ? `There was no ${this.CryoBeacon} detected on the Registration Tray.`
          : 'Reading from Single Vial Reader'
      }

      return null
    },
    computeActiveBeaconCounterMsg() {
      if (this.ticket?.beacons) {
        return activeBeaconCounterMsg(this.ticket.beacons)
      }
      return ''
    },
    scanAgain() {
      return !this.isBeaconValid && (this.isBeaconReadTimedOut || this.beaconBarcode)
    }
  }
}
</script>
