<template>
  <div
    class="login h-screen w-screen flex items-center justify-center"
    gridSlots="1"
    data-test="reset-password"
    id="reset-password"
  >
    <div class="w-3/12 justify-center items-center" data-test="reset-password__form-section">
      <div class="flex items-center justify-center">
        <tmrw-logo @onClickAppLogo="showAppInfoModal" />
      </div>
      <p data-test="forgot-pass-subtitle" class="text-tmrw-green-light text-center py-4">SPECIMEN MANAGEMENT SYSTEM</p>
      <h2
        class="text-3xl text-white font-exo font-semibold py-10 flex items-center justify-center"
      >
        Reset your password
    </h2>
      <Tooltip
        placement="right"
        :visible="showUsernameFirstTooltip"
        content="Content"
        :tooltip-cross-offset="-20"
        background-color="#fff"
      >
        <template #content>
          <section class="tooltip-content">
            <ul class="flex flex-col items-center p-6 font-exo">
              <li>Please enter a User Name first</li>
            </ul>
          </section>
        </template>
        <input-field
          inputType="text"
          inputName="username"
          @focus="selectedInput = 'username'"
          @blur="selectedInput = null"
          :inputLabel="state.username ? 'User Name' : ''"
          v-model="state.username"
          inputPlaceholder="User Name"
          placeholderColor="tmrw-green-light"
          labelColor="tmrw-green-light"
          inputColor="text-white"
          :hasError="checkError.username"
          colorErrorText="text-tmrw-green-light"
          class="w-full text-lg pb-12"
        />
      </Tooltip>
      <Tooltip
        placement="right"
        :visible="showPasswordValidationTooltip"
        :tooltip-cross-offset="-20"
        backgroundColor="#fff"
      >
        <template #content>
          <section class="tooltip-content">
            <ul class="flex flex-col items-center p-6 font-exo">
              <li>Passwords should have at least:</li>
              <li
                :class="{
                  'text-tmrw-warning my-1': capitalLetterValidation,
                  'text-tmrw-success my-1': !capitalLetterValidation,
                }"
              >
                One capital letter
              </li>
              <li
                :class="{
                  'text-tmrw-warning my-1': lowerLetterValidation,
                  'text-tmrw-success my-1': !lowerLetterValidation,
                }"
              >
                One lower case letter
              </li>
              <li
                :class="{
                  'text-tmrw-warning my-1': numericValidation,
                  'text-tmrw-success my-1': !numericValidation,
                }"
              >
                One number
              </li>
              <li
                :class="{
                  'text-tmrw-warning my-1': hasCorrectlengthValidation,
                  'text-tmrw-success my-1': !hasCorrectlengthValidation,
                }"
              >
                8 or more characters
              </li>
            </ul>
          </section>
        </template>
        <input-field
          inputType="password"
          inputName="password"
          @focus="selectedInput = 'password'"
          @blur="selectedInput = null"
          :inputLabel="state.password ? 'Password' : ''"
          v-model="state.password"
          inputPlaceholder="Password"
          placeholderColor="tmrw-green-light"
          labelColor="tmrw-green-light"
          inputColor="text-white"
          :hasError="checkError.password"
          colorErrorText="text-tmrw-green-light"
          class="w-full text-lg pb-12"
        />
      </Tooltip>
      <input-field
        class="w-full text-lg"
        inputType="password"
        inputName="confirmPassword"
        @focus="selectedInput = 'confirmPassword'"
        @blur="selectedInput = null"
        v-model="state.confirmPassword"
        inputPlaceholder="Re-enter password"
        placeholderColor="tmrw-green-light"
        labelColor="tmrw-green-light"
        textColor="text-white"
        inputColor="text-white"
        :inputLabel="state.confirmPassword ? 'Re-enter password' : ''"
        :label="state.confirmPassword ? 'Password' : ''"
        :hasError="checkError.confirmPassword"
        colorErrorText="text-tmrw-green-light"
      />
      <p
        v-if="serverError"
        data-test="reset-password-error-message"
        class="reset-password-error-message text-center text-tmrw-warning py-6"
      >
        {{ serverError }}
      </p>

      <div class="flex items-center justify-center" :class="{ 'mt-12': !serverError }">
        <button-dynamic
          :isLoading="processingLogin"
          btnText="Reset Password"
          btnStyle="primary"
          :isDisabled="isButtonDisabled"
          :hasError="checkError.confirmPassword || serverError"
          @click="handleResetPassword"
        />
      </div>
      <p class="text-tmrw-green-light text-center py-5" data-test="reset-pass-contact-message">
        Forgot your user name?<br />
        Please contact your clinic administrator.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  required, sameAs, minLength
} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {
  computed, reactive, onMounted, watch, ref
} from 'vue'
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import InputField from '@/components/InputField/InputField.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import useActions from '@/composables/useActions'
import { UsersModule } from '@/store/modules/users'


export interface IResetPassword {
  processingLogin: boolean
  selectedInput: string | null
  submited: boolean
  resetPasswordToken: string
  serverError: any
  state: {
    username: string
    password: string
    confirmPassword: string
  },
  showUsernameFirstTooltip: boolean,
  showPasswordValidationTooltip: boolean
}

const router = useRouter()
const route = useRoute()
const store = useStore()

const containsPasswordRequirement = (value) => /^(?=.*[A-Z])/.test(value) && /^(?=.*[0-9])/.test(value) && /^(?=.*[a-z])/.test(value)

const processingLogin = ref<boolean>(false)
const selectedInput = ref<string | null>(null)
const submited = ref(false)
const resetPasswordToken = ref('')
const serverError = ref<any>(null)
const state = reactive({
  username: '',
  password: '',
  confirmPassword: ''
})

const rules = computed(() => ({
  username: {
    required,
    $autoDirty: true
  },
  password: {
    required,
    containsPasswordRequirement,
    minLength: minLength(8),
    $autoDirty: true
  },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(state.password),
    $autoDirty: true
  }
}))

  
const v$ = useVuelidate(rules, state)

const checkError = computed(() => {
  const passwordMessage = `Passwords must include at least one capital letter,
        one number, and eight or more characters.`
  return {
    username: v$.value.username?.$error ? 'Sign In Name is required' : '',
    password: v$.value.password?.$error ? passwordMessage : '',
    confirmPassword: v$.value.confirmPassword?.$error ? 'Password doesn’t match' : ''
  }
})

const isButtonDisabled = computed(
  () => state.username === '' || state.password === '' || state.confirmPassword === '' || v$.value.$invalid
)

const isPasswordSelected = computed(() => {
  return selectedInput.value !== null && selectedInput.value !== 'username'
})

const showPasswordValidationTooltip = computed(
  () => !showUsernameFirstTooltip.value && isPasswordSelected.value && state.password.length > 0
)

const showUsernameFirstTooltip = computed(() => {
  if (isPasswordSelected.value && !state.username) {
    return true
  }
  return false
})

const capitalLetterValidation = computed(() => {
  // at least One Capital Letter
  const regexp = /^(?=.*[A-Z])/
  return !regexp.test(state.password)
})

const lowerLetterValidation = computed(() => {
  // at least One Lower Letter
  const regexp = /^(?=.*[a-z])/
  return !regexp.test(state.password)
})

const numericValidation = computed(() => {
  // at least one number
  const regexp = /^(?=.*[0-9])/
  return !regexp.test(state.password)
})

const hasCorrectlengthValidation = computed(
  () =>
    // at least 8 or more letters
    state.password.length < 8
)

onMounted(() => {
  resetPasswordToken.value = route.params.token as string
})

watch(() => state.username, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    serverError.value = null
  }
})

const { changePassword } = useActions<UsersModule['actions']>('usersModule')
// TODO: add type definition to authModule
const { toggleAppInfoModal } = useActions('appInfoModule')


const showAppInfoModal = () => {
  toggleAppInfoModal()
  store.dispatch('appInfoModule/toggleAppInfoModal')
}

const handleResetPassword = async () => {
  submited.value = true
  processingLogin.value = true
  try {
    await changePassword({
      loginName: state.username,
      newPassword: state.password,
      resetPasswordToken: resetPasswordToken.value
    })
    router.push({ name: 'Login', params: { resetedPassword: 'true' } })
  } catch (error: any) {
    const [, errorMessage] = error.toString().split('Error: ')
    serverError.value = errorMessage
  } finally {
    submited.value = false
    processingLogin.value = false
  }
}
</script>
<style lang="scss">
.tooltip-content {
  @apply text-base text-tmrw-blue;
}
</style>
