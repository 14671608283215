<template>
  <section
    data-test="specimen-detail"
    class="p-4"
  >
    <top-header
      back-btn
    >
      You are viewing {{ CRYODEVICE }} ID
      {{ localSpecimenDetail.cryodeviceBarcode }}
    </top-header>
    <loading-ui
      v-if="isLoading"
      modal
      message="Loading Specimen Details"
    />
    <template v-else>
      <ActionBar
        data-test="patient-view__action-bar"
        cols-distribution="6/6"
      >
        <template v-slot:left-side>
          <dynamic-title
            v-if="localSpecimenDetail.location && localSpecimenDetail.location.robotLayoutTypeId !== 0"
            title-type="h2"
            float-to="left"
          >
            Location
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btn-type="button"
            btn-text="Close"
            show-icon
            btn-style="primary"
            add-margin-top
            add-margin-bottom
            font-awesome-icon-class="times-circle"
            @click="backToPatient"
          />
        </template>
      </ActionBar>
      <div
        v-if="localSpecimenDetail.location && localSpecimenDetail.location?.robotLayoutTypeId !== 0"
        data-test="specimen-detail__location-table"
        class="grid gap-4 p-4 sm:grid-cols lg:grid-cols-12 bg-white rounded-md mb-6"
      >
        <div
          v-for="(field, key) in specimenFields"
          :key="key"
          :class="field.addClasses && [...field.addClasses]"
          :data-test="`location-field-${field.dataTest}`"
        >
          <label class="mb-2 text-base text-tmrw-blue">
            {{ field.label }}
          </label>
          <p class="text-xl font-medium text-tmrw-blue-dark border-b border-solid py-2 h-10">
            {{ field.value }}
          </p>
        </div>
      </div>
      <section
        data-test="specimen-details__details"
        class="flex flex-col mb-6"
      >
        <dynamic-title
          title-type="h2"
          float-to="left"
          class="mb-2"
        >
          Details
        </dynamic-title>
        <InventorySpecimenTables
          :embryo-specimen-headers="embryoHeaders"
          :egg-specimen-headers="oocyteHeaders"
          :embryo-specimens="embryoSpecimensModel"
          :egg-specimens="eggSpecimensModel"
          class="overflow-auto"
        />
      </section>
      <Patients
        v-if="isMultiplePatientEnabled && hasSharedPatients"
        data-test="specimen-detail__patients"
        class="my-6"
        :ids="patientIds"
        :specimenId="specimenId"
        :patientId="Number(patientId)"
        :cryodeviceBarcode="localSpecimenDetail.cryodeviceBarcode"
      />
      <ActionBar
        data-test="patient-view__action-bar"
        cols-distribution="6/6"
        class="mb-2"
      >
        <template v-slot:left-side>
          <dynamic-title
            title-type="h2"
            float-to="left"
          >
            Audit Trail
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <link-dynamic
            :href="url"
            :download="auditLogCSVFileName"
            link-type="button"
            link-text="Export"
            link-style="secondary"
            show-icon
            addMarginBottom
            font-awesome-icon-class="file-export"
          />
        </template>
      </ActionBar>
      <ScrollTableComponent
        data-test="specimen-detail__audit-trail-table"
        v-model="eventsModel"
        :headers="auditHeaders"
        :overflow-hidden="false"
        class="overflow-auto"
        min-height="h-full"
      />
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  embryoTypeValues,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EMBRYO,
  CRYOBEACON,
  CRYODEVICE,
  CRYOGRID,
  CRYOROBOT,
  LAYOUT_B3C,
  LAYOUT_WAVE,
  LAYOUT_CRS,
  NON_TMRW_LOCATION
} from '@/constants'
import { embryoHeaders, oocyteHeaders, auditHeaders } from '@/constants/table-headers/cryodevice-details'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import LinkDynamic from '@/components/LinkDynamic/LinkDynamic.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import toast from '@/config/toast'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import TempCryoBeaconMixin from '@/mixins/TempCryoBeaconMixin'
import { downloadBlob } from '@/helpers/downloadBlobHelper'
import { replaceStringFilter } from '@/filters'
import Patients from '@/components/SpecimenDetail/Patients/Patients.vue'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'
import { getShippingSourceName } from '@/helpers/patientHelpers'
import { buildImportedSpecimenToolTipTableData } from '@/helpers/specimenHelpers'

dayjs.extend(utc)

export default {
  name: 'SpecimenDetail',
  data() {
    return {
      isLoading: true,
      isMultiplePatientEnabled: false,
      url: null,
      CryoBeacon: CRYOBEACON,
      CryoGrid: CRYOGRID,
      CryoRobot: CRYOROBOT,
      eventsModel: {
        sort: {
          orderBy: 'dateTime',
          direction: 'desc'
        },
        options: []
      },
      localSpecimensModel: {
        sort: {
          orderBy: 'dateTime',
          direction: 'desc'
        },
        options: []
      },
      auditLogCSVFileName: null,
      embryoHeaders,
      oocyteHeaders,
      baseHeaders: { embryoHeaders, oocyteHeaders },
      auditHeaders,
      CRYODEVICE,
      specimenUnit: 0
    }
  },
  mixins: [TempCryoBeaconMixin],
  async mounted() {
    try {
      this.isMultiplePatientEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
      await this.getExternalClinics()
      await this.fetchEmbryoTypes()
      await this.fetchCryoDevicesTypes()
      await this.fetchSpecimenDetails([
        this.specimenId,
        this.patientId
      ])
      await this.fetchSpecimensEvents([
        this.specimenId,
        this.specimenDetails.cprId
      ])
      await this.fetchAuditLog([
        this.patientId,
        this.specimenId
      ])
      this.specimenUnit = this.allContainers.find(container => container.containerId === this.localSpecimenDetail.unitId)
      this.url = window.URL.createObjectURL(new Blob([this.fileUrl]))
      this.auditLogCSVFileName = 'audittrail.csv'
    } catch (err) {
      toast.error(err)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('specimensModule', [
      'fetchSpecimensEvents',
      'fetchSpecimenDetails',
      'fetchAuditLog',
      'fetchEmbryoTypes',
      'fetchCryoDevicesTypes'
    ]),
    ...mapActions('patientsModule', ['getExternalClinics']),
    handleSubmit(event) {
      event.preventDefault()
      this.editAndSavePatient()
    },
    backToPatient() {
      this.$router.push({
        path: `/patients/${this.patientId}`
      })
    },
    async getAuditLog() {
      this.auditLogLoading = true
      await this.fetchAuditLog([
        this.$route.params.patientId,
        this.$route.params.specimenId
      ])
      this.url = window.URL.createObjectURL(new Blob([this.fileUrl]))
      await downloadBlob({ url: this.url })
      this.auditLogLoading = false
    }
  },
  computed: {
    ...mapGetters('specimensModule', [
      'specimenDetails',
      'specimenEvents',
      'fileUrl',
      'embryoTypes',
      'cryoDevicesTypes'
    ]),
    ...mapGetters('patientsModule', ['externalClinics', 'patient']),
    ...mapGetters('siteModule', ['allContainers']),
    embryoSpecimensModel() {
      const specimens = this.localSpecimensModel.options?.filter((specimen) => specimen.specimenType === 'Embryo')
      return {
        sort: {
          orderBy: 'dateTime',
          direction: 'desc'
        },
        options: specimens
      }
    },
    eggSpecimensModel() {
      const specimens = this.localSpecimensModel.options?.filter((specimen) => specimen.specimenType === 'Egg')
      let options = []
      if (specimens) {
        options = specimens
      }

      return {
        sort: {
          orderBy: 'dateTime',
          direction: 'desc'
        },
        options
      }
    },
    specimenFields() {
      const composedSpecimenFields = []
      if(this.localSpecimenDetail.location) {
        if(this.localSpecimenDetail?.location.robotLayoutTypeId === LAYOUT_B3C) {
          composedSpecimenFields.push(
            {
              label: this.CryoRobot,
              value: this.specimenUnit.robotNumber || '',
              dataTest: 'cryo-robot'
            },
            {
              label: 'Rack',
              value: this.localSpecimenDetail.location.rack || '',
              dataTest: 'rack'
            },
            {
              label: 'Shelf',
              value: this.localSpecimenDetail.location.shelf || '',
              dataTest: 'shelf'
            },
            {
              label: 'Position',
              value: this.localSpecimenDetail.location.position || '',
              dataTest: 'position'
            },
            {
              label: `${this.CryoGrid} ID`,
              value: this.localSpecimenDetail.location.boxName || '',
              addClasses: ['col-span-4'],
              dataTest: 'cryo-grid'
            },
            {
            label: `${this.CryoBeacon} ID`,
            value: this.showIfValidCryoBeaconId(this.localSpecimenDetail.beaconBarcode),
            addClasses: ['col-span-4'],
              dataTest: 'cryo-beacon'
          }
          )
        }
        if(this.localSpecimenDetail.location?.robotLayoutTypeId === LAYOUT_CRS) {
          composedSpecimenFields.push(
            {
              label: `${this.CryoRobot} Select`,
              value: this.specimenUnit.robotNumber || ''
            },
            {
              label: 'Position',
              value: this.localSpecimenDetail.location.position || '',
              addClasses: ['col-span-2']
            },
            {
              label: `${this.CryoBeacon} ID`,
              value: this.showIfValidCryoBeaconId(this.localSpecimenDetail.beaconBarcode),
              addClasses: ['col-span-9']
            }
          )
        }
        if(this.localSpecimenDetail.location?.robotLayoutTypeId === LAYOUT_WAVE) {
          composedSpecimenFields.push(
            {
              label: NON_TMRW_LOCATION,
              value: this.localSpecimenDetail.location.locationDetails || '',
              addClasses: ['col-span-6']
            },
            {
              label: `${this.CryoBeacon} ID`,
              value: this.showIfValidCryoBeaconId(this.localSpecimenDetail.beaconBarcode),
              addClasses: ['col-span-6']
            }
          )
        }
        return composedSpecimenFields
      }
      return []
    },
    localSpecimenDetail() {
      const baseSpecimenDetails = {
        cryodeviceType: this.specimenDetails.cryodeviceTypeId
          ? this.cryoDevicesTypes.filter(
            (cryo) => Number(cryo.id) === this.specimenDetails.cryodeviceTypeId
          )[0].value
          : '',
        cryoDate: dayjs
          .utc(Number(this.specimenDetails.cryoDate))
          .format('DDMMMYYYY')
          .toUpperCase()
      }

      if (this.specimenDetails.specimenType === SPECIMEN_TYPE_EMBRYO) {
        return {
          ...this.specimenDetails,
          ...baseSpecimenDetails,
          embryoTypeLabel: selectEmbryoTypeLabel(this.specimenDetails, this.embryoTypes)
        }
      }
      if (this.specimenDetails.specimenType === SPECIMEN_TYPE_EGG) {
        return {
          ...this.specimenDetails,
          ...baseSpecimenDetails,
          embryoTypeLabel: replaceStringFilter(this.specimenDetails.specimenType, embryoTypeValues)
        }
      }
      return {
        ...baseSpecimenDetails,
        ...this.specimenDetails
      }
    },
    localSpecimenInfo() {
      // If Free Text field is set, add it to the Embryo or Oocyte array of object columns.
      if (this.localSpecimenDetail.metadata?.metadata?.cryolabelFreeText?.length) {
        // Free Text column object to include in Embryo or Oocyte if value is set
        const freeTextColumn = {
          name: 'Free Text',
          sort: false,
          key: 'metadata.metadata.cryolabelFreeText',
          cssClasses: ['truncate', 'w-24'],
          component: 'tooltip',
          componentOptions: {
            alignment: 'left',
            maxlength: 10
          }
        }
        // Set index to insert the Free Text Field column.
        const specimenHeadersInsertIndex = this.localSpecimenDetail.specimenType === SPECIMEN_TYPE_EMBRYO ? 11 : 8
        // Determine and isolate the specimen specific object to insert the header to.
        const specimenTypeHeadersObject = this.localSpecimenDetail.specimenType === SPECIMEN_TYPE_EMBRYO
          ? 'embryoHeaders'
          : 'oocyteHeaders'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this[specimenTypeHeadersObject] = [...this.baseHeaders[specimenTypeHeadersObject]]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this[specimenTypeHeadersObject].splice(specimenHeadersInsertIndex, 0, freeTextColumn)
      }
      
      return [
        {
          ...this.localSpecimenDetail,
          embryoNumber: this.localSpecimenDetail.embryo
            ? this.localSpecimenDetail.embryo.embryoNumber
            : '',
          maturityLevel: this.localSpecimenDetail.oocyte
            ? this.localSpecimenDetail.oocyte.maturityLevel
            : '',
          biopsy: this.localSpecimenDetail.embryo ? this.localSpecimenDetail.embryo.biopsy : '',
          result:
            this.localSpecimenDetail.embryo && this.localSpecimenDetail.embryo.biopsyResult
              ? this.localSpecimenDetail.embryo.biopsyResult
              : '',
          freeText:
            !!this.localSpecimenDetail.metadata?.metadata?.cryolabelFreeText?.length
            && this.localSpecimenDetail.metadata.metadata.cryolabelFreeText,
          sources: this.localSpecimenDetail?.sources?.map((source) => (
            {
              ...source,
              shippingSourceName: getShippingSourceName({
                externalClinics: this.externalClinics,
                shippingSourceId: Number(source.shippingSourceId)
              })
            }
          )),
          sourcesValue: {
            items: buildImportedSpecimenToolTipTableData(this.localSpecimenDetail),
            image: this.isSpecimenSingleImported ? 'importCryoBeacon' : ''
          }
        }
      ]
    },
    localSpecimenEvents() {
      return this.specimenEvents.map((specimen) => ({
        ...specimen,
        fullName: `${specimen.user.firstName} ${specimen.user.lastName} (${specimen.user.loginName} - Id: ${specimen.user.id})`
      }))
    },
    patientId() {
      return this.$route.params.patientId
    },
    hasAuditEvents() {
      return Boolean(this.specimenEvents.length)
    },
    hasSharedPatients() {
      if (!this.specimenDetails) return false
      const { sharedPatientIds } = this.specimenDetails
      return Boolean(sharedPatientIds)
    },
    patientIds() {
      if (!this.hasSharedPatients) return []
      const { sharedPatientIds, cprId } = this.specimenDetails
      return [...sharedPatientIds, cprId]
    },
    specimenId() {
      return this.$route.params.specimenId
    },
    isSpecimenTypeEmbryo() {
      return this.specimenDetails.specimenType === SPECIMEN_TYPE_EMBRYO
    },
    urlPath() {
      return this.url
    },
    isSpecimenSingleImported() {
      return !!(this.specimenDetails?.sources && this.specimenDetails?.sources?.length)
    }
  },
  watch: {
    specimenEvents(newValue) {
      if (newValue) {
        this.eventsModel = {
          ...this.eventsModel,
          options: this.localSpecimenEvents
        }
      }
    },
    specimenDetails(newValue) {
      if (newValue) {
        this.localSpecimensModel = {
          ...this.localSpecimensModel,
          options: this.localSpecimenInfo
        }
      }
    }
  },
  components: {
    TopHeader,
    ScrollTableComponent,
    ButtonDynamic,
    InventorySpecimenTables,
    LoadingUi,
    ActionBar,
    DynamicTitle,
    LinkDynamic,
    Patients
  }
}
</script>
