<template>
  <span class="bg-white to-transparent flex justify-between p-5 text-tmrw-blue-light rounded-lg">
    {{message}}
  </span>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>
