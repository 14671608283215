import { getFieldSessionStorage } from '@/config/session-storage-help'
import { LocationItem, TicketMode } from '../types'

export type BiorepositoryTicketState = {
  ticketMode: TicketMode
  cryoShipperTickets: any[]
  sourceLocation: LocationItem
  destinationLocation: LocationItem
  selectedPatient: any
  selectedBeacons: any
  destinationCryorobot: LocationItem
}

const state: BiorepositoryTicketState = {
  cryoShipperTickets: getFieldSessionStorage('biorepositoryTicket', 'cryoShipperTickets'),
  ticketMode: getFieldSessionStorage('biorepositoryTicket', 'ticketMode'),
  sourceLocation: getFieldSessionStorage('biorepositoryTicket', 'sourceLocation'),
  destinationLocation: getFieldSessionStorage('biorepositoryTicket', 'destinationLocation'),
  selectedPatient: getFieldSessionStorage('biorepositoryTicket', 'selectedPatient'),
  selectedBeacons: getFieldSessionStorage('biorepositoryTicket', 'selectedBeacons'),
  destinationCryorobot: getFieldSessionStorage('biorepositoryTicket', 'destinationCryorobot')
}

export default state
