import { httpRequest } from '@/helpers/apiHelpers'
import { Paths as SearchPaths } from '@/types/searchBeacons'
import { Paths as TicketPaths } from '@/types/ticketsCreate'
import { OperationResponse } from 'openapi-client-axios'

export const searchCryobeacons = async (
  params: SearchPaths.SearchBeacons.HeaderParameters &
    SearchPaths.SearchBeacons.PathParameters
): OperationResponse<SearchPaths.SearchBeacons.Responses.$200> =>
  httpRequest().post('/beacons/search', params)

export const addCryobeaconsTickets = async (
  data: TicketPaths.BulkRelocationTickets.RequestBody
): Promise<
  OperationResponse<TicketPaths.BulkRelocationTickets.Responses.$200>
> => httpRequest().post('/tickets/bulk', data)
