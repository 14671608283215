export default {
  async saveState({ commit }) {
    commit('saveState')
  },
  async resetState({ commit }) {
    const currentState = window.localStorage.getItem('sms_state')
    if (currentState) {
      commit('resetState')
    }
  }
}
