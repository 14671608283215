import { createI18n } from 'vue-i18n'

async function loadLocaleMessages() {
  const locales = import.meta.glob('./**/translations.json')

  const parseLocales = Object.keys(locales).map(key => {
    const newKey = key.split('/')[1]
    return { [newKey]: locales[key] }
  })

  const waitFor = (ms) => new Promise(r => setTimeout(r, ms))

  const parsedLocales = Object.assign({}, ...parseLocales)

  const messages = {}

  Object.keys(parsedLocales).map(async key => {
    messages[key] = await parsedLocales[key](key)
  })

  // Wait for all locales to be loaded
  await waitFor(1000)

  return messages
}

const i18n = async () => {
  const messages = await loadLocaleMessages()
  return createI18n({
    locale: import.meta.env.VITE_I18N_LOCALE || 'en',
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
    messages
  })
}

export default i18n

