<template>
  <button-dynamic
    :btnText="options.label"
    btnType="button"
    btnStyle="secondary"
    :btnDataTest="options.dataTest"
    :isDisabled="isDisabled"
    @click="onClick"
  />
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'scroll-table-button-field',
  emits: ['onButtonClicked'],
  props: {
    value: {
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    columnOptions: {
      type: Object,
      required: false
    }
  },
  computed: {
    isDisabled() {
      const value = this.value.disabled
      return Boolean(value)
    }
  },
  methods: {
    onClick() {
      this.$emit('onButtonClicked', this.value)
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>
