type thisDataType = {
    thisData: {
      $ss: {
        getFieldSessionStorage: (reference: string, subReference?: string) => [],
        setFieldSessionStorage: (reference: string, data: []) => void
      },
      $router: {
        push: (data: []) => void
      }
    }
  }

const historyRoutesHelper = ({ thisData } : thisDataType) => {
  // TODO: This is kind of a mess needs cleanup
  // OR removed, disabled for now
  try {
    const storedRoutes = thisData.$ss.getFieldSessionStorage('routesHistory')
    storedRoutes.pop()
    thisData.$router.push(storedRoutes[storedRoutes.length - 1])
    thisData.$ss.setFieldSessionStorage('routesHistory', storedRoutes)
    return storedRoutes
  } catch (error) {
    throw Error(`historyRoutesHelper error --> ${error}`)
  }
}

export {
  historyRoutesHelper
}
