import { API_COMPLETED } from '@/constants'
import { User, UserRole, UserAlert } from '@/services/user/types'

export type UsersState = {
  usersList: User[],
  user: User
  status: string
  lockStatus: string
  resetPasswordStatus: string
  roles: UserRole[]
  userEmailAlerts: UserAlert[]
  userModuleError: any,
  error: any
}

const state: UsersState = {
  usersList: [],
  user: {} as User,
  status: API_COMPLETED,
  lockStatus: API_COMPLETED,
  resetPasswordStatus: API_COMPLETED,
  roles: [],
  userEmailAlerts: [],
  userModuleError: null,
  error: null
}

export default state
