import * as ss from '@/config/session-storage-help'

export const getDocumentType = (id?: number): string => {
  try {
    const documentTypes = ss.getFieldSessionStorage('documentTypes', '') || []
    const hasDocuments = documentTypes.length && !!id
    if (!hasDocuments) {
      throw new Error('Documents are not loaded in session')
    }

    const documentType = documentTypes.find(({ typeId }) => typeId === id)
    if (!documentType) {
      throw new Error('Document type not found')
    }
    return documentType.description
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error getting document type!', err)
    return ''
  }
}
