<template>
  <div class="mt-4">
    <hr class="border-tmrw-gray mx-4 my-10" />
    <p data-test="other-message" class="p-4 text-xl text-white leading-6 font-semibold">
      Please add the reason:
    </p>
    <div data-test="other-reason-options" class="flex w-full">
      <div class="text-xl text-white leading-6 bg-white mx-4 w-full p-10 rounded-lg flex flex-col">
        <span
          data-test="other-reason-text"
          class="text-sm font-semibold text-tmrw-blue"
        >Reason*</span
        >
        <input
          data-test="other-reason-option"
          class="
            mr-1
            p-4
            w-1/2
            border-solid border-b-2 border-tmrw-blue
            font-exo
            text-tmrw-blue
            focus:outline-none
          "
          @input="($attrs as any).onSyncData(($event.target as any).value)"
          name="other"
        />
        <span
          data-test="other-reason-error"
          v-if="!otherReason"
          class="text-sm font-semibold mt-1.5 text-red-500"
        >
          The field can’t be empty
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from 'vue'

export default {
  setup(props) {
    const { otherReason } = toRefs(props)
    return otherReason
  },
  name: 'other',
  props: {
    otherReason: {
      type: [String],
      required: true
    }
  }
}
</script>
