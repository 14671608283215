import { getAllSites, getAllContainers } from '@/services/sites'
import * as ss from '@/config/session-storage-help'

export default {
  async getAllSites({ commit, rootState }) {
    const {
      appBaseConfig: { clinicId },
      token
    } = rootState.authModule
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await getAllSites({ clinicId, token })
      const sites = response.data.map((site) => ({
        value: site.id,
        label: site.name,
        unitIds: site.unitIds,
        biorepoConfig: site.biorepoConfig ?? null,
        isBiorepo: site.biorepoConfig?.destinationSiteId === site.id
      }))
      commit('setAllSites', sites)
    } catch (error) {
      throw error
    }
  },
  async getAllContainers({ commit, rootState }, params) {
    const {
      appBaseConfig: { clinicId, siteId },
      token
    } = rootState.authModule
    // eslint-disable-next-line no-useless-catch
    try {
      const containersBySite = await getAllContainers({ clinicId, siteId, token, ...params })
      ss.setFieldSessionStorage('containersBySite', containersBySite.data)
      commit('setAllContainersBySite', containersBySite.data)
      // eslint-disable-next-line no-param-reassign
      params = { ...params, bySite: false }
      const allContainers = await getAllContainers({ clinicId, siteId, token, ...params })
      ss.setFieldSessionStorage('allContainers', allContainers.data)
      commit('setAllContainers', allContainers.data)
    } catch (error) {
      throw error
    }
  }
}
