<template>
  <div class="text-tmrw-gray">
    <span v-if="!cryobeaconsAmount"> {{ creationMessage }} </span>

    <span v-else>
      You have successfully created {{ cryobeaconsAmount }} {{ MOVE_LOCATION }} Tickets.
      <!-- <a
        @click="handleClickBack(STEP_SELECT_BEACONS)"
        class="font-exo underline text-tmrw-green cursor-pointer"
      >
        {{ !hasMultipleBeacons ? CRYOBEACON : CRYOBEACONS }}
      </a> -->
    </span>

    <span v-if="areSimilarOriginDestination">
      within
      <a
        @click="handleClickBack(STEP_SELECT_LOCATION)"
        class="font-exo underline text-tmrw-green cursor-pointer"
      >
        {{ sourceLocation.name }}
      </a>
    </span>

    <span v-if="sourceLocation && !areSimilarOriginDestination">
      from
      <a
        @click="handleClickBack(STEP_SELECT_LOCATION)"
        class="font-exo underline text-tmrw-green cursor-pointer"
      >
        {{ sourceLocation.name }}
      </a>
    </span>

    <span v-if="destinationLocation && !areSimilarOriginDestination">
      to
      <a
        @click="handleClickBack(STEP_SELECT_LOCATION)"
        class="font-exo underline text-tmrw-green cursor-pointer"
      >
        {{ destinationLocation.name }}
      </a>
    </span>

    <span v-if="selectedPatient">
      for
      <a
        @click="handleClickBack(STEP_SELECT_PATIENT)"
        class="font-exo underline text-tmrw-green cursor-pointer"
      >
        {{ selectedPatient.fullName }}
      </a>
    </span>
  </div>
</template>

<script setup>
import { MOVE_LOCATION, TICKET_RELOCATION } from '@/constants'
import { STEP_SELECT_LOCATION, STEP_SELECT_PATIENT } from '@/constants/moveLocationTicketSteps'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  cryobeaconsAmount: {
    type: Number,
    required: false,
    default: null
  },
  sourceLocation: {
    type: Object,
    required: false,
    default: null
  },
  destinationLocation: {
    type: Object,
    required: false,
    default: null
  },
  selectedPatient: {
    type: Object,
    required: false,
    default: null
  },
  ticketMode: {
    type: String,
    required: false,
    default: TICKET_RELOCATION
  }
})

const router = useRouter()

// const hasMultipleBeacons = computed(() => {
//   return shouldHaveMultipleBeacons(props.cryobeaconsAmount)
// })

const creationMessage = computed(() => {
  return props.ticketMode === TICKET_RELOCATION
    ? 'Create a Relocation'
    : 'Create a New CryoShipper Ticket'
})

const areSimilarOriginDestination = computed(() => {
  return (
    props.sourceLocation &&
    props.destinationLocation &&
    props.sourceLocation.name === props.destinationLocation.name
  )
})

const handleClickBack = (step) => {
  router.push({ name: step })
}
</script>
