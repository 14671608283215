<template>
  <div>
    <button
      class="bg-tmrw-gray bg-opacity-50 text-tmrw-blue font-exo font-bold text-base px-4 py-2 rounded-lg mt-4 mr-3"
      data-test="introduction-pills-include"
      v-for="pill in introductionPills"
      :key="pill.id"
    >
      {{ pill.label }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const introductionPills = ref([
  {
    id: 'first',
    label: '1. Add search criteria by selecting the Add button.'
  },
  {
    id: 'second',
    label: '2. Start a search with the chosen search criteria by selecting the Search button.'
  }
])
</script>
