<template>
  <span class="flex flex-row-reverse">
    <img :src="userRoleIcon" class="w-6 ml-2" :class="{ 'filter-white-2': item.selected }" />
    <p class="font-exo text-base font-normal" :class="userRoleTextColor">
      {{ userRole }}
    </p>
  </span>
</template>

<script>
import { ADMIN_ROLE, EMBRYOLOGIST_ROLE, ARCHIVED_ROLE } from '@/constants/'
import AdminIcon from '@/assets/images/person-shield-light-blue.svg'
import ArchiveIcon from '@/assets/images/archive-box-light-blue.svg'
import EmbryologistIcon from '@/assets/images/embryologist-user.svg'

export default {
  name: 'ScrollTableUserRole',
  data() {
    return {
      AdminIcon,
      ArchiveIcon,
      EmbryologistIcon
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    userRole() {
      if (this.isUserArchived()) {
        return ARCHIVED_ROLE.toUpperCase()
      } else if (this.isUserAdmin()) {
        return ADMIN_ROLE.toUpperCase()
      } else {
        return EMBRYOLOGIST_ROLE.toUpperCase()
      }
    },
    userRoleTextColor() {
      return this.item.selected ? 'text-tmrw-white' : 'text-tmrw-blue-light'
    },
    userRoleIcon() {
      if (this.isUserArchived()) {
        return ArchiveIcon
      } else if (this.isUserAdmin()) {
        return AdminIcon
      } else {
        return EmbryologistIcon
      }
    }
  },
  methods: {
    isUserAdmin() {
      const { admin: isAdmin } = this.item
      return isAdmin
    },
    isUserArchived() {
      return this.item.activityStatus === 'Archived'
    },
    isUserLocked() {
      return this.item.activityStatus === 'Locked'
    }
  }
}
</script>
<style></style>
