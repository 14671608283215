<template>
  <label :data-test="`toggle__${toggleValues.code}`" class="flex cursor-pointer">
    <div
      class="rounded-2xl flex items-center relative flex-shrink-0 "
      :class="[
        height,
        width,
        disabled ? 'cursor-auto' : 'cursor-pointer',
        {
          'shadow-md': shadow,
          'opacity-50': disabled,
          'bg-gray-300': !isActive,
          'bg-tmrw-blue': isActive
        },
      ]"
    >
      <input
        class="outline-none m-0 p-0 w-0 h-0 opacity-0"
        type="checkbox"
        v-model="checkedValue"
        :disabled="disabled"
      />
      <div
        class="absolute w-5 h-5 rounded-xl transform transition-all duration-300"
        :class="[
          margin,
          isActive ? activeThumbColor : inactiveThumbColor,
          borderColor,
          {
            'translate-x-7': isActive,
          },
        ]"
      />
    </div>
    <p class="font-semibold text-2xl mx-4">{{toggleValues.label}}</p>
  </label>
</template>

<script>
export default {
  name: 'OnOffToggle',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onColor: {
      type: String,
      default: 'bg-black'
    },
    offColor: {
      type: String,
      default: 'bg-black'
    },
    activeThumbColor: {
      type: String,
      default: 'bg-tmrw-green'
    },
    inactiveThumbColor: {
      type: String,
      default: 'bg-black'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    width: {
      type: String,
      default: 'w-16'
    },
    height: {
      type: String,
      default: 'h-8'
    },
    margin: {
      type: String,
      default: 'm-2'
    },
    shadow: {
      type: Boolean,
      default: true
    },
    toggleValues: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentState: this.value
    }
  },
  emits: ['changeValue'],
  computed: {
    isActive() {
      return this.currentState
    },
    checkedValue: {
      get() {
        return this.currentState
      },
      set(newValue) {
        this.currentState = newValue
        this.$emit('changeValue', newValue)
      }
    }
  }
}
</script>
