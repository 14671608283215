<template>
  <section class="grid grid-cols-2 mx-10 col-span-6" data-test="cryobeacon-status-placeholder">
    <h3 class="col-span-2 justify-self-start text-xs font-semibold text-tmrw-blue mb-4">
      CRYOBEACON STATUS
    </h3>

    <div
      data-test="robot-source"
      class="flex flex-col"
      v-for="(robot, index) in getCircles"
      :key="robot.robotId"
    >
      <div class="flex mb-3">
        <div class="flex" :class="getCircleClass(index)"></div>
        <hr
          v-if="index === 0"
          class="mt-4 w-full h-0.5 border-tmrw-gray border-dashed"
          :style="{
            width: 'calc(100% - 2rem)'
          }"
        />
      </div>
      <section>
        <div
          class="font-bold text-xs text-tmrw-blue"
          :class="[index === 1 ? 'opacity-50' : '']"
          data-test="ticket-detail__procedure-start"
        >
          --
        </div>
        <div class="flex mt-1.5">
          <div
            class="flex flex-col mr-8 border-solid border-r-2 border-tmrw-gray pr-8"
            :class="[index === 1 ? 'opacity-50' : '']"
          >
            <span class="font-semibold text-tmrw-blue-dark text-xl">--</span>
            <span
              class="text-xs text-tmrw-blue-dark font-bold flex-1"
              data-test="ticket-detail__cryorobot"
              >--</span
            >
            <span class="text-xs text-tmrw-blue-dark" data-test="ticket-detail-placerholder-robots">
              (--)
            </span>
          </div>
          <div class="flex flex-col justify-end">
            <span
              data-test="ticket-detail__date"
              class="text-xs font-inconsolata mt-3"
              :class="[index === 1 ? 'opacity-50' : '']"
              ><i
                data-test="ticket-detail__calendar-icon"
                class="fas fa-calendar-alt mr-3"
              />--</span
            >

            <span
              data-test="ticket-detail__time"
              class="text-xs font-inconsolata"
              :class="[index === 1 ? 'opacity-50' : '']"
              ><i data-test="ticket-detail__clock-icon" class="far fa-clock mr-3" />--</span>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script setup>
import { computed } from 'vue'

const getCircles = computed(() => {
  return Array.from({ length: 2 }, (_, i) => i + 1)
})

const getCircleClass = (index) => {
  let baseCircleCss = 'justify-center items-center border-1 min-w-8 w-8 h-8 rounded-full relative'
  if (index === 1) return `${baseCircleCss} border-tmrw-gray border-dashed`

  return `${baseCircleCss} border-tmrw-blue border-solid`
}
</script>
