<template>
  <MainContainer gridSlots="2">
    <TopHeader>Admin</TopHeader>
    <div class="flex items-center justify-center">
      <button
        class="flex flex-col items-center justify-center h-48 w-96 p-10 font-exo font-medium bg-white rounded-2xl shadow-lg m-5"
        v-for="button in buttons"
        :key="button.text"
        @click="button.onClick"
        :data-test="button.dataTest"
      >
        <i :class="button.icon" class="flex-1" />
        <p class="text-4xl font-exo font-semibold">{{ button.text }}</p>
      </button>
    </div>
  </MainContainer>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { CRYOLABEL_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'
import { CRYOLABEL, CRYOROBOTS } from '@/constants'

const buttons = computed(() => [
  {
    icon: 'fas fa-tags text-4xl',
    text: `${CRYOLABEL} Format`,
    dataTest: 'cryolabel-format-btn',
    onClick: () => onClickCryoLabelFormat()
  },
  {
    icon: 'fas fa-list-alt text-4xl',
    text: CRYOROBOTS,
    dataTest: 'cryorobots-btn',
    onClick: () => onClickCryoRobots()
  }
])
const router = useRouter()

const onClickCryoLabelFormat = () => {
  router.push('/admin/cryolabels')
}

const onClickCryoRobots = () => {
  router.push('/admin/cryorobot')
}

onMounted(() => {
  if (!isFeatureEnabled(CRYOLABEL_ENABLED_FF)) {
    router.push('/flight-board')
  }
})
</script>
