import { NOTES } from '@/constants'

export default function addLabelToHeader(specimen, label) {
  const specimenWithLabel = []
  specimen.forEach((item) => {
    if (item.name === NOTES) {
      specimenWithLabel.push(label, item)
    } else {
      specimenWithLabel.push(item)
    }
  })
  return specimenWithLabel
}
