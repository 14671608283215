import dayjs from 'dayjs'

export const MODULAR_LABEL_EMBRYO = 'EMBRYO'
export const MODULAR_LABEL_EGG = 'EGG'
export const MODULAR_LABEL_SITE_PROPERTY_EMBRYO = 'site.cryolabel.embryo'
export const MODULAR_LABEL_SITE_PROPERTY_EGG = 'site.cryolabel.egg'
export const MODULAR_LABEL_TEXT_PREVIEW = 'PREVIEW'
export const MODULAR_LABEL_TEXT_OPTIONAL = 'OPTIONAL'
export const MODULAR_LABEL_TEXT_SPECIMEN_TYPE_TITLE = { [MODULAR_LABEL_EMBRYO]: 'Embryo', [MODULAR_LABEL_EGG]: 'Oocyte' }
export const MODULAR_LABEL_SUCCESS = 'Cryolabels updated successfully!'
export const MODULAR_LABEL_BLANK_SPACE = 'blankSpace'
export const MODULAR_LABEL_X_ELEMENT = 'xElement'
export const MODULAR_LABEL_PATIENT_FIRST_NAME = 'firstName'
export const MODULAR_LABEL_PATIENT_LAST_NAME = 'lastName'
export const MODULAR_LABEL_PATIENT_DOB = 'patientDob'
export const MODULAR_LABEL_PATIENT_ID = 'identificationNumber'
export const MODULAR_LABEL_CURRENT_LABEL_ID = 'currentLabelId'
export const MODULAR_LABEL_CRYODATE = 'procedureDateFrom'
export const MODULAR_LABEL_FREE_TEXT = 'cryolabelFreeText'
export const MODULAR_LABEL_COUNT = 'specimenCount'
export const MODULAR_LABEL_EMBRYO_TYPE = 'embryoType'
export const MODULAR_LABEL_EMBRYO_TYPES = 'embryoTypes'
export const MODULAR_LABEL_EMBRYO_NUMBER = 'embryoNumber'
export const MODULAR_LABEL_EMBRYO_GRADE = 'grade'
export const MODULAR_LABEL_BIOPSY = 'biopsy'
export const MODULAR_LABEL_BIOPSY_RESULT = 'biopsyResult'
export const MODULAR_LABEL_MATURITY = 'maturityLevel'
export const RIGHT_X = 'right'
export const LEFT_X = 'left'
export const MODULAR_LABEL_FIELDS_TEXT = {
  [MODULAR_LABEL_PATIENT_FIRST_NAME]: 'Patient First Name',
  [MODULAR_LABEL_PATIENT_LAST_NAME]: 'Patient Last Name',
  [MODULAR_LABEL_PATIENT_DOB]: 'Patient D.o.B',
  [MODULAR_LABEL_PATIENT_ID]: 'Patient ID',
  [MODULAR_LABEL_CRYODATE]: 'CryoDate',
  [MODULAR_LABEL_FREE_TEXT]: 'Free Text',
  [MODULAR_LABEL_COUNT]: 'Count',
  [MODULAR_LABEL_EMBRYO_TYPE]: 'Day of Development',
  [MODULAR_LABEL_EMBRYO_NUMBER]: 'Embryo #',
  [MODULAR_LABEL_BIOPSY]: 'Biopsy',
  [MODULAR_LABEL_MATURITY]: 'Maturity',
  [MODULAR_LABEL_EMBRYO_GRADE]: 'Grade'
}
export const MODULAR_LABEL_EMBRYO_IDENTIFIER = 'EMB'
export const MODULAR_LABEL_OOCYTE_IDENTIFIER = 'Ooc'

export const MODULAR_LABEL_PROPERTY_FIELD_SIZE = 'w'
export const MODULAR_LABEL_WIDTH_SIZE = 0.55  // In REM

const getSpaceElement = (x) => ({
  id: MODULAR_LABEL_BLANK_SPACE,
  h: 1,
  locked: true,
  maxH: 1,
  w: 1,
  x,
  y: 0,
  noResize: true,
  noMove: true,
  content: '<div class="space-holder"> </div>'
})

export const MODULAR_LABEL_TEXT_FIELDS_CONFIG = {
  [MODULAR_LABEL_PATIENT_FIRST_NAME]: {
    id: MODULAR_LABEL_PATIENT_FIRST_NAME, required: false, w: 1, minW: 1, maxW: undefined, type: null
  },
  [MODULAR_LABEL_PATIENT_LAST_NAME]: {
    id: MODULAR_LABEL_PATIENT_LAST_NAME, required: true, w: 1, minW: 5, maxW: undefined, type: null
  },
  [MODULAR_LABEL_PATIENT_DOB]: {
    id: MODULAR_LABEL_PATIENT_DOB, required: false, w: 9, minW: 9, maxW: 9, type: null
  },
  [MODULAR_LABEL_PATIENT_ID]: {
    id: MODULAR_LABEL_PATIENT_ID, required: true, w: 5, minW: 5, maxW: undefined, type: null
  },
  [MODULAR_LABEL_CRYODATE]: {
    id: MODULAR_LABEL_CRYODATE, required: true, w: 9, minW: 9, maxW: 9, type: null
  },
  [MODULAR_LABEL_FREE_TEXT]: {
    id: MODULAR_LABEL_FREE_TEXT, required: false, w: 1, minW: 1, maxW: undefined, type: null
  },
  [MODULAR_LABEL_COUNT]: {
    id: MODULAR_LABEL_COUNT, required: false, w: 2, minW: 2, maxW: 2, type: null
  },
  [MODULAR_LABEL_EMBRYO_TYPE]: {
    id: MODULAR_LABEL_EMBRYO_TYPE, required: false, w: 3, minW: 2, maxW: 3, type: MODULAR_LABEL_EMBRYO
  },
  [MODULAR_LABEL_EMBRYO_NUMBER]: {
    id: MODULAR_LABEL_EMBRYO_NUMBER, required: true, w: 2, minW: 2, maxW: 8, type: MODULAR_LABEL_EMBRYO
  },
  [MODULAR_LABEL_BIOPSY]: {
    id: MODULAR_LABEL_BIOPSY, required: false, w: 1, minW: 1, maxW: 1, type: MODULAR_LABEL_EMBRYO
  },
  [MODULAR_LABEL_MATURITY]: {
    id: MODULAR_LABEL_MATURITY, required: false, w: 3, minW: 3, maxW: 3, type: MODULAR_LABEL_EGG
  },
  [MODULAR_LABEL_EMBRYO_GRADE]: {
    id: MODULAR_LABEL_EMBRYO_GRADE, required: false, w: 3, minW: 3, maxW: 4, type: MODULAR_LABEL_EMBRYO
  }
}

export const defaultItemsEmbryoRowOne = [
  {
    x: 0,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_PATIENT_ID]
  },
  getSpaceElement(5),
  getSpaceElement(29),
  {
    x: 30,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_EMBRYO_NUMBER]
  }
]

export const defaultItemsEmbryoRowTwo = [
  {
    x: 0,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_PATIENT_LAST_NAME]
  },
  getSpaceElement(1),
  getSpaceElement(22),
  {
    x: 23,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_CRYODATE]
  }
]

export const defaultItemsOocyteRowOne = [
  {
    x: 0,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_PATIENT_ID]
  },
  getSpaceElement(5)
]

export const defaultItemsOocyteRowTwo = [
  {
    x: 0,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_PATIENT_LAST_NAME]
  },
  getSpaceElement(1),
  getSpaceElement(22),
  {
    x: 23,
    y: 0,
    ...MODULAR_LABEL_TEXT_FIELDS_CONFIG[MODULAR_LABEL_CRYODATE]
  }
]

export const defaultLabelItems = {
  [MODULAR_LABEL_EMBRYO]: [defaultItemsEmbryoRowOne, defaultItemsEmbryoRowTwo],
  [MODULAR_LABEL_EGG]: [defaultItemsOocyteRowOne, defaultItemsOocyteRowTwo]
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const numbers = '1234567890'
export const defaultFilledEmbryo = {
  beaconBarcode: '',
  embryo: {
    biopsy: 'No',
    embryoNumber: numbers,
    grade: '3AB'
  },
  cryodeviceBarcode: '00000000000',
  specimenType: 'Embryo',
  cryodeviceTypeId: 1,
  identificationNumber: numbers,
  firstName: alphabet,
  lastName: alphabet,
  procedureDateFrom: dayjs().format('DDMMMYYYY').toUpperCase(),
  embryoType: numbers,
  biopsy: 'No',
  specimenCount: numbers
}

export const defaultFilledEgg = {
  beaconBarcode: '',
  oocyte: {
    maturityLevel: 'MII'
  },
  cryodeviceBarcode: '00000000000',
  specimenType: 'Egg',
  cryodeviceTypeId: 1,
  identificationNumber: numbers,
  firstName: alphabet,
  lastName: alphabet,
  procedureDateFrom: dayjs().format('DDMMMYYYY').toUpperCase(),
  embryoType: numbers,
  biopsy: 'No',
  specimenCount: numbers
}
