import { MOVE_LOCATION } from '@/constants'

import {
  CRYOLABEL_ENABLED_FF
} from '@/helpers/featureFlags'

export default [
  {
    id: 'menu-item-flight-board',
    route: '/flight-board',
    label: 'Flight Board',
    icon: 'far fa-list-alt'
  },
  {
    id: 'menu-item-patients',
    route: '/patients-search',
    label: 'Patients',
    icon: 'far fa-user-circle'
  },
  {
    id: 'menu-biorepostory',
    route: '/biorepository/flight-board',
    label: MOVE_LOCATION,
    icon: 'fas fa-arrow-right'
  },
  {
    id: 'menu-item-printer-status',
    route: '/printers-status',
    label: 'Printer Status',
    icon: 'fas fa-print'
  },
  {
    id: 'admin-divider',
    label: 'Administrator',
    isDivider: true,
    onlyIfAdmin: true
  },
  {
    id: 'menu-item-admin',
    route: '/admin',
    label: 'Admin',
    icon: 'fas fa-cog',
    onlyIfAdmin: true,
    onlyIfSiteProperty: { [CRYOLABEL_ENABLED_FF]: 'true' }
  },
  {
    id: 'menu-item-users',
    route: '/users-list',
    label: 'Users',
    icon: 'fas fa-microscope',
    onlyIfAdmin: true
  }
]
