import { EMAIL_REPORT, EMAIL_AUTOMATED_ALERT, EMAIL_ALERT_LABELS } from '@/constants/email-alert-labels'
import { computed, ref } from 'vue'

export const useAlertsComputed = () => {
  const userEmailAlertsFields = ref(EMAIL_ALERT_LABELS)
  const reports = computed(() => {
    return userEmailAlertsFields.value.filter(({ isActive, type }) => isActive && type === EMAIL_REPORT)
  })
  const alerts = computed(() => {
    return userEmailAlertsFields.value.filter(({ isActive, type }) => isActive && type === EMAIL_AUTOMATED_ALERT)
  })

  return {
    userEmailAlertsFields,
    reports,
    alerts
  }
}
