<template>
  <div>
    <ModalWindow
      data-test="label-modal-scan"
      modal-title="Do you want to scan the labels?"
      title-type="h1"
      modalSize="lg"
      cols-distribution="8/4"
      is-semi-bold
      :isModalOpen="isModalOpen"
      :modal-class="['p-8']"
      @closeModalWindow="handleClose"
    >
      <h2
        data-test="modal-scan-count"
        class="border-none text-2xl"
      >
        {{ cryoDevices.length }} Cryodevice{{ cryoDevices.length === 1 ? ' has': 's have' }} been marked as Not Present or Discarded.
      </h2>
      <div data-test="modal-scan-patient" class="my-5 flex flex-row items-center">
        <h4 class="flex-grow text-2xl font-bold">{{  ticket?.patientName }}</h4>
        <div data-test="patient-id" class="text-left">
          <h6 class="text-sm">PATIENT ID</h6>
          <span class="font-inconsolata font-semibold">{{ patientId }}</span>
        </div>
        <div data-test="patient-dob" class="text-left ml-6">
          <h6 class="text-sm">DOB</h6>
          <span class="font-inconsolata font-semibold">{{ patientDOB }}</span>
        </div>
      </div>
      <div class="scroller">
        <div v-if="hasEmbryos">
          <h6 class="text-tmrw-blue text-base font-bold">{{ embryoCount }} Embryo{{ embryoCount === 1 ? '':'s' }}</h6>
          <ScanRow
            v-for="device in embryos"
            :key="device.cryodeviceBarcode"
            :cryo-device="device"
            @scan-label="onScan"
            :specimen-type="specimenTypes.embryo"
          />
        </div>
        <div v-if="hasOocytes">
          <h6 class="text-tmrw-blue text-base font-bold">{{ oocyteCount }} Oocyte{{ oocyteCount === 1 ? '':'s' }}</h6>
          <ScanRow
            v-for="device in oocytes"
            :key="device.cryodeviceBarcode"
            :cryo-device="device"
            @scan-label="onScan"
            :specimen-type="specimenTypes.oocyte"
          />
        </div>
      </div>
      <ButtonDynamic
        :btn-text="continueLabel"
        data-test="continue-button"
        btnType="button"
        add-margin-top
        btnStyle="secondary"
        :add-classes="['!text-3.5xl', '!py-3']"
        @click="handleContinue"
      />
    </ModalWindow>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import dayjs from 'dayjs'

import { SPECIMEN_TYPE_EMBRYO, SPECIMEN_TYPE_EGG } from '@/constants'
import { sortByCryodevicesByBarcode } from '@/utils'

import {
  postScanNotPresentOrDiscardedSpecimen
} from '@/services/specimens'
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import ButtonDynamic from '../ButtonDynamic/ButtonDynamic.vue'
import ScanRow from './ScanRow.vue'

const emit = defineEmits([
  'close',
  'continue'
])

const props = defineProps({
  cryoDevices: {
    type: Array<any>,
    required: true
  },
  ticket: {
    type: Object,
    required: true
  },
  isModalOpen: {
    type: Boolean,
    required: true
  },
  keepStateOnClose: {
    type: Boolean,
    required: false,
    default: false
  }
})

const specimenTypes = ref({
  oocyte: SPECIMEN_TYPE_EGG,
  embryo: SPECIMEN_TYPE_EMBRYO
})


const hasScanned = ref<Boolean>(false)
const scannedCryodevices = ref<Array<string>>([])
const continueLabel = computed(() => hasScanned.value ? 'Continue' : 'Continue without Scan')

const embryos = computed(() => props.cryoDevices.filter(({ specimenType }) => specimenType === SPECIMEN_TYPE_EMBRYO ).map((cryodevice) => ({
  ...cryodevice,
  cryodeviceBarcodeValidation: scannedCryodevices.value.includes(cryodevice.cryodeviceBarcode)
})).sort(sortByCryodevicesByBarcode))
const hasEmbryos = computed(() => embryos.value.length > 0)

const oocytes = computed(() => props.cryoDevices.filter(({ specimenType }) => specimenType === SPECIMEN_TYPE_EGG ).map((cryodevice) => ({
  ...cryodevice,
  cryodeviceBarcodeValidation: scannedCryodevices.value.includes(cryodevice.cryodeviceBarcode)
})).sort(sortByCryodevicesByBarcode))
const hasOocytes = computed(() => oocytes.value.length > 0)

const patientId = computed(() => props.ticket?.identificationNumber || '-')
const patientDOB = computed(() => props.ticket?.patientDob ? dayjs(props.ticket.patientDob).format('DDMMMYYYY').toUpperCase() : '-')

const embryoCount = computed(() => embryos.value.reduce(
    (accumulator, currentCryodevice) => accumulator + currentCryodevice.specimenCount,
    0
))
const oocyteCount = computed(() => oocytes.value.reduce(
    (accumulator, currentCryodevice) => accumulator + currentCryodevice.specimenCount,
    0
))
const handleContinue = () => {
  emit('continue')
}

const handleClose = () => {
  emit('close')
}


const onScan = async ({ specimenId, cryodeviceBarcode }) => {
  hasScanned.value = true
  try {
    scannedCryodevices.value.push(cryodeviceBarcode)
    await postScanNotPresentOrDiscardedSpecimen({ specimenId })
  } catch(e) {
    console.error('Scan error', e)
  }
}

watch(props, (newProps) => {
  if(!newProps.isModalOpen) {
    if(!newProps.keepStateOnClose) {
      hasScanned.value = false
      scannedCryodevices.value = []
    }
  }
})

</script>
<style lang="scss">

  .scroller {
    max-height: 50vh;
    overflow-y: auto;
  }
</style>
