<template>
  <div class="cryobeacon-detail__wrapper bg-white">
    <div
      class="ticket-table__header flex justify-between mt-4 py-2 px-4 rounded-lg"
      :class="[
        isSelected
          ? 'bg-tmrw-blue text-white'
          : 'bg-gradient-to-r from-tmrw-green-light to-transparent text-tmrw-blue-dark'
      ]"
    >
      <ActionBar :colsDistribution="canSelect ? '6/6' : '12/0'">
        <template v-slot:left-side>
          <div v-if="canSelect && !notSelectableBy" class="flex items-center mr-4">
            <Checkbox :modelValue="isSelected" @update:modelValue="handleSelect" />
          </div>

          <div data-test="ticket-list-beaconID" class="ticket-table__beacon-id flex flex-col pr-8">
            <p data-test="cryobeacon-id-title" class="text-xs font-semibold uppercase">
              {{ cryoBeaconLabel }} ID
            </p>
            <span
              data-test="cryobeacon-id"
              class="mt-1 font-bold text-xl font-inconsolata leading-7"
            >
              {{ showIfValidCryoBeaconId(beaconId) }}
            </span>
          </div>

          <div
            v-if="dateOfBirth"
            data-test="ticket-dob"
            class="ticket-table__dob flex flex-col pr-8"
          >
            <p data-test="cryobeacon-id-title" class="text-xs font-semibold uppercase">
              {{ dateOfBirthLabel }}
            </p>
            <span
              data-test="cryobeacon-id"
              class="mt-1 font-bold text-xl font-inconsolata leading-7"
            >
              {{ dateOfBirth }}
            </span>
          </div>

          <div
            data-test="ticket-list-screening-status"
            class="ticket-table__beacon-id flex flex-col ml-4"
          >
            <p class="text-xs font-semibold uppercase">{{ infectiousScreeningStatusLabel }}</p>
            <ScreeningStatusLabel
              class="scroll-table-screening-status text-lg"
              align="left"
              icon
              :id="infectiousScreeningStatus"
              iconColor="tmrw-blue-dark"
              label
              paddingSmall
              textColor="tmrw-blue-dark font-semibold"
            />
          </div>
          <div
            data-test="relocation-current-location"
            class="ticket-table__beacon-id flex flex-col ml-4"
          >
            <p data-test="current-location-title" class="text-xs font-semibold uppercase">
              {{ currentLocationLabel }}
            </p>
            <p
              data-test="curent-location-data"
              class="font-bold text-xl font-inconsolata leading-7 mt-1"
            >
              {{ currentLocation }}
              <span v-if="locationDetail" class="font-normal">{{ locationDetail }}</span>
            </p>
          </div>
        </template>

        <template v-slot:right-side v-if="canSelect">
          <span
            v-if="notSelectableBy"
            class="mt-1 text-lg text-tmrw-yellow"
            data-test="cryobeacon-detail-no-select-reason"
          >
            {{ notSelectableBy }}
          </span>
          <button-dynamic
            v-if="canRemove"
            btnDataTest="ticket-list__cancel-button"
            btnText="Remove"
            btnType="button"
            btnStyle="secondary"
            class="ticket-list__cancel-button"
          />
        </template>
      </ActionBar>
    </div>
    <div :class="{ 'opacity-50': notSelectableBy }">
      <InventorySpecimenTables
        data-tests="ticket-table__editable__list"
        class="w-full"
        :eggSpecimenHeaders="getEggHeaders"
        :eggSpecimens="eggSpecimens"
        :embryoSpecimenHeaders="getEmbryoHeaders"
        :embryoSpecimens="embryoSpecimens"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import Checkbox from '@/components/Checkbox/Checkbox.vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'

import {
  CRYOBEACON,
  SPECIMEN_TYPE_EMBRYO,
  INFECTIOUS_SCREENING_STATUS,
  CURRENT_LOCATION,
  MAX_LABEL_LENGTH,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  DATE_OF_BIRTH
} from '@/constants'
import { Specimen } from '@/types'
import { timestampToUTCFilter, specimenCountFilter } from '@/filters'
import { computed, ref } from 'vue'
import { useTempCryoBeacon } from '@/composables/useTempCryoBeacon'

interface CryoBeaconDetail {
  isSelected?: boolean
  beaconId: string
  specimens?: Specimen[]
  $emit?: Function
}

interface TicketDetailModel {
  sort: any
  options: any
}

const emit = defineEmits(['selected'])
const props = defineProps({
  beaconId: {
    type: String,
    required: true,
    default: ''
  },
  canRemove: {
    type: Boolean,
    default: false
  },
  notSelectableBy: {
    type: String,
    default: ''
  },
  infectiousScreeningStatus: {
    type: Number,
    required: true,
    default: 0
  },
  specimens: {
    type: Array,
    required: true,
    default: () => []
  },
  currentLocation: {
    type: String,
    required: true,
    default: ''
  },
  canSelect: {
    type: Boolean,
    default: true
  },
  dateOfBirth: {
    type: String,
    default: ''
  },
  locationDetail: {
    type: String,
    default: ''
  }
})

const { showIfValidCryoBeaconId } = useTempCryoBeacon()

const cryoBeaconLabel = ref(CRYOBEACON)
const infectiousScreeningStatusLabel = ref(INFECTIOUS_SCREENING_STATUS)
const currentLocationLabel = ref(CURRENT_LOCATION)
const dateOfBirthLabel = ref(DATE_OF_BIRTH)
const isSelected = ref(false)
const headers = ref([
  {
    key: 'specimenId',
    name: '',
    unique: true,
    visible: false
  },
  {
    key: 'cryodeviceBarcode',
    name: 'Cryodevice ID',
    componentOptions: {
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    cssClasses: ['w-32']
  },
  {
    name: 'Additional Patient',
    component: 'AdditionalPatientsTooltip',
    cssClasses: [''],
    componentOptions: {
      patientsTooltip: true
    }
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-32'],
    filters: specimenCountFilter
  },
  {
    name: 'Embryo #',
    key: 'embryo',
    filters: [(value) => (value ? value.embryoNumber : '')],
    cssClasses: ['w-36']
  },
  {
    name: 'Grade',
    key: 'embryo',
    filters: [(value) => (value ? value.grade : '')],
    sort: true,
    cssClasses: ['w-32']
  },
  {
    key: 'cryoDate',
    name: 'Cryodate',
    filters: timestampToUTCFilter
  },
  {
    name: 'Biopsy',
    key: 'embryo',
    filters: [(value) => (value ? value.biopsy : '')],
    sort: true,
    cssClasses: ['w-32']
  },
  {
    name: 'Results',
    key: 'embryo.biopsyResult',
    sort: true,
    cssClasses: ['w-32'],
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  }
])

const ticketDetailModel = computed<TicketDetailModel>(() => {
  return {
    sort: {
      orderBy: 'cryodeviceBarcode',
      direction: 'asc'
    },
    options: []
  }
})

const embryoSpecimens = computed(() => {
  const _embryoSpecimens = (props as CryoBeaconDetail).specimens?.filter(
    (item: Specimen) => item.specimenType === SPECIMEN_TYPE_EMBRYO
  )
  const embryosModel = {
    ...ticketDetailModel.value,
    options: _embryoSpecimens
  }
  return embryosModel
})
const eggSpecimens = computed(() => {
  const _eggSpecimens = (props as CryoBeaconDetail).specimens?.filter(
    (item: Specimen) =>
      item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
  )
  const eggsModel = {
    ...ticketDetailModel.value,
    options: _eggSpecimens
  }
  return eggsModel
})
const getEggHeaders = computed(() => {
  return headers.value.filter(
    (header) => header.key !== 'embryo' && header.key !== 'embryo.biopsyResult'
  )
})
const getEmbryoHeaders = computed(() => {
  return headers.value
})

const handleSelect = () => {
  isSelected.value = !isSelected.value
  emit('selected', props.beaconId)
}
</script>
