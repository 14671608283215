<template>
  <MainContainer gridSlots="2">
    <TopHeader>Default {{ CRYOROBOT }}</TopHeader>
    <div class="mt-10">
      <dynamic-title addMarginBottom titleType="h2" floatTo="left"
        >Select the Default {{ CRYOROBOT }}</dynamic-title
      >

      <button-dynamic
        btnDataTest="save-default-cryorobot"
        btnText="Save"
        btnType="button"
        showIcon
        fontAwesomeIconClass="check-circle-regular"
        addMarginRight
        btnStyle="primary"
        :isDisabled="disabledSaveButton"
        @click="saveDefaultCryoRobot"
      />
      <button-dynamic
        btnText="Cancel"
        btnType="button"
        btnStyle="secondary"
        data-test="cancel-default-cryorobot"
        addMarginRight
        @click="cancelDefaultCryoRobot"
      />
      <div class="flex w-full bg-white rounded-md py-5 px-10 mt-5">
        <CustomSelect
          class="w-72"
          data-testid="default-cryorobot__destination"
          dataTest="default-cryorobot__destination"
          :options="destinationOptions"
          isDefaultOptionDisabled="true"
          hasLargePadding
          @update:modelValue="onSelectDestination"
          :modelValue="defaultValue"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

import { CRYOROBOT } from '@/constants'
import { useGetDestination } from '@/composables'

const router = useRouter()
const store = useStore()
const { destinationOptions, onSelectDestination, selectedDestination } = useGetDestination()

const disabledSaveButton = computed(() => {
  return Number(selectedDestination.value) === defaultValue.value
})

const defaultValue = computed(() => {
  const defaultCryorobot = destinationOptions.value.find((cryorobot) => cryorobot.defaultRobot)
  return defaultCryorobot?.value || ''
})

const cancelDefaultCryoRobot = () => {
  router.go(-1)
}

const saveDefaultCryoRobot = async () => {
  await store.dispatch('appInfoModule/setDefaultCryorobot', selectedDestination.value)
  await store.dispatch('siteModule/getAllContainers', { includeNonTmrw: true }, { root: true })
  router.go(-1)
}
</script>
