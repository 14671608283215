import {
  UNASSIGNED
} from '@/constants/index'

export const useTempCryoBeacon = () => {
  const showIfValidCryoBeaconId = (cryoBeaconId) => {
    // eslint-disable-next-line no-nested-ternary
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(cryoBeaconId) ? UNASSIGNED : cryoBeaconId === '--' ? UNASSIGNED : cryoBeaconId
  }
  const isUUID = (cryoBeaconId)  =>{
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(cryoBeaconId)
  }

  return { showIfValidCryoBeaconId, isUUID }
}
