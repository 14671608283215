<template>
  <section>
    <MainContainer data-test="how-many" gridSlots="3">
      <top-header @backStep="handleClickBack">
        <HeaderInfo />
      </top-header>
      <ActionBar data-test="how-many__action-bar" colsDistribution="6/6">
        <template v-slot:left-side>
          <dynamic-title titleType="h2" floatTo="left">How many cryodevices are needed?</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnDataTest="how-many__cryos-next-button"
            btnType="button"
            btnText="Next"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="arrow-circle-right"
            :isDisabled="isNextButtonDisabled"
            @click="handleSelectCryoDevice"
          />
        </template>
      </ActionBar>
      <loading-ui modal v-if="isLoaderVisible" message="Validating Patient Inventory" />
      <section v-else>
        <BreadCrumbs :items="getActiveBreadcrumbs" />
        <div
          class="flex bg-white rounded-tr-md rounded-tl-md p-4"
          :class="{ 'rounded-br-md rounded-bl-md': !areSpecimensSet }"
        >
          <CryosPerBeaconForm
            @onUpdate="updateDistribution"
            :specimenType="selectedSpecimenType"
            ref="cryoDevicesPerBeaconForm"
          />
          <div v-if="areSpecimensSet" class="w-1/3">
            <label
              data-test="which-will-total-label"
              class="leading-tight text-base block text-left h-5 font-exo text-tmrw-blue"
            >
              Which will total
            </label>
            <p
              data-test="which-will-total-value"
              class="border-b border-dotted p-2 border-tmrw-blue font-semibold"
            >
              {{ totalCryodevicesTitle }}
            </p>
          </div>
        </div>
        <div class="flex flex-wrap bg-white rounded-br-md rounded-bl-md">
          <div v-for="(beacon, index) in cryoDevicesPerBeacon" :key="index" class="w-1/3">
            <BeaconBox
              :items="beacon"
              :beaconCount="index"
              v-bind:customSpecimensDistribution="customSpecimensDistribution"
              :isResetVisible="isResetVisible"
              @resetCount="resetCount"
              @applyCustomState="applyCustomState"
              @addNewbeacon="addNewbeacon"
              @updateBeacons="updateBeacons"
              @setCustom="setCustom"
              @deleteBeacon="deleteBeacon"
              @updateDistribution="updateDistribution"
            />
          </div>
        </div>
        <ActionBar
          v-if="areSpecimensSet"
          data-test="how-many__general-information-action-bar"
          colsDistribution="6/6"
        >
          <template v-slot:left-side>
            <dynamic-title addMarginTop addMarginBottom titleType="h2" floatTo="left">
              General Information
            </dynamic-title>
          </template>
          <template v-slot:right-side>
            <p class="w-full text-right text-tmrw-green-light my-4">*Required field</p>
          </template>
        </ActionBar>
        <div v-if="areSpecimensSet" class="flex w-full bg-white rounded-md p-2">
          <div class="h-18 p-2" :class="[isImportMethod ? 'w-3/12' : 'w-4/12']">
            <ScreeningStatusSelect v-model="screeningStatusCode" hasLargePadding />
            <error-box
              v-if="errors.screeningStatusCode"
              :errorMessage="errors.screeningStatusCode"
              fontAwesomeIconClass="exclamation-triangle"
              showIcon
              :errorBoxStyleClasses="['warning']"
            />
          </div>
          <div class="h-18 p-2" :class="[isImportMethod ? 'w-3/12' : 'w-4/12']">
            <CustomSelect
              data-testid="how-many__cryodevice-type"
              dataTest="how-many__cryodevice-type"
              class="w-full"
              :options="cryoDevicesTypesList"
              defaultOptionLabel="What type of cryodevice?*"
              hasLargePadding
              @update:modelValue="onCryodeviceSelectChange"
            />
            <error-box
              v-if="errors.selectedCryoDevice"
              :errorMessage="errors.selectedCryoDevice"
              showIcon
              fontAwesomeIconClass="exclamation-triangle"
              :errorBoxStyleClasses="['warning']"
            />
          </div>
          <div class="w-3/12 h-18 p-2" v-if="isImportMethod">
            <input-field
              v-model="donorId"
              data-test="how-many__total-cryo-count"
              inputType="text"
              inputName="totalCryoCount"
              inputPlaceholder="Donor ID"
              borderColor="tmrw-blue"
              placeholderColor="black"
              labelColor="tmrw-blue"
              hasLargePadding
              addPaddingBottom
              addPaddingTop
            />
          </div>
          <div class="w-3/12 h-18 p-2 flex flex-col justify-end" v-if="isImportMethod">
            <button
              data-test="how-many__add-shipping-source-button"
              type="button"
              class="focus:outline-none leading-4 bg-transparent border border-tmrw-blue-dark text-lg flex justify-center cursor-pointer w-full border-t-0 border-l-0 border-r-0"
              @click="openClinicSelector"
            >
              <span
                data-test="how-many__btn-dynamic-text"
                class="w-full h-6 flex justify-between font-exo font-semibold"
              >
                {{ shippingSourceData ? shippingSourceData.name : 'Shipping Source*' }}
                <i data-test="how-many__btn-dynamic-icon" class="is-icon-right mr-3 fa fa-plus" />
              </span>
            </button>
            <error-box
              class="mt-1"
              v-if="errors.shippingSourceData"
              :errorMessage="errors.shippingSourceData"
              showIcon
              fontAwesomeIconClass="exclamation-triangle"
              :errorBoxStyleClasses="['warning']"
            />
          </div>
        </div>
      </section>
      <ExternalClinicsSelector
        v-if="isClinicSelectorOpen"
        @selectedShippingSource="updateShippingSource"
        @closeClinicSelector="closeClinicSelector"
      />
    </MainContainer>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import InputField from '@/components/InputField/InputField.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import CryosPerBeaconForm from '@/components/CryosPerBeaconForm/CryosPerBeaconForm.vue'
import BeaconBox from '@/components/BeaconBox/BeaconBox.vue'
import ScreeningStatusSelect from '@/components/ScreeningStatusSelect/ScreeningStatusSelect.vue'
import cryodeviceIdGenerator from '@/helpers/cryodeviceIdGenerator'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import ErrorBox from '@/components/ErrorBox/ErrorBox.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import {
  MATURITY_LEVEL_METAPHASE_II,
  METHOD_IMPORT,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EMBRYO
} from '@/constants'
import ExternalClinicsSelector from '@/components/ExternalClinicsSelector/ExternalClinicsSelector.vue'
import { useTicket } from '@/composables'
import { calculateMaxSpecimen, getBreadcrumbs } from '@/helpers/newTicketHelpers'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'

// Move these to a const
const CRYODEVICE_TYPE_ERROR_MESSAGE = 'Please select a Cryodevice Type'
const SCREENING_STATUS_ERROR_MESSAGE = 'Please select a Screening Status'
const SHIPPING_SOURCE_ERROR_MESSAGE = 'Please select a Shipping Source'

export default {
  name: 'how-many',
  data() {
    return {
      customSpecimensDistribution: false,
      cryoDevicesPerBeacon: [],
      cryoDevicesTypesList: [],
      cryoPerPatientInitialCounter: 0,
      donorId: '',
      errors: {
        selectedCryoDevice: false,
        screeningStatusCode: false,
        shippingSourceData: false,
        destination: false
      },
      isClinicSelectorOpen: false,
      isLoaderVisible: true,
      isResetVisible: false,
      screeningStatusCode: '',
      selectedCryoDevice: '',
      shippingSourceData: null,
      totalSpecimensCount: 0,
      loading: false
    }
  },
  setup() {
    const {
      ticket, beacons, updateTicketBeacons, saveTicket
    } = useTicket()

    return {
      ticket,
      beacons,
      updateTicketBeacons,
      saveTicket
    }
  },
  async created() {
    let { globalPatientNumber } = this.selectedPatient[0]
    if (this.isMultiplePatientsEnabled) {
      globalPatientNumber = this.primaryPatientId
    }


    await this.fetchSpecimensFromPatient({
      id: globalPatientNumber,
      onlyInRobot: false,
      includeClosed: true,
      onlyInClinic: true
    })

    await this.fetchCryoDevicesTypes()
    const mappedCryoDeviceTypes = this.cryoDevicesTypes.map((cryoDeviceType) => ({
      value: cryoDeviceType.id,
      label: cryoDeviceType.value
    }))

    this.cryoDevicesTypesList = mappedCryoDeviceTypes

    const specimensOwnedByPatient = this.specimensFromPatient.filter(
      (specimen) => Number(specimen.cprId) === globalPatientNumber
    )
    this.cryoPerPatientInitialCounter = calculateMaxSpecimen(specimensOwnedByPatient, globalPatientNumber)
    this.updateBeacons()

    this.isLoaderVisible = false
  },
  methods: {
    ...mapActions('specimensModule', ['fetchCryoDevicesTypes']),
    ...mapActions('patientsModule', ['fetchSpecimensFromPatient', 'setShippingSource']),
    ...mapMutations('ticketModule', [
      'setBeacons',
      'setSelectedCryoDeviceType',
      'setCryoDeviceSourceTypeId',
      'setCryoDeviceSourceDonorId',
      'setCryoDeviceShippingSource',
      'setCryoDeviceSourceShippingSourceId'
    ]),
    openClinicSelector() {
      this.isClinicSelectorOpen = true
    },
    closeClinicSelector() {
      this.isClinicSelectorOpen = false
    },
    applyCustomState() {
      const flattenSpecimenCountPerCryodevice = this.cryoDevicesPerBeacon.flat()
      const specimenTotal = this.getTotalIntValueOfArray(flattenSpecimenCountPerCryodevice)

      this.$refs.cryoDevicesPerBeaconForm.applyCustomState(specimenTotal)
      this.isResetVisible = true
      this.totalSpecimensCount = specimenTotal
    },
    updateDistribution(payload) {
      const flattenPayload = payload.flat()
      const currentSpecimensCount = this.getTotalIntValueOfArray(flattenPayload)

      this.cryoDevicesPerBeacon = payload
      this.totalSpecimensCount = currentSpecimensCount
      this.updateBeacons()
    },
    getTotalIntValueOfArray(currentArray) {
      const total = currentArray.reduce((a, b) => Number(a) + Number(b), 0)
      return total
    },
    setCustom() {
      this.customSpecimensDistribution = true
    },
    onCryodeviceSelectChange(value) {
      const currentCryoDevice = this.cryoDevicesTypes.find(
        (cryodeviceType) => cryodeviceType.id === value
      )
      this.selectedCryoDevice = currentCryoDevice
      this.setSelectedCryoDeviceType(this.selectedCryoDevice)
    },
    addNewbeacon() {
      const updatedCryoDevicesPerBeacon = [...this.cryoDevicesPerBeacon, [1]]

      this.cryoDevicesPerBeacon = updatedCryoDevicesPerBeacon
      this.applyCustomState()
      this.updateBeacons()
    },
    deleteBeacon(index) {
      const filteredCryoDevicesPerBeacon = this.cryoDevicesPerBeacon.filter(
        (_, cryoDeviceIndex) => cryoDeviceIndex !== index
      )

      this.cryoDevicesPerBeacon = filteredCryoDevicesPerBeacon
      this.applyCustomState()
      this.updateBeacons()
    },
    updateBeacons() {
      // Remove any empty cryodevices from the list
      const updatedCryoDevicesPerBeacon = this.cryoDevicesPerBeacon.map((cryoDevice) => cryoDevice.filter((specimens) => specimens !== 0))

      let initialCounter = this.cryoPerPatientInitialCounter

      const updatedBeacons = updatedCryoDevicesPerBeacon.map((beacon, index) => {
        // const total = updatedCryoDevicesPerBeacon.reduce((prev, curr) => curr.length + prev, 0)

        const currentCryoDevices = beacon.map((cryodeviceCount) => {
          // Add +1 to beaconIndex, so that we don't start on the same value as this.cryoPerPatientInitialCounter
          // const addToCounterValue = beaconIndex + 1
          const cryoPerPatientCounter = initialCounter + 1

          const cryodeviceBarcode = cryodeviceIdGenerator(
            this.selectedPatient[0].globalPatientNumber,
            cryoPerPatientCounter
          )

          initialCounter++

          return {
            beaconBarcode: '',
            ...(this.selectedSpecimenType === SPECIMEN_TYPE_EMBRYO && {
              embryo: {
                biopsy: 'No',
                embryoNumber: null,
                embryoType: null,
                grade: null
              }
            }),
            ...(this.selectedSpecimenType === SPECIMEN_TYPE_EGG && {
              oocyte: {
                maturityLevel: MATURITY_LEVEL_METAPHASE_II
              }
            }),
            cryodeviceBarcode,
            screeningStatus: this.screeningStatusCode,
            specimenCount: cryodeviceCount,
            specimenId: '',
            specimenInfo: '',
            specimenType: this.selectedSpecimenType,
            source: this.cryoDeviceSource
          }
        })

        return {
          beaconId: uuidv4(),
          cryoDevice: currentCryoDevices,
          active: index === 0,
          done: false
        }
      })

      this.beacons = updatedBeacons
    },
    updateShippingSource(shippingSourceData) {
      this.shippingSourceData = shippingSourceData
      this.missingFields = false
      this.setShippingSource(shippingSourceData) // TODO: remove this once the new ticket module is being used.
      this.setCryoDeviceSourceShippingSourceId(shippingSourceData.id)
      this.setCryoDeviceShippingSource(shippingSourceData)
      this.setCryoDeviceSourceTypeId('1') // TODO: find out what this 1 mean, to remove the magic string.
    },
    updateScreeningStatusPerCryo() {
      const updatedBeacons = this.beacons.map((beacon) => {
        const updatedCryoDevice = beacon.cryoDevice.map((cryo) => ({
          ...cryo,
          screeningStatus: this.screeningStatusCode
        }))

        return {
          ...beacon,
          cryoDevice: updatedCryoDevice
        }
      })

      this.beacons = updatedBeacons
    },
    resetCount() {
      const defaultCrypDevicesPerBeacon = [[1]]
      this.isResetVisible = false
      this.cryoDevicesPerBeacon = defaultCrypDevicesPerBeacon
      this.$refs.cryoDevicesPerBeaconForm.resetCount()

      this.customSpecimensDistribution = false
      this.updateDistribution(defaultCrypDevicesPerBeacon)
      this.isResetVisible = false
    },
    async handleSelectCryoDevice() {
      this.loading = true
      const hasValidCryoDeviceInfo = this.validateCryoDeviceInfo()
      const hasValidShippingSource = this.validateShippingSource()

      if (!hasValidCryoDeviceInfo || !hasValidShippingSource) {
        this.loading = false
        return
      }

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        selectedCryoDeviceType: this.selectedCryoDevice,
        beacons: this.beacons,
        shippingSourceId: this.shippingSourceData?.id.toString(),
        donorId: this.donorId
      })

      this.loading = false
      this.$router.push({ name: nextStepPath })
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })

      this.$router.replace({ name: previousStepPath })
    },
    validateCryoDeviceInfo() {
      const isCryoDeviceSelected = !!this.selectedCryoDevice
      const isScreeningStatusSelected = !!this.screeningStatusCode

      if (!isCryoDeviceSelected) {
        this.errors.selectedCryoDevice = CRYODEVICE_TYPE_ERROR_MESSAGE
      }

      if (!isScreeningStatusSelected) {
        this.errors.screeningStatusCode = SCREENING_STATUS_ERROR_MESSAGE
      }

      return isCryoDeviceSelected && isScreeningStatusSelected
    },
    validateShippingSource() {
      if (this.isImportMethod && !this.shippingSourceData) {
        this.errors.shippingSourceData = SHIPPING_SOURCE_ERROR_MESSAGE
        return false
      }

      return true
    }
  },
  computed: {
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedPatient',
      'selectedSpecimenType',
      'typeEmbryo'
    ]),
    ...mapGetters('specimensModule', ['cryoDevicesTypes']),
    ...mapGetters('patientsModule', ['specimensFromPatient']),
    ...mapGetters('patientGroupModule', ['primaryPatientId']),
    ...mapGetters('ticketModule', ['cryoDeviceSource']),
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })
      return currentBreadcrumbs
    },
    isMultiplePatientsEnabled() {
      const multiplePatientsEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
      return multiplePatientsEnabled
    },
    totalCryodevices() {
      const cryodevicesCount = this.beacons.reduce((previousCryoDeviceCount, currentBeacon) => {
        const currentCryoDeviceCount = currentBeacon.cryoDevice.length
        return previousCryoDeviceCount + currentCryoDeviceCount
      }, 0)
      return cryodevicesCount
    },
    areSpecimensSet() {
      const hasCryodevices = this.totalCryodevices > 0
      const hasSpecimens = this.totalSpecimensCount > 0
      return hasCryodevices && hasSpecimens
    },
    isNextButtonDisabled() {
      return !this.areSpecimensSet || this.loading
    },
    totalCryodevicesTitle() {
      const hasMultipleCryodevices = this.totalCryodevices > 1
      const hasMultipleSpecimens = this.totalSpecimensCount > 1
      const cryoDeviceLabel = `Cryodevice${hasMultipleCryodevices ? 's' : ''}`
      const specimensLabel = `Specimen${hasMultipleSpecimens ? 's' : ''}`

      return `${this.totalCryodevices} ${cryoDeviceLabel} - ${this.totalSpecimensCount} ${specimensLabel}`
    },
    isImportMethod() {
      return this.selectedMethod === METHOD_IMPORT
    }
  },
  watch: {
    screeningStatusCode() {
      this.errors.screeningStatusCode = this.screeningStatusCode
        ? false
        : SCREENING_STATUS_ERROR_MESSAGE
      this.updateScreeningStatusPerCryo()
    },
    selectedCryoDevice() {
      this.errors.selectedCryoDevice = this.selectedCryoDevice
        ? false
        : CRYODEVICE_TYPE_ERROR_MESSAGE
    },
    shippingSourceData() {
      this.errors.shippingSourceData = this.shippingSourceData
        ? false
        : SHIPPING_SOURCE_ERROR_MESSAGE
    },
    beacons(newBeacons) {
      this.ticket = this.updateTicketBeacons(this.ticket, newBeacons)
      this.saveTicket(this.ticket)
      this.setBeacons(this.beacons)
    },
    donorId(value) {
      this.setCryoDeviceSourceDonorId(value)
    }
  },
  components: {
    ActionBar,
    BeaconBox,
    ButtonDynamic,
    BreadCrumbs,
    CustomSelect,
    CryosPerBeaconForm,
    DynamicTitle,
    ErrorBox,
    ExternalClinicsSelector,
    InputField,
    HeaderInfo,
    LoadingUi,
    MainContainer,
    TopHeader,
    ScreeningStatusSelect
  }
}
</script>
