import { UsersState } from '../state'

const getters = {
  usersList: (state: UsersState) => state.usersList,
  user: (state: UsersState) => state.user,
  userStatus: (state: UsersState) => state.status,
  lockStatus: (state: UsersState) => state.lockStatus,
  resetPasswordStatus: (state: UsersState) => state.resetPasswordStatus,
  roles: (state: UsersState) => state.roles,
  userModuleError: (state: UsersState) => state.userModuleError,
  userEmailAlerts: (state: UsersState) => state.userEmailAlerts
}

export type UsersGetters = typeof getters

export default getters
