export default {
  cryobeaconsResult(state, resp) {
    state.cryobeacons = resp
  },
  cryobeaconsCreated(state, resp) {
    state.cryobeaconsCreated = resp
  },
  statusMassCreate: (state, status) => {
    state.status = status
  },
  cryobeaconSelected: (state, cryobeacon) => {
    state.cryobeaconSelected = cryobeacon
  },
  searchDataPersisted: (state, search) => {
    state.searchData = search
  }
}
