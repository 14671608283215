import { httpRequest } from '@/helpers/apiHelpers'
import {
  GetUserPathParams, User, CreateUserBody, UserAlert, UserRole, UpdateUserBody, UpdateUserAlertsBody, ChangePasswordBody, ResetPasswordBody, ForgotPasswordBody
} from './types'

export const getUsersApi = () => httpRequest().get('/users?isActive=false')

export const getActiveUsersApi = () => httpRequest().get('/users?isActive=true')


export const postResetPasswordRequestApi = (
  params: ResetPasswordBody
) => httpRequest().post(
  '/users/reset-password',
  { ...params }
)

export const postChangePasswordApi = (
  params: ChangePasswordBody
) => httpRequest(false).post('/users/change-password', { ...params })

export const postForgotPasswordApi = (
  params: ForgotPasswordBody
) => httpRequest(false).post('/users/forgot-password', { ...params })

export const patchUpdateUserApi = (
  params: UpdateUserBody
) => httpRequest().patch<User>(`/users/${params.id}`, { ...params })

export const postSetUserApi = (
  params: CreateUserBody
) => httpRequest().post<User>('/users', { ...params })

export const getUserByIdApi = ({
  userId
}: GetUserPathParams) => httpRequest().get<User>(`/users/${userId}`)

export const getUserRolesApi = (
) => httpRequest().get<UserRole[]>('/users-roles')

export const getUserEmailAlertsApi = ({
  userId
}: GetUserPathParams) => httpRequest().get<UserAlert[]>(`/users/${userId}/alerts`)

export const updateUserEmailAlertsApi = ({
  userId,
  alerts
}: UpdateUserAlertsBody) => httpRequest().patch<UserAlert[]>(`/users/${userId}/alerts`, alerts)

export const patchSetUserEmailAlertsApi = ({
  userId,
  alerts
}: UpdateUserAlertsBody) => httpRequest().patch<UserAlert[]>(`/users/${userId}/alerts`, alerts)
