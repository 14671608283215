<template>
  <ul v-if="options.length" data-test="tabs" class="tabs flex items-end">
    <li :data-test="listItemDataTest(option)" :key="option.value" v-for="option in options">
      <div
        :data-test="tabItemLabelDataTest(option)"
        :data-testid="tabItemLabelDataTest(option)"
        :class="getCssClass(option)"
        @click="selectTab(option)"
      >
        <span class="tabs__tab__button text-tmrw-blue font-bold">{{ option.label }}</span>
        <p class="text-tmrw-blue text-xs" v-if="option.count">{{ option.count }}</p>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'tabs',
  data() {
    return {
      hasActiveTab: false
    }
  },
  emits: ['changeValue'],
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    active: null,
    change: {
      type: Function,
      required: false
    }
  },
  mounted() {
    // If there are no specimens for the active tab, we need to redirect to the other tab
    const { embryoDevicesCount } = this.options[0] // Embryo Devices Count
    const { oocyteDevicesCount } = this.options[1] // Oocyte Devices Count

    // If there are no Oocytes for the active tab, we need to redirect to the embryos tab
    if (embryoDevicesCount && !oocyteDevicesCount) {
      this.$emit('changeValue', this.options[0])
    }
    // If there are no Embryos for the active tab, we need to redirect to the oocytes tab
    if (!embryoDevicesCount && oocyteDevicesCount) {
      this.$emit('changeValue', this.options[1])
    }
  },
  methods: {
    getCssClass(option) {
      const { active } = this
      const { count, disabled, value } = option
      const disabledClasses = ' bg-tmrw-gray bg-opacity-75 text-opacity-50 cursor-none'
      const enabledClass = ' cursor-pointer'
      const selected = value === active
      const selectionClasses = (isSelected) =>
        isSelected
          ? ' tabs__tab--active pt-4 bg-tmrw-gray-lighter'
          : ' bg-tmrw-gray hover:bg-tmrw-green'
      let cssClasses = 'tabs__tab mb-0 mr-2 px-5 py-3 rounded-t-[10px] font-inconsolata'

      if (count) {
        cssClasses += ' px-3 py-1'
      }

      if (disabled) {
        cssClasses += disabledClasses
      } else {
        // if not disabled, can be clicked
        cssClasses += enabledClass
        cssClasses += selectionClasses(selected)
      }

      return cssClasses
    },
    selectTab(option) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(`Click on ${option.label} Tab`, {
        component: 'tabs',
        'current-route': currentRoute,
        'view-name': viewName,
        'label-data': option,
        'redirected-from': redirectedFrom,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
      const { disabled } = option
      if (option && !disabled) return this.$emit('changeValue', option)
      return false
    },
    tabItemLabelDataTest(tabItem) {
      const tabLabel = tabItem.value.toLowerCase()
      return `tab-item-${tabLabel}`
    },
    listItemDataTest(tabItem) {
      const tabLabel = tabItem.value.toLowerCase()
      return `tab-list-item-${tabLabel}`
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig'])
  }
}
</script>
