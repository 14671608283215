export default {
  saveSpecimens(state, resp) {
    state.specimens = {
      ...state.specimens,
      all: resp
    }
  },
  setStatus(state, status) {
    state.specimens = {
      ...state.specimens,
      status
    }
    if (status !== 'error') { state.errorMessage = '' }
    state.status = status
  },
  fetchSpecimenEvents(state, payload) {
    state.specimenEvents = payload
  },
  fetchSpecimenDetails(state, payload) {
    state.specimenDetails = payload
  },
  setEmbryoTypes(state, payload) {
    state.embryoTypes = payload
  },
  setCryoDevicesTypes(state, payload) {
    state.cryoDevicesTypes = payload
  },
  setFileUrl(state, payload) {
    state.fileUrl = payload
  },
  setSummaries(state, payload) {
    state.summaries = payload
  },
  error(state, error) {
    state.errorMessage = error
    state.status = 'error'
  },
  success(state) {
    state.status = 'completed'
  },
  sharedPatientAdded(state, value) {
    state.sharedPatientAdded = value
  }
}
