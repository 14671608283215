import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(customParseFormat)

// Custom flag to mock timezone globally (e.g., in a test environment)
let mockTimeZone = null

const getClientTimeZone = () => {
  // Check if there's a mock timezone set manually
  if (mockTimeZone) {
    return mockTimeZone
  }

  // Default to client timezone or fallback to 'America/New_York'
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York'
}

/**
 * Mock function to set client timezone manually, used during testing
 * @param {string} timeZone - The mocked timezone string, e.g., 'America/New_York'
 */
export const setMockTimeZone = (timeZone) => {
  mockTimeZone = timeZone
}

/**
 * Clear mock timezone after testing
 */
export const clearMockTimeZone = () => {
  mockTimeZone = null
}

/**
 * @param {procedureTime : procedure time on Unix Timestamp} - required
 * @param {featureFlag : feature flag} - optional
 * */

type TimeInput = { procedureTime?: number | string; featureFlag?: boolean }
type DateInput = { day: string; month: string; year: string }

const toLocalTimeZone = ({ procedureTime, featureFlag }: TimeInput): string => {
  try {
    const clientTimeZone = getClientTimeZone()
    // if procedure time is missing or undefined
    if (!procedureTime) {
      throw new Error('Invalid Procedure Time')
    }
    const parsedProcedureTime = Number(procedureTime)

    const dateFormat = featureFlag ? 'DDMMMYYYY hh:mm:ssA' : 'DDMMMYYYY - h:mmA'

    return dayjs.tz(parsedProcedureTime, clientTimeZone).format(dateFormat).toUpperCase()
  } catch (error: any) {
    return error.message
  }
}

/**
 * @param { day : string } - required
 * @param { month: string } - required
 * @param { year: string } - required
 */
const toBackendFormat = ({ day, month, year }: DateInput): string | null => {
  let formattedDate: string | null = null
  const validDay = day?.length === 2
  const validMonth = month?.length === 2
  const validYear = year?.length === 4
  const validInput = validDay && validMonth && validYear
  if (validInput) {
    formattedDate = `${year}-${month}-${day}`
    const validDate = dayjs.utc(formattedDate, 'YYYY-MM-DD', true).isValid()
    if (!validDate) {
      formattedDate = null
    }
  }
  return formattedDate
}

export default Object.freeze({
  toLocalTimeZone,
  toBackendFormat,
  setMockTimeZone,
  clearMockTimeZone
})
