import { Beacon } from '@/types'
import { Patient } from '@/types/patient'
import { IncompleteTicket } from '@/types/ticket'
import {
  EXISTING_BEACON_SCREENING_STATUS_POSITIVE,
  EXISTING_BEACON_SCREENING_STATUS_NEGATIVE,
  EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
} from '@/constants'
import * as ss from '@/config/session-storage-help'

export function setActivatedBeacon(beacon: Beacon): Beacon {
  return {
    ...beacon,
    active: true,
    done: false
  }
}

export function setDeactivatedBeacon(beacon: Beacon): Beacon {
  return {
    ...beacon,
    active: false,
    done: true
  }
}

interface UpdatedBeacons {
  beacons: Beacon[],
  nextBeacon?: Beacon,
  currentBeacon: Beacon
}

function getUpdatedBeacons({ beacons, currentBeacon, nextBeacon }: UpdatedBeacons) {
  const updatedBeacons = beacons.map((beacon) => {
    const { beaconId } = beacon
    if (beaconId === currentBeacon.beaconId) {
      return currentBeacon
    }
    if (nextBeacon && beaconId === nextBeacon.beaconId) {
      return nextBeacon
    }
    return beacon
  })

  return updatedBeacons
}

export function processNextBeacon(beacons: Beacon[]) {
  const activeBeaconIndex = beacons.findIndex((beacon) => beacon.active)
  const activeBeacon = beacons[activeBeaconIndex]

  const deactivatedCurrentBeacon = setDeactivatedBeacon(activeBeacon)
  const nextBeacon = beacons[activeBeaconIndex + 1]

  if (nextBeacon) {
    const activatedNextBeacon = setActivatedBeacon(nextBeacon)
    return getUpdatedBeacons({
      beacons,
      currentBeacon: deactivatedCurrentBeacon,
      nextBeacon: activatedNextBeacon
    })
  }

  return getUpdatedBeacons({
    beacons, currentBeacon: deactivatedCurrentBeacon
  })
}

export function getExistingBeaconScreeningStatus(id: number): string {
  switch (id) {
    case 1:
      return EXISTING_BEACON_SCREENING_STATUS_POSITIVE
    case 2:
      return EXISTING_BEACON_SCREENING_STATUS_NEGATIVE
    case 3:
    default:
      return EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
  }
}
interface ScreeningStatusBeaconRequestParam {
  patientId: number,
  screeningStatus: string,
  numCryodevices: number,
  numCryoBeacons: number
}

export function getScreeningStatusBeaconRequestParams(ticket: IncompleteTicket, patient: Patient): ScreeningStatusBeaconRequestParam {
  const { beacons } = ticket
  const [firstBeacon] = beacons
  const [{ screeningStatus }] = firstBeacon.cryoDevice

  return {
    patientId: patient.globalPatientNumber,
    screeningStatus: getExistingBeaconScreeningStatus(screeningStatus),
    numCryodevices: firstBeacon.cryoDevice.length,
    numCryoBeacons: beacons.length
  }
}

export function getNewBeacon(beaconId: string) {
  const newBeaconArray = ss.getFieldSessionStorage('newBeaconArray', null)
  if (newBeaconArray) {
    const parsedBeaconArray = JSON.parse(newBeaconArray)
    const newBeacon = parsedBeaconArray.find((id: string) => id === beaconId)
    return newBeacon
  }
  return newBeaconArray
}
