<template>
  <MainContainer gridSlots="3">
    <top-header>
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title
          titleType="h2"
          floatTo="left"
        >You're editing the selected Specimens on this Ticket</dynamic-title
        >
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="editTicketSpecimens"
          class="select-specimens__btn-cancel"
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          add-margin-right
          @click="cancelEdit"
        />
        <button-dynamic
          v-if="editTicketSpecimens"
          class="select-specimens__btn-update"
          btnText="Confirm"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle"
          :isDisabled="isValid"
          :isLoading="isLoading"
          @click="submitEdit"
        />
        <button-dynamic
          v-else
          class="select-specimens__btn-next"
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="isValid"
          @click="goNext"
        />
      </template>
    </ActionBar>
    <div class="main-container main-container--actions">
      <div class="main-container__body">
        <loading-ui v-if="checkLoading" />
        <h3
          class="
            no-specimens-title
            w-full
            h-full
            flex
            items-center
            justify-center
            text-2xl text-white
          "
          v-else-if="checkNoSpecimen"
        >
          This patient has no Specimens of this type stored. A Thaw Ticket cannot be created.
        </h3>
        <h3
          v-else-if="checkErrorData"
          class="
            no-specimens-title
            w-full
            h-full
            flex
            items-center
            justify-center
            text-2xl text-white
          "
        >
          There was an error requesting the data
        </h3>
        <ScrollTableComponent
          v-else-if="checkTableHeaders"
          v-model="specimenModel"
          :selectionMode="'checkbox'"
          :headers="headers"
          hideSelectAllCheckbox
          minHeight="h-full"
          @update:modelValue="handleTableChange"
          singleSelection
          ignore-select-all-procedure-check
        />
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import toast from '@/config/toast'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import * as specimensHeaders from '@/constants/table-headers/select-specimens'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import {
  CHECKBOX,
  RADIO,
  SPECIMEN_TYPE_EGG_UPPERCASE,
  SPECIMEN_TYPE_EGGS_UPPERCASE,
  SPECIMEN_TYPE_OOCYTE_UPPERCASE,
  SPECIMEN_TYPE_EMBRYO_UPPERCASE,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE
} from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { buildSpecimenSourcesValueObject } from '@/helpers/specimenHelpers'

export default {
  name: 'select-specimens',
  components: {
    ScrollTableComponent,
    TopHeader,
    LoadingUi,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    DynamicTitle,
    HeaderInfo
  },
  data() {
    return {
      specimenModel: {
        sort: {
          orderBy: 'specimenId',
          direction: 'asc'
        },
        options: []
      },
      selectedSpecimens: [],
      preSelectedSpecimens: null,
      allSpecimens: [],
      editTicketSpecimens: false,
      beacons: [],
      selectedTicketToEdit: {},
      isLoading: false,
      specimenType: '',
      cacheResponse: [],
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters('specimensModule', ['specimens', 'embryoTypes']),
    ...mapGetters('authModule', ['appBaseConfig', 'token']),
    ...mapGetters('newTicketModule', ['selectedPatient']),
    headers() {
      return specimensHeaders[this.specimenType.toLowerCase()]
    },
    selectedSpecimensMessage() {
      return `${this.selectedSpecimens.length} Cryodevices Selected`
    },
    isValid() {
      return !this.selectedSpecimens.length
    },
    checkLoading() {
      return this.specimens.status === 'loading'
    },
    checkNoSpecimen() {
      return this.specimens.status === 'ok' && this.allSpecimens.length === 0
    },
    checkErrorData() {
      return this.specimens.status === 'error'
    },
    checkTableHeaders() {
      return this.headers.length
    },
    // Check if there is a seleted ticket to edit to show cheeckbox or radio selection in table
    selectionMode() {
      return Object.keys(this.selectedTicketToEdit).length === 0 ? CHECKBOX : RADIO
    }
  },
  watch: {
    specimens() {
      this.allSpecimens = this.specimens.all.map((specimen) => ({
        ...specimen,
        cryoDate: Number(specimen.cryoDate),
        embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes)
      }))
    },
    async allSpecimens(newValue) {
      if (newValue.length) {
        this.specimenModel = {
          ...this.specimenModel,
          options: this.allSpecimens.map((specimen) => ({
            ...specimen,
            sourcesValue: buildSpecimenSourcesValueObject(specimen)
          }))
        }
      }

      // Here are selected the specimens when edit ticket
      if (
        newValue
        && newValue.length > 0
        && this.editTicketSpecimens
        && !this.preSelectedSpecimens
      ) {
        // This variable name has to be changed to isTicketToEdit.
        const specimens = this.selectedTicketToEdit.specimens.map((item) => item.specimenId)
        this.preSelectedSpecimens = {
          key: 'specimenId',
          values: specimens
        }
        // https://tmrworg.atlassian.net/browse/DEV-3408
        const filteredSpecimens = await this.specimenModel.options.filter(
          (item) => item.beaconBarcode === this.selectedTicketToEdit.beaconBarcode
        )
        this.specimenModel = {
          ...this.specimenModel,
          options: filteredSpecimens.map((option) => ({
            ...option,
            disabled: false
          }))
        }
      }
    },
    // https://tmrworg.atlassian.net/browse/DEV-3408
    // mapping specimenModel changes and changin disabled to false
    // we have to do this as the data mutation is breaking the expected behaviour
    specimenModel() {
      if (this.editTicketSpecimens) {
        const filteredSpecimens = this.specimenModel.options.filter(
          (item) => item.beaconBarcode === this.selectedTicketToEdit.beaconBarcode
        )
        const tempSpecimenModel = {
          ...this.specimenModel,
          options: filteredSpecimens.map((option) => ({
            ...option,
            disabled: false
          }))
        }
        this.specimenModel.options = tempSpecimenModel.options
      }
    }
  },
  async created() {
    this.setSpecimeType()
    const patients = this.selectedPatient || this.$ss.getFieldSessionStorage('newTicket', 'patient')

    // This variable name has to be changed to isTicketToEdit.
    this.selectedTicketToEdit = this.$ss.getFieldSessionStorage('process', 'selectedTicketToEdit')

    this.editTicketSpecimens = !!this.selectedTicketToEdit && Object.keys(this.selectedTicketToEdit).length

    let { globalPatientNumber } = this.selectedTicketToEdit

    if (!this.editTicketSpecimens) {
      globalPatientNumber = patients.length ? patients[0].globalPatientNumber : 0
      if (!globalPatientNumber) {
        return this.$router.push('/select-procedure')
      }
    }
    const patientIsNotNull = Boolean(Object.keys(patients[0]).length)
    if (patientIsNotNull) {
      const patientIds = patients.map((patient) => patient.globalPatientNumber)
      this.fetchSpecimensEnabled({ patientIds, specimenType: this.specimenType })
    }

    const selectedSpecimens = this.$ss.getFieldSessionStorage('newTicket', 'specimens')

    if (selectedSpecimens) {
      this.preSelectedSpecimens = {
        key: 'id',
        values: selectedSpecimens.map((item) => item.id)
      }
    }
    return null
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    ...mapActions('specimensModule', [
      'fetchSpecimensEnabled',
      'fetchEmbryoTypes',
      'fetchSpecimens'
    ]),
    async handleTableChange(newValue) {
      this.selectedItems = newValue.options.filter((option) => option.selected)

      this.selectedSpecimens = this.allSpecimens.filter(
        (specimen) => this.selectedItems.filter((selected) => selected.beaconBarcode === specimen.beaconBarcode)
          .length
      )
      if (this.editTicketSpecimens) {
        // Edition
        this.selectedTicketToEdit = this.$ss.getFieldSessionStorage(
          'process',
          'selectedTicketToEdit'
        )
        this.selectedTicketToEdit.specimens = [
          ...this.selectedSpecimens.map((item) => ({
            ...item,
            inSelectedTicket: true
          }))
        ]
        this.$ss.setFieldSessionStorage(
          'selectedTicketToEdit',
          this.selectedTicketToEdit,
          'process'
        )
      } else {
        // Creation
        this.beacons = []
        this.selectedSpecimens.forEach((specimen) => {
          const existingBeacon = this.beacons.find(
            (item) => item.beaconBarcode === specimen.beaconBarcode
          )
          let beacon = {}
          const specimenWithTypeInfo = {
            ...specimen,
            ...(specimen.specimenType === SPECIMEN_TYPE_EMBRYO_UPPERCASE && {
              embryo: { ...specimen.embryo }
            }),
            ...(specimen.specimenType !== SPECIMEN_TYPE_EMBRYO_UPPERCASE && {
              oocyte: { ...specimen.oocyte }
            })
          }
          if (!existingBeacon) {
            beacon = {
              beaconBarcode: specimen.beaconBarcode,
              cryoDevice: [specimenWithTypeInfo]
            }
          } else {
            this.beacons = this.beacons.filter(
              (beac) => beac.beaconBarcode !== specimen.beaconBarcode
            )
            beacon = {
              ...existingBeacon,
              cryoDevice: [...existingBeacon.cryoDevice, specimenWithTypeInfo]
            }
          }
          this.beacons.push(beacon)
        })
        // https://tmrworg.atlassian.net/browse/DEV-3408
        // identifying the selected specimens by cryodeviceBarcode
        const temporalBeacons = []
        await this.specimenModel.options.map((specimen) => {
          // identifying the selected by selected attributes
          if (specimen.selected) {
            // mapping the beacons
            this.beacons.map((beacon) => beacon.cryoDevice.map((cryodevice) => {
              // if has same cryodeviceBarcode persist the data
              if (cryodevice.cryodeviceBarcode === specimen.cryodeviceBarcode) {
                return temporalBeacons.push(cryodevice)
              }
              return true
            }))
          }
          return true
        })
        // re-assign it the crioDevice value
        await this.beacons.map((beacon) => {
          // mapping temporalBeacons
          temporalBeacons.map(async (temporalBeacon) => {
            // if has same beaconBarcode re-assign the values
            if (beacon.beaconBarcode === temporalBeacon.beaconBarcode) {
              // assign it raw values
              // eslint-disable-next-line no-param-reassign
              beacon.cryoDevice = temporalBeacons
              // filtering data as we could have more than 1 cryodevice
              const filteredData = await beacon.cryoDevice.filter(
                (beaconCriodevice) => beaconCriodevice.beaconBarcode === temporalBeacon.beaconBarcode
              )
              // assign it the data to their proper cryoDevice
              // eslint-disable-next-line no-param-reassign
              beacon.cryoDevice = filteredData
              return this.beacons
            }
            return this.beacons
          })
          return this.beacons
        })
        // persist the beacons on session
        this.$ss.setFieldSessionStorage('beacons', this.beacons, 'newTicket')
      }
    },
    setSpecimeType() {
      this.specimenType = this.$ss.getFieldSessionStorage('newTicket', 'type').toUpperCase()

      if (!this.specimenType) {
        const getSpecimeFromTicketToEdit = this.$ss.getFieldSessionStorage(
          'process',
          'selectedTicketToEdit'
        )
        this.specimenType = getSpecimeFromTicketToEdit.specimens[0].oocyte
          ? SPECIMEN_TYPE_EGGS_UPPERCASE
          : SPECIMEN_TYPE_EMBRYO_UPPERCASE
      }

      this.specimenType = this.specimenType === SPECIMEN_TYPE_EGGS_UPPERCASE
        || this.specimenType === SPECIMEN_TYPE_OOCYTE_UPPERCASE
        ? SPECIMEN_TYPE_EGG_UPPERCASE
        : this.specimenType
    },
    goNext() {
      this.$router.push('/final-ticket')
    },
    async submitEdit() {
      // persist the filtered data
      const filteredSpecimens = []
      this.isLoading = true
      const { collectionProtocolId } = this.appBaseConfig
      const { specimens, ticketId, procedureName } = this.selectedTicketToEdit
      // filtering the cache specimens against the selected specimens
      await specimens.map((specimen) => this.selectedItems.map((selectedItem) => {
        if (selectedItem.cryodeviceBarcode === specimen.cryodeviceBarcode) {
          return filteredSpecimens.push(specimen)
        }
        return true
      }))
      const data = {
        procedureName,
        specimens: filteredSpecimens,
        ticketId,
        cpId: collectionProtocolId
      }
      try {
        await this.updateTickets({ tickets: [data], ticketId })
        this.$router.push('/selected-tickets')
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.isLoading = false
      }
    },
    cancelEdit() {
      this.$ss.setFieldSessionStorage('selectedTicketToEdit', {}, 'process')
      this.$router.push('/selected-tickets')
    }
  }
}
</script>
