export default {
  applyCryoDateToCryoDevice({ commit, state }) {
    const cryoDevicesHaveCryoDate = state.ticket.beacons.every(
      (beacon) => beacon.cryoDevice.every((cryoDev) => cryoDev.cryoDate)
    )

    if (!cryoDevicesHaveCryoDate) {
      const updatedBeacons = state.ticket.beacons.map((beacon) => {
        const updatedCryoDevice = beacon.cryoDevice.map((cryoDevice) => ({
          ...cryoDevice,
          cryoDate: state.ticket.visit.procedureDateFrom
        }))
        return {
          ...beacon,
          cryoDevice: updatedCryoDevice
        }
      })

      commit('setBeacons', updatedBeacons)
    }
  }
}
