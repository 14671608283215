import pdfMake from 'pdfmake/build/pdfmake'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { VFS } from '@/assets/pdf/vfs_fonts'
import * as ss from '@/config/session-storage-help'
import { LOGO_IVFOS, CALENDAR } from '@/assets/pdf/logos-base64'
import { staticTypes, ticketType } from '@/helpers/printPDF/types/index'
import ErrorHandler from '../ErrorHandler/index'
import { bodyHeadersCryoShipperTop, bodyHeadersCryoShipperBottom } from './static'

dayjs.extend(utc)
pdfMake.vfs = VFS

/**
 * @getTableBodyObject -> Generates the table content
 * @param tableBodyItems object required
 * @returns Object
 * */

const getTableBodyObject = (tableBodyItems: object[]) => {
  if (tableBodyItems?.length) {
    const tableBodyObject = {
      style: 'tableTmrw',
      table: {
        widths: [45, 80, 40, 60, 75, 50, 80],
        heights: [15],
        headerRows: 1,
        body: [bodyHeadersCryoShipperTop, ...tableBodyItems]
      },
      layout: 'lightHorizontalLines'
    }
    return tableBodyObject
  }
  return {}
}

const getTableBodyObjectBottom = (tableBodyItems: object[]) => {
  if (tableBodyItems?.length) {
    const tableBodyObject2 = {
      style: 'tableTmrw',
      table: {
        widths: [45, 80, 40, 60, 75, 50, 80],
        heights: [15],
        headerRows: 1,
        body: [bodyHeadersCryoShipperBottom, ...tableBodyItems]
      },
      layout: 'lightHorizontalLines'
    }
    return tableBodyObject2
  }
  return {}
}

/**
 * @pdfContent -> generates the content
 * @param user object required
 * @param config object required
 * @param today string required
 * @param ticketsScheduled array required
 * @param ticketsInProgress array required
 * @param ticketsCompleted array required
 * @returns
 * */

const pdfContent = (contentParams) => {
  const { user, config, today, ticketsListTop, ticketsListBottom } = contentParams
  return {
    pageMargins: [25, 25, 25, 25],
    footer: function(currentPage, pageCount) {
      return {
        text: 'Page ' + currentPage.toString() + ' of ' + pageCount + ' ',
        alignment: 'right',
        margin: [0, 0, 20, 0],
        style: 'footerStyles'
      }
    },
    content: [
      {
        alignment: 'right',
        columns: [
          {
            image: LOGO_IVFOS,
            width: 150
          },
          {
            color: '#5a5b5e',
            fontSize: 20,
            text: `${user.firstName} ${user.lastName}`
          }
        ]
      },
      '\n',
      {
        columns: [
          {
            image: CALENDAR,
            width: 8,
            height: 8
          },
          {
            text: ' ',
            width: 5,
            height: 5
          },
          {
            text: today.toUpperCase(),
            alignment: 'left',
            fontSize: 10,
            bold: true
          },
          {
            text: `${config.clinicName} | ${config.siteName}`,
            alignment: 'right',
            color: '#5a5b5e',
            fontSize: 10
          }
        ]
      },
      '\n\n',
      {
        columns: [
          {
            text: 'CryoShipper Ticket Inventory: Grid 1, Top ' + `(${ticketsListTop.length}` + ' CryoBeacons)'
          }
        ]
      },
      '\n',
      getTableBodyObject(ticketsListTop),
      '\n\n',
      {
        columns: [
          {
            text: 'CryoShipper Ticket Inventory: Grid 2, Bottom ' + `(${ticketsListBottom.length}` + ' CryoBeacons)'
          }
        ]
      },
      '\n',
      getTableBodyObjectBottom(ticketsListBottom)
    ],
    defaultStyle: {
      font: 'Futura'
    },
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: '#5a5b5e',
        margin: [0, 0, 0, 0]
      },
      tableTmrw: {
        fontSize: 8,
        color: '#5a5b5e',
        padding: [10, 10, 10, 10]
      },
      footerStyles: {
        fontSize: 10,
        bold: true
      }
    }
  }
}

/**
 * @FligthBoardToPdf -> generates the PDF for the FligthBoard
 * @param data Array
 * @returns PDF content
 * */

const FligthBoardToPdf = (data) => {
  try {
    // get static data
    const user: staticTypes['user'] = ss.getFieldSessionStorage('user', null)
    const config: staticTypes['config'] = ss.getFieldSessionStorage('config', null)
    const today: Readonly<string> = dayjs().format('DD MMMM YYYY')

    // setup static references
    const ticketsData = {
      ticketsListTop: [],
      ticketsListBottom: []
    }

    // if data is undefined
    if (!data) throw new Error(`tickets data cannot be ${data}`)

    // assign it FB data to local reference
    const totalTicketsTop: ticketType[] = data.top

    const totalTicketsBottom: ticketType[] = data.bottom
    

    // assign it values to ticketData
    totalTicketsTop.forEach((ticket: ticketType) => {
      const ticketData = [
        dayjs(ticket.procedureTime).format('DDMMMYYYY - h:mm A').toUpperCase(),
        ticket?.patientName,
        ticket?.ticketId,
        dayjs(ticket?.patientDob).format('DDMMMYYYY').toUpperCase(),
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Source'
            ? `${ticket?.robotLocations?.[0].siteName}\n${(ticket?.robotLocations?.[0].robotName || '')}`
            : `${ticket?.robotLocations?.[1].siteName}\n${(ticket?.robotLocations?.[1].robotName || '')}`
          : '',
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Destination'
            ? `${ticket?.robotLocations?.[0]?.siteName}`
            : `${ticket?.robotLocations?.[1]?.siteName}`
          : '',
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Source'
            ? `${ticket?.robotLocations?.[0].locationDetails ?? ''}`
            : `${ticket?.robotLocations?.[1].locationDetails ?? ''}`
          : ''
      ]

      // analyzes and assigns the corresponding values to each category
      return Object.assign(ticketsData.ticketsListTop, [
        ...ticketsData.ticketsListTop,
        [...ticketData]
      ])
    })

    totalTicketsBottom.forEach((ticket: ticketType) => {
      const ticketData = [
        dayjs(ticket.procedureTime).format('DDMMMYYYY - h:mm A').toUpperCase(),
        ticket?.patientName,
        ticket?.ticketId,
        dayjs(ticket?.patientDob).format('DDMMMYYYY').toUpperCase(),
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Source'
            ? `${ticket?.robotLocations?.[0]?.siteName}\n${(ticket?.robotLocations?.[0].robotName || '')}`
            : `${ticket?.robotLocations?.[1]?.siteName}\n${(ticket?.robotLocations?.[1].robotName || '')}`
          : '',
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Destination'
            ? `${ticket?.robotLocations?.[0]?.siteName}`
            : `${ticket?.robotLocations?.[1]?.siteName}`
          : '',
        ticket?.robotLocations.length > 0
          ? ticket.robotLocations?.[0].type === 'Source'
            ? `${ticket?.robotLocations?.[0].locationDetails ?? ''}`
            : `${ticket?.robotLocations?.[1].locationDetails ?? ''}`
          : ''
      ]

      // analyzes and assigns the corresponding values to each category
      return Object.assign(ticketsData.ticketsListBottom, [
        ...ticketsData.ticketsListBottom,
        [...ticketData]
      ])
    })

    const contentParams = {
      user,
      config,
      today,
      ticketsListTop: ticketsData.ticketsListTop,
      ticketsListBottom: ticketsData.ticketsListBottom
    }
    // generating the pdf content
    const content = pdfContent(contentParams)

    // returning the pdf
    return pdfMake.createPdf(content).open()
  } catch (error: any) {
    ErrorHandler('PrintToPdf', `\n\n${error.stack || error}`);
    throw error
  }
}

export { FligthBoardToPdf, pdfContent, getTableBodyObject, getTableBodyObjectBottom }
