export default {
  ticketId: (state) => state.ticket.ticketId,
  state: (state) => state.ticket.state,
  substate: (state) => state.ticket.substate,
  procedureName: (state) => state.ticket.procedureName,
  robotTransfer: (state) => state.ticket.robotTransfer,
  beacons: (state) => state.ticket.beacons,
  source: (state) => state.ticket.source,
  cryoDeviceSource(state) {
    const [beacon] = state.ticket.beacons
    return beacon?.cryoDevice[0].source
  },
  ticketCreationState(state) {
    const {
      robotTransfer,
      cpId,
      eventId,
      procedureType,
      globalPatientNumber,
      specimenAction,
      specimenType,
      userId,
      visit,
      metadata,
      patient,
      selectedCryoDeviceType,
      beacons,
      source
    } = state.ticket

    return {
      robotTransfer,
      cpId,
      eventId,
      procedureType,
      globalPatientNumber,
      specimenAction,
      specimenType,
      userId,
      visit,
      source,
      metadata,
      patient,
      beacons,
      selectedCryoDeviceType
    }
  }
}
