<template>
  <div class="flex p-8">
    <div>
      <CustomCalendar @onCalendarDateChange="handleDateTime" />
      <br />
      <p><strong>Return:</strong></p>
      <p>{{ value }}</p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import CustomCalendar from '@/components/CustomCalendar/CustomCalendar.vue'

export default {
  name: 'custom-calendar-demo',
  data() {
    return {
      calendarMode: 'single',
      allowedModes: ['now', 'single', 'range'],
      now: true,
      single: true,
      range: false,
      compactVersionNow: false,
      value: '',
      min: dayjs().startOf('day').valueOf(),
      max: dayjs().add(5, 'day').startOf('day').valueOf(),
      from: dayjs().valueOf()
    }
  },
  watch: {
    now(newValue) {
      this.toggleMode('now', newValue)
    },
    single(newValue) {
      this.toggleMode('single', newValue)
    },
    range(newValue) {
      this.toggleMode('range', newValue)
    }
  },
  methods: {
    handleDateTime(value) {
      this.value = JSON.stringify(value)
    },
    handleCalendarMode(mode) {
      this.calendarMode = mode
    },

    toggleMode(mode, bool) {
      if (!bool && this.allowedModes.includes(mode)) {
        this.allowedModes = this.allowedModes.filter((el) => el !== mode)
      } else {
        this.allowedModes = [...this.allowedModes, mode]
      }
    }
  },
  components: {
    CustomCalendar
  }
}
</script>
