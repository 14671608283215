<template>
  <div>
    <top-header>Print Label Test</top-header>
    <preview-labels showLabelModal :labels="labelsData" />
  </div>
</template>

<script>
import PreviewLabels from '@/components/PreviewLabels/PreviewLabels.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'

export default {
  name: 'print-label-test',
  data() {
    return {
      labelsData: [
        {
          cryodeviceBarcode: '123456789-00523111',
          identificationNumber: '34590-09123',
          firstName: 'Daniel',
          lastName: 'Pesina',
          procedureDateFrom: '06MAR2019',
          screeningStatus: 1
        },
        {
          cryodeviceBarcode: '123456789-00710111',
          identificationNumber: '35490-09123',
          firstName: 'Jhosehp',
          lastName: 'Rendon',
          procedureDateFrom: '23JUN2019',
          screeningStatus: 2
        },
        {
          cryodeviceBarcode: '123456789-00123111',
          identificationNumber: '34590-09123',
          firstName: 'Daniel',
          lastName: 'Pesina',
          procedureDateFrom: '06MAR2019',
          screeningStatus: 1
        },
        {
          cryodeviceBarcode: '123456789-01710111',
          identificationNumber: '35490-09123',
          infectiousState: 'NO',
          firstName: 'Jhosehp',
          lastName: 'Rendon',
          procedureDateFrom: '23JUN2019',
          screeningStatus: 2
        }
      ]
    }
  },
  components: {
    PreviewLabels,
    TopHeader
  }
}
</script>
<style lang="scss">
</style>
