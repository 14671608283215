<template>
  <div data-test="checkbox-container" class="checkbox-container" @click="$emit('update:modelValue', !modelValue)">
    <input type="checkbox" hidden />
    <label
      data-test="checkbox-label"
      class="w-6 h-6 bg-white border block rounded-md border-solid relative border-tmrw-blue"
      :class="{
        'checkbox-container__checkbox--checked after:rotate-40 after:empty-content after:absolute after:w-2 \
        after:h-3 after:border-r-4 after:border-b-4 after:border-solid after:border-tmrw-blue-dark after:top-40 \
        after:left-50 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-sm': modelValue,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'checkbox',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
