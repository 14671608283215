import { useStore } from 'vuex'

const useMutations = (namespace = null) => {
  const store = useStore()
  const mapName = (mutation) => {
    if (!namespace) {
      return mutation
    } else {
      return mutation.replace(`${namespace}/`, '')
    }
  }
  return Object.fromEntries(
    Object.keys(store._mutations)
      .filter((mutation) => {
        if (namespace) {
          return mutation.includes(`${namespace}/`)
        }
        return true
      })
      .map((mutation) => [
        mapName(mutation),
        (value) => store.commit(mutation, value)
      ])
  )
}

export default useMutations
