import { ref, computed } from 'vue'
import {
  ComponentRefType,
  DefaultSearchCriteriaModel,
  ISearchByComponents,
  ISearchCriteria,
  ISearchModeOptions,
  ISearchValueModel,
  SearchBeaconTypeFilter,
  SearchBeaconTypeFilterValues,
  TMode
} from '@/types/massCreate'
import { INCLUDE_LOWER } from '@/constants'
import toast from '@/config/toast'
import exclamationIcon from '@/assets/exclamation-triangle.png'

export function useSearchCriteria(
  inputs: Array<ISearchByComponents>,
  includeExcludeOptions: ISearchModeOptions,
  getDefaultSearchValues: () => DefaultSearchCriteriaModel
) {
  const {
    defaultSearchByValue,
    defaultModeSearchValue,
    defaultSearchInputTextValue,
    defaultSearchCriterias
  } = getDefaultSearchValues()
  const searchByValue = ref<SearchBeaconTypeFilter | ''>(defaultSearchByValue)
  const filterValue = ref<ISearchValueModel>(defaultSearchInputTextValue)
  const modeSearchValue = ref<TMode>(defaultModeSearchValue)
  const searchCriterias = ref<Array<ISearchCriteria>>([...defaultSearchCriterias])
  const componentRef = ref<InstanceType<ComponentRefType> | null>(null)

  const getComponent = computed(() => {
    return inputs.find((input) => input.value === searchByValue.value)?.component
  })

  const searchFieldPlaceholder = computed(() => {
    return searchByValue.value === 'byPatientIds' ? 'Patient ID *' : 'Patient Last Name *'
  })

  const getModeDropdownValue = computed(() => {
    return includeExcludeOptions[modeSearchValue.value].value
  })

  function isCriteriaAlreadyAdded(label) {
    return searchCriterias.value?.some((item) => item.label === label) || false
  }

  function searchCriteriasColor(mode: TMode) {
    return mode === INCLUDE_LOWER ? 'blue-background' : 'error-button'
  }

  function setSearchBy(evt: Event) {
    const target = (evt.target as HTMLInputElement).value
    searchByValue.value = target as SearchBeaconTypeFilter
    filterValue.value = { data: '', label: '' }
    modeSearchValue.value = INCLUDE_LOWER
  }

  function handleIncludeExcludeDropdown(val) {
    modeSearchValue.value = val
  }

  function handleInput(val: { data: any; label: string } | null) {
    if (!val) {
      filterValue.value = { data: '', label: '' }
      return
    }
    filterValue.value = { data: val.data, label: val.label }
  }

  function handleAddSearchCriteria() {
    if (!canAddFilter.value) {
      return
    }

    const searchBy = searchByValue.value
    searchCriterias.value.push({
      key: searchBy as SearchBeaconTypeFilter,
      mode: modeSearchValue.value,
      label: `${modeSearchValue.value}: ${filterValue.value.label}`,
      data: filterValue.value.data as SearchBeaconTypeFilterValues
    })
    modeSearchValue.value = INCLUDE_LOWER
    filterValue.value = { data: '', label: '' }
    componentRef.value?.resetValues()
  }

  const resetFilters = () => {
    searchCriterias.value = []
    filterValue.value = { data: '', label: '' }
    modeSearchValue.value = INCLUDE_LOWER
    componentRef.value?.resetValues()
  }

  function removeCriteria(label: string) {
    searchCriterias.value = searchCriterias.value.filter((item) => item.label !== label)
  }

  const isLocationAdded = computed(() =>
    searchCriterias.value.some((item) => item.key === 'byLocation')
  )

  const lengthOfValuesByType = (type: SearchBeaconTypeFilter) => {
    return searchCriterias.value.filter((criteria) => criteria.key === type)?.length
  }

  const canAddFilter = computed(() => {
    if (
      !filterValue.value.label ||
      isCriteriaAlreadyAdded(`${modeSearchValue.value}: ${filterValue.value.label}`)
    ) {
      return false
    }

    if (searchByValue.value === 'byLocation' && isLocationAdded.value) {
      toast.customError({
        title: 'Only one CryoRobot location can be searched at a time.',
        icon: exclamationIcon
      })
      return false
    }

    if (searchCriterias.value.length >= 5) {
      toast.customError({
        title: 'You have reached the maximum number of search inputs.',
        icon: exclamationIcon
      })
      return false
    }

    if (searchByValue.value === 'byCryoDates' && lengthOfValuesByType('byCryoDates') >= 2) {
      toast.customError({
        title: 'Only two CryoDate ranges can be used per search.',
        icon: exclamationIcon
      })
      return false
    }

    return true
  })

  return {
    searchByValue,
    filterValue,
    modeSearchValue,
    searchCriterias,
    getComponent,
    searchFieldPlaceholder,
    getModeDropdownValue,
    searchCriteriasColor,
    setSearchBy,
    handleIncludeExcludeDropdown,
    handleInput,
    handleAddSearchCriteria,
    resetFilters,
    removeCriteria,
    isCriteriaAlreadyAdded,
    componentRef
  }
}
