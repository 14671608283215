<template>
  <ScreeningStatusLabel
    class="scroll-table-screening-status"
    :icon="options.icon"
    :label="options.label"
    :textColor="options.textColor"
    :iconColor="options.iconColor"
    :align="columnOptions.alignment || 'left'"
    :id="Number(value) || 0" />
</template>

<script>
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'

export default {
  name: 'scroll-table-screening-status',
  props: ['value', 'options', 'columnOptions'],
  components: {
    ScreeningStatusLabel
  }
}
</script>
