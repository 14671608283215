<template>
  <div data-test="screening-status-select" :class="['screening-status-select', CSSclasses || '']">
    <CustomSelect
      data-testid="screening-status"
      dataTest="screening-status"
      class="fa w-full"
      :allowEmpty="allowEmpty"
      :modelValue="modelValue"
      :options="options"
      :hasLargePadding="hasLargePadding"
      :defaultOptionLabel="`What is the ${infectiousScreeningStatus}?*`"
      @update:modelValue="handleInput"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import { INFECTIOUS_SCREENING_STATUS } from '@/constants'

export default {
  name: 'screening-status-select',
  data() {
    return {
      infectiousScreeningStatus: INFECTIOUS_SCREENING_STATUS
    }
  },
  emits: ['update:modelValue'],
  props: [
    'modelValue',
    'allowEmpty',
    'CSSclasses',
    'hasLargePadding'
  ],
  computed: {
    ...mapGetters('screeningStatusModule', ['screeningStatus', 'screeningLoading', 'screeningError']),
    options() {
      return this.screeningStatus.map((elem) => ({
        value: elem.id,
        label: elem.printName
      }))
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', Number(value))
    }
  },
  components: {
    CustomSelect
  }
}
</script>

<style scoped lang="scss">
  .select-loading-placeholder {
    border-radius: 0;
    @apply border border-tmrw-blue-dark;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 30px 8px 20px;
    position: relative;
    color: white;

    &::after{
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      @apply border-t-5 border-tmrw-blue-dark;
      clear: both;
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .spinner {
      height: 24px;
      width: 24px;
    }
  }
</style>
