<template>
  <label
    class="screening-status-label flex items-center"
    :class="{
      [`screening-status-label--${textColor}`]: textColor,
      'justify-end': align === 'right',
      'justify-start': align === 'left',
      'justify-center': align !== 'left' && align !== 'right',
      'w-full': isFullWidth
    }"
  >
    <template v-if="screeningLoading" />
    <template v-else-if="screeningError" />
    <div v-else class="flex items-center">
      <span
        v-if="showIcon"
        class="screening-status-label__icon-container flex items-center justify-center"
        :class="[
          {
            'justify-end': align === 'right',
            'justify-start': align === 'left'
          }
        ]"
      />
      <span v-for="image in screenStatusImageList" :key="image.id">
        <image-element
          v-if="image.id === id"
          :imageSrc="image.fileName"
          :altText="image.altText"
          class="max-w-initial mr-1 inline"
          :class="[{ [`svg-${iconColor}`]: iconColor }, image.class]"
          width="26"
          height="26"
        />
      </span>
      <label
        data-test="screening-status-label"
        v-if="showLabel"
        class="screening-status-label__name truncate inline-block"
        :class="[paddingSmall ? 'py-0.5' : 'py-2', `screening-status-label--${textColor}`]"
      >
        {{ screeningLabel }}
      </label>
    </div>
  </label>
</template>

<script>
import { mapGetters } from 'vuex'
import imageElement from '@/components/ImageElement/ImageElement.vue'

export default {
  name: 'screening-status-label',
  components: {
    imageElement
  },
  data() {
    return {
      screenStatusImageList: [
        {
          id: 1,
          fileName: 'screenstatus-positive.svg',
          altText: 'Positive Status Icon',
          class: 'screening-status-label__icon--positive'
        },
        {
          id: 2,
          fileName: 'screenstatus-negative.svg',
          altText: 'Negative Status Icon',
          class: 'screening-status-label__icon--negative'
        },
        {
          id: 3,
          fileName: 'screenstatus-unscreened.svg',
          altText: 'Unscreen Status Icon',
          class: 'screening-status-label__icon--unscreened'
        }
      ]
    }
  },
  props: {
    id: {
      type: Number,
      required: false
    },
    icon: {
      type: Boolean,
      default: null
    },
    label: {
      type: Boolean,
      default: null
    },
    textColor: {
      type: String,
      required: false,
      default: 'white'
    },
    iconColor: {
      type: String,
      required: false,
      default: 'white'
    },
    align: {
      type: String,
      validator(value) {
        // Center is by default
        return ['left', 'right', ''].indexOf(value) !== -1
      }
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    paddingSmall: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters('screeningStatusModule', [
      'screeningStatus',
      'screeningLoading',
      'screeningError'
    ]),
    screeningLabel() {
      const screeningItems = this.screeningStatus
      if (this.id && screeningItems && screeningItems.length > 0) {
        return screeningItems.find((elem) => elem.id === this.id).printName
      }
      return ''
    },
    showLabel() {
      return !(typeof this.label === 'boolean' && this.label === false)
    },
    showIcon() {
      return !(typeof this.icon === 'boolean' && this.icon === false)
    }
  }
}
</script>
