<template>
  <ActionBar colsDistribution="9/3" addMarginTop>
    <template v-slot:left-side>
      <DynamicTitle titleType="h2" is-bold floatTo="left">
        {{ `Drag & Drop to edit the CryoLabel Format` }}
      </DynamicTitle>
    </template>
    <template v-slot:right-side>
      <ButtonDynamic
        class="mr-4"
        btnText="Print"
        btnType="button"
        floatTo="right"
        showIcon
        btnStyle="secondary"
        isBgTransparent
        fontAwesomeIconClass="print"
        @click="onPrint"
      />
      <ButtonDynamic
        btnText="Save"
        btnType="button"
        btnStyle="primary"
        floatTo="right"
        showIcon
        fontAwesomeIconClass="check-circle"
        @click="onSave"
      />
    </template>
  </ActionBar>
</template>

<script>
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

export default {
  name: 'modular-label-header',
  props: {
    onSave: {
      type: Function,
      required: true
    },
    onPrint: {
      type: Function,
      required: true
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle
  }
}
</script>
