<template>
  <div class="modular-label__tabs">
    <button
      :class="{ tab: true, active: currentSpecimenType === MODULAR_LABEL_EMBRYO }"
      @click="handleToggleSpecimenType(MODULAR_LABEL_EMBRYO)"
    >
      {{ MODULAR_LABEL_TEXT_SPECIMEN_TYPE_TITLE[MODULAR_LABEL_EMBRYO] }}
    </button>
    <button
      :class="{ tab: true, active: currentSpecimenType === MODULAR_LABEL_EGG }"
      @click="handleToggleSpecimenType(MODULAR_LABEL_EGG)"
    >
      {{ MODULAR_LABEL_TEXT_SPECIMEN_TYPE_TITLE[MODULAR_LABEL_EGG] }}
    </button>
  </div>
</template>

<script>
import {
  MODULAR_LABEL_TEXT_SPECIMEN_TYPE_TITLE,
  MODULAR_LABEL_EGG,
  MODULAR_LABEL_EMBRYO
} from '@/constants/modular-cryolabel'

export default {
  name: 'modular-label-tabs',
  data() {
    return {
      MODULAR_LABEL_TEXT_SPECIMEN_TYPE_TITLE,
      MODULAR_LABEL_EGG,
      MODULAR_LABEL_EMBRYO
    }
  },
  props: {
    handleToggleSpecimenType: {
      type: Function,
      required: true
    },
    currentSpecimenType: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.modular-label__tabs {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  user-select: none;
}

.modular-label__tabs .tab {
  padding: 12px 12px 21px 12px;
  background: #C1C5C7;
  color: #00122E;
  border-radius: 4px 4px 0px 0px;
  width: 116px;
  height: 56px;
  margin-right: 8px;
}

.modular-label__tabs .tab:not(.active):hover {
  background: #29FFDA;
}

.modular-label__tabs .tab.active {
  background-color: white;
}
</style>
