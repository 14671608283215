/* eslint-disable */
import * as ss from '@/config/session-storage-help'
import { createTicketsApi } from '@/services/tickets'

const $ss = ss

export default {
  async createTickets({ commit, rootState }, ticketsArray) {
    const {
      appBaseConfig: { clinicId, siteId },
      token
    } = rootState.authModule
    try {
      const response = await createTicketsApi({ clinicId, siteId, ticketsArray, token })
      commit('createTicket', response.data)
      commit('success')
      // commit('resetTicketProcess')
      return response
    } catch (err) {
      commit('error', err)
      throw new Error(err.message)
    }
  },

  setCurrentStep({ commit }, value) {
    commit('setCurrentStep', value)
  },

  resetTicketSteps({ commit }) {
    commit('resetTicketSteps')
  },

  setMethod({ commit }, value) {
    commit('setMethod', value)
  },

  setType({ commit }, value) {
    commit('setType', value)
  },

  setTypeEmbryo({ commit }, value) {
    commit('setTypeEmbryo', value)
  },

  setPatient({ commit }, value) {
    commit('setPatient', value)
  },
  setBatchSharedPatientIds({ commit }, value) {
    commit('setBatchSharedPatientIds', value)
  },

  setProcedureDateFrom({ commit }, date) {
    commit('setProcedureDateFrom', date)
  },

  setProcedureDateTo({ commit }, date) {
    commit('setProcedureDateTo', date)
  },

  setProcedureTime({ commit }, date) {
    commit('setProcedureTime', date)
  },

  resetTicketProcess({ commit }) {
    commit('resetTicketProcess')
    return (
      $ss.setFieldSessionStorage('currentStep', 1, 'newTicket'),
      $ss.setFieldSessionStorage('method', '', 'newTicket'),
      $ss.setFieldSessionStorage('type', '', 'newTicket'),
      $ss.setFieldSessionStorage('typeEmbryo', '', 'newTicket'),
      $ss.removeFieldSessionStorage('lastScannedBeacon'),
      $ss.removeFieldSessionStorage('newBeaconsArray')
    )
  },

  resetCreateTicketProcess({ commit }) {
    commit('resetCreateTicketProcess')
  },
  setBeacons({ commit }, beacons) {
    commit('setBeacons', beacons)
  },
  setSelectedMethod({ commit }, selectedMethod) {
    commit('setSelectedMethod', selectedMethod)
  },
  setCurrentTicketStep({ commit }, currentTicketStep) {
    commit('setCurrentTicketStep', currentTicketStep)
  },
  setSelectedCryoDeviceType({ commit }, selectedCryoDeviceType) {
    $ss.setFieldSessionStorage('cryodeviceSelected', selectedCryoDeviceType),
      commit('setSelectedCryoDeviceType', selectedCryoDeviceType)
  },
  setSelectedSpecimenType({ commit }, selectedSpecimenType) {
    commit('setSelectedSpecimenType', selectedSpecimenType)
  },
  setSelectedPatient({ commit }, selectedPatient) {
    commit('setSelectedPatient', selectedPatient)
  },
  setSource({ commit }, source) {
    commit('setSource', source)
  },
  setMetadata({ commit }, metadata) {
    commit('setMetadata', metadata)
  },
  setRobotTransfer({ commit }, robotTransfer) {
    commit('setRobotTransfer', robotTransfer)
  },
  setShippingSourceId({ commit }, shippingSourceId) {
    commit('setShippingSourceId', shippingSourceId)
  },
  setDonorId({ commit }, donorId) {
    commit('setDonorId', donorId)
  },
  setSelectedTypeEmbryo({ commit }, selectedTypeEmbryo) {
    commit('setSelectedTypeEmbryo', selectedTypeEmbryo)
  },
  setSubType({ commit }, batchType) {
    commit('setSubType', batchType)
  },
  setBatch({ commit }, batch) {
    commit('setBatch', batch)
  }
}
