<template>
  <MainContainer data-test="flight-board" gridSlots="2">
    <top-header>Printers Status</top-header>
    <section class="flex items-center justify-center w-full h-full">
      <div class="w-4/12 flex flex-col justify-center items-center bg-white mx-4 rounded-md">
        <p class="pt-6 pb-8 text-center text-black text-3xl font-bold font-futura">
          Zebra Browser Print
        </p>
        <i
          class="fas fa-print text-5xl"
          :class="[zebraBrowserPrintStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          <span
            class="
              w-6
              h-6
              bg-white
              rounded-full
              block
              flex
              justify-center
              items-center
              absolute
              -mt-4
              ml-6
            "
          >
            <i
              class="fas text-xl"
              :class="[
                zebraBrowserPrintStatus
                  ? 'fa-check-circle text-tmrw-success'
                  : 'fa-exclamation-circle text-tmrw-error',
              ]"
            />
          </span>
        </i>
        <p
          class="text-center text-3xl font-bold pb-4 pt-8"
          :class="[zebraBrowserPrintStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          {{ zebraBrowserPrintStatus ? 'Online' : 'Offline' }}
        </p>
      </div>
      <div class="w-4/12 flex flex-col justify-center items-center bg-white mx-4 rounded-md">
        <p class="pt-6 pb-8 text-center text-black text-3xl font-bold font-futura">
          TMRW Label Printer status
        </p>
        <i
          class="fas fa-print text-5xl"
          :class="[labelPrinterStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          <span
            class="
              w-6
              h-6
              bg-white
              rounded-full
              block
              flex
              justify-center
              items-center
              absolute
              -mt-4
              ml-6
            "
          >
            <i
              class="fas text-xl"
              :class="[
                labelPrinterStatus
                  ? 'fa-check-circle text-tmrw-success'
                  : 'fa-exclamation-circle text-tmrw-error',
              ]"
            />
          </span>
        </i>
        <p
          class="text-center text-3xl font-bold pb-4 pt-8"
          :class="[labelPrinterStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          {{ labelPrinterStatus ? 'Online' : 'Offline' }}
        </p>
      </div>
      <div class="w-4/12 flex flex-col justify-center items-center bg-white mx-4 rounded-md">
        <p class="pt-6 pb-8 text-center text-black text-3xl font-bold font-futura">
          TMRW Ticket Printer status
        </p>
        <i
          class="fas fa-print text-5xl"
          :class="[ticketPrinterStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          <span
            class="
              w-6
              h-6
              bg-white
              rounded-full
              block
              flex
              justify-center
              items-center
              absolute
              -mt-4
              ml-6
            "
          >
            <i
              class="fas text-xl"
              :class="[
                ticketPrinterStatus
                  ? 'fa-check-circle text-tmrw-success'
                  : 'fa-exclamation-circle text-tmrw-error',
              ]"
            />
          </span>
        </i>
        <p
          class="text-center text-3xl font-bold pb-4 pt-8"
          :class="[ticketPrinterStatus ? 'text-tmrw-success' : 'text-tmrw-error']"
        >
          {{ ticketPrinterStatus ? 'Online' : 'Offline' }}
        </p>
      </div>
    </section>
  </MainContainer>
</template>

<script>
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { getPrintersStatus } from '@/modules/printer'
import { PRINTER_READY, ZBP_READY } from '@/constants'
import * as ss from '@/config/session-storage-help'

export default {
  name: 'printers-status',
  data() {
    return {
      labelPrinterStatus: false,
      ticketPrinterStatus: false,
      printersStatusInterval: false,
      zebraBrowserPrintStatus: false
    }
  },
  async mounted() {
    getPrintersStatus()
    this.printersStatusInterval = setInterval(async () => {
      const labelPrinterStatus = ss.getFieldSessionStorage('labelPrinterStatus')
      const ticketPrinterStatus = ss.getFieldSessionStorage('ticketPrinterStatus')
      const zebraBrowserPrintStatus = ss.getFieldSessionStorage('zebraBrowserPrintStatus')
      if (labelPrinterStatus === PRINTER_READY) this.labelPrinterStatus = true
      if (ticketPrinterStatus === PRINTER_READY) this.ticketPrinterStatus = true
      if (zebraBrowserPrintStatus === ZBP_READY) this.zebraBrowserPrintStatus = true
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.printersStatusInterval)
  },
  components: {
    TopHeader,
    MainContainer
  }
}
</script>
