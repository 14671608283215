<template>
  <div
    class="fixed w-screen h-screen top-0 left-0 z-100000 flex justify-center items-center bg-black bg-opacity-50"
  >
    <div class="bg-white px-4 pt-4 pb-8 rounded-lg">
      <div class="text-tmrw-blue py-6 px-12">
        <i class="fas fa-exclamation-triangle text-4xl mr-4" />
        <span class="text-4xl"
          >Due to inactivity you will be signed out in {{ timer }} seconds.</span
        >
      </div>
      <div class="modal__actions px-12">
        <button-dynamic
          btnText="Sign me out"
          fontAwesomeIconClass="door-open"
          showIcon
          floatTo="none"
          btnType="button"
          btnStyle="secondary"
          @click="handleSignOut"
        />
        <button-dynamic
          btnText="Keep me signed in"
          fontAwesomeIconClass="sign-in-alt"
          showIcon
          btnType="button"
          btnStyle="primary"
          @click="keepMeSignedIn"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import * as workerTimers from 'worker-timers'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { MODAL_SIGNOUT_TIMEOUT } from '@/constants'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const emit = defineEmits(['closeModal'])
const props = defineProps(['status'])

const time = ref(MODAL_SIGNOUT_TIMEOUT * 60 * 1000)
const timerInterval = ref()

const timer = computed(() => {
  const minutes = Math.floor(time.value / 60000)
  const seconds = (time.value % 60000) / 1000
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds.toFixed(0)}`
})

const handleSignOut = () => {
  emit('closeModal', false)
}
const keepMeSignedIn = () => {
  clear()
  emit('closeModal', true)
}

const clear = () => {
  if (timerInterval.value) {
    workerTimers.clearInterval(timerInterval.value)
    timerInterval.value = null
  }
}

onMounted(() => {
  timerInterval.value = workerTimers.setInterval(() => {
    time.value -= 1000
    if (!props.status) {
      clear()
    }
    if (time.value < 1) {
      clear()
      emit('closeModal', false)
    }
  }, 1000)
})

onBeforeUnmount(() => {
  clear()
})
</script>
<style lang="scss">
.modal {
  border-radius: 4px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.19);
  display: block;
  padding: 42px 80px;

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1600px) {
  .modal {
    padding: 36px 70px;

    &__title {
      margin-bottom: 40px;

      i {
        font-size: 36px;
        margin-right: 24px;
      }

      &--text {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__actions {
      &--btn {
        font-size: 34px;
        padding: 8px 36px;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .modal {
    padding: 30px 60px;

    &__title {
      margin-bottom: 30px;

      i {
        font-size: 30px;
        margin-right: 24px;
      }

      &--text {
        font-size: 20px;
        line-height: 25px;
      }
    }

    &__actions {
      &--btn {
        font-size: 28px;
        padding: 8px 32px;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .modal {
    padding: 26px 50px;

    &__title {
      margin-bottom: 20px;

      i {
        font-size: 25px;
        margin-right: 20px;
      }

      &--text {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__actions {
      &--btn {
        font-size: 24px;
        padding: 8px 28px;
      }
    }
  }
}
</style>
