<template>
  <div class="mt-4">
    <p class="p-4 text-xl text-white leading-6">Please enter a reason:</p>
    <div data-test="other-reason-options" class="flex w-1/2">
      <div class="w-full" v-for="(item, index) in items" :key="index">
        <div class="text-xl text-white leading-6 bg-tmrw-blue-light mx-4">
          <input
            data-testid="other-reason-option"
            data-test="other-reason-option"
            class="mr-1 p-4 text-tmrw-blue-light w-full"
            v-model="otherReason"
            @input="syncData($event)"
            name="other"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  OTHER
} from '@/constants'

export default {
  name: 'other',
  data() {
    return {
      otherReason: '',
      items: [OTHER]
    }
  },
  emits: ['dataSync'],
  methods: {
    syncData(event) {
      this.$emit('dataSync', { [event.target.name]: this.otherReason })
    }
  }
}
</script>
