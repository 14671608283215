<template>
  <div>
    <Datepicker
      v-if="isRange"
      data-test="date-picker"
      class="date-picker-range"
      inline
      autoApply
      :modelValue="date"
      @update:modelValue="handleDate"
      :enableTimePicker="false"
      :minDate="minDate"
      :maxDate="maxDate"
      multiCalendars
      range
      @internalModelChange="internalChange"
    />
    <Datepicker
      v-else-if="isNow"
      data-test="date-picker-now"
      class="date-picker"
      inline
      autoApply
      :modelValue="date"
      @update:modelValue="handleDate"
      :enableTimePicker="false"
      :minDate="minDate"
      :maxDate="maxDate"
    />
    <Datepicker
      v-else
      data-test="date-picker-single"
      class="date-picker"
      inline
      autoApply
      :modelValue="date"
      @update:modelValue="handleDate"
      :enableTimePicker="false"
      :minDate="minDate"
      :maxDate="maxDate"
    />
  </div>
</template>
<script setup>
import { ref, nextTick, computed, watch } from 'vue'
import { CALENDAR_MODE_NOW, CALENDAR_MODE_RANGE } from '@/constants'

const props = defineProps({
  minDate: {
    type: Date,
    default() {
      return new Date()
    }
  },
  maxDate: {
    type: Date,
    default() {
      return null
    }
  },
  mode: {
    type: String,
    default() {
      return CALENDAR_MODE_NOW
    }
  },
  value: {
    type: Date,
    default() {
      return new Date()
    }
  }
})

const date = ref(new Date())
const isNow = computed(() => props.mode === CALENDAR_MODE_NOW)
const isRange = computed(() => props.mode === CALENDAR_MODE_RANGE)

const emit = defineEmits(['onCalendarDateChange'])

let endDate

watch(
  () => props.mode,
  async (currentProps) => {
    date.value = new Date()
    if (currentProps === CALENDAR_MODE_NOW) {
      await nextTick()
      const todayChild = document.querySelector('.dp__today')
      if (todayChild) {
        todayChild.classList.add('dp__active_date')
        const today = todayChild.closest('div.dp__calendar_item')
        today.setAttribute('aria-selected', true)
      }
    }

    if (currentProps === CALENDAR_MODE_RANGE) {
      const startDate = new Date()
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7))
      date.value = [startDate, endDate]
    }
  }
)

const addStyleSelected = async () => {
  const color = 'rgba(211, 255, 246, var(--tw-bg-opacity))'

  endDate?.removeAttribute('style')

  await nextTick()
  const endDateChild = document.querySelector('.dp__range_end')

  if (endDateChild) {
    endDate = endDateChild.closest('div.dp__calendar_item')
    endDate.style.background = `linear-gradient(to right, ${color} 0%, ${color} 50%, white 50%, white 100% )`
  }
}

const handleDate = (modelData) => {
  date.value = modelData
  if (isRange.value) {
    const [start, end] = modelData
    const selectedDate = { start, end }
    emit('onCalendarDateChange', selectedDate)
  } else {
    emit('onCalendarDateChange', modelData)
  }
}

const internalChange = () => {
  if (isRange.value) {
    addStyleSelected()
  }
}
</script>
<style lang="scss">
.date-picker-range {
  div[aria-selected='true'] {
    background: linear-gradient(
      to right,
      white 0%,
      white 50%,
      rgba(211, 255, 246, var(--tw-bg-opacity)) 50%,
      rgba(211, 255, 246, var(--tw-bg-opacity)) 100%
    );
  }
}
.date-picker,
.date-picker-range {
  border: none;

  .dp__flex_display > .dp__instance_calendar:nth-of-type(1) {
    margin-right: 20px;
  }

  .vc-pane:start-child {
    @apply mr-8;
  }

  .dp__month_year_select {
    @apply font-inconsolata;
    @apply text-tmrw-blue;
    @apply font-semibold;
  }

  .dp__today {
    border: none;
  }

  .dp__pointer {
    @apply mt-0;
    @apply font-inconsolata;

    &.dp__active_date,
    &.dp__range_start,
    &.dp__range_end {
      @apply text-white;
      @apply bg-tmrw-blue;
      @apply rounded-full;
      border: none;
      z-index: 2;
    }

    &.dp__range_between::after {
      content: '';
      height: 15px;
      width: 15px;
      background-color: green;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }

    &.dp__range_between {
      background-color: rgba(211, 255, 246, var(--tw-bg-opacity));
      @apply text-tmrw-blue;
    }

    &.dp__today {
      border: none;
    }

    &.dp__date_hover:hover {
      @apply bg-tmrw-green;
      @apply text-tmrw-blue;
      @apply rounded-full;
    }
  }

  .dp__calendar {
    @apply m-0;
    @apply relative;

    &_header_item {
      @apply bg-transparent;
      @apply text-tmrw-gray;
      @apply border;
      @apply border-tmrw-gray;
      @apply font-normal;
    }

    &--item {
      @apply bg-transparent;
      @apply text-tmrw-gray;
      @apply border;
      @apply border-tmrw-gray;
      @apply font-normal;
    }
  }
}
</style>
