<template>
  <section>
    <reasons
      :items="items"
      @isSelected="isSelected"
      @syncData="syncData"
    />
    <div v-if="reason === other_value">
      <Other
        :otherReason="otherReason"
        @syncData="syncOtherReason"
      />
    </div>
  </section>
</template>
<script lang="ts">
import {
  OTHER,
  OTHER_VALUE
} from '@/constants'
import Reasons from './Reasons/Reasons.vue'
import Other from './Other/Other.vue'
import { ReasonTypes } from './interfaces/index'

export default {
  name: 'cancel-reason',
  components: {
    Reasons,
    Other
  },
  props: {
    isValid: {
      type: [Function],
      required: true
    }
  },
  data() {
    return {
      reason: '' as string | undefined,
      otherReason: '' as string,
      items: [
        { label: 'Clerical error', value: 'Clerical error' },
        { label: 'Clinician decision', value: 'Clinician decision' },
        { label: 'Embryologist decision', value: 'Embryologist decision' },
        { label: OTHER, value: OTHER_VALUE }
      ] as Object[],
      other_value: OTHER_VALUE
    }
  },
  methods: {
    syncData(this: ReasonTypes['this'], data: string | undefined): void {
      this.reason = data
      this.isValid(this.reason)
    },
    syncOtherReason(this: ReasonTypes['this'], otherReason: string): void {
      this.otherReason = otherReason
      this.isValid(this.otherReason)
    },
    isSelected(this: ReasonTypes['this'], data: string): Boolean {
      return this.reason === data
    }
  }
}
</script>
