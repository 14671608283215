<template>
  <div class="custom-select">
    <CustomSelect
      :modelValue="value"
      @update:modelValue="input"
      :allowEmpty="false"
      class="fa w-full"
      :options="options"
    />
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'

export default {
  name: 'scroll-table-select-field',
  emits: ['valueFromCustomFieldChanged'],
  props: {
    value: {
      type: [String, Number, Object],
      required: false
    },
    options: {
      type: [Array, Object],
      required: true
    },
    columnOptions: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    input(value) {
      this.$emit('valueFromCustomFieldChanged', value, this.columnOptions, this.options)
    }
  },
  components: {
    CustomSelect
  }
}
</script>
