<template>
  <MainContainer data-test="how-many" gridSlots="3">
    <top-header>
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="how-many__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <dynamic-title
          titleType="h2"
          floatTo="left"
        >Place the {{ CryoBeacon }} in the Registration Tray</dynamic-title
        >
      </template>
      <template v-slot:right-side>
        <!-- Next to final ticket Preview -->
        <button-dynamic
          data-test="next-btn"
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isBeaconValid"
          @click="onClickNext"
        />
      </template>
    </ActionBar>
    <div class="scan-beacon">
      <new-cryoBeacon />
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import NewCryoBeacon from '@/components/NewCryoBeacon/NewCryoBeacon.vue'
import { CRYOBEACON } from '@/constants'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'

export default {
  name: 'scan-beacon-donation',
  data() {
    return {
      isPrintBeaconLabelModalVisible: false,
      CryoBeacon: CRYOBEACON,
      isBeaconValid: false
    }
  },
  computed: {
    ...mapGetters('beaconsModule', ['beaconBarcodeNumber'])
  },
  methods: {
    ...mapActions('beaconsModule', [
      'checkBeaconId',
      'setBeaconIdCheckMessage',
      'setSingleBeaconReaderReads'
    ]),
    onClickNext() {
      this.setSingleBeaconReaderReads(this.beaconBarcodeNumber)
      this.$router.push('/final-ticket')
    }
  },
  components: {
    TopHeader,
    MainContainer,
    DynamicTitle,
    ActionBar,
    ButtonDynamic,
    NewCryoBeacon,
    HeaderInfo
  }
}
</script>

<style lang="scss">
.single-beacon-reader-img {
  max-height: 500px;
}
</style>
