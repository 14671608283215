import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { timestampToUTCFilter } from '@/filters/index'
import {
  SPECIMEN_TYPE_OOCYTE,
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  MAX_LABEL_LENGTH
} from '@/constants'

dayjs.extend(utc)

export const embryo = [
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-36', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    }
  },
  {
    name: 'Count x Type',
    key: 'specimenCount',
    cssClasses: ['w-32'],
    filters: [
      (value, item) =>
        `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ]
  },
  {
    name: 'Embryo #',
    key: 'embryo',
    cssClasses: ['w-24'],
    filters: [(value) => value?.embryoNumber]
  },
  {
    name: 'Grade',
    filters: [(value) => (value ? value.grade : '')],
    cssClasses: ['w-20'],
    key: 'embryo'
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    cssClasses: ['w-40']
  },
  {
    name: 'Donor ID',
    key: 'sources',
    sort: false,
    cssClasses: ['w-32'],
    filters: [(sources) => sources[0]?.donorId]
  },
  {
    name: 'Shipping Source',
    key: 'sources',
    sort: false,
    cssClasses: ['w-56'],
    filters: [(sources) => sources[0]?.shippingSourceId],
    component: 'shippingSource'
  },
  {
    name: 'Biopsy',
    filters: [(value) => (value ? value.biopsy : '')],
    key: 'embryo',
    cssClasses: ['w-20']
  },
  {
    name: 'Result',
    key: 'embryo.biopsyResult',
    sort: true,
    component: 'tooltip',
    cssClasses: ['w-24'],
    componentOptions: {
      maxlength: 10
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10,
      displayDotsIcon: false
    },
    cssClasses: ['w-40']
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    sort: true,
    cssClasses: ['w-30'],
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  }
]

export const egg = [
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-36', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    }
  },
  {
    name: 'Count x Type',
    filters: [(value, item) => `${value} x ${item.oocyte.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE}`],
    key: 'specimenCount',
    cssClasses: ['w-32']
  },
  {
    name: 'Maturity Level',
    key: 'oocyte',
    filters: [(value) => (value && value ? value.maturityLevel : '')],
    sort: true,
    cssClasses: ['w-40']
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    cssClasses: ['w-48']
  },
  {
    name: 'Donor ID',
    key: 'sources',
    sort: false,
    cssClasses: ['w-32'],
    filters: [(sources) => sources[0]?.donorId]
  },
  {
    name: 'Shipping Source',
    key: 'sources',
    sort: false,
    cssClasses: ['w-56'],
    filters: [(sources) => sources[0]?.shippingSourceId],
    component: 'shippingSource'
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      maxlength: 10,
      alignment: 'left'
    },
    cssClasses: ['w-56']
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    sort: true,
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    },
    cssClasses: ['w-30']
  }
]

export const sourcesValueHeader = {
  name: '',
  key: 'sourcesValue',
  component: 'customTooltip',
  alignment: 'center',
  cssClasses: ['w-12', 'max-w-40'],
  componentOptions: {
    alignment: 'left',
    displayDotsIcon: false,
    tooltipTableHeaders: [
      {
        title: 'Imported',
        key: 'imported'
      },
      {
        title: 'Current label ID',
        key: 'currentLabelId'
      }
    ],
    imageWidth: 'w-7',
    imageHeight: 'h-7'
  }
}
