import FileSaver from 'file-saver'
import dayjs from 'dayjs'
import toast from '@/config/toast'
import {
  getBeaconIdFromBarcode,
  getCountriesApi,
  getDownloadJobStatusApi,
  getExternalClinicsApi,
  getJobStatusApi,
  getPatientByCpIdApi,
  getPatientCryoDevicesApi,
  getPatientsApi,
  getSpecimensApi,
  patchUpdatePatientApi,
  patchUpdateScreeningStatusApi,
  postCreateExternalClinicApi,
  postUploadApi
} from '@/services/patients'

import { getTicketsByPatientId, getMissingTicketsByPatientId } from '@/services/tickets'

export default {
  async fetchPatients({ commit, rootState }, searchValues) {
    const { timestamp, searchPatientBy, searchPatientValue } = searchValues
    let params
    const { appBaseConfig } = rootState.authModule
    if (!searchValues.timestamp) {
      const newTimestamp = dayjs().valueOf()
      commit('setPatientTimestamp', newTimestamp)
    }
    switch (searchPatientBy) {
      // Search by Patient Identification Number
      case 'identificationNumber':
        params = { cb: timestamp, identificationNumber: searchPatientValue }
        break
      // Search by Patient Last Name
      case 'lastName':
        params = { cb: timestamp, lastName: searchPatientValue }
        break
      default:
        params = {}
    }

    commit('loading')

    try {
      const patientsResponse = await getPatientsApi({ ...appBaseConfig, params })
      commit('fetchPatients', patientsResponse.data)
      commit('success')
    } catch (err) {
      commit('error', err)
      toast.error({ title: err.message })
      throw new Error(err.message)
    }
  },

  resetState({ commit }) {
    commit('resetState')
  },

  async searchPatients({ commit, rootState }, params) {
    const { appBaseConfig } = rootState.authModule
    commit('loading')

    return getPatientsApi({ ...appBaseConfig, params })
  },

  async fetchPatient({ commit, rootState }, id) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    commit('loading')

    try {
      const response = await getPatientByCpIdApi({ id, collectionProtocolId })
      const { data } = response
      commit('fetchPatient', data)
      commit('error', null)
      commit('success')
      return data
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },

  async postUpload({ commit, rootState }, formData) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    commit('loading')

    formData.append('cpId', collectionProtocolId)
    try {
      const uploads = await postUploadApi({
        formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      const { data } = uploads

      commit('fetchUpload', data)
      commit('error', null)
      commit('success')
      return data
    } catch (error) {
      error.response?.data.forEach((item) => {
        toast.custom('error', 'error', item.message)
        toast.custom('error', 'error', 'The content of the file is empty or has invalid data.')
        commit('error', item.message)
        throw new Error(item.message)
      })
      return error
    }
  },

  async checkJobStatus({ commit, rootState }, jobId) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    commit('loading')

    try {
      const response = await getJobStatusApi({ jobId, collectionProtocolId })
      const jobStatusResponse = { ...response.data, jobId }
      commit('error', null)
      commit('success')
      commit('setJobStatus', jobStatusResponse)
      return jobStatusResponse
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },

  async getPatientsUploadStatus({ commit }, data) {
    const { status, jobId, filename } = data

    if (status === 'FAILED') {
      try {
        const response = await getDownloadJobStatusApi({ jobId })
        commit('success')
        FileSaver.saveAs(response.data, filename || 'result.csv')
      } catch (error) {
        commit('error', error)
        toast.warn('Error trying to get the document with the detailed information.')
        throw new Error(error.message)
      }
    }
    if (status === 'COMPLETED') {
      commit('success')
    }
  },

  async getExternalClinics({ commit }) {
    try {
      const clinicsResponse = await getExternalClinicsApi()
      commit('getExternalClinics', clinicsResponse.data)
      return clinicsResponse.data
    } catch (error) {
      commit('error', error)
      toast.warn('Error trying to get clinics.')
      return error
    }
  },

  async createExternalClinic({ commit }, data) {
    commit('loading')
    try {
      const clinicsResponse = await postCreateExternalClinicApi(data)
      commit('success')
      commit('setCreatedClinic', clinicsResponse.data)
    } catch (error) {
      commit('error', error)
      toast.warn('Error trying to get clinics.')
    }
  },

  async setJobStatusDefault({ commit }) {
    commit('setDefaultJobStatus')
  },

  setDonorId({ commit }, donorId) {
    commit('setDonorId', donorId)
  },

  setShippingSource({ commit }, shippingSource) {
    commit('setShippingSource', shippingSource)
  },

  setNewClinicName({ commit }, clinicName) {
    commit('setNewClinicName', clinicName)
  },

  setNewClinicAddress({ commit }, clinicAddress) {
    commit('setNewClinicAddress', clinicAddress)
  },

  async fetchCountries({ commit }) {
    commit('loading')
    try {
      const response = await getCountriesApi()
      commit('setCountries', response.data)
      commit('success')
    } catch (error) {
      commit('error', error)
      throw new Error(error.message)
    }
  },

  async fetchSpecimensFromPatient(
    { commit },
    { id, onlyInRobot = false, includeClosed = true, onlyInClinic }
  ) {
    commit('loading')
    try {
      const response = await getSpecimensApi({
        id,
        onlyInRobot,
        includeClosed,
        onlyInClinic
      })
      await commit('fetchSpecimens', response.data)
      commit('success')
      return response.data
    } catch (error) {
      commit('error')
      toast.error({ title: error.message })
      return error
    }
  },

  async fetchClosedSpecimensFromPatient(
    { commit },
    { id, onlyInRobot = false, includeClosed = true }
  ) {
    commit('loading')
    try {
      const response = await getSpecimensApi({
        id,
        onlyInRobot,
        includeClosed,
        onlyClosed: true
      })
      commit('fetchClosedSpecimens', response.data)
      commit('success')
    } catch (error) {
      commit('error')
      toast.error({ title: error.message })
    }
  },

  async fetchPatientInventory({ commit, rootState }, patientId) {
    const {
      appBaseConfig: { clinicId, collectionProtocolId }
    } = rootState.authModule
    commit('loading')

    try {
      // Get Patient CryoDevices
      const cryoDevices = await getPatientCryoDevicesApi({ patientId, collectionProtocolId })

      // Get Patient existing Tickets
      const getPatientTickets = await getTicketsByPatientId({
        clinicId,
        collectionProtocolId,
        patientId
      })

      // Update cryodevices object to contain boolean for Outstanding Tickets
      const cryoDevicesData = cryoDevices.data.map((cryoDevice) => {
        const getOutstandingTicketsValue = getPatientTickets.data.filter(
          (ticket) => ticket.beaconBarcode === cryoDevice.beaconBarcode
        )
        const disabled = !!getOutstandingTicketsValue.length
        return { ...cryoDevice, disabled }
      })
      commit('fetchPatientInventory', cryoDevicesData)
      commit('error', null)
      commit('success')
      return cryoDevicesData
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },

  async editPatient({ commit, rootState }, params) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    const { globalPatientNumber } = params
    commit('loading')

    try {
      const response = await patchUpdatePatientApi({
        params,
        globalPatientNumber,
        collectionProtocolId
      })
      commit('fetchPatient', response.data)
      commit('success')
      toast.success('Patient Updated Successfully')
      return response.data
    } catch (error) {
      const errorMessage =
        error.response?.data[0].message ||
        'Unexpected error occurred. please try again later or contact your system administrator'
      toast.custom('error', 'error', errorMessage)
      commit('error', errorMessage)
      throw new Error(error.message)
    }
  },

  async updateScreeningStatus({ commit, rootState }, { specimens, beaconId, patient }) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    commit('loading')

    try {
      const response = await patchUpdateScreeningStatusApi({
        beaconId,
        collectionProtocolId,
        specimens,
        patient
      })
      commit('updateScreeningStatus', response.data)
      commit('success')
      return response.data
    } catch (err) {
      err.response.data &&
        err.response.data.forEach((item) => {
          toast.custom('error', 'error', item.message)
          commit('error', item.message)
        })
      throw new Error(err.message)
    }
  },

  async fetchOpenTicketsFromPatient(
    { commit, rootState },
    { patientId, includeAllSites = false, includeChildTickets = false }
  ) {
    const {
      appBaseConfig: { clinicId, collectionProtocolId }
    } = rootState.authModule
    commit('loading')
    try {
      const openTicketsFromPatients = await getTicketsByPatientId({
        clinicId,
        collectionProtocolId,
        includeAllSites,
        includeSharedTickets: true,
        patientId,
        includeChildTickets
      })
      const response = openTicketsFromPatients.data

      commit('fetchOpenTicketsFromPatient', response)
      commit('error', null)
      commit('success')
      return response
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      return error
    }
  },

  async fetchMissingTicketsFromPatient({ commit, rootState }, patientId) {
    try {
      const {
        appBaseConfig: { clinicId, collectionProtocolId }
      } = rootState.authModule
      commit('loading')
      const missingTicketsFromPatients = await getMissingTicketsByPatientId({
        clinicId,
        collectionProtocolId,
        includeSharedTickets: true,
        patientId
      })
      const response = missingTicketsFromPatients.data
      commit('fetchMissingTicketsFromPatient', response)
      commit('error', null)
      commit('success')
      return response
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      return error
    }
  },

  async getBeaconIdFromBarcode({ commit }, { beaconBarcode, unitId }) {
    commit('loading')
    try {
      const response = await getBeaconIdFromBarcode({ unitId, beaconBarcode })
      commit('getBeaconIdFromBarcode', response.data)
      commit('error', null)
      commit('success')
      return response.data
    } catch (error) {
      commit('error', error)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },

  fetchDefaultSpecimens({ commit }) {
    commit('fetchDefaultSpecimens')
  },

  setPatient({ commit }, patient) {
    if (patient && patient.id) {
      commit('setPatient', patient)
    }
  },

  setDonatePatient({ commit }, patient) {
    commit('setDonatePatient', patient)
  },

  setPatients({ commit }, response) {
    commit('searchPatients', response.data)
    commit('success')
  },

  setPatientsEmpty({ commit }) {
    commit('setPatientsEmpty')
  },

  setPatientInventory({ commit }, patientInventory) {
    commit('fetchPatientInventory', patientInventory)
  },

  setPatientTimestamp({ commit }, patientTimestamp) {
    commit('setPatientTimestamp', patientTimestamp)
  }
}
