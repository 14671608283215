import { createApp } from 'vue'

// Import Elastic APM
import { init as initApm } from '@elastic/apm-rum'
import mixpanel from 'mixpanel-browser'
import vfmPlugin from 'vue-final-modal'
import Notifications from '@kyvg/vue3-notification'
import Vue3Sanitize from 'vue-3-sanitize'
import Datepicker from '@vuepic/vue-datepicker'
import * as Sentry from '@sentry/vue'
import * as ss from '@/config/session-storage-help'
import * as constants from '@/constants'
import { onlyNumberDirective } from '@/directives'
import i18n from '@/locales/i18n'
import { replaceStringFilter } from '@/filters'
import store from './store'
import router from './router'
import App from './App.vue'

// Import CSS Libraries
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/tailwind.css'
import '@/assets/fonts/fonts.css'
import '@/scss/_main.scss'

// declaring types coming inside Vue Component
// @ts-ignore
declare module 'vue/types/vue' {
  interface Vue {
    checkLoading: boolean
  }
}

declare global {
  interface Window {
    VITE_NAMESPACE: string
    VUE_APP_NAMESPACE: string
  }
}
/* ***************************************************************
Error Handler
docs: https://www.raymondcamden.com/2019/05/01/handling-errors-in-vuejs
*************************************************************** */
export const app = createApp(App as any)

// Init Mixpanel
const initMixpanel = async () => {
  const isProductionEnv = window.location.hostname === 'sms.tmrw.org'
  const mixpanelToken = isProductionEnv
    ? '1652163250f83203a7624f035979c0d4'
    : '5fd33f6dc33f63f7bf66469ebb8fa272'
  mixpanel.init(mixpanelToken, { debug: !isProductionEnv })
  app.config.globalProperties.$mixpanel = mixpanel
  app.provide('mixpanel', mixpanel)
}

const getEnvironment = () => {
  // fallback to VUE_APP_NAMESPACE until prod is updated
  const viteNamespace =
    window.VITE_NAMESPACE || window.VUE_APP_NAMESPACE || 'dev'
  let sentryEnvironment
  let dsn
  switch (viteNamespace) {
    case 'staging':
      dsn =
        'https://30111ff9c330e0666ec158990fd5f0e6@o4504888360108032.ingest.sentry.io/4504928302727168'
      sentryEnvironment = viteNamespace
      break
    case 'prod':
      dsn =
        'https://b8792759d2a646066270aa3a8a30279d@o4504888360108032.ingest.sentry.io/4504928302727168'
      sentryEnvironment = viteNamespace
      break
    case 'beta':
      dsn =
        'https://8359764286641cf84e25ee0831baab20@o4504888360108032.ingest.sentry.io/4504928302727168'
      sentryEnvironment = viteNamespace
      break
    default:
      dsn =
        'https://ce5e665feb3446bc81104550583d6a95@o4504888360108032.ingest.sentry.io/4504928302727168'
      sentryEnvironment = 'dev'
      break
  }

  // eslint-disable-next-line no-console
  console.info(
    'Running on namespace:',
    viteNamespace,
    ', sentry environment',
    sentryEnvironment
  )
  return { sentryEnvironment, viteNamespace, dsn }
}

// Elastic APM Setup

const initSentry = () => {
  const { sentryEnvironment, viteNamespace, dsn } = getEnvironment()
  Sentry.init({
    app,
    dsn,
    environment: sentryEnvironment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['[a-zA-Z-.].cryogenics.life', /^\//]
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: sentryEnvironment === 'prod' ? 1.0 : 0
    // debug: true
  })
  Sentry.setTag('namespace', viteNamespace)
  Sentry.setTag('appVersion', store.getters['appInfoModule/appVersion'])
}

const initApp = async () => {
  initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'clinic-ui',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl:
      'https://78159f44324f423997aaf30da9e1c46d.apm.us-east1.gcp.elastic-cloud.com:443',

    // Set service version (required for sourcemap feature)
    serviceVersion: import.meta.env.VITE_VERSION,

    // Environment name comes from config.js, which is generated by helm charts during the deploy
    environment: window.VITE_NAMESPACE,

    active: false
  })
  initMixpanel()
  initSentry()
  app.config.globalProperties.productionTip = false

  app.config.globalProperties.$filters = {
    replaceStringFilter
  }
  app.config.globalProperties.$constants = constants
  app.config.globalProperties.$ss = ss
  app.config.errorHandler = (err: any, vm, info) => {
    /* eslint no-console: ['error', { allow: ['warn', 'error'] }] */
    // eslint-disable-next-line no-console
    console.error('Error:', err, vm, info)
    Sentry.captureException(err)
    // if there is a loader this action will hide the loader
    // @ts-ignore
    if (vm.checkLoading) {
      const temp = vm
      // @ts-ignore
      temp.checkLoading = false
    }
  }
  const _i18n = await i18n()
  app.component('Datepicker', Datepicker)
  app.use(_i18n)
  // @ts-ignore
  app.use(vfmPlugin)
  app.use(Notifications)
  app.use(Vue3Sanitize)
  // @ts-ignore
  app.directive('only-number', onlyNumberDirective)

  app.use(router)
  app.use(store)
  await router.isReady()
  await enableMocking()
  app.mount('#app')
}

async function enableMocking() {
  // ENABLE MOCK API SERVER IF NEEDED
  // if (process.env.NODE_ENV === 'development') {
  //   const { worker } = await import('@/mocks/browser')
  //   return worker.start()
  // }
  return Promise.resolve()
}

initApp()
