import toast from '@/config/toast'
import { CRYOBEACON, EXISTING_BEACON_STEP, EXISTING_BEACON_NEW_BEACON } from '@/constants'
import * as ss from '@/config/session-storage-help'
import { getCheckBeaconIdApi, getBeaconsApi, getBeaconSpecimens } from '@/services/beacons'
import { isFeatureEnabled, ADD_TO_EXISTING_BEACON_ENABLED_FF } from '@/helpers/featureFlags'

export default {
  setBeaconIdCheckMessage({ commit }, message) {
    commit('setBeaconIdCheckMessage', message)
  },
  setSingleBeaconReaderReads({ commit }, beaconId) {
    const lastScannedBeacon = ss.getFieldSessionStorage('lastScannedBeacon')
    const newTicketBeacons = ss.getFieldSessionStorage('newTicket', 'beacons')
    let beaconsArray = ss.getFieldSessionStorage('newBeaconsArray')
    if (beaconsArray?.length) {
      beaconsArray = JSON.parse(beaconsArray)
    } else {
      beaconsArray = []
    }
    if (beaconsArray.indexOf(beaconId) === -1 && newTicketBeacons && newTicketBeacons.length) {
      const activeBeaconIndex = newTicketBeacons.findIndex((beacon) => beacon.active)
      beaconsArray[activeBeaconIndex] = beaconId
      ss.setFieldSessionStorage('newBeaconsArray', JSON.stringify(beaconsArray))
    }
    commit('setSingleBeaconReaderReads', lastScannedBeacon)
  },
  changeBeaconStep({ commit }, step) {
    commit('setBeaconStep', step)
  },
  setBeaconBarcode({ commit }, beaconBarcode) {
    commit('setBeaconValid', beaconBarcode)
  },
  async checkBeaconId({ commit, rootState }, args) {
    const {
      appBaseConfig: { collectionProtocolId }
    } = rootState.authModule
    const [barcode, containerType] = args

    return getCheckBeaconIdApi({ containerType, barcode, cpId: collectionProtocolId })
      .then((response) => {
        commit('setBeaconIdValidate', response.data.status !== 'INVALID')
        if (response.data.status === 'INVALID') {
          if (response.data.errors[0] === 'Barcode not found in mapping file') {
            const message = `Invalid ID, please use a different ${CRYOBEACON}`

            commit('setBeaconIdCheckMessage', message)
            commit('checkBeaconId', message)
          } else {
            const typeCodeLabel = containerType === 'Cryodevice' ? 'ID' : CRYOBEACON
            const message = `This ${typeCodeLabel} already exists, please use a new ${typeCodeLabel}`

            commit('setBeaconIdCheckMessage', message)
            commit('checkBeaconId', message)
          }
        } else {
          commit('checkBeaconId', '')
          commit('setBeaconIdCheckMessage', '')
        }
        return Promise.resolve(response)
      })
      .catch((err) => {
        commit('setBeaconIdValidate', false)
        commit('setBeaconIdCheckMessage', err.message)
        commit('setStatus', 'error')
        toast.error({ title: err.message })
        throw new Error(err.message)
      })
  },
  async fetchBeaconsSpecimens({ commit }, { beacons }) {
    const fetchBeaconSpecimens = async (beacon) => {
      const { barcode, containerId: beaconId } = beacon
      const response = await getBeaconSpecimens({ beaconId })
      return { barcode, specimens: response.data }
    }

    try {
      const promisses = beacons.map((beacon) => fetchBeaconSpecimens(beacon))
      const data = await Promise.all(promisses)
      commit('setBeaconsSpecimens', data)
    } catch (err) {
      toast.error({ title:'Error loading specimens'})
    }
  },
  async getBeacons({ commit }, params) {
    const { screeningStatus, patientId, numCryodevices, numCryoBeacons } = params
    try {
      if (isFeatureEnabled(ADD_TO_EXISTING_BEACON_ENABLED_FF)) {
        const responseBeacons = await getBeaconsApi({ patientId, screeningStatus })
        const beacons = responseBeacons.data
        const isLength = beacons && beacons.length
        const availableBeacons = beacons.filter((item) => !item.reason)
        const isCrydevicesAvailables = availableBeacons.filter(
          (item) => item.freePositions >= numCryodevices
        ).length
        const beaconStep =
          isLength && isCrydevicesAvailables > 0 && numCryoBeacons === 1
            ? EXISTING_BEACON_STEP
            : EXISTING_BEACON_NEW_BEACON
        commit('finallyBeacons')
        commit('setBeaconStep', beaconStep)
        commit('setBeacons', beacons)
        return beaconStep
      } else {
        commit('setBeaconStep', EXISTING_BEACON_NEW_BEACON)
        return EXISTING_BEACON_NEW_BEACON
      }
    } catch (error) {
      commit('setStatus', 'error')
      commit('setBeaconStep', EXISTING_BEACON_NEW_BEACON)
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  },
  selectBeacon({ commit }, beacon) {
    commit('selectBeacon', beacon)
  },
  resetBeaconReaderReads({ commit }) {
    commit('resetBeaconReaderReads')
  }
}
