/* eslint-disable */
import * as ss from '@/config/session-storage-help'

const $ss = ss

export default {
  fetchLogin(state, { token, loggedUserInfo, appBaseConfig }) {
    state.status = 'success'
    state.token = token
    state.loggedUserInfo = loggedUserInfo
    state.appBaseConfig = appBaseConfig
  },

  logout(state) {
    state.status = null
    state.token = null
    state.tokenExpiresAt = null
    $ss.clear()
  },

  setTokenExpiresAt(state, tokenExpiresAt) {
    state.tokenExpiresAt = tokenExpiresAt
  }
}
