import { getFieldSessionStorage } from '@/config/session-storage-help'

export default {
  status: null,
  token: getFieldSessionStorage('user', 'token') || null,
  tokenExpiresAt: getFieldSessionStorage('user', 'tokenExpiresAt') || null,
  appBaseConfig: {
    siteId: getFieldSessionStorage('config', 'siteId') || null,
    clinicId: getFieldSessionStorage('config', 'clinicId') || null,
    siteName: getFieldSessionStorage('config', 'siteName') || null,
    clinicName: getFieldSessionStorage('config', 'clinicName') || null,
    collectionProtocolId: getFieldSessionStorage('config', 'collectionProtocolId') || null,
    unitIds: getFieldSessionStorage('config', 'unitIds') || null
  },
  loggedUserInfo: {
    userId: getFieldSessionStorage('user', 'userId') || null,
    loginName: getFieldSessionStorage('user', 'userName') || null,
    userType: getFieldSessionStorage('user', 'userType') || null
  }
}
