import { METHOD_IMPORT } from '@/constants'
import {
  STEP_HOW_MANY,
  STEP_DETAILS,
  STEP_LABEL_SCAN,
  STEP_CRYO_BEACON,
  STEP_FINAL,
  STEP_SCAN_BEACON,
  STEP_ADD_EXISTING_BEACON
} from '@/constants/ticketSteps'
import { MethodType } from '@/helpers/manageTicket'

type StepType =
  typeof STEP_HOW_MANY |
  typeof STEP_DETAILS |
  typeof STEP_LABEL_SCAN |
  typeof STEP_CRYO_BEACON |
  typeof STEP_SCAN_BEACON |
  typeof STEP_ADD_EXISTING_BEACON |
  typeof STEP_FINAL

interface GetBreadCrumbs {
  currentTicketStep: StepType
  selectedMethod: MethodType
}

export const getBreadcrumbs = ({ currentTicketStep, selectedMethod }: GetBreadCrumbs) => {
  const defaultBreadcrumbs = [
    {
      id: STEP_HOW_MANY,
      active: currentTicketStep === STEP_HOW_MANY,
      label: 'How Many'
    },
    {
      id: STEP_DETAILS,
      active: currentTicketStep === STEP_DETAILS,
      label: 'Details'
    },
    {
      id: STEP_LABEL_SCAN,
      active: currentTicketStep === STEP_LABEL_SCAN,
      label: 'Label Scan'
    },
    {
      id: STEP_CRYO_BEACON,
      active: currentTicketStep === STEP_CRYO_BEACON
        || currentTicketStep === STEP_SCAN_BEACON
        || currentTicketStep === STEP_ADD_EXISTING_BEACON,
      label: 'CryoBeacon'
    },
    {
      id: STEP_FINAL,
      active: currentTicketStep === STEP_FINAL,
      label: 'Confirm'
    }
  ]

  if (selectedMethod === METHOD_IMPORT) {
    return defaultBreadcrumbs.filter((breadcrumb) => breadcrumb.id !== STEP_LABEL_SCAN)
  }

  return defaultBreadcrumbs
}
