import * as ss from '@/config/session-storage-help'
import {
  setSelectedMethod,
  setSelectedProcedureDate,
  setSelectedPatient,
  setSelectedSpecimenType
} from '@/helpers/manageTicket/utils'

import { capitalizeLower } from '@/helpers/stringHelper'
import replaceEggToOocyte from '@/helpers/replaceEggToOocyte'
import { METHOD_FREEZE } from '@/constants'
import { SpecimenType } from '@/types'

type selectedTicketsType = {
  beaconBarcode: string
  specimens: {
    beaconBarcode: number
    cryodeviceBarcode: string
    embryo: {}
    oocyte: {
      maturityLevel: string
    }
    screeningStatus: number
    specimenCount: number
    specimenId: number
    specimenInfo: string
    specimenType: string
    metadata?: any
  }[]
}

export type beaconsFromSelectedTicketsType = {
  active: boolean
  beaconId: string
  cryoDevice: {
    beaconBarcode: number
    cryodeviceBarcode: string
    embryo: {}
    oocyte: {
      maturityLevel: string
    }
    screeningStatus: number
    specimenCount: number
    specimenId: number
    specimenInfo: string
    specimenType: string
    metadata?: any
  }[]
  done: boolean
}

const generateActiveBeacons = (): beaconsFromSelectedTicketsType[] => {
  try {
    const selectedTicketToEdit: selectedTicketsType = ss.getFieldSessionStorage(
      'process',
      'selectedTicketToEdit'
    )
    if (selectedTicketToEdit) {
      return [
        {
          active: true,
          beaconId: selectedTicketToEdit.beaconBarcode,
          cryoDevice: selectedTicketToEdit.specimens.map(
            ({
              beaconBarcode,
              cryodeviceBarcode,
              embryo,
              oocyte,
              screeningStatus,
              specimenCount,
              specimenId,
              specimenInfo,
              specimenType,
              metadata
            }) => ({
              beaconBarcode,
              cryodeviceBarcode,
              embryo,
              oocyte,
              screeningStatus,
              specimenCount,
              specimenId,
              specimenInfo,
              specimenType,
              metadata
            })
          ),
          done: false
        }
      ]
    } else {
      throw new Error('generateActiveBeacon error -> there are no active beacons to process')
    }
  } catch (error) {
    throw new Error(`generateActiveBeacon error -> ${error}`)
  }
}

// NOTE: This method is only called by EMR_FREEZE
const setUpNewTicketDetailData = (
  ticket:
    | {
        patient: {
          firstName: string
          lastName: string
          globalPatientNumber: number
          identificationNumber: string
          patientDob: string
        }
        procedureTime: string
        procedureName: string
        procedure: string
        specimens: []
      }
    | undefined
) => {
  try {
    if (!ticket) throw new Error('there is not ticket data to process')
    const { firstName, lastName, globalPatientNumber, identificationNumber, patientDob } =
      ticket.patient

    const { clinicId, siteId } = ss.getFieldSessionStorage('config', '') || null

    // NOTE: This method is only called by EMR_FREEZE, so no need to calculate method

    setSelectedMethod(METHOD_FREEZE)

    setSelectedProcedureDate({
      procedureDateFrom: new Date().getTime(),
      // @ts-ignore
      procedureDateTo: null,
      procedureTime: ticket.procedureTime
    })

    setSelectedPatient([
      {
        birthDate: patientDob,
        firstName,
        lastName,
        globalPatientNumber,
        identificationNumber,
        clinicId,
        siteId,
        specimenCount: ticket?.specimens.length
      }
    ])
    // At this point, this seems to be the only way to obtain the specimen type from ticket
    const procedureSplit = ticket?.procedure?.split('_')
    const specimenType: SpecimenType = replaceEggToOocyte(capitalizeLower(procedureSplit[0]))
    setSelectedSpecimenType(specimenType)
  } catch (error) {
    throw new Error(`setUpNewTicketDetailData error -> ${error}`)
  }
}

const getCryodeviceId = (cryodeviceTypeId: number) =>
  ss
    .getFieldSessionStorage('cryoDevicesTypes', '')
    .find(({ id }: { id: string }) => id === `${cryodeviceTypeId}`)

export { generateActiveBeacons, setUpNewTicketDetailData, getCryodeviceId }
