import * as ss from '@/config/session-storage-help'

// eslint-disable-next-line func-names
export default function () {
  const config = ss.getFieldSessionStorage('config')

  return {
    clinicId: config.clinicId || null,
    siteId: config.siteId || null,
    unitId: config.unitId || null,
    clinicName: config.clinicName || null,
    siteName: config.siteName || null,
    collectionProtocolId: config.collectionProtocolId || null,
    headers: {
      'x-access-token': ss.getFieldSessionStorage('user', 'token')
    }
  }
}
