<template>
  <div class="c11 doc-content">
    <p class="c7"><span class="c0">August 1, 2022</span></p>
    <p class="c7 c17"><span class="c0"></span></p>
    <p class="c7">
      <span class="c8"
        >This privacy notice provides information on how TMRW Life Sciences collects and processes
        your personal data when you use our proprietary ivfOS software, training software and
        related software and services (</span
      ><span class="c6">Services</span
      ><span class="c0">), as an employee of your employing practice. </span>
    </p>
    <p class="c7">
      <span class="c8">It is important that you read this privacy notice together with our </span
      ><a class="link-privacy c8 text-blue-600 underline" @click="handleLinkClick"
        >End User Licence Agreement</a
      ><span class="c0">.</span>
    </p>
    <p class="c5">
      <span class="c8"
        >This privacy notice does not apply to the use of our website; you can view our website
        privacy policy here: </span
      ><span class="c4"
        ><a class="c1" target="_blank" href="https://www.tmrw.org/privacy-policy"
          >https://www.tmrw.org/privacy-policy</a
        ></span
      >
    </p>
    <ol class="c3 lst-kix_78eled36ys0g-0 start" start="1">
      <li class="c9 li-bullet-0">
        <span class="c6 c10">Important information and who we are</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c0">TMRW is the controller and responsible for your personal data. </span>
    </p>
    <p class="c7">
      <span class="c0"
        >We have appointed a data protection officer (DPO). If you have any questions about this
        privacy notice or our data protection practices please contact us using the following
        details.</span
      >
    </p>
    <p class="c7"><span class="c10 c6">CONTACT DETAILS</span></p>
    <p class="c7"><span class="c0">Our full details are:</span></p>
    <p class="c7"><span class="c0">Full name of legal entity: TMRW Life Sciences, Inc.</span></p>
    <p class="c7"><span class="c0">Data Protection Officer:</span></p>
    <p class="c7"><span class="c0">privacy@tmrw.org</span></p>
    <p class="c7 c0">{{TMRW_ADDRESS}}</p>
    <p class="c5"><span class="c0">Telephone number: (+1) 866-719-2441</span></p>
    <ol class="c3 lst-kix_nhwci88u4ssc-0 start" start="1">
      <li class="c9 li-bullet-0"><span class="c10 c6">The data we collect about you</span></li>
    </ol>
    <p class="c7">
      <span class="c0"
        >We may collect, use, store and transfer different kinds of personal data about you as
        follows:</span
      >
    </p>
    <ul class="c3 lst-kix_we5485gizvks-0 start">
      <li class="c2 li-bullet-0">
        <span class="c6">Identity Data</span
        ><span class="c0"
          >&nbsp;including first name, last name, username or similar identifier, and title.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Contact Data</span
        ><span class="c0">&nbsp;including email address and telephone numbers.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Technical Data</span
        ><span class="c0"
          >&nbsp;including internet protocol (IP) address, your login data, browser type and
          version, time zone setting and location, browser plug-in types and versions, operating
          system and platform, and other technology on the devices you use to access the
          Services.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Profile Data</span
        ><span class="c0">&nbsp;including your username and password.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Usage Data</span
        ><span class="c0">&nbsp;including information about how you use the Services.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c6">Marketing and Communications Data</span
        ><span class="c0"
          >&nbsp;including your preferences in receiving marketing from us and our third parties and
          your communication preferences.</span
        >
      </li>
    </ul>
    <p class="c7">
      <span class="c0"
        >We do not collect any special categories of personal data or criminal offence data about
        you.</span
      >
    </p>
    <p class="c5">
      <span class="c8"
        >We collect your personal data in a variety of ways: information you provide to us directly
        through your use of the Services, information we obtain through automated technologies or
        interactions when you are using the Services, and information from your employing
        practice</span
      ><span class="c8 c12">.</span>
    </p>
    <ol class="c3 lst-kix_exbsxbj9gtdc-0 start" start="1">
      <li class="c9 li-bullet-0"><span class="c10 c6">How we use your personal data</span></li>
    </ol>
    <p class="c7">
      <span class="c0"
        >We will only use your personal data for the purpose for which we collected it which include
        the following:
      </span>
    </p>
    <ul class="c3 lst-kix_n83g64kel8qw-0 start">
      <li class="c2 li-bullet-0">
        <span class="c0">To provide the Services to you and your employing practice.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c0">To manage our relationship with you and your employing practice.</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c0"
          >To register you to access and use our Services on behalf of your employing
          practice.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c0"
          >To provide you with and maintain user credentials and access levels/permissions and to
          keep our Services and the information we hold secure.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c0"
          >To monitor and evaluate your and your employing practice&rsquo;s use of the Services
          including as necessary to ensure appropriate use of the Services and to keep information
          secure.</span
        >
      </li>
      <li class="c2 li-bullet-0">
        <span class="c0">To monitor, evaluate and improve our Services.</span>
      </li>
    </ul>
    <p class="c7">
      <span class="c0"
        >Our lawful basis for processing your personal data is that our processing is
        necessary:</span
      >
    </p>
    <ul class="c3 lst-kix_o5vb5srkea4k-0 start">
      <li class="c2 li-bullet-0">
        <span class="c8"
          >For you and us to exercise our respective rights and obligations under the </span
        ><span class="link-privacy c8 text-blue-600 underline" @click="handleLinkClick"
          >End User Licence Agreement</span
        ><span class="c0">&nbsp;between you and us (Article 6(1)(b) of the UK GDPR);</span>
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >For your employing practice&rsquo;s legitimate interests in using the Services and our
          legitimate interests in providing these Services to your employing practice and related
          purposes set out above (Article 6(1)(f) of the UK GDPR).</span
        >
      </li>
    </ul>
    <ol class="c3 lst-kix_yrsea08o0da3-0 start" start="1">
      <li class="c9 li-bullet-0"><span class="c10 c6">How we share your personal data</span></li>
    </ol>
    <p class="c7">
      <span class="c0"
        >Your personal data will be shared with you and your employing practice as is necessary for
        the purposes of providing the Services. &nbsp;We may also share your data with third party
        providers who perform services on our behalf, including vendors and hosting partners to
        provide the necessary hardware, software, networking, storage, and related technology
        required to provide you with the ivfOS software and training software. For further
        information, please contact us using the details above.
      </span>
    </p>
    <p class="c15"><span class="c0"></span></p>
    <ol class="c3 lst-kix_5qzol3806dqy-0 start" start="1">
      <li class="c9 li-bullet-0"><span class="c10 c6">International transfers</span></li>
    </ol>
    <p class="c5">
      <span class="c8">The servers that store </span><span class="c8">TMRW&rsquo;s</span
      ><span class="c0"
        >&nbsp;information are based in the United States. We may transfer, store and process your
        personal data in the United States as necessary to provide the Services. We have undertaken
        a risk assessment and put in place appropriate safeguards in the form of the Information
        Commissioner&rsquo;s Office (ICO) International Data Transfer Agreement to protect your
        personal data. For further information, please contact us using the details above.
      </span>
    </p>
    <ol class="c3 lst-kix_5rr8wzysyuvy-0 start" start="1">
      <li class="c9 li-bullet-0"><span class="c10 c6">Your legal rights</span></li>
    </ol>
    <p class="c7">
      <span class="c8"
        >Under certain circumstances, users in the UK have rights under data protection laws in
        relation to their personal data including the right to receive a copy of the personal data
        we hold about you and the right to make a complaint at any time to the Information
        Commissioner&#39;s Office (ICO), the UK regulator for data protection issues. &nbsp;Please
        see: </span
      ><span class="c4"
        ><a class="c1" target="_blank" href="https://ico.org.uk/">https://ico.org.uk/</a></span
      ><span class="c0">.</span>
    </p>
    <p class="c7">
      <span class="c8">Users in the UK can find more detail about their rights at</span
      ><span class="c8"
        ><a class="c1" target="_blank" href="https://ico.org.uk/your-data-matters/">&nbsp;</a></span
      ><span class="c4"
        ><a class="c1" target="_blank" href="https://ico.org.uk/your-data-matters/"
          >https://ico.org.uk/your-data-matters/</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c7">
      <span class="c0"
        >If you wish to exercise your rights, please contact us using the details above.</span
      >
    </p>
    <p class="c14"><span class="c10 c16"></span></p>
  </div>
</template>
<script>
import {TMRW_ADDRESS} from '@/constants';
export default {
  data() {
    return {
      TMRW_ADDRESS,
    };
  },
  
  name: 'privacy-policy-doc',
  props: {
    handleLinkClick: {
      type: Function,
      required: true,
    },
  },
  
};

</script>
<style scoped>
.link-privacy {
  cursor: pointer;
}
.lst-kix_yrsea08o0da3-3 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-3;
}
.lst-kix_78eled36ys0g-0 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-0, decimal) '. ';
}
ol.lst-kix_exbsxbj9gtdc-0.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-0 0;
}
ol.lst-kix_5qzol3806dqy-3.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-3 0;
}
.lst-kix_exbsxbj9gtdc-7 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-7;
}
.lst-kix_exbsxbj9gtdc-5 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-5, lower-roman) '. ';
}
.lst-kix_78eled36ys0g-6 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-6, decimal) '. ';
}
.lst-kix_78eled36ys0g-7 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-7, lower-latin) '. ';
}
.lst-kix_5qzol3806dqy-4 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-4;
}
.lst-kix_exbsxbj9gtdc-3 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-3, decimal) '. ';
}
.lst-kix_78eled36ys0g-4 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-4, lower-latin) '. ';
}
.lst-kix_78eled36ys0g-8 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-8, lower-roman) '. ';
}
.lst-kix_exbsxbj9gtdc-4 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-4, lower-latin) '. ';
}
.lst-kix_exbsxbj9gtdc-1 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-1, lower-latin) '. ';
}
ol.lst-kix_exbsxbj9gtdc-6.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-6 0;
}
.lst-kix_78eled36ys0g-2 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-2, lower-roman) '. ';
}
.lst-kix_78eled36ys0g-3 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-3, decimal) '. ';
}
.lst-kix_5rr8wzysyuvy-2 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-2;
}
.lst-kix_78eled36ys0g-1 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-1, lower-latin) '. ';
}
ol.lst-kix_78eled36ys0g-0.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-0 0;
}
.lst-kix_exbsxbj9gtdc-2 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-2, lower-roman) '. ';
}
ol.lst-kix_yrsea08o0da3-8.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-8 0;
}
.lst-kix_nhwci88u4ssc-7 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-7;
}
.lst-kix_exbsxbj9gtdc-0 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-0, decimal) '. ';
}
ol.lst-kix_5rr8wzysyuvy-5.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-5 0;
}
ol.lst-kix_yrsea08o0da3-2.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-2 0;
}
ol.lst-kix_nhwci88u4ssc-5.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-5 0;
}
.lst-kix_78eled36ys0g-5 > li:before {
  content: '' counter(lst-ctn-kix_78eled36ys0g-5, lower-roman) '. ';
}
ul.lst-kix_n83g64kel8qw-4 {
  list-style-type: none;
}
ul.lst-kix_n83g64kel8qw-3 {
  list-style-type: none;
}
ul.lst-kix_n83g64kel8qw-2 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-6.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-6 0;
}
ul.lst-kix_n83g64kel8qw-1 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-6.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-6 0;
}
ul.lst-kix_n83g64kel8qw-8 {
  list-style-type: none;
}
ul.lst-kix_n83g64kel8qw-7 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-6.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-6 0;
}
ul.lst-kix_n83g64kel8qw-6 {
  list-style-type: none;
}
.lst-kix_78eled36ys0g-2 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-2;
}
ul.lst-kix_n83g64kel8qw-5 {
  list-style-type: none;
}
.lst-kix_nhwci88u4ssc-5 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-5;
}
.lst-kix_5rr8wzysyuvy-4 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-4;
}
.lst-kix_5qzol3806dqy-8 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-8;
}
ul.lst-kix_n83g64kel8qw-0 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-8.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-8 0;
}
.lst-kix_exbsxbj9gtdc-0 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-0;
}
ol.lst-kix_exbsxbj9gtdc-5.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-5 0;
}
.lst-kix_nhwci88u4ssc-7 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-7, lower-latin) '. ';
}
.lst-kix_nhwci88u4ssc-8 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-8, lower-roman) '. ';
}
.lst-kix_exbsxbj9gtdc-6 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-6, decimal) '. ';
}
ol.lst-kix_5rr8wzysyuvy-0.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-0 0;
}
.lst-kix_nhwci88u4ssc-2 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-2, lower-roman) '. ';
}
.lst-kix_nhwci88u4ssc-3 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-3, decimal) '. ';
}
.lst-kix_exbsxbj9gtdc-7 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-7, lower-latin) '. ';
}
.lst-kix_yrsea08o0da3-5 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-5;
}
.lst-kix_exbsxbj9gtdc-8 > li:before {
  content: '' counter(lst-ctn-kix_exbsxbj9gtdc-8, lower-roman) '. ';
}
ol.lst-kix_78eled36ys0g-5.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-5 0;
}
.lst-kix_nhwci88u4ssc-6 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-6, decimal) '. ';
}
.lst-kix_nhwci88u4ssc-4 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-4, lower-latin) '. ';
}
.lst-kix_nhwci88u4ssc-5 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-5, lower-roman) '. ';
}
.lst-kix_exbsxbj9gtdc-3 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-3;
}
.lst-kix_5rr8wzysyuvy-8 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-8, lower-roman) '. ';
}
ol.lst-kix_yrsea08o0da3-7.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-7 0;
}
.lst-kix_nhwci88u4ssc-0 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-0, decimal) '. ';
}
.lst-kix_nhwci88u4ssc-1 > li:before {
  content: '' counter(lst-ctn-kix_nhwci88u4ssc-1, lower-latin) '. ';
}
ol.lst-kix_nhwci88u4ssc-0.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-0 0;
}
.lst-kix_o5vb5srkea4k-7 > li:before {
  content: '\0025cb  ';
}
.lst-kix_78eled36ys0g-5 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-5;
}
ol.lst-kix_yrsea08o0da3-4.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-4 0;
}
ol.lst-kix_78eled36ys0g-1.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-1 0;
}
.lst-kix_5rr8wzysyuvy-0 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-0, decimal) '. ';
}
.lst-kix_5rr8wzysyuvy-4 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-4, lower-latin) '. ';
}
.lst-kix_5qzol3806dqy-0 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-0;
}
.lst-kix_o5vb5srkea4k-3 > li:before {
  content: '\0025cf  ';
}
.lst-kix_o5vb5srkea4k-1 > li:before {
  content: '\0025cb  ';
}
.lst-kix_5rr8wzysyuvy-6 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-6, decimal) '. ';
}
ol.lst-kix_yrsea08o0da3-1.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-1 0;
}
ol.lst-kix_5rr8wzysyuvy-4.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-4 0;
}
.lst-kix_78eled36ys0g-6 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-6;
}
ol.lst-kix_nhwci88u4ssc-1 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-0 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-5 {
  list-style-type: none;
}
.lst-kix_5qzol3806dqy-1 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-1;
}
ol.lst-kix_nhwci88u4ssc-4 {
  list-style-type: none;
}
.lst-kix_o5vb5srkea4k-5 > li:before {
  content: '\0025a0  ';
}
ol.lst-kix_nhwci88u4ssc-3 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-2 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-1.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-1 0;
}
.lst-kix_5rr8wzysyuvy-2 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-2, lower-roman) '. ';
}
.lst-kix_n83g64kel8qw-5 > li:before {
  content: '\0025a0  ';
}
.lst-kix_5rr8wzysyuvy-8 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-8;
}
ol.lst-kix_nhwci88u4ssc-8 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-7 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-6 {
  list-style-type: none;
}
.lst-kix_n83g64kel8qw-1 > li:before {
  content: '\0025cb  ';
}
.lst-kix_n83g64kel8qw-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_5qzol3806dqy-2.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-2 0;
}
ol.lst-kix_78eled36ys0g-4.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-4 0;
}
.lst-kix_78eled36ys0g-7 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-7;
}
.lst-kix_nhwci88u4ssc-1 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-1;
}
ol.lst-kix_exbsxbj9gtdc-2.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-2 0;
}
.lst-kix_5qzol3806dqy-8 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-8, lower-roman) '. ';
}
.lst-kix_exbsxbj9gtdc-5 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-5;
}
.lst-kix_5qzol3806dqy-6 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-6, decimal) '. ';
}
.lst-kix_yrsea08o0da3-8 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-8, lower-roman) '. ';
}
.lst-kix_yrsea08o0da3-6 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-6, decimal) '. ';
}
ol.lst-kix_5qzol3806dqy-5.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-5 0;
}
.lst-kix_5qzol3806dqy-2 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-2, lower-roman) '. ';
}
ol.lst-kix_5rr8wzysyuvy-3.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-3 0;
}
ol.lst-kix_78eled36ys0g-3.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-3 0;
}
.lst-kix_5qzol3806dqy-0 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-0, decimal) '. ';
}
.lst-kix_5qzol3806dqy-4 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-4, lower-latin) '. ';
}
.lst-kix_nhwci88u4ssc-0 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-0;
}
.lst-kix_exbsxbj9gtdc-4 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-4;
}
ol.lst-kix_5qzol3806dqy-1 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-2 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-3 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-4 {
  list-style-type: none;
}
.lst-kix_nhwci88u4ssc-6 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-6;
}
ol.lst-kix_5qzol3806dqy-5 {
  list-style-type: none;
}
.lst-kix_n83g64kel8qw-7 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_5qzol3806dqy-6 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-7 {
  list-style-type: none;
}
.lst-kix_we5485gizvks-1 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_5qzol3806dqy-8 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-1.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-1 0;
}
ol.lst-kix_yrsea08o0da3-3.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-3 0;
}
ol.lst-kix_5qzol3806dqy-4.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-4 0;
}
.lst-kix_yrsea08o0da3-0 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-0, decimal) '. ';
}
.lst-kix_78eled36ys0g-0 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-0;
}
.lst-kix_yrsea08o0da3-4 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-4, lower-latin) '. ';
}
ol.lst-kix_78eled36ys0g-2.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-2 0;
}
.lst-kix_5qzol3806dqy-6 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-6;
}
.lst-kix_yrsea08o0da3-2 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-2, lower-roman) '. ';
}
.lst-kix_5rr8wzysyuvy-3 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-3;
}
.lst-kix_nhwci88u4ssc-8 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-8;
}
.lst-kix_exbsxbj9gtdc-8 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-8;
}
ol.lst-kix_78eled36ys0g-8.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-8 0;
}
ol.lst-kix_5rr8wzysyuvy-8.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-8 0;
}
ol.lst-kix_5qzol3806dqy-0.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-0 0;
}
.lst-kix_5qzol3806dqy-5 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-5;
}
ol.lst-kix_exbsxbj9gtdc-3.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-3 0;
}
.lst-kix_5rr8wzysyuvy-1 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-1;
}
.lst-kix_yrsea08o0da3-4 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-4;
}
.lst-kix_5qzol3806dqy-7 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-7;
}
.lst-kix_we5485gizvks-2 > li:before {
  content: '\0025a0  ';
}
ul.lst-kix_we5485gizvks-0 {
  list-style-type: none;
}
.lst-kix_we5485gizvks-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_yrsea08o0da3-5.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-5 0;
}
ol.lst-kix_5qzol3806dqy-0 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-6.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-6 0;
}
.lst-kix_we5485gizvks-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_we5485gizvks-4 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_nhwci88u4ssc-2.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-2 0;
}
.lst-kix_we5485gizvks-5 > li:before {
  content: '\0025a0  ';
}
ol.lst-kix_78eled36ys0g-3 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-8 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-2 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-1 {
  list-style-type: none;
}
.lst-kix_yrsea08o0da3-6 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-6;
}
ol.lst-kix_78eled36ys0g-0 {
  list-style-type: none;
}
ol.lst-kix_nhwci88u4ssc-8.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-8 0;
}
.lst-kix_we5485gizvks-7 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_5rr8wzysyuvy-2.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-2 0;
}
ul.lst-kix_we5485gizvks-2 {
  list-style-type: none;
}
.lst-kix_78eled36ys0g-1 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-1;
}
ol.lst-kix_yrsea08o0da3-0 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-1 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-1 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-4 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-2 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-3 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-8 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-3 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-6 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-7 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-4 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-5 {
  list-style-type: none;
}
.lst-kix_we5485gizvks-8 > li:before {
  content: '\0025a0  ';
}
ol.lst-kix_78eled36ys0g-6 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-5 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-8 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-5 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-6 {
  list-style-type: none;
}
ul.lst-kix_we5485gizvks-7 {
  list-style-type: none;
}
ol.lst-kix_78eled36ys0g-4 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-7 {
  list-style-type: none;
}
.lst-kix_78eled36ys0g-8 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-8;
}
ul.lst-kix_o5vb5srkea4k-7 {
  list-style-type: none;
}
ul.lst-kix_o5vb5srkea4k-8 {
  list-style-type: none;
}
ul.lst-kix_o5vb5srkea4k-5 {
  list-style-type: none;
}
ul.lst-kix_o5vb5srkea4k-6 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-6.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-6 0;
}
.lst-kix_yrsea08o0da3-8 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-8;
}
ul.lst-kix_o5vb5srkea4k-3 {
  list-style-type: none;
}
.lst-kix_exbsxbj9gtdc-6 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-6;
}
ol.lst-kix_nhwci88u4ssc-7.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-7 0;
}
ul.lst-kix_o5vb5srkea4k-4 {
  list-style-type: none;
}
ol.lst-kix_5qzol3806dqy-1.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-1 0;
}
ul.lst-kix_o5vb5srkea4k-1 {
  list-style-type: none;
}
ul.lst-kix_o5vb5srkea4k-2 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-7.start {
  counter-reset: lst-ctn-kix_5rr8wzysyuvy-7 0;
}
ul.lst-kix_o5vb5srkea4k-0 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-8 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-7 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-6 {
  list-style-type: none;
}
ol.lst-kix_yrsea08o0da3-0.start {
  counter-reset: lst-ctn-kix_yrsea08o0da3-0 0;
}
ol.lst-kix_exbsxbj9gtdc-1 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-0 {
  list-style-type: none;
}
.lst-kix_yrsea08o0da3-2 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-2;
}
ol.lst-kix_exbsxbj9gtdc-5 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-4 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-4.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-4 0;
}
ol.lst-kix_5qzol3806dqy-7.start {
  counter-reset: lst-ctn-kix_5qzol3806dqy-7 0;
}
ol.lst-kix_exbsxbj9gtdc-3 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-2 {
  list-style-type: none;
}
.lst-kix_nhwci88u4ssc-2 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-2;
}
.lst-kix_exbsxbj9gtdc-2 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-2;
}
.lst-kix_o5vb5srkea4k-8 > li:before {
  content: '\0025a0  ';
}
.lst-kix_5rr8wzysyuvy-3 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-3, decimal) '. ';
}
.lst-kix_5rr8wzysyuvy-5 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-5, lower-roman) '. ';
}
ol.lst-kix_exbsxbj9gtdc-7.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-7 0;
}
.lst-kix_5rr8wzysyuvy-7 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-7, lower-latin) '. ';
}
.lst-kix_o5vb5srkea4k-2 > li:before {
  content: '\0025a0  ';
}
.lst-kix_5rr8wzysyuvy-7 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-7;
}
.lst-kix_exbsxbj9gtdc-1 > li {
  counter-increment: lst-ctn-kix_exbsxbj9gtdc-1;
}
ol.lst-kix_nhwci88u4ssc-1.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-1 0;
}
.lst-kix_o5vb5srkea4k-4 > li:before {
  content: '\0025cb  ';
}
ol.lst-kix_nhwci88u4ssc-4.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-4 0;
}
.lst-kix_5rr8wzysyuvy-1 > li:before {
  content: '' counter(lst-ctn-kix_5rr8wzysyuvy-1, lower-latin) '. ';
}
.lst-kix_o5vb5srkea4k-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_nhwci88u4ssc-3 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-3;
}
.lst-kix_n83g64kel8qw-4 > li:before {
  content: '\0025cb  ';
}
.lst-kix_5rr8wzysyuvy-5 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-5;
}
.lst-kix_nhwci88u4ssc-4 > li {
  counter-increment: lst-ctn-kix_nhwci88u4ssc-4;
}
.lst-kix_n83g64kel8qw-2 > li:before {
  content: '\0025a0  ';
}
.lst-kix_o5vb5srkea4k-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_5qzol3806dqy-2 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-2;
}
.lst-kix_n83g64kel8qw-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_78eled36ys0g-4 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-4;
}
ol.lst-kix_78eled36ys0g-7.start {
  counter-reset: lst-ctn-kix_78eled36ys0g-7 0;
}
.lst-kix_yrsea08o0da3-1 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-1;
}
.lst-kix_5qzol3806dqy-3 > li {
  counter-increment: lst-ctn-kix_5qzol3806dqy-3;
}
.lst-kix_5qzol3806dqy-5 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-5, lower-roman) '. ';
}
.lst-kix_5qzol3806dqy-7 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-7, lower-latin) '. ';
}
.lst-kix_yrsea08o0da3-7 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-7, lower-latin) '. ';
}
.lst-kix_5qzol3806dqy-1 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-1, lower-latin) '. ';
}
.lst-kix_5qzol3806dqy-3 > li:before {
  content: '' counter(lst-ctn-kix_5qzol3806dqy-3, decimal) '. ';
}
.lst-kix_yrsea08o0da3-0 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-0;
}
.lst-kix_78eled36ys0g-3 > li {
  counter-increment: lst-ctn-kix_78eled36ys0g-3;
}
ol.lst-kix_5rr8wzysyuvy-2 {
  list-style-type: none;
}
.lst-kix_yrsea08o0da3-7 > li {
  counter-increment: lst-ctn-kix_yrsea08o0da3-7;
}
ol.lst-kix_5rr8wzysyuvy-3 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-0 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-1 {
  list-style-type: none;
}
.lst-kix_we5485gizvks-0 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_5rr8wzysyuvy-6 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-7 {
  list-style-type: none;
}
.lst-kix_n83g64kel8qw-6 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_5rr8wzysyuvy-4 {
  list-style-type: none;
}
ol.lst-kix_exbsxbj9gtdc-8.start {
  counter-reset: lst-ctn-kix_exbsxbj9gtdc-8 0;
}
ol.lst-kix_5rr8wzysyuvy-5 {
  list-style-type: none;
}
ol.lst-kix_5rr8wzysyuvy-8 {
  list-style-type: none;
}
.lst-kix_n83g64kel8qw-8 > li:before {
  content: '\0025a0  ';
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_5rr8wzysyuvy-0 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-0;
}
.lst-kix_yrsea08o0da3-5 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-5, lower-roman) '. ';
}
ol.lst-kix_nhwci88u4ssc-3.start {
  counter-reset: lst-ctn-kix_nhwci88u4ssc-3 0;
}
.lst-kix_yrsea08o0da3-1 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-1, lower-latin) '. ';
}
.lst-kix_5rr8wzysyuvy-6 > li {
  counter-increment: lst-ctn-kix_5rr8wzysyuvy-6;
}
.lst-kix_yrsea08o0da3-3 > li:before {
  content: '' counter(lst-ctn-kix_yrsea08o0da3-3, decimal) '. ';
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c9 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 21pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c2 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 17pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c15 {
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8.5pt;
  font-family: 'Arial';
  font-style: normal;
}
.c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Arial';
  font-style: italic;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 5pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c10 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Arial';
  font-style: normal;
}
.c4 {
  text-decoration-skip-ink: none;
  font-size: 8.5pt;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c11 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 20pt 20pt 72pt 20pt;
}
.c3 {
  padding: 0;
  margin: 0;
}
.c1 {
  color: inherit;
  text-decoration: inherit;
}
.c16 {
  font-weight: 400;
  font-size: 11pt;
}
.c6 {
  font-size: 8.5pt;
  font-weight: 700;
}
.c13 {
  background-color: #ffff0b;
}
.c8 {
  font-size: 8.5pt;
}
.c17 {
  height: 11pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
