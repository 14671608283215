<template>
  <vue-final-modal
    data-test="modal-bulk"
    class="w-full m-auto text-tmrw-blue"
    @closed="clickedModalOut"
    :clickToClose="false"
    name="modal-bulk"
    height="auto"
    :modelValue="show"
    classes="modal--container"
    content-class="modal--content"
    :content-style="{ height: 'auto', width: '700px' }"
  >
    <section class="w-11/12 m-auto text-tmrw-blue pb-6">
      <div class="flex flex-row mt-4">
        <ActionBar data-test="modal-bulk__action-bar" colsDistribution="9/3">
          <template v-slot:left-side>
            <dynamic-title titleType="h3-blue" floatTo="left">
              {{ modalTitle }}
            </dynamic-title>
          </template>
          <template v-slot:right-side>
            <i
              @click="handleClose"
              class="w-2/12 flex items-center justify-end far fa-times-circle cursor-pointer"
              :class="{ 'fas--disabled': processingUpload }"
            />
          </template>
        </ActionBar>
      </div>
      <div class="flex flex-row mt-6 mb-4">
        <p class="w-8/12 flex justify-start">
          {{ bulkImportType === 'patients' ? 'Patient' : 'Specimen' }} record must be a .csv file
        </p>
        <div class="flex w-4/12 justify-end">
          <i class="fas fa-file-download mr-4" />
          <a
            v-if="bulkImportType === 'patients'"
            data-test="patients-template-download-link"
            :href="patientsTemplateDownloadLink"
            target="_blank"
            >Download Template</a
          >
        </div>
      </div>
      <loading-ui
        v-if="processingUpload"
        class="mb-4"
        modal
        :message="PROCESSING_PATIENT_BULK_IMPORT"
      />
      <div v-if="isJobStatusFailed">
        <div class="bg-tmrw-warning rounded p-4 text-white flex flex-col items-start">
          <p class="font-bold">The file you uploaded contained errors and was not imported.</p>
          <p class="my-4 leading-5">
            We've updated the file to show which entries are in error. Click download to see the
            updated file, and to correct the affected entries.
          </p>
          <div
            class="flex flex-row bg-white p-2 border-solid border-1 border-tmrw-blue cursor-pointer text-tmrw-blue rounded w-auto"
            v-on:click="handleDownloadFile"
          >
            <i class="fas fa-download mr-2" />
            <p class="font-bold">Download</p>
          </div>
        </div>
        <p class="mt-6 mb-4">Or upload a new file:</p>
      </div>

      <div
        v-if="file && file.name"
        class="flex items-center justify-between border-solid border-1 border-tmrw-blue-light rounded p-4 text-tmrw-blue-light mb-4"
      >
        <p>{{ file.name }}</p>
        <i
          class="w-8 flex items-center justify-end far fa-times-circle cursor-pointer"
          @click="removeFile"
        />
      </div>
      <div
        class="border-dashed border-2 border-tmrw-blue-light rounded bg-tmrw-green-light text-tmrw-blue-light relative text-center h-56"
        v-if="!file"
      >
        <i class="fas fa-cloud-upload-alt mt-12 mb-2 text-2xl" />
        <p class="mb-10">Drag and drop to upload</p>
        <p class="underline text-tmrw-blue font-bold">Or click to choose a file</p>
        <p class="mt-2 text-tmrw-error" v-if="error">{{ error }}</p>
        <form id="csv-file-upload-form" enctype="multipart/form-data">
          <input
            class="absolute cursor-pointer opacity-0 h-full w-full flex flex-col inset-0"
            type="file"
            id="file"
            ref="fileRef"
            v-on:change="handleFileUpload"
          />
        </form>
        <p class="mt-4 text-tmrw-error" v-if="fileError">
          Something went wrong while uploading your file. Please try again.
        </p>
      </div>
      <p class="mt-4 text-tmrw-success font-bold" v-if="successUpload">
        {{ successMessage }}
      </p>
      <div>
        <button-dynamic
          btnText="Import"
          btnType="button"
          floatTo="right"
          showIcon
          btnStyle="primary"
          :isDisabled="!file"
          fontAwesomeIconClass="cloud-upload-alt"
          addMarginTop
          addMarginBottom
          @click="processBulkImportFile"
        />
        <button-dynamic
          btnText="Cancel"
          btnType="button"
          floatTo="right"
          btnStyle="transparent-blue"
          :isDisabled="processingUpload"
          addMarginTop
          addMarginBottom
          addMarginRight
          @click="handleClose"
        />
      </div>
    </section>
  </vue-final-modal>
</template>

<script setup lang="ts">
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import toast from '@/config/toast'
import {
  COMPLETED,
  FAILED,
  WRONG_MESSAGE_PATIENT_BULK,
  ERROR_MESSAGE_FILE_CSV,
  PROCESSING_PATIENT_BULK_IMPORT,
  COMPLETED_WITH_SKIPPED,
  QUEUED
} from '@/constants'
import useGetters from '@/composables/useGetters'
import { computed, ref, watch } from 'vue'
import useActions from '@/composables/useActions'

type ModalBulkProps = {
  bulkImportType?: 'patients'
  show?: boolean
  closeModal: boolean
}

type JobStatus = {
  status?: typeof COMPLETED | typeof COMPLETED_WITH_SKIPPED | typeof FAILED | typeof QUEUED
  jobId?: number
}

const error = ref('')
const file = ref<File | undefined>()
const fileRef = ref<HTMLInputElement | null>(null)
const filename = ref('')
const fileError = ref(false)
const processingUpload = ref(false)
const generalJobStatus = ref<JobStatus>({})
const intervalPatient = ref<null | NodeJS.Timeout>(null)

const emit = defineEmits(['closeModal'])

const props = withDefaults(defineProps<ModalBulkProps>(), {
  bulkImportType: 'patients',
  show: false
})

const { jobStatus } = useGetters('patientsModule')

const modalTitle = computed(() => {
  return 'Import Patient Record'
})
const patientsTemplateDownloadLink = computed(() => {
  return 'https://tmrw.gitlab.io/eng/app-config/patient_template.csv'
})
const isJobStatusFailed = computed(() => {
  return (
    generalJobStatus.value && generalJobStatus.value.status === FAILED && !processingUpload.value
  )
})

const successUpload = computed(() => {
  return (
    generalJobStatus.value?.status &&
    ([COMPLETED, COMPLETED_WITH_SKIPPED] as JobStatus['status'][]).includes(
      generalJobStatus.value.status
    )
  )
})

const successMessage = computed(() => {
  switch (generalJobStatus.value?.status) {
    case COMPLETED:
      return 'All patients were imported'
    case COMPLETED_WITH_SKIPPED:
      return 'All patients were imported or already exist in ivfOS'
    default:
      return ''
  }
})

const {
  fetchPatients,
  setJobStatusDefault,
  getPatientsUploadStatus,
  postUpload,
  checkJobStatus,
  setPatientTimestamp
} = useActions('patientsModule')

const processBulkImportFile = () => {
  const formData = new FormData()
  formData.append('file', file.value!)
  processingUpload.value = true
  if (props.bulkImportType === 'patients') {
    postUploadPatient(formData)
  }
}
const removeFile = () => {
  file.value = undefined
}
const handleBulkStatus = (response) => {
  const showError = () => {
    stopLoader()
    handleReset()
    toast.error({ title: WRONG_MESSAGE_PATIENT_BULK })
  }

  if (!response) {
    showError()
    return
  }
  const { status } = response

  switch (status) {
    case COMPLETED:
    case COMPLETED_WITH_SKIPPED:
      stopLoader()
      handleReset()
      break

    case FAILED:
      showError()
      break

    default:
      break
  }
}
const stopLoader = () => {
  clearTimer()
  setPatientTimestamp(null)
  fetchPatients()
  processingUpload.value = false
}
const postUploadPatient = async (formData) => {
  try {
    const postUploadResponse = await postUpload(formData)
    const id = postUploadResponse
    intervalPatient.value = setInterval(async () => {
      const checkJobStatusResponse = await checkJobStatus(id)
      handleBulkStatus(checkJobStatusResponse)
    }, 5000)
  } catch (error) {
    stopLoader()
    file.value = undefined
    filename.value = ''
    fileError.value = true
  }
  return intervalPatient.value
}
const clearTimer = () => {
  if (intervalPatient.value) {
    clearInterval(intervalPatient.value)
  }
  intervalPatient.value = null
}
const handleFileUpload = () => {
  error.value = ''
  fileError.value = false
  const newFile = fileRef.value?.files?.[0]
  if (newFile && newFile?.name && newFile.name.substr(newFile.name.length - 4, 4) !== '.csv') {
    error.value = ERROR_MESSAGE_FILE_CSV
    return
  }
  if (newFile && newFile?.name) {
    filename.value = newFile.name
  }
  file.value = newFile
}
const handleDownloadFile = () => {
  const { jobId, status } = generalJobStatus.value
  if (props.bulkImportType === 'patients') {
    getPatientsUploadStatus({ jobId, status, filename: filename.value })
  }
}
const handleClose = () => {
  generalJobStatus.value = {}
  fileError.value = false
  if (!processingUpload.value) {
    file.value = undefined
    error.value = ''
    setJobStatusDefault()
  }
  emit('closeModal')
}
const clickedModalOut = () => {
  handleClose()
}
const handleReset = () => {
  file.value = undefined
  error.value = ''
}

watch(jobStatus, (newValue) => {
  if (newValue && newValue.status) {
    generalJobStatus.value = newValue
  }
})
</script>
<style lang="scss">
.modal {
  &--content {
    // default settings of vue-js-modal
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);

    display: flex;
    flex-direction: column;
    //margin: 0 1rem;
    //padding: 1rem;
    //border: 1px solid #e2e8f0;
    //border-radius: 0.25rem;
    //background: #fff;
  }
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
