// assert we're not allowing null or undefined values
export function assertIsNonNullish<T>(value: T, reference: string) : asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`${reference} can't be null or undefined`)
  }
}
// handle false responses but non undefined of null values
export function assertIsBooleanNonNullish(value: boolean): boolean {
  if (value === null || value === undefined) {
    return false
  }
  return value
}
