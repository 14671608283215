import { version } from '@/../package.json'

export default {
  isAppModalOpen: false,
  appInfoStatus: null,
  appVersion: version,
  apiVersion: '',
  bulkImportApiStatus: null,
  bulkImportApiVersion: '',
  appSupportEmail: 'support@tmrw.org',
  appSupportPhoneUS: '(833) 433-8679',
  appSupportPhoneUK: '+44 8081 694118',
  modularCryolabelStatus: null,
  modularCryolabel: {},
  isTermsAndConditionsModalOpen: false,
  isPrivacyPolicyModalOpen: false
}
