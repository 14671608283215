<template>
  <div class="tooltip-container" ref="baseParent" data-test="tooltip-container">
    <div
      :class="[
        {
          'shadow-tooltip': shadow
        },
        tooltipClass
      ]"
      class="tooltip"
      ref="tooltip"
      data-test="tooltip-content"
      role="tooltip"
    >
      <slot name="content">
        <i v-if="!!tooltipIcon" :class="tooltipIcon" class="pr-2" />
        <span>{{ content }}</span>
      </slot>
      <div
        :class="[
          'arrow',
          tooltipArrowClass,
          {
            hidden: !showArrow
          }
        ]"
        ref="arrow"
      />
    </div>
    <div
      data-test="tooltip-reference"
      class="reference-container"
      ref="reference"
      @click="clickTooltip"
      @mouseover="showTooltip"
      @mouseout="hideTooltip"
    >
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, onUnmounted, PropType, computed, onBeforeUnmount } from 'vue'
import {
  computePosition,
  offset,
  shift,
  flip,
  arrow as arrowMW,
  autoUpdate,
  Placement,
  Coords
} from '@floating-ui/dom'
import { formatFAIcon } from '@/utils'

const tooltipVisible = ref<boolean>(false)
const tooltipBehavior = ref<'hover' | 'click' | 'manual'>('hover')

const reference = ref<HTMLElement | null>(null)
const baseParent = ref<HTMLElement | null>(null)
const arrow = ref<HTMLElement | null>(null)
const tooltip = ref<HTMLElement | null>(null)
const parentRef = ref<HTMLElement | null>(null)

let cleanup: () => void
const props = defineProps({
  content: {
    type: String,
    required: false,
    default: ''
  },
  tooltipClass: {
    type: String,
    required: false,
    default: ''
  },
  tooltipArrowClass: {
    type: String,
    required: false,
    default: ''
  },
  tooltipOffset: {
    type: Number,
    required: false,
    default: 18
  },
  arrowSize: {
    type: Number,
    required: false,
    default: 16
  },
  tooltipCrossOffset: {
    type: Number,
    required: false,
    default: 0
  },
  placement: {
    type: String as PropType<Placement>,
    required: false,
    default: 'top'
  },
  icon: {
    type: String,
    required: false,
    default: undefined
  },
  backgroundColor: {
    type: String,
    required: false
  },
  color: {
    type: String,
    required: false
  },
  behavior: {
    type: String as PropType<'hover' | 'click' | 'manual'>,
    required: false,
    default: 'hover'
  },
  visible: {
    type: Boolean,
    required: false,
    default: undefined
  },
  showArrow: {
    type: Boolean,
    required: false,
    default: true
  },
  shadow: {
    type: Boolean,
    required: false,
    default: false
  },
  parent: {
    type: String,
    required: false,
    default: undefined
  },
  enabled: {
    type: Boolean,
    required: false,
    default: true
  }
})

const emit = defineEmits(['tooltip-visible'])

const tooltipIcon = computed<string | undefined>(() => {
  if (!props.icon) {
    return undefined
  }
  return formatFAIcon(props.icon)
})

const showTooltip = () => {
  if (props.enabled && tooltipBehavior.value === 'hover') {
    tooltipVisible.value = true
  }
}

const hideTooltip = () => {
  if (props.enabled && tooltipBehavior.value === 'hover') {
    tooltipVisible.value = false
  }
}

const clickTooltip = () => {
  if (props.enabled && tooltipBehavior.value === 'click') {
    tooltipVisible.value = !tooltipVisible.value
  }
}

const setPosition = async () => {
  if (!reference.value || !tooltip.value || !arrow.value) {
    return
  }
  const { tooltipOffset, tooltipCrossOffset, placement, arrowSize = 16 } = props
  const offsetObject = {
    mainAxis: tooltipOffset * 1,
    crossAxis: tooltipCrossOffset * 1
  }
  const position = await computePosition(reference.value, tooltip.value, {
    placement,
    middleware: [offset(offsetObject), shift(), flip(), arrowMW({ element: arrow.value })]
  })
  const { x, y, placement: positionPlacement, middlewareData } = position
  if (!reference.value || !tooltip.value || !arrow.value) {
    return
  }
  Object.assign(tooltip.value.style, {
    left: `${x}px`,
    top: `${y}px`
  })

  const { x: arrowX, y: arrowY } = middlewareData.arrow as Coords
  const staticSide: string | undefined = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
  }[positionPlacement.split('-')[0]]
  Object.assign(arrow.value.style, {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY + tooltipCrossOffset * 1}px` : '',
    right: '',
    bottom: '',
    [staticSide as string]: `-${arrowSize / 2}px`
  })
}

onMounted(() => {
  tooltipBehavior.value = props.behavior
  if (props.visible !== undefined) {
    tooltipVisible.value = props.enabled && props.visible
    tooltipBehavior.value = 'manual'
  }
  if (tooltip.value) {
    tooltip.value.style.display = tooltipVisible.value ? 'block' : 'none'
  }
  if (tooltip.value && props.backgroundColor) {
    tooltip.value.style.backgroundColor = props.backgroundColor
  }
  if (tooltip.value && props.color) {
    tooltip.value.style.color = props.color
  }
  if (arrow.value && props.backgroundColor) {
    arrow.value.style.backgroundColor = props.backgroundColor
  }
  if (arrow.value && props.arrowSize) {
    arrow.value.style.width = `${props.arrowSize}px`
    arrow.value.style.height = `${props.arrowSize}px`
  }
  if (reference.value && tooltip.value) {
    try {
      cleanup = autoUpdate(reference.value, tooltip.value, setPosition)
    } catch (e) {
      // empty catch (Unit test will always throw this error)
    }
  }
  // if the tooltip needs to be mounted on a different DOM element that this component
  if (props.parent) {
    parentRef.value = document.querySelector(props.parent)
    if (parentRef.value && tooltip.value) {
      parentRef.value.appendChild(tooltip.value)
    }
  }
  if (tooltipVisible.value) {
    setPosition()
  }
})
onUnmounted(() => {
  if (cleanup) {
    try {
      cleanup()
    } catch (e) {
      // empty catch (Unit test will always throw this error)
    }
  }
})
onBeforeUnmount(() => {
  // if mounted elsewhere
  // returns the tooltip to this component's DOM tree so i can be properly unmounted
  if (props.parent && parentRef.value && tooltip.value) {
    baseParent.value?.appendChild(tooltip.value)
  }
})
watch(tooltipVisible, (newVisible) => {
  if (!tooltip.value) {
    return
  }
  tooltip.value.style.display = newVisible ? 'block' : 'none'
  if (newVisible) {
    setPosition()
  }
})
watch(props, (newProps) => {
  if (newProps.visible !== undefined) {
    tooltipVisible.value = newProps.enabled && newProps.visible
    tooltipBehavior.value = 'manual'
  } else {
    tooltipBehavior.value = newProps.behavior
  }
  if (tooltip.value && props.backgroundColor) {
    tooltip.value.style.backgroundColor = props.backgroundColor
  }
  if (tooltip.value && props.color) {
    tooltip.value.style.color = props.color
  }
  if (arrow.value && props.backgroundColor) {
    arrow.value.style.backgroundColor = props.backgroundColor
  }
  if (arrow.value && props.arrowSize) {
    arrow.value.style.width = `${props.arrowSize}px`
    arrow.value.style.height = `${props.arrowSize}px`
  }
})
watch(tooltipVisible, (newVisible) => {
  emit('tooltip-visible', newVisible)
})
</script>
<style lang="scss" scoped>
.tooltip {
  @apply absolute text-base text-white bg-tmrw-blue-light font-exo font-semibold rounded-md top-0 left-0 hidden z-50 p-4;
  pointer-events: none;
}

.arrow {
  @apply bg-tmrw-blue-light absolute rounded-sm w-4 h-4;
  transform: rotate(45deg);
}
</style>
