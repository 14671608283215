<template>
  <section :data-test="`message-card-${currentRouteName}`" class="text-2xl text-white font-exo font-semibold py-2">
    <slot>Place a comment!</slot>
  </section>
</template>

<script>
export default {
  name: 'message-card',
  computed: {
    currentRouteName() {
      return this.$route.fullPath.replace('/', '')
    }
  }
}
</script>
