<template>
  <header
    class="flex py-2 pr-4"
    :class="{
      'bg-tmrw-blue rounded text-white': isSelected,
      'bg-gradient-to-r from-tmrw-green-light to-transparent': !isSelected
    }"
  >
    <RadioButton
      v-if="isSelectable && !beacon.disabled"
      :modelValue="isSelected"
      @update:modelValue="handleSelection"
      class="flex items-center pr-2 pl-4"
    />
    <div
      data-test="cryobeacon-barcode"
      class="flex flex-col justify-around pl-4"
      :class="opacityClass"
    >
      <p class="text-xs font-semibold">{{ CRYOBEACON.toUpperCase() }} ID</p>
      <p class="text-lg font-bold">{{ beacon.barcode }}</p>
    </div>
    <div class="flex flex-col justify-around pl-6" :class="opacityClass">
      <p class="text-xs font-semibold">{{ INFECTIOUS_SCREENING_STATUS.toUpperCase() }}</p>
      <ScreeningStatusLabel
        class="text-lg"
        icon
        label
        paddingSmall
        align="left"
        textColor="tmrw-blue-dark font-semibold"
        iconColor="tmrw-blue-dark"
        :id="beacon.screeningStatusId"
      />
    </div>
    <div class="flex flex-col justify-around pl-6" :class="opacityClass">
      <p class="text-xs font-semibold">LOCATION</p>
      <p class="text-lg font-bold">
        {{ getCryobeaconLocationRobot.cryoRobotName }}
        <span class="text-xs">{{ getCryobeaconLocationRobot.location }}</span>
      </p>
    </div>
    <div
      v-if="showStatus"
      data-test="cryobeacon-status"
      class="ml-auto flex items-center text-2xl"
      :class="textColorClass"
    >
      {{ status }}
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as ss from '@/config/session-storage-help'
import RadioButton from '@/components/RadioButton/RadioButton.vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { AVAILABLE_FOR_USE, CRYOBEACON, INFECTIOUS_SCREENING_STATUS } from '@/constants'
import { getCryoBeaconLocation } from '@/helpers/cryoBeaconLocation'

export default {
  name: 'cryobeacon-header',
  components: {
    RadioButton,
    ScreeningStatusLabel
  },
  props: {
    beacon: {
      type: Object,
      required: true
    },
    isSelectable: {
      type: Boolean,
      required: false,
      default: false
    },
    showStatus: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      CRYOBEACON,
      INFECTIOUS_SCREENING_STATUS
    }
  },
  methods: {
    ...mapActions('beaconsModule', ['selectBeacon']),
    async handleSelection(selected) {
      if (selected) {
        await this.selectBeacon(this.beacon)
        ss.setFieldSessionStorage('newBeaconsArray', JSON.stringify([this.beacon.barcode]))
      } else {
        await this.selectBeacon(null)
        ss.removeFieldSessionStorage('newBeaconsArray')
      }
    }
  },
  computed: {
    ...mapGetters('beaconsModule', ['currentBeacon']),
    isSelected() {
      const { beacon, currentBeacon, isSelectable } = this

      return isSelectable && currentBeacon === beacon
    },
    status() {
      const { beacon, showStatus } = this
      const { disabled, reason } = beacon

      if (!showStatus) return ''
      if (disabled) return reason
      return AVAILABLE_FOR_USE
    },
    opacityClass() {
      const { disabled } = this.beacon

      if (disabled) return 'opacity-25'
      return 'opacity-100'
    },
    textColorClass() {
      const { beacon, isSelected } = this
      const { disabled } = beacon

      if (isSelected) return 'text-tmrw-white'
      if (disabled) return 'text-tmrw-warning'
      return 'text-tmrw-success'
    },
    getCryobeaconLocationRobot() {
      return getCryoBeaconLocation(this.beacon)
    }
  }
}
</script>
