import {
  DYNAMIC_HEADER_ERROR,
  PROCEDURE_TYPE_FREEZE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EMBRYO_LOWERCASE,
  PROCEDURE_TYPE_SINGLE_IMPORT
} from '@/constants'
import toast from '@/config/toast'
import { dynamicEmbryo, dynamicEgg } from '@/constants/table-headers/dynamic-headers'

type createDynamicHeadersType = {
  procedureType: string,
  specimenType: string,
  reprintDisabled?: boolean
}

export const createDynamicHeaders = ({
  procedureType,
  specimenType,
  reprintDisabled = false
}: createDynamicHeadersType) => {
  try {
    switch (procedureType) {
      case PROCEDURE_TYPE_FREEZE: {
        if (specimenType === SPECIMEN_TYPE_EMBRYO_LOWERCASE) {
          return dynamicEmbryo({ reprintLabelShow: true, reprintDisabled })
        }
        return dynamicEgg({ reprintLabelShow: true, reprintDisabled })
      }
      default: {
        if (specimenType === SPECIMEN_TYPE_EMBRYO_LOWERCASE) {
          return dynamicEmbryo({ reprintDisabled })
        }
        return dynamicEgg({ reprintDisabled })
      }
    }
  } catch (error) {
    toast.error({ title:DYNAMIC_HEADER_ERROR})
    throw new Error(`createDynamicHeaders ---> error : ${error}`)
  }
}

type dynamicHeaderTicketType = {
  ticket: {
    procedureType: string,
    specimens: [{
      specimenType: string
    }],
    cryoDevice: [{
      specimenType: string,
      cryoDevice: string
    }]
  } | null,
  patientsTooltip?: boolean,
  reprintDisabled?: boolean,
}

export const createDynamicHeadersFromSpecimens = ({
  ticket,
  patientsTooltip = false,
  reprintDisabled = false
}: dynamicHeaderTicketType) => {
  try {
    let embryoHeaders = dynamicEmbryo({ patientsTooltip, reprintDisabled })
    let oocyteHeaders = dynamicEgg({ patientsTooltip, reprintDisabled })
    let isImportTicket = false

    if (ticket?.procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
      isImportTicket = true
    }

    if (ticket) {
      const reprintLabelFlag = ticket?.procedureType === PROCEDURE_TYPE_FREEZE

      embryoHeaders = dynamicEmbryo({
        reprintLabelShow: reprintLabelFlag,
        isImportTicket,
        patientsTooltip,
        reprintDisabled
      })
      oocyteHeaders = dynamicEgg({
        reprintLabelShow: reprintLabelFlag,
        isImportTicket,
        patientsTooltip,
        reprintDisabled
      })

      if (ticket?.specimens) {
        ticket.specimens.map(({ specimenType }) => {
          if (specimenType === SPECIMEN_TYPE_EMBRYO) {
            embryoHeaders = dynamicEmbryo({
              reprintLabelShow: reprintLabelFlag,
              isImportTicket,
              patientsTooltip,
              reprintDisabled
            })
            return embryoHeaders
          }
          oocyteHeaders = dynamicEgg({
            reprintLabelShow: reprintLabelFlag,
            isImportTicket,
            patientsTooltip,
            reprintDisabled
          })
          return oocyteHeaders
        })
      }
    }
    return {
      embryoHeaders,
      oocyteHeaders
    }
  } catch (error) {
    toast.error({ title:DYNAMIC_HEADER_ERROR})
    throw Error(`createDynamicHeadersFromSpecimens ---> error : ${error}`)
  }
}
