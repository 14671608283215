<template>
  <div
    v-if="shouldDisplay"
    :data-test="`scroll-table-row__robot-locations-${options?.locationType}`"
    class="leading-1 flex items-center justify-end"
    :class="[getStyles.container]"
  >
    <div :class="[getStyles.text]">
      <Tooltip :content="cryoBeaconLocation.siteName" :enabled="enabledTooltip">
        <div>
          <p
            v-if="isRelocationView"
            class="w-28 break-words mr-4 leading-5 whitespace-pre-line"
            :class="{
              'text-tmrw-success': isGreenText
            }"
          >
            {{ locationName }}<br />
            <span class="text-xs">{{ cryoBeaconLocation?.robotName || '' }}</span>
          </p>
          <p
            v-else
            class="mr-4 w-28 break-word whitespace-pre-line"
            :class="{
              'text-tmrw-success': isGreenText
            }"
          >
            {{ cryoBeaconLocation?.robotName || '' }}
          </p>
        </div>
      </Tooltip>
    </div>
    <div
      class="w-6 h-6 border-2 flex relative"
      :class="[
        selectedTicketColor.borderCircle,
        getStyles.container,
        getCircleClass(item, cryoBeaconLocation)
      ]"
    >
      <img
        v-if="hasIcon"
        :src="getIcon.icon"
        :alt="`icon-${getIcon.name}`"
        :data-test="`scrollTableLocation__${getIcon.name}`"
      />
      <i
        v-if="inventoryUpdateCheckIcon"
        data-test="scrollTableLocation__inventory-completed-icon"
        class="far fa-check-circle text-tmrw-success text-xs absolute -bottom-0.5 bg-white -mr-1 rounded-full"
      />
    </div>
    <hr
      v-if="shouldShowHr"
      class="w-3 h-0 border-1"
      :class="[getStyles.progressLine, getProgressLineClass, selectedTicketColor.border]"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { MAX_SITE_LENGTH, SOURCE } from '@/constants'
import { trimString } from '@/utils'
import { getCryoBeaconLocationByType, isDone, isNotReady } from '@/helpers/cryoBeaconLocation'
import {
  isInventoryUpdateNotRequired,
  isInventoryUpdateRequired,
  getCircleClass,
  getTicketStatusIcon,
  isInventoryUpdateDone,
  isStepDone,
  isReplaceCryoBeaconProcedure,
  isScheduled,
  isCryobeaconDiscarded,
  isBeaconMoveProcedure,
  isStepDoneCryoShipper
} from '@/helpers/ticketState'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import { TICKET_SCHEDULED, PROCEDURE_TYPE_CRYOSHIPPER } from '@/constants'

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
    required: true
  },
  options: {
    type: Object,
    default: () => {},
    required: false
  },
  item: {
    type: Object,
    default: () => {},
    required: false
  },
  isRelocationView: {
    type: Boolean,
    default: false,
    required: false
  }
})

const styles = {
  source: {
    container: '',
    circle: '',
    icon: '',
    text: 'text-right',
    progressLine: '-mr-3'
  },
  destination: {
    container: 'flex-row-reverse',
    circle: '',
    icon: '',
    text: 'ml-5',
    progressLine: '-ml-3'
  }
}

const shouldDisplay = computed(() => {
  return cryoBeaconLocation.value?.robotName || props.item.procedure === PROCEDURE_TYPE_CRYOSHIPPER
})

const shouldShowHr = computed(() => {
  return props.item.robotLocations.length > 1 || props.item.procedure === PROCEDURE_TYPE_CRYOSHIPPER
})

const cryoBeaconLocation = computed(() => {
  const locationType = props.options?.locationType
  const locationData = getCryoBeaconLocationByType(props.value, locationType)
  return Object.keys(locationData).length ? { ...locationData, type: locationType } : {}
})

const enabledTooltip = computed(() => {
  return props.isRelocationView && cryoBeaconLocation.value?.siteName?.length >= MAX_SITE_LENGTH
})
const locationName = computed(() => {
  return trimString(cryoBeaconLocation.value.siteName, MAX_SITE_LENGTH, 'end', 'start')
})

const getStyles = computed(() => {
  if (props.options?.locationType === SOURCE) {
    return styles.source
  }

  return styles.destination
})

const getIcon = computed(() => {
  const iconSrc = getTicketStatusIcon(props.item, cryoBeaconLocation.value)

  return iconSrc
})

const hasIcon = computed(() => !!getIcon.value)

const getProgressLineClass = computed(() => {
  const exceptionForReplaceCryobeacon =
    isReplaceCryoBeaconProcedure(props.item) && isScheduled(props.item)

  // Check if the cryobeacon is discarded or the transfer status is not done
  if (
    exceptionForReplaceCryobeacon ||
    (isInventoryUpdateNotRequired(props.item) &&
      isNotReady(props.item?.robotLocations[0]?.transferStatus)) ||
    isNotReady(props.item?.robotLocations[1]?.transferStatus) ||
    (isCryoShipperTicket.value && isCryoShipperScheduled.value)
  ) {
    return 'border-tmrw-gray border-dashed' // Return the class for a dashed border
  } else if (
    isStepDone(props.item, cryoBeaconLocation.value) ||
    isStepDoneCryoShipper(props.item)
  ) {
    return 'border-solid border-tmrw-success' // Return the class for a success border
  }
  return 'border-solid border-tmrw-blue-dark' // Return the class for a gradient background
})

const isCryoShipperTicket = computed(() => {
  return props.item.procedure === PROCEDURE_TYPE_CRYOSHIPPER
})

const isCryoShipperScheduled = computed(() => {
  return props.item.status === TICKET_SCHEDULED
})

const selectedTicketColor = computed(() => {
  return {
    border:
      props.item.selected && !isStepDone(props.item, cryoBeaconLocation.value)
        ? 'border-white'
        : '',
    borderCircle:
      props.item.selected && !isStepDone(props.item, cryoBeaconLocation.value)
        ? 'border-2 border-solid border-white rounded-full'
        : '',
    icon: props.item.selected && isInventoryUpdateRequired(props.item) ? 'text-white' : ''
  }
})

const inventoryUpdateCheckIcon = computed(
  () =>
    !isBeaconMoveProcedure(props.item) &&
    isInventoryUpdateDone(props.item) &&
    !isStepDone(props.item, cryoBeaconLocation.value)
)

const isGreenText = computed(() => {
  return isDone(cryoBeaconLocation.value.transferStatus) || isCryobeaconDiscarded(props.item)
})
</script>
