import state, { BiorepositoryTicketState } from './state'
import getters, { BiorepositoryTicketGetters } from './getters'
import mutations, { BiorepositoryTicketMutations } from './mutations'
import actions, { BiorepositoryTicketActions } from './actions'
import { StoreModule } from '@/store/store-types'

export type BiorepositoryTicketModule = StoreModule<BiorepositoryTicketState, BiorepositoryTicketGetters, BiorepositoryTicketMutations, BiorepositoryTicketActions>


const module: BiorepositoryTicketModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
