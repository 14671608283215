import {
  EMBRYO_COUNT,
  OOCYTE_COUNT,
  SPECIMEN_COUNT,
  TOTAL_SHARED
} from '@/constants'

const getKeyIndex = (headers: any[], key: string) => (
  headers.findIndex((header) => header.key === key)
)

type TotalShared = {
  embryoCount: number,
  oocyteCount: number,
  specimenCount: number
}

const footer = (headers: any[], totalShared: TotalShared) => {
  const columns = new Array(headers.length)

  // First column is the label
  columns[0] = TOTAL_SHARED

  // Sets value of totalShared property into same position of header key
  // This is necessary to display totalCount at the bottom of the embryo/oocyte/specimen count column
  const setFooterCount = (property: 'embryoCount' | 'oocyteCount' | 'specimenCount') => {
    const index = getKeyIndex(headers, property)
    if (index > 0) {
      columns[index] = totalShared[property]
    }
  }

  setFooterCount(EMBRYO_COUNT)
  setFooterCount(OOCYTE_COUNT)
  setFooterCount(SPECIMEN_COUNT)
  return columns
}

export default footer
