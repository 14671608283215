<template>
  <div class="flex justify-start" :class="[columnOptions && columnOptions.cssClasses]">
    <button-dynamic
      data-test="reprint-btn"
      btnText="Reprint"
      btnType="button"
      btnStyle="transparent-blue-no-border"
      btnSize="small-regular"
      showIcon
      floatTo="left"
      fontAwesomeIconClass="print"
      :is-disabled="isReprintDisabled"
      @click="reprintLabel"
    />
    <reprint-label
      :showLabelModal="showLabelModal"
      :cryoDeviceIndex="cryoDeviceReprintIndex"
      :activeBeacon="activeBeacon"
      :activeTicket="activeTicket"
      @closeLabelModal="onCloseLabelModal"
      @clickPrint="onClickPrint"
    />
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ReprintLabel from '@/components/ReprintLabel/ReprintLabel.vue'
import { getInstalledPrinters } from '@/modules/printer'
import { ZEBRA_LABEL_PRINTERS_ID } from '@/constants'
import { sendPrintJob, getBeaconData } from '@/helpers/reprintLabel/reprintLabel'
import { useTicket } from '@/composables'

export default {
  name: 'reprintLabelHeader',
  setup() {
    const { isEMRTicket } = useTicket()

    return {
      isEMRTicket
    }
  },
  data() {
    return {
      activeBeacon: {},
      showLabelModal: false,
      cryoDeviceReprintIndex: null,
      activeTicket: {},
      selectedPrinter: 'pdf',
      selectedPatient: {},
      activePrinters: []
    }
  },
  components: {
    ButtonDynamic,
    ReprintLabel
  },
  props: {
    indexKey: {
      type: Number,
      required: false,
      default: undefined
    },
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    columnOptions: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  mounted() {
    // Browser Print Setup
    getInstalledPrinters().then((localPrinters) => {
      if (localPrinters.printer.length > 0) {
        this.isPrinterActive = true
      } else {
        this.selectedPrinter = 'pdf'
      }
      localPrinters.printer.forEach((printer) => {
        ZEBRA_LABEL_PRINTERS_ID.forEach((printerId) => {
          if (printer.name.includes(printerId)) {
            this.selectedPrinter = printer.uid
            this.activePrinters.push(printer)
          }
        })
      })
    })
  },
  computed: {
    isReprintDisabled() {
      if (this.columnOptions?.isDisabled) {
        return true
      }
      const { activeBeacon, cryoDeviceReprintIndex } = getBeaconData({
        indexKey: this.indexKey,
        item: this.item,
        isEMRTicket: this.isEMRTicket
      })
      return !!activeBeacon?.cryoDevice?.[cryoDeviceReprintIndex]?.metadata?.externalCryolabel
    }
  },
  methods: {
    reprintLabel() {
      const { activeBeacon, cryoDeviceReprintIndex } = getBeaconData({
        item: this.item,
        isEMRTicket: this.isEMRTicket
      })
      this.activeBeacon = activeBeacon
      this.cryoDeviceReprintIndex = cryoDeviceReprintIndex
      this.showLabelModal = true
      return this.showLabelModal
    },
    onCloseLabelModal() {
      this.showLabelModal = false
    },
    changeMetadataReprint(printData) {
      const cryoDevice = this.activeBeacon.cryoDevice[this.cryoDeviceReprintIndex]

      const reprintData = {
        dateTime: printData.dateTime,
        reason: printData.reason
      }

      if (cryoDevice?.metadata?.labelReprints) {
        const reprintHistory = JSON.parse(cryoDevice.metadata.labelReprints)
        reprintHistory.push(reprintData)

        this.activeBeacon.cryoDevice[this.cryoDeviceReprintIndex].metadata.labelReprints =
          JSON.stringify(reprintHistory)
      } else if (cryoDevice?.metadata) {
        this.activeBeacon.cryoDevice[this.cryoDeviceReprintIndex].metadata = {
          ...this.activeBeacon.cryoDevice[this.cryoDeviceReprintIndex].metadata,
          labelReprints: JSON.stringify([reprintData])
        }
      } else {
        this.activeBeacon.cryoDevice[this.cryoDeviceReprintIndex].metadata = {
          labelReprints: JSON.stringify([reprintData])
        }
      }
    },
    onClickPrint(printData) {
      this.selectedPrinter = printData.selectedPrinter

      this.changeMetadataReprint(printData)

      return sendPrintJob({
        selectedPrinter: this.selectedPrinter,
        activePrinters: this.activePrinters,
        activeBeacon: this.activeBeacon,
        cryoDeviceReprintIndex: this.cryoDeviceReprintIndex,
        onCloseLabelModal: this.onCloseLabelModal
      })
    }
  }
}
</script>
