/* eslint-disable */
import defaultState from '../state'

export default {
  createTicket(state, ticket) {
    state.ticket = ticket
  },
  setCurrentStep(state, value) {
    state.ticketStep = value
  },
  resetTicketSteps(state) {
    state.ticketStep = 1
  },
  setMethod(state, value) {
    state.method = value
  },
  setType(state, value) {
    state.type = value
  },
  setTypeEmbryo(state, value) {
    state.typeEmbryo = value
  },
  setPatient(state, value) {
    state.patient = value
  },
  setBatchSharedPatientIds(state, value) {
    state.batchSharedPatientIds = value
  },
  success(state) {
    state.ticketCompletionStatus = 'completed'
  },
  error(state, error) {
    state.ticketCompletionStatus = 'error'
    state.error = error
  },
  loading(state) {
    state.ticketCompletionStatus = 'loading'
  },
  exportStep: (state) => {
    state.ticketStep += 2
  },
  setBeacons(state, beacons) {
    state.beacons = beacons
  },
  setProcedureDateFrom(state, date) {
    state.procedureDateFrom = date
  },
  setProcedureDateTo(state, date) {
    state.procedureDateTo = date
  },
  setProcedureTime(state, time) {
    state.procedureTime = time
  },
  setSelectedMethod(state, selectedMethod) {
    state.selectedMethod = selectedMethod
  },
  setCurrentTicketStep(state, currentTicketStep) {
    state.currentTicketStep = currentTicketStep
  },
  setSelectedCryoDeviceType(state, selectedCryoDeviceType) {
    state.selectedCryoDeviceType = selectedCryoDeviceType
  },
  setSelectedSpecimenType(state, selectedSpecimenType) {
    state.selectedSpecimenType = selectedSpecimenType
  },
  setSelectedPatient(state, selectedPatient) {
    state.selectedPatient = selectedPatient
  },
  setSource(state, source) {
    state.source = source
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setRobotTransfer(state, robotTransfer) {
    state.robotTransfer = robotTransfer
  },
  setShippingSourceId(state, shippingSourceId) {
    state.shippingSourceId = shippingSourceId
  },
  setDonorId(state, donorId) {
    state.donorId = donorId
  },
  setSelectedTypeEmbryo(state, selectedTypeEmbryo) {
    state.selectedTypeEmbryo = selectedTypeEmbryo
  },
  setSubType(state, subType) {
    state.subType = subType
  },
  setBatch(state, batch) {
    state.batch = batch
  },
  resetTicketProcess(state) {
    for (const item in state) {
      state[item] = defaultState[item]
    }
  },
  resetCreateTicketProcess(state) {
    const resetState = {
      beacons: [],
      procedureDateFrom: '',
      procedureDateTo: '',
      procedureTime: '',
      batchSharedPatientIds: [],
      selectedCryoDeviceType: null,
      selectedPatient: null,
      selectedSpecimenType: null,
      source: null,
      metadata: null,
      robotTransfer: null,
      shippingSourceId: null,
      donorId: null,
      selectedTypeEmbryo: null
    }
    // For some reason, the state cannot be replaced
    Object.keys(resetState).forEach(key => {
      state[key] = resetState[key]
    })
  }
}
