<template>
  <MainContainer gridSlots="4">
    <top-header>
      <HeaderSummary :ticketMode="ticketMode" />
    </top-header>

    <ActionBar data-test="select-location_action-bar" colsDistribution="9/3" class="mb-4 h-16">
      <template v-slot:left-side>
        <message-card class="float-left">
          <span>Select the Origin and Destination Locations</span>
        </message-card>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="select-location-cancel-button"
          btnType="button"
          btnText="Cancel"
          btnStyle="secondary"
          @click="handleCancel"
          addMarginRight
        />
        <button-dynamic
          btnDataTest="select-location-button"
          btnType="button"
          btnText="Next"
          iconPosition="right"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="fas fa-arrow-right"
          :isDisabled="!areLocationsSelected"
          @click="handleNext"
        />
      </template>
    </ActionBar>
    <bread-crumbs size="base" :items="breadCrumsItems" />
    <loading-ui modal v-if="isLoaderVisible" />
    <div class="bg-white p-6 rounded-lg h-24">
      <div class="w-1/2 flex">
        <custom-select
          borderColor="tmrw-blue"
          class="pt-0 pb-1.5"
          data-test="select-location__current-location"
          data-testid="select-location__current-location"
          defaultOptionLabel="Choose Origin Site"
          :isLabelVisible="true"
          v-model="v$.currentLocation.$model"
          :inputValue="v$.currentLocation.$model"
          :options="currentLocations"
          @update:modelValue="handleSourceSelect"
        />
        <custom-select
          borderColor="tmrw-blue"
          class="pt-0 pb-1.5 ml-8"
          data-test="select-location__destination"
          data-testid="select-location__destination"
          defaultOptionLabel="Choose Destination Site"
          :isLabelVisible="true"
          v-model="v$.destination.$model"
          :inputValue="v$.destination.$model"
          :options="siteDestinations"
          :disabledSelect="disableDestinationDropdown"
          @update:modelValue="handleDestinationSelect"
        />
        <custom-select
          v-if="showRobotDropdown"
          :disabledSelect="disableCryorobotsDropdown"
          borderColor="tmrw-blue"
          class="pt-0 pb-1.5 ml-8"
          data-test="select-location__cryorobot"
          data-testid="select-location__cryorobot"
          defaultOptionLabel="Choose Destination ID"
          :isLabelVisible="true"
          v-model="v$.cryorobotSelected.$model"
          :inputValue="v$.cryorobotSelected.$model"
          :options="cryorobots"
          @update:modelValue="handleCryorobotSelect"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import HeaderSummary from '@/components/Biorepository/HeaderSummary/HeaderSummary.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'

import { TEMPORARY_STORAGE, TEMPORARY, TICKET_CRYOSHIPPER, TICKET_RELOCATION } from '@/constants'
import {
  STEP_BIOREPOSITORY_FLIGHTBOARD,
  STEP_ADD_CRYOBEACONS,
  STEP_SELECT_RELOCATION_TICKETS
} from '@/constants/moveLocationTicketSteps'
import { useRouter } from 'vue-router'
import { ref, reactive, computed, watch, onMounted } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'

import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'

import { Site, Locations } from '@/store/modules/biorepositoryTicket/types'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'

const router = useRouter()

const currentLocations = ref<Site[]>([])
const siteDestinations = ref<Site[]>([])
const cryorobots = ref<Site[]>([])

const isLoaderVisible = ref(false)

const isHidden = ref(false)

const { allSites, allContainers } = useGetters('siteModule')

const { ticketMode } = useGetters<BiorepositoryTicketModule['getters']>('biorepoTicketModule')

const state = reactive({
  currentLocation: '',
  destination: '',
  cryorobotSelected: ''
})

const showRobotDropdown = computed(() => ticketMode.value === TICKET_RELOCATION && !isHidden.value)

const breadCrumsItems = computed(() => {
  return getBiorepoBreadcrumbs({ active: 'location', ticketMode: ticketMode.value })
})

const hasMultipleCryorobots = computed(() => {
  return cryorobots.value.length > 1
})

const rules = computed(() => ({
  currentLocation: {
    required
  },
  destination: {
    required
  },
  cryorobotSelected: {
    requiredIf: requiredIf(ticketMode.value === TICKET_RELOCATION && hasMultipleCryorobots.value)
  }
}))
const v$ = useVuelidate(rules, state)

const areLocationsSelected = computed(() => {
  // cannot continue if destination has no robots
  let cryorobotSelected = cryorobots.value.length > 0
  if (ticketMode.value === TICKET_RELOCATION) {
    if (hasMultipleCryorobots.value && !isHidden.value) {
      cryorobotSelected = !!state.cryorobotSelected
    } else if (state.currentLocation === state.destination) {
      // cannot continue if source and destination are the same and there is only one robot
      cryorobotSelected = false
    }
  }

  return state.currentLocation && state.destination && cryorobotSelected
})

const disableDestinationDropdown = computed(() => {
  return state.currentLocation === ''
})
const disableCryorobotsDropdown = computed(() => {
  return !(hasMultipleCryorobots.value && state.destination)
})

const { getAllSites } = useActions('siteModule')
const { setLocations, setCryoShipperTickets } =
  useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')
const { setSearchData } = useActions('massCreateModule')

const updateIsHidden = (destination) => {
  const site = siteDestinations.value.find((site) => site.value === Number(destination))
  if (site?.biorepoConfig) {
    isHidden.value = site.biorepoConfig.destinationSiteId === Number(site.value)
  } else {
    isHidden.value = false
  }
}

const handleNext = () => {
  const payload = buildPayload()
  setLocations(payload)
  setSearchData({})
  if (ticketMode.value === TICKET_RELOCATION) {
    router.push({ name: STEP_ADD_CRYOBEACONS })
  } else {
    router.push({ name: STEP_SELECT_RELOCATION_TICKETS })
  }
}
const handleCancel = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}
const handleDestinationSelect = (selectedDestination) => {
  state.destination = selectedDestination
}
const handleSourceSelect = (selectedSource) => {
  state.currentLocation = selectedSource
}
const handleCryorobotSelect = (selectedRobot) => {
  state.cryorobotSelected = selectedRobot
}
const buildPayload = (): Locations => {
  const destinationLocation = siteDestinations.value.find(
    (currentLocation) => currentLocation.value === Number(state.destination)
  )
  const sourceLocation = currentLocations.value.find(
    (currentLocation) => currentLocation.value === Number(state.currentLocation)
  )
  const cryoRobot = cryorobots.value.find(
    (currentCryorobot) => currentCryorobot.value === Number(state.cryorobotSelected)
  )

  const payload: Locations = {
    destinationLocation: {
      id: destinationLocation?.value,
      name: destinationLocation?.label
    },
    sourceLocation: {
      id: sourceLocation?.value,
      name: sourceLocation?.label
    }
  }

  if (ticketMode.value === TICKET_RELOCATION && hasMultipleCryorobots.value) {
    payload.cryorobot = {
      id: cryoRobot?.value,
      name: cryoRobot?.label
    }
  }

  return payload
}
const normalizeCryorobotsList = (selectedSource) => {
  const containersNormalized = allContainers.value.reduce((robotsList, robot) => {
    if (robot.siteId === Number(selectedSource)) {
      if (robot.robotNumber === TEMPORARY) {
        if (state.currentLocation !== state.destination) return robotsList

        return [
          {
            label: TEMPORARY_STORAGE,
            value: robot.containerId
          },
          ...robotsList
        ]
      }

      return [
        ...robotsList,
        {
          label: robot.robotName,
          value: robot.containerId
        }
      ]
    }

    return robotsList
  }, [])

  cryorobots.value = containersNormalized
}

const normalizeDestinationList = (selectedSource) => {
  const selectedSite = currentLocations.value.find(({ value }) => value === Number(selectedSource))
  const isSourceBioRepo = selectedSite?.isBiorepo
  siteDestinations.value = allSites.value.filter(
    ({ value, isBiorepo }) =>
      value !== Number(selectedSource) && (isSourceBioRepo ? !isBiorepo : isBiorepo)
  )
}

onMounted(async () => {
  setCryoShipperTickets([])
  isLoaderVisible.value = true
  try {
    await getAllSites()
    isLoaderVisible.value = false
  } catch (error) {
    isLoaderVisible.value = false
  }
  setLocations({
    destinationLocation: null,
    sourceLocation: null,
    cryorobot: null
  })
  currentLocations.value = [...allSites.value]
  siteDestinations.value = [...allSites.value]
})

watch(
  () => state.currentLocation,
  (value) => {
    state.destination = ''
    state.cryorobotSelected = ''
    normalizeCryorobotsList(value)
    if (ticketMode.value === TICKET_CRYOSHIPPER) {
      normalizeDestinationList(value)
    }
  }
)

watch(
  () => state.destination,
  (value) => {
    state.cryorobotSelected = ''
    normalizeCryorobotsList(value)
    updateIsHidden(value)
  }
)
</script>
