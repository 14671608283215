import * as ss from '@/config/session-storage-help'

const $ss = ss

export default {
  status: null,
  token: $ss.getFieldSessionStorage('user', 'token') || null,
  tokenExpiresAt: $ss.getFieldSessionStorage('user', 'tokenExpiresAt') || null,
  appBaseConfig: {
    siteId: $ss.getFieldSessionStorage('config', 'siteId') || null,
    clinicId: $ss.getFieldSessionStorage('config', 'clinicId') || null,
    siteName: $ss.getFieldSessionStorage('config', 'siteName') || null,
    clinicName: $ss.getFieldSessionStorage('config', 'clinicName') || null,
    collectionProtocolId: $ss.getFieldSessionStorage('config', 'collectionProtocolId') || null
  },
  loggedUserInfo: {
    userId: $ss.getFieldSessionStorage('user', 'userId') || null,
    loginName: $ss.getFieldSessionStorage('user', 'userName') || null,
    userType: $ss.getFieldSessionStorage('user', 'userType') || null
  }
}
