import { httpRequestSingleVialReader } from '@/helpers/apiHelpers'
import { GetRegistrationStatus, GetScanBeaconIdApi } from './types'

export const getScanBeaconIdApi = (
  options: GetScanBeaconIdApi = {}
) => httpRequestSingleVialReader().get(
  '/SingleVialReader',
  { ...options }
)

export const getSVRStatus = () => httpRequestSingleVialReader().get('/ConnectionStatus')

export const getRegistrationStatusApi = ({
  status
}: GetRegistrationStatus) => httpRequestSingleVialReader().get('/RegistrationStatus', {
  params: { status }
})
