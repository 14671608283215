export default {
  setCalendarMinDate({ commit }, payload) {
    commit('setCalendarMinDate', payload)
  },
  setCalendarMaxDate({ commit }, payload) {
    commit('setCalendarMaxDate', payload)
  },
  setPerformedOnCalendarDate({ commit }, payload) {
    commit('setPerformedOnCalendarDate', payload)
  },
  setTicketToUpdate({ commit }, payload) {
    commit('setTicketToUpdate', payload)
  }
}
