import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  GRADE_CAPITALS,
  CRYO_DATE_CAPITALS,
  BIOPSY_CAPITALS,
  NOTES,
  DYNAMIC_HEADER_ERROR,
  MAX_LABEL_LENGTH
} from '@/constants'
import toast from '@/config/toast'
import { timestampToUTCFilter } from '@/filters/index'

dayjs.extend(utc)

const specimenId = [
  {
    name: '',
    key: 'specimenId',
    unique: true,
    visible: false
  }
]

export const currentLabelID = {
  name: 'Current Label ID',
  key: 'currentLabelId',
  cssClasses: ['w-32']
}

export const dynamicEmbryo = ({
  reprintLabelShow = false,
  isImportTicket = false,
  patientsTooltip = false,
  reprintDisabled = false
}: {
  reprintLabelShow?: boolean
  isImportTicket?: boolean
  patientsTooltip?: boolean
  reprintDisabled?: boolean
}) => {
  try {
    return [
      ...specimenId,
      {
        name: 'Cryodevice ID',
        key: 'cryodeviceBarcode',
        cssClasses: ['w-32', 'whitespace-nowrap'],
        componentOptions: {
          alignment: 'right',
          theme: 'light',
          maxLength: MAX_LABEL_LENGTH,
          ellipsis: 'start'
        },
        sort: true
      },
      {
        name: 'Reprint Label',
        component: 'reprintLabelHeader',
        cssClasses: !reprintLabelShow ? ['force-hidden'] : [''],
        isDisabled: reprintDisabled
      },
      {
        name: 'Additional Patient',
        component: 'AdditionalPatientsTooltip',
        cssClasses: !patientsTooltip ? ['force-hidden'] : [''],
        componentOptions: {
          patientsTooltip
        }
      },
      {
        name: 'Count x Type',
        alignment: 'left',
        filters: [
          (value: string, item: { embryo: { embryoType: string } }) =>
            `${value} x ${
              item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO
            }`
        ],
        key: 'specimenCount',
        sort: true
      },
      {
        name: `${SPECIMEN_TYPE_EMBRYO} #`,
        key: 'embryo',
        filters: [(value: { embryoNumber: number }) => (value && value ? value.embryoNumber : '')]
      },
      {
        name: GRADE_CAPITALS,
        key: 'embryo',
        filters: [(value: { grade: string }) => (value && value ? value.grade : '')],
        sort: true
      },
      {
        name: CRYO_DATE_CAPITALS,
        key: 'cryoDate',
        filters: timestampToUTCFilter,
        sort: true
      },
      {
        name: BIOPSY_CAPITALS,
        key: 'embryo',
        filters: [(value: { biopsy: string }) => (value && value ? value.biopsy : '')],
        sort: true
      },
      {
        name: 'Result',
        key: 'embryo.biopsyResult',
        sort: true,
        component: 'tooltip',
        componentOptions: {
          alignment: 'left',
          maxlength: 10
        }
      },
      {
        name: NOTES,
        key: 'specimenInfo',
        component: 'tooltip',
        componentOptions: {
          alignment: 'left',
          maxlength: 10
        }
      },
      {
        name: 'Current Label ID',
        key: 'currentLabelId',
        cssClasses: !isImportTicket ? ['force-hidden'] : ['']
      },
      {
        name: '',
        key: 'isImport',
        cssClasses: !isImportTicket ? ['force-hidden'] : ['']
      }
    ]
  } catch (error) {
    toast.error({ title: DYNAMIC_HEADER_ERROR })
    throw new Error(`dynamicEmbryo -> error : ${error}`)
  }
}

export const dynamicEgg = ({
  reprintLabelShow = false,
  isImportTicket = false,
  patientsTooltip = false,
  reprintDisabled = false
}: {
  reprintLabelShow?: boolean
  isImportTicket?: boolean
  patientsTooltip?: boolean
  reprintDisabled?: boolean
}) => {
  try {
    return [
      ...specimenId,
      {
        name: 'Cryodevice ID',
        key: 'cryodeviceBarcode',
        cssClasses: ['whitespace-nowrap'],
        componentOptions: {
          alignment: 'right',
          theme: 'light',
          maxLength: MAX_LABEL_LENGTH,
          ellipsis: 'start'
        },
        sort: true
      },
      {
        name: 'Reprint Label',
        component: 'reprintLabelHeader',
        cssClasses: !reprintLabelShow ? ['force-hidden'] : [''],
        isDisabled: reprintDisabled
      },
      {
        name: 'Additional Patient',
        component: 'AdditionalPatientsTooltip',
        cssClasses: !patientsTooltip ? ['force-hidden'] : [''],
        componentOptions: {
          patientsTooltip
        }
      },
      {
        name: 'Count x Type',
        alignment: 'left',
        key: 'specimenCount',
        filters: [
          (value: string, item: { oocyte: { maturityLevel: string } }) =>
            `${value} x ${
              item?.oocyte?.maturityLevel ? `${item.oocyte.maturityLevel}` : SPECIMEN_TYPE_OOCYTE
            }`
        ],
        sort: true
      },
      {
        name: CRYO_DATE_CAPITALS,
        key: 'cryoDate',
        filters: timestampToUTCFilter,
        sort: true
      },
      {
        name: NOTES,
        key: 'specimenInfo',
        component: 'tooltip',
        componentOptions: {
          displayDotsIcon: false,
          alignment: 'left',
          maxlength: 10
        }
      },
      {
        name: 'Current Label ID',
        key: 'currentLabelId',
        cssClasses: !isImportTicket ? ['force-hidden'] : ['']
      },
      {
        name: '',
        key: 'isImport',
        cssClasses: !isImportTicket ? ['force-hidden'] : ['']
      }
    ]
  } catch (error) {
    toast.error({title: DYNAMIC_HEADER_ERROR})
    throw new Error(`dynamicEgg ---> error : ${error}`)
  }
}
