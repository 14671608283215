import dayjs from 'dayjs'
import * as ss from '@/config/session-storage-help'
import { Patient, SpecimenType, Beacon } from '@/types'
import { METHOD_FREEZE, METHOD_IMPORT, EXISTING_BEACON_MANUAL_SCAN } from '@/constants'
import { getScanBeaconIdApi } from '@/services/singleVialReader'
import { STEP_SCAN_BEACON } from '@/constants/ticketSteps'
import store from '../../store'
import {
  StepType,
  MethodType,
  ProcedureDate,
  SelectedCryoDeviceType,
  SingleVialReaderStatus,
  MetadataType,
  ThawEmbryoTypes
} from './types'
import { beaconsFromSelectedTicketsType } from '../detailsHelper'

interface VerifyUserPermissionInput {
  selectedMethod: MethodType
  currentTicketStep: StepType
}
// GetUserPermissions on the old nextSteps() function
export async function verifyUserPermission({
  selectedMethod,
  currentTicketStep
}: VerifyUserPermissionInput) {
  const permissions = ss.getFieldSessionStorage('user', 'permissions') || []
  const userHasManualScanPermission = permissions.includes(EXISTING_BEACON_MANUAL_SCAN)
  const isStorageMethod = selectedMethod === METHOD_FREEZE || selectedMethod === METHOD_IMPORT
  const isStepAllowed = currentTicketStep !== STEP_SCAN_BEACON
  // const { data: { connectionStatus: svrStatus } } = await getSVRStatus()
  if (!userHasManualScanPermission && isStorageMethod && isStepAllowed) {
    try {
      // Throw error if SVR is disconnected.
      // eslint-disable-next-line no-throw-literal
      // if (svrStatus === 'disconnected') throw 'Registration Tray is disconnected!'
      // Get Single Vial Reader Read to determine if there is a beacon inserted. If so, do not allow user to proceed.
      const svrData = await getScanBeaconIdApi({ timeout: 250 })
      if (svrData.data.tagDetected) {
        store.dispatch(
          'modalModule/displayModal',
          [
            'Registration Tray',
            'There is an unexpected CryoBeacon in the RFID reader on the Registration Tray. Please remove it to continue.'
          ],
          { root: true }
        )

        return SingleVialReaderStatus.CRYOBEACON_DETECTED
      }
      return SingleVialReaderStatus.CRYOBEACON_NOT_DETECTED
    } catch (err) {
      const errorAsString = JSON.stringify(err)
      if (errorAsString.includes('250ms exceeded')) {
        return SingleVialReaderStatus.CRYOBEACON_NOT_DETECTED
      }
      store.dispatch(
        'modalModule/displayModal',
        [
          'Registration Tray Error',
          'There is a problem with the Registration Tray. Registration Tray is disconnected!.'
        ],
        { root: true }
      )

      return SingleVialReaderStatus.REGISTRATION_TRAY_NOT_DETECTED
    }
  }
  return SingleVialReaderStatus.SINGLE_VIAL_READER_STATUS_IGNORED
}

export function resetTicketProcess() {
  // TODO: update removeFieldSessionStorage for typescript
  // @ts-ignore
  if (ss.getFieldSessionStorage('newTicket')) {
    // @ts-ignore
    ss.removeFieldSessionStorage('newTicket')
  }

  // @ts-ignore
  if (ss.getFieldSessionStorage('batch')) {
    // @ts-ignore
    ss.removeFieldSessionStorage('batch')
  }

  store.dispatch('beaconsModule/resetBeaconReaderReads')

  store.dispatch('patientsModule/setDonatePatient', { value: [] })
  store.dispatch('patientGroupModule/resetPatients')

  store.dispatch('newTicketModule/resetCreateTicketProcess')

  // @ts-ignore
  if (ss.getFieldSessionStorage('process')) {
    ss.removeFieldSessionStorage('process', 'selectedTicketToEdit')
  }
}

export function setSelectedMethod(selectedMethod: MethodType) {
  ss.setFieldSessionStorage('selectedMethod', selectedMethod, 'newTicket')
  store.dispatch('newTicketModule/setSelectedMethod', selectedMethod)
}

export function setCurrentTicketStep(currentTicketStep: StepType) {
  ss.setFieldSessionStorage('currentTicketStep', currentTicketStep, 'newTicket')
  store.dispatch('newTicketModule/setCurrentTicketStep', currentTicketStep)
}

export function setSelectedSpecimenType(selectedSpecimenType: SpecimenType | null) {
  ss.setFieldSessionStorage('selectedSpecimenType', selectedSpecimenType, 'newTicket')
  store.dispatch('newTicketModule/setSelectedSpecimenType', selectedSpecimenType)
}

export function setSelectedCryoDeviceType(selectedCryoDeviceType: SelectedCryoDeviceType) {
  ss.setFieldSessionStorage('selectedCryoDeviceType', selectedCryoDeviceType, 'newTicket')
  store.dispatch('newTicketModule/setSelectedCryoDeviceType', selectedCryoDeviceType)
}

export function setSelectedPatient(patients: Patient[]) {
  ss.setFieldSessionStorage('selectedPatient', patients, 'newTicket')
  store.dispatch('newTicketModule/setSelectedPatient', patients)
}
export function setBatchSharedPatientIds(sharedPatientIds: number[][]) {
  store.dispatch('newTicketModule/setBatchSharedPatientIds', sharedPatientIds)
}

export function getDateDifference(selectedDate: number) {
  const now = dayjs().valueOf()
  return selectedDate - now
}

export function isFutureDate(procedureDate: ProcedureDate) {
  const { procedureDateFrom, procedureDateTo } = procedureDate

  const diffFrom = getDateDifference(procedureDateFrom)
  const diffTo = getDateDifference(procedureDateTo)

  return diffFrom > 0 || diffTo > 0
}

export function setSelectedProcedureDate(procedureDate: ProcedureDate) {
  ss.setFieldSessionStorage('procedureDateFrom', procedureDate.procedureDateFrom, 'newTicket')
  ss.setFieldSessionStorage('procedureDateTo', procedureDate.procedureDateTo, 'newTicket')
  ss.setFieldSessionStorage('procedureTime', procedureDate.procedureTime, 'newTicket')

  store.dispatch('newTicketModule/setProcedureDateFrom', procedureDate.procedureDateFrom)
  store.dispatch('newTicketModule/setProcedureDateTo', procedureDate.procedureDateTo)
  store.dispatch('newTicketModule/setProcedureTime', procedureDate.procedureTime)
}

export function setBeacons(beacons: Beacon[] | beaconsFromSelectedTicketsType[]) {
  ss.setFieldSessionStorage('beacons', beacons, 'newTicket')
  store.dispatch('newTicketModule/setBeacons', beacons)
}

export function setBeaconBarcodeNumber(beaconBarcodeNumber: string) {
  store.dispatch('beaconsModule/setSingleBeaconReaderReads', beaconBarcodeNumber)
  // @ts-ignore
  ss.setFieldSessionStorage('lastScannedBeacon', beaconBarcodeNumber)
}

export function setShippingSourceId(shippingSourceId?: string) {
  store.dispatch('newTicketModule/setShippingSourceId', shippingSourceId)
  ss.setFieldSessionStorage('shippingSourceId', shippingSourceId, 'newTicket')
}

export function setDonorId(donorId?: string) {
  store.dispatch('newTicketModule/setDonorId', donorId)
  ss.setFieldSessionStorage('donorId', donorId, 'newTicket')
}

export function setMetadata(metadata: MetadataType) {
  store.dispatch('newTicketModule/setMetadata', metadata)
  ss.setFieldSessionStorage('metadata', metadata, 'newTicket')
}

export function setSelectedTypeEmbryo(selectedTypeEmbryo: ThawEmbryoTypes) {
  store.dispatch('newTicketModule/setSelectedTypeEmbryo', selectedTypeEmbryo)
  ss.setFieldSessionStorage('selectedTypeEmbryo', selectedTypeEmbryo, 'newTicket')
}

export function setSubType(batchType: string) {
  store.dispatch('newTicketModule/setSubType', batchType)
  ss.setFieldSessionStorage('subType', batchType, 'newTicket')
}

export function setBatchData(batch: object) {
  store.dispatch('newTicketModule/setBatch', batch)
  ss.setFieldSessionStorage('batch', batch, '')
}
