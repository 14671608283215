<template>
  <MainContainer data-test="flight-board" gridSlots="3">
    <top-header>Embryologist</top-header>
    <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          {{ selectEmbryologistViewTitle }}
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          @click="onClickBack"
          addMarginRight
        />
        <button-dynamic
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          :is-disabled="isNextButtonDisabled"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          @click="goToCalendar"
        />
      </template>
    </ActionBar>
    <ScrollTableComponent
      ref="scrollTable"
      v-model="localUsersListModel"
      :selectionMode="'radio'"
      tableWrapperClasses="overflow-hidden"
      :headers="headers"
      :minHeight="'h-full'"
    />
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { whoPerformedThe } from '@/constants'

dayjs.extend(utc)

export default {
  name: 'select-embryologist',
  data() {
    return {
      localUsersListModel: {
        sort: {
          orderBy: 'loginName',
          direction: 'asc'
        },
        options: []
      },
      selectedItem: [],
      realFromDate: null,
      performedOn: null,
      headers: [
        {
          name: 'User Name',
          key: 'loginName'
        },
        {
          name: 'User ID',
          key: 'id'
        },
        {
          name: 'First Name',
          key: 'firstName'
        },
        {
          name: 'Last Name',
          key: 'lastName'
        },
        {
          name: 'Email',
          key: 'emailAddress'
        },
        {
          name: '',
          key: 'userRole',
          sort: true,
          component: 'ScrollTableUserRole'
        }
      ],
      calendarMode: 'single',
      saveInProgress: false,
      activeTicket: 0
    }
  },
  created() {
    this.activeTicket = this.selectedTickets.find(
      (ticket) => ticket.ticketId === Number(this.$route.params.ticketId)
    )
  },
  async mounted() {
    this.displaySpinner('Loading Embryologist')
    await this.fetchActiveUsers()
    if (!this.calendarMaxDate || !this.performedOnCalendarDate) {
      const currentTicket = this.getCurrentlyVisibleTicket(Number(this.$route.params.ticketId))
      const calendarMinDate = Number(currentTicket.procedureTime)
      const calendarMaxDate = dayjs().valueOf()
      // eslint-disable-next-line no-underscore-dangle
      const performedOnCalendarDate = this.$router?.currentRoute._value.query.procedureTime
        ? // eslint-disable-next-line no-underscore-dangle
          Number(this.$router?.currentRoute._value.query.procedureTime)
        : calendarMinDate
      this.setCalendarMinDate(calendarMinDate)
      this.setCalendarMaxDate(calendarMaxDate)
      this.setPerformedOnCalendarDate(performedOnCalendarDate)
    }
    setTimeout(() => {
      this.hideSpinner()
    }, 500)
  },
  watch: {
    localUsersList(newValue) {
      if (newValue) {
        this.localUsersListModel = {
          ...this.localUsersListModel,
          options: this.localUsersList.map((user) => ({
            ...user,
            selected:
              user.id ===
              this.getCurrentlyVisibleTicket(Number(this.$route.params.ticketId)).embryologistId
          }))
        }
        this.$nextTick(() => {
          this.preselectUser()
        })
      }
    },
    localUsersListModel(newValue) {
      if (newValue) {
        const { options } = this.localUsersListModel
        this.selectedItem = options.filter((option) => option.selected)
      }
    }
  },
  computed: {
    ...mapGetters('usersModule', ['usersList']),
    ...mapGetters('calendarModule', ['calendarMaxDate', 'performedOnCalendarDate']),
    ...mapGetters('selectedTicketsModule', ['selectedTickets']),
    ...mapGetters('authModule', ['loggedUserInfo']),

    localUsersList() {
      return this.usersList ? this.usersList : []
    },
    isNextButtonDisabled() {
      return this.selectedItem && this.selectedItem.length === 0
    },
    selectEmbryologistViewTitle() {
      const ticket = this.getCurrentlyVisibleTicket(Number(this.$route.params.ticketId))
      return whoPerformedThe(ticket?.procedureType.toLowerCase())
    }
  },
  methods: {
    ...mapActions('usersModule', ['fetchActiveUsers']),
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    ...mapActions('calendarModule', [
      'setCalendarMinDate',
      'setCalendarMaxDate',
      'setPerformedOnCalendarDate',
      'setTicketToUpdate'
    ]),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    handleDateTime(dateValue) {
      this.performedOn = dateValue.timestamp
    },
    handleCalendarMode(mode) {
      this.calendarMode = mode
    },
    async goToCalendar() {
      const [selectedEmbriologist] = this.selectedItem
      const newTicket = {
        embryologistId: selectedEmbriologist.id,
        performedOn: this.performedOn,
        ticketId: this.$route.params.ticketId
      }

      this.setTicketToUpdate(newTicket)
      this.displaySpinner('Processing')

      /**
       * @TODO - please remove this timer as soon as the calendar been fixed
       * @Reason  the use of this timer is in order to been able to show the modal as the default execution is not working as been blocked by the maximum call stack error faced on the calendar, because of this the only possible way to execute the call ( display the modal ) is to pause the router redirection ( to the calendar view, where the error is), on this way we get a functional modal view */

      setTimeout(() => {
        this.$router?.push('/select-procedure-date-time/')
        this.hideSpinner()
      }, 1000)
    },
    onClickBack() {
      this.$router.push('/selected-tickets')
    },
    getCurrentlyVisibleTicket(ticketId) {
      return this.selectedTickets.find((ticket) => ticket.ticketId === Number(ticketId))
    },
    preselectUser() {
      const userId = this.loggedUserInfo.userId
      const userIndex = this.localUsersList.findIndex((user) => user.id === userId)
      if (userIndex !== -1) {
        this.localUsersListModel.options[userIndex].selected = true
        this.selectedItem = [this.localUsersListModel.options[userIndex]]
        this.$nextTick(() => {
          const scrollTable = this.$refs.scrollTable.$el
          const userRow = scrollTable.querySelectorAll('.scroll-table-row')[userIndex]
          if (userRow) {
            userRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        })
      }
    }
  },
  components: {
    TopHeader,
    MainContainer,
    ButtonDynamic,
    ScrollTableComponent,
    ActionBar,
    DynamicTitle
  }
}
</script>
