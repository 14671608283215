<template>
  <button
    @click="handleButtonClick()"
    :id="btnId"
    :class="[
      'focus:outline-none leading-4',
      {
        'top-0 right-0 mr-5 mt-5 bg-white !rounded-full shadow-lg hover:bg-tmrw-green absolute w-12 h-12':
          btnStyle === 'closeMenu',
        'z-40 bg-white !rounded-full p-5 cursor-pointer flex items-center justify-center shadow-lg w-10 h-10 text-xl':
          btnStyle === 'hamburguer',
        'bg-tmrw-green hover:bg-tmrw-green-light border border-tmrw-blue shadow-lg text-tmrw-blue-dark':
          btnStyle === 'primary',
        'bg-white text-tmrw-blue hover:text-black border border-black shadow':
          btnStyle === 'secondary',
        'bg-transparent text-tmrw-green': btnStyle === 'transparent',
        'bg-transparent text-white border': btnStyle === 'transparent-white',
        'bg-transparent text-tmrw-blue border': btnStyle === 'transparent-blue',
        'bg-tmrw-blue-light text-white': btnStyle === 'tmrw-blue-light',
        'bg-transparent text-tmrw-blue': btnStyle === 'transparent-blue-no-border',
        'bg-transparent border border-tmrw-blue-dark': btnStyle === 'transparent-blue-dark',
        'bg-transparent text-white border pb-4': btnStyle === 'transparent-white-search',
        '!rounded-full bg-white w-10 h-10 text-xl text-tmrw-blue-dark': btnStyle === 'back-button',
        '!rounded-full bg-white w-5 h-5 text-md': btnStyle === 'icon-button',
        'rounded-md bg-white text-tmrw-blue-dark w-8 h-5 text-md flex items-center justify-center':
          btnStyle === 'icon-button-alt',
        '!rounded-full bg-white w-5 h-5 text-md': btnStyle === 'close-button',
        '!rounded-full bg-white w-5 h-5 text-md': btnStyle === 'close-button-blue',
        'bg-tmrw-gray bg-opacity-50 text-tmrw-blue text-base': btnStyle === 'gray',
        'bg-tmrw-error text-white': btnStyle === 'error-button',
        'bg-tmrw-blue text-white': btnStyle === 'blue-background',
        'font-bold': isFontBold,
        'ml-4': addMarginLeft,
        'mr-4': addMarginRight,
        'mt-4': addMarginTop,
        'mb-4': addMarginBottom,
        '!px-0': hasNoXPadding,
        'rounded-lg': hasRoundBorders,
        'py-12 text-2xl w-64': btnSize === 'large',
        'py-24 text-5xl w-96 font-bold': btnSize === 'xlarge',
        'px-4 py-2 text-lg flex justify-center items-center': btnSize === 'regular',
        'px-4 py-2 text-base flex justify-start items-center leading-3':
          btnSize === 'small-regular',
        'text-xs flex justify-start items-center leading-3': btnSize === 'xsmall'
      },
      isDisabled ? 'opacity-75 cursor-not-allowed' : 'cursor-pointer',
      ...addClasses
    ]"
    :data-test="btnId"
    :style="btnStyles"
    :type="btnType"
    :disabled="isDisabled"
  >
    <div v-if="isLoading" class="w-16 h-6 flex items-center justify-center">
      <div class="dot-flashing" role="status" />
    </div>
    <i
      :data-test="buttonIconDataTest"
      v-if="showIcon && !isLoading && iconPosition === 'left'"
      :title="iconTitle"
      :class="[
        'is-icon-left',
        btnStyle === 'hamburguer'
          ? ''
          : btnStyle === 'closeMenu'
            ? 'text-2xl'
            : btnStyle === 'back-button' ||
                btnStyle === 'close-button' ||
                btnStyle === 'close-button-blue' ||
                btnStyle === 'icon-button' ||
                btnStyle === 'icon-button-alt'
              ? ''
              : 'mr-3',
        showFontAwesomeIcon,
        isDisabled ? 'opacity-50' : '',
        {
          'text-tmrw-blue': btnStyle === 'close-button-blue',
          'text-tmrw-blue text-xs': btnStyle === 'xsmall'
        },
        ...addIconClasses
      ]"
    />
    <span
      :data-test="buttonTextDataTest"
      :class="[
        'h-6 flex items-center justify-center font-exo',
        isDisabled ? 'opacity-50' : '',
        isTextUnderlined ? 'underline' : '',
        {
          'text-4xl': btnSize === 'large',
          'font-semibold': btnSize === 'regular',
          'text-base font-semibold': btnSize === 'small-regular',
          'text-xs': btnStyle === 'xsmall'
        }
      ]"
      v-if="!isLoading && btnText"
    >
      {{ btnText }}
      <i
        :data-test="buttonIconDataTest"
        v-if="showIcon && !isLoading && iconPosition === 'right'"
        :class="[
          'is-icon-right',
          btnStyle === 'hamburguer' ? '' : 'ml-3',
          showFontAwesomeIcon,
          isDisabled ? 'opacity-50' : '',
          ...addIconClasses
        ]"
      />
    </span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
import generateElementId from '@/helpers/elementIdGenerator'
import { formatFAIcon } from '@/utils'

export default {
  name: 'button-dynamic',
  emits: ['click'],
  props: {
    overrideDefaultClasses: {
      type: Boolean,
      default: false,
      required: false
    },
    // Btn Primary Text.
    btnText: {
      type: String,
      required: false
    },
    // Btn Type. Same as HTML element (button, reset, submit).
    btnType: {
      type: String,
      required: false,
      default: 'button'
    },
    // Set Button Color.
    btnStyle: {
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'primary',
          'secondary',
          'transparent',
          'transparent-white',
          'transparent-blue',
          'transparent-blue-no-border',
          'transparent-blue-dark',
          'transparent-white-search',
          'closeMenu',
          'hamburguer',
          'back-button',
          'close-button-blue',
          'icon-button',
          'icon-button-alt',
          'error-button',
          'xsmall',
          'close-button',
          'gray'
        ].includes(value),
      required: false
    },
    // Define Button size
    btnSize: {
      type: String,
      default: 'regular',
      validator: (value) =>
        ['xsmall', 'small-regular', 'regular', 'large', 'xlarge'].includes(value),
      required: false
    },
    isFontBold: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add margin to the left.
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add rounded border to button. True by default.
    hasRoundBorders: {
      type: Boolean,
      default: true,
      required: false
    },
    // Add underline to button text.
    isTextUnderlined: {
      type: Boolean,
      default: false,
      required: false
    },
    // Btn specific style for look and feel (Different than AddClass, which adds custom classes).
    btnStyleClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Display btn loader is boolean is passed on this prop.
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    // Add custom classes to the btn.
    addClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Add custom classes to the icon.
    addIconClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    // Loading Spinner moving element color.
    spinnerHexForeColor: {
      type: String,
      default: '#3a8dde',
      required: false
    },
    // Loading Spinner Background color. Has to be RGBA with opacity.
    spinnerRgbaBgColor: {
      type: String,
      default: '90, 90, 90, .2',
      required: false
    },
    // Btn disabled when this prop is true.
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // Float button to left or right. Default is "right"
    floatTo: {
      type: String,
      default: 'right',
      required: false
    },
    // Show Icon. Any class linked to an Icon library (Currently Font Awesome).
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    // Name of the class that will show the Icon from selected library (Currently Font Awesome).
    fontAwesomeIconClass: {
      type: String,
      default: 'print',
      required: false
    },
    fontAwesomeStyle: {
      type: String,
      default: 'solid',
      validator: (value) => ['solid', 'regular'].includes(value),
      required: false
    },
    // Icon Position
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
      required: false
    },
    // Icon alternative tooltip
    iconTitle: {
      type: String,
      required: false
    },
    // Add custom styles to button.
    customStyles: {
      type: String,
      default: '',
      required: false
    },
    // Add data test to button
    btnDataTest: {
      type: String,
      default: 'btn-dynamic'
    },
    //removes x padding
    hasNoXPadding: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    handleButtonClick() {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(`Click on ${this.btnText || this.btnStyle} at ${viewName} view`, {
        component: 'Button Dynamic',
        'button-id': this.btnId,
        'button-text': this.btnText,
        'button-type': this.btnType,
        'current-route': currentRoute,
        'view-name': viewName,
        'redirected-from': redirectedFrom,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
      this.$emit('click')
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    btnId() {
      const getButtonName = () => {
        const hasText = Boolean(this.btnText)
        if (hasText) {
          return this.btnText.split(' ').join('-').toLowerCase()
        }
        return this.btnDataTest
      }
      const name = getButtonName()
      return generateElementId({
        route: this.$route,
        parent: this.$parent,
        type: 'btn',
        name
      })
    },
    // Compose Spinner color and background-color from Props.
    spinnerStyle() {
      return `border-right-color: ${this.spinnerHexForeColor}; color: rgba(${this.spinnerRgbaBgColor});`
    },
    // Add Styles to float button. IMPORTANT: This should be refactored when using TailwindCSS.
    btnStyles() {
      return `float: ${this.floatTo}; ${this.customStyles}`
    },
    // Add Icon name class to Button to show the proper Icon (Currently Font Awesome).
    // This should be refactored if any additional library is added
    showFontAwesomeIcon() {
      return formatFAIcon(this.fontAwesomeIconClass)
    },
    buttonIconDataTest() {
      return `${this.btnDataTest}-icon`
    },
    buttonTextDataTest() {
      return `${this.btnDataTest}-text`
    }
  }
}
</script>
