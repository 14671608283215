<template>
  <MainContainer gridSlots="2" data-test="select-thaw-procedure">
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <select-thaw-procedure-buttons
      :baseData="baseData"
      :nextStep="handleSelectTypeEmbryo"
    />
  </MainContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import SelectThawProcedureButtons from '@/components/CreateTicket/SelectThawProcedureButtons/SelectThawProcedureButtons.vue'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import {
  FET,
  BIOPSY_CAPITALS
} from '@/constants'

export default {
  name: 'select-thaw-procedure',
  data() {
    return {
      baseData: {
        typeEmbryo: [BIOPSY_CAPITALS, FET]
      }
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod'])
  },
  methods: {
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })

      this.$router.replace({ name: previousStepPath })
    },
    async handleSelectTypeEmbryo(selectedTypeEmbryo) {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        selectedTypeEmbryo
      })

      this.$router.push({ name: nextStepPath })
    }
  },
  components: {
    TopHeader,
    HeaderInfo,
    SelectThawProcedureButtons,
    MainContainer
  }
}
</script>
