import { API_COMPLETED, API_ERROR, API_LOADING } from '@/constants'
import { UsersState } from '../state'
import { User, UserRole, UserAlert } from '@/services/user/types'

const mutations = {
  fetchUsers(state: UsersState, usersList: User[]) {
    state.usersList = usersList
  },
  fetchUser(state: UsersState, user: User) {
    state.user = user
  },
  fetchUserRoles(state: UsersState, roles: UserRole[]) {
    state.roles = roles
  },
  fetchUserEmailAlerts(state:UsersState, userEmailAlerts: UserAlert[]) {
    state.userEmailAlerts = userEmailAlerts
  },
  createUser(state: UsersState, user: User) {
    state.user = user
  },
  editUser(state: UsersState, user: User) {
    state.user = user
  },
  editUserEmailAlerts(state: UsersState, userEmailAlerts: UserAlert[]) {
    state.userEmailAlerts = userEmailAlerts
  },
  success(state: UsersState) {
    state.status = API_COMPLETED
  },
  error(state: UsersState, error) {
    state.status = API_ERROR
    state.userModuleError = error
    state.resetPasswordStatus = API_ERROR
  },
  loading(state: UsersState) {
    state.status = API_LOADING
  },
  successLockStatus(state: UsersState) {
    state.lockStatus = API_COMPLETED
  },
  errorLockStatus(state: UsersState, error) {
    state.lockStatus = API_ERROR
    state.error = error
  },
  loadingLockStatus(state: UsersState) {
    state.lockStatus = API_LOADING
  },
  successResetPasswordStatus(state: UsersState) {
    state.resetPasswordStatus = API_COMPLETED
  },
  errorResetPasswordStatus(state: UsersState, error) {
    state.resetPasswordStatus = API_ERROR
    state.error = error
  },
  loadingResetPasswordStatus(state: UsersState) {
    state.resetPasswordStatus = API_LOADING
  }
}

export type UsersMutations = typeof mutations

export default mutations
