import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { timestampToUTCFilter } from '@/filters/index'
import { sourcesValueHeader } from '@/constants/table-headers/patient/details'
import {
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_BIOPSY_NO,
  MAX_LABEL_LENGTH
} from '@/constants'

dayjs.extend(utc)

const specimenId = [
  {
    name: '',
    key: 'cryodeviceBarcode',
    visible: false,
    unique: true
  }
]

const updateScreeningStatusFields = [
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    component: 'screeningStatusSelect',
    componentOptions: {
      selectAll: true
    },
    alignment: 'left',
    cssClasses: ['w-40']
  }
]

export const embryo = [
  ...specimenId,
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-24', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-12'],
    filters: [
      (value, item) =>
        `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ]
  },
  {
    name: 'Embryo #',
    alignment: 'left',
    key: 'embryo.embryoNumber',
    sort: true,
    cssClasses: ['w-24', 'max-w-24']
  },
  {
    name: 'Grade',
    alignment: 'left',
    key: 'embryo.grade',
    sort: true,
    cssClasses: ['w-16', 'max-w-16']
  },
  {
    name: 'Cryodate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    cssClasses: ['w-24', 'max-w-24'],
    alignment: 'left',
    sort: true
  },
  {
    name: 'Biopsy',
    key: 'embryo.biopsy',
    component: 'select',
    componentOptions: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ],
    sort: true,
    cssClasses: ['w-20', 'pr-4']
  },
  {
    name: 'Results',
    key: 'embryo.biopsyResult',
    component: 'DataFieldModal',
    disabled: (item) => item.embryo?.biopsy === SPECIMEN_BIOPSY_NO,
    sort: true,
    maxlength: 255,
    cssClasses: ['w-24', 'max-w-32']
  },
  {
    name: 'Notes',
    alignment: 'left',
    component: 'DataFieldModal',
    key: 'specimenInfo',
    maxlength: 255,
    edit: true,
    cssClasses: ['w-24', 'max-w-32']
  },
  ...updateScreeningStatusFields,
  sourcesValueHeader
]

export const egg = [
  ...specimenId,
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-24', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'left',
    key: 'specimenCount',
    sort: true,
    cssClasses: ['w-1/4'],
    filters: [
      (value, item) =>
        `${value} x ${
          item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    ]
  },
  {
    name: 'Cryodate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    cssClasses: ['w-24', 'max-w-24'],
    sort: true
  },
  {
    name: 'Notes',
    alignment: 'left',
    key: 'specimenInfo',
    component: 'DataFieldModal',
    edit: true,
    maxlength: 255,
    cssClasses: ['w-24', 'max-w-24']
  },
  ...updateScreeningStatusFields
]
