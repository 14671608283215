<template>
  <section class="unsupported-browser flex flex-col items-center justify-center z-50 fixed top-0 left-0 w-screen h-screen">
    <div class="w-7/12 flex flex-col justify-center items-center">
      <img src="@/assets/browser-icon.svg" class="w-12">
      <p class="mt-10 text-center text-tmrw-green-light text-2xl">We’re sorry but this browser is not supported.</p>
      <p class="mt-2 text-center text-tmrw-green-light text-2xl">Please use the latest version of Google Chrome.</p>
      <button-dynamic
        btnText="Download Chrome"
        btnStyle="primary"
        btnType="button"
        btnPixelsWidth="250px"
        :addClasses="['text-xl mt-8']"
        :btnStyleClasses="['warning', 'bold']"
        @click="onClickDownloadChrome"
      />
    </div>
  </section>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'unsupported-browser',
  methods: {
    onClickDownloadChrome() {
      window.open('https://www.google.com/chrome/')
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>

<style lang="scss">
  .unsupported-browser {
    background: url('../../assets/circles-background.svg') center center repeat-x;
    background-size: 50% auto;
  }
</style>
