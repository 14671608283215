<template>
  <div class="justify-center w-full" v-if="beaconsModel.options.length">
    <section
      class="flex justify-between text-tmrw-blue-light font-semibold bg-white py-6 px-10 rounded-md"
    >
      <div class="flex flex-col gap-2">
        <span class="text-2xl">Add to an existing {{ CryoBeacon }}</span>
        <span>There is space in existing {{ CryoBeacon }}(s) for this patient.</span>
        <span>Please select which one you would like to retrieve from the CryoRobot</span>
      </div>
      <div v-if="isFreezeOrImportTicket" class="flex flex-col justify-center gap-3">
        <div class="text-xl">Or you can add a new {{ CryoBeacon }}:</div>
        <button-dynamic
          :btnText="`Add new ${CryoBeacon}`"
          btnType="button"
          btnStyle="secondary"
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="showNewCryoBeaconAlertModal"
        />
      </div>
    </section>
    <CryoBeaconsList :beacons="beaconsModel.options" />
    <div
      v-if="isNewCryoBeaconAlertModalVisible"
      class="
        w-screen
        h-screen
        flex
        items-center
        justify-center
        absolute
        top-0
        left-0
        bg-black bg-opacity-50
        z-50
      "
    >
      <div class="w-5/12 bg-white p-6 rounded-lg">
        <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3">
          <template v-slot:left-side>
            <dynamic-title titleType="h3-blue-dark" is-bold floatTo="left">{{
              modalTitle
            }}</dynamic-title>
          </template>
          <template v-slot:right-side>
            <button-dynamic
              btnText=""
              btnType="button"
              btnStyle="close-button"
              showIcon
              fontAwesomeIconClass="times-circle"
              floatTo="right"
              @click="hideNewCryoBeaconAlertModal"
            />
          </template>
        </ActionBar>
        <div class="p-1 leading-7">
          {{ modalMessage }}
        </div>
        <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3" class="mt-4">
          <template v-slot:left-side>
            <button-dynamic
              btnText="Cancel"
              btnType="button"
              btnStyle="secondary"
              @click="hideNewCryoBeaconAlertModal"
            />
          </template>
          <template v-slot:right-side>
            <button-dynamic
              btnText="Confirm"
              btnType="button"
              btnStyle="primary"
              @click="addNewCryoBeacon"
            />
          </template>
        </ActionBar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as ss from '@/config/session-storage-help'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CryoBeaconsList from '@/components/CryoBeaconsList/CryoBeaconsList.vue'
import { useTicket } from '@/composables/useTicket'

import {
  CRYOBEACON,
  CRYOROBOT,
  CRYODEVICE,
  CRYODEVICES,
  INFECTIOUS_SCREENING_STATUS,
  SINGLE_IMPORT_CONST,
  METHOD_FREEZE,
  METHOD_IMPORT
} from '@/constants'

export default {
  name: 'existing-cryobeacons',
  props: {
    handleAddNewCryoBeacon: {
      type: Function,
      required: true
    }
  },
  setup() {
    const { ticket } = useTicket()
    return { ticket }
  },
  data() {
    return {
      isProcessing: true,
      selectedItem: [],
      CryoBeacon: CRYOBEACON,
      CryoRobot: CRYOROBOT,
      isNewCryoBeaconAlertModalVisible: false,
      isFreezeOrImportTicket: false,
      modalTitle: `New ${CRYOBEACON}`,
      modalMessage: `Choosing a New ${CRYOBEACON} when there is space in the existing ${CRYOBEACON}s may incur an additional charge.`,
      specimens: [],
      headers: [
        {
          name: '',
          key: 'barcode',
          visible: false,
          unique: true,
          cssClasses: ['mr-2', 'w-40'],
          alignment: 'left'
        },
        {
          name: 'CryoBeacon ID',
          key: 'barcode',
          cssClasses: ['mr-2', 'w-40'],
          sort: true
        },
        {
          name: 'Available Space',
          key: 'freePositions',
          cssClasses: ['mr-2', 'w-48'],
          sort: true,
          filters: [(value) => `${value} ${value > 1 ? CRYODEVICES : CRYODEVICE}`]
        },
        {
          name: INFECTIOUS_SCREENING_STATUS,
          key: 'screeningStatusId',
          component: 'screeningStatus',
          cssClasses: ['w-48'],
          sort: true,
          componentOptions: {
            icon: true,
            label: true,
            textColor: 'white'
          }
        },
        {
          name: 'Status',
          key: 'reason',
          sort: true
        }
      ],
      beaconsModel: {
        sort: {
          orderBy: 'reason',
          direction: 'asc'
        },
        options: []
      }
    }
  },
  async created() {
    this.selectBeacon(null)
    const [firstBeacon] = this.selectedBeacons
    const numCryodevices = firstBeacon.cryoDevice.length
    const { sort } = this.beaconsModel
    const options = this.beaconsList.map((item) => ({
      ...item,
      disabled: Boolean(item.reason) || item.freePositions < numCryodevices
    }))
    this.beaconsModel = { sort, options }

    this.isProcessing = false

    // TicketToPrint uses SINGLE_IMPORT_CONST
    this.isFreezeOrImportTicket = this.selectedMethod === METHOD_FREEZE
      || this.selectedMethod === METHOD_IMPORT
      || this.ticket.procedureType === METHOD_FREEZE
      || this.ticket.procedureType === SINGLE_IMPORT_CONST
  },
  computed: {
    ...mapGetters('beaconsModule', ['isCompleted', 'beaconsList']),
    ...mapGetters('newTicketModule', ['selectedMethod']),
    ...mapGetters('newTicketModule', {
      selectedBeacons: 'beacons'
    })
  },
  methods: {
    ...mapActions('beaconsModule', ['selectBeacon', 'changeBeaconStep']),
    ...mapActions('modalModule', ['displayModal', 'hideModal']),
    handleRefs() {
      this.$refs.beacons.update()
    },
    showNewCryoBeaconAlertModal() {
      this.isNewCryoBeaconAlertModalVisible = true
    },
    hideNewCryoBeaconAlertModal() {
      this.isNewCryoBeaconAlertModalVisible = false
    },
    addNewCryoBeacon() {
      this.isNewCryoBeaconAlertModalVisible = false
      this.handleAddNewCryoBeacon()
    }
  },
  watch: {
    beaconsModel(newValue, oldValue) {
      if (newValue.options !== oldValue.options) {
        const selectedItem = this.beaconsModel.options.filter((option) => option.selected)
        this.selectedItem = selectedItem || []
        if (this.selectedItem && this.selectedItem.length) {
          const [beacon] = this.selectedItem
          this.selectBeacon(beacon)
          ss.setFieldSessionStorage(
            'newBeaconsArray',
            JSON.stringify([this.selectedItem[0].barcode])
          )
        } else {
          this.selectBeacon(null)
        }
      }
    }
  },
  components: {
    ButtonDynamic,
    ActionBar,
    DynamicTitle,
    CryoBeaconsList
  }
}
</script>
