<template>
  <MainContainer data-test="calendar-view" gridSlots="3" class="calendar-board">
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="calendar-view__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          {{ headerMessage }}
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="calendar-view__select-button"
          btnType="button"
          btnText="Select"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isValidToContinue"
          @click="handleSelectDate"
        />
      </template>
    </ActionBar>
    <calendar-card
      v-model="appointmentDate"
      :procedureType="selectedMethod"
      :minDate="minimunAllowedDate"
    />
  </MainContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import CalendarCard from '@/components/CalendarCard/CalendarCard.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { DONATE_TO_PATIENT_VALUE, embryoTypeValues, METHOD_EXPORT } from '@/constants'
import { replaceStringFilter } from '@/filters'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'

dayjs.extend(utc)

export default {
  name: 'calendar-view',
  data() {
    return {
      procedureDateFrom: null,
      procedureDateTo: null,
      procedureTime: null,
      appointmentDate: {}
    }
  },
  mounted() {
    const time = dayjs().format('hh:mm A')
    this.appointmentDate = {
      mode: 'now',
      date: { start: dayjs().startOf('day').valueOf(), end: null },
      time
    }
  },
  computed: {
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedSpecimenType',
      'selectedTypeEmbryo'
    ]),
    isRangeSelectionDone() {
      const { mode, date } = this.appointmentDate

      if (mode === 'range') {
        return !!date?.start && !!date?.end
      }

      return true
    },
    isValidToContinue() {
      // Used to enable the next button
      return this.isRangeSelectionDone
    },
    headerMessage() {
      if (
        this.selectedMethod === DONATE_TO_PATIENT_VALUE
        || this.selectedMethod === METHOD_EXPORT
      ) {
        return 'When would you like to schedule this Export?'
      }

      const currentType = this.selectedTypeEmbryo || this.selectedSpecimenType
      return `When would you like to schedule this ${replaceStringFilter(
        currentType,
        embryoTypeValues
      )} ${this.selectedMethod}?`
    },
    minimunAllowedDate() {
      if (this.appointmentDate.mode === 'range') {
        return dayjs().add(1, 'day').startOf('day').valueOf()
      }
      return dayjs().startOf('day').valueOf()
    }
  },
  methods: {
    ...mapMutations('ticketModule', [
      'setVisit',
      'setProcedureTime',
      'setCryoDeviceCryoDate'
    ]),
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })

      this.$router.replace({ name: previousStepPath })
    },
    async handleSelectDate() {
      // Set a unique value for now to be used across this method
      // so we dont use different instances of dayjs which will produce
      // different dates values.
      const now = dayjs().valueOf()
      const {
        date: { start: procedureDateFrom, end: procedureDateTo },
        time,
        mode
      } = this.appointmentDate

      this.procedureDateFrom = Number.isNaN(procedureDateFrom) ? now : procedureDateFrom
      this.procedureDateTo = procedureDateTo
      this.procedureTime = time

      if (mode === 'single' || mode === 'now') {
        const [hour, meridian] = time.split(' ')
        const formatedTime = `${hour}:00 ${meridian}`
        const isProcedureDateFromSet = Number.isNaN(procedureDateFrom) ? now : procedureDateFrom
        const formatedDate = dayjs(isProcedureDateFromSet).format('MMM D, YYYY')
        this.procedureDateFrom = dayjs(`${formatedDate} ${formatedTime}`).utc().valueOf()
      }

      const procedureDate = {
        procedureDateFrom: this.procedureDateFrom,
        procedureDateTo: this.procedureDateTo,
        procedureTime: this.procedureTime
      }

      this.setProcedureTime(this.procedureTime)
      this.setVisit({
        procedureDateFrom: this.procedureDateFrom,
        procedureDateTo: this.procedureDateTo
      })

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        procedureDate
      })

      this.$router.push({ name: nextStepPath })
    }
  },
  components: {
    TopHeader,
    DynamicTitle,
    CalendarCard,
    ButtonDynamic,
    HeaderInfo,
    MainContainer,
    ActionBar
  }
}
</script>
<style lang="scss">
.calendar-board {
  position: relative;
  &__message {
    margin-bottom: 28.5px;
    .message-card {
      width: max-content;
    }
  }
  &__container {
    grid-template-rows: 1fr auto;
    grid-row-gap: 16px;
  }
  &__controls {
    display: flex;
    justify-content: flex-end;

    > button {
      margin-right: 16px;
      padding: 10px 40px;
    }
  }
  &__table {
    flex-grow: 2;
  }

  .date-time-validation-error {
    width: calc(512px - 40px);
    text-align: center;
    font-size: 20px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 10px;
  }
}
</style>
