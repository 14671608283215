<template>
  <thead class="h-8 hidden md:table-header-group">
    <tr v-if="headerInfo">
      <th
        :colspan="headers.length + 1"
        class="bg-tmrw-gray-lighter px-6 py-3.5 sticky top-0 overflow-hidden z-10"
      >
        <p
          class="font-exo font-bold text-base text-left text-tmrw-blue-dark"
          data-test="scroll-table-header__tickets-qty"
          v-html="headerInfo"
        ></p>
      </th>
    </tr>
    <tr data-test="scroll-table-header" class="scroll-table-header">
      <th
        v-if="isSelectionModeCheckbox || isSelectionModeRadio"
        class="scroll-table-header__th th-columns border-b"
        :class="[
          isBackgroundTransparent ? 'bg-transparent' : 'bg-white',
          headerInfo ? 'top-[52px]' : 'top-0 rounded-tl-md'
        ]"
      >
        <Checkbox
          v-if="isSelectionModeCheckbox && !hideSelectAllCheckbox"
          :modelValue="areAllSelected"
          @update:modelValue="selectAllCheckboxes(areAllSelected)"
        />
      </th>
      <th
        v-for="(column, key) in headers"
        :key="key"
        :data-test="getHeaderDataTest(column)"
        @click="sortColumnHandler(column)"
        class="font-exo text-tmrw-blue z-10 py-3.5 whitespace-nowrap text-base sticky px-3 th-columns border-b"
        :class="[
          `th-${column.name.toLowerCase()}`,
          column.cssClasses || [],
          isBackgroundTransparent ? 'bg-transparent' : 'bg-white',
          headerInfo ? 'top-[52px]' : 'top-0 rounded-tr-md',
          {
            'max-w-none': !column.fitContent || !column.cssClasses,
            'w-6 whitespace-nowrap': column.fitContent,
            'cursor-pointer': column.sort,
            [`text-${column.alignment || 'left'}`]: ['center', 'left', 'right'].includes(
              column.alignment || 'left'
            )
          }
        ]"
      >
        <div v-if="column.leftIcon" class="float-left mr-5">
          <div class="w-6 h-6 border-solid border-2 border-tmrw-blue rounded-full">
            <hr class="w-3 h-0 border-1 border-dashed border-tmrw-blue mt-2.5 -ml-3" />
          </div>
        </div>
        {{ column.name }}
        <div v-if="column.rightIcon" class="float-right ml-4">
          <div class="w-6 h-6 border-solid border-2 border-tmrw-blue rounded-full">
            <hr class="w-3 h-0 border-1 border-dashed border-tmrw-blue mt-2.5 ml-6" />
          </div>
        </div>
        <span
          v-if="column.sort"
          class="inline-block relative w-0 h-3 mr-2"
          :class="{
            'scroll-table-header__toggle-down': isToggleUpOrder(column),
            'scroll-table-header__toggle-up': isToggleDownOrder(column)
          }"
        />
      </th>
    </tr>
  </thead>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import slugify from 'slugify'
import Checkbox from '@/components/Checkbox/Checkbox.vue'
import selectionModes from './selectionModes'

const props = defineProps({
  headers: {
    type: null,
    required: false
  },
  selectionMode: {
    type: null,
    required: false
  },
  sort: {
    type: null,
    required: false
  },
  options: {
    type: null,
    required: false
  },
  areAllSelected: {
    type: null,
    required: false
  },
  hideSelectAllCheckbox: {
    type: null,
    required: false
  },
  isTableDataEmpty: {
    type: null,
    required: false
  },
  /**
   * Set if the table BG is Transparent
   */
  isBackgroundTransparent: {
    type: Boolean,
    default: false,
    required: false
  },
  isRelocationView: {
    type: Boolean,
    default: false,
    required: false
  },
  headerInfo: {
    type: String,
    required: false
  }
})

const emit = defineEmits(['onSelectAll', 'onSortColumn'])

// Computed
const isSelectionModeRadio = computed((): Boolean => {
  return selectionModes.RADIO === props.selectionMode
})
const isSelectionModeCheckbox = computed((): Boolean => {
  return selectionModes.CHECKBOX === props.selectionMode
})

// Methods
const selectAllCheckboxes = (areAllSelected): void => {
  emit('onSelectAll', !areAllSelected)
}

/**
 * @sortColumnHandler
 * In this event we send info
 * to the parent component about the
 * user action, this info will be sent
 * back to the ScrollTable parent* component
 * by calling the @input event with the new
 * v-model value
 * @return Void
 */
const sortColumnHandler = (columnName): void => {
  let direction = props.sort.direction === 'asc' ? 'desc' : 'asc'

  /**
   * If in the the column
   * settings there is no info on sort
   * this column, then we disable the
   * click on this column title
   * in the table
   */
  if (!columnName.sort) {
    return
  }
  /**
   * If the sorting mean to be by a different
   * than the previews order, then
   * the order will be ascendant
   */
  if (columnName.key !== props.sort.orderBy) {
    direction = 'asc'
  }
  emit('onSortColumn', {
    ...props.sort,
    orderBy: columnName.key,
    direction
  })
}

/**
 * @isToggleUpOrder
 * Utility method to decipher
 * the Up order for the
 * selected column
 * @return Boolean
 */
const isToggleUpOrder = (column): Boolean => {
  return column.sort && column.key === props.sort.orderBy && props.sort.direction === 'desc'
}

/**
 * @isToggleDownOrder
 * Utility method to decipher
 * the Down order for the
 * selected column
 * @return Boolean
 */
const isToggleDownOrder = (column): Boolean => {
  return column.sort && column.key === props.sort.orderBy && props.sort.direction === 'asc'
}

const getHeaderDataTest = (column): String => {
  return slugify(`scroll-table-header-${column.name}`, { lower: true })
}
</script>

<style lang="scss" scoped>
.scroll-table-header {
  .scroll-table-header__th {
    @apply sticky;
    @apply text-sm whitespace-nowrap;
    @apply z-10 w-6 pl-3 pr-6 py-3.5;
  }

  .th-columns {
    &:before {
      top: 51px;
      @apply border-tmrw-blue border-1 border-solid;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }
  }

  &__toggle-down,
  &__toggle-up {
    &:after {
      @apply absolute;
      @apply empty-content;
      @apply ml-2 mt-1;
      @apply border-b-0;
      @apply border-t-5 border-solid border-tmrw-blue;
      @apply border-l-5 border-solid border-l-transparent;
      @apply border-r-5 border-solid border-r-transparent;
      @apply transform duration-100 ease-in-out;
    }
  }

  &__toggle-down:after {
    @apply rotate-0;
  }

  &__toggle-up:after {
    @apply rotate-180;
  }

  &__th--ellipsis {
    @apply max-w-0;
  }
}
</style>
