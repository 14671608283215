import { BASE_URL } from '@/config/http-constants'
import toast from '@/config/toast'
import * as $ss from '@/config/session-storage-help'
import { axiosInterceptors } from '@/config/http-interceptors'

type baseConfigType = {
  siteId: string | null;
  clinicId: string | null;
  cpId: string | null;
  userId: string | null;
};

class ApiCallsFactory {
  private url: string

  private token: string

  private body: object | undefined

  constructor(url: string, token: string, body?: object) {
    this.url = url
    this.token = token
    this.body = body
  }

  private constructURL(): string {
    const appBaseConfig: baseConfigType = {
      siteId: $ss.getFieldSessionStorage('config', 'siteId') || null,
      clinicId: $ss.getFieldSessionStorage('config', 'clinicId') || null,
      cpId: $ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
      userId: $ss.getFieldSessionStorage('user', 'userId') || null
    }
    const { clinicId, siteId } = appBaseConfig

    return `${BASE_URL}/clinics/${clinicId}/sites/${siteId}${this.url}`
  }

  public async getData(): Promise<object> {
    try {
      const url = this.constructURL()
      const axiosInstance = axiosInterceptors()
      const response = await axiosInstance.get(url, {
        headers: {
          'x-access-token': this.token,
          'content-type': 'application/json; charset=UTF-8'
        }
      })
      return response.data
    } catch (error: any) {
      toast.error({ title: error.message })
      return []
    }
  }

  public async postData(): Promise<object> {
    try {
      const url = this.constructURL()
      const axiosInstance = axiosInterceptors()
      const response = await axiosInstance.post(url, this.body, {
        headers: {
          'x-access-token': this.token,
          'content-type': 'application/json; charset=UTF-8'
        }
      })
      return response.data
    } catch (error: any) {
      toast.error({ title: error.message })
      return []
    }
  }

  public async deleteData(
    cancelReason?: string,
    userId?: number | string,
    includeAllSites?: boolean
  ): Promise<object> {
    try {
      const data =
        cancelReason && userId
          ? {
              data: {
                cancelReason,
                userId,
                includeAllSites
              }
            }
          : {} // Remove from Batch
      const url = this.constructURL()
      const axiosInstance = axiosInterceptors()
      const response = await axiosInstance.delete(url, {
        headers: {
          'x-access-token': this.token,
          'content-type': 'application/json; charset=UTF-8'
        },
        ...data
      })
      return response.data
    } catch (err: any) {
      throw new Error(err)
    }
  }
}

export { ApiCallsFactory }
