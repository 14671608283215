import { ref, onMounted, computed } from 'vue'
import { EMR_SOURCE_ID } from '@/constants/'
import { capitalizeLower } from '@/helpers/stringHelper'
import * as ss from '@/config/session-storage-help'

function getTicketSpecimenType(ticket) {

  // NOTE: Seems this is the only way to extract specimen type at this point
  let [specimenType] = ticket.procedure.split('_')
  specimenType = capitalizeLower(specimenType)

  return specimenType
}

function getPatientObject(ticket) {
  const { globalPatientNumber, identificationNumber, patientName, patientDob } = ticket
  const [firstName, lastName] = patientName.split(' ')
  return {
    birthDate: patientDob,
    globalPatientNumber,
    identificationNumber,
    firstName,
    lastName
  }
}

function isEditingTicket() {
  const selectedTicketToEdit = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
  return !!Object.keys(selectedTicketToEdit).length
}

function getSessionStorageTicket() {
  if (isEditingTicket()) {
    const selectedTicketToEdit = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
    return {
      ...selectedTicketToEdit,
      type: getTicketSpecimenType(selectedTicketToEdit),
      patient: getPatientObject(selectedTicketToEdit)
    }
  }

  return ss.getFieldSessionStorage('newTicket', '')
}

export function getActiveBeaconFromBeaconList(beacons) {
  const hasActiveBeacons = beacons.filter(({ active }) => active).length
  if (hasActiveBeacons) {
    return beacons.find(({ active }) => active)
  }

  const isFirstBeacon = (index) => index === 0
  const updatedTicketBeacons = beacons.map((beacon, index) => ({
    ...beacon,
    active: isFirstBeacon(index),
    beaconId: beacon.beaconBarcode
  }))

  return updatedTicketBeacons[0]
}

export function buildEMRBeacons(ticket) {
  let cryodevices = null
  if (ticket.beacons?.length) {
    cryodevices = ticket.beacons[0].cryoDevice
  } else {
    cryodevices = ss.getFieldSessionStorage('newTicket', 'beacons')[0].cryoDevice
  }

  const isExternalCryoLabel = ticket.beacons?.[0]?.externalCryolabel || cryodevices[0]?.metadata?.externalCryolabel
  return [
    {
      active: true,
      beaconBarcode: cryodevices[0].beaconBarcode,
      externalCryolabel: isExternalCryoLabel,
      cryoDevice: ticket.specimens
        .map((specimen) => {
          const cryodevice = cryodevices.find(({ cryodeviceBarcode, originalCryodeviceBarcode }) => originalCryodeviceBarcode === specimen.cryodeviceBarcode || cryodeviceBarcode === specimen.cryodeviceBarcode)
          return {
            ...specimen,
            ...specimen.embryo,
            ...specimen.oocyte,
            cryodeviceBarcode: cryodevice?.cryodeviceBarcode || specimen.cryodeviceBarcode,
            originalCryodeviceBarcode: cryodevice?.originalCryodeviceBarcode || specimen.cryodeviceBarcode,
            freeText: cryodevice?.freeText || specimen.freeText,
            metadata: cryodevice?.metadata || specimen.metadata
          }
        })
    }
  ]
}

function saveTicket(ticket) {
  if (isEditingTicket()) {
    ss.setFieldSessionStorage('selectedTicketToEdit', ticket, 'process')
  } else {
    ss.setFieldSessionStorage(null, ticket, 'newTicket')
  }
}

function updateTicketBeacons(ticket, beacons) {
  return { ...ticket, beacons }
}

export function replaceBeaconInBeaconList(beacons, newBeacon) {
  return beacons.map((beacon) => {
    const hasSameBeaconId =
      beacon.beaconId && newBeacon.beaconId && beacon.beaconId === newBeacon.beaconId
    const hasSameBeaconBarcode =
      beacon.beaconBarcode &&
      newBeacon.beaconBarcode &&
      beacon.beaconBarcode === newBeacon.beaconBarcode
    if (hasSameBeaconId || hasSameBeaconBarcode) {
      return newBeacon
    }
    return beacon
  })
}

export function useTicket() {
  // STATE

  const ticket = ref({})
  const beacons = ref([])
  const activeBeacon = ref({})

  // COMPUTEDS

  const isEMRTicket = computed(() => ticket.value?.source === EMR_SOURCE_ID)

  // METHODS

  function getBeacons() {
    // eslint-disable-next-line no-shadow
    let beacons = ticket.value.beacons ? ticket.value.beacons : []
    if (!beacons.length) {
      beacons = ss.getFieldSessionStorage('newTicket', 'beacons')
    }
    return isEMRTicket.value ? buildEMRBeacons(ticket.value) : beacons
  }

  function updateActiveBeaconInBeaconState() {
    activeBeacon.value = getActiveBeaconFromBeaconList(beacons.value)
    beacons.value = replaceBeaconInBeaconList(beacons.value, activeBeacon.value)
  }

  // HOOKS

  onMounted(() => {
    ticket.value = getSessionStorageTicket()
    beacons.value = getBeacons()
    if (beacons.value && beacons.value.length) {
      updateActiveBeaconInBeaconState()
    }

    ticket.value = updateTicketBeacons(ticket.value, beacons.value)
    saveTicket(ticket.value)
  })

  return {
    ticket,
    beacons,
    activeBeacon,
    isEMRTicket,
    saveTicket,
    replaceBeaconInBeaconList,
    updateTicketBeacons,
    getActiveBeaconFromBeaconList,
    buildEMRBeacons,
    isEditingTicket
  }
}
