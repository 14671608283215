<template>
  <div
    v-if="robotLocations?.length && ticket"
    data-test="cryobeacon-status-wrapper"
    class="grid grid-cols-2 ml-10 col-span-6"
  >
    <h3 class="col-span-2 justify-self-start text-xs font-semibold text-tmrw-blue mb-4 uppercase">
      {{ statusTitle }} STATUS {{ statusValue }}
    </h3>
    <div
      class="flex flex-col"
      v-for="(location, index) in locations"
      :key="index"
      :data-test="`${location.locationType}-location`"
    >
      <div class="flex mb-3">
        <div
          class="flex relative"
          :class="addCircleClass(location)"
          data-test="ticket-detail__step-circle-container"
        >
          <img
            v-if="!!getIcon(location)"
            :src="getIcon(location)?.icon"
            :data-test="`ticket-detail__step-icon${getIcon(location)?.name}`"
            class="w-8 h-8"
          />
          <i
            v-if="showMiniCheckMark(index)"
            data-test="ticket-detail__inventory-completed-icon"
            class="far fa-check-circle text-tmrw-success text-xs absolute right-0 bottom-0 bg-white -mr-1 rounded-full"
          />
        </div>
        <hr
          v-if="!index && locations.length > 1"
          class="mt-4 w-full h-0.5"
          data-test="ticket-detail__progress-line"
          :class="[getProgressLineClass(location)]"
          :style="{
            width: 'calc(100% - 2rem)'
          }"
        />
      </div>
      <section>
        <div
          class="font-bold text-xs uppercase"
          :class="[isCompleteClass(location), isScheduledClass(location), isDiscardedClass()]"
          :data-test="`ticket-detail__procedure-${location.transferTypeName}`"
        >
          {{ getTransferType(index, location) }}
        </div>
        <div class="w-72 flex items-end justify-start mt-1.5">
          <div
            class="flex flex-col border-solid border-r-2 border-tmrw-gray pr-6"
            :class="[isScheduledClass(location)]"
            v-if="
              (isCryobeaconDiscarded && locations.length > 1 && index === 0) ||
              !isCryobeaconDiscarded
            "
          >
            <p data-test="site-name" class="font-semibold text-tmrw-blue-dark text-xl">
              {{ location.siteName }}
            </p>
            <p
              class="text-xs text-tmrw-blue-dark font-bold"
              :data-test="`ticket-detail__${location.robotNumber}`"
            >
              {{ isCryoShipperTicket ? '&nbsp;' : location.robotName }}
            </p>
            <p
              v-if="location.locationDetails"
              class="text-xs text-tmrw-blue-dark"
              :data-test="`${location.locationType}-location`"
            >
              ({{ location.locationDetails }})
            </p>
          </div>
          <div
            class="flex flex-col justify-end"
            :class="{ 'pl-4': !(isCryobeaconDiscarded && index === 1) }"
          >
            <p
              data-test="ticket-detail__date"
              class="text-xs font-inconsolata mt-3 uppercase w-20"
              :class="[isScheduledClass(location)]"
            >
              <i data-test="ticket-detail__calendar-icon" class="fas fa-calendar-alt mr-2" />{{
                getDateTimeCryobeacon(location).date
              }}
            </p>
            <p
              data-test="ticket-detail__time"
              class="text-xs font-inconsolata uppercase w-20"
              :class="[isScheduledClass(location)]"
            >
              <i data-test="ticket-detail__clock-icon" class="far fa-clock mr-2" />{{
                getDateTimeCryobeacon(location).time
              }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
  <CryoBeaconStatusPlaceholder v-else />
</template>
<script setup lang="ts">
import {
  isInventoryUpdateDone,
  isTicketCompleted,
  getTicketStatusIcon,
  getCircleClass,
  hasSourceAndDestination,
  isBeaconMoveProcedure
} from '@/helpers/ticketState'
import { computed } from 'vue'
import {
  ROBOT_TRANSFER_NOT_READY,
  CRYOBEACON,
  CRYOBEACON_DISCARDED,
  PROCEDURE_TYPE_CRYOSHIPPER,
  CRYOSHIPPER
} from '@/constants'
import CryoBeaconStatusPlaceholder from './CryoBeaconStatusPlaceholder.vue'
import {
  isDone,
  getCurrentCryoBeaconLocation,
  orderLocations,
  RobotLocation,
  ProcessedLocation
} from '@/helpers/cryoBeaconLocation'

import dayjs from 'dayjs'
import { Ticket } from '@/types/ticket'

// PROPS
const props = defineProps<{
  ticket: Ticket
  robotLocations: RobotLocation[]
}>()

// COMPUTED
// COMPUTED
const isComplete = computed(() => isTicketCompleted(props.ticket)) // Compute if the ticket is complete
const isCryoBeaconDiscarded = computed(() => props.ticket.beaconDiscarded) // Compute if the cryobeacon is discarded
const isInventoryUpdDone = computed(() => isInventoryUpdateDone(props.ticket)) // Compute if the inventory update is done

const locations = computed(() => {
  const orderedLocations = orderLocations(props.robotLocations) // Order the robot locations
  const processedLocations: ProcessedLocation[] = []
  orderedLocations.forEach((location) => {
    const currentCryoBeaconLocation = getCurrentCryoBeaconLocation([location]) // Get the current cryobeacon location
    return processedLocations.push(currentCryoBeaconLocation) // Add the current cryobeacon location to the processed locations array
  })
  return processedLocations // Return the processed locations array
})

const statusTitle = computed(() => {
  return isCryoShipperTicket.value ? CRYOSHIPPER : CRYOBEACON
})

const statusValue = computed(() => 
  isCryoShipperTicket.value ? `: ${props.ticket?.state ?? ""}` : ""
)

const isCryoShipperTicket = computed(() => {
  return props.ticket.procedure === PROCEDURE_TYPE_CRYOSHIPPER
})

// METHODS
const getIcon = (location) => {
  const iconSrc = getTicketStatusIcon(props.ticket, location)

  return iconSrc
}

const isStepDone = (location) => isComplete.value || isDone(location.transferStatus) // Check if the current location step is done

const addCircleClass = (location) => {
  const baseCircleCss = 'w-8 h-8 border-2'
  return getCircleClass(props.ticket, location, baseCircleCss)
}

const isScheduledClass = (location) =>
  location.transferStatus === ROBOT_TRANSFER_NOT_READY ? 'opacity-50' : '' // Set the class for scheduled location step

const isDiscardedClass = () => {
  return props.ticket.beaconDiscarded ? 'text-tmrw-success' : 'text-tmrw-blue-dark' // Set the class for discarded CryoBeacon
}

const isCompleteClass = (location) => {
  if (isStepDone(location)) {
    return 'text-tmrw-success' // Set the class for completed location step
  }
  return '' // Else no class is set
}

const showMiniCheckMark = (index) => {
  return (
    isInventoryUpdDone.value &&
    !isBeaconMoveProcedure(props.ticket) &&
    (index === 1 || !hasSourceAndDestination(props.ticket)) &&
    !isComplete.value &&
    !isCryoBeaconDiscarded.value
  )
}

const getProgressLineClass = (location) => {
  // Check if the cryobeacon is discarded or the transfer status is not done
  if (!isDone(location.transferStatus)) {
    return 'border-tmrw-gray border-dashed' // Return the class for a dashed border
  } else if (isComplete.value) {
    return 'border-tmrw-success' // Return the class for a success border
  }
  return 'bg-gradient-to-r from-tmrw-green to-tmrw-blue-dark' // Return the class for a gradient background
}

const isCryobeaconDiscarded = computed(() => {
  return props.ticket?.beaconDiscarded || false
})

const getTransferType = (index, location) => {
  // Destructure transferTypeName from location
  const { transferTypeName } = location

  // Check if the beacon is discarded
  if (props.ticket.beaconDiscarded) {
    // If the beacon is discarded and there is only one robot location and it is the first one,
    // or there are multiple robot locations onlye CRYOBEACON_DISCARDED if it is the last location
    // Otherwise, return the transferTypeName
    return (locations.value.length === 1 && !index) || (locations.value.length > 1 && index === 1)
      ? CRYOBEACON_DISCARDED
      : transferTypeName
  }
  // If the beacon is not discarded, return the transferTypeName
  return transferTypeName
}

const getDateTimeCryobeacon = (location) => {
  if (isCryobeaconDiscarded.value) {
    const { procedureTimeTo } = props.ticket
    const date = procedureTimeTo ? dayjs(procedureTimeTo).format('DDMMMYYYY') : '--'
    const time = procedureTimeTo ? dayjs(procedureTimeTo).format('hh:mmA') : '--'
    return {
      date,
      time
    }
  }

  return {
    date: location.completedAtDate,
    time: location.completedAtTime
  }
}
</script>
