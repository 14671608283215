<template>
  <MainContainer data-test="flight-board" gridSlots="8">
    <TopHeader :backBtn="false">
      <HeaderSummary :cryobeaconsAmount="ticketsCreatedQty" />
    </TopHeader>

    <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3" class="mb-4">
      <template v-slot:left-side>
        <MessageCard class="float-left">
          {{ ticketsCreatedQty }} Relocation Tickets created.
          <span v-if="amountOfErrors" class="text-tmrw-warning">
            There {{ amountOfErrors > 1 ? 'are' : 'is' }} {{ amountOfErrors }} error{{
              amountOfErrors > 1 ? 's' : ''
            }}
            to consider:</span
          >
        </MessageCard>
      </template>
      <template v-slot:right-side>
        <div class="flex justify-center m-5 gap-3">
          <button-dynamic
            btnDataTest="select-patient-button"
            btnType="button"
            btnText="Put in CryoShipper"
            btnStyle="tmrw-blue-light"
            @click="handlePutInCryoShipper"
            :isDisabled="isCryoShipperDisabled"
          />

          <button-dynamic
            btnDataTest="select-patient-button"
            btnType="button"
            btnText="Return to Relocation Flightboard"
            btnStyle="primary"
            :add-classes="['min-w-64']"
            @click="handleReturnToFbBtn"
          />
        </div>
      </template>
    </ActionBar>
    <BreadCrumbs :items="breadCrumsItems" size="base" />
    <!-- @vue-ignore -->
    <ScrollTableComponent
      minHeight="h-full"
      v-model="ticketsModel"
      :headers="tableCryobeaconRelocations"
      selectionMode="checkbox"
      :isRelocationView="true"
      :headerInfo="headerInfo"
      :loading="loadingTable"
      @onButtonClicked="onViewClicked"
      :groupHeaders="[
        {
          title: 'Errors',
          colorText: 'tmrw-error',
          colorBg: 'tmrw-error-light',
          icon: 'fas fa-exclamation-triangle',
          collapsed: false
        },
        {
          title: 'Successfully Created',
          colorText: 'tmrw-blue',
          colorBg: 'tmrw-green-light',
          icon: 'far fa-check-circle',
          collapsed: getTicketsWithErrorsTableData.length > 0
        }
      ]"
    />
  </MainContainer>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import HeaderSummary from '@/components/Biorepository/HeaderSummary/HeaderSummary.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import useGetters from '@/composables/useGetters'
import {
  STEP_BIOREPOSITORY_FLIGHTBOARD,
  CRYOSHIPPER_CONFIRM
} from '@/constants/moveLocationTicketSteps'
import { MOVE_LOCATION, TICKET_RELOCATION } from '@/constants'
import { tableCryobeaconRelocations } from '@/constants/table-headers/relocation-headers'
import { TicketsResponse, TableDataError } from '@/views/BatchTicket/Interfaces'
import useActions from '@/composables/useActions'
import { getContainerId } from '@/helpers/massCreateHelpers'
import * as ss from '@/config/session-storage-help'
import { Components } from '@/types/ticketsCreate'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { CRYOSHIPPER_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'

import {
  TICKET_SCHEDULED,
  TICKET_IN_PROGRESS,
  TICKET_COMPLETE,
  TICKET_OPEN_CAPITALS,
  TICKET_CRYOSHIPPER
} from '@/constants'

import { ROUTE_MOVE_TICKET } from '@/constants/moveLocationTicketSteps'
import { watch } from 'vue'
import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'
import toast from '@/config/toast'
import exclamationIcon from '@/assets/exclamation-triangle-dark.svg'

dayjs.extend(utc)

const MAX_TICKETS = 49

const router = useRouter()

const ticketsModel = ref<any>({
  sort: {
    orderBy: 'procedureTime',
    direction: 'desc'
  },
  options: []
})

const { moveLocationTickets } = useGetters('ticketsModule')

const { sourceLocation, destinationLocation, cryoShipperTickets } =
  useGetters<BiorepositoryTicketModule['getters']>('biorepoTicketModule')

// const hasTickets = computed(() => {
//   return !!ticketsModel.value.options.length
// })

const ticketsByStatus = computed(() => {
  const tickets = {}
  cryoShipperTickets.value?.forEach((ticket) => {
    if (!tickets[ticket.screeningStatus]) {
      tickets[ticket.screeningStatus] = []
    }
    tickets[ticket.screeningStatus].push(ticket)
  })

  return tickets
})

const isValid = computed(() => {
  const keys = Object.keys(ticketsByStatus.value)
  // Check if there is more than 2 status
  if (keys.length === 3) {
    return false
  }
  // Check if there is only 1 status, allows double max tickets (98)
  if (keys.length === 1) {
    return ticketsByStatus.value[keys[0]].length <= MAX_TICKETS * 2
  }
  // If there is two status, allows 49 each
  for (let i = 0; i < keys.length; i += 1) {
    if (ticketsByStatus.value[keys[i]].length > MAX_TICKETS) {
      return false
    }
  }
  return true
})

const addDisabled = computed(() => {
  return !hasSelectedTickets.value || !isValid.value
})

const hasSelectedTickets = computed(() => {
  return !!cryoShipperTickets.value?.length
})

const isCryoShipperDisabled = computed(() => {
  const isFeatureDisabled = !isFeatureEnabled(CRYOSHIPPER_ENABLED_FF)
  const destinationSiteId = destinationLocation.value?.id
  const sourceSiteId = sourceLocation.value?.id
  const source = allSites.value.find(({ value }) => value === Number(sourceSiteId))
  const destination = allSites.value.find(({ value }) => value === Number(destinationSiteId))
  const hasBioRepo = source?.isBiorepo || destination?.isBiorepo
  const isSameLocation = sourceSiteId === destinationSiteId
  return isFeatureDisabled || addDisabled.value || !hasBioRepo || isSameLocation
})

// const headerInfo = computed(() => {
//   const items = ticketsModel.value.options.flat()
//   const totalNumTickets = getTotalNumTickets(items)
//   return `<span class="font-normal">Displaying </span>${totalNumTickets} eligible Relocation Tickets <span class="font-normal">from your</span> Relocation Flight Board. <span class="font-normal">Select any column header to reorder the display.</span>`
// })

const { fetchMoveLocationTickets } = useActions('ticketsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { setSelectedTickets, setSelectedViewMode } = useActions('selectedTicketsModule')
const { setCryoShipperTickets } =
  useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')

const onViewClicked = (ticket) => {
  setSelectedViewMode(TICKET_CRYOSHIPPER)
  setSelectedTickets([ticket.value])
  router.push({ name: ROUTE_MOVE_TICKET })
}

// const handleNewCryoShipperTicket = async () => {
//   router.push({ name: CRYOSHIPPER_CONFIRM })
// }

const groupTicketsByStatus = (tickets) => {
  const readyForMoveTickets: any[] = []
  const readyForOffSiteTickets: any[] = []
  const completeTickets: any[] = []

  tickets.forEach((ticket) => {
    switch (ticket.state) {
      case TICKET_SCHEDULED:
        readyForMoveTickets.push(ticket)
        break
      case TICKET_IN_PROGRESS:
        readyForOffSiteTickets.push(ticket)
        break
      case TICKET_COMPLETE:
        completeTickets.push(ticket)
        break
    }
  })

  const ticketsGrouped = [readyForMoveTickets, readyForOffSiteTickets, completeTickets]

  if (!ticketsGrouped.flat().length) return []

  return ticketsGrouped.flat()
}

// const handleCancel = () => {
//   router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
// }

watch(moveLocationTickets, (nextTickets) => {
  // @ts-ignore
  const tickets = groupTicketsByStatus(nextTickets)
  const selectedIds = cryoShipperTickets.value.map(({ ticketId }) => ticketId)
  const options = tickets.map((ticket) => ({
    ...ticket,
    selected: selectedIds.includes(ticket.ticketId)
  }))
  ticketsModel.value = {
    ...ticketsModel.value,
    options
  }
})
watch(ticketsModel, (nextTableData, prevTableData) => {
  if (nextTableData.options !== prevTableData.options) {
    // @ts-ignore
    const selectedTickets = nextTableData.options.flat().filter((option) => option.selected)
    setCryoShipperTickets(selectedTickets)
  }
})
watch(isValid, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    // @ts-ignore
    const doesErrorMessageExist = [...document.getElementsByClassName('!bg-tmrw-warning')]
    doesErrorMessageExist.map((toast) => toast.remove())
    if (!newValue) {
      toast.customError({
        title: `Only 1-${
          MAX_TICKETS * 2
        } CryoBeacons, with up to 2 screening statuses (one per CryoGrid) can be added at a time.`,
        icon: exclamationIcon,
        customClasses: ['!bg-tmrw-warning !text-tmrw-blue-dark']
      })
    }
  }
})

const ticketsCreatedQty = ref(0)

const loadingTable = ref(false)

const breadCrumsItems = computed(() => {
  return getBiorepoBreadcrumbs({ active: 'created', ticketMode: TICKET_RELOCATION })
})
const { allSites } = useGetters('siteModule')
const { getCryobeaconsCreated, getCryobeacons } = useGetters('massCreateModule')
const { fetchTicketsById } = useActions('ticketsModule')

onMounted(async () => {
  setSelectedTickets([])
  setSelectedViewMode('normal')
  try {
    displaySpinner()
    await fetchMoveLocationTickets({
      ticketState: TICKET_OPEN_CAPITALS,
      sourceSiteId: sourceLocation.value?.id,
      destinationSiteId: destinationLocation.value?.id,
      includeAllSites: false,
      includeDateRange: false,
      siteMode: undefined,
      onlyRelocation: true
    })
  } catch (error) {
    console.error('An error occurred while fetching move location tickets:', error)
  } finally {
    hideSpinner()
  }

  let successTicketsData = []
  ticketsCreatedQty.value = getSuccessTickets.value.length
  if (getSuccessTickets.value.length) {
    successTicketsData = await getTicketsInfo(getSuccessTickets.value)
  }
  ss.setFieldSessionStorage('isBiorepositoryRoute', true, 'process')

  sanitizeDataForTable(successTicketsData)
})

const getSuccessTickets = computed<TicketsResponse[]>(() => {
  return getCryobeaconsCreated.value.ticketIds || []
})

const getTicketsWithErrors = computed<Components.Schemas.ErrorResponse[]>(() => {
  return getCryobeaconsCreated.value.errors || []
})

const headerInfo = computed<string>(() => {
  return `<span class="font-normal">Displaying </span>${getSuccessTickets.value.length} ${MOVE_LOCATION} Tickets <span class="font-normal">and</span> ${getTicketsWithErrors.value.length} Errors.`
})

const amountOfErrors = computed<number>(() => {
  return getTicketsWithErrors.value.length
})

const handlePutInCryoShipper = async () => {
  router.push({ name: CRYOSHIPPER_CONFIRM })
}
const handleReturnToFbBtn = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}

// TODO: Future work for cryoShipper
// const viewSelectedTicket = () => {
//   setSelectedTickets(getTicketsSelected.value)
// }

const getTicketsInfo = async (successTickets: Array<TicketsResponse>) => {
  loadingTable.value = true
  let tickets: any = []
  try {
    tickets = await fetchTicketsById(successTickets)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  } finally {
    loadingTable.value = false
  }
  return tickets
}

// const getTicketsSelected = computed(() => {
//   const cryobraconsSelected = ticketsModel.value.options.flat().filter((ticket) => ticket?.selected)

//   return cryobraconsSelected
// })

const sanitizeDataForTable = (ticketsSuccess: Array<TicketsResponse>) => {
  // TODO: For CryoShipper work this line should be removed
  const tempDisableCheckboxes = ticketsSuccess.map((ticket) => {
    return {
      ...ticket
      //disabled: true
    }
  })

  const errorTickets = getTicketsWithErrorsTableData.value || []
  const ticketsGrouped = [errorTickets, tempDisableCheckboxes]

  if (!ticketsGrouped.flat().length) return []

  ticketsModel.value = {
    ...ticketsModel.value,
    options: ticketsGrouped
  }
}

const getCompleteDataWithErrors = computed(() => {
  let errorsBarcodeKeys = {}
  for (const errorRow of getTicketsWithErrors.value) {
    if (errorRow.cryobeaconBarcode) {
      errorsBarcodeKeys[errorRow.cryobeaconBarcode] = errorRow
    }
  }

  const completeRowErrors = getCryobeacons.value.reduce((acum, value) => {
    if (errorsBarcodeKeys[value.cryobeaconBarcode]) {
      acum.push({
        ...value,
        ...errorsBarcodeKeys[value.cryobeaconBarcode]
      })
    }

    return acum
  }, [])

  return completeRowErrors
})

const getTicketsWithErrorsTableData = computed<Array<TableDataError>>(() => {
  const errorTickets = getCompleteDataWithErrors.value.map((ticket) => {
    const containerId = getContainerId(allSites.value, sourceLocation.value!.id, ticket.unitId)
    return {
      procedureTime: '',
      identificationNumber: ticket.patient.id,
      destination: '',
      source: sourceLocation.value?.name || '',
      robotLocations: [
        {
          type: 'Source',
          transferStatus: 'READY',
          robotTransfer: 'IN',
          siteName: sourceLocation.value?.name,
          robotId: containerId
        }
      ],
      ticketId: 'ERROR',
      patientDob: ticket.patient?.birthdate,
      patient: ticket.patient,
      beaconBarcode: ticket.cryobeaconBarcode,
      code: ticket.code,
      disabled: true
    }
  }) as Array<TableDataError>

  return errorTickets
})
</script>
