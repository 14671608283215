/* eslint-disable max-classes-per-file */
import dayjs from 'dayjs'
import screenStatusUnscreened from '@/assets/images/screenstatus-unscreened.svg'
import screenStatusPositive from '@/assets/images/screenstatus-positive.svg'
import screenStatusNegative from '@/assets/images/screenstatus-negative.svg'
import {
  EMR_SOURCE_ID,
  FILTER_TODAY,
  FILTER_TOMORROW,
  FILTER_NEXT_7_DAYS,
  FILTER_NEXT_30_DAYS,
  FILTER_30_DAYS,
  PROCEDURE_BATCH_RETRIEVE,
  INFECTIOUS_SCREENING_STATUS,
  PROCEDURE_TYPE_MOVE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  FILTER_30_DAYS_TODAY,
  FILTER_FROM_TODAY,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  CURRENT_LOCATION
} from '@/constants'
import * as ss from '@/config/session-storage-help'

// types
type mappingType = { [key: string]: number | string | object }
type sortData = { a: {}; b: {}; sort: (params: object) => {} }

type SelectedItemType = {
  ticketId: number,
  screeningStatusId: number,
  procedure: string,
  robotId: string
}

export type SortProps = {
  direction?: string,
  key?: string
  toggle?: boolean
}
type FactoryProps = {
  data: sortData,
  sortBy: string,
  direction?: string
}

const DEFAULT_DIRECTION = 'asc'

class SortFactory {
  data?: sortData

  sortBy: string

  direction: string

  public constructor({data, sortBy, direction = DEFAULT_DIRECTION}: FactoryProps) {
    this.data = data
    this.sortBy = this.getIdentifier(sortBy)
    this.direction = direction
  }

  /**
   * sortColumn is sorting  the Data Values
   * @param data Object
   * @returns Object
   */
  public sortColumn({ direction, key, toggle }: SortProps = {}): object | null | undefined {
    try {
      const sortBy =  this.getIdentifier(key)
      if(direction) {
        // Assigns the direction
        this.direction = direction
      } else if(sortBy !== this.sortBy) {
        // Resets direction to default
        this.direction = DEFAULT_DIRECTION
      } else if(toggle) {
        // Toggles direction
        this.direction = this.direction === 'asc' ? 'desc' : 'asc'
      }
      this.sortBy = sortBy
      this.data?.sort((a: mappingType, b: mappingType) => {
        // checking if the this.key exist on the root level
        // if not execute the sort on a deeper level
        if (
          !Object.prototype.hasOwnProperty.call(a, this.sortBy)
          || !Object.prototype.hasOwnProperty.call(b, this.sortBy)
        ) {
          return this.deepSort(this.sortBy, a, b)
        }
        if (a[this.sortBy] < b[this.sortBy]) {
          return this.direction === 'asc' ? -1 : 1
        }
        if (a[this.sortBy] > b[this.sortBy]) {
          return this.direction === 'asc' ? 1 : -1
        }
        return 0
      })
      return this.data
    } catch (error: any) {
      console.error('Sort Error', error)
      throw error
    }
  }

  /**
   * Deep Sort is used to sorting into the nested references
   * till a level + 1 of depth
   * @param k String
   * @param a Object
   * @param b Object
   * @returns Object
   * @example robotLocations: { boxId, robotNumber: "1", ... }
   */
  private deepSort(k: string, a: ReturnType<typeof Object>, b: ReturnType<typeof Object>) {
    try {
      const refs: string[] = k.split('.')
      if (
        !Object.prototype.hasOwnProperty.call(a, [refs[0]][0])
        || !Object.prototype.hasOwnProperty.call(b, [refs[0]][0])
      ) {
        return 0
      }
      if (a[refs[0]][0][refs[1]] < b[refs[0]][0][refs[1]]) {
        return this.direction === 'asc' ? -1 : 1
      }
      if (a[refs[0]][0][refs[1]] > b[refs[0]][0][refs[1]]) {
        return this.direction === 'asc' ? 1 : -1
      }
      return this
    } catch (error) {
      return error
    }
  }

  /**
   * getIdentifier is used to pair the key with the proper object parameter
   * @param key string
   * @returns string or undefined
   */
  public getIdentifier(key): string {
    if(!key) {
      return this.sortBy
    }
    switch (key) {
      case 'Date and Time':
        return 'procedureTime'
      case 'Patient Name':
        return 'patientName'
      case 'ID Number':
        return 'identificationNumber'
      case 'Date of Birth':
        return 'patientDob'
      case 'Ticket #':
        return 'ticketId'
      case INFECTIOUS_SCREENING_STATUS:
        return 'screeningStatus'
      case CURRENT_LOCATION:
        return 'robotLocations.robotNumber'
      case 'Procedure':
        return 'procedureName'
      case 'Status':
        return 'state'
      default:
        return key
    }
  }
}

/**
 * ScreeningStatusFactory is used to generate the screening status Reponse
 * @param key number
 */
class ScreeningStatusFactory {
  private screeningId: number

  private response: { img: string; text: string }

  constructor(screeningId: number = 0) {
    this.screeningId = screeningId
    this.response = { img: '', text: '' }
  }

  /**
   * compositeResponse is used to generate the screening status object response to be render on the functional template
   * @param screeningId number
   * @returns Object
   */
  compositeResponse() {
    try {
      switch (this.screeningId) {
        case 1:
          this.response = {
            img: screenStatusPositive,
            text: 'Screen Positive'
          }
          break
        case 2:
          this.response = {
            img: screenStatusNegative,
            text: 'Screen Negative'
          }
          break
        case 3:
          this.response = {
            img: screenStatusUnscreened,
            text: 'Unscreened'
          }
          break
        default:
          this.response = {
            img: '',
            text: ''
          }
          break
      }
      const template = `<div class="screening-status-label--white justify-start screening-status-label w-full flex items-center"><span class="screening-status-label__icon-container flex items-center justify-center"><img src="${this.response.img}" class="screening-status-label__icon w-4 h-4 mr-1 screening-status-label__icon--positive svg-white"></span><span class="screening-status-label__name truncate inline-block py-2 screening-status-label--white"> ${this.response.text}</span></div>`
      return template
    } catch (error) {
      return error
    }
  }
}

/**
 * SelectionFactory is used to keep track of the object items have been marked as selected / unselected
 * @param ref string
 * @param selectedItems Object
 * @param id Number
 * @param screeningId Number ** is optional
 */
class SelectionFactory {
  private ref: string

  private selectedItems: SelectedItemType[]

  private id: number

  private screeningId: number

  private procedure: string

  private robotId: string

  constructor(
    ref: string,
    selectedItems: any[],
    id: number,
    procedure: string,
    robotId: string,
    screeningId?: number
  ) {
    this.ref = ref
    this.selectedItems = selectedItems
    this.id = id
    this.screeningId = screeningId || 0
    this.procedure = procedure
    this.robotId = robotId
  }

  /**
   * selectedColumn is used to identify if the column it has been already selected, if so it'll mark as deselect it  the item, if not it will marked as selected
   * @param ref string
   * @param selectedItems Object
   * @param id Number
   * @returns Object
   */
  selectedColumn(): SelectedItemType[] {
      if (this.ref.includes('selected-row')) {
        this.selectedItems.forEach((selectedItem, index) => {
          // removing id from selected items
          if (selectedItem.ticketId === this.id) {
            this.selectedItems.splice(index, 1)
          }
        })
      } else {
        this.selectedItems.push({
          ticketId: this.id,
          screeningStatusId: this.screeningId,
          procedure: this.procedure,
          robotId: this.robotId
        })
      }
      return this.selectedItems
  }
}

/**
 * PersistenceFactory is used to verify an enabled just the beacons with the same screening
 * @param retrieveProcess String
 * @returns Object
 */

class PersistenceFactory {
  private retrieveProcess: string

  constructor() {
    this.retrieveProcess = PROCEDURE_BATCH_RETRIEVE
  }

  /**
   * batchPersistence is used to enabled / disabled the table items that not match the accepted criteria
   * accepted criteria: same screening status and same robotLocation
   * accepted criteria 2: Move tickets can only be batched with others move procedure, keeping the previous validations
   * @param apiElements Object
   * @param selectedItems Object
   * @param robotSelected Number
   * @param referenceKey Number
   * @param disabledKey Number
   * @param screenigKey Number
   * @param procedureName String
   * @returns Object
   */
  public batchPersistence(
    apiElements: { map: (params: {}) => {} },
    selectedItems: SelectedItemType[],
    robotSelected: number,
    referenceKey: number,
    procedureName: string,
    disabledKey: number,
    screenigKey: number,
    procedureKey: number,
    robotIdKey: number
  ): InstanceType<typeof Object> {
    // eslint-disable-next-line no-useless-catch
    try {
      apiElements.map((item: [{ value: number | string; optionalFlag: number }]) => {
        if (selectedItems.length > 0) {
          selectedItems.forEach(
            (selectedItem: SelectedItemType) => {
              if (selectedItem.procedure.includes(PROCEDURE_TYPE_MOVE)) {
                if (!(item[procedureKey].value as string).includes(PROCEDURE_TYPE_MOVE)) {
                // Disable all cryobeacons that are not MOVE
                  Object.assign(item[referenceKey], { ...item[referenceKey], disabled: true })
                }
              } else {
                if ((item[procedureKey].value as string).includes(PROCEDURE_TYPE_MOVE)) {
                // Disable all cryobeacons that are MOVE
                  Object.assign(item[referenceKey], { ...item[referenceKey], disabled: true })
                }
              }
              if (selectedItem.ticketId === item[referenceKey].value) {
              // adding selected attribute to the cloned reference in order to display as selected
                Object.assign(item[referenceKey], { ...item[referenceKey], selected: true })
              }
              // disabled all the cryobeacons without the same screening status
              if (selectedItem.screeningStatusId !== item[screenigKey].optionalFlag) {
                Object.assign(item[referenceKey], { ...item[referenceKey], disabled: true })
              }
              if (procedureName === this.retrieveProcess && selectedItems.length > 0) {
                if (item[disabledKey].optionalFlag !== robotSelected) {
                // mark as disabled all the different robotLocation as robotSelected value
                  Object.assign(item[referenceKey], { ...item[referenceKey], disabled: true })
                }
              }
              const selectedMethod = ss.getFieldSessionStorage('selectedMethod', 'newTicket')
              const batchSubType = ss.getFieldSessionStorage('subType', 'newTicket')
              if (
                selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE
                && batchSubType === PROCEDURE_BATCH_RETRIEVE
                && item[robotIdKey].value !== selectedItem.robotId
              ) {
                // mark as disabled all the different robotLocation as robotSelected value
                Object.assign(item[referenceKey], { ...item[referenceKey], disabled: true })
              }
            }
          )
        }
        return apiElements
      })
      return apiElements
    } catch (error) {
      throw error
    }
  }
}
/**
 * RequestParams is used to generate a new date
 * @param filter String
 */
class RequestParams {
  private filter: string

  constructor(filter: string) {
    this.filter = filter
  }

  /**
   * getTicketsRequestParams is used to generate a new date according to the filter parameter
   * @param filter String
   * @returns Object
   */
  getTicketsRequestParams = () => {
    const dateFrom: ReturnType<typeof dayjs> = dayjs().startOf('day')
    const dateTo: ReturnType<typeof dayjs> = dayjs().endOf('day')
    const defaultParams = {
      dateFromMsUtc: '21600000',
      dateToMsUtc: dateTo.utc().valueOf()
    }
    switch (this.filter) {
      case FILTER_TODAY:
        return {
          ...defaultParams
        }
      case FILTER_TOMORROW:
        return {
          ...defaultParams,
          dateFromMsUtc: dateFrom.add(1, 'days').utc().valueOf(),
          dateToMsUtc: dateTo.add(1, 'days').utc().valueOf()
        }
      case FILTER_NEXT_7_DAYS:
        return {
          ...defaultParams,
          dateFromMsUtc: dateFrom.add(1, 'days').utc().valueOf(),
          dateToMsUtc: dateTo.add(7, 'days').utc().valueOf()
        }
      case FILTER_NEXT_30_DAYS:
        return {
          ...defaultParams,
          dateFromMsUtc: dateFrom.add(1, 'days').utc().valueOf(),
          dateToMsUtc: dateTo.add(30, 'days').utc().valueOf()
        }
      case FILTER_30_DAYS:
        return {
          ...defaultParams,
          dateFromMsUtc: dateFrom.add(30, 'days').utc().valueOf(),
          dateToMsUtc: dateTo.add(30, 'days').add(1, 'year').utc().valueOf()
        }
      case FILTER_30_DAYS_TODAY:
        return {
          ...defaultParams,
          dateToMsUtc: dateTo.add(30, 'days').utc().valueOf()
        }
      case FILTER_FROM_TODAY:
        return {
          ...defaultParams
        }
      default:
        return defaultParams
    }
  }
}

/**
 * StatusFactory is used to identify the ticket status
 * @param status String
 * @param procedureName String
 * @param ticketSource Number
 * @param response Array
 * @acceptedCriteria
 * Ticket Source
      INTERNAL(1, "Internal"),
      EMR(2, "EMR"),
      BULK_SPECIMEN(3, "Bulk-Specimen");
*/
class StatusFactory {
  private status: string

  private procedureName: string

  private procedureType: string

  private ticketSource: number

  public response: string[]

  constructor(status: string, procedureName: string, ticketSource: number, procedureType) {
    this.status = status
    this.procedureName = procedureName
    this.procedureType = procedureType
    this.ticketSource = ticketSource
    this.response = []
  }

  /**
   * batchStatus is used to generate the HTML reponse to be render on the View
   * @returns Array
   */
  batchStatus() {
    this.ticketStatus()
    this.isImport()
    this.getEMRImage()
    const callback = ''.concat(...this.response)
    return callback
  }

  /**
   * ticketStatus is used to validate if the ticket is on scheduled status
   * @returns Array
   */
  private ticketStatus() {
    // checking ticket status
    switch (this.status) {
      case 'Scheduled':
        this.response.push('<i class="far fa-calendar-plus mx-1"></>')
        break
      default:
        break
    }
  }

  /**
   * isImport is used to validate if the ticket is on import status
   * @returns Array
   */
  private isImport() {
    // Embryo Single Import
    if (this.procedureType && this.procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
      this.response.push('<i class="fas fa-file-medical mx-1"></i>')
    }
  }

  /**
   * getEMRImage is used to validate if the ticket is an EMR source
   * @returns Array
   */
  private getEMRImage() {
    // verify the source
    if (this.ticketSource === EMR_SOURCE_ID) {
      this.response.push('<i class="fa fa-lock mx-1"></i>')
    }
  }
}

/**
 * SortByItemParam is used to sort the table by specific params
 * @param batchData Object
 * @param ticket Object
 * @param flag String
 */
class SortByItemParam {
  /**
   * sortByFlag is used to sort the batchData by specific flag
   * @param batchData Object
   * @param ticket Object
   * @param flag String
   * @returns Array
   */
  sortByFlag(
    batchData?: [
      {
        specimens: [{ cryodeviceBarcode: number }]
        procedureName: string
        ticketId: number
        source: number
      }
    ],
    ticket?: { cryoDevice: [{ cryodeviceBarcode: number }] },
    flag?: string
  ) {
    let tempValue: string | number = ''
    if (batchData && ticket) {
      for (const item of batchData) {
        const { cryodeviceBarcode } = ticket.cryoDevice[0]
        for (const specimen of item.specimens) {
          if (specimen.cryodeviceBarcode === cryodeviceBarcode) {
            switch (flag) {
              case 'procedureName':
                tempValue = item.procedureName
                return tempValue
              case 'ticketId':
                tempValue = item.ticketId
                return tempValue
              case 'source':
                tempValue = item.source
                return tempValue
              default:
                return false
            }
          }
        }
      }
    }
    return tempValue
  }
}

export {
  SortFactory,
  ScreeningStatusFactory,
  SelectionFactory,
  RequestParams,
  PersistenceFactory,
  StatusFactory,
  SortByItemParam
}
