<template>
  <MainContainer gridSlots="2">
    <TopHeader>{{ headerText }}</TopHeader>
    <div class="flex items-center justify-center">
      <ButtonDynamic
        :key="button.key"
        v-for="button in buttons"
        :data-test="button.dataTest"
        class="m-5 font-semibold h-32 w-72 p-10"
        btnType="button"
        btnStyle="primary"
        btnSize="large"
        :isDisabled="button.disabled"
        :btnText="button.text"
        @click="button.onClick"
      />
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { MOVE_LOCATION, CRYOSHIPPER, TICKET_CRYOSHIPPER, TICKET_RELOCATION } from '@/constants'
import { STEP_SELECT_LOCATION } from '@/constants/moveLocationTicketSteps'
import useActions from '@/composables/useActions'

import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'
import { CRYOSHIPPER_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'

const router = useRouter()

const { setTicketMode } = useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')

const headerText = ref('Create a Relocation')

const buttons = computed(() => [
  {
    text: MOVE_LOCATION,
    key: 'btn-relocation',
    disabled: false,
    dataTest: 'relocation-btn',
    onClick: onClickRelocation
  },
  {
    text: CRYOSHIPPER,
    key: 'btn-cryoshipper',
    disabled: !isFeatureEnabled(CRYOSHIPPER_ENABLED_FF),
    dataTest: 'cryoshipper-btn',
    onClick: () => onClickCryoShipper()
  }
])

onMounted(() => {
  setTicketMode('none')
})

const onClickRelocation = () => {
  setTicketMode(TICKET_RELOCATION)
  headerText.value = 'Create a Relocation'
  router.push({ name: STEP_SELECT_LOCATION })
}

const onClickCryoShipper = () => {
  setTicketMode(TICKET_CRYOSHIPPER)
  headerText.value = 'Create a New Cryoshipper Ticket'
  router.push({ name: STEP_SELECT_LOCATION })
}
</script>
