<template>
  <div
    data-test="modal-window"
    class="
      absolute
      top-0
      left-0
      w-screen
      h-screen
      justify-center
      items-center
      flex
      z-50
      bg-black bg-opacity-50
    "
    v-if="isModalOpen"
  >
    <div
      :class="[
        'bg-white p-4 shadow-md rounded-md',
        {
          'w-1/4': modalSize === 'sm',
          'w-2/4': modalSize === 'md',
          'w-3/4': modalSize === 'lg',
        },
        ...modalClass
      ]"
    >
      <ActionBar data-test="select-patient__action-bar" :cols-distribution="colsDistribution" addMarginBottom>
        <template v-slot:left-side>
          <dynamic-title
            :title-type="titleType"
            :float-to="floatTo"
            :is-semi-bold="isSemiBold"
            :is-bold="isBold"
            :is-error="isError"
            :is-warning="isWarning"
            :is-success="isSuccess"
            >
            {{ modalTitle }}
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnText=""
            btnType="button"
            btnStyle="close-button"
            showIcon
            :font-awesome-icon-class="closeIcon"
            @click="closeModalWindow"
          />
        </template>
      </ActionBar>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'modal-window'
  }
</script>

<script setup lang="ts">
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

const emit = defineEmits(['closeModalWindow'])
defineProps({
    modalTitle: {
      type: String,
      default: '',
      required: true
    },
    modalClass: {
      type: Array,
      default: () => [],
      required: false
    },
    modalSize: {
      type: String,
      default: null,
      validator: (value: string) => ['sm', 'md', 'lg'].includes(value),
      required: false
    },
    titleType: {
      type: String,
      default: 'h1-blue',
      required: false
    },
    colsDistribution: {
      type: String,
      default: '6/6',
      required: false
    },
    isModalOpen: {
      type: Boolean,
      default: false,
      required: true
    },
    floatTo: {
      type: String,
      default: 'left',
      required: false
    },
    isBold: {
      type: Boolean,
      default: false,
      required: false
    },
    isSemiBold: {
      type: Boolean,
      default: false,
      required: false
    },
    isError: {
      type: Boolean,
      default: false,
      required: false
    },
    isWarning: {
      type: Boolean,
      default: false,
      required: false
    },
    isSuccess: {
      type: Boolean,
      default: false,
      required: false
    },
    closeIcon: {
      type: String,
      required: false,
      default: 'times-circle'
    }
  })
  const closeModalWindow = () => {
    emit('closeModalWindow')
  }
</script>
