<template>
  <MainContainer class="select-specimens" gridSlots="3">
    <top-header>
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left"
          >Which Specimens would you like to export?</dynamic-title
        >
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="editTicketSpecimens"
          ref="select-specimens-to-export-view-btn-action-bar-comp-cancel"
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          addMarginRight
          @click="cancelEdit"
        />
        <button-dynamic
          v-if="editTicketSpecimens"
          ref="select-specimens-to-export-view-btn-action-bar-comp-confirm"
          btnText="Confirm"
          btnType="button"
          btnStyle="primary"
          :isDisabled="isValid"
          @click="submitEdit"
        />
        <button-dynamic
          v-else
          ref="select-specimens-to-export-view-btn-action-bar-comp-next"
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="isValid"
          @click="goNext"
        />
      </template>
    </ActionBar>
    <div class="main-container__body">
      <Breadcrumbs :items="breadcrumbsItems" />
      <loading-ui v-if="checkLoading" />
      <div v-else-if="checkNoSpecimen">
        <h3 class="no-specimens-title text-white">
          This patient has no Specimens of this type stored. An Export Ticket cannot be created.
        </h3>
      </div>
      <div v-else-if="checkErrorData">
        <h3 class="no-specimens-title text-white">There was an error requesting the data</h3>
      </div>
      <div v-else-if="checkTableHeaders && !isEditTicket">
        <div
          v-if="numberSpecimensOocytes > 0 || numberSpecimensEmbryos > 0"
          class="flex justify-between"
        >
          <div class="flex items-end">
            <Tabs :options="openSpecimensOptions" :active="filter" @changeValue="handleTabChange" />
          </div>
          <div data-test="export-specimen-count" class="text-xl text-white leading-6 mr-2">
            {{ specimensCount }} Specimen{{ specimensCount > 1 ? 's' : '' }}
          </div>
        </div>
        <ScrollTableComponent
          v-model="localSpecimensModel"
          :selectionMode="'checkbox'"
          :headers="headers"
          :min-height="'h-full'"
          singleSelection
          hasTabs
          hideSelectAllCheckbox
          @update:modelValue="handleTableChange"
          ignore-select-all-procedure-check
        />
        <p data-test="selected-specimens-count" class="text-white py-3">
          {{ selectedSpecimensMessage }}
        </p>
      </div>
      <div v-if="!checkLoading && isEditTicket">
        <ScrollTableComponent
          v-model="specimenModel"
          :selectionMode="'checkbox'"
          :min-height="'h-full'"
          :headers="headers"
          singleSelection
          @update:modelValue="handleTableChange"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import toast from '@/config/toast'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import * as specimensHeaders from '@/constants/table-headers/select-specimens'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Breadcrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  METHOD_DONATE,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE,
  SPECIMEN_INVENTORY_ACTION_DONATED
} from '@/constants'
import { SpecimensFactory } from '@/factories/index'
import { buildSpecimenSourcesValueObject } from '@/helpers/specimenHelpers'

export default {
  name: 'select-specimens',
  components: {
    ScrollTableComponent,
    TopHeader,
    HeaderInfo,
    DynamicTitle,
    LoadingUi,
    ButtonDynamic,
    Tabs,
    Breadcrumbs,
    MainContainer,
    ActionBar
  },
  data() {
    return {
      specimenModel: {
        sort: {
          orderBy: 'specimenId',
          direction: 'asc'
        },
        options: []
      },
      localSpecimensModel: {
        sort: {
          orderBy: 'cryoDate',
          direction: 'desc'
        },
        options: []
      },
      selectedSpecimens: [],
      preSelectedSpecimens: null,
      allSpecimens: [],
      editTicketSpecimens: false,
      beacons: [],
      selectedTicketToEdit: {},
      filter: SPECIMEN_TYPE_EMBRYO,
      exportStep: 1,
      isEditTicket: false,
      cacheResponse: []
    }
  },
  computed: {
    ...mapGetters('specimensModule', ['specimens', 'embryoTypes']),
    ...mapGetters('authModule', ['appBaseConfig']),
    ...mapGetters('newTicketModule', ['selectedPatient']),
    headers() {
      const filterLowerCase = this.filter.toLowerCase()
      const headers = specimensHeaders[filterLowerCase] || specimensHeaders.embryo
      return headers
    },
    selectedSpecimensMessage() {
      const embryo = []
      const oocyte = []
      this.selectedSpecimens.map((specimen) =>
        specimen.specimenType === SPECIMEN_TYPE_EMBRYO
          ? embryo.push(+specimen.specimenCount)
          : oocyte.push(+specimen.specimenCount)
      )
      const embryoCount = embryo.reduce((a, b) => a + b, 0)
      const oocyteCount = oocyte.reduce((a, b) => a + b, 0)
      return `${embryoCount} Embryo${embryo.length === 1 ? '' : 's'} and ${oocyteCount} Oocyte${
        oocyteCount === 1 ? '' : 's'
      } Selected`
    },
    specimensCount() {
      return this.localSpecimens.length
    },
    isValid() {
      return !this.selectedSpecimens.length
    },
    checkLoading() {
      return this.specimens.status === 'loading'
    },
    checkNoSpecimen() {
      return this.specimens.status === 'ok' && this.allSpecimens.length === 0
    },
    checkErrorData() {
      return this.specimens.status === 'error'
    },
    checkTableHeaders() {
      return this.headers.length > 0
    },
    numberSpecimensEmbryos() {
      const options = this.localSpecimens
      const localSpecimens = new SpecimensFactory(options)
      const localSpecimensResponse = localSpecimens.filterBySpecimenType(SPECIMEN_TYPE_EMBRYO)
      const specimens = new SpecimensFactory(localSpecimensResponse)
      const response = specimens.getSpecimensCount()
      return response
    },
    numberSpecimensOocytes() {
      const options = this.localSpecimens
      const localSpecimens = new SpecimensFactory(options)
      const localSpecimensResponse = localSpecimens.filterBySpecimenType(SPECIMEN_TYPE_EGG)
      const specimensCount = new SpecimensFactory(localSpecimensResponse)
      const response = specimensCount.getSpecimensCount()
      return response
    },
    localSpecimens() {
      return this.specimens.all.map(this.formatSpecimens)
    },
    openSpecimensOptions() {
      return [
        {
          embryoDevicesCount: this.numberSpecimensEmbryos,
          value: SPECIMEN_TYPE_EMBRYO,
          label: `${this.numberSpecimensEmbryos} Embryos`
        },
        {
          oocyteDevicesCount: this.numberSpecimensOocytes,
          value: SPECIMEN_TYPE_EGG,
          label: `${this.numberSpecimensOocytes} Oocytes`
        }
      ]
    },
    breadcrumbsItems() {
      return [
        {
          label: 'Specimens',
          active: this.exportStep === 1
        },
        {
          label: 'Reason',
          active: this.exportStep === 2
        },
        {
          label: 'Confirm',
          active: this.exportStep === 3
        }
      ]
    }
  },
  watch: {
    specimens() {
      this.allSpecimens = this.specimens.all.map((specimen) => ({
        ...specimen,
        cryoDate: Number(specimen.cryoDate),
        embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes)
      }))
    },
    async allSpecimens(newValue) {
      if (newValue) {
        this.specimenModel = {
          ...this.specimenModel,
          options: this.applySourcesValueObjectToSpecimens(this.allSpecimens)
        }
      }
      // Here are selected the specimens when edit ticket
      if (
        newValue &&
        newValue.length > 0 &&
        this.editTicketSpecimens &&
        !this.preSelectedSpecimens
      ) {
        const specimens = this.selectedTicketToEdit.specimens.map((item) => item.specimenId)
        this.preSelectedSpecimens = {
          key: 'specimenId',
          values: specimens
        }
        this.specimenModel = {
          ...this.specimenModel,
          options: this.specimenModel.options.map((option) => ({
            ...option,
            selected: specimens.includes(option.specimenId),
            sourcesValue: buildSpecimenSourcesValueObject(option)
          }))
        }
      }
    },
    async localSpecimens(newLocalSpecimensValue) {
      if (newLocalSpecimensValue) {
        this.localSpecimensChanges()
      }
    },
    filter(newFilterValue) {
      if (newFilterValue) {
        this.filterChanges()
      }
    }
  },
  async created() {
    try {
      const { clinicId, siteId } = this.appBaseConfig
      const patient =
        this.selectedPatient || this.$ss.getFieldSessionStorage('newTicket', 'patient')

      this.selectedTicketToEdit = this.$ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
      this.editTicketSpecimens =
        !!this.selectedTicketToEdit && Object.keys(this.selectedTicketToEdit).length > 0

      this.resetSpecimens()

      if (this.editTicketSpecimens) {
        const { globalPatientNumber } = this.selectedTicketToEdit
        const exportEditType = this.$ss.getFieldSessionStorage('process', 'exportEditType')
        const specimenType = exportEditType.toUpperCase()
        await this.fetchSpecimens({ patientIds: globalPatientNumber, specimenType })
        this.isEditTicket = true
        this.filter = specimenType
        // if we have data to work with
        if (this.specimenModel.options.length > 0) {
          // filtering data in order to return only the editable cryodevice
          const filteredData = this.specimenModel.options.filter(
            (item) => item.beaconBarcode === this.selectedTicketToEdit.beaconBarcode
          )
          // cloning the original response in case of need it
          this.cacheResponse = this.specimenModel.options
          // adding the new data to be disaplay
          this.specimenModel.options = filteredData
        }
      } else {
        const globalPatientNumber =
          Object.keys(patient[0]).length > 0 ? patient[0].globalPatientNumber : 0
        if (clinicId && siteId && globalPatientNumber) {
          let specimenType = this.$ss.getFieldSessionStorage('newTicket', 'type').toUpperCase()
          specimenType = specimenType === 'EGGS' ? 'EGG' : specimenType
          this.fetchSpecimensEnabled([globalPatientNumber, specimenType])
        } else {
          this.$router.push('/select-procedure')
        }
      }
      this.$ss.setFieldSessionStorage('exportStep', 1, 'newTicket')
    } catch (error) {
      console.error(error)
      toast.error({ title: error.message })
    }
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    ...mapActions('specimensModule', [
      'fetchSpecimensEnabled',
      'fetchEmbryoTypes',
      'fetchSpecimens',
      'resetSpecimens'
    ]),
    handleTableChange(newValue) {
      // TODO: Bad performance on Big O notation - needs refactor or use for() for less time
      this.localSpecimens.map((localItem) =>
        newValue.options.map((selectedItem) => {
          if (localItem.cryodeviceBarcode === selectedItem.cryodeviceBarcode) {
            Object.assign(localItem, selectedItem)
          }
          return selectedItem
        })
      )

      const selectedItems = this.localSpecimens.filter((option) => option.selected)

      this.selectedSpecimens = this.allSpecimens.filter(
        (specimen) =>
          selectedItems.filter(
            (selected) => selected.cryodeviceBarcode === specimen.cryodeviceBarcode
          ).length
      )
      if (this.editTicketSpecimens) {
        // Edition
        this.selectedTicketToEdit = this.$ss.getFieldSessionStorage(
          'process',
          'selectedTicketToEdit'
        )
        this.selectedTicketToEdit.specimens = [
          ...this.selectedSpecimens.map((item) => ({
            ...item,
            inSelectedTicket: true
          }))
        ]
        this.$ss.setFieldSessionStorage(
          'selectedTicketToEdit',
          this.selectedTicketToEdit,
          'process'
        )
      } else {
        // Creation
        this.beacons = []
        this.selectedSpecimens.forEach((specimen) => {
          const existingBeacon = this.beacons.find(
            (item) => item.beaconBarcode === specimen.beaconBarcode
          )
          let beacon = {}
          const specimenWithTypeInfo = {
            ...specimen,
            ...(specimen.specimenType === SPECIMEN_TYPE_EMBRYO && {
              embryo: { ...specimen.embryo }
            }),
            ...(specimen.specimenType !== SPECIMEN_TYPE_EMBRYO && {
              oocyte: { ...specimen.oocyte }
            })
          }
          if (!existingBeacon) {
            beacon = {
              beaconBarcode: specimen.beaconBarcode,
              cryoDevice: [specimenWithTypeInfo]
            }
          } else {
            this.beacons = this.beacons.filter(
              (beac) => beac.beaconBarcode !== specimen.beaconBarcode
            )
            beacon = {
              ...existingBeacon,
              cryoDevice: [...existingBeacon.cryoDevice, specimenWithTypeInfo]
            }
          }
          this.beacons.push(beacon)
        })
        this.$ss.setFieldSessionStorage('beacons', this.beacons, 'newTicket')
      }
    },
    goNext() {
      this.$router.push('/select-reason-to-export')
    },
    async submitEdit() {
      this.specimens.status = 'loading'
      const { collectionProtocolId } = this.appBaseConfig
      const { specimens, ticketId, procedureName, procedure } = this.selectedTicketToEdit
      const data = {
        procedureName,
        specimens,
        ticketId,
        cpId: collectionProtocolId
      }
      try {
        if (procedure === METHOD_DONATE) {
          data.specimens.forEach((specimen) => {
            // eslint-disable-next-line no-param-reassign
            specimen.inventoryAction = SPECIMEN_INVENTORY_ACTION_DONATED
          })
        }
        await this.updateTickets({
          tickets: [data],
          ticketId
        })
        // hiding eslint checking in order to hide the loader
        // eslint-disable-next-line no-unused-expressions
        this.specimens.status === 'ok'
        this.$router.push('/selected-tickets')
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
      } catch (err) {
        // hiding eslint checking in order to hide the loader
        // eslint-disable-next-line no-unused-expressions
        this.specimens.status === 'error'
        toast.error({ title: err.message })
      }
    },
    cancelEdit() {
      this.$ss.setFieldSessionStorage('selectedTicketToEdit', {}, 'process')
      this.$router.push('/selected-tickets')
    },
    handleTabChange(option) {
      this.filter = option.value
    },
    formatSpecimens(specimen) {
      return {
        ...specimen,
        embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes),
        seeMore: {
          icon: 'eye',
          to: `/specimen-detail/${Number(this.$route.params.patientId)}/${specimen.specimenId}`
        }
      }
    },
    localSpecimensChanges() {
      const { sort } = this.localSpecimensModel
      const options = this.localSpecimens
      const filteredSpecimens = options.filter(
        (specimen) => specimen.specimenType === SPECIMEN_TYPE_EMBRYO
      )

      this.localSpecimensModel = {
        sort,
        options: this.applySourcesValueObjectToSpecimens(filteredSpecimens)
      }
    },
    filterChanges() {
      const filteredSpecimens = this.localSpecimens.filter(
        ({ specimenType }) => specimenType === this.filter
      )
      const specimensWithSourcesValues = this.applySourcesValueObjectToSpecimens(filteredSpecimens)
      this.localSpecimensModel.options = [...specimensWithSourcesValues]
    },
    clickBack() {
      return this.$ss.setFieldSessionStorage('currentStep', this.ticketStep - 1, 'newTicket')
    },
    applySourcesValueObjectToSpecimens(specimens) {
      return specimens.map((specimen) => ({
        ...specimen,
        sourcesValue: buildSpecimenSourcesValueObject(specimen)
      }))
    }
  }
}
</script>

<style lang="scss">
.select-specimens {
  .no-specimens-title {
    font-weight: 200;
    font-size: 20px;
  }

  .table-container {
    display: grid;
    grid-template-rows: auto 30px;
    height: 105%;
    width: 100%;
  }
}
</style>
