/* eslint-disable max-classes-per-file */
import { assertIsBooleanNonNullish, assertIsNonNullish } from '@/helpers/assertionFunctions'

type emrType = {
  emrIntegration: {
    enabled: NonNullable<boolean>,
    sms: {
      allowCreateTickets: NonNullable<boolean>,
      allowCreatePatients: NonNullable<boolean>,
      allowCreateStoreTickets: NonNullable<boolean>,
      allowCreateRetrieveTickets: NonNullable<boolean>,
      nonTmrwLocationDetails?: {
        allowEdit?: boolean
        isRequired?: boolean
      }
    },
    mirthForwarder: { enabled: NonNullable<boolean> }
  }
}

class EmrComposite {
  public config : emrType

  constructor(config: emrType) {
    this.config = config
  }

  public composite() {
    try {
      // destructuring the data
      const { emrIntegration } = this.config

      // cloning the data to avoid mutation
      const cloneObj = JSON.parse(JSON.stringify(emrIntegration))

      // destructure data and assign it to a const
      const emrEnabled : NonNullable<boolean> = assertIsBooleanNonNullish(cloneObj.enabled)
      const smsEnabled : NonNullable<boolean> = assertIsBooleanNonNullish(cloneObj.sms)
      const mirthForwarderEnabled : NonNullable<boolean> = assertIsBooleanNonNullish(cloneObj.mirthForwarder)

      // checking we're not getting null or undefined values
      assertIsNonNullish(emrEnabled, 'emrIntegration -> enabled')
      assertIsNonNullish(smsEnabled, 'emrIntegration -> sms')
      assertIsNonNullish(mirthForwarderEnabled, 'emrIntegration -> mirth Forwarder')

      // Freeze the response in order to avoid mutation
      return Object.freeze(emrIntegration)
    } catch (error) {
      // in case we're getting undefined as value, returns the default value
      throw new Error('Somthing were wrong, please check your EMR config')
    }
  }
}

class EmrFactory extends EmrComposite {
  public smsPermissionsEnabled(): object | unknown {
    try {
      const compositeResponse = this.composite()
      const {
        enabled: emrTransactionsEnabled,
        sms: {
          allowCreatePatients,
          allowCreateStoreTickets: rawAllowCreateStoreTickets,
          allowCreateRetrieveTickets: rawAllowCreateRetrieveTickets,
          allowCreateTickets,
          nonTmrwLocationDetails
        }
      } = compositeResponse
      // fallback to allowCreateTickets flag if the flag is not existing
      const allowCreateStoreTickets = typeof rawAllowCreateStoreTickets === 'boolean' ? rawAllowCreateStoreTickets : allowCreateTickets
      const allowCreateRetrieveTickets = typeof rawAllowCreateRetrieveTickets === 'boolean' ? rawAllowCreateRetrieveTickets : allowCreateTickets
      
      return {
        emrTransactionsEnabled,
        allowCreatePatients,
        allowCreateTickets,
        allowCreateStoreTickets,
        allowCreateRetrieveTickets,
        nonTmrwLocationDetails
      }
    } catch (error) {
      return error
    }
  }
}

export {
  EmrFactory
}
