import { BiorepositoryTicketState } from '../state'

const getters = {
  sourceLocation: (state: BiorepositoryTicketState) => state.sourceLocation,
  selectedBeacons: (state: BiorepositoryTicketState) => state.selectedBeacons,
  selectedPatient: (state: BiorepositoryTicketState) => state.selectedPatient,
  destinationLocation: (state: BiorepositoryTicketState) => state.destinationLocation,
  destinationCryorobot: (state: BiorepositoryTicketState) => state.destinationCryorobot,
  ticketMode: (state: BiorepositoryTicketState) => state.ticketMode,
  cryoShipperTickets: (state: BiorepositoryTicketState) => state.cryoShipperTickets
}

export type BiorepositoryTicketGetters = typeof getters

export default getters
