<template>
  <ButtonDynamic
    v-if="!hidden"
    btnStyle="secondary"
    btnType="button"
    :btnText="options.label"
    @click="onClick"
  />
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'scroll-table-remove-button-field',
  components: {
    ButtonDynamic
  },
  emits: ['onButtonClicked'],
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    options: {
      type: Object,
      required: true
    },
    columnOptions: {
      type: Object,
      required: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('onButtonClicked', this.value, this.columnOptions)
    }
  },
  computed: {
    title() {
      return this.options.title
    },

    hidden() {
      const { shouldHide } = this.options
      if (shouldHide) {
        return shouldHide(this.item)
      }
      return false
    }
  }
}
</script>
