export default {
  setScreeningStatus(state, payload) {
    state.screeningStatus = payload
  },
  setScreeningStatusError(state, payload) {
    state.error = payload
  },
  setScreeningStatusLoading(state, payload) {
    state.loading = payload
  }
}
