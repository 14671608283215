import dayjs from 'dayjs'
import {
  APPLIED_TO,
  DATE_AND_TIME,
  FILE_NAME,
  TYPE,
  PATIENT_NAME
} from '@/constants'

import {
  getDocumentType
} from '@/helpers/documentType'

const dateTime = {
  name: DATE_AND_TIME,
  key: 'createdAt',
  sort: true,
  filters: [
    (value: string) => dayjs(value).format('DDMMMYYYY HH:mm'),
    (value: string) => value.toUpperCase()
  ]
}

const patientName = {
  name: PATIENT_NAME,
  key: 'userFullName',
  cssClasses: ['w-64']
}

const fileName = {
  name: FILE_NAME,
  key: 'filename'
}

const type = {
  name: TYPE,
  key: 'type',
  filters: [
    (value: string) => getDocumentType(Number(value))
  ]
}

const appliedTo = {
  name: APPLIED_TO,
  key: 'appliedTo'
}

export const headers = [
  dateTime,
  patientName,
  fileName,
  type,
  appliedTo
]
