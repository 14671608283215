<template>
  <section class="bg-white rounded">
    <status-table-header v-if="ticketDetail" :ticket-details="ticketDetail" class="px-2" />
    <div class="mb-2" v-if="hasEmbryoSpecimens">
      <ScrollTableComponent
        v-model="embryoSpecimensModel"
        :headers="embryoSpecimenHeaders"
        minHeight="h-auto"
        hasScroll
        @update:modelValue="handleEmbryosInventoryInput"
      >
        <template v-slot:table-container-header>
          <h1
            class="font-inconsolata font-bold text-base text-tmrw-blue border-b border-dashed border-tmrw-gray mt-2 pb-2 px-2"
          >
            {{ embryoSpecimensCaption }}
          </h1>
        </template>
      </ScrollTableComponent>
    </div>

    <div v-if="hasEggSpecimens">
      <ScrollTableComponent
        v-model="eggSpecimensModel"
        :headers="eggSpecimenHeaders"
        minHeight="h-auto"
        hasScroll
        @update:modelValue="handleEggsInventoryInput"
      >
        <template v-slot:table-container-header>
          <h1
            class="font-inconsolata font-bold text-base text-tmrw-blue border-b border-dashed border-tmrw-gray mt-2 pb-2 px-2"
          >
            {{ eggSpecimensCaption }}
          </h1>
        </template>
      </ScrollTableComponent>
    </div>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import StatusTableHeader from '@/components/StatusTableHeader/StatusTableHeader.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { SPECIMEN_TYPE_EMBRYO, SPECIMEN_TYPE_OOCYTE } from '@/constants/'

// Props
const props = defineProps({
  ticketDetail: {
    type: Object,
    default: null
  },
  embryoSpecimenHeaders: {
    type: Array
  },
  eggSpecimenHeaders: {
    type: Array
  },
  embryoSpecimens: {
    type: Object
  },
  eggSpecimens: {
    type: Object
  }
})

const eggSpecimensModel = ref({
  sort: {
    orderBy: 'procedureTime',
    direction: 'asc'
  },
  options: []
})

const embryoSpecimensModel = ref({
  sort: {
    orderBy: 'procedureTime',
    direction: 'asc'
  },
  options: []
})

// Computed
const hasEmbryoSpecimens = computed(() => Boolean(props.embryoSpecimens?.options.length))
const hasEggSpecimens = computed(() => Boolean(props.eggSpecimens?.options.length))
const embryoSpecimensCaption = computed(() => {
  const emrbyoSpecimensCount = calculateTotalSpecimens(props.embryoSpecimens.options)
  return `${emrbyoSpecimensCount} ${SPECIMEN_TYPE_EMBRYO}${emrbyoSpecimensCount > 1 ? 's' : ''}`
})
const eggSpecimensCaption = computed(() => {
  const eggSpecimensCount = calculateTotalSpecimens(props.eggSpecimens.options)
  return `${eggSpecimensCount} ${SPECIMEN_TYPE_OOCYTE}${eggSpecimensCount > 1 ? 's' : ''}`
})

// Emits
const emit = defineEmits(['onEmbryosInput', 'onEggsInput'])

onMounted(() => {
  eggSpecimensModel.value = props.eggSpecimens
  embryoSpecimensModel.value = props.embryoSpecimens
})

const handleEmbryosInventoryInput = (value) => {
  emit('onEmbryosInput', value)
}
const handleEggsInventoryInput = (value) => {
  emit('onEggsInput', value)
}

const calculateTotalSpecimens = (cryodevices) => {
  const totalSpecimens = cryodevices.reduce(
    (accumulator, currentCryodevice) => accumulator + currentCryodevice.specimenCount,
    0
  )
  return totalSpecimens
}

watch(
  () => props.eggSpecimens,
  (newValue) => {
    eggSpecimensModel.value = newValue
  }
)
watch(
  () => props.embryoSpecimens,
  (newValue) => {
    embryoSpecimensModel.value = newValue
  }
)
</script>
