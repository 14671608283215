<template>
  <div class="flex justify-center items-center">
    <div v-for="method in methods" :key="method" class="w-64 mx-8">
      <div class="float-left py-6">
        <button-dynamic
          btnType="button"
          btnStyle="primary"
          btnSize="large"
          :btnText="method"
          @click="onSelectProcedure(method)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  props: {
    methods: {
      type: Array,
      required: true
    },
    onSelectProcedure: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>
