import { httpRequest } from '@/helpers/apiHelpers'
import { GetProperty } from './types'

export const getPropertyApi = ({ property, siteId, clinicId }: GetProperty) => {
  // before sign-up we don't have the clinic/sites credentials yet, so we need to receive them as a param
  if (siteId && clinicId) {
    return httpRequest(false).get(`/clinics/${clinicId}/sites/${siteId}/properties/${property}`)
  }

  return httpRequest().get(`/properties/${property}`)
}

export const patchPropertyApi = ({ property, data }: any) => httpRequest().patch(`/properties/${property}`, data)
