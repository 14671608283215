<template>
  <RadioButton
    :modelValue="isSelected"
    @update:modelValue="input"
    :columnOptions="columnOptions"
  />
</template>

<script>
import RadioButton from '@/components/RadioButton/RadioButton.vue'

export default {
  name: 'scroll-table-radio-button-field',
  emits: ['valueFromCustomFieldChanged'],
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    options: {
      type: Object,
      required: false
    },
    columnOptions: {
      type: Object,
      required: false
    }
  },
  methods: {
    input() {
      this.$emit(
        'valueFromCustomFieldChanged',
        this.columnOptions.value,
        this.columnOptions,
        this.options,
        this.value
      )
    }
  },
  computed: {
    isSelected() {
      return this.value === this.columnOptions.value
    }
  },
  components: {
    RadioButton
  }
}
</script>
