export default {
  setAllSites(state, allSites) {
    state.allSites = allSites
  },
  setAllContainers(state, allContainers) {
    state.allContainers = allContainers
  },
  setAllContainersBySite(state, allContainersBySite) {
    state.allContainersBySite = allContainersBySite
  }
}
