<template>
  <TooltipTable
    :class="[{ hidden: !value.items }]"
    :headers="options.tooltipTableHeaders || []"
    :items="value.items || []"
    :alignment="options.alignment || 'bottom'"
    :textAlignment="columnOptions.alignment || 'left'"
    :displayDotsIcon="options.displayDotsIcon"
    :theme="options.theme || 'default'"
    :append-body="appendBody"
    :image="getImage || getOptionsImage"
    :disableHover="value.disableHover !== undefined ? value.disableHover : options.disableHover"
    :label="value.label || ''"
    :width="columnOptions.componentOptions.imageWidth"
    :height="columnOptions.componentOptions.imageHeight"
    :row-item="item"
  />
</template>

<script>
import TooltipTable from '@/components/TooltipTable/TooltipTable.vue'
import overdue from '@/assets/ticketState/overdue.svg'
import green from '@/assets/ticketState/green.svg'
import inProgress from '@/assets/ticketState/in-progress.svg'
import importCryoBeacon from '@/assets/images/ticketStates/import-cryo-beacon-blue.svg'
import bulkImportCryoBeacon from '@/assets/images/ticketStates/bulk-import-icon.svg'

const assets = {
  overdue,
  green,
  inProgress,
  importCryoBeacon,
  bulkImportCryoBeacon
}

export default {
  name: 'scroll-table-custom-tooltip-field',
  props: {
    value: {
      type: [Object, String],
      required: false,
      default: () => ({
        image: null,
        label: null,
        items: null
      })
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    columnOptions: {
      type: Object,
      required: false,
      default: () => {}
    },
    /**
     * Current row item being rendered
     */
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    appendBody() {
      return typeof this.options.appendBody === 'boolean' ? this.options.appendBody : true
    },
    getImage() {
      return assets[this.value.image] || ''
    },
    getOptionsImage() {
      return assets[this.options.image] || ''
    }
  },
  components: {
    TooltipTable
  }
}
</script>
