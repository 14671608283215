<template>
  <div
    class="
      w-screen
      h-screen
      flex
      items-center
      justify-center
      absolute
      top-0
      left-0
      bg-black bg-opacity-50
      z-50
    "
  >
    <div class="w-5/12 bg-white p-4 rounded-lg">
      <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue-dark" is-bold floatTo="left">{{ title }}</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            v-if="showCloseButton"
            btnText=""
            btnType="button"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="times-circle"
            floatTo="right"
            @click="closeModal"
          />
        </template>
      </ActionBar>
      <div class="p-1 leading-7">
        {{ message }}
      </div>
      <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3" class="mt-4">
        <template v-slot:left-side />
        <template v-slot:right-side>
          <button-dynamic
            v-if="showBottomButton"
            btnText="Ok"
            btnType="button"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="check-circle"
            floatTo="right"
            @click="closeModal"
          />
        </template>
      </ActionBar>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'bulk-detail',
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    showBottomButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions('modalModule', ['hideModal']),
    closeModal() {
      this.hideModal()
    }
  },
  components: {
    ButtonDynamic,
    ActionBar,
    DynamicTitle
  }
}
</script>
