export default {
  isAppModalOpen: (state) => state.isAppModalOpen,
  appInfoStatus: (state) => state.appInfoStatus,
  appVersion: (state) => state.appVersion,
  apiVersion: (state) => state.apiVersion,
  bulkImportApiStatus: (state) => state.bulkImportApiStatus,
  bulkImportApiVersion: (state) => state.bulkImportApiVersion,
  appSupportEmail: (state) => state.appSupportEmail,
  appSupportPhoneUS: (state) => state.appSupportPhoneUS,
  appSupportPhoneUK: (state) => state.appSupportPhoneUK,
  modularCryolabel: (state) => state.modularCryolabel,
  isTermsAndConditionsModalOpen: (state) => state.isTermsAndConditionsModalOpen,
  isPrivacyPolicyModalOpen: (state) => state.isPrivacyPolicyModalOpen
}
