export default {
  patients: (state) => state.patients,
  patientsFound: (state) => state.patientsFound,
  patient: (state) => state.patient,
  donatePatient: (state) => state.donatePatient,
  countryList: (state) => state.countryList,
  specimensFromPatient: (state) => state.specimensFromPatient,
  specimensClosedFromPatient: (state) => state.specimensClosedFromPatient,
  statusPatient: (state) => state.statusPatient,
  uploadId: (state) => state.uploadId,
  error: (state) => state.error,
  jobStatus: (state) => state.jobStatus,
  patientInventory: (state) => state.patientInventory,
  ticketsFromPatient: (state) => state.ticketsFromPatient,
  missingTicketsFromPatient: (state) => state.missingTicketsFromPatient,
  container: (state) => state.container,
  updatedTicketId: (state) => state.updatedTicketId,
  donorId: (state) => state.donorId,
  shippingSource: (state) => state.shippingSource,
  newClinicName: (state) => state.newClinicName,
  newClinicAddress: (state) => state.newClinicAddress,
  externalClinics: (state) => state.externalClinics,
  createdClinic: (state) => state.createdClinic,
  patientTimestamp: (state) => state.patientTimestamp
}
