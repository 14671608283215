/* eslint-disable no-useless-escape */
import printJS from 'print-js'
import ErrorHandler from '../ErrorHandler/index'

// TODO: Replace this function to use a new library that supports font-family
// To be able to work with Inconsolata on print of labels, it was required to add preconnect and preload tags on index.html
const labelToPdf = (modulePrint) => {
  try {
      const labelStyle = `

      html {
        font-size: 100%;
      }
      
      .cryo-label {
        display: none !important;
      }
      .flex {
        display: flex;
      }
      .flex-wrap {
        flex-wrap: wrap;
      }
      .m-4 {
        margin: 1rem;
      }
      .my-4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .rounded-md {
        border-radius: 0.375rem;
      }
      .rounded-3xl {
        border-radius: 1.5rem;
      }
      .p-1 {
        padding: 0.25rem;
      }
      .p-4 {
        padding: 1rem;
      }
      .px-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      .pb-1 {
        padding-bottom: 0.25rem;
      }
      .leading-5 {
        line-height: 1.25rem;
      }

      .w-\[320px\] {
        width: 320px;
      }

      .w-1\/4 {
        width: 25%;
      }
      .font-inconsolata {
        font-family: Inconsolata;
      }
      .font-semibold {
        font-weight: 600;
      }
      .w-full {
        width: 100%;
      }
      .bg-white {
        background-color: rgb(255, 255, 255)
      }
      .text-black {
        color: rgb(0, 0, 0)
      }
      .h-4 {
        height: 16px;
      }
      .h-5 {
        height: 20px;
      }
      .h-6 {
        height: 25px;
      }
      .mb-1 {
        margin-bottom: 4px;
      }
      .uppercase {
        text-transform: uppercase;
      }
      .text-sm {
        font-size: 0.875rem !important;
        line-height: 1.25rem;
      }
      .text-center {
        text-align: center;
      }
      .overflow-hidden {
        overflow: hidden;
      }
      .-mt-4 {
        margin-top: -16px;
      }
      .relative {
        position: relative;
      }
      .z-10 {
        z-index: 10;
      }
      .items-center {
        align-items: center;
      }
      .justify-center {
        justify-content: center;
      }
      .whitespace-nowrap {
        white-space: no-wrap;
      }
      .text-left {
        text-align: left;
      }
      .inline-block {
        display: inline-block;
      }

  `

      printJS({
        printable: modulePrint,
        type: 'html',
        css: ['https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap'],
        style: labelStyle,
        targetStyle: ['width'],
        targetStyles: ['width'],
        maxWidth: 320,
        font_size: '0.875rem'
      })
  } catch (error: any) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [customStack] = error.stack?.split(')')
    throw ErrorHandler('selectedTicketToPdf', `\n \n ${customStack || error})`)
  }
}

export { labelToPdf }
