import {
  METHOD_NOT_SELECTED,
  METHOD_FREEZE,
  METHOD_THAW,
  METHOD_IMPORT,
  METHOD_EXPORT,
  METHOD_DONATE,
  SPECIMEN_TYPE_EMBRYO,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  EMR_FREEZE,
  EMR_SINGLE_IMPORT,
  PROCEDURE_TYPE_BEACON_MOVE
} from '@/constants'
import {
  STEP_CALENDAR,
  STEP_DETAILS,
  STEP_FINAL,
  STEP_HOW_MANY,
  STEP_INITIAL,
  STEP_LABEL_SCAN,
  STEP_SCAN_BEACON,
  STEP_SELECT_PATIENT,
  STEP_SELECT_PROCEDURE,
  STEP_SELECT_SPECIMEN_TYPE,
  STEP_ADD_EXISTING_BEACON,
  STEP_SELECT_THAW_PROCEDURE,
  STEP_SELECT_SPECIMENS_NEW_TICKET,
  STEP_SELECT_REASON_TO_EXPORT,
  STEP_BATCH_TICKET_TYPE,
  STEP_NEW_BATCH_TICKET,
  STEP_SELECTED_TICKET
} from '@/constants/ticketSteps'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import { SpecimenType, Patient, Beacon } from '@/types'
import { beaconsFromSelectedTicketsType, setUpNewTicketDetailData } from '@/helpers/detailsHelper'
import {
  StepType,
  MethodType,
  ProcedureDate,
  SelectedCryoDeviceType,
  SingleVialReaderStatus,
  MetadataType,
  ThawEmbryoTypes
} from './types'
import {
  isFutureDate,
  resetTicketProcess,
  setBeacons,
  setCurrentTicketStep,
  setSelectedMethod,
  setSelectedCryoDeviceType,
  setSelectedPatient,
  setBatchSharedPatientIds,
  setSelectedProcedureDate,
  setSelectedSpecimenType,
  verifyUserPermission,
  setBeaconBarcodeNumber,
  setShippingSourceId,
  setDonorId,
  setMetadata,
  setSelectedTypeEmbryo,
  setSubType
} from './utils'

// Note: patients should be refactored to use a single patient and not an array of patients
type ticketDataType = {
  patient: {
    firstName: string
    lastName: string
    globalPatientNumber: number
    identificationNumber: string
    patientDob: string
  }[]
  procedureTime: string
  procedureName: string
  procedureType: string
}

interface ExecuteStep {
  currentTicketStep: StepType
  specimenType?: SpecimenType
  patients?: Patient[]
  batchSharedPatientIds?: number[][]
  procedureDate?: ProcedureDate
  selectedCryoDeviceType?: SelectedCryoDeviceType
  beacons?: Beacon[] | beaconsFromSelectedTicketsType[]
  shippingSourceId?: string
  donorId?: string
  isScanBeacon?: boolean
  beaconBarcodeNumber?: string
  metadata?: MetadataType
  selectedTypeEmbryo?: ThawEmbryoTypes
  isDonateToPatient?: boolean
  batchType?: string
  hasNextBeacon?: boolean
  ticketData?: ticketDataType
}

function executeInitialStep(): StepType {
  setCurrentTicketStep(STEP_SELECT_PROCEDURE)
  return STEP_SELECT_PROCEDURE
}

interface ExecuteGeneralStep {
  selectedMethod: MethodType
  currentTicketStep: StepType
  specimenType?: SpecimenType
  patients?: Patient[]
  procedureDate?: ProcedureDate
  beaconBarcodeNumber?: string
  metadata?: MetadataType
  beacons?: Beacon[] | beaconsFromSelectedTicketsType[]
  hasNextBeacon?: boolean
}

function executeGeneralStep({
  selectedMethod,
  currentTicketStep,
  specimenType,
  patients,
  procedureDate,
  beaconBarcodeNumber,
  metadata,
  beacons,
  hasNextBeacon
}: ExecuteGeneralStep): StepType {
  switch (currentTicketStep) {
    case STEP_INITIAL:
      setCurrentTicketStep(STEP_SELECT_PROCEDURE)
      return STEP_SELECT_PROCEDURE
    case STEP_SELECT_PROCEDURE:
      // clear session storage and vuex store
      resetTicketProcess()
      // start a new freeze process
      setCurrentTicketStep(STEP_SELECT_SPECIMEN_TYPE)
      setSelectedMethod(selectedMethod)
      return STEP_SELECT_SPECIMEN_TYPE
    case STEP_SELECT_SPECIMEN_TYPE:
      if (!specimenType) {
        return STEP_SELECT_SPECIMEN_TYPE
      }

      setCurrentTicketStep(STEP_SELECT_PATIENT)
      setSelectedSpecimenType(specimenType)
      return STEP_SELECT_PATIENT
    case STEP_SELECT_PATIENT:
      // if no patients is passed remain on the same page
      if (!patients) {
        return STEP_SELECT_PATIENT
      }

      setCurrentTicketStep(STEP_CALENDAR)
      setSelectedPatient(patients)
      return STEP_CALENDAR
    case STEP_CALENDAR:
      // if no procedureDate is passed remain on the same page
      if (!procedureDate) {
        return STEP_CALENDAR
      }

      setSelectedProcedureDate(procedureDate)

      if (isFutureDate(procedureDate)) {
        // Ensure there are no cryodevices otherwise the post will fail
        setBeacons([])
        setCurrentTicketStep(STEP_FINAL)
        return STEP_FINAL
      }

      setCurrentTicketStep(STEP_HOW_MANY)
      return STEP_HOW_MANY
    case STEP_SCAN_BEACON:
      if (!beaconBarcodeNumber) {
        return STEP_SCAN_BEACON
      }

      setBeaconBarcodeNumber(beaconBarcodeNumber)

      if (hasNextBeacon) {
        if (!beacons) {
          return STEP_SCAN_BEACON
        }
        setBeacons(beacons)
        setCurrentTicketStep(STEP_DETAILS)
        return STEP_DETAILS
      }

      setCurrentTicketStep(STEP_FINAL)
      return STEP_FINAL
    case STEP_ADD_EXISTING_BEACON:
      if (metadata) {
        setCurrentTicketStep(STEP_SCAN_BEACON)
        setMetadata(metadata)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_FINAL)
      return STEP_FINAL
    case STEP_FINAL:
      resetTicketProcess()
      return STEP_INITIAL
    default:
      return STEP_INITIAL
  }
}

function executeFreezeStep({
  currentTicketStep,
  specimenType,
  patients,
  procedureDate,
  selectedCryoDeviceType,
  beacons,
  isScanBeacon,
  beaconBarcodeNumber,
  metadata,
  hasNextBeacon
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_HOW_MANY:
      if (!selectedCryoDeviceType || !beacons) {
        return STEP_HOW_MANY
      }

      setCurrentTicketStep(STEP_DETAILS)
      setSelectedCryoDeviceType(selectedCryoDeviceType)
      setBeacons(beacons)
      return STEP_DETAILS
    case STEP_DETAILS:
      if (!beacons) {
        return STEP_DETAILS
      }
      setBeacons(beacons)

      if (isScanBeacon) {
        setCurrentTicketStep(STEP_SCAN_BEACON)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_LABEL_SCAN)
      return STEP_LABEL_SCAN
    case STEP_LABEL_SCAN:
      if (beacons) {
        setBeacons(beacons)
      }
      if (isScanBeacon) {
        setCurrentTicketStep(STEP_SCAN_BEACON)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_ADD_EXISTING_BEACON)
      return STEP_ADD_EXISTING_BEACON
    case STEP_SELECTED_TICKET:
      if (patients) setSelectedPatient(patients)
      setCurrentTicketStep(STEP_HOW_MANY)
      setSelectedMethod(METHOD_FREEZE)
      if (specimenType) setSelectedSpecimenType(specimenType)
      if (procedureDate) {
        setSelectedProcedureDate(procedureDate)
      }
      return STEP_HOW_MANY
    default:
      return executeGeneralStep({
        selectedMethod: METHOD_FREEZE,
        currentTicketStep,
        specimenType,
        patients,
        procedureDate,
        beaconBarcodeNumber,
        metadata,
        beacons,
        hasNextBeacon
      })
  }
}

function executeThawStep({
  currentTicketStep,
  specimenType,
  patients,
  procedureDate,
  beaconBarcodeNumber,
  metadata,
  selectedTypeEmbryo,
  beacons
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_SPECIMEN_TYPE:
      if (!specimenType) {
        return STEP_SELECT_SPECIMEN_TYPE
      }

      setSelectedSpecimenType(specimenType)

      if (specimenType === SPECIMEN_TYPE_EMBRYO) {
        setCurrentTicketStep(STEP_SELECT_THAW_PROCEDURE)
        return STEP_SELECT_THAW_PROCEDURE
      }

      setCurrentTicketStep(STEP_SELECT_PATIENT)
      return STEP_SELECT_PATIENT
    case STEP_SELECT_THAW_PROCEDURE:
      if (!selectedTypeEmbryo) {
        return STEP_SELECT_THAW_PROCEDURE
      }

      setCurrentTicketStep(STEP_SELECT_PATIENT)
      setSelectedTypeEmbryo(selectedTypeEmbryo)

      return STEP_SELECT_PATIENT
    case STEP_CALENDAR:
      // if no procedureDate is passed remain on the same page
      if (!procedureDate) {
        return STEP_CALENDAR
      }

      setSelectedProcedureDate(procedureDate)
      setCurrentTicketStep(STEP_SELECT_SPECIMENS_NEW_TICKET)
      return STEP_SELECT_SPECIMENS_NEW_TICKET
    case STEP_SELECT_SPECIMENS_NEW_TICKET:
      // if no beacons is passed remain on the same page
      if (!beacons) {
        return STEP_SELECT_SPECIMENS_NEW_TICKET
      }

      setBeacons(beacons)
      setCurrentTicketStep(STEP_FINAL)
      return STEP_FINAL
    default:
      return executeGeneralStep({
        selectedMethod: METHOD_THAW,
        currentTicketStep,
        specimenType,
        patients,
        procedureDate,
        beaconBarcodeNumber,
        metadata
      })
  }
}

function executeImportStep({
  currentTicketStep,
  specimenType,
  patients,
  selectedCryoDeviceType,
  beacons,
  isScanBeacon,
  shippingSourceId,
  donorId,
  beaconBarcodeNumber,
  metadata,
  hasNextBeacon
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_PATIENT:
      if (patients) setSelectedPatient(patients)
      setCurrentTicketStep(STEP_HOW_MANY)
      return STEP_HOW_MANY
    case STEP_HOW_MANY:
      if (!selectedCryoDeviceType || !beacons) {
        return STEP_HOW_MANY
      }

      setCurrentTicketStep(STEP_DETAILS)
      setSelectedCryoDeviceType(selectedCryoDeviceType)
      setBeacons(beacons)
      setShippingSourceId(shippingSourceId)
      setDonorId(donorId)
      return STEP_DETAILS
    case STEP_DETAILS:
      if (!beacons) {
        return STEP_DETAILS
      }

      setBeacons(beacons)

      if (isScanBeacon) {
        setCurrentTicketStep(STEP_SCAN_BEACON)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_ADD_EXISTING_BEACON)
      return STEP_ADD_EXISTING_BEACON
    default:
      return executeGeneralStep({
        selectedMethod: METHOD_IMPORT,
        currentTicketStep,
        specimenType,
        patients,
        beaconBarcodeNumber,
        metadata,
        beacons,
        hasNextBeacon
      })
  }
}

function executeExportStep({
  currentTicketStep,
  patients,
  beacons,
  procedureDate,
  beaconBarcodeNumber,
  metadata,
  isDonateToPatient,
  hasNextBeacon
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_PROCEDURE:
      // clear session storage and vuex store
      resetTicketProcess()
      // start a new export process
      setCurrentTicketStep(STEP_SELECT_PATIENT)
      setSelectedSpecimenType(null)
      setSelectedMethod(METHOD_EXPORT)
      return STEP_SELECT_PATIENT
    case STEP_CALENDAR:
      // if no procedureDate is passed remain on the same page
      if (!procedureDate) {
        return STEP_CALENDAR
      }

      setSelectedProcedureDate(procedureDate)
      setCurrentTicketStep(STEP_SELECT_SPECIMENS_NEW_TICKET)
      return STEP_SELECT_SPECIMENS_NEW_TICKET
    case STEP_SELECT_SPECIMENS_NEW_TICKET:
      // if no beacons is passed remain on the same page
      if (!beacons) {
        return STEP_SELECT_SPECIMENS_NEW_TICKET
      }

      setBeacons(beacons)
      setCurrentTicketStep(STEP_SELECT_REASON_TO_EXPORT)
      return STEP_SELECT_REASON_TO_EXPORT
    case STEP_SELECT_REASON_TO_EXPORT:
      // if no metadata is passed remain on the same page
      if (!metadata) {
        return STEP_SELECT_REASON_TO_EXPORT
      }

      setMetadata(metadata)

      if (isDonateToPatient) {
        setSelectedMethod(METHOD_DONATE)
        setCurrentTicketStep(STEP_SCAN_BEACON)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_FINAL)
      return STEP_FINAL
    case STEP_DETAILS:
      setCurrentTicketStep(STEP_SCAN_BEACON)
      return STEP_SCAN_BEACON
    default:
      return executeGeneralStep({
        selectedMethod: METHOD_EXPORT,
        beaconBarcodeNumber,
        currentTicketStep,
        patients,
        procedureDate,
        beacons,
        hasNextBeacon
      })
  }
}

function executeBatchStep({
  currentTicketStep,
  batchType,
  patients,
  batchSharedPatientIds,
  beacons,
  ticketData
}: ExecuteStep): StepType {
  setSelectedMethod(PROCEDURE_TYPE_BATCH_UPPERCASE)
  switch (currentTicketStep) {
    case STEP_INITIAL:
      resetTicketProcess()
      setCurrentTicketStep(STEP_BATCH_TICKET_TYPE)
      return STEP_BATCH_TICKET_TYPE
    case STEP_BATCH_TICKET_TYPE:
      if (!batchType) {
        return STEP_BATCH_TICKET_TYPE
      }

      setSubType(batchType)
      setCurrentTicketStep(STEP_NEW_BATCH_TICKET)
      return STEP_NEW_BATCH_TICKET
    case STEP_NEW_BATCH_TICKET:
      if (patients) setSelectedPatient(patients)
      if (batchSharedPatientIds) setBatchSharedPatientIds(batchSharedPatientIds)
      if (beacons) setBeacons(beacons)
      setCurrentTicketStep(STEP_FINAL)
      return STEP_FINAL
    default:
      return ticketData?.procedureType === PROCEDURE_TYPE_BEACON_MOVE
        ? STEP_BIOREPOSITORY_FLIGHTBOARD
        : STEP_INITIAL
  }
}

function executeEMRFreezeStep({
  currentTicketStep,
  selectedCryoDeviceType,
  isScanBeacon,
  beacons,
  ticketData
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_HOW_MANY:
      if (!selectedCryoDeviceType || !beacons) {
        throw new Error(
          `executeEMRFreezeStep Error -> got undefined on required values, ${
            !selectedCryoDeviceType ? 'selectedCryoDeviceType' : 'beacons'
          }`
        )
      }

      setCurrentTicketStep(STEP_DETAILS)
      setSelectedCryoDeviceType(selectedCryoDeviceType)
      setBeacons(beacons)

      // @ts-expect-error
      setUpNewTicketDetailData(ticketData)

      return STEP_DETAILS
    case STEP_DETAILS:
      if (!beacons) {
        return STEP_DETAILS
      }
      setBeacons(beacons)

      if (isScanBeacon) {
        setCurrentTicketStep(STEP_SCAN_BEACON)
        return STEP_SCAN_BEACON
      }

      setCurrentTicketStep(STEP_LABEL_SCAN)
      return STEP_LABEL_SCAN
    default:
      return STEP_INITIAL
  }
}

function executeEMRSingleImport({
  selectedCryoDeviceType,
  beacons,
  patients,
  procedureDate,
  specimenType
}: ExecuteStep): StepType {
  setCurrentTicketStep(STEP_SCAN_BEACON)
  setSelectedMethod(METHOD_IMPORT)
  if (beacons) setBeacons(beacons)
  if (patients) setSelectedPatient(patients)
  if (procedureDate) setSelectedProcedureDate(procedureDate)
  if (selectedCryoDeviceType) setSelectedCryoDeviceType(selectedCryoDeviceType)
  if (specimenType) setSelectedSpecimenType(specimenType)
  return STEP_SCAN_BEACON
}

const TicketStepsFactory = {
  [METHOD_NOT_SELECTED]: executeInitialStep,
  [METHOD_FREEZE]: executeFreezeStep,
  [METHOD_THAW]: executeThawStep,
  [METHOD_IMPORT]: executeImportStep,
  [METHOD_EXPORT]: executeExportStep,
  [METHOD_DONATE]: executeExportStep,
  [PROCEDURE_TYPE_BATCH_UPPERCASE]: executeBatchStep,
  [EMR_FREEZE]: executeEMRFreezeStep,
  [EMR_SINGLE_IMPORT]: executeEMRSingleImport
}

interface ExecuteNextTicketStepInput {
  selectedMethod: MethodType
  currentTicketStep: StepType
  specimenType?: SpecimenType
  patients?: Patient[]
  batchSharedPatientIds?: number[][]
  procedureDate?: ProcedureDate
  selectedCryoDeviceType?: SelectedCryoDeviceType
  beacons?: Beacon[] | beaconsFromSelectedTicketsType[]
  shippingSourceId?: string
  donorId?: string
  isScanBeacon?: boolean
  beaconBarcodeNumber?: string
  metadata?: MetadataType
  selectedTypeEmbryo?: ThawEmbryoTypes
  isDonateToPatient?: boolean
  batchType?: string
  hasNextBeacon?: boolean
  ticketData?: ticketDataType
}

export async function executeNextTicketStep({
  selectedMethod,
  currentTicketStep,
  specimenType,
  patients,
  batchSharedPatientIds,
  procedureDate,
  selectedCryoDeviceType,
  beacons,
  shippingSourceId,
  donorId,
  isScanBeacon,
  beaconBarcodeNumber,
  metadata,
  selectedTypeEmbryo,
  isDonateToPatient,
  batchType,
  hasNextBeacon,
  ticketData
}: ExecuteNextTicketStepInput): Promise<StepType> {
  const SVRStatus = await verifyUserPermission({ selectedMethod, currentTicketStep })

  if (SVRStatus === SingleVialReaderStatus.REGISTRATION_TRAY_NOT_DETECTED) {
    setCurrentTicketStep(STEP_INITIAL)
    return STEP_INITIAL
  }

  if (SVRStatus === SingleVialReaderStatus.CRYOBEACON_DETECTED) {
    setCurrentTicketStep(currentTicketStep || STEP_INITIAL)
    return currentTicketStep || STEP_INITIAL
  }

  const executeTicketStep = TicketStepsFactory[selectedMethod]
  const nextStepRoutePath = executeTicketStep({
    currentTicketStep,
    specimenType,
    patients,
    batchSharedPatientIds,
    procedureDate,
    selectedCryoDeviceType,
    beacons,
    shippingSourceId,
    donorId,
    isScanBeacon,
    beaconBarcodeNumber,
    metadata,
    selectedTypeEmbryo,
    isDonateToPatient,
    batchType,
    hasNextBeacon,
    ticketData
  })

  return nextStepRoutePath
}
