<template>
  <div
    data-test="customizable-modal"
    class="flex items-center justify-center fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50 z-1000"
  >
    <div class="bg-white p-6 w-5/12 rounded-md text-black">
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left">{{ title }}</dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnType="button"
            btnText=""
            btnStyle="close-button-blue"
            class="modal-cancel__close-btn"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="$attrs.onHideModal"
          />
        </template>
      </ActionBar>
      <p class="text-xl leading-6 font-medium my-8">{{ message }}</p>
      <button-dynamic
        v-for="(elem, index) in (buttonsData as any)"
        :key="index"
        :btnText="elem.btnText"
        :btnType="elem.btnType"
        :btnStyle="elem.btnStyle"
        :addClasses="[elem.classes]"
        @click="elem.action"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

export default {
  name: 'customazible-modal',
  components: {
    ButtonDynamic,
    ActionBar,
    DynamicTitle
  },
  props: {
    message: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    buttonsData: {
      type: Array,
      required: false
    }
  }
}
</script>
