<template>
  <div class="flex flex-col justify-center items-center" data-test="select-specimen-type__buttons">
    <div class="flex">
      <div :key="specimenType" v-for="specimenType in specimenTypes.types" class="float-left px-8">
        <button-dynamic
          :data-test="getButtonDataTest(specimenType)"
          :isDisabled="specimenType === SPECIMEN_TYPE_SPERM"
          btnType="button"
          btnStyle="primary"
          :btnText="specimenType"
          btnSize="large"
          @click="onHandleSelectSpecimenType(specimenType)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { SPECIMEN_TYPE_SPERM } from '@/constants/index'

export default {
  data() {
    return {
      SPECIMEN_TYPE_SPERM
    }
  },
  methods: {
    getButtonDataTest(specimenType) {
      return `select-specimen-type__button-${specimenType.toLowerCase()}`
    }
  },
  props: {
    specimenTypes: {
      type: Object,
      required: true
    },
    onHandleSelectSpecimenType: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>
