<template>
  <modal-window
    modalTitle="Create New Patient"
    modalSize="lg"
    :isModalOpen="isModalOpen"
    @closeModalWindow="closeModalWindow"
  >
    <div class="flex">
      <div class="w-1/4 pr-2 py-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="firstName"
          v-model.trim="v$.dataPatients.firstName.$model"
          :inputValue="v$.dataPatients.firstName.$model"
          :inputLabel="dataPatients.firstName ? 'First Name' : ''"
          inputPlaceholder="First Name"
          data-test="new-patient-modal__first-name"
          :hasError="checkError.firstName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.firstName.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="lastName"
          v-model.trim="v$.dataPatients.lastName.$model"
          :inputLabel="dataPatients.lastName ? 'Last Name' : ''"
          inputPlaceholder="Last Name"
          data-test="new-patient-modal__last-name"
          :hasError="checkError.lastName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.lastName.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="identificationNumber"
          v-model.trim="v$.dataPatients.identificationNumber.$model"
          :inputLabel="dataPatients.identificationNumber ? 'Identification Number' : ''"
          inputPlaceholder="Identification Number"
          data-test="new-patient-modal__identificatio-number"
          :hasError="checkError.identificationNumber"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.identificationNumber.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 flex flex-col justify-center mt-1 p-2">
        <label v-if="dataPatients.birthDate">Date of Birth</label>
        <CustomDate
          :date="v$.dataPatients.birthDate.$model"
          @onValueChange="handleBirthDateChange"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-1/4 h-24 pr-2 py-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="emailAddress"
          v-model.trim="v$.dataPatients.email.$model"
          :inputLabel="dataPatients.email ? 'Email Address' : ''"
          inputPlaceholder="Email Address"
          data-test="new-patient-modal__email-address"
          :hasError="checkError.email"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.email.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="phoneNumber"
          v-model.trim="v$.dataPatients.phone.$model"
          :inputLabel="dataPatients.phone ? 'Phone Number' : ''"
          inputPlaceholder="Phone Number"
          data-test="new-patient-modal__phone-number"
          :hasError="checkError.phone"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.phone.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="streetAddress"
          v-model.trim="v$.dataPatients.street.$model"
          :inputLabel="dataPatients.street ? 'Street Address' : ''"
          inputPlaceholder="Street Address"
          data-test="new-patient-modal__street-address"
          :hasError="checkError.street"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.street.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 pl-2 py-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="apartmentFloor"
          v-model.trim="v$.dataPatients.street2.$model"
          :inputLabel="dataPatients.street2 ? 'Apartment / Floor' : ''"
          inputPlaceholder="Apartment / Floor"
          data-test="new-patient-modal__apartment-floor"
          :hasError="checkError.street2"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.street2.$touch()"
          addPaddingBottom
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-1/4 h-24 pr-2 py-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="city"
          v-model.trim="v$.dataPatients.city.$model"
          :inputLabel="dataPatients.city ? 'City / County' : ''"
          inputPlaceholder="City / County"
          data-test="new-patient-modal__city"
          :hasError="checkError.city"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.city.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="state"
          v-model.trim="v$.dataPatients.state.$model"
          :inputLabel="dataPatients.state ? 'State' : ''"
          inputPlaceholder="State"
          data-test="new-patient-modal__state"
          :hasError="checkError.state"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.state.$touch()"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 p-2 flex items-center justify-center">
        <SelectField
          v-model.trim="v$.dataPatients.country.$model"
          :inputValue="v$.dataPatients.country.$model"
          :options="countryOptions"
          :label="''"
          :error="checkError.country"
          defaultOptionLabel="Country"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
        />
      </div>
      <div class="w-1/4 h-24 p-2 flex items-center justify-center">
        <InputField
          inputType="text"
          inputName="zip"
          v-model.trim="v$.dataPatients.zip.$model"
          :inputLabel="dataPatients.zip ? 'Zip / Post Code' : ''"
          inputPlaceholder="Zip / Post Code"
          data-test="new-patient-modal__zip"
          :hasError="checkError.zip"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          class="w-full"
          @blur="v$.dataPatients.zip.$touch()"
          addPaddingBottom
        />
      </div>
    </div>
    <div
      v-for="(error, key) in validationErrors"
      :key="key"
      class="h-8 flex justify-start items-center text-red-500"
    >
      <span
        class="bg-white rounded-full block w-4 h-4 flex justify-center items-center float-left mr-2"
      >
        <i class="fas fa-exclamation-circle text-red-500 text-sm" />
      </span>
      {{ error }}
    </div>
    <div class="h-8">
      <button-dynamic
        btnText="Create"
        btnType="button"
        btnStyle="primary"
        data-test="modal-new-patient__submit"
        :isDisabled="this.v$.$invalid"
        :isLoading="creatingUser"
        @click="handleSubmit"
      />
      <button-dynamic
        btnText="Cancel"
        btnType="button"
        btnStyle="secondary"
        @click="closeModalWindow"
        addMarginRight
      />
    </div>
  </modal-window>
</template>

<script>
import {
  required,
  email as emailValidation,
  maxLength,
  minLength,
  numeric,
  alpha
} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { mapActions, mapGetters } from 'vuex'
import { MONTH_OPTIONS } from '@/constants'
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import InputField from '@/components/InputField/InputField.vue'
import SelectField from '@/components/CustomSelect/SelectField.vue'
import CustomDate from '@/components/CustomDate/CustomDate.vue'
import toast from '@/config/toast'
import { 
  alphaSpace,
  alphaSpaceDash,
  alphaNumSpaceDash,
  alphaNumDashUnder
} from '@/helpers/formsFieldsValidation'


export default {
  components: {
    ButtonDynamic,
    ModalWindow,
    InputField,
    SelectField,
    CustomDate
  },
  name: 'modal-new-patient',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isNewPatientModalOpen: false,
      creatingUser: false,
      error: '',
      dataPatients: {
        firstName: null,
        lastName: null,
        email: null,
        identificationNumber: null,
        phone: null,
        birthDate: null,
        street: null,
        street2: null,
        city: null,
        zip: null,
        state: null,
        country: null
      },
      monthOptions: MONTH_OPTIONS
    }
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  validations: {
    dataPatients: {
      birthDate: {
        required
      },
      firstName: {
        required,
        alphaSpaceDash
      },
      lastName: {
        required,
        alphaSpaceDash
      },
      identificationNumber: {
        required,
        alphaNumDashUnder
      },
      email: {
        required,
        emailValidation
      },
      phone: {
        required,
        numeric,
        maxLength: maxLength(20),
        minLength: minLength(9)
      },
      street: {
        required
      },
      street2: {
        required: false
      },
      city: {
        alphaSpace,
        required
      },
      zip: {
        alphaNumSpaceDash,
        required: false,
        maxLength: maxLength(15)
      },
      state: {
        alphaSpace,
        required
      },
      country: {
        alpha,
        required
      }
    }
  },
  created() {
    this.fetchCountries()
  },
  computed: {
    ...mapGetters('createPatientModule', ['errorPatient', 'validationErrors']),
    ...mapGetters('patientsModule', ['countryList']),
    showModal() {
      return this.isModalOpen
    },
    checkError() {
      const requiredMessage = '{attribute} is required'
      let firstName = ''
      let lastName = ''
      let identificationNumber = ''
      let birthDate = ''
      let email = ''
      let phone = ''
      let street = ''
      let city = ''
      let zip = ''
      let state = ''
      let country = ''

      // First name validation messages
      if (this.v$.dataPatients.firstName.$error && this.v$.dataPatients.firstName.required) {
        firstName = this.v$.dataPatients.firstName.$errors[0].$message
      }

      // Last name validation messages
      if (this.v$.dataPatients.lastName.$error && this.v$.dataPatients.lastName.required) {
        lastName = this.v$.dataPatients.lastName.$errors[0].$message
      }

      // Identification Number validation messages
      if (
        this.v$.dataPatients.identificationNumber.$error
        && this.v$.dataPatients.identificationNumber.required
      ) {
        identificationNumber = this.v$.dataPatients.identificationNumber.$errors[0].$message
      }

      // Phone number validation messages
      if (this.v$.dataPatients.phone.$error && this.v$.dataPatients.phone.required) {
        phone = this.v$.dataPatients.phone.$errors[0].$message
      }

      // Email validation messages
      if (this.v$.dataPatients.email.$error && this.v$.dataPatients.email.required) {
        email = requiredMessage.replace('{attribute}', 'Email')
      }
      if (this.v$.dataPatients.email.$error && this.v$.dataPatients.email.email) {
        email = 'Invalid email'
      }

      if (this.v$.dataPatients.street.$error && this.v$.dataPatients.street.required) {
        street = this.v$.dataPatients.street.$errors[0].$message
      }
      if (this.v$.dataPatients.city.$error && this.v$.dataPatients.city.required) {
        city = this.v$.dataPatients.city.$errors[0].$message
      }
      if (this.v$.dataPatients.zip.$error && this.v$.dataPatients.zip.required) {
        zip = this.v$.dataPatients.zip.$errors[0].$message
      }
      if (this.v$.dataPatients.state.$error && this.v$.dataPatients.state.required) {
        state = this.v$.dataPatients.state.$errors[0].$message
      }
      if (this.v$.dataPatients.country.$error && this.v$.dataPatients.country.required) {
        country = this.v$.dataPatients.country.$errors[0].$message
      }

      /**
       * Birth Date validations
       */
      // Required
      if (this.v$.dataPatients.birthDate.$error && this.v$.dataPatients.birthDate.required) {
        birthDate = requiredMessage.replace('{attribute}', 'Birth Date')
      }
      
      return {
        firstName,
        lastName,
        identificationNumber,
        birthDate,
        email,
        phone,
        street,
        city,
        zip,
        state,
        country
      }
    },
    countryOptions() {
      return this.countryList.map(({ iso, name }) => ({ value: iso, label: name }))
    }
  },
  emits: ['closeModalWindow', 'fetchPatients'],
  methods: {
    ...mapActions('patientsModule', ['searchPatients', 'fetchCountries', 'setPatientTimestamp']),
    ...mapActions('createPatientModule', ['setValidationErrors', 'createPatient']),
    closeModalWindow() {
      this.beforeClose()
      this.$emit('closeModalWindow')
    },
    async handleSubmit() {
      this.creatingUser = true
      this.setValidationErrors([])
      const {
        firstName,
        lastName,
        identificationNumber,
        email,
        phone,
        street,
        street2,
        city,
        zip,
        state,
        country,
        birthDate
      } = this.dataPatients
      const data = {
        email,
        firstName,
        lastName,
        phone,
        identificationNumber,
        birthDate,
        address: {
          street,
          street2,
          city,
          zip,
          state,
          country
        }
      }

      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      try {
        await this.createPatient(data)
        this.setPatientTimestamp(null)
        toast.success('Patient Created Successfully!')
        this.creatingUser = false
        this.beforeClose()
        this.$emit('fetchPatients', 'identificationNumber', identificationNumber)
        this.$emit('closeModalWindow')
      } catch (error) {
        this.validationErrors.forEach((errorMessage) => {
          toast.custom('error', 'error', errorMessage)
        })
        this.creatingUser = false
      }
    },
    beforeClose() {
      this.dataPatients.firstName = null
      this.dataPatients.lastName = null
      this.dataPatients.birthDate = null
      this.setValidationErrors([])
      this.dataPatients.identificationNumber = null
      this.dataPatients.email = null
      this.dataPatients.phone = null
      this.dataPatients.street = null
      this.dataPatients.street2 = null
      this.dataPatients.city = null
      this.dataPatients.zip = null
      this.dataPatients.state = null
      this.dataPatients.country = null
      setTimeout(() => {
        this.v$.$reset()
      }, 0)
    },
    handleBirthDateChange(newDate) {
      this.dataPatients.birthDate = newDate
    }
  }
}
</script>
