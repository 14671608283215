import { computed } from 'vue'

const useUnsupportedBrowser = () => {
  const isBrowserSupported = computed(() => {
    const { userAgent } = navigator
    let isBrowserSupported = true
    const unsupportedBrowsersRegExps = [
      /(Edg)\/(\d+)\.(\d+)(?:\.(\d+))/i, // EDGE
      /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//i, // SAFARI
      /(Firefox)\/(\d+)\.(\d+)/i, // FIREFOX
      /(?:Chrome).*(OPR)\/(\d+)\.(\d+)\.(\d+)/i // OPERA
    ]
    unsupportedBrowsersRegExps.forEach((regExp) => {
      const testBrowserSupport = regExp.test(userAgent)
      if (testBrowserSupport) {
        isBrowserSupported = false
      }
    })
    return isBrowserSupported
  })
  return { isBrowserSupported }
}

export default useUnsupportedBrowser
