<template>
  <MainContainer gridSlots="2" data-test="select-specimen-type-view">
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <select-specimen-type-buttons
      :specimenTypes="specimenTypes"
      :onHandleSelectSpecimenType="handleSelectSpecimenType"
    />
  </MainContainer>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_SPERM,
  SPECIMEN_TYPE_EMBRYO,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  SPECIMEN_TYPE_EGGS,
  SPECIMEN_TYPE_EGG
} from '@/constants'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import SelectSpecimenTypeButtons from '@/components/CreateTicket/SelectSpecimenTypeButtons/SelectSpecimenTypeButtons.vue'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'

export default {
  name: 'select-specimen-type',
  data() {
    return {
      specimenTypes: {
        types: [SPECIMEN_TYPE_EMBRYO, SPECIMEN_TYPE_OOCYTE, SPECIMEN_TYPE_SPERM]
      }
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod'])
  },
  methods: {
    ...mapMutations('ticketModule', ['setSpecimenType', 'setSelectedSpecimenType']),
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })
      this.$router.replace({ name: previousStepPath })
    },
    async handleSelectSpecimenType(specimenType) {
      this.setSelectedSpecimenType(specimenType)
      this.setSpecimenType(this.getSpecimenType(specimenType))

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        specimenType
      })

      this.$router.push({ name: nextStepPath })
    },
    getSpecimenType(specimenType) {
      if (!specimenType || this.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE) return null

      if (specimenType === SPECIMEN_TYPE_EGGS || specimenType === SPECIMEN_TYPE_OOCYTE) {
        return SPECIMEN_TYPE_EGG
      }

      return specimenType.toUpperCase()
    }
  },
  components: {
    TopHeader,
    HeaderInfo,
    SelectSpecimenTypeButtons,
    MainContainer
  }
}
</script>
