<template>
  <MainContainer gridSlots="2" data-test="select-procedure-view">
    <TopHeader @backStep="handleClickBack">
      Create a New Ticket
    </TopHeader>
    <SelectProcedureButtons
      :ticketMethods="ticketMethods"
      :onSelectProcedure="handleSelectProcedure"
    />
  </MainContainer>
</template>
<script setup>
import useMutations from '@/composables/useMutations'
import useGetters from '@/composables/useGetters'

import {
  METHOD_NOT_SELECTED,
  METHOD_FREEZE,
  METHOD_THAW,
  METHOD_IMPORT,
  METHOD_EXPORT,
  SINGLE_IMPORT_CONST
} from '@/constants'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import SelectProcedureButtons from '@/components/CreateTicket/SelectProcedureButtons/SelectProcedureButtons.vue'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { useRouter } from 'vue-router'
import { computed, onMounted, inject } from 'vue'

const { currentTicketStep } = useGetters('newTicketModule')
const { appBaseConfig, loggedUserInfo } = useGetters('authModule')

const router = useRouter()

const isSiteEmrEnabled = inject('isSiteEmrEnabled')

const {
  setSelectedTicketMethod,
  setUserId,
  setCollectionProtocolId,
  setProcedureType,
  clearTicketState
} = useMutations('ticketModule')

const ticketMethods = computed(() => {
  return {
    store: {
      methods: [METHOD_FREEZE, METHOD_IMPORT],
      allowed: isSiteEmrEnabled?.allowCreateStoreTickets
    },
    retrieve: {
      methods: [METHOD_THAW, METHOD_EXPORT],
      allowed: isSiteEmrEnabled?.allowCreateRetrieveTickets
    }
  }
})

const handleClickBack = () => {
  const previousStepPath = executePreviousTicketStep({
    selectedMethod: METHOD_NOT_SELECTED,
    currentTicketStep: currentTicketStep.value
  })

  router.replace({ name: previousStepPath })
}

const getProcedureType = (selectedMethod) => {
  return selectedMethod === METHOD_IMPORT ? SINGLE_IMPORT_CONST : selectedMethod.toUpperCase()
}

const handleSelectProcedure = async (method) => {
  setSelectedTicketMethod(method)
  setProcedureType(getProcedureType(method))

  const nextStepPath = await executeNextTicketStep({
    selectedMethod: method,
    currentTicketStep: currentTicketStep.value
  })

  router.push({ name: nextStepPath })
}

onMounted(() => {
  // TODO: this won't work for all cases (for example EMR), cause this step is skipped
  // but maybe it won't matter since when updating the ticket we just overwrite the state?
  clearTicketState()

  const { userId } = loggedUserInfo.value
  const { collectionProtocolId } = appBaseConfig.value
  setUserId(userId)
  setCollectionProtocolId(collectionProtocolId)
})

</script>
