import dayjs from 'dayjs'
import { Specimen, SourcesValueItems } from '@/types/specimen'


export function buildImportedSpecimenToolTipTableData(specimen: Specimen): Array<SourcesValueItems> {
  if (specimen?.sources?.length) {
    return [{
      imported: specimen.collectionDate ? dayjs(Number(specimen.collectionDate)).format('DDMMMYYYY hh:mmA').toUpperCase() : '--',
      currentLabelId: specimen.currentLabelId ? specimen.currentLabelId : '--'
    }]
  }
  return [{
    imported: '--',
    currentLabelId: '--'
  }]
}

interface SourceValueObject {
  image: string,
  items: Array<SourcesValueItems>
}
export function buildSpecimenSourcesValueObject(specimen: Specimen): SourceValueObject {
  const tooltipTableData = buildImportedSpecimenToolTipTableData(specimen)
  const importedSpecimenIconName = specimen?.sources?.length ? 'importCryoBeacon' : ''
  return { image: importedSpecimenIconName, items: tooltipTableData }
}
