<template>
  <section
    :data-test="dataTest"
    class="modal-card absolute top-0 left-0 w-full h-full justify-center items-center flex z-50"
    @click="onClickModalCard"
    v-if="open">
    <div :class="[...containerClasses, 'modal-card__content', 'bg-white p-10 shadow-md']">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'modal-card',
  emits: ['onClickModalCard'],
  props: {
    open: {
      type: Boolean
    },
    containerClasses: {
      type: Array,
      default: () => []
    },
    dataTest: {
      type: String,
      default: 'modal-card'
    }
  },
  methods: {
    onClickModalCard() {
      this.$emit('onClickModalCard')
    }
  }
}
</script>

<style lang="scss">
  .modal-card {
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
