import dayjs from 'dayjs'
import {
  CRYODEVICES,
  DATE_OF_BIRTH,
  PATIENT_ID,
  PATIENT_NAME,
  SCAN_BEACON_TITLE,
  SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYOS,
  SPECIMEN_TYPE_OOCYTES,
  SOURCE,
  DESTINATION,
  LAYOUT_WAVE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_CRYOSHIPPER
} from '..'
import { changeCryoShipperToPascal, reversePatientName } from '@/helpers/flightBoardHelpers'
import { TCryodevicesResponse } from '@/types/massCreate'
import { getCryoBeaconLocationByType } from '@/helpers/cryoBeaconLocation'
import dateAndTime from '@/helpers/dateAndTime'
import {
  sortByNonTmrwLocation,
  sortByRobotLocations,
  sortByPatientName
} from '@/helpers/ticketState'
import { buildCryoShipperRobotLocations } from '@/helpers/biorepository'

const VIEW_COLUMN = {
  name: '',
  key: 'iconView',
  component: 'iconButton',
  componentOptions: {
    icon: 'fa fa-eye',
    iconColor: 'tmrw-blue-dark',
    tooltip: 'View'
  },
  content: (item: any) => item
}

const PATIENT_NAME_COLUMN = {
  name: PATIENT_NAME,
  key: 'patient',
  sort: true,
  sortFn: sortByPatientName(),
  filters: [(value) => reversePatientName(value)]
}

const PATIENT_ID_COLUMN = {
  name: PATIENT_ID,
  key: 'identificationNumber',
  sort: true
}

const PATIENT_DOB_COLUMN = {
  name: DATE_OF_BIRTH,
  key: 'patientDob',
  sort: true,
  filters: [
    (value: string) => dayjs(value).format('DDMMMYYYY'),
    (value: string) => value.toUpperCase()
  ]
}

const PROCEDURE_DATE_COLUMN = {
  name: 'Date and Time',
  key: 'procedureTime',
  cssClasses: ['w-64'],
  sort: true,
  filters: [
    (value: string) =>
      value ? dateAndTime.toLocalTimeZone({ procedureTime: value }).toUpperCase() : 'ERROR'
  ]
}

const TICKET_NUMBER_COLUMN = {
  name: 'Ticket #',
  key: 'ticketId',
  component: 'ticketNumber',
  cssClasses: ['w-32'],
  sort: true,
  componentOptions: {
    withScreeningStatusIcon: true
  }
}

const ROBOT_LOCATION_ORIGIN_COLUMN = {
  name: 'Origin',
  key: 'robotLocations',
  sortFn: sortByRobotLocations('Source'),
  content: (item: any) => {
    return item.robotLocations
  },
  component: 'robotLocationByType',
  alignment: 'right',
  cssClassesIcon: ['float-right', 'ml-2', 'rotate-[181deg]'],
  iconColumn: true,
  rightIcon: true,
  sort: true,
  componentOptions: {
    locationType: SOURCE
  }
}
const ROBOT_LOCATION_DESTINATION_COLUMN = {
  name: 'Destination',
  key: 'destination',
  content: (item: any) => {
    return item.robotLocations
  },
  sortFn: sortByRobotLocations('Destination'),
  component: 'robotLocationByType',
  sort: true,
  leftIcon: true,
  componentOptions: {
    locationType: DESTINATION
  }
}

export const tableCryobeaconRelocations = [
  {
    ...PROCEDURE_DATE_COLUMN,
    forceEnabled: true
  },
  {
    ...PATIENT_NAME_COLUMN,
    forceEnabled: true
  },
  {
    ...PATIENT_ID_COLUMN,
    forceEnabled: true
  },
  {
    ...PATIENT_DOB_COLUMN,
    forceEnabled: true
  },
  {
    ...TICKET_NUMBER_COLUMN,
    unique: true,
    forceEnabled: true
  },
  {
    ...ROBOT_LOCATION_ORIGIN_COLUMN,
    forceEnabled: true
  },
  {
    ...ROBOT_LOCATION_DESTINATION_COLUMN,
    forceEnabled: true
  },
  {
    name: 'CryoBeacon ID',
    key: 'beaconBarcode',
    component: 'cryobeaconId',
    cssClasses: ['w-72'],
    componentOptions: {
      showLabel: false
    },
    sort: true,
    forceEnabled: true
  }
]

export const tableAddCryobeaconsRelocation = [
  VIEW_COLUMN,
  {
    name: SCAN_BEACON_TITLE,
    key: 'cryobeaconBarcode',
    sort: true,
    unique: true
  },
  PATIENT_NAME_COLUMN,
  {
    ...PATIENT_ID_COLUMN,
    unique: true
  },
  PATIENT_DOB_COLUMN,
  {
    name: 'Screening Status',
    key: SCREENING_STATUS,
    sort: true,
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'tmrw-blue-dark',
      iconColor: 'tmrw-blue-dark'
    }
  },
  {
    name: 'Origin',
    sort: true,
    key: 'robotName'
  },
  {
    name: 'Contents in CryoBeacon',
    key: 'cryodevices',
    cssClasses: ['w-96'],
    filters: [
      (value: TCryodevicesResponse) =>
        `${value.embryoCount} ${SPECIMEN_TYPE_EMBRYOS}, ${
          value.oocyteCount
        } ${SPECIMEN_TYPE_OOCYTES} (${value.total} ${CRYODEVICES.toLowerCase()})` || '--'
    ]
  }
]

const SPACE_COL = {
  name: '',
  cssClasses: ['w-96'],
  content: () => ' '
}
export const tableAddRelocationTickets = [
  VIEW_COLUMN,
  PROCEDURE_DATE_COLUMN,
  PATIENT_NAME_COLUMN,
  PATIENT_ID_COLUMN,
  PATIENT_DOB_COLUMN,
  {
    ...TICKET_NUMBER_COLUMN,
    unique: true
  },
  {
    name: 'Origin',
    sort: true,
    sortFn: sortByRobotLocations('Source'),
    content: (item: any) => {
      return item.robotLocations
    },
    filters: [
      (value) => {
        return getCryoBeaconLocationByType(value, SOURCE)?.robotName
      }
    ]
  },
  SPACE_COL
]

export const tableCryoShipperDetails = [
  {
    ...VIEW_COLUMN,
    componentOptions: {
      ...VIEW_COLUMN.componentOptions,
      float: 'left'
    }
  },
  PROCEDURE_DATE_COLUMN,
  PATIENT_NAME_COLUMN,
  PATIENT_ID_COLUMN,
  PATIENT_DOB_COLUMN,
  {
    ...TICKET_NUMBER_COLUMN,
    unique: true
  },
  {
    ...ROBOT_LOCATION_ORIGIN_COLUMN,
    forceEnabled: true
  },
  {
    ...ROBOT_LOCATION_DESTINATION_COLUMN,
    forceEnabled: true
  },
  {
    name: 'Non-TMRW Location Notes',
    content: (item: any) => {
      return item
    },
    cssClasses: ['w-32'],
    sort: true,
    sortFn: sortByNonTmrwLocation(),
    noBatchStyles: true,
    filters: [
      (value) => {
        const { robotLocations, procedure } = value
        if (procedure === PROCEDURE_TYPE_BATCH_UPPERCASE) {
          return '&nbsp;'
        }
        const location = getCryoBeaconLocationByType(robotLocations, SOURCE)
        return location?.robotLayoutTypeId === LAYOUT_WAVE ? location?.locationDetails : '&nbsp;'
      }
    ]
  }
]

export const relocationFlightboardHeaders = [
  {
    name: 'Id',
    key: 'ticketId',
    visible: false,
    unique: true
  },
  {
    ...PROCEDURE_DATE_COLUMN,
    filters: [(value) => dateAndTime.toLocalTimeZone({ procedureTime: value })]
  },
  {
    ...PATIENT_NAME_COLUMN,
    cssClasses: ['w-40']
  },
  {
    ...PATIENT_ID_COLUMN,
    cssClasses: ['w-48']
  },
  {
    ...PATIENT_DOB_COLUMN,
    cssClasses: ['w-40']
  },
  {
    ...TICKET_NUMBER_COLUMN
  },
  {
    name: 'Origin',
    key: 'robotLocations',
    component: 'robotLocationByType',
    alignment: 'right',
    rightIcon: true,

    content: (item) => {
      if (item?.procedureType === PROCEDURE_TYPE_CRYOSHIPPER) {
        return buildCryoShipperRobotLocations(item)
      }
      return item?.robotLocations
    },
    componentOptions: {
      locationType: SOURCE
    }
  },
  {
    name: 'Destination',
    key: 'robotLocations',
    component: 'robotLocationByType',
    leftIcon: true,
    content: (item) => {
      if (item?.procedureType === PROCEDURE_TYPE_CRYOSHIPPER) {
        return buildCryoShipperRobotLocations(item)
      }
      return item?.robotLocations
    },
    componentOptions: {
      locationType: DESTINATION
    }
  },
  {
    name: 'Procedure',
    key: 'procedureName',
    cssClasses: ['w-40'],
    filters: [(value) => changeCryoShipperToPascal(value)]
  },
  {
    name: 'Status',
    key: 'statusImage',
    component: 'relocationTicketStatus',
    cssClasses: ['w-64'],
    componentOptions: {
      showLabel: false
    },
    sort: true
  }
]
