export default {
  selectedTickets: [[]],
  ticketDetail: {
    beaconBarcode: '',
    specimens: []
  },
  selectedViewMode: ['normal'],
  viewTicket: [
    {
      tickettype: 'general',
      ticketId: null
    }
  ],
  error: null,
  status: 'completed'
}
