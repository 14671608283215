<template>
  <img
    class="w-6"
    :data-test="`image-element-${imageData}`"
    :src="imgSrc"
    :alt="altText"
    :height="height"
    :style="{ width }"
  />
</template>

<script>
export default {
  name: 'image-element',
  data() {
    return {
      imgSrc: ''
    }
  },
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: 'auto',
      required: false
    },
    height: {
      type: String,
      default: 'auto',
      required: false
    }
  },
  computed: {
    imageData() {
      return this.imageSrc
    }
  },
  mounted() {
    this.getImageSrc(this.imageSrc)
  },
  watch: {
    imageSrc(newImg) {
      this.getImageSrc(newImg)
    }
  },
  methods: {
    async getImageSrc(img) {
      const fileArr = img.split('.')
      const file = fileArr.slice(0, -1).join('.')
      let filename = ''
      switch (fileArr[fileArr.length - 1]) {
        case 'png':
          filename = await import(`../../assets/images/${file}.png`)
          break
        case 'svg':
          filename = await import(`../../assets/images/${file}.svg`)
          break
        default:
          break
      }
      this.imgSrc = filename.default
    }
  }
}
</script>
