<template>
  <MainContainer data-test="users-form" gridSlots="3">
    <top-header :back-btn="false">You're creating a new User</top-header>
    <ActionBar data-test="users-form__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">Create New User</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          addMarginRight
          @click="cancelUserCreation"
        />
        <button-dynamic
          btnText="Create"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle"
          :isLoading="loadingForm"
          :isDisabled="v$.$invalid"
          @click="createNewUser"
        />
      </template>
    </ActionBar>
    <div>
      <div
        class="rounded-md p-4 bg-white grid gap-4 grid-cols-4"
        data-test="users-form__fields-info"
      >
        <InputField
          v-model.trim="v$.form.loginName.$model"
          :inputValue="v$.form.loginName.$model"
          inputLabel="Login Name"
          inputName="Login Name"
          :hasError="formErrors && formErrorMsg.loginName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
          maxLength="25"
        />
        <InputField
          v-model.trim="v$.form.firstName.$model"
          :inputValue="v$.form.firstName.$model"
          inputLabel="First Name"
          inputName="First Name"
          :hasError="formErrors && formErrorMsg.firstName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
        />
        <InputField
          v-model.trim="v$.form.lastName.$model"
          :inputValue="v$.form.lastName.$model"
          inputLabel="Last Name"
          inputName="Last Name"
          :hasError="formErrors && formErrorMsg.lastName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
        />
        <InputField
          v-model.trim="v$.form.emailAddress.$model"
          :inputValue="v$.form.emailAddress.$model"
          inputLabel="Email Address"
          inputName="Email Address"
          :hasError="formErrors && formErrorMsg.emailAddress"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
        />
        <SelectField
          v-model="v$.form.userRoleId.$model"
          :options="rolesOptions"
          label="Role"
          :error="formErrors && formErrorMsg.userRoleId"
          addPaddingTop
          addPaddingBottom
        />
        <InputField
          v-model.trim="v$.form.clinicName.$model"
          :inputValue="v$.form.clinicName.$model"
          inputLabel="Clinic"
          inputName="Clinic"
          isDisabled
          :hasError="formErrors && formErrorMsg.clinicName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
        />
        <InputField
          v-model.trim="v$.form.primarySite.$model"
          :inputValue="v$.form.primarySite.$model"
          inputLabel="Site"
          inputName="Site"
          isDisabled
          :hasError="formErrors && formErrorMsg.primarySite"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingTop
          addPaddingBottom
        />
      </div>
      <div class="w-full flex flex-row mt-4">
        <div data-test="automated-alerts-section" class="w-2/3 pr-4">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Automated Alerts</dynamic-title>
          <div class="bg-white rounded-md p-4 grid grid-cols-2 grid-rows-3 mt-4">
            <div :key="key" v-for="(field, key) in alerts">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @changeValue="updateEmailAlertValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
        <div class="w-1/3 pl-4" data-test="reporting-section">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Reporting</dynamic-title>
          <div class="bg-white rounded-md p-4 grid grid-cols-1 mt-4">
            <div :key="key" v-for="(field, key) in reports">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @changeValue="updateEmailAlertValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import { required, email, alphaNum } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import toast from '@/config/toast'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import InputField from '@/components/InputField/InputField.vue'
import SelectField from '@/components/CustomSelect/SelectField.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import OnOffToggle from '@/components/OnOffToggle/OnOffToggle.vue'
import { USER_TYPES, USER_CREATED_EMAIL_SENT, USER_ALERTS_SET } from '@/constants/index'
import { validateFields } from '@/helpers/formsFieldsValidation/index'
import { useAlertsComputed } from '../alerts-computed'
import { CreateUserBody } from '@/services/user/types'
import { computed, onMounted, reactive, ref } from 'vue'
import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'
import { useRouter } from 'vue-router'

import { UsersModule } from '@/store/modules/users'

import * as ss from '@/config/session-storage-help'


export interface ICreateUser {
  loadingData: boolean
}

const router = useRouter()

const loadingForm = ref(false)
const loginNameError = ref('')
const emailAddressError = ref('')
const userId = ref<number | null>(null)
const state = reactive({
  form: {
    loginName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    userRoleId: '',
    clinicName: '',
    primarySite: ''
  }
})
const formErrorMsg = ref({
  loginName: null,
  firstName: null,
  lastName: null,
  emailAddress: null,
  userRoleId: null,
  clinicName: null,
  primarySite: null
})
const rules = computed(() => ({
  form: {
    loginName: {
      required,
      alphaNum
    },
    firstName: {
      required,
      lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
    },
    lastName: {
      required,
      lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
    },
    emailAddress: {
      required,
      email,
      acceptsOnlyLowerCaseLetters: (value) => !/([A-Z]+)([a-z]+)?\b/gm.test(value)
    },
    userRoleId: {
      required,
      lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
    },
    clinicName: {
      required,
      lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _()]+$/i.test(value)
    },
    primarySite: {
      required,
      alphaNumericHyphenBlankSpaces: (value) => /^[a-zA-Z0-9 \-_()]+$/i.test(value)
    }
  }
}))
const v$ = useVuelidate(rules, state)

const { reports, alerts, userEmailAlertsFields } = useAlertsComputed()
const { roles } = useGetters<UsersModule['getters']>('usersModule')

// TODO: add type definition to authModule
const { appBaseConfig } = useGetters('authModule')

const rolesOptions = computed(() => {
  return roles.value.map(({ id, name }) => ({ value: id, label: name }))
})

const formErrors = computed(() => {
  const requiredFields = [
    'loginName',
    'firstName',
    'lastName',
    'emailAddress',
    'userRoleId',
    'clinicName',
    'primarySite'
  ]
  const { form } = v$.value
  return validateFields(form, requiredFields, formErrorMsg.value)
})

const { createUser, setUserEmailAlerts, fetchUserRoles } = useActions<UsersModule['actions']>('usersModule')


const cancelUserCreation = () => {
      router.push('/users-list')
}
const createNewUser = async () => {
      loadingForm.value = true
      loginNameError.value = ''
      emailAddressError.value = ''
      const { userRoleId, ...userForm } = state.form
      const parsedUserRoleId = Number(userRoleId)
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      const data: CreateUserBody = {
        ...userForm,
        userRoles: [{ id: parsedUserRoleId }],
        type: USER_TYPES[parsedUserRoleId]
      }

      try {
        const { id } = await createUser([data])
        userId.value = id
        ss.setFieldSessionStorage('selectedUser', id)
        toast.success(USER_CREATED_EMAIL_SENT)
        router.push('/user/details')
      } catch (error) {
        toast.error({
          title:
            'Error creating new user. Please verify the User Name and Email for the new account and try again.'
        })
      } finally {
        loadingForm.value = false
      }

      try {
        if (!userId.value) {
          throw new Error("Missing required value UserId.")
        }
        await setUserEmailAlerts([userId.value, userEmailAlertsFields.value])
        toast.success(USER_ALERTS_SET)
      } catch (error) {
        toast.error({ title: 'Error setting new user Email Alerts.' })
      } finally {
        loadingForm.value = false
      }
    }
const updateEmailAlertValue = (value) => {
  userEmailAlertsFields.value = userEmailAlertsFields.value.map((el) => ({
    ...el,
    enabled: el.id === value ? !el.enabled : el.enabled
  }))
}

onMounted(async () => {
  state.form = {
    ...state.form,
    clinicName: appBaseConfig.value.clinicName,
    primarySite: appBaseConfig.value.siteName
  }
  await fetchUserRoles()
})

</script>
