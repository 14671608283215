<template>
  <div v-if="shouldShowTooltip" class="tooltip-table" data-test="table-tooltip-container">
    <Tooltip :placement="alignment" :visible="visible" tooltip-class="p-0" :parent="tooltipParent">
      <template #content>
        <div
          v-if="items && items.length"
          ref="tooltipContainer"
          data-test="table-tooltip-content"
          class="
            tooltip-table-container
          "
        >
          <table class="tooltip-table-container-tooltip border-separate max-w-full w-full border-spacing-x-1.5 border-spacing">
            <tr v-if="displayHeaders">
              <td
                v-for="(head, key) in headers"
                :key="key"
                class="text-left text-white text-xs font-bold pt-1 font-exo"
              >
                {{ head.title }}
              </td>
            </tr>
            <tr v-for="(item, keyRow) in items" :key="keyRow">
              <th
                v-for="(head, key) in headers"
                class="text-white whitespace-pre-wrap text-2xl py-2 font-inconsolata font-normal"
                :class="{
                  'border-transparent border-t border-solid text-left': !displayHeaders,
                  'border-white border-t border-solid text-left': displayHeaders,
                }"
                :key="key"
              >
                <span>{{ item[head.key] }}</span>
              </th>
            </tr>
          </table>
        </div>
      </template>
      <div
        ref="tooltipItems"
        data-test="tooltip-table-label"
        class="md:truncate sm:whitespace-pre-line cursor-pointer pt-3 pb-2 inline-block"
        :class="`text-${textAlignment || 'left'}`"
        @mouseover="handleMoreInfoOver"
        @mouseleave="handleMoreInfoLeave"
      >
        <i v-if="faIcon" :class="`${faIcon}`" />
        <img
          v-if="image"
          class="my-0 mx-auto min-w-5"
          :class="[`${width} ${height}`, { 'filter-white': rowItem?.selected && !rowItem.disabled }]"
          :src="image"
          :alt="getMaxLengthLabel"
          :title="getMaxLengthLabel"
        />
        {{ getMaxLengthLabel }}
      </div>
    </Tooltip>
  </div>
</template>

<script setup>
import Tooltip from '../TooltipComponent/Tooltip.vue'
import { trimString } from '@/utils'
import { computed, ref } from 'vue'

const props = defineProps({
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    labelValue: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: String,
      required: false,
      default: null
    },
    alignment: {
      type: String,
      required: false,
      default: 'left',
      validator: (value) => ['left', 'right', 'bottom'].includes(value)
    },
    textAlignment: {
      type: String,
      required: false,
      default: 'left',
      validator: (value) => ['left', 'right', 'center'].includes(value)
    },
    displayDotsIcon: {
      type: Boolean,
      default: true
    },
    pullIcon: {
      type: String,
      default: 'left'
    },
    displayHeaders: {
      type: Boolean,
      default: true
    },
    appendBody: {
      type: Boolean,
      default: true
    },
    disableHover: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'w-4'
    },
    height: {
      type: String,
      default: 'h-4'
    },
    rowItem: {
      type: Object,
      default: () => {}
    },
    faIcon: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: 255
    }
  })

  // Data

  const visible = ref(false)

  // Computed

  const itsNotEmpty = computed(() => {
    return props.items
      .map((item) => {
        const fields = Object.keys(item)
        const values = fields.map((field) => item[field]).filter(Boolean)
        return values.length
      })
      .filter(Boolean).length
  })
  const shouldShowTooltip = computed(() => {
    return props.image || props.labelValue || props.faIcon
  })

  const getMaxLengthLabel = computed(() => {
    return trimString(props.labelValue,props.maxLength, 'end', 'start')
  })

  const tooltipParent = computed(() => {
    return props.appendBody ? 'body' : null
  })

  // Methods

  const handleMoreInfoOver = () => {
    if (!itsNotEmpty.value || props.disableHover) {
      return
    }
    visible.value = true
  }
  const handleMoreInfoLeave = ()  => {
    if (!itsNotEmpty.value || props.disableHover) {
      return
    }
    visible.value = false
  }

</script>

<style lang="scss" scoped>
.tooltip-table {
  &-container {
    @apply z-50 leading-tight py-0 whitespace-nowrap;
    max-width: 350px;
    word-break: break-word;
    &-tooltip {
      // There doesn't seem to be a tailwind utility for border-spacing
      border-spacing: 22px 8px;
    }
  }
}
</style>
