import dayjs from 'dayjs'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import toast from '@/config/toast'
import {
  ADD_SHARED_PATIENT_ERROR,
  REMOVE_PATIENT_FROM_SPECIMEN_ERROR
} from '@/constants'

import {
  addSharedPatientsApi,
  deleteSharedPatientApi,
  getSpecimensByPatientIdsApi,
  getSpecimensEventsApi,
  getSpecimenDetailsApi,
  getEmbryoTypesApi,
  getCryodeviceTypesApi,
  getAuditLogApi
} from '@/services/specimens'

import { getTicketsByBeaconsApi } from '@/services/tickets'

dayjs.extend(utc)
dayjs.extend(timezone)

export const fetchSpecimens = async (
  { commit },
  { fromSiteId, onlyInClinic, patientIds, specimenType, onlyInRobot }
) => {
  commit('setStatus', 'loading')
  try {
    const response = await getSpecimensByPatientIdsApi({
      fromSiteId,
      onlyInClinic,
      patientIds,
      specimenType,
      onlyInRobot
    })
    const { data } = response
    commit('saveSpecimens', data)
    commit('setStatus', 'ok')
    return data
  } catch (error) {
    commit('setStatus', 'error')
    toast.error({ title: error.message })
    throw new Error(error)
  }
}

export const resetSpecimens = async ({ commit }) => {
  commit('saveSpecimens', [])
}

export const fetchSpecimensEvents = async ({ commit, rootState }, params) => {
  const {
    appBaseConfig: { collectionProtocolId }
  } = rootState.authModule
  const [specimenId, patientId, isBiorepo] = params
  const clientTimeZone = dayjs.tz.guess() || 'America/New_York'
  commit('setStatus', 'loading')
  try {
    const response = await getSpecimensEventsApi({
      collectionProtocolId,
      specimenId,
      patientId,
      clientTimeZone,
      excludeSiteCriteria: isBiorepo
    })
    commit('fetchSpecimenEvents', response.data)
    commit('setStatus', 'ok')
  } catch (err) {
    commit('setStatus', 'error')
    toast.error({ title: err.message })
    throw new Error(err.message)
  }
}

export const fetchSpecimenDetails = async ({ commit }, params) => {
  const [specimenId, patientId] = params
  commit('setStatus', 'loading')
  try {
    const response = await getSpecimenDetailsApi({ patientId, specimenId })
    const { data } = response
    commit('fetchSpecimenDetails', data)
    commit('setStatus', 'ok')
    return data
  } catch (err) {
    commit('setStatus', 'error')
    toast.error({ title: err.message })
    throw new Error(err.message)
  }
}

export const fetchEmbryoTypes = async ({ commit }) => {
  try {
    commit('setStatus', 'loading')
    const response = await getEmbryoTypesApi()
    commit('setEmbryoTypes', response.data)
    commit('setStatus', 'ok')
    return response
  } catch (err) {
    commit('setStatus', 'error')
    toast.error({ title: err.message })
    throw new Error(err.message)
  }
}

export const fetchCryoDevicesTypes = async ({ commit }) => {
  try {
    commit('setStatus', 'loading')
    const response = await getCryodeviceTypesApi()
    commit('setCryoDevicesTypes', response.data)
    commit('setStatus', 'ok')
    return response.data
  } catch (err) {
    commit('setStatus', 'error')
    toast.error({ title: err.message })
    throw new Error(err.message)
  }
}

export const fetchAuditLog = async ({ commit }, params) => {
  const [patientId, specimenId] = params
  const clientTimeZone = dayjs.tz.guess() || 'America/New_York'

  try {
    const response = await getAuditLogApi({ patientId, specimenId, clientTimeZone })
    const { data } = response
    commit('setFileUrl', data)
  } catch (err) {
    commit('setStatus', 'error')
    toast.error({ title: err.message })
    throw new Error(err.message)
  }
}

export const fetchSpecimensEnabled = async (
  { commit, rootState },
  { patientIds, specimenType, includeChildTickets = false }
) => {
  const {
    appBaseConfig: { clinicId, collectionProtocolId }
  } = rootState.authModule
  const getSpecimens = async () => {
    const response = await getSpecimensByPatientIdsApi({ patientIds, specimenType })
    const { data } = response
    const returnedSpecimensArray = Boolean(data?.length)
    if (returnedSpecimensArray) {
      return data
    }
    return []
  }

  const getOpenTicketBeaconBarcodes = async (beaconBarcodes) => {
    const openTicketsResponse = await getTicketsByBeaconsApi({
      clinicId,
      collectionProtocolId,
      beaconBarcodes,
      includeChildTickets
    })
    const { data } = openTicketsResponse
    const returnedTicketsArray = Boolean(data?.length)
    if (returnedTicketsArray) {
      return data.map((ticket) => ticket.beaconBarcode)
    }
    return []
  }

  const getSpecimensWithDisabledFlag = async (specimens) => {
    const beaconBarcodes = [...new Set(specimens.map((specimen) => specimen.beaconBarcode))]
    const openTicketBeacons = await getOpenTicketBeaconBarcodes(beaconBarcodes)
    const isDisabled = (specimen) => openTicketBeacons.includes(specimen.beaconBarcode)
    const specimensWithDisabledFlag = specimens.map((specimen) => ({
      ...specimen,
      disabled: isDisabled(specimen)
    }))
    return specimensWithDisabledFlag
  }

  try {
    commit('setStatus', 'loading')
    const specimens = await getSpecimens()
    const hasSpecimens = Boolean(specimens.length)
    if (hasSpecimens) {
      const specimensWithDisabledFlag = await getSpecimensWithDisabledFlag(specimens)
      commit('saveSpecimens', specimensWithDisabledFlag)
    }
    commit('setStatus', 'ok')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    commit('error', 'Error fetching available specimens')
  }
}

export const addSharedPatients = async ({ commit }, { specimenId, patientIds }) => {
  try {
    commit('setStatus', 'loading')
    const response = await addSharedPatientsApi({ specimenId, patientIds })
    const { data, status } = response
    if (status !== 200) throw new Error(data)
    commit('setStatus', 'ok')
    commit('sharedPatientAdded', true)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    commit('error', ADD_SHARED_PATIENT_ERROR)
  }
}

export const removeSharedPatient = async ({ commit }, { specimenId, patientId, reason }) => {
  try {
    commit('setStatus', 'loading')
    const response = await deleteSharedPatientApi({ specimenId, patientId, reason })
    const { data, status } = response
    if (status !== 200) {
      throw new Error(data)
    }
    commit('setStatus', 'ok')
  } catch (e) {
    commit('error', REMOVE_PATIENT_FROM_SPECIMEN_ERROR)
    // eslint-disable-next-line no-console
    console.error(REMOVE_PATIENT_FROM_SPECIMEN_ERROR, e)
  }
}

export const resetSharedPatientAddedFlag = async ({ commit }) => {
  commit('sharedPatientAdded', false)
}

export default {
  resetSpecimens,
  fetchSpecimens,
  fetchSpecimensEvents,
  fetchSpecimenDetails,
  fetchEmbryoTypes,
  fetchCryoDevicesTypes,
  fetchAuditLog,
  fetchSpecimensEnabled,
  /** Specimen Details * */
  addSharedPatients,
  removeSharedPatient,
  resetSharedPatientAddedFlag
}
