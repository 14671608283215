/* eslint-disable max-len */
export const PRINTER_CONFIG_THERMAL_TRANSFER = `^FX The following command sets the printer darkness (-15 to 30)
^MD30
^FX The following command sets the printer speed ^PRa,b,c ()
^PR222
^FX The following command sets the label width in dots per inch (Print Width)
^PW400
^FX The following command sets the label length in dots per inch (Print Height)
^LL298
^FX The following command sets the Media Tracking being used
^MNA
^FX The following command sets the Media Type being used
^MTT
^FX The following command sets the label home position (X,Y)
^LH0,145
^FX The following command sets the label top position (single value)
^LT-10
^FX Remove backfeed
~JSB`

export const PRINTER_CONFIG_DIRECT_THERMAL = `^FX The following command sets the printer darkness (-15 to 30)
^MD10
^FX The following command sets the label width in dots per inch (Print Width)
^PW600
^FX The following command sets the label length in dots per inch (Print Height)
^LL1800
^FX The following command sets the Media Tracking being used
^MNM,0
^FX The following command sets the Media Type being used
^MTD
^FX The following command sets the label home position (X,Y)
^LH0,0
^FX The following command sets the label top position (single value)
^LT0
^FX Laben orientation (N = Normal / I = Inverted)
^PON
^FX Remove backfeed
~JSB`

export const BARCODE = `
^FO100,35
^GB142,25,20,B,3^FS
^FO35,25
^BY2,2,25,N,N,N
^BCN30,Y,N,N
^FD`

export const PATIENT_ID = '^FO15,65^FR^A0N,24,23^FDID #'

export const SQUARE = '^FO0,0^GB360,250,3^FS'

export const ARROW_LEFT = `
^FO10,245^GFA,104,104,4, H0G1GEJ0:::::::::::::G0G6G1GEG1I0G0GFG1GEG3GCH0G0GFG9GEG7GCH0G0JFGCH0G0G7IFG8H0G0G3IFI0G0G1HFGEI0H0HFGCI0H0G7GFG8I0H0G3GFJ0H0G1GEJ0I0GCJ0^FS
^FO40,245^GFA,104,104,4, H0G1GEJ0:::::::::::::G0G6G1GEG1I0G0GFG1GEG3GCH0G0GFG9GEG7GCH0G0JFGCH0G0G7IFG8H0G0G3IFI0G0G1HFGEI0H0HFGCI0H0G7GFG8I0H0G3GFJ0H0G1GEJ0I0GCJ0^FS
`

export const ARROW_RIGHT = `
^FO290,245^GFA,104,104,4, H0G1GEJ0:::::::::::::G0G6G1GEG1I0G0GFG1GEG3GCH0G0GFG9GEG7GCH0G0JFGCH0G0G7IFG8H0G0G3IFI0G0G1HFGEI0H0HFGCI0H0G7GFG8I0H0G3GFJ0H0G1GEJ0I0GCJ0^FS
^FO320,245^GFA,104,104,4, H0G1GEJ0:::::::::::::G0G6G1GEG1I0G0GFG1GEG3GCH0G0GFG9GEG7GCH0G0JFGCH0G0G7IFG8H0G0G3IFI0G0G1HFGEI0H0HFGCI0H0G7GFG8I0H0G3GFJ0H0G1GEJ0I0GCJ0^FS
`

export const BEGIN_APPLICATION_HERE = '^FO100,250^FR^A0N,22,21^FDAPPLY FROM HERE'

// Standard labels configuration

export const CRYODEVICE_ID = '^FO111,38^FR^A0N,24,23^FD'

export const SCREEN_POSITIVE_ICON = '^FO310,47^GFA,128,128,4, ,:K0G4H0H0G4H0G2H0H0GCH0G3H0H0G8H0G1H0G0G1G8H0G1G8G0:G0G3G8H0G1GCG0G0G3G8G7GEG1GCG0G0G3G9HFGBGCG0G0G3GDGCG3GBGCG0G0G3GEH0G7GCG0G0HFH0HFG0G1HFGCG3HFG8G3LFGCG7G0G1HFG8G0GEG6G1GCG7GEG3G8G6GCG1GCG7GEG3G8G3G8G0GCG3GCG3G0G1:G8G0GEG3GCG7G0G1H0G7G1G8GEH0H0G3H9GCH0H0G1GBGDG8H0I0G3GCI0I0G7GEI0:H0G1HFG8H0G0G6G7GEG7GFG6G0G0G1GFG8G1GFG8G0,^FS'

export const SCREEN_NEGATIVE_ICON = '^FO310,50^GFA,128,128,4, I0HFI0H0G7HFGEH0H0JFG8G0G0G3JFGCG0G0G7JFGEG0G0LFG0G1LFG8G1LFGCG3LFGCG7JFGEGFGEG7JFGCG3GEG7JFG8G3GEKFG0G3GFJFGEG0G7GFHFG7GFGCG0HFGFGEG3GFG8G1HFGFGCG0GFG0G3HFGFGCG0GEG0G7HFGFGEI0IFHFH0G1IFG7GFG8G0G3HFGEG7GFGCG0G7HFGEG7GFGEG0IFGEG3HFG1IFGCG3LFGCG1LFG8G0LFG0G0G7JFGEG0G0G3JFGCG0G0G1JFG8G0H0G7HFGEH0I0HFI0^FS'

export const UNSCREENED = `^FO320,50^GC30,30,B^FS
^FO328,57^FR^A0N,25,20^FDU^FS`

export const SCREEN_POSITIVE_ICON_OOCYTE = '^FO180,47^GFA,128,128,4, ,:K0G4H0H0G4H0G2H0H0GCH0G3H0H0G8H0G1H0G0G1G8H0G1G8G0:G0G3G8H0G1GCG0G0G3G8G7GEG1GCG0G0G3G9HFGBGCG0G0G3GDGCG3GBGCG0G0G3GEH0G7GCG0G0HFH0HFG0G1HFGCG3HFG8G3LFGCG7G0G1HFG8G0GEG6G1GCG7GEG3G8G6GCG1GCG7GEG3G8G3G8G0GCG3GCG3G0G1:G8G0GEG3GCG7G0G1H0G7G1G8GEH0H0G3H9GCH0H0G1GBGDG8H0I0G3GCI0I0G7GEI0:H0G1HFG8H0G0G6G7GEG7GFG6G0G0G1GFG8G1GFG8G0,^FS'

export const SCREEN_NEGATIVE_ICON_OOCYTE = '^FO180,50^GFA,128,128,4, I0HFI0H0G7HFGEH0H0JFG8G0G0G3JFGCG0G0G7JFGEG0G0LFG0G1LFG8G1LFGCG3LFGCG7JFGEGFGEG7JFGCG3GEG7JFG8G3GEKFG0G3GFJFGEG0G7GFHFG7GFGCG0HFGFGEG3GFG8G1HFGFGCG0GFG0G3HFGFGCG0GEG0G7HFGFGEI0IFHFH0G1IFG7GFG8G0G3HFGEG7GFGCG0G7HFGEG7GFGEG0IFGEG3HFG1IFGCG3LFGCG1LFG8G0LFG0G0G7JFGEG0G0G3JFGCG0G0G1JFG8G0H0G7HFGEH0I0HFI0^FS'

export const UNSCREENED_OOCYTE = `^FO190,50^GC30,30,B^FS
^FO198,57^FR^A0N,25,20^FDU^FS`

export const OOCYTE_MATURITY_LEVEL = '^FO295,65^FR^A0N,24,23^FD'

export const SPECIMEN_COUNT = '^FO160,65^FR^A0N,24,23^FD'

export const EMBRYO_NUMBER = '^FO310,65^FPR^FR^A0N,26,25^FD'

export const EMBRYO_DAY = '^FO150,65^FR^A0N,26,25^FD'

export const PATIENT_NAME = '^FO15,90^FR^A0N,26,25^FD'

export const PRINT_DATE = '^FO220,90^FR^A0N,24,23^FD'

// Slim labels configuration

export const OOCYTE_MATURITY_LEVEL_SLIM = '^FO290,49^FR^A0N,26,26^FD'

export const SPECIMEN_COUNT_SLIM = '^FO160,49^FR^A0N,26,25^FD'

export const EMBRYO_NUMBER_SLIM = '^FO250,47^FPR^FR^A0N,26,25^FD'

export const CRYODEVICE_ID_SLIM = '^FO15,49^FR^A0N,26,26^FD'

export const SCREEN_POSITIVE_ICON_SLIM = '^FO310,47^GFA,72,72,3, ,:G0G1H0G8G0G0G3H0GCG0G0G6H0G6G0::G0G6G7GEG6G0G0J6G0G0GFH0GFG0G3GFG8G1GFGCG7JFGEG6G0G7GEG0G6GCG6G3GCG6G3G8G2G3GCG4G1G8G3G1G8GCG1G0G3H9GCG0G0G1GDGBG8G0H0GDGBH0H0G3GCH0:H0HFH0G0G7GEG7GEG0,^FS'

export const SCREEN_NEGATIVE_ICON_SLIM = '^FO310,48^GFA,72,72,3, H0G7GEH0G0G3HFGCG0G0G7HFGEG0G0JFG0G1JFG8G3JFGCG7JFGEG7IFG9GEG7IFG1GEIFGEG1GFIFGCG3GFGFG8GFG8G7GFGFG8G7G0HFGFG8G2G1HFGFGCG0G3HFG7GEG0G7GFGEG7GFG0HFGEG7GFG9HFGEG3JFGCG1JFG8G0JFG0G0G7HFGEG0G0G3HFGCG0H0G7GEH0^FS'

export const UNSCREENED_SLIM = `^FO320,48^GC22,22,B^FS
^FO326,52^FR^A0N,20,15^FDU^FS`

export const SCREEN_POSITIVE_ICON_SLIM_OOCYTE = '^FO310,47^GFA,72,72,3, ,:G0G1H0G8G0G0G3H0GCG0G0G6H0G6G0::G0G6G7GEG6G0G0J6G0G0GFH0GFG0G3GFG8G1GFGCG7JFGEG6G0G7GEG0G6GCG6G3GCG6G3G8G2G3GCG4G1G8G3G1G8GCG1G0G3H9GCG0G0G1GDGBG8G0H0GDGBH0H0G3GCH0:H0HFH0G0G7GEG7GEG0,^FS'

export const SCREEN_NEGATIVE_ICON_SLIM_OOCYTE = '^FO310,48^GFA,72,72,3, H0G7GEH0G0G3HFGCG0G0G7HFGEG0G0JFG0G1JFG8G3JFGCG7JFGEG7IFG9GEG7IFG1GEIFGEG1GFIFGCG3GFGFG8GFG8G7GFGFG8G7G0HFGFG8G2G1HFGFGCG0G3HFG7GEG0G7GFGEG7GFG0HFGEG7GFG9HFGEG3JFGCG1JFG8G0JFG0G0G7HFGEG0G0G3HFGCG0H0G7GEH0^FS'

export const UNSCREENED_SLIM_OOCYTE = `^FO320,48^GC22,22,B^FS
^FO326,52^FR^A0N,20,15^FDU^FS`

export const PATIENT_NAME_SLIM = '^FO15,75^FR^A0N,24,23^FD'

export const PRINT_DATE_SLIM = '^FO235,75^FR^A0N,25,24^FD'

export const BEACON_ID = '^FO15,55^FR^A0N,28,27^FD'

// General Helpers

export const LABEL_START = '^XA'

export const LABEL_UTF8_FORMAT = '^CI28'

export const LABEL_END = '^XZ'

export const FIELD_SEPARATOR = '^FS'

export const TEAR_OFF_LABEL = '^MMT,N'
export const CUTTER = '^MMC,N'

export const TMRW_LOGO = `
^FO90,10^GC5,5,B^FS
^FO96,10^GC5,5,B^FS
^FO102,10^GC5,5,B^FS
^FO108,10^GC5,5,B^FS
^FO114,10^GC5,5,B^FS
^FO120,10^GC5,5,B^FS
^FO90,17^GC5,5,B^FS
^FO96,17^GC5,5,B^FS
^FO102,17^GC5,5,B^FS
^FO108,17^GC5,5,B^FS
^FO114,17^GC5,5,B^FS
^FO120,17^GC5,5,B^FS
^FO102,24^GC5,5,B^FS
^FO108,24^GC5,5,B^FS
^FO102,31^GC5,5,B^FS
^FO108,31^GC5,5,B^FS
^FO102,38^GC5,5,B^FS
^FO108,38^GC5,5,B^FS
^FO102,45^GC5,5,B^FS
^FO108,45^GC5,5,B^FS
^FO130,10^GC5,5,B^FS
^FO136,10^GC5,5,B^FS
^FO130,17^GC5,5,B^FS
^FO136,17^GC5,5,B^FS
^FO130,24^GC5,5,B^FS
^FO136,24^GC5,5,B^FS
^FO130,31^GC5,5,B^FS
^FO136,31^GC5,5,B^FS
^FO130,38^GC5,5,B^FS
^FO136,38^GC5,5,B^FS
^FO130,45^GC5,5,B^FS
^FO136,45^GC5,5,B^FS
^FO142,17^GC5,5,B^FS
^FO142,24^GC5,5,B^FS
^FO148,24^GC5,5,B^FS
^FO148,30^GC5,5,B^FS
^FO154,17^GC5,5,B^FS
^FO154,24^GC5,5,B^FS
^FO160,10^GC5,5,B^FS
^FO166,10^GC5,5,B^FS
^FO160,17^GC5,5,B^FS
^FO166,17^GC5,5,B^FS
^FO160,24^GC5,5,B^FS
^FO166,24^GC5,5,B^FS
^FO160,31^GC5,5,B^FS
^FO166,31^GC5,5,B^FS
^FO160,38^GC5,5,B^FS
^FO166,38^GC5,5,B^FS
^FO160,45^GC5,5,B^FS
^FO166,45^GC5,5,B^FS
^FO178,10^GC5,5,B^FS
^FO184,10^GC5,5,B^FS
^FO190,10^GC5,5,B^FS
^FO196,10^GC5,5,B^FS
^FO202,10^GC5,5,B^FS
^FO178,17^GC5,5,B^FS
^FO184,17^GC5,5,B^FS
^FO190,17^GC5,5,B^FS
^FO196,17^GC5,5,B^FS
^FO202,17^GC5,5,B^FS
^FO118,17^GC5,5,B^FS
^FO178,24^GC5,5,B^FS
^FO184,24^GC5,5,B^FS
^FO202,24^GC5,5,B^FS
^FO208,24^GC5,5,B^FS
^FO178,31^GC5,5,B^FS
^FO184,31^GC5,5,B^FS
^FO190,31^GC5,5,B^FS
^FO196,31^GC5,5,B^FS
^FO202,31^GC5,5,B^FS
^FO178,38^GC5,5,B^FS
^FO184,38^GC5,5,B^FS
^FO196,38^GC5,5,B^FS
^FO202,38^GC5,5,B^FS
^FO178,45^GC5,5,B^FS
^FO184,45^GC5,5,B^FS
^FO202,45^GC5,5,B^FS
^FO208,45^GC5,5,B^FS
^FO220,10^GC5,5,B^FS
^FO220,17^GC5,5,B^FS
^FO220,24^GC5,5,B^FS
^FO220,31^GC5,5,B^FS
^FO220,38^GC5,5,B^FS
^FO220,45^GC5,5,B^FS
^FO226,10^GC5,5,B^FS
^FO226,17^GC5,5,B^FS
^FO226,24^GC5,5,B^FS
^FO226,31^GC5,5,B^FS
^FO226,38^GC5,5,B^FS
^FO226,45^GC5,5,B^FS
^FO250,10^GC5,5,B^FS
^FO250,17^GC5,5,B^FS
^FO250,24^GC5,5,B^FS
^FO250,31^GC5,5,B^FS
^FO250,38^GC5,5,B^FS
^FO250,45^GC5,5,B^FS
^FO256,10^GC5,5,B^FS
^FO256,17^GC5,5,B^FS
^FO256,24^GC5,5,B^FS
^FO256,31^GC5,5,B^FS
^FO256,38^GC5,5,B^FS
^FO256,45^GC5,5,B^FS
^FO232,31^GC5,5,B^FS
^FO232,38^GC5,5,B^FS
^FO238,24^GC5,5,B^FS
^FO238,31^GC5,5,B^FS
^FO244,31^GC5,5,B^FS
^FO244,38^GC5,5,B^FS`
