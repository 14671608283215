<template>
  <div class="empty-state__wrapper bg-white rounded-lg p-6">
    <span class="ticket-table__header bg-gradient-to-r from-tmrw-green-light to-transparent flex justify-between p-5 text-tmrw-blue-light rounded-lg">
      No specimens added yet
    </span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'biorepository-ticket-empty-state'
}
</script>

<style lang="scss" scoped>
.empty-state__wrapper {
  height: fit-content;
}
</style>
