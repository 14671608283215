// eslint-disable-next-line max-classes-per-file
import { DataTypes } from '../interfaces/index'

class ApiFactory {
  private data: object[]

  public constructor(data: object[]) {
    this.data = data
  }

  public DeepComposite(): object {
    try {
      Object.entries(this.data)
        .map(([id, value]: [DataTypes['multiple'], DataTypes['multiple']]) => {
          this.data[id] = { id, value }
          return this.data
        })
      return this
    } catch (error: any) {
      return error
    }
  }

  public compositeResponse() {
    try {
      const tempArray = [] as object[]
      // cloning object in order to avoid data mutation
      const cloneObj: {
        map: (params: {}) => {}
      } = JSON.parse(JSON.stringify(this.data))
      cloneObj.map((clonedObject: object[]) => {
        const api: {
          DeepComposite: () => object
        } = new ApiFactory(clonedObject)
        const response: ReturnType<typeof Object> = api.DeepComposite()
        return tempArray.push(response.data)
      })
      return tempArray
    } catch (error) {
      return error
    }
  }
}

class ObjectFactory {
  private object: InstanceType<typeof ObjectFactory>

  private attribute: string

  private value: ReturnType<typeof Object>

  private optionalFlag: DataTypes['multiple'] | undefined

  public constructor(
    object: ReturnType<typeof Object>,
    attribute: string = '',
    value: ReturnType<typeof Object>,
    optionalFlag?: string | number | object
  ) {
    this.object = object
    this.attribute = attribute
    this.value = value
    this.optionalFlag = optionalFlag
  }

  composite(): object {
    try {
      return Object.assign(this.object, { ...this.object, [this.attribute]: this.value, optionalFlag: this.optionalFlag })
    } catch (error: any) {
      return error
    }
  }

  deepComposite(): object {
    try {
      this.object.value
        .map((item: DataTypes['map']) => Object.assign(item, { ...item, [this.attribute]: { id: this.attribute, value: this.value, optionalFlag: this.optionalFlag } }))
      return this
    } catch (error: any) {
      return error
    }
  }
}

class TransformationFactory {
  private item: { procedureName: { value: string} }

  constructor(item: { procedureName: { value: string} }) {
    this.item = item
  }

  changeText(to: string, from: string) {
    if (this.item.procedureName.value.includes('Egg')) {
      this.item.procedureName.value = this.item.procedureName.value.replace(to, from)
    }
    return this.item
  }
}

export {
  ApiFactory,
  ObjectFactory,
  TransformationFactory
}
