<template>
  <MainContainer data-test="slected-ticket" :grid-slots="gridSlots">
    <top-header @backStep="handleClickBack">{{ headerMessage }}</top-header>
    <ActionBar data-test="how-many__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">{{ bubbleMessage }}</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="canBreakBatch"
          btnText="Break up Batch ticket"
          btnType="button"
          btnStyle="secondary"
          addMarginLeft
          showIcon
          fontAwesomeIconClass="link"
          :isLoading="isProcessingBatchBreak"
          @click="onClickBreakUpBatch"
        />
        <button-dynamic
          v-if="showPrint"
          btnText="Print All"
          btnType="button"
          btnStyle="secondary"
          showIcon
          fontAwesomeIconClass="print"
          addMarginLeft
          @click="showPrinterModal"
        />

        <button-dynamic
          btnText="Close"
          btnType="button"
          btnStyle="primary"
          fontAwesomeIconClass="times-circle"
          showIcon
          addMarginLeft
          @click="onClickClose"
        />
      </template>
    </ActionBar>
    <bread-crumbs size="base" v-if="breadCrumsItems" :items="breadCrumsItems" />
    <section v-if="selectedTicketsLength" class="pb-4">
      <paginator
        data-test="selected-tickets__paginator"
        v-if="selectedTicketsLength > 1"
        :total-items-to-display="selectedTicketsLength"
        :max-paginators-count-per-view="maxPaginatorsCountPerView"
        :currentActivePage="currentActivePage"
        @handlePageChange="handlePageChange"
      />
      <TicketToPrint
        v-for="(ticket, key) in getItemsToPrintPerPage"
        :ticket="ticket"
        :key="setKey(key)"
        :ticketType="viewTicket.ticketType"
        :can-disable-reprint="true"
        :show-ticket-action-buttons="!isStacked"
        :onClickCancel="onClickCancel"
      />
      <div class="mt-10" v-if="showRemainingSpecimens">
        <dynamic-title addMarginBottom titleType="h3" floatTo="left">
          Remaining in CryoBeacon
        </dynamic-title>
        <div class="flex w-full bg-white rounded-md py-5 px-10 mt-5">
          <span class="w-full">
            <InventorySpecimenTables
              data-test="ticket-detail__remaining-specimens"
              class="w-full"
              :ticketDetail="ticketDetailModel"
              :eggSpecimenHeaders="EGG_HEADERS"
              :eggSpecimens="eggSpecimens"
              :embryoSpecimenHeaders="EMBRYO_HEADERS"
              :embryoSpecimens="embryoSpecimens"
            />
          </span>
        </div>
      </div>
    </section>
    <ModalGeneric
      v-if="isTicketModalOpen"
      cols-distribution="11/1"
      data-test="selected-tickets-cancel-modal"
      :title="cancelTitle"
      :confirm-label="cancelLabel"
      cancel-label="Back"
      confirm-icon="trash"
      :title-classes="['text-2xl', 'font-semibold', 'text-tmrw-blue']"
      confirm-button-style="error-button"
      button-aligment="justify-between"
      modal-width="w-1/2"
      close-icon="times-circle-regular"
      @confirm="cancelTicket"
      @cancel="closeCancelTicketModal"
    />
    <BreakUpBatchTicketModal
      :ticketId="viewTicket.ticketId"
      @onHideModal="onHideBreakUpBatchModal"
      @breakUpBatchTicket="breakUpBatchTicket"
      :show="showBreakUpBatchModal"
    />

    <ModalPrint
      v-if="isPrinterModalVisible"
      :selected-tickets="selectedTickets"
      :tickets-to-print="selectedTicketsToPrint"
      :activePrinters="activePrinters"
      :selectedPrinter="selectedPrinter"
      :isPrinterLoading="isPrinterLoading"
      @close-printer-modal="closePrinterModal"
    />
  </MainContainer>
</template>

<script setup lang="ts">
/**
 * To Do
 * Redesign architechture
 * This component is similar to -> src/views/Tickets/Tickets.vue
 */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import TicketToPrint from '@/components/TicketToPrint/TicketToPrint.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import Paginator from '@/components/Paginator/Paginator.vue'
import ModalPrint from '@/components/ModalPrint/ModalPrint.vue'

import {
  MAX_PAGINATORS_COUNT_PER_VIEW,
  CRYODEVICE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  TICKET_CRYOSHIPPER,
  PROCEDURE_TYPE_CRYOSHIPPER,
  CRYOSHIPPER_VIEW_MODE,
  SOURCE,
  PROCEDURE_TYPE_BATCH,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  ZEBRA_TICKET_PRINTERS_ID
} from '@/constants'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import BreakUpBatchTicketModal from '@/components/BreakUpBatchModal/BreakUpBatchModal.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ModalGeneric from '@/components/ModalGeneric/ModalGeneric.vue'
import * as ss from '@/config/session-storage-help'
import toast from '@/config/toast'
import { createDynamicHeadersFromSpecimens } from '@/helpers/dynamicTableHeaders'
import { ApiCallsFactory } from '@/factories/index'
import { STEP_INITIAL } from '@/constants/ticketSteps'
import { setBeacons } from '@/helpers/manageTicket'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'
import { FligthBoardToPdf } from '@/helpers/printPDF/cryoShipperPdf'
import { getTicketApi } from '@/services/tickets'
import { TOP, SMALL, BOTTOM } from '@/constants'
import { getInstalledPrinters } from '@/modules/printer'

dayjs.extend(utc)

const router = useRouter()
const route = useRoute()

const currentActivePage = ref(1)
const bubbleMessage = ref('')

const isPrinterModalVisible = ref(false)
const selectedTicketsToPrint = ref<any[]>([])
const maxPaginatorsCountPerView = ref(MAX_PAGINATORS_COUNT_PER_VIEW)
const isProcessingBatchBreak = ref(false)
const showBreakUpBatchModal = ref(false)
const isTicketModalOpen = ref(false)
const EGG_HEADERS = ref<any[]>([])
const EMBRYO_HEADERS = ref<any[]>([])

const cancelTitle = ref('Are you sure you’d like to cancel this CryoShipper Ticket?')
const cancelLabel = ref('Yes, Cancel this ticket')
const activePrinters = ref<any[]>([])
const selectedPrinter = ref('pdf')
const isPrinterLoading = ref(true)

const ticketDetailModel = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})

const showPrint = computed(() => {
  return true
})

const { selectedTickets, selectedViewMode, viewTicket, isStacked } =
  useGetters('selectedTicketsModule')

const { loggedUserInfo } = useGetters('authModule')
const { allContainersBySite } = useGetters('siteModule')

const selectedTicketsLength = computed(() => {
  return selectedTicketsToPrint.value.length
})
const headerMessage = computed(() => {
  return getHeaderMessage(selectedTicketsToPrint.value)
})
const activeSelectedTicket = computed<any>(() => {
  return selectedTicketsToPrint.value[currentActivePage.value - 1]
})

const breadCrumsItems = computed(() => {
  if (selectedViewMode.value === CRYOSHIPPER_VIEW_MODE) {
    return getBiorepoBreadcrumbs({
      active: 'created',
      ticketMode: TICKET_CRYOSHIPPER
    })
  }
  return null
})

const gridSlots = computed(() => {
  return breadCrumsItems.value ? '4' : '3'
})

const canBreakBatch = computed(() => {
  return viewTicket.value.ticketType === 'batch' && !isStacked.value
})

const getItemsToPrintPerPage = computed(() => {
  const maxItemsPerPage = 1
  const currentTicketToPrint = currentActivePage.value * maxItemsPerPage
  const start = currentTicketToPrint - maxItemsPerPage
  return selectedTicketsToPrint.value.slice(start, currentTicketToPrint)
})
const isBiorepositoryMoveTicketView = computed(() => {
  return ss.getFieldSessionStorage('process', 'isBiorepositoryRoute')
})
const getSpecimensRemaining = computed(() => {
  const remainingSpecimens =
    activeSelectedTicket.value?.specimens?.filter((specimen) => !specimen.inSelectedTicket) || []

  return remainingSpecimens
})
const showRemainingSpecimens = computed(() => {
  return !!getSpecimensRemaining.value.length
})

const embryoSpecimens = computed(() => {
  const embryosModel = {
    ...ticketDetailModel.value,
    options: getSpecimensRemaining.value.filter(
      (item) => item.specimenType === SPECIMEN_TYPE_EMBRYO
    )
  }
  return embryosModel
})
const eggSpecimens = computed(() => {
  const eggsModel = {
    ...ticketDetailModel.value,
    options: getSpecimensRemaining.value.filter(
      (item) =>
        item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
    )
  }
  return eggsModel
})

const { fetchTicketsInfo, deleteTicket, popAll } = useActions('selectedTicketsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const handleClickBack = () => {
  onClickClose()
}
const goAway = () => {
  const flightBoardRouteName = isBiorepositoryMoveTicketView.value
    ? STEP_BIOREPOSITORY_FLIGHTBOARD
    : STEP_INITIAL
  router.push({ name: flightBoardRouteName })
}
const onClickClose = () => {
  ss.removeFieldSessionStorage('process', 'activeSlideSelectedTickets')
  if (isStacked.value) {
    popAll()
    router.go(-1)
    return
  }
  if (selectedViewMode.value === TICKET_CRYOSHIPPER) {
    router.go(-1)
    return
  }
  goAway()
}

const getPrinters = async () => {
  const localPrinters = await getInstalledPrinters()
  localPrinters.printer.forEach((printer) => {
    ZEBRA_TICKET_PRINTERS_ID.forEach((printerId) => {
      if (printer.name.includes(printerId)) {
        activePrinters.value.push(printer)
        selectedPrinter.value = printer.uid
      }
    })
  })
  isPrinterLoading.value = false
}
const handlePageChange = (page) => {
  currentActivePage.value = page
  ss.setFieldSessionStorage('activeSlideSelectedTickets', page, 'process')
}
const setBubbleMessage = () => {
  if (selectedViewMode.value === 'cryoshipper-created') {
    bubbleMessage.value = `CryoShipper Ticket #${activeSelectedTicket.value?.ticketId} has been successfully created.`
    return
  }
  if (viewTicket.value.ticketType === 'batch') {
    bubbleMessage.value = `You are viewing Batch Ticket #${viewTicket.value.ticketId}`
    return
  }

  if (viewTicket.value.ticketType === 'cryoshipper') {
    bubbleMessage.value = `Viewing details for CryoShipper Ticket #${viewTicket.value.ticketId}`
    return
  }
  bubbleMessage.value = activeSelectedTicket.value?.ticketId
    ? `Ticket #${activeSelectedTicket.value.ticketId}`
    : ''
  const numberOfTickets = selectedTicketsToPrint.value.length
  bubbleMessage.value = numberOfTickets
    ? `You're viewing ${numberOfTickets} Ticket${numberOfTickets > 1 ? 's' : ''}`
    : ''
}
const closePrinterModal = () => {
  isPrinterModalVisible.value = false
}

async function fetchAllTickets(ticketIds) {
  const response: any = await getTicketApi({ ticketId: ticketIds.join(',') })
  return response.data
}
const getBatchTicketsTop = (items) => {
  return items
    .filter((item) => item.cryoshipperGridType === TOP || item.cryoshipperGridType === SMALL)
    .flatMap((item) =>
      item.tickets
        .filter((ticket) => ticket.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE)
        .map((ticket) => ticket.children)
    )
    .flat()
}

const getBatchTicketsBottom = (items) => {
  return items
    .filter((item) => item.cryoshipperGridType === BOTTOM)
    .flatMap((item) =>
      item.tickets
        .filter((ticket) => ticket.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE)
        .map((ticket) => ticket.children)
    )
    .flat()
}

const getGridsByType = (grids, types) => {
  return grids.filter((grid) => types.includes(grid.cryoshipperGridType))
}

const filterTicketsByProcedure = (tickets, procedureName) => {
  return tickets.filter((ticket) => ticket.procedureName !== procedureName)
}

const showPrinterModal = async () => {
  if (
    viewTicket.value.ticketType !== PROCEDURE_TYPE_CRYOSHIPPER.toLowerCase() &&
    viewTicket.value.ticketType !== PROCEDURE_TYPE_CRYOSHIPPER.toUpperCase()
  ) {
    isPrinterModalVisible.value = true
  } else {
    const batchTicketsTop = getBatchTicketsTop(
      getItemsToPrintPerPage.value[0].shipmentDetails.cryoshipperGrids
    )
    const allTopBatchTicketsId = batchTicketsTop
      .flat()
      .filter((ticketBatch) => ticketBatch !== undefined)
    let allTopBatchTickets =
      allTopBatchTicketsId && allTopBatchTicketsId[0]
        ? await fetchAllTickets(allTopBatchTicketsId)
        : []

    const batchTicketsBottom = getBatchTicketsBottom(
      getItemsToPrintPerPage.value[0].shipmentDetails.cryoshipperGrids
    )
    const allBottomBatchTicketsId = batchTicketsBottom
      .flat()
      .filter((ticketBatch) => ticketBatch !== undefined)
    let allBottomBatchTickets =
      allBottomBatchTicketsId && allBottomBatchTicketsId[0]
        ? await fetchAllTickets(allBottomBatchTicketsId)
        : []

    let ticketListTop = getGridsByType(
      getItemsToPrintPerPage.value[0].shipmentDetails.cryoshipperGrids,
      [TOP, SMALL]
    )
    let ticketsArrayTop = ticketListTop.length > 0 ? ticketListTop[0].tickets : []
    let filteredTicketsTop = filterTicketsByProcedure(ticketsArrayTop, PROCEDURE_TYPE_BATCH)
    const totalTicketsTopNonBatch = filteredTicketsTop.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    )
    ticketListTop = [...totalTicketsTopNonBatch, ...allTopBatchTickets]

    let ticketListBottom = getGridsByType(
      getItemsToPrintPerPage.value[0].shipmentDetails.cryoshipperGrids,
      [BOTTOM]
    )
    let ticketsArrayBottom = ticketListBottom.length > 0 ? ticketListBottom[0].tickets : []
    let filteredTicketsBottom = filterTicketsByProcedure(ticketsArrayBottom, PROCEDURE_TYPE_BATCH)
    const totalTicketsBottomNonBatch = filteredTicketsBottom.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    )
    ticketListBottom = [...totalTicketsBottomNonBatch, ...allBottomBatchTickets]

    ticketListTop = updateRobotNames(ticketListTop)
    ticketListBottom = updateRobotNames(ticketListBottom)

    const allTickets = { top: ticketListTop, bottom: ticketListBottom }
    composeCryoShipperTicket(allTickets)
  }
}

const updateRobotNames = (tickets) => {
  return tickets.map((ticket) => {
    // Create a new ticket object
    return {
      ...ticket,
      robotLocations: ticket.robotLocations.map((location) => {
        if (location.type === SOURCE && location.robotName === undefined) {
          const currentLocation = allContainersBySite.value.find(
            (container) => container.containerId === Number(location.robotId)
          )?.robotName

          if (currentLocation) {
            return { ...location, robotName: currentLocation }
          }
        }
        return location
      })
    }
  })
}

const composeCryoShipperTicket = async (allTickets) => {
  const printingFlightBoardToPDF = await FligthBoardToPdf(allTickets)
  return printingFlightBoardToPDF
}

const onClickCancel = ({ action }) => {
  switch (action) {
    case 'cancel-modal':
      openCancelTicketModal()
      return
    case 'reason-screen':
      ss.setFieldSessionStorage('selectedTicketToCancel', activeSelectedTicket.value, 'process')
      router.push('/cancel-ticket')
      return
    default:
      return false
  }
}

const onClickBreakUpBatch = () => {
  showBreakUpBatchModal.value = true
}
const onHideBreakUpBatchModal = () => {
  showBreakUpBatchModal.value = false
}
const cancelTicket = async (emitValues) => {
  try {
    closeCancelTicketModal()
    displaySpinner()
    const { ticketId, cancelReason } = emitValues || {}
    const userId = ss.getFieldSessionStorage('user', 'userId') || null
    const finalCancelReason =
      cancelReason ||
      `User ${loggedUserInfo.value.firstName} ${loggedUserInfo.value.lastName} cancelled ticket`

    const finalTicketId = ticketId || viewTicket.value.ticketId
    await deleteTicket({
      ticketId: finalTicketId,
      userId,
      cancelReason: finalCancelReason
    })
    goAway()
  } catch (e: any) {
    toast.error({ title: e.message })
  } finally {
    hideSpinner()
  }

  // return deleted
}
const breakUpBatchTicket = async () => {
  try {
    isProcessingBatchBreak.value = true
    // get base app config data
    const appBaseConfig = {
      siteId: ss.getFieldSessionStorage('config', 'siteId') || null,
      clinicId: ss.getFieldSessionStorage('config', 'clinicId') || null,
      cpId: ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
      token: ss.getFieldSessionStorage('user', 'token') || null,
      userId: ss.getFieldSessionStorage('user', 'userId') || null
    }

    const ticketId = viewTicket.value?.ticketId || null
    // hidding modal
    onHideBreakUpBatchModal()
    // construct URL
    const url = `/tickets/${ticketId}`
    // creating a new delete instance
    const batchTicket = new ApiCallsFactory(url, appBaseConfig.token)
    await batchTicket.deleteData('breakUpBatch', appBaseConfig.userId, true)
    // redirecting to fb
    if (isStacked.value) {
      popAll()
      return
    }
    return router.back()
  } catch (error: any) {
    isProcessingBatchBreak.value = false
    return toast.error({ title: error.message })
  }
}
const setKey = (key) => {
  return `${key}${dayjs().valueOf()}`
}

const getHeaderMessage = (tickets) => {
  if (selectedViewMode.value === 'cryoshipper-created') {
    return 'You have successfully created a New CryoShipper Ticket.'
  }
  const hasMultipleTicketsSelected = tickets.length > 1

  if (viewTicket.value.ticketType === 'cryoshipper') {
    return ''
  }
  if (hasMultipleTicketsSelected) {
    return `You're viewing ${tickets.length} tickets`
  }

  const [ticket] = tickets
  const ticketCryoDevicesCount = ticket?.specimens?.length
  const cryoDeviceSubString =
    ticketCryoDevicesCount > 1 ? `${CRYODEVICE.toLowerCase()}s` : CRYODEVICE.toLowerCase()
  return ticket
    ? `Ticket #${ticket.ticketId} with ${ticketCryoDevicesCount} ${cryoDeviceSubString}`
    : ''
}

const closeCancelTicketModal = () => {
  isTicketModalOpen.value = false
}
const openCancelTicketModal = () => {
  isTicketModalOpen.value = true
}

watch(currentActivePage, () => {
  setBubbleMessage()
})

watch(selectedTicketsToPrint, (newValue) => {
  if (newValue) {
    setBubbleMessage()
  }
})

onMounted(async () => {
  getPrinters()
  setBeacons([])
  const { embryoHeaders, oocyteHeaders } = createDynamicHeadersFromSpecimens({
    ticket: activeSelectedTicket.value,
    reprintDisabled: true
  })

  EMBRYO_HEADERS.value = embryoHeaders
  EGG_HEADERS.value = oocyteHeaders

  try {
    if (selectedTickets.value.length === 0) {
      router.back()
    } else {
      const ticketsId = selectedTickets.value
        .map((item) => {
          if (item.ticketId) {
            return item.ticketId
          }
          return item
        })
        .join()
      await fetchTicketsInfo(ticketsId)
    }
    selectedTicketsToPrint.value = [...selectedTickets.value]
    const page = Number(route.query.activeSlide) || 0
    if (page > 0 && page <= selectedTicketsToPrint.value.length) {
      handlePageChange(page)
    } else {
      ss.removeFieldSessionStorage('process', 'activeSlideSelectedTickets')
    }
  } catch (error) {
    console.error('Error', error)
  }
})

watch(selectedTickets, (newTickets) => {
  if (newTickets) {
    selectedTicketsToPrint.value = [...newTickets]
  }
})
</script>
