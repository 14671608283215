import toast from '@/config/toast'
import { MAX_PATIENTS, MAXIMUM_PATIENTS_ERROR } from '@/constants'
import { calculateSharedSpecimens, fetchPatientsWithFullName } from '@/modules/patient-group'

const updateTotalSharedIfNeeded = async (commit, state) => {
  if (state.patients.length) {
    commit('loading', true)
    const totalShared = await calculateSharedSpecimens(state.patients)
    commit('setTotalSharedCryoDevices', totalShared)
    commit('loading', false)
  }
}

const setPrimaryPatientIfOnePatient = (commit, state) => {
  const { patients } = state
  if (patients.length === 1) {
    const [firstPatient] = patients
    commit('setPrimaryPatientId', firstPatient.globalPatientNumber)
  }
}

const validatePatientsOnChange = async (commit, state) => {
  await updateTotalSharedIfNeeded(commit, state)
  setPrimaryPatientIfOnePatient(commit, state)
}

const patientExists = (state, patient) => {
  const { patients } = state
  if (patients.length === 0) return false

  const existingPatient = patients.find(
    (item) => item.globalPatientNumber === patient.globalPatientNumber
  )
  return Boolean(existingPatient)
}

const setAndValidatePatients = async (commit, state, patients) => {
  commit('setPatients', patients)
  await validatePatientsOnChange(commit, state)
}

const reachedMaximumPatients = (patients) => patients.length >= MAX_PATIENTS

export default {
  async addPatient({ commit, state }, patient) {
    if (patientExists(state, patient)) return
    if (reachedMaximumPatients(state.patients)) {
      toast.error({ title: MAXIMUM_PATIENTS_ERROR })
      return
    }
    commit('addPatient', patient)
    await validatePatientsOnChange(commit, state)
  },
  async fetchPatients({ commit, state }, patientIds) {
    commit('loading', true)
    const patients = await fetchPatientsWithFullName(patientIds)
    setAndValidatePatients(commit, state, patients)
    commit('loading', false)
  },
  async fetchPatientsWithCount({ commit, state }, patientIds) {
    commit('loading', true)
    const patients = await fetchPatientsWithFullName(patientIds, { includeSpecimenCount: true })
    setAndValidatePatients(commit, state, patients)
    commit('loading', false)
  },
  async removePatientFromGroup({ commit, state }, patientId) {
    commit('removePatient', patientId)
    await validatePatientsOnChange(commit, state)
  },
  async setPatients({ commit, state }, patients) {
    setAndValidatePatients(commit, state, patients)
  },
  async resetPatients({ commit }) {
    commit('setPatients', [])
  },
  async setPrimaryPatientId({ commit }, patientId) {
    commit('setPrimaryPatientId', patientId)
  }
}
