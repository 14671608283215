<template>
  <div
    class="flex flex-col relative"
    :data-test="`${inputId}-input-container`"
    :class="[
      alt && hasError ? 'alternative alt' : alt ? 'alt' : ''
    ]"
  >
    <label
      v-if="inputLabel"
      class="leading-tight text-base block text-left"
      :class="[
        `text-${labelColor}`,
        {
          'opacity-50': isDisabled,
        },
      ]"
      :data-test="`${inputId}-label`"
    >
      {{ inputLabel }}
    </label>
    <div class="w-full flex flex-col relative" :class="[{'light-bg': lightBackground }]">
      <input
        :data-test="`${inputId}-input-field`"
        autocomplete="off"
        :id="inputId"
        :key="inputId"
        class="border-b border-solid bg-transparent outline-none p-2"
        :class="[
          `border-${borderColor}`,
          `placeholder-${placeholderColor}`,
          addPaddingTop ? (hasLargePadding ? 'pt-2' : 'pt-1.5') : '',
          addPaddingBottom ? (hasLargePadding ? 'pb-2' : 'pb-1.5') : '',
          hasFontInconsolata ? 'font-inconsolata' : 'font-exo',
          {
            'opacity-50': !inputLabel && addOpacity,
            'mt-1.5': addMarginTop,
            'mb-1.5': addMarginBottom,
            'text-tmrw-blue': isBlueOnWhite,
            'border-tmrw-warning': hasError
          },
          leftIcon ? 'pl-8' : '',
          rightIcon || hasRightComponent ? 'pr-8' : '',
          inputClass,
          inputColor,
        ]"
        :ref="inputName"
        :type="inputType"
        :name="inputName"
        :maxlength="maxLength"
        :disabled="isDisabled"
        :placeholder="inputPlaceholder"
        v-model="content"
        :min="minNumber"
        :max="maxNumber"
        @keyup.enter="handleEnter"
        v-on:click="onClickCustomize"
        @focus="handleFocus()"
        @blur="handleBlur()"
        @input="handleInput"
      />
      <div v-if="hasIcon" class="absolute top-0 h-full flex items-center justify-center" :class="iconClass">
        <i class="text-tmrw-blue" :class="faIcon" />
      </div>
      <div v-if="hasRightComponent" class="absolute top-0 right-0" :class="[!!inputLabel ? 'mt-5' : '']">
        <slot name="right-component" />
      </div>
      <span
        v-if="!dontDisplayErrors"
        :class="[hasError ? 'block' : 'hidden', 'text-sm', 'mt-1', colorErrorText]"
        data-test="field__group__alert"
      >
        {{ hasError }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import generateElementId from '@/helpers/elementIdGenerator'
import { formatFAIcon } from '@/utils'

export default {
  data() {
    return {
      content: this.inputValue
    }
  },
  name: 'input-field',
  props: {
    inputType: {
      type: String,
      default: 'text',
      required: false
    },
    leftIcon: {
      type: String,
      default: undefined,
      required: false
    },
    rightIcon: {
      type: String,
      default: undefined,
      required: false
    },
    inputName: {
      type: String,
      default: '',
      required: false
    },
    inputColor: {
      type: String,
      default: 'text-black',
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    inputLabel: {
      type: String,
      required: false
    },
    hasError: {
      type: [String, Boolean, null],
      default: false,
      required: false
    },
    colorErrorText: {
      type: String,
      default: 'text-tmrw-error'
    },
    inputPlaceholder: {
      type: String,
      default: '',
      required: false
    },
    inputClass: {
      type: String,
      default: '',
      required: false
    },
    inputValue: {
      type: [Number, String],
      required: false
    },
    alt: {
      type: String,
      default: '',
      required: false
    },
    isSearchField: {
      type: Boolean,
      default: false,
      required: false
    },
    isTouched: {
      type: [Boolean, Function],
      default: false,
      required: false
    },
    isNumeric: {
      type: Boolean,
      default: false,
      required: false
    },
    dontDisplayErrors: {
      type: String,
      default: '',
      required: false
    },
    maxLength: {
      type: String,
      default: '255',
      required: false
    },
    borderColor: {
      type: String,
      default: 'white',
      required: false
    },
    placeholderColor: {
      type: String,
      default: 'white',
      required: false
    },
    labelColor: {
      type: String,
      default: 'white',
      required: false
    },
    isBlueOnWhite: {
      type: Boolean,
      default: false,
      required: false
    },
    showError: {
      type: Boolean,
      default: false,
      required: false
    },
    addPaddingTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addPaddingBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    hasLargePadding: {
      type: Boolean,
      default: false,
      required: false
    },
    hasFontInconsolata: {
      type: Boolean,
      default: false,
      required: false
    },
    clearValueOnClick: {
      type: Boolean,
      default: false,
      required: false
    },
    addOpacity: {
      type: Boolean,
      default: false,
      required: false
    },
    minNumber: {
      type: Number,
      required: false
    },
    maxNumber: {
      type: Number,
      required: false
    },
    lightBackground: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    hasRightComponent() {
      return this.$slots['right-component']
    },
    faIcon() {
      return formatFAIcon(this.rightIcon || this.leftIcon)
    },
    inputId() {
      const name = this.inputName && this.inputName.split(' ').join('-').toLowerCase()
      return generateElementId({
        route: this.$route,
        parent: this.$parent,
        type: 'input',
        name
      })
    },
    hasIcon() {
      return !this.hasRightComponent && (!!this.rightIcon || !!this.leftIcon)
    },
    iconClass() {
      return [
        this.rightIcon ? 'right-1' : 'left-2',
        this.hasError && '-mt-3'
      ]
    }
  },
  emits: ['handleSearch', 'update:modelValue', 'focus', 'blur', 'handleEnter'],
  methods: {
    handleFocus() {
      this.trackEvent('focus')
      this.$emit('focus')
    },
    handleBlur() {
      this.trackEvent('blur')
      this.$emit('blur')
    },
    handleInput({ target: { value } }) {
      if (this.isNumeric) {
        const validNumber = value.replace(/[^A-z\s\d][\\^]?/g, '')
        if (validNumber === '') return this.$emit('update:modelValue', '')
        const parsedNumber = Number(validNumber)
        const outsideOfValidRange = parsedNumber > this.maxNumber || parsedNumber < this.minNumber

        if (outsideOfValidRange || Number.isNaN(parsedNumber)) {
          this.content = this.minNumber
          if (!this.minNumber) return null
          return this.$emit('update:modelValue', this.minNumber)
        }
        this.content = parsedNumber
        return this.$emit('update:modelValue', parsedNumber)
      }
      this.content = value
      return this.$emit('update:modelValue', value)
    },
    handleEnter() {
      this.trackEvent('Intro Event')
      this.$emit('handleEnter')
    },
    onClickCustomize() {
      if (this.clearValueOnClick) this.content = ''
      return this.content
    },
    trackEvent(eventType) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(
        `Input Field ${eventType} at ${viewName}`,
        {
          component: 'Input Field',
          'event-type': eventType,
          'current-route': currentRoute,
          'view-name': viewName,
          'redirected-from': redirectedFrom,
          'clinic-name': this.appBaseConfig.clinicName,
          'site-name': this.appBaseConfig.siteName,
          'user-name': this.loggedUserInfo.loginName,
          'user-type': this.loggedUserInfo.userType,
          hostname
        }
      )
    }
  },
  watch: {
    inputValue(newValue) {
      this.content = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.light-bg input:not(.border-white):focus {
  @apply bg-tmrw-gray-light;
}
</style>
