<template>
  <div data-test="editable-table" class="p-4 bg-white rounded-bl-md rounded-br-md" :class="[{ 'rounded-tl-md rounded-tr-md': AddRoundedBordersTop }]">
    <table class="w-full border border-tmrw-blue table-fixed">
      <thead>
        <tr>
          <th
            v-for="(header, key) in headers"
            :data-test="getDataTestValue(header.name, true)"
            :key="key"
            :class="header.columnClass"
            class="text-tmrw-blue text-sm font-semibold py-2 px-4 text-left border-b border-solid border-tmrw-blue"
          >
            {{header.name}}
          </th>
        </tr>
      </thead>
      <tbody data-test="editable-table-table-body" :key="tbodyKey">
        <tr v-for="(item, itemIndex) in localItems" :key="itemIndex" class="border-collapse border-b border-solid border-tmrw-gray">
          <td
            v-for="(header, key) in headers"
            :data-test="getDataTestValue(header.name, false)"
            :key="key"
            :class="[header.columnClass]"
          >
            <div
              class="flex justify-start items-center py-2 px-4"
              :class="{
                'border-solid border-2 border-tmrw-error': fieldErrors && header.required
                  && !itemValue(item, header),
                'flex-col mt-7': shouldShowApplyAll(item, itemIndex, header),
              }"
            >
              <component
                v-if="header.component === 'ScreeningStatusLabel'"
                :is="'DataField'"
                :tab-key="tabKey(itemIndex, key)"
                value=""
                :edit="false"
                :key="`${itemIndex}-'ScreeningStatus'`"
              >
                <ScreeningStatusLabel
                  textColor="yellow"
                  iconColor="yellow"
                  :id="item[header.key]"
                />
              </component>
              <component
                v-else
                :is="header.component"
                :edit="header.edit"
                :size="header.componentSize || null"
                :block="header.block || false"
                :options="header.options || null"
                :alignContentToLeft="header.alignContentToLeft || false"
                :fieldClasses="header.fieldClasses || null"
                :scanMode="scanMode || null"
                :defaultValue="header.defaultValue || null"
                :value="itemValue(item, header)"
                :maxlength="header.maxlength"
                :maxValue="header.maxValue"
                :fieldType="header.fieldType"
                :itemKey="header.key"
                :SetTooltip="header.tooltip"
                :name="header.name"
                :key="itemIndex"
                :tab-key="tabKey(itemIndex, key)"
                :indexKey="itemIndex"
                :showCryodeviceValueOnly="showCryodeviceValueOnly"
                :dataTest="header.name"
                :item="item"
                @changeValue="(value, additionalValues) => handleFieldChange(itemIndex, value, header, additionalValues)"
              />
              <p
                v-if="shouldShowApplyAll(item, itemIndex, header)"
                class="
                  text-blue-800 text-sm underline
                  text-left w-full py-1
                  hover:cursor-pointer hover:text-tmrw-blue-dark"
                :data-test="`apply-all-${header.key}`"
                @click="handleApplyAll(header.key, item)"
              >
                Apply to all
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import ScanCryodevice from '@/components/ScanCryodevice/ScanCryodevice.vue'
import DataField from '@/components/EditableFields/DataField.vue'
import DataFieldModal from '@/components/EditableFields/DataFieldModal.vue'
import DateField from '@/components/EditableFields/DateField.vue'
import OptionsField from '@/components/EditableFields/OptionsField.vue'
import reprintLabelHeader from '@/components/reprintLabelHeader/reprintLabelHeader.vue'
import {
  CRYO_DATE,
  MATURITY_LEVEL,
  MATURITY_OPTIONS,
  SCAN_MODE_SCAN,
  SCAN_MODE_SHOW,
  SPECIMEN_COUNT,
  SPECIMEN_TYPE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE
} from '@/constants'

import {
  COUNT_X_TYPE_COLUMN,
  CRYO_DATE_COLUMN,
  EMBRYO_HEADER_COLUMNS,
  EGG_HEADER_COLUMNS,
  FREE_TEXT_COLUMN
} from '@/constants/editable-table-tickets'

import {
  MODULAR_LABEL_CURRENT_LABEL_ID,
  MODULAR_LABEL_EGG,
  MODULAR_LABEL_EMBRYO,
  MODULAR_LABEL_EMBRYO_TYPE,
  MODULAR_LABEL_EMBRYO_TYPES,
  MODULAR_LABEL_FREE_TEXT,
  MODULAR_LABEL_SITE_PROPERTY_EMBRYO,
  MODULAR_LABEL_SITE_PROPERTY_EGG,
  MODULAR_LABEL_PROPERTY_FIELD_SIZE
} from '@/constants/modular-cryolabel'

import * as ss from '@/config/session-storage-help'

export default {
  name: 'EditableTable',
  data() {
    return {
      applyAllColumnsHidden: [],
      applyedAllColumns: [],
      embryoTypes: [],
      localItems: [],
      maturityOptions: MATURITY_OPTIONS,
      tbodyKey: 0
    }
  },
  emits: ['changeValue'],
  props: {
    tabDirection: {
      type: String,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    specimenType: {
      type: String,
      required: false,
      default: SPECIMEN_TYPE_EMBRYO
    },
    edit: {
      type: Boolean,
      required: false,
      default: undefined
    },
    modalEdit: {
      type: Array,
      required: false,
      default: () => []
    },
    scanMode: {
      type: String,
      required: false,
      default: SCAN_MODE_SHOW,
      validator: (value) => [SCAN_MODE_SHOW, SCAN_MODE_SCAN].includes(value)
    },
    methodIsImport: {
      type: Boolean,
      required: false
    },
    AddRoundedBordersTop: {
      type: Boolean,
      default: false,
      required: false
    },
    fieldErrors: {
      type: Boolean,
      default: false,
      required: false
    },
    showCryodeviceValueOnly: {
      type: Boolean,
      default: false
    },
    isReprintVisible: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.setEmbryoTypes()
    this.localItems = this.items
    this.newTicketPersistence = ss.getFieldSessionStorage('newTicket')
  },
  methods: {
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    tabKey(rowIndex, columnIndex) {
      switch(this.tabDirection) {
        case 'column':
        case 'vertical':
          return columnIndex*100+rowIndex
        case 'row':
        case 'horizontal':
          return rowIndex*100+columnIndex
        default:
          return 0
      }
    },
    getDataTestValue(columnName, isHeader) {
      const dataTest = `${columnName.replace(/\s+/g, '-').toLowerCase()}-${isHeader ? 'header' : 'value'}`
      return dataTest
    },
    handleFieldChange(key, value, header, additionalValues) {
      const fieldName = header.key
      this.localItems = this.localItems.map((item, index) => {
        if (fieldName === CRYO_DATE) {
          if (value.applyToAllCryodevices) {
            return {
              ...this.localItems[index],
              [fieldName]: value.selectedDate
            }
          }
          if (index === key) {
            return {
              ...item,
              [fieldName]: value.selectedDate
            }
          }
        }
        if (fieldName === MATURITY_LEVEL) {
          if (index === key) {
            return {
              ...item,
              oocyte: {
                [fieldName]: value
              }
            }
          }
        }
        if (
          fieldName === 'embryoType'
          || fieldName === 'embryoNumber'
          || fieldName === 'biopsyResult'
          || fieldName === 'biopsy'
          || fieldName === 'embryoTypeLabel'
          || fieldName === 'grade'
        ) {
          if (index === key) {
            return {
              ...item,
              embryo: {
                ...item.embryo,
                [fieldName]: value
              }
            }
          }
        }
        if (index === key && fieldName !== CRYO_DATE) {
          if (additionalValues) {
            return {
              ...item,
              [fieldName]: value,
              ...additionalValues
            }
          }
          return {
            ...item,
            [fieldName]: value
          }
        }
        return { ...item }
      })

      if (key === 0 && header.applyAll === true) {
        const firstRowValue = this.localItems[0][this.headerType.toLowerCase()][fieldName]
        let hasDiff = false
        if (this.localItems.length > 1) {
          // eslint-disable-next-line no-unused-expressions
          this.localItems.slice(1)?.forEach((localItem) => {
            const rowValue = localItem[this.headerType.toLowerCase()][fieldName]
            if (rowValue !== firstRowValue) hasDiff = true
          })
          if (hasDiff) this.showApplyAll(header.key)
        }
      }
      this.$emit('changeValue', this.localItems)
    },
    setEmbryoTypes() {
      this.embryoTypes = ss.getFieldSessionStorage(MODULAR_LABEL_EMBRYO_TYPES)
        .map(({ id, name }) => ({ value: id, label: name }))
    },
    itemValue(item, header) {
      if(header.value) {
        return header.value(item)
      }
      return item[header.key]
    },
    applyedAll(header) {
      return this.applyedAllColumns.includes(header)
    },
    applyAllColumnVisibility(item, header) {
      const itemValue = this.itemValue(item, header)
      const hidden = this.applyAllColumnsHidden.includes(header.key)
      const notDefaultValue = (defaultValue, currentValue, key) => defaultValue !== currentValue || this.applyedAll(key)
      const shouldShow = itemValue && !hidden
        && notDefaultValue(header.defaultValue, itemValue, header.key)
      return shouldShow
    },
    shouldShowApplyAll(item, itemIndex, header) {
      // Validate bigger conditionals first to prevent unecessary processing
      if (!this.edit) return false
      if (this.localItems.length < 2) return false
      const hasApplyAll = header?.applyAll === true && itemIndex === 0
      if (!hasApplyAll) return false

      // Heavy validations only when necessary
      return this.applyAllColumnVisibility(item, header)
    },
    handleApplyAll(key, item) {
      const subObject = this.headerType.toLowerCase()
      this.localItems.forEach((localItem) => {
        const updatedItem = {
          ...localItem,
          [subObject]: {
            ...localItem[subObject],
            [key]: item[subObject][key]
          }
        }
        Object.assign(localItem, updatedItem)
      })

      this.applyedAllColumns.push(key)
      this.hideApplyAll(key)
      // re-render list
      this.tbodyKey += 1
      this.$emit('changeValue', this.localItems)
    },
    hideApplyAll(key) {
      if (!this.applyAllColumnsHidden.includes(key)) {
        this.applyAllColumnsHidden.push(key)
      }
    },
    showApplyAll(key) {
      if (this.applyAllColumnsHidden.includes(key)) {
        this.applyAllColumnsHidden = this.applyAllColumnsHidden.filter((column) => column !== key)
      }
    }
  },
  computed: {
    headerType() {
      return this.specimenType === SPECIMEN_TYPE_OOCYTE || this.specimenType === SPECIMEN_TYPE_EGG ? SPECIMEN_TYPE_EGG : SPECIMEN_TYPE_EMBRYO
    },
    isEmbryo() {
      return this.headerType === SPECIMEN_TYPE_EMBRYO
    },
    headers() {
      const addFreeTextIfEnabled = (headerColumns) => {
        let specimenType = MODULAR_LABEL_EGG
        let modularLabel = MODULAR_LABEL_SITE_PROPERTY_EGG
        if (this.isEmbryo) {
          specimenType = MODULAR_LABEL_EMBRYO
          modularLabel = MODULAR_LABEL_SITE_PROPERTY_EMBRYO
        }
        const labelData = ss.getFieldSessionStorage('siteProperties')[modularLabel]
        if (labelData.includes(MODULAR_LABEL_FREE_TEXT)) {
          const props = JSON.parse(labelData)
          const maxLength = props[specimenType]
            .flat()
            .find(
              ({ id }) => id === MODULAR_LABEL_FREE_TEXT
            )[MODULAR_LABEL_PROPERTY_FIELD_SIZE]
          headerColumns.push({ ...FREE_TEXT_COLUMN({ modalEdit: this.modalEdit }), maxLength })
        }

        return headerColumns
      }

      const columnsToHideOnVisualization = () => {
        if (this.isEmbryo) { return [MODULAR_LABEL_EMBRYO_TYPE] }

        return [SPECIMEN_TYPE, MATURITY_LEVEL]
      }

      const getBaseHeaders = () => {
        if (this.isEmbryo) {
          return EMBRYO_HEADER_COLUMNS({
            isImport: this.methodIsImport,
            isEdit: this.edit,
            embryoTypes: this.embryoTypes,
            currentTicketStep: this.isReprintVisible,
            modalEdit: this.modalEdit
          })
        }
        return EGG_HEADER_COLUMNS({
          isImport: this.methodIsImport,
          isEdit: this.edit,
          currentTicketStep: this.isReprintVisible,
          modalEdit: this.modalEdit
        })
      }

      const hideColumns = (headerColumns, columnsKeys) => (
        headerColumns.filter((column) => !columnsKeys.includes(column.key))
      )

      const mergeCountAndType = (headerColumns) => {
        const countXType = COUNT_X_TYPE_COLUMN
        return headerColumns.map((column) => (column.key === SPECIMEN_COUNT ? countXType : column))
      }

      let columns = getBaseHeaders()
      addFreeTextIfEnabled(columns)

      if (!this.edit) {
        const toHide = columnsToHideOnVisualization()
        columns = hideColumns(columns, toHide)
        columns = mergeCountAndType(columns)
      }

      const addCryoDateColumn = (headerColumns) => {
        const newList = headerColumns
        newList.splice(5, 0, {...CRYO_DATE_COLUMN, edit: this.edit})
        return newList
      }

      if (this.methodIsImport) {
        columns = addCryoDateColumn(columns)
      } else {
        columns = hideColumns(columns, [MODULAR_LABEL_CURRENT_LABEL_ID])
      }
      return columns
    }
  },
  components: {
    VueCustomScrollbar,
    ScanCryodevice,
    DataField,
    OptionsField,
    DateField,
    DataFieldModal,
    ScreeningStatusLabel,
    reprintLabelHeader
  }
}
</script>

<style lang="scss" scoped>
  .editable-table {
    display: grid;
    grid-template-rows: 38px auto;

    &__header {
      li {
        flex: 1 0 0;
      }
    }
  }
</style>
