<template>
  <vue-final-modal
    name="modal-acknowledge"
    height="auto"
    width="615"
    classes="modal--container"
    content-class="modal--content"
    :click-to-close="false"
    :esc-to-close="false"
  >
    <div data-test="acknowledge-modal" class="bg-white rounded-2xl h-100 p-8">
      <div class="flex justify-between align-center mb-8">
        <p data-test="acknowledge-title" class="text-xl text-tmrw-blue-dark leading-6 font-bold">
          {{ title }}
        </p>
      </div>
      <div class="flex align-center justify-end">
        <button-dynamic
          :btnText="buttonText"
          btnType="button"
          btnStyle="primary"
          data-test="acknowledge-done"
          @click="handleConfirm"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  components: {
    ButtonDynamic
  },
  name: 'acknowledge-modal',
  props: {
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  emits: ['confirmAcknowledge'],
  methods: {
    handleConfirm() {
      this.$emit('confirmAcknowledge')
    }
  }
}
</script>
