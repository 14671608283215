<template>
  <div
    v-if="items"
    class="beacon-box m-2 bg-tmrw-green-light rounded-md"
    :data-testid="`cryobeacon-box--${beaconCount + 1}`">
    <div class="flex">
      <div class="w-6/12">
        <p class="p-4 text-tmrw-blue font-semibold text-xl">{{CryoBeacon}} {{beaconCount + 1}}</p>
      </div>
      <div class="w-6/12 pt-2">
        <button-dynamic
          btnText="Customize"
          btnType="button"
          btnStyle="transparent-blue-no-border"
          showIcon
          fontAwesomeIconClass="edit"
          data-test="beacon-box__button-customize"
          @click="toggleCustomizeModal"
        />
        <button-dynamic
          v-if="beaconCount >= 1"
          btnType="button"
          btnText="Delete"
          btnStyle="transparent-blue-no-border"
          showIcon
          data-test="beacon-box__button-delete"
          fontAwesomeIconClass="trash"
          @click="deleteBeaconBox(beaconCount)"
        />
        <button-dynamic
          v-if="beaconCount === 0 && isResetVisible"
          btnType="button"
          btnText="Reset"
          btnStyle="transparent-blue-no-border"
          showIcon
          data-test="beacon-box__button-reset"
          fontAwesomeIconClass="redo"
          @click="resetCountBox"
        />
      </div>
    </div>
    <div class="flex flex-col justify-center items-center h-40">
      <div class="flex justify-center">
        <div
          v-for="(box, key) in localItems.slice(0, cryodevicesSliceAt)"
          :key="key"
          class="bg-tmrw-blue flex items-center justify-center p-4 text-white text-2xl mx-2 my-2 w-12 h-12 shadow rounded-md">
          {{box}}
        </div>
      </div>
      <div class="flex justify-center">
        <div
          v-for="(box, key) in localItems.slice(cryodevicesSliceAt)"
          :key="key"
          class="bg-tmrw-blue flex items-center justify-center p-4 text-white text-2xl mx-2 my-1 w-12 h-12 shadow rounded-md">
          {{box}}
        </div>
      </div>
    </div>
    <CustomizeBeaconsDistribution
      v-bind:items="localItems"
      v-if="showLabelModal"
      key="customizeBeaconDist"
      data-test="customize-beacon-distribution"
      :beaconCount="beaconCount"
      @applyCustomDistribution="applyCustomDistribution"
      @cancelCustomDistribution="cancelCustomDistribution"
      @addNewBeacon="addNewBeacon"
    />
  </div>
</template>

<script>
import CustomizeBeaconsDistribution from '@/components/CustomizeBeaconsDistribution/CustomizeBeaconsDistribution.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { CRYOBEACON } from '@/constants'
import * as ss from '@/config/session-storage-help'

export default {
  name: 'beacon-box',
  data() {
    return {
      showLabelModal: false,
      customized: this.customSpecimensDistribution,
      CryoBeacon: CRYOBEACON,
      maxCryodevicesPerBeacon: 0,
      cryodevicesSliceAt: 0
    }
  },
  emits: [
    'aaplyCustomState',
    'updateBeacons',
    'updateDistribution',
    'resetCount',
    'applyCustomState',
    'addNewbeacon',
    'setCustom',
    'deleteBeacon'
  ],
  props: {
    items: {
      type: Array,
      required: false
    },
    beaconCount: {
      type: Number,
      required: false
    },
    customSpecimensDistribution: {
      type: Boolean,
      required: false
    },
    resetCount: {
      type: Function,
      required: false
    },
    isResetVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    this.maxCryodevicesPerBeacon = ss.getFieldSessionStorage('siteProperties')['ticket.cryodevice.max-number']
    this.cryodevicesSliceAt = Math.ceil(this.maxCryodevicesPerBeacon / 2)
  },
  methods: {
    toggleCustomizeModal() {
      this.showLabelModal = !this.showLabelModal
    },
    applyCustomDistribution() {
      this.isCustomized = true
      this.toggleCustomizeModal()
      this.$emit('applyCustomState')
      this.$emit('updateBeacons')
    },
    resetCountBox() {
      this.customized = false
      this.$emit('updateDistribution', [[1]])
      this.$emit('resetCount')
    },
    cancelCustomDistribution(initialItems) {
      // TODO: this was failing and the props are being mutated everywhere, including the array's content, needs thorough rework and refactor
      initialItems.forEach((item, index) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.items[index] = item
      })
      if (this.items.length > 1) {
        this.isCustomized = true
      } else {
        this.isCustomized = false
      }
      this.toggleCustomizeModal()
    },
    addNewBeacon() {
      this.isCustomized = true
      this.$emit('applyCustomState')
      this.toggleCustomizeModal()
      this.$emit('addNewbeacon')
      this.$emit('setCustom')
    },
    deleteBeaconBox(key) {
      this.$emit('deleteBeacon', key)
    }
  },
  computed: {
    localItems() {
      if (this.showLabelModal) {
        if (this.items.length < this.maxCryodevicesPerBeacon) {
          const arr = this.items
          for (let i = this.items.length; arr.length < this.maxCryodevicesPerBeacon; i++) {
            arr.push(0)
          }
          return arr
        }
      } else {
        return this.items.filter((el) => el !== 0)
      }
      return this.items
    }
  },
  components: {
    CustomizeBeaconsDistribution,
    ButtonDynamic
  }
}
</script>
