<template>
  <!-- Show Cryodevice Value only if showCryodeviceValueOnly prop is set to true on component -->
  <span
    v-if="showCryodeviceValueOnly"
    data-test="scan-cryodevice__cryodevice-detail-id-label"
    class="flex items-center justify-start py-1.5 bg-white text-tmrw-blue border-b border-solid w-full leading-5"
  >
    {{ formattedValue(value.cryodeviceBarcode) }}
  </span>
  <!-- Otherwise show Scan Label UI  -->
  <div v-else>
    <button-dynamic
      v-if="!isScannedLabelValid"
      data-test="scan-cryodevice__show-button"
      :btnText="!showLabelError ? scanLabel : scanAgain"
      btnType="button"
      :btnStyle="!showLabelError ? 'primary' : 'error-button'"
      showIcon
      class="w-full"
      :font-awesome-icon-class="buttonIcon"
      :add-classes="buttonClasses"
      :add-icon-classes="buttonIconClasses"
      @click="openCryodeviceScanModal"
    />
    <span
      data-test="scan-cryodevice__cryodevice-detail-id-scanned"
      v-if="isScannedLabelValid"
      class="flex items-center justify-start py-1.5 bg-white text-tmrw-blue w-full leading-5 pt-4 font-semibold"
      :class="validScanClass"
    >
      <i
        class="text-lg fas fa-check-circle text-tmrw-green mr-2"
        :class="validScanIconClass"
        data-testid="scan-cryodevice__cryodevice-valid-id-icon"
      />
      {{ formattedValue(scannedCryodeviceValue) }}
    </span>
    <div
      v-if="isCryodeviceScanModalOpen"
      data-test="scan-cryodevice__modal"
      class="bg-black bg-opacity-50 rounded-md flex items-center justify-center w-screen h-screen fixed z-50 top-0 left-0"
    >
      <div
        data-test="scan-cryodevice__modal-wrapper"
        class="p-4 bg-white rounded-md w-4/12"
        @keydown.esc="closeCryodeviceScanModal"
        @keydown.enter="validateCryodeviceLabel"
      >
        <ActionBar data-test="scan-cryodevice__action-bar" colsDistribution="10/2">
          <template v-slot:left-side>
            <dynamic-title titleType="h3-blue" floatTo="left">{{
              scanCryodeviceTitle
            }}</dynamic-title>
          </template>
          <template v-slot:right-side>
            <button-dynamic
              btnText=""
              btnType="button"
              btnStyle="close-button-blue"
              data-test="scan-cryodevice__close-button"
              showIcon
              fontAwesomeIconClass="times-circle"
              @click="closeCryodeviceScanModal"
            />
          </template>
        </ActionBar>
        <input-field
          :inputPlaceholder="scanCryodevicePlaceholder"
          inputName="codeType"
          placeholderColor="black"
          labelColor="tmrw-blue"
          borderColor="tmrw-blue"
          ref="cryobeaconValueInput"
          v-model="scannedCryodeviceValue"
          max-length="60"
          addPaddingTop
          addPaddingBottom
          addMarginTop
          addMarginBottom
        />
        <div class="flex items-center justify-end mt-4">
          <button-dynamic
            btn-type="button"
            btn-text="Cancel"
            btn-style="transparent-blue"
            data-test="scan-cryodevice__cancel-button"
            add-margin-right
            @click="closeCryodeviceScanModal"
          />
          <button-dynamic
            data-test="scan-cryodevice__confirm-button"
            btn-type="submit"
            btn-text="Confirm"
            btn-style="primary"
            :is-loading="loading"
            :add-classes="['submit-scan-btn']"
            :is-disabled="!isCryodeviceValueCorrect"
            @click="validateCryodeviceLabel"
          />
        </div>
      </div>
    </div>
    <ModalWindow
      modal-title="CryoLabel not unique"
      title-type="h2-blue-dark"
      cols-distribution="10/2"
      :isModalOpen="showUniqueModalError"
      is-bold
      is-error
      @closeModalWindow="closeUniqueModalWindow"
    >
      <p
        class="my-8 text-tmrw-blue text-2xl">
        A CryoLabel with this ID already exists.<br>
        Use a unique Cryolabel ID to continue.
      </p>
      <button-dynamic
        btnText="Ok"
        btnType="button"
        btnStyle="primary"
        @click="closeUniqueModalWindow"
      />
    </ModalWindow>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import InputField from '@/components/InputField/InputField.vue'

import { trimString } from '@/utils'

import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import {
  SCAN_LABEL,
  SCAN_CRYODEVICE_PLACEHOLDER,
  SCAN_CRYODEVICE_TITLE,
  CRYODEVICE,
  SCAN_AGAIN,
  MAX_LABEL_LENGTH
} from '@/constants'

import { useTicket } from '@/composables'
import { mapGetters } from 'vuex'
import { getCheckBeaconIdApi } from '@/services/beacons'
import { getBeaconData } from '@/helpers/reprintLabel/reprintLabel'

export default {
  name: 'scan-cryodevice',
  setup() {
    const { isEMRTicket } = useTicket()
    return {
      isEMRTicket
    }
  },
  data() {
    return {
      isCryodeviceScanModalOpen: false,
      scannedCryodeviceValue: '',
      cryodevice: CRYODEVICE,
      scanCryodevicePlaceholder: SCAN_CRYODEVICE_PLACEHOLDER,
      scanCryodeviceTitle: SCAN_CRYODEVICE_TITLE,
      isScannedLabelValid: false,
      showLabelError: false,
      loading: false,
      showUniqueModalError: false
    }
  },
  emits: ['changeValue'],
  props: {
    indexKey: {
      type: Number,
      required: false,
      default: undefined
    },
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    skipCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    showCryodeviceValueOnly: {
      type: Boolean,
      default: false
    },
    scanIcon: {
      type: String,
      required: false,
      default: 'qrcode'
    },
    scanLabel: {
      type: String,
      required: false,
      default: SCAN_LABEL
    },
    scanAgain: {
      type: String,
      required: false,
      default: SCAN_AGAIN
    },
    validScanClass: {
      type: Array,
      required: false,
      default: () => ['font-inconsolata']
    },
    validScanIconClass: {
      type: Array,
      required: false,
      default: () => []
    },
    buttonClasses: {
      type: Array,
      required: false,
      default: () => []
    },
    buttonIconClasses: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted() {
    if(this.value?.cryodeviceBarcodeValidation) {
      this.isScannedLabelValid = true
      this.scannedCryodeviceValue = this.value.cryodeviceBarcode
    }
  },
  methods: {
    openCryodeviceScanModal() {
      this.scannedCryodeviceValue = ''
      this.isCryodeviceScanModalOpen = true
      setTimeout(() => {
        // TODO: configure eslint to not throw errors on optional chaining
        /* eslint-disable-next-line */
        this.$refs.cryobeaconValueInput?.$el?.children[0].children[0].focus()
      }, 0)
    },
    closeCryodeviceScanModal() {
      this.isCryodeviceScanModalOpen = false
    },
    closeUniqueModalWindow() {
      this.showUniqueModalError = false
    },
    async checkUnique() {
      const { activeBeacon } = getBeaconData({
        indexKey: this.indexKey,
        item: this.item,
        isEMRTicket: this.isEMRTicket
      })
      const cryodeviceIds = activeBeacon.cryoDevice.map(({ cryodeviceBarcode }) => cryodeviceBarcode)
      if(cryodeviceIds.includes(this.scannedCryodeviceValue)) {
        return false
      }
      try {
        const { collectionProtocolId } = this.appBaseConfig
        const response = await getCheckBeaconIdApi({ containerType: 'Cryodevice', cpId: collectionProtocolId, barcode: this.scannedCryodeviceValue, extraParams: { isExternalCryolabel: true }})
        return response.data.status !== 'INVALID'
      } catch(e) {
        // eslint-disable-next-line no-console
        console.log('ERROR', e)
      }
      return false
    },
    async validateCryodeviceLabel() {
      const { metadata, cryodeviceBarcode } = this.value
      this.loading = true
      let isUnique = true
      if(metadata?.externalCryolabel && !this.skipCheck) {
        if(this.isCryodeviceValueCorrect) {
          isUnique = await this.checkUnique()
          this.isScannedLabelValid = isUnique
        } else {
          this.isScannedLabelValid = false
        }
      } else {
        this.isScannedLabelValid =
        cryodeviceBarcode === this.scannedCryodeviceValue && !!this.scannedCryodeviceValue.length
      }
      
      this.loading = false
      this.isCryodeviceScanModalOpen = false
      this.showLabelError = !this.isScannedLabelValid
      this.showUniqueModalError = !isUnique
      if(this.isScannedLabelValid) {
          this.$emit(
            'changeValue',
            this.scannedCryodeviceValue,
            {
              cryodeviceBarcodeValidation: this.isScannedLabelValid
            }
        )
      }
      
    }
    ,
    formattedValue(string) {
      return trimString(string, MAX_LABEL_LENGTH, 'start')
    }
  },
  computed: {
    ...mapGetters('authModule', ['appBaseConfig']),
    buttonIcon() {
      return !this.showLabelError ? this.scanIcon : 'exclamation-circle'
    },
    isCryodeviceValueCorrect() {
      const { metadata } = this.value
      if(this.skipCheck) {
        return !!this.scannedCryodeviceValue.length
      } else if(metadata?.externalCryolabel) {
        return this.scannedCryodeviceValue.length && /^[a-zA-Z0-9 ()@-]+$/i.test(this.scannedCryodeviceValue)
      } else {
        return this.scannedCryodeviceValue.length === 11
      }
    }
  },
  components: {
    ButtonDynamic,
    ActionBar,
    DynamicTitle,
    InputField,
    ModalWindow
  }
}
</script>
