<template>
  <div data-test="select-patient__search-field" class="flex justify-center flex-col w-full">
    <div data-test="radio-button-search-box" class="w-96">
      <span class="w-72">
        <input
          class="pl-1 w-4 h-4"
          type="radio"
          id="patient_by_id"
          name="patient_by_id"
          value="identificationNumber"
          v-model="searchPatientsBy"
          @change="resetSearchValue"
          data-test="select-patient__radio-by-id"
        >
        <label data-test="search-by-id-label" class="w-28 pl-2 text-xl font-bold" :class="isBlueOnWhite ? 'text-tmrw-blue' : 'text-white'" for="patient_by_id">Search by ID</label>
      </span>
      <span class="w-72 pl-4">
        <input
          class="pl-1 w-4 h-4"
          type="radio"
          id="patient_by_lastname"
          name="patient_by_lastname"
          value="lastName"
          v-model="searchPatientsBy"
          @change="resetSearchValue"
          data-test="select-patient__radio-by-lastname"
        >
        <label data-test="search-by-lastname-label" class="w-28 pl-2 text-xl font-bold" :class="isBlueOnWhite ? 'text-tmrw-blue' : 'text-white'" for="patient_by_lastname">Search by Last Name</label>
      </span>
    </div>
    <div class="h-16 pt-6">
      <div v-if="searchPatientsBy" class="flex flex-col mb-0 w-full">
        <div class="flex justify-items-stretch">
          <input-field
            data-test="select-patient__search-input"
            :class="[
              width || 'w-80',
            ]"
            :inputType="type"
            :inputName="name"
            :inputColor="inputColor"
            v-model="patientValueToSearch"
            :inputValue="patientValueToSearch"
            :inputPlaceholder="searchFieldPlaceholder"
            :placeholderColor="isBlueOnWhite ? 'tmrw-blue' : 'tmrw-green-light'"
            :borderColor="borderColor"
            :isBlueOnWhite="isBlueOnWhite"
            :showError="hasError"
            inputClass="!text-tmrw-blue"
            addPaddingBottom
            @update:modelValue="handleInput"
            @handleEnter="handlePatientsSearch"
          >
            <template #right-component>
              <button-dynamic
                data-testid="select-patient__search-button"
                class="border-none -mr-6"
                btnType="button"
                btnText=""
                btnStyle="transparent-white-search"
                showIcon
                fontAwesomeIconClass="search"
                :hasRoundBorders="false"
                :addIconClasses="['text-tmrw-blue-dark']"
                :addClasses="[
                  isBlueOnWhite && !hasError ? 'border-tmrw-blue text-tmrw-blue' : '',
                  hasError ? 'border-tmrw-error text-tmrw-blue' : '',
                ]"
                @click="handlePatientsSearch"
              />
            </template>
          </input-field>
        </div>
        <p
          class="search-field__group__alert text-tmrw-error"
          v-if="hasError"
          data-test="search-field__group__error">{{patientNotFoundErrorMessage}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import InputField from '@/components/InputField/InputField.vue'
import { PATIENT_NOT_FOUND_ERROR } from '@/constants'

export default {
  data() {
    return {
      patientValueToSearch: '',
      isSearchFieldVisible: false,
      searchPatientsBy: 'identificationNumber',
      patientNotFoundErrorMessage: PATIENT_NOT_FOUND_ERROR
    }
  },
  emits: ['handlePatientsSearch', 'update:modelValue', 'resetSearchValue'],
  name: 'search-field',
  props: {
    type: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    hasError: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    },
    width: {
      type: Boolean,
      required: false
    },
    isBlueOnWhite: {
      type: Boolean,
      default: false,
      required: false
    },
    inputColor: {
      type: String,
      default: 'text-black',
      required: false
    }
  },
  methods: {
    handlePatientsSearch() {
      this.$emit('handlePatientsSearch', this.searchPatientsBy, this.patientValueToSearch)
    },
    handleInput() {
      this.$emit('update:modelValue', this.patientValueToSearch)
    },
    resetSearchValue() {
      this.patientValueToSearch = ''
      this.$emit('resetSearchValue')
    }
  },
  computed: {
    borderColor() {
      // eslint-disable-next-line no-nested-ternary
      return this.hasError ? 'tmrw-error' : this.isBlueOnWhite ? 'tmrw-blue' : 'tmrw-green-light'
    },
    searchFieldPlaceholder() {
      // eslint-disable-next-line no-nested-ternary
      return this.searchPatientsBy === 'identificationNumber' ? 'Patient ID *' : 'Patient Last Name *'
    }
  },
  components: {
    ButtonDynamic,
    InputField
  }
}
</script>
