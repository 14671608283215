<template>
  <MainContainer data-test="patient-list" gridSlots="patients">
    <loading-ui v-if="isLoadingPatients" message="Searching Patients" modal />
    <top-header :back-btn="false">Add new patients or Edit existing patients</top-header>
    <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>&nbsp;</template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="View Selected"
          btnStyle="primary"
          addMarginLeft
          :isDisabled="!selectedPatient.length"
          @click="viewSelectedPatient"
        />
      </template>
    </ActionBar>
    <div class="bg-white rounded-lg p-6 h-36">
      <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
        <template v-slot:left-side>
          <div class="flex flex-col items-center justify-center">
            <patients-search-field
              type="text"
              name="patient-search-box"
              inputColor="text-white"
              :hasError="shouldDisplaySearchErrorMessage"
              isBlueOnWhite
              addOpacity
              @handlePatientsSearch="searchPatientList"
            />
          </div>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnType="button"
            btnText="Create Patient"
            btnStyle="secondary"
            addMarginLeft
            showIcon
            fontAwesomeIconClass="plus-circle"
            :isDisabled="!allowCreatePatients"
            @click="handleCreatePatient"
          />
          <button-dynamic
            btnType="button"
            btnText="Import Patients"
            btnStyle="secondary"
            addMarginLeft
            showIcon
            fontAwesomeIconClass="plus-circle"
            :isDisabled="!allowCreatePatients"
            @click="openModalBulk"
          />
        </template>
      </ActionBar>
    </div>
    <div>
      <ScrollTableComponent
        v-if="arePatientsFound && isPatientSearchSubmitted"
        minHeight="h-full"
        v-model="patientsModel"
        :headers="tableHeaderPatients"
        :selectionMode="'radio'"
      />
    </div>
    <div class="absolute z-20">
      <modal-new-patient :createPatient="createPatient" />
      <modal-bulk :type="bulkType" :show="showModalBulk" @closeModal="closeModalBulk" />
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ModalBulk from '@/components/ModalBulk/ModalBulk.vue'
import PatientsSearchField from '@/components/SearchField/PatientsSearchField.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import ModalNewPatient from '@/views/ModalNewPatient/ModalNewPatient.vue'
import { PATIENT_NOT_FOUND_ERROR } from '@/constants'
import toast from '@/config/toast'
import { tableHeaderPatients } from '@/constants/table-headers/patient'
import { ALPHANUM_DASHES, ALPHANUM_SPECIAL_CHARACTERS } from '@/constants/regular-expressions'

export default {
  name: 'patient-list',
  data() {
    return {
      patientsModel: {
        sort: {
          orderBy: 'globalPatientNumber',
          direction: 'desc'
        },
        options: [],
        hasLoaded: false
      },
      selectedPatient: [],
      bulkType: 'patients',
      allowCreatePatients: this.$isSiteEmrEnabled?.allowCreatePatients,
      isLoadingPatients: false,
      isPatientSearchSubmitted: false,
      patientNotFoundErrorMessage: PATIENT_NOT_FOUND_ERROR,
      tableHeaderPatients,
      hasInvalidSearchParam: false,
      showModalBulk: false
    }
  },
  methods: {
    ...mapActions('patientsModule', ['searchPatients', 'fetchPatients', 'setPatientTimestamp']),
    ...mapActions('createPatientModule', ['createPatient']),
    viewSelectedPatient() {
      this.$router.push(`/patients/${this.selectedPatient[0].globalPatientNumber}`)
    },
    handleTableChange(selectedPatient) {
      this.selectedPatient = selectedPatient
    },
    openModalBulk() {
      this.showModalBulk = true
    },
    closeModalBulk() {
      this.showModalBulk = false
    },
    handleCreatePatient() {
      this.$router.push('/patients/create')
    },
    async searchPatientList(searchPatientBy, searchPatientValue) {
      try {
        const { sort } = this.patientsModel
        let options = []
        // Clear Patients List
        this.patientsModel = {
          sort,
          options,
          hasLoaded: false
        }
        // Show loader
        this.isLoadingPatients = true
        // Set search submitted as false before executing search.
        this.isPatientSearchSubmitted = false

        let params
        switch (searchPatientBy) {
          // Search by Patient Identification Number
          case 'identificationNumber':
            params = { identificationNumber: searchPatientValue }
            break
          // Search by Patient Last Name
          case 'lastName':
            params = { lastName: searchPatientValue }
            break
          default:
            params = { identificationNumber: searchPatientValue }
        }

        const regex = params.lastName ? ALPHANUM_SPECIAL_CHARACTERS : ALPHANUM_DASHES
        this.hasInvalidSearchParam = !(regex).test(searchPatientValue)
        if (this.hasInvalidSearchParam) {
          return
        }

        const getPatients = await this.searchPatients(params)
        if (getPatients.data.length) {
          // Set new array for Patients List
          const patientsList = []
          // Loop through patients object to add Full Name of patient.
          getPatients.data.forEach((patient) => {
            if(patient.external) {
              return
            }
            const { firstName, lastName } = patient
            const fullName = `${firstName} ${lastName}`
            // eslint-disable-next-line no-param-reassign
            patient.fullName = fullName
            patientsList.push(patient)
          })

          options = patientsList
          this.patientsModel = {
            sort,
            options,
            hasLoaded: true
          }
        }
      } catch (err) {
        toast.custom('error', 'error', err)
      } finally {
        // Remove loader from UI.
        this.isLoadingPatients = false
        // Set search submitted as true after completing search and array composition.
        this.isPatientSearchSubmitted = true
      }
    }
  },
  computed: {
    ...mapGetters('patientsModule', ['patients', 'patient', 'patientTimestamp']),
    arePatientsFound() {
      return !!this.patientsModel.options.length
    },
    shouldDisplaySearchErrorMessage() {
      return this.isPatientSearchSubmitted && (!this.arePatientsFound || this.hasInvalidSearchParam)
    }
  },
  watch: {
    patientsModel(newValue, oldValue) {
      if (newValue.options !== oldValue.options) {
        const selectedPatient = this.patientsModel.options.filter((option) => option.selected)
        this.selectedPatient = selectedPatient || []
      }
    }
  },
  components: {
    TopHeader,
    ModalNewPatient,
    ModalBulk,
    PatientsSearchField,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    LoadingUi,
    ScrollTableComponent
  }
}
</script>
