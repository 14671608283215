import { Components } from '@/types/searchBeacons'
import { CryodateRanges, ISearchCriteria, SearchBeaconTypeFilter } from '@/types/massCreate'
import {
  CRYOROBOT,
  EXCLUDE,
  EXCLUDE_LOWER,
  INCLUDE,
  LAYOUT_B3C,
  LAYOUT_CRS,
  LAYOUT_WAVE,
  TEMPORARY,
  TEMPORARY_STORAGE
} from '@/constants'
import { Destination } from '@/types'

const separateModeValues = <T = string | CryodateRanges>(criteria: ISearchCriteria[]) => {
  const includeValues: Array<T> = [], // Initialize as an array
    excludeValues: Array<T> = [] // Initialize as an array
  for (const item of criteria) {
    if (item.mode === EXCLUDE_LOWER) {
      excludeValues.push(item.data as T)
    } else {
      includeValues.push(item.data as T)
    }
  }

  return { includeValues, excludeValues }
}

const getValuesByKey = (criteria: ISearchCriteria[], key: SearchBeaconTypeFilter) => {
  return criteria.filter((item) => item.key === key)
}

const getValuesByPatientId = (
  criteria: ISearchCriteria[]
): Components.Schemas.ByPatientIdsRequest[] | undefined => {
  const valuesByKey = getValuesByKey(criteria, 'byPatientIds') as ISearchCriteria[]

  if (valuesByKey.length === 0) return undefined

  const { includeValues, excludeValues } = separateModeValues(valuesByKey) as {
    includeValues: string[]
    excludeValues: string[]
  }

  return [
    {
      ids: includeValues,
      mode: INCLUDE.toUpperCase() as Components.Schemas.Mode
    },
    {
      ids: excludeValues,
      mode: EXCLUDE.toUpperCase() as Components.Schemas.Mode
    }
  ]
}

const getValuesByLastName = (
  criteria: ISearchCriteria[]
): Components.Schemas.ByLastNamesRequest[] | undefined => {
  const valuesByKey = getValuesByKey(criteria, 'byLastNames') as ISearchCriteria[]

  if (valuesByKey.length === 0) return undefined

  const { includeValues, excludeValues } = separateModeValues(valuesByKey) as {
    includeValues: string[]
    excludeValues: string[]
  }

  return [
    {
      lastNames: includeValues,
      mode: INCLUDE.toUpperCase() as Components.Schemas.Mode
    },
    {
      lastNames: excludeValues,
      mode: EXCLUDE.toUpperCase() as Components.Schemas.Mode
    }
  ]
}

const getValuesByLocation = (
  criteria: ISearchCriteria[]
): Components.Schemas.ByLocationRequest | undefined => {
  const valuesByKey = getValuesByKey(criteria, 'byLocation') as ISearchCriteria[]

  return (valuesByKey[0]?.data as Components.Schemas.ByLocationRequest) || undefined
}

const getValuesByCryodates = (
  criteria: ISearchCriteria[]
): Components.Schemas.ByCryoDatesRequest[] | undefined => {
  const valuesByKey = getValuesByKey(criteria, 'byCryoDates') as ISearchCriteria[]

  if (valuesByKey.length === 0) return undefined

  const { includeValues, excludeValues } = separateModeValues(valuesByKey) as {
    includeValues: CryodateRanges[]
    excludeValues: CryodateRanges[]
  }

  return [
    {
      ranges: includeValues,
      mode: INCLUDE.toUpperCase() as Components.Schemas.Mode
    },
    {
      ranges: excludeValues,
      mode: EXCLUDE.toUpperCase() as Components.Schemas.Mode
    }
  ]
}

const getValuesBySpecimenType = (
  criteria: ISearchCriteria[]
): Components.Schemas.BySpecimenTypeRequest[] | undefined => {
  const valuesByKey = getValuesByKey(criteria, 'bySpecimenTypes') as ISearchCriteria[]

  if (valuesByKey.length === 0) return undefined

  const { includeValues, excludeValues } = separateModeValues(valuesByKey) as {
    includeValues: { type: Components.Schemas.SpecimenType }[]
    excludeValues: { type: Components.Schemas.SpecimenType }[]
  }
  const specimenTypes: Array<Components.Schemas.BySpecimenTypeRequest> = []

  if (includeValues.length > 0) {
    specimenTypes.push({
      type: includeValues[0]?.type,
      mode: INCLUDE.toUpperCase() as Components.Schemas.Mode,
      criteria: []
    })
  }

  if (excludeValues.length > 0) {
    specimenTypes.push({
      type: excludeValues[0]?.type,
      mode: EXCLUDE.toUpperCase() as Components.Schemas.Mode,
      criteria: []
    })
  }

  return specimenTypes.length > 0 ? specimenTypes : undefined
}

export const convertObjectToApiRequest = (
  criteria: ISearchCriteria[],
  sourceSiteId: number
): Components.Schemas.SearchBeaconsRequest => {
  return {
    byPatientIds: getValuesByPatientId(criteria),
    byLastNames: getValuesByLastName(criteria),
    bySpecimenTypes: getValuesBySpecimenType(criteria),
    byLocation: getValuesByLocation(criteria),
    byCryoDates: getValuesByCryodates(criteria),
    sourceSiteId
  }
}

export const getContainerId = (allSites, siteId, cryorobotId = null) => {
  if (cryorobotId) return cryorobotId
  const site = allSites.find((site) => site.value === siteId)
  return site?.unitIds[0] || ''
}

export const getCurrentLocation = (beacon, allContainers) => {
  const containerLocation = allContainers.find(
    (container) => container.containerId === beacon.specimens[0].unitId
  )

  if (containerLocation?.robotNumber === TEMPORARY) {
    return TEMPORARY_STORAGE
  }
  return containerLocation?.robotName ?? `${CRYOROBOT} Not Found`
}

export const getUnitType = (robotLayoutTypeId: number) => {
  const typesOfRobots = {
    [LAYOUT_B3C]: 'B3C',
    [LAYOUT_CRS]: 'CRS',
    [LAYOUT_WAVE]: 'NON_TMRW'
  }

  return typesOfRobots[robotLayoutTypeId] || ''
}

export const getRobotsAvailable = (allContainers: Destination[], siteId: number) => {
  const containers = allContainers.filter((container) => container.siteId === siteId)
  return containers
}
