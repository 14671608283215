import { google, Loader } from 'google-maps'
import { GOOGLE_MAPS_KEY } from '@/constants'
const options = {
  libraries: ['places']
}
let googleInstance: Promise<google>

export const getGoogleInstance = () => {
  if (!googleInstance) {
    const loader = new Loader(GOOGLE_MAPS_KEY, options)
    googleInstance = loader.load()
  }
  return googleInstance
}
