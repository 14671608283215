<template>
  <div class="forgot-pass" data-test="forgot-password">
    <tmrw-logo @onClickAppLogo="showAppInfoModal" class="cursor-pointer pb-4" />
    <h1 data-test="forgot-pass-subtitle" class="text-tmrw-green-light text-center">SPECIMEN MANAGEMENT SYSTEM</h1>
    <div v-if="!processingLoginSuccess" data-test="forgot-password__form-section">
      <h2 class="text-white m-0 text-3.5xl mt-10 text-center font-semibold">Forgot your Password?</h2>
      <input-field
        btn-type="text"
        inputName="username"
        :inputLabel="state.username ? 'User Name' : ''"
        v-model="state.username"
        :isTouched="isUserNameTouched"
        inputPlaceholder="User Name"
        placeholderColor="tmrw-green-light"
        labelColor="tmrw-green-light"
        textColor="text-white"
        inputColor="text-white"
        :hasError="checkError.username || serverError"
        class="w-full text-xl mt-20"
      />
      <button-dynamic
        btnText="Reset Password"
        btnStyle="primary"
        :addClasses="['text-2xl', 'py-2', 'px-6', 'mt-10', 'mx-auto', 'h-12']"
        float-to="none"
        :isDisabled="isDisabled"
        :isLoading="processingLogin"
        :btnStyleClasses="['warning', 'bold']"
        @click="handleSubmit"
      />
    </div>
    <p v-else class="text-white text-center text-2xl mt-10" data-test="forgot-password__check-section">
      Please check your email<br />
      for a message from TMRW<br />
      to reset your Password.
    </p>
    <button-dynamic
      btnType="button"
      btnText="Back to Sign In"
      btnStyle="transparent"
      :addClasses="['mt-20', 'w-full']"
      isTextUnderlined
      @click="onClickBackLogin"
      isBgTransparent
    />
    <p class="text-tmrw-green-light text-center font-semibold text-base leading-7 mt-8" data-test="forgot-pass-contact-message">
      Forgot your User Name?<br />
      Please contact your clinic administrator.
    </p>
  </div>
</template>

<script setup lang="ts">

import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import InputField from '@/components/InputField/InputField.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { computed, reactive, ref, watch } from 'vue'
import useActions from '@/composables/useActions'
import { UsersModule } from '@/store/modules/users'
import { Router, useRouter } from 'vue-router'


export interface IForgotPassword {
  submited: boolean
  processingLogin: boolean
  state: {
    username: string
  }
  isDisabled: boolean
  handleSubmit: () => void
  onClickBackLogin: () => void
  router: Router
}


const router = useRouter()

const submited = ref(false)
const processingLogin = ref(false)
const processingLoginSuccess = ref(false)
const serverError = ref(null)
const rules = computed(() => ({
  username: {
    required
  }
}))

const state = reactive({
  username: ''
})
const v$ = useVuelidate(rules, state)

const { forgotPassword } = useActions<UsersModule['actions']>('usersModule')
// TODO: add type definition to authModule
const { toggleAppInfoModal } = useActions('appInfoModule')

const onClickBackLogin = () => {
  router.push('/')
}
const handleSubmit = async() => {
  submited.value = true
  processingLogin.value = true
  if (state.username) {
    try {
      await forgotPassword({ loginNameOrEmail: state.username })
    } catch(error) {
      // Empty
    }
  }
  processingLoginSuccess.value = true
  processingLogin.value = false
}
const isUserNameTouched = () => {
  v$.value.username.$touch()
}
const showAppInfoModal = () => {
  toggleAppInfoModal()
}

const checkError = computed(() => {
  return {
    username: v$.value.username.$error ? 'Sign In Name is required' : ''
  }
})

const isDisabled = computed(() => {
  return state.username === '' || v$.value.$invalid
})
watch(() => state.username, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    serverError.value = null
  }
})

</script>
<style lang="scss" scoped>
  .forgot-pass {
    @apply bg-center bg-repeat-x h-screen w-screen flex items-center justify-center flex-col;
    background-image: url('../../assets/circles-background.svg');
    background-size: 50% auto;
  }
</style>
