import {
  PRIMARY_PATIENT,
  REMOVE_PATIENT,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG
} from '@/constants'
import { REMOVE_BUTTON } from '@/constants/scroll-table-fields'
import { getSpecimensByPatientIdsApi } from '@/services/specimens'
import { getPatientByIdApi } from '@/services/patients'
import { Patient, Specimen } from '@/types'

// TODO: any should be changed to ScrollTableColumn type
export const isRemovePatientColumn = ({
  component,
  componentOptions
}: any): boolean =>
  component === REMOVE_BUTTON && componentOptions.title === REMOVE_PATIENT

export const isPrimaryPatientColumn = (column: any): boolean => {
  const { name } = column
  return name === PRIMARY_PATIENT
}

const hasSubArray = (arr1: any[], arr2: any[]) =>
  arr1.every((elem) => arr2.includes(elem))

const isSinglePatient = (patients: Patient[]) => patients.length === 1

const isShared = (specimen: Specimen, patientIds: number[]) => {
  const { cprId, sharedPatientIds } = specimen
  const specimenOwner = Number(cprId)
  const allPatientIds = [specimenOwner, ...sharedPatientIds]
  return hasSubArray(patientIds, allPatientIds)
}

const getSpecimenCount = (type: string, specimens: Specimen[]) => {
  const filteredSpecimens = specimens.filter(
    ({ specimenType }) => specimenType === type
  )
  const counts = filteredSpecimens.map(({ specimenCount }) => specimenCount)
  return counts.reduce((a, b) => a + b, 0)
}

export const totalSharedCount = (
  patientIds: number[],
  specimens: Specimen[]
) => {
  const sharedSpecimens = specimens.filter((specimen) =>
    isShared(specimen, patientIds)
  )
  const embryoCount = getSpecimenCount(SPECIMEN_TYPE_EMBRYO, sharedSpecimens)
  const oocyteCount = getSpecimenCount(SPECIMEN_TYPE_EGG, sharedSpecimens)
  return { embryoCount, oocyteCount }
}

export const calculateSharedSpecimens = async (patients: Patient[]) => {
  if (isSinglePatient(patients)) {
    return { embryoCount: 0, oocyteCount: 0 }
  }

  const patientIds: number[] = patients.map(
    (patient) => patient.globalPatientNumber
  )
  try {
    const response = await getSpecimensByPatientIdsApi({
      patientIds,
      onlyInRobot: false
    })
    const specimens = response.data as Specimen[]
    const value = totalSharedCount(patientIds, specimens)
    return value
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error calculating shared specimens', e)
    return { embryoCount: 0, oocyteCount: 0 }
  }
}

export const patientWithFullName = (patient: Patient) => ({
  ...patient,
  fullName: `${patient.firstName} ${patient.lastName}`
})

export const fetchPatientsWithFullName = async (ids: number[], query: any) => {
  const requests = ids.map((id) => getPatientByIdApi({ id, query }))
  const responses = await Promise.all(requests)
  if (responses.length) {
    const patients = responses.map(({ data }) => patientWithFullName(data))
    return patients
  }
  return []
}
