<template>
  <MainContainer
    data-test="patient-inventory"
    :gridSlots="loadingPatientInventory ? '2' : '4'"
    class="gap-4"
  >
    <top-header>Updating {{ specimensCapitalized }} for {{ patientFullName }} with:</top-header>
    <loading-ui v-if="loadingPatientInventory" message="Loading Patient Inventory" />
    <ActionBar
      data-test="patient-form__action-bar"
      v-if="!loadingPatientInventory"
      colsDistribution="9/3"
    >
      <template v-slot:left-side>
        <dynamic-title
          titleType="h2"
          floatTo="left"
        >Which Specimens would you like to update?</dynamic-title
        >
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnText="Next"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :is-disabled="!hasSelectedSpecimen"
          @click="sendToUpdateScreening"
        />
      </template>
    </ActionBar>

    <template v-if="!loadingPatientInventory && inventoryModel.options.length > 0">
      <section class="flex justify-between items-center">
        <p data-test="embryo-count-label" class="text-xl mt-5 mb-8 text-white">
          {{ embryoCountLabel }}
        </p>
      </section>
      <ScrollTableComponent
        data-testid="patient-inventory-embryo"
        v-model="inventoryModel"
        :headers="embryoHeaders"
        :selectionMode="'checkbox'"
        hasScroll
        minHeight="h-full"
        hideSelectAllCheckbox
        :class="inventoryModelOocyte.options.length > 0 ? '-mt-4 max-h-72' : '-mt-4'"
        @update:modelValue="handleDataUpdateEmbryo"
      />
    </template>
    <template v-if="!loadingPatientInventory && inventoryModelOocyte.options.length > 0">
      <p  data-test="oocyte-count-label" class="text-xl mt-5 mb-8 text-white">
        {{ oocyteCountLabel }}
      </p>
      <ScrollTableComponent
        data-testid="patient-inventory-oocyte"
        v-model="inventoryModelOocyte"
        :headers="oocyteHeaders"
        :selectionMode="'checkbox'"
        hasScroll
        minHeight="h-full"
        hideSelectAllCheckbox
        :class="inventoryModel.options.length > 0 ? '-mt-4 max-h-72' : '-mt-4'"
        @update:modelValue="handleDataUpdateOocyte"
      />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import toast from '@/config/toast'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  CRYOBEACON,
  SPECIMENS_CAPITALIZED,
  CRYODEVICES,
  CRYODEVICE
} from '@/constants'
import * as patientInventoryHeaders from '@/constants/table-headers/patient/inventory'
import { sourcesValueHeader } from '@/constants/table-headers/patient/details'
import { checkSpecimenImport, updateSpecimenModal } from '@/helpers/patientHelpers'

export default {
  name: 'patient-inventory',
  data() {
    const defaultInventoryModel = {
      sort: {
        orderBy: 'cryoDate',
        direction: 'desc'
      },
      options: []
    }

    return {
      specimensCapitalized: SPECIMENS_CAPITALIZED,
      inventoryModel: defaultInventoryModel,
      inventoryModelOocyte: defaultInventoryModel,
      loadingPatientInventory: true,
      originInventoryModel: null,
      originInventoryModelOocyte: null,
      patientFullName: '',
      patientId: '',
      selectedSpecimens: [],
      specimenTypeEmbryo: SPECIMEN_TYPE_EMBRYO,
      specimenTypeEgg: SPECIMEN_TYPE_EGG,
      tableVisible: SPECIMEN_TYPE_EMBRYO,
      warningMessage: [
      `<p class="toast-warn-title text-tmrw-blue-dark text-base mb-2">Warning</p> <p class="toast-warn-text text-tmrw-blue-dark">All specimens in the affected ${CRYOBEACON} will be changed</p>`
      ]
    }
  },
  computed: {
    ...mapGetters('patientsModule', ['patientInventory', 'statusPatient', 'externalClinics']),
    ...mapGetters('specimensModule', ['embryoTypes']),
    hasSelectedSpecimen() {
      const embryosSelected = this.inventoryModel.options.filter((option) => option.selected).length
      const oocyteSelected = this.inventoryModelOocyte.options.filter((option) => option.selected).length
      return Boolean(embryosSelected || oocyteSelected)
    },
    embryoHeaders() {
      return this.getHeaders(SPECIMEN_TYPE_EMBRYO)
    },
    embryoCountLabel() {
      const cryoDevicesCount = this.inventoryModel.options.length
      const specimensCount = this.inventoryModel.options.reduce((counter, { specimenCount }) => counter + specimenCount, 0)
      const label = cryoDevicesCount > 1 ? `${SPECIMEN_TYPE_EMBRYO}s` : SPECIMEN_TYPE_EMBRYO
      return `${specimensCount} ${label} on ${cryoDevicesCount} ${cryoDevicesCount > 1 ? CRYODEVICES : CRYODEVICE}`
    },
    oocyteHeaders() {
      return this.getHeaders(SPECIMEN_TYPE_EGG)
    },
    oocyteCountLabel() {
      const cryoDevicesCount = this.inventoryModelOocyte.options.length
      const specimensCount = this.inventoryModelOocyte.options.reduce((counter, { specimenCount }) => counter + specimenCount, 0)
      const label = cryoDevicesCount > 1 ? `${SPECIMEN_TYPE_OOCYTE}s` : SPECIMEN_TYPE_OOCYTE
      return `${specimensCount} ${label} on ${cryoDevicesCount} ${cryoDevicesCount > 1 ? CRYODEVICES : CRYODEVICE}`
    },
    openSpecimensOptions() {
      return [
        {
          value: SPECIMEN_TYPE_EMBRYO,
          label: this.embryoCountLabel
        },
        {
          value: SPECIMEN_TYPE_EGG,
          label: this.oocyteCountLabel
        }
      ]
    }
  },
  watch: {
    patientInventory(newValue) {
      if (newValue) {
        this.updateLocalModels()
      }
    }
  },
  async created() {
    await Promise.all([
      this.fetchEmbryoTypes(),
      this.fetchPatientInventory(this.$route.params.patientId),
      this.getExternalClinics()
    ])
    this.loadingPatientInventory = false
    const patient = await this.fetchPatient(this.$route.params.patientId)
    this.patientId = patient.globalPatientNumber
    this.patientFullName = `${patient.firstName} ${patient.lastName}`
  },
  methods: {
    ...mapActions('patientsModule', [
      'fetchPatientInventory',
      'fetchPatient',
      'setPatientInventory',
      'getExternalClinics'
    ]),
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    handleTabChange(option) {
      this.tableVisible = option.value
    },
    updateLocalModels() {
      this.inventoryModel = updateSpecimenModal({
        getSpecimenImport: this.getSpecimenImport,
        options: this.patientInventory,
        optionsFilter: SPECIMEN_TYPE_EMBRYO,
        sort: this.inventoryModel.sort,
        shouldCheckSpecimenImport: true
      })

      this.inventoryModelOocyte = updateSpecimenModal({
        getSpecimenImport: this.getSpecimenImport,
        options: this.patientInventory,
        optionsFilter: SPECIMEN_TYPE_EGG,
        sort: this.inventoryModelOocyte.sort,
        shouldCheckSpecimenImport: true
      })

      // Preserve original inventory
      if (!this.originInventoryModel && !this.originalInventoryModelOocyte) {
        this.originInventoryModel = this.inventoryModel
        this.originInventoryModelOocyte = this.inventoryModelOocyte
      }
    },
    getSpecimenImport(specimen) {
      return checkSpecimenImport({
        externalClinics: this.externalClinics,
        image: 'importCryoBeacon',
        specimen
      })
    },
    handleDataUpdateEmbryo(currentSpecimens) {
      const selectedSpecimens = currentSpecimens.options.filter((option) => option.selected)

      if (selectedSpecimens.length) {
        this.updateSelectedSpecimens({
          embryoSpecimens: currentSpecimens,
          oocyteSpecimens: this.inventoryModelOocyte,
          selectedSpecimens,
          specimenType: SPECIMEN_TYPE_EMBRYO
        })

        return
      }

      this.resetSelectedSpecimens({
        embryoSpecimens: currentSpecimens,
        oocyteSpecimens: this.inventoryModelOocyte,
        originalEmbryoSpecimens: this.originInventoryModel,
        originalOocyteSpecimens: this.originInventoryModelOocyte
      })
    },
    handleDataUpdateOocyte(currentSpecimens) {
      const selectedSpecimens = currentSpecimens.options.filter((option) => option.selected)

      if (selectedSpecimens.length) {
        this.updateSelectedSpecimens({
          embryoSpecimens: this.inventoryModel,
          oocyteSpecimens: currentSpecimens,
          selectedSpecimens,
          specimenType: SPECIMEN_TYPE_EGG
        })

        return
      }

      this.resetSelectedSpecimens({
        embryoSpecimens: this.inventoryModel,
        oocyteSpecimens: currentSpecimens,
        originalEmbryoSpecimens: this.originInventoryModel,
        originalOocyteSpecimens: this.originInventoryModelOocyte
      })
    },
    updateSelectedSpecimens({
      embryoSpecimens, oocyteSpecimens, selectedSpecimens, specimenType
    }) {
      const [{ beaconBarcode }] = selectedSpecimens

      const toastContent = this.warningMessage.join()
      toast.custom('error', 'custom-warn', toastContent)

      const isEmbryoSelected = specimenType === SPECIMEN_TYPE_EMBRYO

      const updatedEmbryoModal = isEmbryoSelected
        ? this.setSelectedSpecimens({ beaconBarcode, currentSpecimen: embryoSpecimens })
        : this.setDisabledSpecimens(embryoSpecimens)

      const updatedOocyteModal = !isEmbryoSelected
        ? this.setSelectedSpecimens({ beaconBarcode, currentSpecimen: oocyteSpecimens })
        : this.setDisabledSpecimens(oocyteSpecimens)

      this.inventoryModel = updatedEmbryoModal
      this.inventoryModelOocyte = updatedOocyteModal
    },
    setSelectedSpecimens({ beaconBarcode, currentSpecimen }) {
      return {
        ...currentSpecimen,
        options: currentSpecimen.options.map((option) => ({
          ...option,
          selected: option.beaconBarcode === beaconBarcode,
          disabled: option.beaconBarcode !== beaconBarcode
        }))
      }
    },
    setDisabledSpecimens(currentSpecimen) {
      return {
        ...currentSpecimen,
        options: currentSpecimen.options.map((option) => ({
          ...option,
          selected: false,
          disabled: true
        }))
      }
    },
    resetSelectedSpecimens({
      embryoSpecimens,
      oocyteSpecimens,
      originalEmbryoSpecimens,
      originalOocyteSpecimens
    }) {
      this.inventoryModel = this.resetSelectedSpecimensValues({
        currentSpecimens: embryoSpecimens,
        originalSpecimens: originalEmbryoSpecimens
      })

      this.inventoryModelOocyte = this.resetSelectedSpecimensValues({
        currentSpecimens: oocyteSpecimens,
        originalSpecimens: originalOocyteSpecimens
      })
    },
    resetSelectedSpecimensValues({ currentSpecimens, originalSpecimens }) {
      return {
        ...currentSpecimens,
        options: currentSpecimens.options.map((option) => ({
          ...option,
          selected: false,
          disabled: originalSpecimens.options.filter(
            (specimen) => specimen.cryodeviceBarcode === option.cryodeviceBarcode
          )[0].disabled
        }))
      }
    },
    sendToUpdateScreening() {
      const specimens = [...this.inventoryModel.options, ...this.inventoryModelOocyte.options]
      const [selected] = specimens.filter((option) => option.selected)
      const { beaconBarcode } = selected
      const path = `/update-screening-status/${this.patientId}/${beaconBarcode}`
      this.$router.push({ path })
    },
    getHeaders(specimenType) {
      const specimenTypeLowerCase = specimenType.toLowerCase()
      const headers = patientInventoryHeaders[specimenTypeLowerCase]

      if (this.patientInventory.filter((specimen) => specimen.sources).length) {
        const updatedHeaders = [...headers, sourcesValueHeader]
        return updatedHeaders
      }
      return headers
    },
    getSelectedSpecimens(currentSpecimens) {
      return currentSpecimens.options.filter((option) => option.selected).length
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    MainContainer,
    ScrollTableComponent,
    TopHeader
  }
}
</script>
