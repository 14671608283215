<template>
  <div class="flex flex-row justify-center items-center">
    <div v-bind:key="typeEmbryo" v-for="typeEmbryo in baseData.typeEmbryo" class="float-left px-8">
      <button-dynamic
        btnType="button"
        btnStyle="primary"
        btn-size="large"
        :btnText="typeEmbryo"
        @click="nextStep(typeEmbryo)"
      />
    </div>
  </div>
</template>
<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  props: {
    baseData: {
      type: Object,
      required: true
    },
    nextStep: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonDynamic
  }
}
</script>
