<template>
  <dynamic-title
    :title-type="titleType"
    is-semi-bold
    float-to="left"
    :class="[isAtTheLeftCorner ? 'w-screen absolute top-2 inset-x-2' : '']"
  >
    <div
      data-test="cryobeaconNumber"
      class="rounded-lg"
      :class="[
        showBackground ? 'bg-tmrw-green-light' : '',
        customPadding
      ]"
    >
      {{ activeBeaconCounter }}
    </div>
  </dynamic-title>
</template>

<script>
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

export default {
  name: 'cryobeacon-number',
  components: {
    DynamicTitle
  },
  props: {
    activeBeaconCounter: {
      type: [String, Number],
      required: true
    },
    isAtTheLeftCorner: {
      type: Boolean,
      required: false,
      default: false
    },
    showBackground: {
      type: Boolean,
      required: false,
      default: true
    },
    titleType: {
      type: String,
      required: false,
      default: 'h1-blue-small'
    },
    customPadding: {
      type: String,
      required: false,
      default: 'py-1.5 px-6'
    }
  }
}
</script>
