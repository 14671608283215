<template>
  <section class="p-4" data-test="patient-view">
    <top-header>{{ pageTitle }}</top-header>
    <loading-ui v-if="loadingPatientData" modal message="Loading Patient Data" />
    <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6" class="mt-4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left"> Patient Details </dynamic-title>
        <p
          data-test="emr-patient-icon-label"
          v-if="!allowCreatePatients"
          class="flex ml-4 float-left text-tmrw-blue-light h-6"
        >
          <i data-test="emr-lock-icon" class="fa fa-lock text-xl mr-2 mt-0.5" />
          <span class="font-medium text-2xl">EMR</span>
        </p>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Close"
          btnStyle="secondary"
          addMarginRight
          @click="closePatient"
        />
        <button-dynamic
          v-if="allowCreatePatients"
          btnText="Edit"
          btnType="button"
          btnStyle="secondary"
          showIcon
          :isDisabled="!allowCreatePatients"
          fontAwesomeIconClass="edit"
          @click="updatePatient"
        />
      </template>
    </ActionBar>
    <div
      v-if="Object.keys(patient).length"
      data-test="patient-view__patient-details"
      data-testid="patient-detail"
      class="grid gap-4 p-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 bg-white rounded-md mt-2"
    >
      <div
        :key="key"
        v-for="(field, key) in patientFields"
        :class="field.addClasses && [...field.addClasses]"
      >
        <span class="text-xs font-normal text-tmrw-blue">
          {{ field.label }}
        </span>
        <p
          class="text-xl font-medium text-tmrw-blue-dark border-b border-dotted border-gray-800 py-2"
          :data-test="`patient-field-${field.dataTest}`"
          :class="field.valueAddClasses && [...field.valueAddClasses]"
        >
          {{ field.value }}
        </p>
      </div>
    </div>
    <PatientDocuments class="mt-6 mb-4" />
    <dynamic-title v-if="!loadingPatientData" titleType="h3" floatTo="none" class="mt-6 mb-4">
      {{ numberTickets }} Open Ticket{{ numberTickets === 1 ? '' : 's' }}
    </dynamic-title>
    <ScrollTableComponent
      data-test="patient-view__open-tickets-table"
      data-testid="patient-open-tickets"
      v-if="numberTickets && !loadingPatientData"
      v-model="localTicketsModel"
      :headers="headersTickets"
      minHeight="h-auto"
      :overflowHidden="false"
      hideSelectAllCheckbox
    />
    <div v-if="!loadingPatientData" class="patient text-white w-full">
      <dynamic-title titleType="h3" floatTo="none" class="mt-6 mb-4">
        {{ numberOfActiveSpecimens.totalCount }}
        {{ numberOfActiveSpecimens.totalCount === 1 ? specimen : specimens }} in Inventory
      </dynamic-title>
      <ActionBar
        v-if="numberOfActiveSpecimens.totalCount !== 0"
        data-test="patient-view__action-bar"
        colsDistribution="6/6"
      >
        <template v-slot:left-side>
          <Tabs
            :options="activeSpecimensOptions"
            :active="activeSpecimensTab"
            @changeValue="handleTabChange"
          />
        </template>
        <template v-slot:right-side>
          <button-dynamic
            v-if="numberOfActiveSpecimens.hasSpecimens"
            class="float-right"
            btnText="Update"
            btnType="button"
            btnStyle="secondary"
            showIcon
            addMarginBottom
            fontAwesomeIconClass="edit"
            @click="handlePatientInventoryRedirect"
          />
        </template>
      </ActionBar>
      <ScrollTableComponent
        data-testid="patient-active-tickets"
        data-test="patient-view__inventory-table"
        v-if="numberOfActiveSpecimens.hasSpecimens"
        v-model="localSpecimensModel"
        :headers="headers"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        titleType="h3"
        floatTo="none"
        class="mt-6 mb-4"
        v-if="numberOfMissingSpecimens.totalCount"
      >
        {{ numberOfMissingSpecimens.totalCount }}
        {{
          numberOfMissingSpecimens.totalCount === 1
            ? ` Missing ${specimen}`
            : `Missing ${specimens}`
        }}
      </dynamic-title>
      <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
        <template v-slot:left-side>
          <Tabs
            v-if="numberOfMissingSpecimens.totalCount"
            :options="missingSpecimensOptions"
            :active="missingSpecimensTab"
            @changeValue="handleMissingTabChange"
          />
        </template>
        <template v-slot:right-side>
          <button-dynamic
            v-if="numberOfMissingSpecimens.hasSpecimens"
            class="float-right"
            btnText="Update"
            btnType="button"
            btnStyle="secondary"
            showIcon
            addMarginBottom
            fontAwesomeIconClass="edit"
            @click="handlePatientInventoryRedirect"
          />
        </template>
      </ActionBar>
      <ScrollTableComponent
        data-testid="patient-missing-tickets"
        data-test="patient-view__missing-inventory-table"
        v-model="localMissingSpecimensModel"
        v-if="numberOfMissingSpecimens.totalCount"
        :headers="headersMissing"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        v-if="numberOfOffsiteSpecimens.totalCount"
        class="mt-6 mb-4"
        floatTo="none"
        titleType="h3"
      >
        {{ subtitleOffsiteSpecimens }}
      </dynamic-title>

      <div v-if="numberOfOffsiteSpecimens.totalCount" class="flex justify-between">
        <Tabs
          :options="offsiteSpecimensOptions"
          :active="filterOffsite"
          @changeValue="handleOffsiteTabChange"
        />
      </div>
      <ScrollTableComponent
        data-testid="patient-offsite-tickets"
        data-test="patient-view__offsite-inventory-table"
        class="mb-12"
        v-if="numberOfOffsiteSpecimens.totalCount"
        v-model="localOffsiteSpecimensModel"
        :headers="headersOffSite"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        titleType="h3"
        floatTo="none"
        class="mt-6 mb-4"
        v-if="numberOfClosedSpecimens.totalCount"
      >
        {{ numberOfClosedSpecimens.totalCount }}
        {{ numberOfClosedSpecimens.totalCount === 1 ? specimen : specimens }} No Longer in Inventory
      </dynamic-title>
      <div v-if="numberOfClosedSpecimens.totalCount" class="flex justify-between">
        <Tabs
          :options="closedSpecimensOptions"
          :active="closedSpecimensTab"
          @changeValue="handleClosedTabChange"
        />
      </div>
      <ScrollTableComponent
        data-testid="patient-closed-tickets"
        data-test="patient-view__closed-inventory-table"
        class="mb-12"
        v-if="numberOfClosedSpecimens.totalCount"
        v-model="localClosedSpecimensModel"
        :headers="headersClosed"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import inProgress from '@/assets/ticketState/in-progress.svg'
import {
  CRYODEVICES,
  EMR_SOURCE_ID,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  embryoTypeValues,
  SPECIMEN_CAPITALIZED,
  SPECIMENS_CAPITALIZED,
  INFECTIOUS_SCREENING_STATUS,
  MISSING_CRYOBEACON,
  TEMPORARY,
  CURRENT_LOCATION
} from '@/constants'
import * as patientDetailTableHeaders from '@/constants/table-headers/patient/details'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import dateAndTime from '@/helpers/dateAndTime'
import PatientDocuments from '@/components/Documents/PatientDocuments.vue'
import { selectEmbryoTypeLabel } from '@/utils'
import toast from '@/config/toast'
import * as ss from '@/config/session-storage-help'
import { checkSpecimenImport } from '@/helpers/patientHelpers'
import { replaceStringFilter } from '@/filters'

dayjs.extend(utc)

export default {
  name: 'patient-view',
  data() {
    const specimensModal = {
      sort: {
        orderBy: 'cryoDate',
        direction: 'desc'
      },
      options: []
    }

    return {
      activeSpecimensTab: SPECIMEN_TYPE_EMBRYO,
      closedSpecimensTab: SPECIMEN_TYPE_EMBRYO,
      missingSpecimensTab: SPECIMEN_TYPE_EMBRYO,
      filterOffsite: SPECIMEN_TYPE_EMBRYO,
      allowCreatePatients: this.$isSiteEmrEnabled?.allowCreatePatients,
      emrTransactionsEnabled: this.$isSiteEmrEnabled?.emrTransactionsEnabled,
      loadingPatientData: true,
      localSpecimensModel: specimensModal,
      localClosedSpecimensModel: specimensModal,
      localMissingSpecimensModel: specimensModal,
      localOffsiteSpecimensModel: specimensModal,
      localTicketsModel: {
        sort: {
          orderBy: 'procedureTime',
          direction: 'desc'
        },
        options: []
      },
      specimen: SPECIMEN_CAPITALIZED,
      specimens: SPECIMENS_CAPITALIZED
    }
  },
  async mounted() {
    await this.loadData()
  },
  computed: {
    ...mapState('patientsModule', ['specimenImportTypes']),
    ...mapGetters('specimensModule', ['embryoTypes']),
    ...mapGetters('siteModule', ['allContainersBySite']),
    ...mapGetters('patientsModule', [
      'patient',
      'specimensFromPatient',
      'specimensClosedFromPatient',
      'error',
      'ticketsFromPatient',
      'missingTicketsFromPatient',
      'statusPatient',
      'externalClinics'
    ]),
    ...mapGetters('authModule', ['appBaseConfig']),
    ...mapGetters('selectedTicketsModule', ['selectedTickets']),
    patientHasEMRTickets() {
      return this.localTickets.filter((ticket) => ticket.source === EMR_SOURCE_ID).length
    },
    pageTitle() {
      return this.patient?.firstName
        ? `You're viewing ${this.patient.firstName} ${this.patient.lastName}`
        : ''
    },
    patientFields() {
      const {
        address: { street, street2, city, zip, state, country },
        birthDate,
        email,
        firstName,
        identificationNumber,
        lastName,
        phone
      } = this.patient

      const patientName = `${firstName} ${lastName}`
      const birthDay = dayjs(birthDate).format('DD MMM YYYY').toUpperCase()
      const formattedAddress = [street, street2, city, zip, state, country].join(' ').toUpperCase()

      return [
        {
          label: 'First and Last Name',
          value: patientName,
          dataTest: 'patient-name'
        },
        {
          label: 'Patient ID',
          value: identificationNumber,
          dataTest: 'identification-number'
        },
        {
          label: 'Date of Birth',
          value: birthDay,
          dataTest: 'birth-day'
        },
        {
          label: 'Email Address',
          value: email,
          dataTest: 'email'
        },
        {
          label: 'Phone Number',
          value: phone,
          dataTest: 'phone'
        },
        {
          label: 'Address',
          value: formattedAddress,
          addClasses: ['col-span-2'],
          dataTest: 'address'
        }
      ]
    },
    activeSpecimensOptions() {
      return this.formatSpecimenTabs(this.localSpecimens, this.numberOfActiveSpecimens)
    },
    closedSpecimensOptions() {
      return this.formatSpecimenTabs(this.localClosedSpecimens, this.numberOfClosedSpecimens)
    },
    missingSpecimensOptions() {
      return this.formatSpecimenTabs(this.localMissingSpecimens, this.numberOfMissingSpecimens)
    },
    offsiteSpecimensOptions() {
      return this.formatSpecimenTabs(this.localOffsiteSpecimens, this.numberOfOffsiteSpecimens)
    },
    numberOfActiveSpecimens() {
      const embryoCount = this.composeSpecimensCount(this.localSpecimens, SPECIMEN_TYPE_EMBRYO)
      const oocyteCount = this.composeSpecimensCount(this.localSpecimens, SPECIMEN_TYPE_EGG)
      return {
        embryoCount,
        oocyteCount,
        totalCount: embryoCount + oocyteCount,
        hasSpecimens: oocyteCount > 0 || embryoCount > 0
      }
    },
    numberOfClosedSpecimens() {
      const embryoCount = this.composeSpecimensCount(
        this.localClosedSpecimens,
        SPECIMEN_TYPE_EMBRYO
      )
      const oocyteCount = this.composeSpecimensCount(this.localClosedSpecimens, SPECIMEN_TYPE_EGG)

      return {
        embryoCount,
        oocyteCount,
        totalCount: embryoCount + oocyteCount
      }
    },
    numberOfOffsiteSpecimens() {
      const embryoCount = this.composeSpecimensCount(
        this.localOffsiteSpecimens,
        SPECIMEN_TYPE_EMBRYO
      )
      const oocyteCount = this.composeSpecimensCount(this.localOffsiteSpecimens, SPECIMEN_TYPE_EGG)

      return {
        embryoCount,
        oocyteCount,
        totalCount: embryoCount + oocyteCount,
        hasSpecimens: !!(oocyteCount || embryoCount)
      }
    },
    numberOfMissingSpecimens() {
      const embryoCount = this.composeSpecimensCount(
        this.localMissingSpecimens,
        SPECIMEN_TYPE_EMBRYO
      )
      const oocyteCount = this.composeSpecimensCount(this.localMissingSpecimens, SPECIMEN_TYPE_EGG)

      return {
        embryoCount,
        oocyteCount,
        totalCount: embryoCount + oocyteCount
      }
    },
    localSpecimens() {
      const specimensFromPatient = this.filteredSpecimens()
      const { localOffsiteSpecimens } = this
      return specimensFromPatient
        .filter((specimen) => !this.shouldBeOffsiteSpecimen({ specimen, localOffsiteSpecimens }))
        .map(this.formatSpecimens)
        .map((specimen) =>
          this.ticketsFromPatient.some((el) => el.beaconBarcode === specimen.beaconBarcode)
            ? {
                ...specimen,
                selected: true,
                disabled: true,
                statusImage: {
                  sources: [inProgress]
                }
              }
            : specimen
        )
    },
    localClosedSpecimens() {
      return this.specimensClosedFromPatient
        .map(this.formatSpecimens)
        .map((specimen) => ({ ...specimen, selected: true, disabled: true }))
    },
    localMissingSpecimens() {
      return this.missingSpecimens.map(this.formatSpecimens).map((specimen) =>
        this.ticketsFromPatient.some((el) => el.beaconBarcode === specimen.beaconBarcode)
          ? {
              ...specimen,
              selected: false,
              disabled: false,
              statusImage: {
                sources: [inProgress]
              }
            }
          : specimen
      )
    },
    localOffsiteSpecimens() {
      const specimens = this.specimensFromPatient.map(this.formatSpecimens)
      const { unitIds } = this.appBaseConfig
      return this.composeOffsiteSpecimens({ specimens, unitIds })
    },
    localTickets() {
      const tickets = this.ticketsFromPatient.map((ticket) => {
        const dateOfBirth = dayjs(ticket.patientDob).valueOf()
        return {
          ...ticket,
          procedureTime: dateAndTime.toLocalTimeZone({ procedureTime: ticket.procedureTime }),
          patientDob: dayjs.utc(Number(dateOfBirth)).format('DD MMM YYYY').toUpperCase()
        }
      })

      return tickets
    },
    headers() {
      return this.buildHeaders(this.activeSpecimensTab)
    },
    headersClosed() {
      return this.buildHeaders(this.closedSpecimensTab)
    },
    headersMissing() {
      return this.buildMissingHeaders(this.missingSpecimensTab)
    },
    headersOffSite() {
      return this.buildHeaders(this.filterOffsite)
    },
    headersTickets() {
      return [
        {
          name: 'Date & Time',
          key: 'procedureTime',
          cssClasses: ['w-64', 'max-w-64']
        },
        {
          name: 'Patient Name',
          key: 'patientName',
          cssClasses: ['w-48', 'max-w-48']
        },
        {
          name: 'Patient ID',
          key: 'identificationNumber',
          cssClasses: ['w-56', 'max-w-56']
        },
        {
          name: 'Date of Birth',
          key: 'patientDob',
          cssClasses: ['w-40', 'max-w-40']
        },
        {
          name: 'Ticket #',
          key: 'ticketId',
          cssClasses: ['w-48', 'max-w-48']
        },
        {
          name: INFECTIOUS_SCREENING_STATUS,
          key: 'screeningStatus',
          cssClasses: ['w-64'],
          component: 'screeningStatus',
          componentOptions: {
            icon: true,
            label: true,
            textColor: 'tmrw-blue-dark',
            iconColor: 'tmrw-blue-dark'
          }
        },
        {
          name: CURRENT_LOCATION,
          key: 'robotLocations',
          component: 'robotLocations',
          cssClasses: ['w-40']
        },
        {
          name: 'Procedure',
          key: 'procedureName',
          filters: [(value) => replaceStringFilter(value, embryoTypeValues)]
        }
      ]
    },
    numberTickets() {
      return this.ticketsFromPatient.length
    },
    subtitleOffsiteSpecimens() {
      const { numberOfOffsiteSpecimens } = this
      return this.composeSubtitleOffsiteSpecimens({ numberOfOffsiteSpecimens })
    },
    missingSpecimens() {
      const missingSpecimens = []
      if (this.missingTicketsFromPatient && this.missingTicketsFromPatient.length > 0) {
        const beaconBarcodesSet = new Set(
          this.missingTicketsFromPatient.map((missingTicket) => missingTicket.beaconBarcode)
        )
        this.specimensFromPatient.forEach((specimen) => {
          if (beaconBarcodesSet.has(specimen.beaconBarcode)) {
            missingSpecimens.push(specimen)
          }
        })
      }
      return missingSpecimens
    }
  },
  methods: {
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    ...mapActions('patientsModule', [
      'fetchPatient',
      'fetchSpecimensFromPatient',
      'fetchClosedSpecimensFromPatient',
      'fetchOpenTicketsFromPatient',
      'fetchMissingTicketsFromPatient',
      'resetState',
      'fetchDefaultSpecimens',
      'getExternalClinics'
    ]),
    async loadData() {
      try {
        await Promise.all([
          this.fetchPatient(this.$route.params.patientId),
          this.getExternalClinics(),
          this.fetchEmbryoTypes(),
          this.fetchOpenTicketsFromPatient({
            patientId: this.$route.params.patientId,
            includeChildTickets: true
          }),
          this.fetchMissingTicketsFromPatient(this.$route.params.patientId),
          this.fetchSpecimensFromPatient({
            id: this.$route.params.patientId,
            onlyInRobot: false,
            includeClosed: false,
            onlyInClinic: true
          }),
          this.fetchClosedSpecimensFromPatient({ id: this.$route.params.patientId })
        ])
        this.loadingPatientData = false
        this.updateActiveSpecimenModel()
      } catch (err) {
        toast.custom('error', 'error', err)
      }
    },
    closePatient() {
      try {
        this.fetchDefaultSpecimens()
        let selectedTicketProcedureName
        const process = ss.getFieldSessionStorage('process')
        const selectedTickets = this.selectedTickets
        const { activeSlideSelectedTickets } = process

        switch (selectedTickets.length) {
          case 0:
            selectedTicketProcedureName = undefined
            break
          default:
            selectedTicketProcedureName = activeSlideSelectedTickets
              ? selectedTickets[activeSlideSelectedTickets - 1].procedureName
              : selectedTickets[0].procedureName
            break
        }

        switch (selectedTicketProcedureName) {
          case MISSING_CRYOBEACON:
            return this.$router.push({ path: '/selected-tickets' })
          default:
            return this.$router.push({ path: '/patients-search' })
        }
      } catch (error) {
        throw new Error(`Close Patient -> ${error}`)
      }
    },
    handlePatientInventoryRedirect() {
      this.$router.push(`/patient-inventory/${this.patient.globalPatientNumber}`)
    },
    updatePatient() {
      this.$router.push({ path: `/patients/update/${this.patient.globalPatientNumber}` })
    },
    handleTabChange(option) {
      this.activeSpecimensTab = option.value
    },
    handleClosedTabChange(option) {
      this.closedSpecimensTab = option.value
    },
    handleOffsiteTabChange(option) {
      this.filterOffsite = option.value
    },
    handleMissingTabChange(option) {
      this.missingSpecimensTab = option.value
    },
    formatSpecimens(specimen) {
      return {
        ...specimen,
        embryoTypeLabel: selectEmbryoTypeLabel(specimen, this.embryoTypes),
        seeMore: {
          icon: 'eye',
          label: 'View',
          to: `/specimen-detail/${Number(this.$route.params.patientId)}/${specimen.specimenId}`
        },
        sourcesValue: {
          icon: 'exclamation-circle',
          label: '',
          to: '',
          iconColor: 'text-red-500'
        }
      }
    },
    formatSpecimenTabs(specimen, specimenCount) {
      const embryoCryodevices = specimen.filter((cryodevice) => cryodevice.embryo)
      const oocyteCryodevices = specimen.filter((cryodevice) => cryodevice.oocyte)
      const { embryoCount, oocyteCount } = specimenCount
      return [
        {
          embryoDevicesCount: embryoCryodevices.length,
          value: SPECIMEN_TYPE_EMBRYO,
          label: `${embryoCount} Embryos`,
          count: `${embryoCount} Total on ${embryoCryodevices.length} ${CRYODEVICES}`,
          disabled: !embryoCount
        },
        {
          oocyteDevicesCount: oocyteCryodevices.length,
          value: SPECIMEN_TYPE_EGG,
          label: `${oocyteCount} Oocytes`,
          count: `${oocyteCount} Total on ${oocyteCryodevices.length} ${CRYODEVICES}`,
          disabled: !oocyteCount
        }
      ]
    },
    composeSpecimensCount(specimens, specimenType) {
      const specimenCount =
        specimens?.reduce((accumulator, currentSpecimen) => {
          if (currentSpecimen.specimenType === specimenType) {
            return accumulator + Number(currentSpecimen.specimenCount)
          }
          return accumulator
        }, 0) ?? 0
      return specimenCount
    },
    buildHeaders(currentFilter) {
      const formattedFilter = currentFilter.toLowerCase()
      const initialHeaders = patientDetailTableHeaders[formattedFilter]
      const linkHeader = {
        name: '',
        key: 'seeMore',
        component: 'link',
        alignment: 'left',
        forceEnabled: true
      }

      const updatedHeaders = [...initialHeaders]

      if (
        this.localSpecimens.filter((specimen) => specimen.sources?.length) ||
        this.localMissingSpecimens.filter((specimen) => specimen.sources?.length)
      ) {
        const importHeader = patientDetailTableHeaders.sourcesValueHeader
        updatedHeaders.push(importHeader)
      }

      updatedHeaders.push(linkHeader)
      return updatedHeaders
    },
    buildMissingHeaders(currentFilter) {
      const formattedFilter = currentFilter.toLowerCase()
      const initialHeaders = patientDetailTableHeaders[formattedFilter]
      const linkHeader = {
        name: '',
        key: 'seeMore',
        component: 'link',
        alignment: 'left',
        forceEnabled: true
      }

      const alertHeader = {
        name: '',
        key: 'sourcesValue',
        alignment: 'left',
        component: 'icon'
      }

      const updatedHeaders = [...initialHeaders]

      if (
        this.localSpecimens.filter((specimen) => specimen.sources).length ||
        this.localMissingSpecimens.filter((specimen) => specimen.sources).length
      ) {
        const importHeader = patientDetailTableHeaders.sourcesValueHeader
        updatedHeaders.push(importHeader)
      }

      updatedHeaders.push(alertHeader, linkHeader)

      return updatedHeaders
    },
    getActiveTab(specimensCount) {
      const { embryoCount } = specimensCount
      return embryoCount ? SPECIMEN_TYPE_EMBRYO : SPECIMEN_TYPE_EGG
    },
    updateActiveSpecimenModel() {
      const activeTab = this.getActiveTab(this.numberOfActiveSpecimens)
      this.activeSpecimensTab = activeTab
      this.localSpecimensModel = this.updateSpecimenModal({
        options: this.localSpecimens,
        optionsFilter: activeTab,
        sort: this.localSpecimensModel.sort,
        shouldCheckSpecimenImport: true
      })
    },
    updateClosedSpecimensModel() {
      const activeTab = this.getActiveTab(this.numberOfClosedSpecimens)
      this.closedSpecimensTab = activeTab
      this.localClosedSpecimensModel = this.updateSpecimenModal({
        options: this.localClosedSpecimens,
        optionsFilter: activeTab,
        sort: this.localClosedSpecimensModel.sort,
        shouldCheckSpecimenImport: true
      })
    },
    updateOffsiteSpecimensModel() {
      this.localOffsiteSpecimensModel = this.updateSpecimenModal({
        options: this.localOffsiteSpecimens,
        optionsFilter: SPECIMEN_TYPE_EMBRYO,
        sort: this.localOffsiteSpecimensModel.sort,
        shouldCheckSpecimenImport: true
      })
    },
    updateMissingSpecimensModel() {
      const activeTab = this.getActiveTab(this.numberOfMissingSpecimens)
      this.missingSpecimensTab = activeTab
      this.localMissingSpecimensModel = this.updateSpecimenModal({
        options: this.localMissingSpecimens,
        optionsFilter: activeTab,
        sort: this.localMissingSpecimensModel.sort,
        shouldCheckSpecimenImport: true
      })
    },
    updateActiveSpecimenTab() {
      this.localSpecimensModel = this.updateSpecimenModal({
        options: this.localSpecimens,
        optionsFilter: this.activeSpecimensTab,
        sort: this.localSpecimensModel.sort,
        shouldCheckSpecimenImport: true
      })
    },
    updateClosedSpecimenTab() {
      this.localClosedSpecimensModel = this.updateSpecimenModal({
        options: this.localClosedSpecimens,
        optionsFilter: this.closedSpecimensTab,
        sort: this.localClosedSpecimensModel.sort
      })
    },
    updateOffsiteSpecimenTab() {
      this.localOffsiteSpecimensModel = this.updateSpecimenModal({
        options: this.localOffsiteSpecimens,
        optionsFilter: this.filterOffsite,
        sort: this.localOffsiteSpecimensModel.sort
      })
    },
    updateMissingSpecimenTab() {
      this.localMissingSpecimensModel = this.updateSpecimenModal({
        options: this.localMissingSpecimens,
        optionsFilter: this.missingSpecimensTab,
        sort: this.localMissingSpecimensModel.sort
      })
    },
    updateLocalTickets() {
      const { sort } = this.localTicketsModel
      const options = this.localTickets
      this.localTicketsModel = { sort, options }
    },
    getSpecimenImport(specimen, index) {
      return checkSpecimenImport({
        externalClinics: this.externalClinics,
        hasLocalSpecimen: Boolean(this.localSpecimens[index]),
        specimen
      })
    },
    updateSpecimenModal({ options, optionsFilter, sort, shouldCheckSpecimenImport = false }) {
      const filteredOptions = options.filter((specimen) => specimen.specimenType === optionsFilter)
      const updatedOptions = shouldCheckSpecimenImport
        ? filteredOptions.map((specimen, index) => this.getSpecimenImport(specimen, index))
        : filteredOptions

      return {
        sort,
        options: updatedOptions
      }
    },
    filteredSpecimens() {
      // removing from localSpecimens the missing specimens as those got their own category
      const { siteId, unitIds } = this.appBaseConfig
      const temporaryUnits = this.allContainersBySite?.filter(
        (container) => container.robotNumber === TEMPORARY && container.siteId === siteId
      )
      let combinedUnitIds = unitIds
      for (const tempUnit in temporaryUnits) {
        if (!Object.values(combinedUnitIds).includes(temporaryUnits[tempUnit].containerId)) {
          combinedUnitIds.push(temporaryUnits[tempUnit].containerId)
        }
      }

      if (this.missingTicketsFromPatient && this.missingTicketsFromPatient.length > 0) {
        const localSpecimens = this.missingTicketsFromPatient.map((missingTicket) =>
          this.specimensFromPatient.filter((specimen) => {
            return missingTicket.beaconBarcode !== specimen.beaconBarcode
          })
        )
        const [specimensFromPatient] = localSpecimens
        return specimensFromPatient
      }
      return this.specimensFromPatient
    },
    composeSubtitleOffsiteSpecimens({ numberOfOffsiteSpecimens }) {
      const isSingular = numberOfOffsiteSpecimens.totalCount === 1
      return `${numberOfOffsiteSpecimens.totalCount} ${
        isSingular ? SPECIMEN_CAPITALIZED : SPECIMENS_CAPITALIZED
      } on Off-site Inventory`
    },
    composeOffsiteSpecimens({ specimens, unitIds }) {
      if (!specimens || !unitIds) return null
      const offSiteSpecimens = specimens.filter((specimen) => !unitIds.includes(specimen.unitId))
      return offSiteSpecimens
    },
    shouldBeOffsiteSpecimen({ specimen, localOffsiteSpecimens }) {
      if (!specimen || !localOffsiteSpecimens) return null
      return localOffsiteSpecimens
        .map((localSpecimen) => localSpecimen.specimenId)
        .includes(specimen.specimenId)
    }
  },
  beforeUnmount() {
    this.resetState()
  },
  watch: {
    localSpecimens(newLocalSpecimensValue) {
      if (newLocalSpecimensValue) {
        this.updateActiveSpecimenModel()
      }
    },
    localClosedSpecimens(newLocalClosedSpecimensValue) {
      if (newLocalClosedSpecimensValue) {
        this.updateClosedSpecimensModel()
      }
    },
    localOffsiteSpecimens(nextLocalOffsiteSpecimens) {
      if (nextLocalOffsiteSpecimens) {
        this.updateOffsiteSpecimensModel()
      }
    },
    localMissingSpecimens(newLocalMissingSpecimensValue) {
      if (newLocalMissingSpecimensValue) {
        this.updateMissingSpecimensModel()
      }
    },
    localTickets(newLocalTicketsValue) {
      if (newLocalTicketsValue) {
        this.updateLocalTickets()
      }
    },
    activeSpecimensTab(newFilterValue) {
      if (newFilterValue) {
        this.updateActiveSpecimenTab()
      }
    },
    closedSpecimensTab(newFilterClosedValue) {
      if (newFilterClosedValue) {
        this.updateClosedSpecimenTab()
      }
    },
    filterOffsite(nextFilterOffsite) {
      if (nextFilterOffsite) {
        this.updateOffsiteSpecimenTab()
      }
    },
    missingSpecimensTab(newFilterClosedValue) {
      if (newFilterClosedValue) {
        this.updateMissingSpecimenTab()
      }
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    PatientDocuments,
    ScrollTableComponent,
    Tabs,
    TopHeader
  }
}
</script>
