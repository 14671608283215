<template>
  <div
    :disabled="columnOptions.disabled"
    class="flex justify-center radio-button-container"
    :class="{ 'pointer-events-none': columnOptions.disabled }"
    @click="handleRadioButtonClick(!modelValue)"
    :data-testid="radioButtonDataTestId"
  >
    <input type="checkbox" hidden />
    <label
      data-test="radio-button"
      class="w-6 h-6 bg-white border block rounded-50 border-solid relative border-tmrw-blue cursor-pointer"
      :class="{
        'radio-button-container__checkbox--checked after:empty-content after:absolute after:w-3 after:h-3 \
        after:border-solid after:top-5 after:left-5 after:rounded-50': modelValue,
        'after:bg-tmrw-blue-light': !columnOptions.disabled,
        'opacity-40': columnOptions.disabled,
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'radio-button',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    columnOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleRadioButtonClick(value) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(
        'Click on Radio Button',
        {
          component: 'Radio Button',
          'current-route': currentRoute,
          'view-name': viewName,
          'redirected-from': redirectedFrom,
          'clinic-name': this.appBaseConfig.clinicName,
          'site-name': this.appBaseConfig.siteName,
          'user-name': this.loggedUserInfo.loginName,
          'user-type': this.loggedUserInfo.userType,
          hostname
        }
      )
      this.$emit('update:modelValue', value)
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    radioButtonDataTestId() {
      if (this.columnOptions?.name) {
        const columName = this.columnOptions.name
          .replace(' ', '-')
          .toLowerCase()
        return `${columName}-radio-button`
      }
      return ''
    }
  }
}
</script>
