<template>
  <div class="flex items-center">
    <button-dynamic
      btnDataTest="ticket-list__cancel-button"
      v-if="shouldShowCancelButton"
      btnText="Cancel"
      btnType="button"
      btnStyle="secondary"
      addMarginLeft
      class="ticket-list__cancel-buton"
      @click="onCancelButtonClicked"
      :isDisabled="isButtonDisabled"
    />

    <button-dynamic
      v-if="editDate"
      btnText="Edit date and time"
      btnType="button"
      btnStyle="secondary"
      showIcon
      fontAwesomeIconClass="edit"
      data-test="ticket-card-detail__edit-date"
      addMarginLeft
      :addClasses="['w-40']"
      @click="onClickEditDate"
    />

    <button-dynamic
      btnDataTest="ticket-list__remove-from-batch-button"
      v-if="shouldShowRemoveFromBatchButton"
      btnText="Remove From Batch"
      btnType="button"
      btnStyle="secondary"
      addMarginLeft
      @click="onRemoveBatchButtonClicked"
      :class="[{ 'cursor-not-allowed': isRemoveFromBatchButtonDisabled }]"
      :disabled="isRemoveFromBatchButtonDisabled"
    />

    <button-dynamic
      v-if="shouldShowAddDetailsButton"
      btnDataTest="action-button-add-detail"
      :btnText="addFreezeDetailsButtonLabel"
      btnType="button"
      btnStyle="primary"
      addMarginLeft
      :fontAwesomeIconClass="addDetailsIconClass"
      showIcon
      @click="onAddDetailsButtonClicked"
    />

    <button-dynamic
      v-if="shouldShowCompleteButton"
      btnDataTest="ticket-list__complete-button"
      btnText="Complete"
      btnType="button"
      btnStyle="primary"
      addMarginLeft
      fontAwesomeIconClass="check-circle"
      showIcon
      @click="onCompleteButtonClicked"
    />

    <button-dynamic
      v-if="shouldShowReviewAuditButton"
      btnDataTest="ticket-list__review_audit-button"
      btnText="Review Audit"
      btnType="button"
      btnStyle="primary"
      addMarginLeft
      @click="onShouldReviewAuditButton"
    />

    <button-dynamic
      v-if="shouldShowEditButton"
      ref="edit-button"
      btnDataTest="ticket-list__edit-button"
      btnText="Edit ticket"
      btnType="button"
      btnStyle="primary"
      addMarginLeft
      fontAwesomeIconClass="edit"
      showIcon
      @click="onEditButtonClicked"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import {
  isFreezeProcedure,
  isInventoryUpdateDone,
  isThawProcedure,
  isSingleImportProcedure,
  isExportProcedure,
  isDonationToPatientProcedure,
  isInProgress,
  canUpdateInventory,
  isSubStatePending,
  canCompleteTicket,
  isEMRTicket,
  isTicketCancellable,
  isMissingCryoBeaconProcedure,
  isStateCompleteReady,
  isBatchProcedure,
  isMoveProcedure,
  isReplaceCryoBeaconProcedure
} from '@/helpers/ticketState'
import {
  ADD_FREEZE_DETAILS,
  EDIT_FREEZE_DETAILS,
  ADD_THAW_DETAILS,
  EDIT_THAW_DETAILS,
  ADD_IMPORT_DETAILS,
  EDIT_IMPORT_DETAILS,
  ADD_EXPORT_DETAILS,
  EDIT_EXPORT_DETAILS,
  PROCEDURE_TYPE_BATCH,
  ROBOT_TRANSFER_IN,
  INVENTORY_UPDATE_NOT_REQUIRED
} from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import useGetters from '@/composables/useGetters'

const props = defineProps({
  ticket: {
    type: Object,
    required: true
  },
  editDate: {
    type: Boolean,
    default: false
  },
  isButtonDisabled: {
    type: Boolean,
    default: false

  }
})

const detailButtonLabels = {
  FREEZE: {
    add: ADD_FREEZE_DETAILS,
    edit: EDIT_FREEZE_DETAILS
  },
  THAW: {
    add: ADD_THAW_DETAILS,
    edit: EDIT_THAW_DETAILS
  },
  SINGLE_IMPORT: {
    add: ADD_IMPORT_DETAILS,
    edit: EDIT_IMPORT_DETAILS
  },
  EXPORT: {
    add: ADD_EXPORT_DETAILS,
    edit: EDIT_EXPORT_DETAILS
  },
  DONATION_TO_PATIENT: {
    add: ADD_EXPORT_DETAILS,
    edit: EDIT_EXPORT_DETAILS
  }
}
const emit = defineEmits([
  'cancel-button-clicked',
  'remove-from-batch-button-clicked',
  'add-details-button-clicked',
  'complete-button-clicked',
  'review-audit-button-clicked',
  'edit-button-clicked',
  'on-click-edit-date',
  'replace-cryobeacon-clicked'
])

const { selectedTickets, viewTicket } = useGetters('selectedTicketsModule')

const canAddEMRTicketDetails = () => {
  if (!isInProgress(props.ticket) || isBatchProcedure(viewTicket.value)) {
    return false
  }

  // TODO: analyze the main properties involved in determining if a ticket can add/edit details to prevent this
  // condition from growing too much.
  return (
    isThawProcedure(props.ticket) ||
    isExportProcedure(props.ticket) ||
    isFreezeProcedure(props.ticket) ||
    (isSingleImportProcedure(props.ticket) && canUpdateInventory(props.ticket, viewTicket.value))
  )
}
const canAddRegularTicketDetails = () => {
  const isSecondDonationToPatientTicket =
    isDonationToPatientProcedure(props.ticket) && isStateCompleteReady(props.ticket)
  if (isInProgress(props.ticket) || isSecondDonationToPatientTicket) {
    switch (true) {
      case isBatchProcedure(viewTicket.value):
        return false
      case isFreezeProcedure(props.ticket):
      case isSingleImportProcedure(props.ticket) &&
        canUpdateInventory(props.ticket, viewTicket.value):
      case isExportProcedure(props.ticket):
      case isThawProcedure(props.ticket):
      case isDonationToPatientProcedure(props.ticket) &&
        canUpdateInventory(props.ticket, viewTicket.value):
        return true
      default:
        return false
    }
  }
  return false
}
const onCancelButtonClicked = () => {
  emit('cancel-button-clicked')
}
const onRemoveBatchButtonClicked = () => {
  emit('remove-from-batch-button-clicked')
}
const onAddDetailsButtonClicked = () => {
  emit('add-details-button-clicked')
}
const onCompleteButtonClicked = () => {
  emit('complete-button-clicked')
}
const onShouldReviewAuditButton = () => {
  emit('review-audit-button-clicked')
}
const onEditButtonClicked = () => {
  emit('edit-button-clicked')
}

const addDetailsIconClass = computed(() =>
  isInventoryUpdateDone(props.ticket) ? 'check-circle' : 'edit'
)
const canAddTicketDetails = computed(() => {
  if (isEMRTicket(props.ticket)) {
    return canAddEMRTicketDetails()
  }

  return canAddRegularTicketDetails()
})
const isRobotTransferIn = computed(() => {
  if (isThawProcedure(props.ticket) || isFreezeProcedure(props.ticket)) {
    return (
      props.ticket.robotTransfer === ROBOT_TRANSFER_IN &&
      props.ticket.performedByUpdate !== INVENTORY_UPDATE_NOT_REQUIRED
    )
  }
  return true
})
const shouldShowAddDetailsButton = computed(
  () => !isSubStatePending(props.ticket) && canAddTicketDetails.value && isRobotTransferIn.value
)
const shouldShowCompleteButton = computed(
  () => !isSubStatePending(props.ticket) && canCompleteTicket(props.ticket, viewTicket.value)
)
const shouldShowReviewAuditButton = computed(
  () => !isSubStatePending(props.ticket) && isMissingCryoBeaconProcedure(props.ticket)
)
const shouldShowEditButton = computed(() => {
  if (isSubStatePending(props.ticket)) {
    return false
  }

  return (
    (isMoveProcedure(props.ticket) || isReplaceCryoBeaconProcedure(props.ticket)) &&
    canUpdateInventory(props.ticket, viewTicket.value)
  )
})
const shouldShowCancelButton = computed(() => isTicketCancellable(props.ticket))
const shouldShowRemoveFromBatchButton = computed(() => isBatchProcedure(viewTicket.value))
const addFreezeDetailsButtonLabel = computed(() => {
  const action = isInventoryUpdateDone(props.ticket) ? 'edit' : 'add'
  return detailButtonLabels[props.ticket.procedureType][action]
})
const isRemoveFromBatchButtonDisabled = computed(() => {
  const { ticketType } = viewTicket.value || ''
  return (
    ticketType &&
    ticketType.toLowerCase() === PROCEDURE_TYPE_BATCH.toLowerCase() &&
    selectedTickets.value.length <= 2
  )
})

const onClickEditDate = () => {
  return props.editDate && emit('on-click-edit-date')
}
</script>
