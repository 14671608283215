<template>
  <MainContainer gridSlots="8">
    <TopHeader>
      <HeaderSummary :sourceLocation="sourceLocation" :destinationLocation="destinationLocation" />
    </TopHeader>

    <ActionBar data-test="specimen-builk-import_action-bar" colsDistribution="9/3" class="mb-4">
      <template v-slot:left-side>
        <DynamicTitle titleType="h2" floatTo="left"
          >Select CryoBeacons to Add to Relocation</DynamicTitle
        >
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnDataTest="select-patient-cancel-button"
          btnType="button"
          btnText="Cancel"
          btnStyle="secondary"
          @click="handleCancel"
          addMarginRight
        />
        <button-dynamic
          btnDataTest="select-patient-button"
          btnType="button"
          :btnText="addButtonLabel"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="plus-circle"
          :isDisabled="!getCryobeaconsSelected.length"
          :isLoading="isLoadingTicketCreation"
          :add-classes="['min-w-64']"
          @click="handleAddSelected"
        />
      </template>
    </ActionBar>
    <BreadCrumbs :items="breadCrumbsItems" size="base" />
    <div class="h-full flex flex-col">
      <div class="bg-white w-full h-auto mb-9 p-6 rounded-lg">
        <SearchBy
          :prevSearch="getSearchDataPersisted.searchCriterias"
          :allContainers="allContainers"
          :sourceLocation="sourceLocation.id"
          @getSearchCriteria="getSearchCriteria"
          @resetFilters="resetFilters"
        />
      </div>
      <div class="bg-white pt-0 rounded-lg h-full">
        <!-- @vue-ignore -->
        <ScrollTableComponent
          minHeight="main-cryobeacons-table"
          selectionMode="checkbox"
          v-model="cryobeaconsModel"
          :headers="tableAddCryobeaconsRelocation"
          :ignoreSelectAllProcedureCheck="true"
          :headerInfo="headerInfo"
          :loading="isLoadingPatients"
          :tableEmptyMessage="tableEmptyMessage"
          :overflowHidden="!isEmptyTable"
          @onButtonClicked="onViewClicked"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import HeaderSummary from '@/components/Biorepository/HeaderSummary/HeaderSummary.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import SearchBy from '@/components/SearchBy/SearchBy.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { tableAddCryobeaconsRelocation } from '@/constants/table-headers/relocation-headers'
import {
  ADD_CRITERIA_MSG,
  CRYOBEACONS,
  NO_RESULTS_MSG,
  MOVE_LOCATION,
  TICKET_RELOCATION
} from '@/constants'
import useActions from '@/composables/useActions'
import { ICryobeaconValue, ISearchCriteria } from '@/types/massCreate'
import { convertObjectToApiRequest, getContainerId } from '@/helpers/massCreateHelpers'
import {
  CRYOBEACON_DETAILS,
  STEP_BIOREPOSITORY_FLIGHTBOARD,
  STEP_TICKET_CREATED
} from '@/constants/moveLocationTicketSteps'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'

const { getCryobeacons, getSearchDataPersisted } = useGetters('massCreateModule')
const { sourceLocation, destinationLocation, destinationCryorobot } =
  useGetters('biorepoTicketModule')
const { loggedUserInfo } = useGetters('authModule')
const { allSites, allContainers } = useGetters('siteModule')

const { fetchSpecimens, resetSearch, addCryobeacons, setCryobeaconSelected, setSearchData } =
  useActions('massCreateModule')

const router = useRouter()

const isLoadingPatients = ref(false)
const isLoadingTicketCreation = ref(false)
const cryobeaconsModel = ref({
  sort: {
    orderBy: '',
    direction: 'desc'
  },
  options: [],
  hasLoaded: false
})
const tableEmptyMessage = ref(ADD_CRITERIA_MSG)

onMounted(() => {
  if (Object.keys(getSearchDataPersisted.value).length) {
    cryobeaconsModel.value = getSearchDataPersisted.value.cryobeaconsModel
  }
})

const breadCrumbsItems = computed(() => {
  return getBiorepoBreadcrumbs({ active: 'cryobeacons', ticketMode: TICKET_RELOCATION })
})

const addButtonLabel = computed(() => {
  return `Create ${getCryobeaconsSelected.value.length} ${MOVE_LOCATION} Tickets`
})

const headerInfo = computed(() => {
  const numItems = cryobeaconsModel.value.options.length
  const location = sourceLocation.value?.name || ''
  const defaultMsg = `<span class="font-normal">Previewing </span>${numItems} ${CRYOBEACONS}`

  if (numItems >= 98) {
    return `Over 98 ${CRYOBEACONS} <span class="font-normal"> meet this criteria. Previewing </span> the first 98 ${CRYOBEACONS} <span class="font-normal">available at</span> ${location}.`
  }
  if (numItems) return defaultMsg
  return `${defaultMsg} <span class="font-normal">that meet this criteria available at</span> ${location}.`
})

const isEmptyTable = computed(() => {
  return cryobeaconsModel.value.options.length === 0
})

const handleCancel = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}

const handleAddSelected = async () => {
  isLoadingTicketCreation.value = true
  const containerId = getContainerId(
    allSites.value,
    destinationLocation.value?.id,
    destinationCryorobot.value?.id
  )

  try {
    await addCryobeacons({
      cryobeaconBarcodes: getCryobeaconsBarcodes.value,
      destinationUnitId: containerId,
      destinationSiteId: destinationLocation.value?.id,
      source: 1,
      userId: loggedUserInfo.value.userId
    })
    isLoadingTicketCreation.value = false
    router.push({ name: STEP_TICKET_CREATED })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

const handleEmptySearch = () => {
  cryobeaconsModel.value = {
    ...cryobeaconsModel.value,
    options: [],
    hasLoaded: true
  }
  tableEmptyMessage.value = ADD_CRITERIA_MSG
}

const getSearchCriteria = async (criteria: ISearchCriteria[]) => {
  if (criteria.length === 0) {
    setSearchData({})
    handleEmptySearch()
    return
  }
  const buildPayload = convertObjectToApiRequest(criteria, sourceLocation.value.id)
  isLoadingPatients.value = true
  try {
    await fetchSpecimens(buildPayload)
    isLoadingPatients.value = false
    cryobeaconsModel.value = {
      ...cryobeaconsModel.value,
      options: sanitizeData(),
      hasLoaded: true
    }
    setSearchData({
      cryobeaconsModel: cryobeaconsModel.value,
      searchCriterias: criteria
    })
  } catch (error) {
    isLoadingPatients.value = false
    console.error('Error fetching cryobeacons', error)
  }
}

const resetFilters = () => {
  resetSearch()
  cryobeaconsModel.value = {
    ...cryobeaconsModel.value,
    options: [],
    hasLoaded: false
  }
}

const getCryobeaconsSelected = computed(() => {
  const cryobraconsSelected = cryobeaconsModel.value.options.filter(
    (cryobeacon: ICryobeaconValue) => cryobeacon?.selected
  )

  return cryobraconsSelected
})

const getCryobeaconsBarcodes = computed(() => {
  return getCryobeaconsSelected.value.map(
    (cryobeacon: ICryobeaconValue) => cryobeacon.cryobeaconBarcode
  )
})

const onViewClicked = (cryobeacon) => {
  setCryobeaconSelected(cryobeacon.value)
  router.push({
    name: CRYOBEACON_DETAILS,
    params: { cryobeaconBarcode: cryobeacon.value.cryobeaconBarcode }
  })
}

// TODO: Delete this after adjust API
const sanitizeData = () => {
  if (!getCryobeacons.value.length) {
    tableEmptyMessage.value = NO_RESULTS_MSG
  }

  return getCryobeacons.value.map((cryobeacon) => {
    return {
      ...cryobeacon,
      patientName: `${cryobeacon.patient.firstName} ${cryobeacon.patient.lastName}`,
      identificationNumber: cryobeacon.patient.id,
      patientDob: cryobeacon.patient.birthdate
    }
  })
}
</script>
<style>
.main-cryobeacons-table {
  height: calc(100vh - 450px);
}
</style>
