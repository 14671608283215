import dayjs from 'dayjs'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE
} from '@/constants'
import { Specimen, LocalSpecimen } from '@/types/specimen'
import { CryoDevice, Beacon } from '@/types'
import { IncompleteTicket } from '@/types/ticket'
import * as ss from '@/config/session-storage-help'
import toast from '@/config/toast'
import store from '@/store'
import { Router } from 'vue-router'

function buildSpecimen(newSpecimen: LocalSpecimen): Specimen {
  const { ...specimen } = newSpecimen

  const builtSpecimen = {
    ...specimen,
    ...(newSpecimen.specimenType === SPECIMEN_TYPE_EMBRYO && { ...specimen.embryo }),
    ...(newSpecimen.specimenType === SPECIMEN_TYPE_EGG && { ...specimen.oocyte })
  }

  return builtSpecimen
}

function fixSpecimenTypeName(specimenType: string) {
  const fixedSpecimenTypeName = specimenType.endsWith('s')
    ? specimenType.slice(0, -1)
    : specimenType

  return fixedSpecimenTypeName
}

interface CryoDeviceInfo {
  cryoDevices: Array<CryoDevice>
  cryodeviceTypeId: number | null
}

export function getSpecimensFromCryoDevices({
  cryoDevices,
  cryodeviceTypeId
}: CryoDeviceInfo): Array<Specimen> {
  return cryoDevices.map((specimen) => {
    const { freeText } = specimen
    let { metadata } = specimen
    if (freeText?.length) {
      if (!metadata) {
        metadata = {
          metadata: {}
        }
      }
      metadata = {
        ...metadata,
        metadata: {
          ...metadata.metadata,
          cryolabelFreeText: freeText
        }
      }
    }
    const incompleteSpecimen: any = {
      ...specimen,
      specimenType: fixSpecimenTypeName(specimen.specimenType),
      metadata,
      cryodeviceTypeId
    }

    if ('cryodeviceBarcodeValidation' in incompleteSpecimen) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { cryodeviceBarcodeValidation, ...restOfSpecimen } = incompleteSpecimen
      const specimenWithCryodeviceBarcodeValidation = buildSpecimen(restOfSpecimen)
      return specimenWithCryodeviceBarcodeValidation
    }

    const specimenWithoutCryodeviceBarcodeValidation = buildSpecimen(incompleteSpecimen)
    return specimenWithoutCryodeviceBarcodeValidation
  })
}

interface CreateTicketsFromBeaconInfo {
  ticket: IncompleteTicket
  collectionProtocolId: number
  userId: number
  cryodeviceTypeId: number | null
}

export function createTicketsFromBeacon({
  ticket,
  collectionProtocolId,
  userId,
  cryodeviceTypeId
}: CreateTicketsFromBeaconInfo) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { patient, ...baseTicket } = ticket
  let { beacons } = ticket

  if (!beacons) {
    beacons = ss.getFieldSessionStorage('newTicket', 'beacons')
  }
  const newTicket = beacons.map((beacon: Beacon) => {
    const { cryoDevice: cryoDevices } = beacon
    const specimens = getSpecimensFromCryoDevices({ cryoDevices, cryodeviceTypeId })

    return {
      ...baseTicket,
      userId,
      specimens,
      performedBy: '',
      procedureTime: dayjs().valueOf(),
      cpId: collectionProtocolId,
      screeningStatus: specimens[0].screeningStatus
    }
  })

  return newTicket
}

export function updateDonationToPatientBeaconBarcode(
  donationToPatientTicket: any,
  beaconBarcode: string
) {
  const { exportReason } = donationToPatientTicket.metadata
  const { details } = donationToPatientTicket.metadata.exportReason
  const { metadata } = donationToPatientTicket.metadata.exportReason.details

  const updatedDonationToPatientTicket = {
    ...donationToPatientTicket,
    metadata: {
      exportReason: {
        ...exportReason,
        details: {
          ...details,
          metadata: {
            ...metadata,
            beaconBarcode
          }
        }
      }
    }
  }
  return updatedDonationToPatientTicket
}

export function applyBeaconBarcodeToTickets(tickets: any, scannedBeaconBarcodes: Array<String>) {
  const ticketsWithBarcode = scannedBeaconBarcodes.map((beaconBarcode, index) => {
    const ticketSpecimens = tickets[index].specimens
    return {
      ...tickets[index],
      beaconBarcode,
      specimens: ticketSpecimens.map((specimen: any) => ({ ...specimen, beaconBarcode }))
    }
  })
  store.commit('beaconsModule/clearScannedBeaconBarcodeNumbers')
  return ticketsWithBarcode
}

interface handleTicketUpdateInfo {
  router: Router
  ticket: IncompleteTicket
  collectionProtocolId: number
  beaconBarcodeNumber: number
  loggedUserId: number
}

export async function handleTicketUpdate({
  router,
  ticket,
  collectionProtocolId,
  beaconBarcodeNumber,
  loggedUserId
}: handleTicketUpdateInfo) {
  const { ticketId } = ticket
  const cryodeviceSelected = ss.getFieldSessionStorage('cryodeviceSelected')
  const cryodeviceTypeId = cryodeviceSelected ? Number(cryodeviceSelected.id) : null
  store.commit('beaconsModule/addScannedBeaconBarcodeNumber', beaconBarcodeNumber)
  const scannedBeaconBarcodes = store.getters['beaconsModule/scannedBeaconBarcodes']

  const now = dayjs().valueOf()

  let createdTicket = createTicketsFromBeacon({
    ticket: {
      ...ticket,
      procedureTime: now,
      procedureTimeTo: now
    },
    collectionProtocolId,
    userId: loggedUserId,
    cryodeviceTypeId
  })

  createdTicket = applyBeaconBarcodeToTickets(createdTicket, scannedBeaconBarcodes)
  try {
    await store.dispatch('selectedTicketsModule/updateTickets', {
      tickets: createdTicket,
      ticketId
    })
    const activeSlide = ss.getFieldSessionStorage('process', 'activeSlideSelectedTickets') || 1
    store.dispatch('beaconsModule/setBeaconBarcode', null)
    store.dispatch('beaconsModule/selectBeacon', null)
    router.push({
      path: '/selected-tickets',
      query: {
        activeSlide
      }
    })
    toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
  } catch (err) {
    toast.error({ title: `Error updating Ticket. ${err}` })
  }
}

export default {
  createTicketsFromBeacon,
  getSpecimensFromCryoDevices
}
