<template>
  <MainContainer class="edit-date-calendar" data-test="edit-date-calendar" grid-slots="3">
    <top-header>Edit Ticket Date and Time</top-header>
    <ActionBar data-test="details__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          {{ headerMessage }}
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Cancel"
          btnStyle="secondary"
          addMarginRight
          @click="onCancelEdit"
        />
        <button-dynamic
          btnType="button"
          btnText="Confirm"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle"
          :disabled="processingUpdate"
          @click="onConfirmEdit"
        />
      </template>
    </ActionBar>
    <calendar-card
      v-model="appointmentDate"
      :procedureType="selectedMethod"
      :min-date="minimunAllowedDate"
    />
  </MainContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import toast from '@/config/toast'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import CalendarCard from '@/components/CalendarCard/CalendarCard.vue'
import { embryoTypeValues, EXISTING_BEACON_TICKET_SUCCESS_MESSAGE } from '@/constants'
import { replaceStringFilter } from '@/filters'

export default {
  name: 'edit-date-calendar',
  components: {
    TopHeader,
    ButtonDynamic,
    MainContainer,
    ActionBar,
    DynamicTitle,
    CalendarCard
  },
  data() {
    return {
      selectedTicketToEdit: this.$ss.getFieldSessionStorage('process', 'selectedTicketToEdit'),
      min: dayjs().valueOf(),
      from: this.$ss.getFieldSessionStorage('process', 'selectedTicketToEdit').procedureTime,
      calendarMode: 'single',
      allowedModes: ['now', 'single'],
      calendarDate: '',
      processingUpdate: false,
      appointmentDate: {}
    }
  },
  mounted() {
    const time = dayjs().format('hh:mm:ss A')
    this.appointmentDate = {
      mode: 'now',
      date: { start: dayjs().valueOf(), end: null },
      time
    }
  },
  created() {
    if (Object.keys(this.selectedTicketToEdit).length === 0) {
      this.$router.push('/selected-tickets')
    }
  },
  watch: {
    calendarDate() {
      this.selectedTicketToEdit = {
        ...this.selectedTicketToEdit,
        procedureTime: this.calendarDate.timestamp
      }
      this.$ss.setFieldSessionStorage('selectedTicketToEdit', this.selectedTicketToEdit, 'process')
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['selectedMethod']),
    headerMessage() {
      let methodAndType = ''

      const ticket = this.$ss.getFieldSessionStorage('process', 'selectedTicketToEdit')

      if (ticket && ticket.procedureName) {
        methodAndType = replaceStringFilter(ticket.procedureName, embryoTypeValues)
      }

      return `When is this ${methodAndType} happening?`
    },
    minimunAllowedDate() {
      if (this.appointmentDate.mode === 'range') {
        return dayjs().add(1, 'day').startOf('day').valueOf()
      }
      return dayjs().startOf('day').valueOf()
    }
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    async onConfirmEdit() {
      this.processingUpdate = true
      const { ticketId } = this.selectedTicketToEdit
      const {
        date: { start },
        time
      } = this.appointmentDate
      // generating a new date as by default calendar card set the hour to 00:00:00
      const { $d: newDate } = dayjs(start)
      // making it string in order to allow the replace functionality
      const dateToString = newDate.toString()
      // parsin the data in order to get the date as timestamp
      const procedureTime = Date.parse(dateToString.replace('00:00:00', time))
      const data = {
        procedureTime,
        ticketId
      }
      try {
        await this.updateTickets({ tickets: [data], ticketId })
        this.$router.push('/selected-tickets')
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
      } catch (err) {
        toast.error({ title: err.message })
        throw Error(`onConfirmEdit error --> ${err}`)
      } finally {
        this.processingUpdate = false
      }
    },
    onCancelEdit() {
      this.$ss.removeFieldSessionStorage('process', 'selectedTicketToEdit')
      this.$router.push('selected-tickets')
    }
  }
}
</script>
