import {
  getDocumentTypesApi,
  getPatientDocumentsApi
} from '@/services/documents'

export default {
  async fetchPatientDocuments({ commit }, patientId) {
    try {
      commit('setLoading', true)
      const response = await getPatientDocumentsApi({ patientId })
      commit('setDocuments', response.data)
    } catch (err) {
      commit('setError', err)
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchDocumentTypes({ commit }) {
    let documentTypes = []
    try {
      commit('setLoading', true)
      const response = await getDocumentTypesApi()
      const { data } = response
      documentTypes = data
    } catch (err) {
      commit('setError', err)
    } finally {
      commit('setLoading', false)
    }
    return documentTypes
  },
  setDocuments({ commit }, documents) {
    commit('setDocuments', documents)
  },
  setError({ commit }, errorMessage) {
    commit('setError', errorMessage)
  },
  setLoading({ commit }, isLoading) {
    commit('setLoading', isLoading)
  }
}
