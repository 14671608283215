import { httpRequest } from '@/helpers/apiHelpers'
import { GetClinicInfoApi } from './types'

export const getClinicInfoApi = ({
  clinicId,
  token
}: GetClinicInfoApi) => httpRequest(false).get(`/clinics/${clinicId}`, {
  headers: {
    'x-access-token': token
  }
})
