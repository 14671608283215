export const EMAIL_AUTOMATED_ALERT = 'automated_alert'
export const EMAIL_REPORT = 'report'

export type EmailAlertLabels = {
  label: string
  code: 'LIQUID_NITROGEN' | 'TEMPERATURE' | 'SYSTEM_NOTIFICATION' | 'MISSING_CRYOBEACON' | 'INACTIVITY_ATR' | 'OVERDUE_TICKETS' | 'DAILY_INVENTORY_REPORT' | 'MONTHLY_INVENTORY_REPORT'
  enabled: boolean,
  id: number,
  value: number,
  isActive: boolean,
  type: typeof EMAIL_AUTOMATED_ALERT | typeof EMAIL_REPORT
} 
export const EMAIL_ALERT_LABELS:  EmailAlertLabels[] = [
  {
    label: 'Liquid Nitrogen is below the safe level',
    code: 'LIQUID_NITROGEN',
    enabled: false,
    id: 1,
    value: 1,
    isActive: false,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'Temperature is above the safe level',
    code: 'TEMPERATURE',
    enabled: false,
    id: 2,
    value: 2,
    isActive: false,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'System Notifications',
    code: 'SYSTEM_NOTIFICATION',
    enabled: false,
    id: 8,
    value: 8,
    isActive: true,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'Missing CryoBeacon',
    code: 'MISSING_CRYOBEACON',
    enabled: false,
    id: 3,
    value: 3,
    isActive: true,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'Inactivity at the CryoRobot',
    code: 'INACTIVITY_ATR',
    enabled: false,
    id: 4,
    value: 4,
    isActive: true,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'Overdue Ticket',
    code: 'OVERDUE_TICKETS',
    enabled: false,
    id: 5,
    value: 5,
    isActive: true,
    type: EMAIL_AUTOMATED_ALERT
  },
  {
    label: 'Receive Daily Reporting Emails',
    code: 'DAILY_INVENTORY_REPORT',
    enabled: false,
    id: 6,
    value: 6,
    isActive: true,
    type: EMAIL_REPORT
  },
  {
    label: 'Receive Monthly Reporting Emails',
    code: 'MONTHLY_INVENTORY_REPORT',
    enabled: false,
    id: 7,
    value: 7,
    isActive: true,
    type: EMAIL_REPORT
  }
]
