<template>
  <MainContainer data-test="tickets" gridSlots="3">
    <top-header />
    <div
      v-if="isPrinterModalVisible"
      class="
        h-screen
        w-screen
        flex
        justify-center
        items-center
        z-50
        fixed
        t-0
        l-0
        bg-black bg-opacity-50
      "
    >
      <div class="relative bg-tmrw-blue p-4">
        <span
          class="
            absolute
            flex
            justify-center
            items-center
            top-0
            right-0
            bg-white
            rounded-full
            p-2
            w-4
            h-4
            mt-2
            mr-2
            cursor-pointer
          "
        >
          <i @click="closePrinterModal" class="fas fa-times text-sm text-blue-600" />
        </span>
        <h3 class="font-bold text-white text-2xl mb-4">Select Printer</h3>
        <div>
          <span class="text-white">Default printer: </span>&nbsp;
          <select v-model="selectedPrinter">
            <option class="text-black" value="0">Print PDF</option>
            <option
              class="text-black"
              v-for="(printer, index) in activePrinters"
              :key="index"
              :value="printer.uid"
            >
              {{ getPrinterName(printer.name) }}
            </option>
          </select>
        </div>
        <button-dynamic
          v-if="selectedPrinter"
          btnText="Print"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="print"
          floatTo="right"
          @click="sendPrintJob"
        />
      </div>
    </div>
    <ActionBar data-test="tickets__action-bar" class="my-6" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          {{ errorMessage ? errorMessage : 'Your ticket has been created!' }}
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnText="Print"
          btnType="button"
          btnStyle="secondary"
          showIcon
          fontAwesomeIconClass="print"
          floatTo="right"
          @click="onPrint"
          addMarginRight
        />
        <button-dynamic
          btnText="Finish"
          btnType="button"
          btnStyle="primary"
          @click="onClickFinish"
        />
      </template>
    </ActionBar>

    <section v-if="!isTicketInfoLoading">
      <paginator
        v-if="ticketDetailLength"
        :total-items-to-display="ticketDetailLength"
        :max-paginators-count-per-view="maxPaginatorsCountPerView"
        :currentActivePage="currentActivePage"
        @handlePageChange="onChangeTicketToView"
        data-test="tickets__paginator"
      />
      <TicketInfo v-for="(ticket, key) in getItemsToPrintPerPage" :ticket="ticket" :key="key" />
    </section>
    <section v-else>
      <loading-ui modal />
    </section>

    <MultipleRobotsModal @onHideModal="onHideModal" :robots="robotsNumbers" />
  </MainContainer>
</template>

<script>
/**
 * To Do
 * Redesign architechture
 * This component is similar to -> src/views/SelectedTickets/SelectedTickets.vue
 * Test on /tickets/ticketNumber
 */
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import Paginator from '@/components/Paginator/Paginator.vue'
import TicketInfo from '@/components/TicketInfo/TicketInfo.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { getInstalledPrinters, printTickets, setPrinterName } from '@/modules/printer'
import MultipleRobotsModal from '@/components/MultipleRobotsModal/MultipleRobotsModal.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

import {
  ZEBRA_TICKET_PRINTERS_ID,
  MAX_PAGINATORS_COUNT_PER_VIEW,
  SOURCE,
  DESTINATION
} from '@/constants'
import { selectedTicketToPdf } from '@/helpers/printPDF/helpers/index'
import { sortSpecimensByCryodeviceId } from '@/utils'

dayjs.extend(utc)

export default {
  name: 'tickets',
  data() {
    return {
      tickets: [],
      errorMessage: '',
      robotsNumbers: [],
      isPrinterModalVisible: false,
      activePrinters: [],
      currentActivePage: 1,
      maxPaginatorsCountPerView: MAX_PAGINATORS_COUNT_PER_VIEW,
      isTicketInfoLoading: true,
      showMultipleRobotsModal: false
    }
  },
  async created() {
    try {
      const { ticketId } = this.$route.params
      await this.fetchTicketsDetail([ticketId])
      this.isTicketInfoLoading = false
      this.externalClinics = await this.getExternalClinics()
      return ticketId
    } catch (error) {
      return error
    }
  },
  async mounted() {
    // Browser Print Setup
    const localPrinters = await getInstalledPrinters()
    localPrinters.printer.forEach((printer) => {
      ZEBRA_TICKET_PRINTERS_ID.forEach((printerId) => {
        if (printer.name.includes(printerId)) {
          this.activePrinters.push(printer)
          this.isPrinterActive = true
          this.selectedPrinter = printer.uid
        }
      })
    })
  },
  computed: {
    ...mapGetters('selectedTicketsModule', ['ticketDetail']),
    ticketDetailLength() {
      const ticketDetailObjectToArray = [this.ticketDetail]
      return ticketDetailObjectToArray.length
    },
    getItemsToPrintPerPage() {
      const maxItemsPerPage = 1
      const currentPageToShow = this.currentActivePage * maxItemsPerPage
      const start = currentPageToShow - maxItemsPerPage
      const ticketDetailObjectToArray = [this.ticketDetail]
      return ticketDetailObjectToArray.slice(start, currentPageToShow)
    }
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['fetchTicketsDetail']),
    ...mapActions('patientsModule', ['getExternalClinics']),
    onClickFinish() {
      const { robotLocations } = this.ticketDetail
      try {
        if (robotLocations?.length > 1) {
          this.robotsNumbers.push(robotLocations.filter(location => location.type === SOURCE)[0].robotNumber)
          this.robotsNumbers.push(robotLocations.filter(location => location.type === DESTINATION)[0].robotNumber)
          if (this.robotsNumbers[0] !== this.robotsNumbers[1]) {
            this.showMultipleRobotsModal = true
          }
        }
        return this.$router.push('/flight-board')
      } catch (error) {
        return error
      }
    },
    getPrinterName(printerName) {
      return setPrinterName(printerName)
    },
    onPrint() {
      this.showPrinterModal()
    },
    onChangeTicketToView(page) {
      this.currentActivePage = page
      this.$ss.setFieldSessionStorage('selectedTicketKey', page)
    },
    onHideModal() {
      this.showMultipleRobotsModal = false
      return this.$router.push('/flight-board')
    },
    closePrinterModal() {
      this.isPrinterModalVisible = false
    },
    showPrinterModal() {
      this.isPrinterModalVisible = true
    },
    sendPrintJob() {
      if (this.selectedPrinter !== '0') {
        this.activePrinters.forEach((printer) => {
          if (printer.uid === this.selectedPrinter) {
            const selectedTicketsToPrint = sortSpecimensByCryodeviceId([this.ticketDetail])
            printTickets(printer, selectedTicketsToPrint)
            this.isPrinterModalVisible = false
          }
        })
      } else {
        this.printPDFTicket()
        this.isPrinterModalVisible = false
      }
    },
    printPDFTicket() {
      const params = {
        selectedTickets: sortSpecimensByCryodeviceId([this.ticketDetail]),
        selectedTicketsToPrint: [this.ticketDetail],
        externalClinics: this.externalClinics || []
      }
      selectedTicketToPdf(params)
    }
  },
  components: {
    TopHeader,
    TicketInfo,
    Paginator,
    ButtonDynamic,
    MultipleRobotsModal,
    MainContainer,
    DynamicTitle,
    ActionBar,
    LoadingUi
  }
}
</script>
