<template>
  <MainContainer data-test="patient-form" :gridSlots="loadingData ? '2' : '3'">
    <top-header>{{ pageTitle }}</top-header>
    <loading-ui v-if="loadingData" size="small" />
    <div v-else class="mt-8">
      <ActionBar data-test="patient-form__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h2" floatTo="left">
            {{ formTitle }}
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            data-test="patient-form__cancelBtn"
            btnType="button"
            btnText="Cancel"
            btnStyle="secondary"
            addMarginRight
            @click="closePatient"
          />
          <button-dynamic
            data-test="patient-form__submitBtn"
            btnStyle="primary"
            :btnText="isNewPatient ? 'Create' : 'Edit'"
            :isDisabled="isSubmitButtonDisabled"
            :isLoading="loadingForm"
            @click="handleSubmit"
          />
        </template>
      </ActionBar>
      <div class="grid gap-6 sm:grid-cols grid-rows-3 lg:grid-cols-4 mt-4 rounded-md p-6 bg-white">
        <InputField
          v-model.trim="v$.form.firstName.$model"
          :inputValue="v$.form.firstName.$model"
          :inputLabel="v$.form.firstName.$model ? 'First Name' : '&nbsp;'"
          inputName="First Name"
          inputPlaceholder="First Name"
          :hasError="formErrors && formErrorMsg.firstName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.lastName.$model"
          :inputValue="v$.form.lastName.$model"
          :inputLabel="v$.form.lastName.$model ? 'Last Name' : '&nbsp;'"
          inputName="Last Name"
          inputPlaceholder="Last Name"
          :hasError="formErrors && formErrorMsg.lastName"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.identificationNumber.$model"
          :inputValue="v$.form.identificationNumber.$model"
          :inputLabel="v$.form.identificationNumber.$model ? 'Patient ID' : '&nbsp;'"
          inputName="Identification Number"
          inputPlaceholder="Patient ID"
          :isDisabled="!isNewPatient"
          :hasError="formErrors && formErrorMsg?.identificationNumber"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <div class="mb-2">
          <label class="leading-tight text-base block text-left"> Date of Birth </label>
          <CustomDate
            data-test="date-component"
            :noFuture="true"
            :date="form.birthDate"
            @onValueChange="handleBirthDateChange"
          />
        </div>
        <InputField
          v-model.trim="v$.form.email.$model"
          :inputValue="v$.form.email.$model"
          :inputLabel="v$.form.email.$model ? 'Email Address' : '&nbsp;'"
          inputPlaceholder="Email Address"
          inputName="Email Address"
          :hasError="formErrors && formErrorMsg.email"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.phone.$model"
          :inputValue="v$.form.phone.$model"
          :inputLabel="v$.form.phone.$model ? 'Phone Number' : '&nbsp;'"
          inputPlaceholder="Phone Number"
          inputName="Phone Number"
          :hasError="formErrors && formErrorMsg.phone"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.street.$model"
          :inputValue="v$.form.street.$model"
          :inputLabel="v$.form.street.$model ? 'Street Address' : '&nbsp;'"
          inputPlaceholder="Street Address"
          inputName="Street Address"
          :hasError="formErrors && formErrorMsg.street"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.street2.$model"
          :inputValue="v$.form.street2.$model"
          :inputLabel="v$.form.street2.$model ? 'Apartment / Floor' : '&nbsp;'"
          inputPlaceholder="Apartment / Floor"
          inputName="Apartment / Floor"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.city.$model"
          :inputValue="v$.form.city.$model"
          :inputLabel="v$.form.city.$model ? 'City / County' : '&nbsp;'"
          inputPlaceholder="City / County"
          inputName="City"
          :hasError="formErrors && formErrorMsg.city"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <InputField
          v-model.trim="v$.form.state.$model"
          :inputValue="v$.form.state.$model"
          :inputLabel="v$.form.state.$model ? 'State' : '&nbsp;'"
          inputPlaceholder="State"
          inputName="State"
          :hasError="formErrors && formErrorMsg.state"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
        <div class="mb-2">
          <label data-test="select-country-label" class="leading-tight text-base block text-left">
            {{ form.country ? 'Country' : '&nbsp;' }}
          </label>
          <SelectField
            v-model.trim="v$.form.country.$model"
            :inputValue="v$.form.country.$model"
            :options="countryOptions"
            name="country"
            :error="formErrors && formErrorMsg.country"
            defaultOptionLabel="Country"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingBottom
            hasFontInconsolata
          />
        </div>
        <InputField
          v-model.trim="v$.form.zip.$model"
          :inputValue="v$.form.zip.$model"
          :inputLabel="v$.form.zip.$model ? 'Zip / Post Code' : '&nbsp;'"
          inputPlaceholder="Zip / Post Code"
          inputName="ZIP"
          :hasError="formErrors && formErrorMsg.zip"
          placeholderColor="tmrw-blue-dark"
          labelColor="tmrw-blue-dark"
          borderColor="tmrw-blue"
          addPaddingBottom
          hasFontInconsolata
          addPaddingTop
          hasLargePadding
          right-icon="edit-regular"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  required,
  email as emailValidation,
  maxLength,
  minLength,
  numeric,
  alpha
} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import InputField from '@/components/InputField/InputField.vue'
import SelectField from '@/components/CustomSelect/SelectField.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import CustomDate from '@/components/CustomDate/CustomDate.vue'
import toast from '@/config/toast'
import {
  validateFields,
  alphaSpace,
  alphaSpaceDash,
  alphaNumSpaceDash,
  alphaNumDashUnder
} from '@/helpers/formsFieldsValidation'

export default {
  name: 'patient-form',
  components: {
    TopHeader,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    SelectField,
    InputField,
    MainContainer,
    ActionBar,
    CustomDate
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      loadingData: true,
      loadingForm: false,
      identificationNumberError: '',
      form: {
        birthDate: null,
        firstName: null,
        lastName: null,
        identificationNumber: null,
        email: null,
        phone: null,
        street: null,
        street2: null,
        city: null,
        state: null,
        country: null,
        zip: null
      },
      initialDataForm: {},
      formErrorMsg: {
        birthDate: null,
        firstName: null,
        lastName: null,
        identificationNumber: null,
        email: null,
        phone: null,
        street: null,
        street2: null,
        city: null,
        state: null,
        country: null,
        zip: null
      }
    }
  },
  watch: {
    /**
     * Remove errors when the user types
     */
    identificationNumber(newVal) {
      if (newVal && newVal.length > 0) {
        this.identificationNumberError = ''
      }
    }
  },
  computed: {
    identificationNumber() {
      return this.v$.form?.identificationNumber.$model
    },
    ...mapGetters('patientsModule', ['patient', 'countryList']),
    ...mapGetters('createPatientModule', ['validationErrors']),
    countryOptions() {
      return this.countryList.map(({ iso, name }) => ({ value: iso, label: name }))
    },
    isNewPatient() {
      return !this.$route?.params.patientId
    },
    formTitle() {
      if (!this.isNewPatient) {
        return 'Edit Patient'
      }
      return 'Create New Patient'
    },
    pageTitle() {
      if (!this.isNewPatient) {
        return `You're viewing ${this.patient.firstName} ${this.patient.lastName}`
      }
      return 'Create Patient'
    },
    formErrors() {
      const requiredFields = [
        'firstName',
        'lastName',
        'identificationNumber',
        'email',
        'phone',
        'street',
        'street2',
        'city',
        'zip',
        'state',
        'country',
        'birthDate'
      ]
      const { form } = this.v$
      return validateFields(form, requiredFields, this.formErrorMsg)
    },
    isSubmitButtonDisabled() {
      return (
        this.loadingForm ||
        this.v$.$invalid ||
        (!this.isNewPatient && isEqual(this.form, this.initialDataForm))
      )
    }
  },
  validations() {
    return {
      form: {
        birthDate: {
          required
        },
        firstName: {
          required,
          alphaSpaceDash
        },
        lastName: {
          required,
          alphaSpaceDash
        },
        identificationNumber: {
          required,
          alphaNumDashUnder
        },
        email: {
          required,
          emailValidation
        },
        phone: {
          required,
          numeric,
          maxLength: maxLength(20),
          minLength: minLength(9)
        },
        street: {
          required
        },
        street2: {
          required: false
        },
        city: {
          alphaSpace,
          required
        },
        zip: {
          alphaNumSpaceDash,
          required: false,
          maxLength: maxLength(15)
        },
        state: {
          alphaSpace,
          required
        },
        country: {
          alpha,
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('patientsModule', ['fetchPatient', 'fetchCountries', 'editPatient']),
    ...mapActions('createPatientModule', ['createPatient']),
    closePatient() {
      this.$router.push('/patients-search')
    },
    /**
     * handleSubmit
     * This funtion address the submit action
     * It validates that the info in the
     * form is corrected by calling the `this.v$.$touch()`
     * function which is a function from the vuelidate library
     * If the info is correct then it makes the call to the server
     * whether `this.handleCreatePatient` or `this.handleUpdatePatient`
     * according to the `this.$router.params.patientId`
     *
     * void
     */
    async handleSubmit() {
      const {
        firstName,
        lastName,
        identificationNumber,
        email,
        phone,
        street,
        street2,
        city,
        zip,
        state,
        country,
        birthDate
      } = this.form

      const data = {
        email,
        firstName,
        lastName,
        phone,
        identificationNumber,
        birthDate,
        address: {
          street,
          street2,
          city,
          zip,
          state,
          country
        }
      }

      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.loadingForm = true
      if (this.isNewPatient) {
        this.handleCreatePatient(data)
      } else {
        const dataToEdit = { ...data, globalPatientNumber: this.form.globalPatientNumber }
        this.handleUpdatePatient(dataToEdit)
      }
    },
    handleBirthDateChange(newValue) {
      this.form.birthDate = newValue
    },
    async handleCreatePatient(data) {
      try {
        const { globalPatientNumber } = await this.createPatient(data)
        toast.success('Patient Created Successfully!')
        this.$router.push(`/patients/${globalPatientNumber}?created=success`)
        this.loadingForm = false
        this.v$.$reset()
      } catch (error) {
        this.validationErrors.forEach((errorMessage) => {
          toast.error({ title: errorMessage })
        })
        this.handleResponseError(error)
      }
    },
    async handleUpdatePatient(data) {
      try {
        await this.editPatient(data)
        const patient = await this.fetchPatient(this.$route.params.patientId)

        this.form = {
          ...patient,
          ...patient.address,
          birthDate: patient.birthDate
          // TODO Talk to backend team to have this as a boolean
          // Instead of a text with the boolean value
        }
        this.initialDataForm = cloneDeep(this.form)
        this.loadingForm = false
        this.v$.$reset()
      } catch (error) {
        this.handleResponseError(error)
      }
    },
    handleResponseError(error) {
      if (!this.validationErrors.length) {
        toast.error({ title: error.message })
      }
      const [errorMessage] = this.validationErrors
      this.identificationNumberError = errorMessage
      this.loadingForm = false
    },
    async loadData() {
      try {
        /**
         * Populating the local data
         * from server data
         */
        await Promise.all([
          this.fetchCountries(),
          !this.isNewPatient ? this.fetchPatient(this.$route?.params.patientId) : Promise.resolve()
        ])

        /**
         * Fill out the form with the
         * selected user if it's update scenario
         */
        if (!this.isNewPatient) {
          this.form = {
            ...this.patient,
            ...this.patient.address,
            birthDate: this.patient.birthDate,
            phone: this.patient.phone.replaceAll('.', '')
          }
          // Keeping a copy from the original form data to compare them and alow the edition if they are differents
          this.initialDataForm = cloneDeep(this.form)
          this.v$.$touch()
        }
        // To hide this page spinner
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>
