<template>
  <div
    v-if="isTermsAndConditionsModalOpen"
    class="w-screen flex items-center justify-center bg-black bg-opacity-75 absolute top-0 left-0 z-50 h-screen"
  >
    <div class="bg-white p-6 rounded-lg w-4/12 h-5/6">
      <div class="w-full flex mb-4">
        <div class="w-11/12 text-tmrw-blue-dark text-3xl">
          <p class="text-left text-tmrw-blue-dark font-bold">Terms and Conditions</p>
        </div>
        <div class="w-1/12 flex items-start justify-end">
          <i
            class="far fa-times-circle fa-lg text-tmrw-blue-dark cursor-pointer text-xl"
            @click="handleClose"
          />
        </div>
      </div>
      <div
        class="flex w-12/12 text-tmrw-blue text-xl py-7 overflow-y-scroll overflow-x-hidden h-5/6 border-b-1 border-t-1 border-solid border-gray-300"
      >
        <TermsConditionsDoc
          class="h-full"
          :handle-link-click="handleLinkClick"
        />
      </div>
      <div class="flex flex-1 justify-end items-end h-1/6 pb-10 w-full">
        <button-dynamic
          class="mr-4 h-auto"
          btn-type="button"
          btn-text="Close"
          btn-style="secondary"
          @click="handleClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import TermsConditionsDoc from './TermsConditionsDoc.vue'

export default {
  name: 'TermsAndConditionsModal',
  components: {
    ButtonDynamic,
    TermsConditionsDoc
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('appInfoModule', ['isTermsAndConditionsModalOpen'])
  },
  methods: {
    ...mapActions('appInfoModule', [
      'toggleTermsAndConditionsModal',
      'togglePrivacyPolicyModal'
    ]),
    handleClose() {
      this.toggleTermsAndConditionsModal(false)
    },
    handleLinkClick() {
      this.toggleTermsAndConditionsModal(false)
      this.togglePrivacyPolicyModal(true)
    }
  }
}
</script>

<style></style>
