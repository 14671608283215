<template>
  <modal-card
    data-test="app-info-modal"
    class="app-info-Modal"
    :open="isAppModalOpen"
    :containerClasses="['w-auto', 'rounded-md']"
    @onClickModalCard="onClickAppInfoModal"
  >
    <div class="mb-10 flex justify-end">
      <tmrw-logo class="w-64" color="blue" />
    </div>
    <div class="app-info-Modal__details text-tmrw-blue">
      <div class="flex">
        <div class="w-3/4">
          <p class="text-base mb-2" data-test="app-version">
            <strong>ivfOS:</strong> {{ ivfOSVersion }}
          </p>
          <div class="flex flex-row mt-4">
            <div class="flex items-center gap-1">
              <strong>Support: </strong
              ><a class="text-xs font-inconsolata" :href="`mailto:${appSupportEmail}`">{{
                appSupportEmail
              }}</a>
            </div>
            <div class="w-px bg-tmrw-blue-dark ml-2" />
            <div class="pl-2">
              <p class="text-xs font-inconsolata">
                <a :href="`tel:${appSupportPhoneUS}`">(US) {{ appSupportPhoneUS }}</a>
              </p>
              <p class="text-xs font-inconsolata">
                <a :href="`tel:${appSupportPhoneUK}`">(UK) {{ appSupportPhoneUK }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="w-1/4 flex flex-col items-end">
          <img class="w-[150px]" src="@/assets/TMRW-QR-Code-Static.png" alt="tmrw-qr-code" />
          <a
            class="text-xs text-nowrap font-inconsolata"
            href="https://institute.tmrw.org/user-manual"
            >institute.tmrw.org/user-manual</a
          >
        </div>
      </div>
      <div
        class="mt-8 border-1 border-tmrw-blue-light border-opacity-25 border-solid p-2 rounded-lg"
      >
        <div class="flex flex-row items-center justify-between p-2">
          <img class="w-[106.9px]" :src="TMRW_FACTORY_LOGO" alt="tmrw-factory-logo" />
          <img class="w-[181px]" src="@/assets/EC-REP.svg" alt="EC-REP logo" />
          <img class="w-[32px]" src="@/assets/CE.svg" alt="CE logo" />
          <img class="h-[21px]" src="@/assets/REF.svg" alt="REF logo" />
        </div>
        <div class="flex flex-row items-center justify-between p-2 mt-1">
          <img class="h-[30px]" src="@/assets/info-logo.svg" alt="info logo" />
          <img class="h-[34px]" src="@/assets/FCC.svg" alt="FCC logo" />
          <img class="h-[21-px]" src="@/assets/MD-UDI.svg" alt="MD UDI logo" />
          <div class="flex flex-col items-center">
            <img class="w-[50px]" src="@/assets/UDIBarcode.svg" alt="Barcode logo" />
            <p class="text-tmrw-blue-dark font-exo text-xxs">{{ UDI_BARCODE_NUMBER }}</p>
          </div>
        </div>
      </div>
    </div>
  </modal-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import ModalCard from '@/components/ModalCard/ModalCard.vue'
import { TMRW_FACTORY_LOGO } from '@/constants/images'
import { UDI_BARCODE_NUMBER } from '@/constants'
import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'

const { appVersion, isAppModalOpen, appSupportEmail, appSupportPhoneUS, appSupportPhoneUK } =
  useGetters('appInfoModule')

const { toggleAppInfoModal } = useActions('appInfoModule')

const ivfOSVersion = computed(() => {
  // Regex to find the pattern of a Number followed by a "." and then
  // one OR two numbers after. eg: "3.0", "3.1", "3.12"
  const regex = /\d{1,2}.\d{1,2}/
  return appVersion.value.match(regex)?.[0]
})

const onClickAppInfoModal = () => {
  toggleAppInfoModal(false)
}
</script>
