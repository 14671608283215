import {
  METHOD_NOT_SELECTED,
  METHOD_FREEZE,
  METHOD_THAW,
  METHOD_IMPORT,
  METHOD_EXPORT,
  METHOD_DONATE,
  FET,
  BIOPSY_CAPITALS,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  EMR_FREEZE,
  EMR_SINGLE_IMPORT
} from '@/constants'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'

import {
  STEP_INITIAL,
  STEP_SELECT_PROCEDURE,
  STEP_SELECT_SPECIMEN_TYPE,
  STEP_SELECT_PATIENT,
  STEP_CALENDAR,
  STEP_HOW_MANY,
  STEP_DETAILS,
  STEP_FINAL,
  STEP_SCAN_BEACON,
  STEP_LABEL_SCAN,
  STEP_ADD_EXISTING_BEACON,
  STEP_SELECT_THAW_PROCEDURE,
  STEP_SELECT_SPECIMENS,
  STEP_SELECT_SPECIMENS_NEW_TICKET,
  STEP_SELECT_REASON_TO_EXPORT,
  STEP_BATCH_TICKET_TYPE,
  STEP_NEW_BATCH_TICKET,
  STEP_SELECTED_TICKET
} from '@/constants/ticketSteps'

export type MethodType =
  | typeof METHOD_NOT_SELECTED
  | typeof METHOD_FREEZE
  | typeof METHOD_THAW
  | typeof METHOD_IMPORT
  | typeof METHOD_EXPORT
  | typeof METHOD_DONATE
  | typeof PROCEDURE_TYPE_BATCH_UPPERCASE
  | typeof EMR_FREEZE
  | typeof EMR_SINGLE_IMPORT

export type StepType =
  | typeof STEP_INITIAL
  | typeof STEP_SELECT_PROCEDURE
  | typeof STEP_SELECT_SPECIMEN_TYPE
  | typeof STEP_SELECT_THAW_PROCEDURE
  | typeof STEP_SELECT_PATIENT
  | typeof STEP_CALENDAR
  | typeof STEP_HOW_MANY
  | typeof STEP_DETAILS
  | typeof STEP_LABEL_SCAN
  | typeof STEP_ADD_EXISTING_BEACON
  | typeof STEP_SCAN_BEACON
  | typeof STEP_FINAL
  | typeof STEP_SELECT_SPECIMENS
  | typeof STEP_SELECT_SPECIMENS_NEW_TICKET
  | typeof STEP_SELECT_REASON_TO_EXPORT
  | typeof STEP_BATCH_TICKET_TYPE
  | typeof STEP_NEW_BATCH_TICKET
  | typeof STEP_SELECTED_TICKET
  | typeof STEP_BIOREPOSITORY_FLIGHTBOARD

export type ProcedureDate = {
  procedureDateFrom: number
  procedureDateTo: number | null
  procedureTime?: string
}

// eslint-disable-next-line no-shadow
export enum SingleVialReaderStatus {
  CRYOBEACON_DETECTED = 'CRYOBEACON_DETECTED',
  CRYOBEACON_NOT_DETECTED = 'CRYOBEACON_NOT_DETECTED',
  REGISTRATION_TRAY_NOT_DETECTED = 'REGISTRATION_TRAY_NOT_DETECTED',
  SINGLE_VIAL_READER_STATUS_IGNORED = 'SINGLE_VIAL_READER_STATUS_IGNORED'
}

export type SelectedCryoDeviceType = {
  id: string
  value: string
}

export type MetadataType = {
  addedNewBeaconHavingExistingSpace: boolean
}

export type ThawEmbryoTypes = typeof BIOPSY_CAPITALS | typeof FET
