<template>
  <div
    data-test="search-field"
    class="flex justify-center flex-col mb-0 mt-1 justify-items-stretch"
  >
    <input-field
      data-test="search-input-field"
      class="w-72"
      :inputType="type"
      :inputName="name"
      :inputColor="inputColor"
      :isDisabled="isDisabled"
      :inputPlaceholder="placeholder"
      :placeholderColor="isBlueOnWhite ? 'tmrw-blue' : 'tmrw-green-light'"
      :isBlueOnWhite="isBlueOnWhite"
      rightIcon="fas fa-search text-white"
      addPaddingBottom
      ref="searchInputField"
      v-model="userValueToSearch"
      @update:modelValue="handleInput"
    />
  </div>
</template>

<script>
import InputField from '@/components/InputField/InputField.vue'

export default {
  data() {
    return {
      userValueToSearch: ''
    }
  },
  name: 'search-field',
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    isBlueOnWhite: {
      type: Boolean,
      default: false,
      required: false
    },
    inputColor: {
      type: String,
      default: 'text-black',
      required: false
    },
    placeholder: {
      type: String,
      default: 'Filter Users',
      required: false
    }
  },
  methods: {
    clearPatientsResults() {
      this.$refs.searchInputField.modelValue = ''
    },
    handleInput(value) {
      this.$emit('update:modelValue', value)
    }
  },
  components: {
    InputField
  }
}
</script>
