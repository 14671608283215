export default {
  patients: [],
  totalSharedCryoDevices: {
    embryoCount: 0,
    oocyteCount: 0,
    specimenCount: 0
  },
  primaryPatientId: 0,
  loading: false
}
