import { httpRequest } from '@/helpers/apiHelpers'
import { CRYOLABEL_SCANNED_BY_USER } from '@/constants'

import {
  GetSpecimensEventsApi,
  GetSpecimenDetails,
  GetAuditLogApi,
  GetSpecimensEnabledApi,
  GetSpecimensByPatientIdsApi,
  DeleteSharedPatientApi,
  AddSharedPatientsApi
} from './types'

export const getSpecimensByPatientIdsApi = ({
  fromSiteId,
  onlyInClinic,
  patientIds,
  onlyInRobot = true,
  specimenType
}: GetSpecimensByPatientIdsApi) =>
  httpRequest().get(`/patients/${patientIds}/specimens`, {
    params: {
      fromSiteId,
      onlyInClinic,
      onlyInRobot,
      specimenType
    }
  })

export const getSpecimensEventsApi = ({
  collectionProtocolId,
  specimenId,
  patientId,
  clientTimeZone,
  excludeSiteCriteria
}: GetSpecimensEventsApi) => {
  const specimenData = {
    cpId: collectionProtocolId,
    specimenId
  }
  return httpRequest().get(`/patients/${patientId}/audit-log`, {
    headers: {
      timezone: clientTimeZone
    },
    params: excludeSiteCriteria ? { ...specimenData, excludeSiteCriteria } : specimenData
  })
}

export const getSpecimenDetailsApi = ({ specimenId, patientId }: GetSpecimenDetails) =>
  httpRequest().get(`/patients/${patientId}/specimens/${specimenId}`)

export const getEmbryoTypesApi = () => httpRequest(false).get('/embryo-types')

export const getCryodeviceTypesApi = () => httpRequest(false).get('/cryodevice-types')

export const getAuditLogApi = ({ specimenId, patientId, clientTimeZone }: GetAuditLogApi) =>
  httpRequest().get(`/patients/${patientId}/audit-log/export`, {
    headers: {
      timezone: clientTimeZone
    },
    params: {
      format: 'csv',
      specimenId
    }
  })

export const getSpecimensEnabledApi = ({
  clinicId,
  collectionProtocolId,
  globalPatientNumber
}: GetSpecimensEnabledApi) =>
  httpRequest().get('/tickets', {
    params: {
      clinicId,
      cpId: collectionProtocolId,
      globalPatientNumber,
      ticketState: 'OPEN',
      skipExclusions: true
    }
  })

export const addSharedPatientsApi = ({ specimenId, patientIds }: AddSharedPatientsApi) =>
  httpRequest().patch(`/specimens/${specimenId}/shared-patients`, patientIds)

export const deleteSharedPatientApi = ({ specimenId, patientId, reason }: DeleteSharedPatientApi) =>
  httpRequest().delete(`/specimens/${specimenId}/shared-patients`, {
    data: [
      {
        patientId,
        reason
      }
    ]
  })

export const postScanNotPresentOrDiscardedSpecimen = ({ specimenId }) =>
  httpRequest().post(`/specimens/${specimenId}/audit-events?event=${CRYOLABEL_SCANNED_BY_USER}`)
