<template>
  <MainContainer gridSlots="2" data-test="select-procedure-view">
    <top-header @backStep="handleClickBack">
      <span class="font-exo font-semibold text-md"> Create a Batch ticket </span>
    </top-header>
    <div class="flex flex-row justify-center items-center">
      <div class="w-96 px-16 border-solid border-r-1 border-tmrw-green-light" data-test="storeProcedures">
        <img width="28" :src="store" alt="Store" class="mb-4 m-auto">
        <span class="w-full block px-2 mt-3 text-center text-tmrw-green-light text-xl mb-8">Store</span>
        <button-dynamic
          :key="method"
          v-for="method in ['Store']"
          class="py-6"
          data-test="storeProcedureButton"
          btnType="button"
          btnStyle="primary"
          btnSize="large"
          :btnText="method"
          @click="redirectToTableComponent({ batchType: 'Store' })"
        />
      </div>
      <div class="w-96 px-16" data-test="retrieveProcedures">
        <img width="28" :src="retrieve" alt="Retrieve" class="mb-4 m-auto">
        <span class="w-full block px-2 mt-3 text-center text-tmrw-green-light text-xl mb-8">Retrieve</span>
        <button-dynamic
          :key="method"
          v-for="method in ['Retrieve']"
          class="py-6"
          data-test="retrieveProcedureButton"
          btnType="button"
          btnStyle="primary"
          btnSize="large"
          :btnText="method"
          @click="redirectToTableComponent({ batchType: 'Retrieve' })"
        />
      </div>
    </div>
  </MainContainer>
</template>
<script>
import { mapGetters } from 'vuex'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import store from '@/assets/process/store.svg'
import retrieve from '@/assets/process/retrieve.svg'
import { PROCEDURE_TYPE_BATCH_UPPERCASE } from '@/constants/index'
import { executePreviousTicketStep, executeNextTicketStep } from '@/helpers/manageTicket'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import { STEP_INITIAL } from '@/constants/ticketSteps'
import { getFieldSessionStorage } from '@/config/session-storage-help'

export default {
  components: {
    ButtonDynamic,
    MainContainer,
    TopHeader
  },
  data() {
    return {
      store,
      retrieve,
      ticketStep: 1,
      fromRouteName: null
    }
  },
  methods: {
    async redirectToTableComponent({ batchType }) {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
        currentTicketStep: this.currentTicketStep,
        batchType
      })

      this.$router.push({ name: nextStepPath })
    },
    handleClickBack() {
      const isBiorepositoryRoute = getFieldSessionStorage('process', 'isBiorepositoryRoute')

      const previousStepPath = executePreviousTicketStep({
        selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
        currentTicketStep: this.currentTicketStep,
        fromRouteName: isBiorepositoryRoute
          ? STEP_BIOREPOSITORY_FLIGHTBOARD
          : STEP_INITIAL
      })

      this.$router.replace({ name: previousStepPath })
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep'])
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  }
}
</script>
