export default {
  specimenEvents: [],
  specimenDetails: {},
  fileUrl: null,
  specimens: {
    all: [],
    status: 'ok' // loading, ok, error
  },
  embryoTypes: [],
  cryoDevicesTypes: [],
  errorMessage: '',
  status: false,
  summaries: {},
  sharedPatientAdded: false
}
