export default {
  removePatient(state, patientId) {
    const { patients } = state
    if (patients.length) {
      state.patients = patients.filter((patient) => patient.globalPatientNumber !== patientId)
    }
  },
  setPatients(state, patients) {
    state.patients = patients
  },
  setPrimaryPatientId(state, patientId) {
    state.primaryPatientId = patientId
  },
  addPatient(state, patient) {
    state.patients = [...state.patients, patient]
  },
  setTotalSharedCryoDevices(state, cryoDevice) {
    const { embryoCount, oocyteCount } = cryoDevice
    state.totalSharedCryoDevices = {
      embryoCount,
      oocyteCount,
      specimenCount: embryoCount + oocyteCount
    }
  },
  loading(state, isLoading) {
    state.loading = isLoading
  }
}
