/* eslint-disable */
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import * as ss from '@/config/session-storage-help'
import * as PRINTER_CONSTANTS from '@/constants/label-generator-constants'
import {
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EMBRYO_UPPERCASE,
  SCREENING_STATUSES,
  SPECIMEN_TYPE_EGGS,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_EGG_UPPERCASE,
  SPECIMEN_TYPE_EGGS_UPPERCASE,
  SPECIMEN_TYPE_OOCYTE_UPPERCASE,
  PRINTER_READY,
  ZBP_READY,
  ZBP_OFFLINE,
  PRINTER_NOT_READY,
  TMRW_TICKET_PRINTER,
  TMRW_LABEL_PRINTER,
  CRYOBEACON,
  SPECIMEN_TYPE_EMBRYO,
  ZEBRA_LABEL_PRINTERS_ID,
  ZEBRA_TICKET_PRINTERS_ID,
  SOURCE,
  DESTINATION,
  UNASSIGNED
} from '@/constants'
import { getTicketApi } from '@/services/tickets'
import {
  MODULAR_LABEL_SITE_PROPERTY_EMBRYO,
  MODULAR_LABEL_SITE_PROPERTY_EGG,
  MODULAR_LABEL_COUNT,
  MODULAR_LABEL_EGG,
  MODULAR_LABEL_EMBRYO,
  MODULAR_LABEL_EMBRYO_NUMBER,
  MODULAR_LABEL_PATIENT_DOB,
  MODULAR_LABEL_FREE_TEXT,
  MODULAR_LABEL_EMBRYO_TYPE,
  MODULAR_LABEL_MATURITY,
  MODULAR_LABEL_EMBRYO_IDENTIFIER,
  MODULAR_LABEL_OOCYTE_IDENTIFIER,
  MODULAR_LABEL_X_ELEMENT,
  MODULAR_LABEL_EMBRYO_GRADE,
  MODULAR_LABEL_BIOPSY,
  MODULAR_LABEL_BIOPSY_RESULT
} from '@/constants/modular-cryolabel'
import { CRYOLABEL_ENABLED_FF, isFeatureEnabled } from '@/helpers/featureFlags'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation/index'
import { sortSpecimensByCryodeviceId } from '@/utils'
import store from '@/store'
import { textElipsis } from '@/helpers/printPDF/helpers'

dayjs.extend(utc)

// WIP! Do not remove
// let zebraPrinterLabels = null
// let zebraPrinterTickets = null

export const getDefaultPrinter = new Promise((resolve) => {
  try {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    BrowserPrint.getDefaultDevice('printer', (defaultDevice) => {
      resolve(defaultDevice)
    })
  } catch (err) {
    //reject(`ivfOS -> Error getDefaultPrinter ${err}`)
  }
})

const isUUID = (cryoBeaconId) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
    cryoBeaconId
  )
}

const ticketPrinterIds =
  ss
    .getFieldSessionStorage('siteProperties', 'causeway.clinic-setup.ticket-printers-ids')
    ?.split(',') || ZEBRA_TICKET_PRINTERS_ID
const labelPrinterIds =
  ss
    .getFieldSessionStorage('siteProperties', 'causeway.clinic-setup.label-printers-ids')
    ?.split(',') || ZEBRA_LABEL_PRINTERS_ID

export const setPrinterName = (printerName) => {
  let parsedPrinterName = printerName
  labelPrinterIds.forEach((printerId) => {
    if (printerName.includes(printerId)) {
      parsedPrinterName = TMRW_LABEL_PRINTER
    }
  })

  ticketPrinterIds.forEach((printerId) => {
    if (printerName.includes(printerId)) {
      parsedPrinterName = TMRW_TICKET_PRINTER
    }
  })
  return parsedPrinterName
}

export const getInstalledPrinters = () =>
  new Promise((resolve, reject) => {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    const emptyPrinters = { printer: [] }
    try {
      if (BrowserPrint) {
        BrowserPrint.getLocalDevices(
          (localPrinters) => {
            if (localPrinters.printer) {
              resolve(localPrinters)
            } else {
              resolve(emptyPrinters)
            }
          },
          () => {
            resolve(emptyPrinters)
          }
        )
      } else {
        resolve(emptyPrinters)
      }
    } catch (err) {
      resolve(emptyPrinters)
    }
  })

export const getPrintersStatus = () =>
  new Promise((resolve, reject) => {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    try {
      const installedPrinters = []
      ss.setFieldSessionStorage('ticketPrinterStatus', PRINTER_NOT_READY)
      ss.setFieldSessionStorage('labelPrinterStatus', PRINTER_NOT_READY)
      ss.setFieldSessionStorage('zebraBrowserPrintStatus', ZBP_OFFLINE)
      BrowserPrint.getLocalDevices((localPrinters) => {
        ss.setFieldSessionStorage('zebraBrowserPrintStatus', ZBP_READY)
        if (localPrinters.printer) {
          localPrinters.printer.forEach(async (printer) => {
            ticketPrinterIds.forEach(async (printerId) => {
              if (printer.name.includes(printerId)) {
                ss.setFieldSessionStorage('ticketPrinterStatus', PRINTER_READY)
                // WIP! Do not remove
                /*zebraPrinterTickets = new Zebra.Printer(printer)
              const ticketPrinterStatus = await zebraPrinterTickets.isPrinterReady()
              await zebraPrinterTickets.getStatus((status) => {
                if(status.getMessage() === PRINTER_READY) {
                  ss.setFieldSessionStorage('ticketPrinterStatus', PRINTER_READY)
                }
              })*/
              }
            })
            labelPrinterIds.forEach(async (printerId) => {
              if (printer.name.includes(printerId)) {
                ss.setFieldSessionStorage('labelPrinterStatus', PRINTER_READY)
                // WIP! Do not remove
                /*zebraPrinterLabels = new Zebra.Printer(printer)
              const LabelsPrinterStatus = await zebraPrinterLabels.isPrinterReady()
              await zebraPrinterLabels.getStatus((status) => {
                if(status.getMessage() === PRINTER_READY) {
                  ss.setFieldSessionStorage('labelPrinterStatus', PRINTER_READY)
                }
              })*/
              }
            })
          })
          resolve()
        }
      })
    } catch (err) {
      reject(`ivfOS -> Error retrieving Printers Status ${err}`)
    }
  })

const sendPrintJob = (selectedPrinter, printPayload) => {
  return new Promise((resolve, reject) => {
    let printerStatus

    ticketPrinterIds.forEach((printerId) => {
      if (selectedPrinter.name.includes(printerId)) {
        printerStatus = ss.getFieldSessionStorage('ticketPrinterStatus')
      }
    })
    labelPrinterIds.forEach((printerId) => {
      if (selectedPrinter.name.includes(printerId)) {
        printerStatus = ss.getFieldSessionStorage('labelPrinterStatus')
      }
    })
    const sanitizedData = printPayload.toString().replace(/(\r\n|\n|\r)/gm, '')

    if (printerStatus === PRINTER_READY) {
      selectedPrinter.send(sanitizedData)
      resolve()
    }
    reject({ error: true, status: false, message: `Printer not ready` })
  })
}

export const getLengthValue = (value) => value.w

export const getPatientDoB = () => {
  const activeSlideSelected =
    ss.getFieldSessionStorage('process', 'activeSlideSelectedTickets') ?? 1

  const selectedTicketPatientDoB = store.getters['selectedTicketsModule/selectedTickets']
  if (selectedTicketPatientDoB?.length) {
    const { patientDob } = selectedTicketPatientDoB[activeSlideSelected - 1]
    return dayjs(patientDob).format('DDMMMYYYY').toUpperCase()
  }

  const selectedPatient = ss.getFieldSessionStorage('newTicket', 'selectedPatient')
  const [{ birthDate }] = selectedPatient
  return dayjs(birthDate).format('DDMMMYYYY').toUpperCase()
}

export const formatCryodeviceIdByLength = (cryodeviceId) => {
  const formattedCryodeviceId =
    cryodeviceId.length > 11 ? `...${cryodeviceId.substr(-9)}` : cryodeviceId
  return formattedCryodeviceId
}

export const formatLabelValue = (label, id) => {
  switch (id) {
    case MODULAR_LABEL_EMBRYO_NUMBER:
      const embryoNum = label?.embryo?.embryoNumber

      return embryoNum || ''

    case MODULAR_LABEL_COUNT:
      return label.specimenCount.toLocaleString(undefined, {
        minimumIntegerDigits: 2
      })

    case MODULAR_LABEL_EMBRYO_TYPE:
      return label?.embryoType
        ? `D${label.embryoType}`
        : label?.embryo?.embryoType
          ? `D${label.embryo.embryoType}`
          : MODULAR_LABEL_EMBRYO_IDENTIFIER

    case MODULAR_LABEL_PATIENT_DOB:
      return getPatientDoB()

    case MODULAR_LABEL_MATURITY:
      return label?.oocyte?.maturityLevel || label?.maturityLevel || MODULAR_LABEL_OOCYTE_IDENTIFIER

    case MODULAR_LABEL_FREE_TEXT:
      return label.freeText || label?.metadata?.metadata?.cryolabelFreeText || ''

    case MODULAR_LABEL_X_ELEMENT:
      return 'x'

    case MODULAR_LABEL_EMBRYO_GRADE:
      return label?.embryo?.grade

    case MODULAR_LABEL_BIOPSY:
      return label?.embryo?.biopsy || ''

    case MODULAR_LABEL_BIOPSY_RESULT:
      const trimmedBiopsyResult = label?.embryo?.biopsyResult.substring(0, 8) || false
      return trimmedBiopsyResult
    default:
      return label[id] || ''
  }
}

export const getCryolabelConfig = (label, embryoConfig, siteProperty) => {
  let customLabel = ''
  const INITIAL_POSITION_X = 0
  const INITIAL_POSITION_Y = 65
  const FONT = '^FR^A0N,24,23^FD'
  const sitePropertyConfig = embryoConfig[siteProperty]

  for (let row = 0; row < sitePropertyConfig.length; row++) {
    const rowValue = sitePropertyConfig[row]
    for (let values = 0; values < rowValue.length; values++) {
      const value = rowValue[values]
      const HORIZONTAL_POSITION = INITIAL_POSITION_X + 12 * value.x
      // Position label
      customLabel += `^FO${HORIZONTAL_POSITION},${INITIAL_POSITION_Y + row * 25}${FONT}`

      if (value.id === 'blankSpace') {
        customLabel += ' '
      } else {
        customLabel += `${
          formatLabelValue(label, value.id)?.slice(0, getLengthValue(value)) || ''
        }${PRINTER_CONSTANTS.FIELD_SEPARATOR}`
      }
    }
  }

  return customLabel
}

export const defaultCryolabel = (label) => {
  const fullName = `${label.lastName.charAt(0)}${label.lastName
    .slice(1)
    .toLowerCase()}, ${label.firstName.charAt(0)}.`
  let cmds = ''
  cmds += PRINTER_CONSTANTS.PATIENT_ID
  cmds += label.identificationNumber
  cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
  cmds += PRINTER_CONSTANTS.PATIENT_NAME
  cmds += fullName
  cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR

  if (
    label.specimenType === SPECIMEN_TYPE_OOCYTE ||
    label.specimenType === SPECIMEN_TYPE_EGGS ||
    label.specimenType === SPECIMEN_TYPE_EGG
  ) {
    cmds += PRINTER_CONSTANTS.SPECIMEN_COUNT
    cmds += label.specimenCount
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
    cmds += PRINTER_CONSTANTS.OOCYTE_MATURITY_LEVEL
    cmds += label.maturityLevel || MODULAR_LABEL_OOCYTE_IDENTIFIER
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
  } else {
    const embryoType = label?.embryoType
      ? `D${label.embryoType}`
      : label?.embryo?.embryoType
        ? `D${label.embryo.embryoType}`
        : MODULAR_LABEL_EMBRYO_IDENTIFIER
    cmds += PRINTER_CONSTANTS.EMBRYO_NUMBER
    cmds += label.embryoNumber ? `${label.embryoNumber.split('').reverse().join('')}#` : ''
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
    cmds += PRINTER_CONSTANTS.EMBRYO_DAY
    cmds += `${label.specimenCount}x${embryoType}`
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
  }
  cmds += PRINTER_CONSTANTS.PRINT_DATE
  cmds += dayjs(label.procedureDateFrom).format('DDMMMYYYY').toUpperCase()
  cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR

  return cmds
}

export const createCustomLabel = (labels) => {
  let printedLabels = 0
  const isEmbryo = labels[0].specimenType === SPECIMEN_TYPE_EMBRYO
  const sessionStorageField = isEmbryo
    ? MODULAR_LABEL_SITE_PROPERTY_EMBRYO
    : MODULAR_LABEL_SITE_PROPERTY_EGG
  const sessionSchema = ss.getFieldSessionStorage('siteProperties', sessionStorageField)
  const schema = JSON.parse(sessionSchema)
  const siteProperty = isEmbryo ? MODULAR_LABEL_EMBRYO : MODULAR_LABEL_EGG
  let cmds = PRINTER_CONSTANTS.LABEL_START
  cmds += PRINTER_CONSTANTS.LABEL_UTF8_FORMAT
  cmds += PRINTER_CONSTANTS.PRINTER_CONFIG_THERMAL_TRANSFER
  cmds += PRINTER_CONSTANTS.LABEL_END
  labels.forEach((label) => {
    // Barcode Row 1
    cmds += PRINTER_CONSTANTS.LABEL_START
    cmds += PRINTER_CONSTANTS.BARCODE
    cmds += label.cryodeviceBarcode
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR
    cmds += PRINTER_CONSTANTS.CRYODEVICE_ID
    cmds += label.cryodeviceBarcode
    cmds += PRINTER_CONSTANTS.FIELD_SEPARATOR

    if (isFeatureEnabled(CRYOLABEL_ENABLED_FF)) {
      cmds += getCryolabelConfig(label, schema, siteProperty)
    } else {
      cmds += defaultCryolabel(label)
    }

    printedLabels++
    cmds +=
      printedLabels === labels.length ? PRINTER_CONSTANTS.CUTTER : PRINTER_CONSTANTS.TEAR_OFF_LABEL
    cmds += PRINTER_CONSTANTS.LABEL_END
  })

  return cmds
}

export const printMultipleLabels = (selectedPrinter, labels) =>
  new Promise(async (resolve, reject) => {
    // Sort labels in ascending order for printing
    labels.sort((a, b) => (a.cryodeviceBarcode < b.cryodeviceBarcode ? 1 : -1))
    const cmds = createCustomLabel(labels)
    const printPayload = [cmds]

    await sendPrintJob(selectedPrinter, printPayload)
    resolve()
  })

export const printSingleLabel = (
  selectedPrinter,
  ticketData,
  activeBeaconData,
  cryoDeviceReprintIndex
) =>
  new Promise(async (resolve, reject) => {
    const cmds = createCustomLabel([activeBeaconData.cryoDevice[cryoDeviceReprintIndex]])

    const printPayload = [cmds]

    await sendPrintJob(selectedPrinter, printPayload)
    resolve()
  })

export const getRobotName = (robotLocations) => {
  if (robotLocations.length) {
    if (robotLocations.length > 1 && robotLocations[0].robotId !== robotLocations[1].robotId) {
      const sourceLocationData = robotLocations.find((location) => location.type === SOURCE)
      const destinationLocationData = robotLocations.find(
        (location) => location.type === DESTINATION
      )
      const sourceLocation = getCurrentCryoBeaconLocation([sourceLocationData])
      const destinationLocation = getCurrentCryoBeaconLocation([destinationLocationData])
      return `${sourceLocation.robotName} to ${destinationLocation.robotName}`
    } else {
      const currentLocation = getCurrentCryoBeaconLocation(robotLocations)
      return `${currentLocation.robotName}`
    }
  }
  return '--'
}

export const getCryoBeaconLocationDetails = (robotLocations) => {
  return getCurrentCryoBeaconLocation(robotLocations).locationDetails
}

export const printTickets = (selectedPrinter, tickets) =>
  new Promise(async (resolve, reject) => {
    try {
      const { clinicName, siteName } = ss.getFieldSessionStorage('config')
      const embryoTypes = store.getters['specimensModule/embryoTypes']
      let cmds = PRINTER_CONSTANTS.LABEL_START
      cmds += PRINTER_CONSTANTS.LABEL_UTF8_FORMAT
      cmds += PRINTER_CONSTANTS.PRINTER_CONFIG_DIRECT_THERMAL
      cmds += PRINTER_CONSTANTS.LABEL_END

      // ^FO0,0^GB600,1800,3^FS
      tickets.forEach((ticket) => {
        // Set Robot Location and Position Data
        const robotName = getRobotName(ticket.robotLocations)
        const cryoBeaconPosition = getCryoBeaconLocationDetails(ticket.robotLocations)

        cmds += '^XA^CI28'
        let linePosition = 50

        if (robotName) {
          cmds += `^FX Tank Line
          ^FB1200,1,,
          ^FO20,200^FWB^AG^A0B,25,24^FD${robotName} - ${ticket.procedureName.toUpperCase()}^FS`
        }
        if (cryoBeaconPosition) {
          cmds += `^FX Position Data
          ^FB1200,1,,
          ^FO${linePosition},200^FWB^AG^A0B,25,24^FD${cryoBeaconPosition}^FS
          `
          linePosition += 30
        }

        cmds += `^FX Patient Name and ID Line
        ^FB1200,1,,
        ^FO${linePosition},200^FWB^AG^A0B,25,24^FD${ticket.patientName.toUpperCase()} | ID ${
          ticket.identificationNumber
        }^FS

        ^FX Date Time Line
        ^FB600,1,,
        ^FO15,25^FWB^AG^A0B,25,24^FD${dayjs(ticket.procedureTime)
          .format('DDMMMYYYY h:mm A')
          .toUpperCase()}^FS`

        if (ticket.specimens.length) {
          cmds += `^FX ${CRYOBEACON} and CryoDeviced Line
          ^FB600,1,,
            ^FO45,25^FWB^AG^A0B,25,24^FDCryoBeacon ID ${
              isUUID(ticket.specimens[0].beaconBarcode)
                ? UNASSIGNED.toUpperCase()
                : ticket.specimens[0].beaconBarcode
            } | ${ticket.specimens.length} ${
              ticket.specimens.length > 1 ? 'Cryodevices' : 'Cryodevice'
            }^FS`
        }

        cmds += `^FX Clinic Line
        ^FB600,1,,
        ^FO75,25^FWB^AG^A0B,25,24^FD${clinicName} - ${siteName}^FS

        ^FX Ticket Number
        ^FB600,1,,
        ^FO105,25^FWB^AG^A0B,25,24^FDTICKET Number: ${ticket.ticketId}^FS
        `

        const specimens = ticket.specimens
          .filter((specimen) => specimen?.inSelectedTicket)
          .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)

        const remainSpecimens = ticket.specimens
          .filter((specimen) => !specimen?.inSelectedTicket)
          .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)

        let hasEgg = false
        //check if filteredSpecimens only contains both specimen
        hasEgg = hasEggSpecimens(specimens) || hasEggSpecimens(remainSpecimens)

        if (!hasEgg) {
          cmds += `^FX Cryodevice ID Title Line
          ^FO180,1650^FWB^AG^A0B,25,24^FDCryodevice ID^FS

          ^FX Cryodevice id count Line
          ^FO180,1550^FWB^AG^A0B,25,24^FDCount^FS

          ^FX Type Line
          ^FO180,1400^FWB^AG^A0B,25,24^FDInfectious^FS

          ^FX Grade Line
          ^FO180,1170^FWB^AG^A0B,25,24^FDEmbryo #^FS

          ^FX Screening Status Line
          ^FO180,960^FWB^AG^A0B,25,24^FDGrade^FS

          ^FX Type Line
          ^FO180,820^FWB^AG^A0B,25,24^FDType^FS

          ^FX Cryodate Line
          ^FO180,620^FWB^AG^A0B,25,24^FDCryoDate^FS

          ^FX Biopsy Line
          ^FO180,470^FWB^AG^A0B,25,24^FDBiopsy^FS

          ^FX Bippsy Result Line
          ^FO180,360^FWB^AG^A0B,25,24^FDResult^FS

          ^FX Notes Line
          ^FO180,220^FWB^AG^A0B,25,24^FDNotes^FS

          ^FX Dividing Line
          ^FO210,25^GB1,1765,3^FS
          `

          cmds += `^FX Selected Line
        ^FO225,1550^FWB^AG^A0B,25,24^FDSelected^FS
        ^FX Dividing Line
        ^FO255,25^GB1,1765,3^FS
        `

          let topPosition = 270

          for (let i = 0; i < specimens.length; i++) {
            const cryodeviceId = specimens[i].cryodeviceBarcode
            const cryoDateMilisValue = Number(specimens[i].cryoDate)
            cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${specimens[i].specimenCount}^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[specimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1040^GB20,220
          ^FO${topPosition},1040^FB220,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${specimens[i].embryo?.embryoNumber ? specimens[i].embryo?.embryoNumber : '--'}^FS

          ^FX Grade
          ^FX^FO${topPosition},910^GB20,100
          ^FO${topPosition},915^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${specimens[i].embryo?.grade ? specimens[i].embryo?.grade : '--'}^FS
          `

            let embryoLabel = '--'
            embryoTypes.forEach((embryoType) => {
              if (
                specimens[i].embryo?.embryoType &&
                typeof specimens[i].embryo?.embryoType === 'number' &&
                embryoType.id === specimens[i].embryo?.embryoType
              ) {
                embryoLabel = embryoType.name
              } else if (
                specimens[i].embryo?.embryoType &&
                typeof specimens[i].embryo?.embryoType === 'string' &&
                embryoType.id.toString() === specimens[i].embryo?.embryoType
              ) {
                embryoLabel = embryoType.name
              }
            })

            cmds += `^FX type
          ^FX^FO${topPosition},620^GB20,250
          ^FO${topPosition},620^FB250,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${embryoLabel}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},505^GB20,200
          ^FO${topPosition},505^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase()}^FS

          ^FX Biopsy
          ^FX^FO${topPosition},420^GB20,100
          ^FO${topPosition},420^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD${
            specimens[i].embryo?.biopsy || 'No'
          }^FS

          ^FX Biopsy Result
          ^FX^FO${topPosition},340^GB20,100
          ^FO${topPosition},340^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            specimens[i].embryo?.biopsyResult
              ? formatLabelValue(specimens[i], MODULAR_LABEL_BIOPSY_RESULT)
              : '--'
          }^FS

          ^FX Notes
          ^FX^FO${topPosition},30^GB20,100
          ^FO${topPosition},30^FB245,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(specimens[i].specimenInfo, 23) || '--'
          }^FS
          `
            topPosition += 30
          }

          cmds += `^FX Dividing Line
          ^FO${topPosition},25^GB1,1765,3^FS`

          if (remainSpecimens.length !== 0) {
            topPosition += 15
            cmds += `^FX Remaining Line
            ^FO${topPosition},1385^FWB^AG^A0B,25,24^FDRemaining in CryoBeacon^FS`
            topPosition += 30
            cmds += `^FX Dividing Line
            ^FO${topPosition},25^GB1,1765,3^FS`
            topPosition += 15
          }

          for (let i = 0; i < remainSpecimens.length; i++) {
            const cryodeviceId = remainSpecimens[i].cryodeviceBarcode
            const cryoDateMilisValue = Number(remainSpecimens[i].cryoDate)
            cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[remainSpecimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1040^GB20,220
          ^FO${topPosition},1040^FB220,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${
            remainSpecimens[i].embryo?.embryoNumber ? remainSpecimens[i].embryo?.embryoNumber : '--'
          }^FS

          ^FX Grade
          ^FX^FO${topPosition},910^GB20,100
          ^FO${topPosition},915^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${remainSpecimens[i].embryo?.grade ? remainSpecimens[i].embryo?.grade : '--'}^FS
          `

            let embryoLabel = '--'
            embryoTypes.forEach((embryoType) => {
              if (
                remainSpecimens[i].embryo?.embryoType &&
                typeof remainSpecimens[i].embryo?.embryoType === 'number' &&
                embryoType.id === remainSpecimens[i].embryo?.embryoType
              ) {
                embryoLabel = embryoType.name
              } else if (
                remainSpecimens[i].embryo?.embryoType &&
                typeof remainSpecimens[i].embryo?.embryoType === 'string' &&
                embryoType.id.toString() === remainSpecimens[i].embryo?.embryoType
              ) {
                embryoLabel = embryoType.name
              }
            })

            cmds += `^FX type
          ^FX^FO${topPosition},620^GB20,250
          ^FO${topPosition},620^FB250,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${embryoLabel}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},505^GB20,200
          ^FO${topPosition},505^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase()}^FS

          ^FX Biopsy
          ^FX^FO${topPosition},420^GB20,100
          ^FO${topPosition},420^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].embryo?.biopsy || 'No'
          }^FS

          ^FX Biopsy Result
          ^FX^FO${topPosition},340^GB20,100
          ^FO${topPosition},340^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].embryo?.biopsyResult
              ? formatLabelValue(remainSpecimens[i], MODULAR_LABEL_BIOPSY_RESULT)
              : '--'
          }^FS

          ^FX Notes
          ^FX^FO${topPosition},30^GB20,100
          ^FO${topPosition},30^FB245,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(remainSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
            topPosition += 30
          }
        }

        if (hasEgg) {
          cmds += `^FX Cryodevice ID Title Line
        ^FO180,1650^FWB^AG^A0B,25,24^FDCryodevice ID^FS

        ^FX Cryodevice id count Line
        ^FO180,1550^FWB^AG^A0B,25,24^FDCount^FS

        ^FX Type Line
        ^FO180,1400^FWB^AG^A0B,25,24^FDInfectious^FS

        ^FX Grade Line
        ^FO180,1140^FWB^AG^A0B,25,24^FDMaturity Level^FS

        ^FX Cryodate Line
        ^FO180,1000^FWB^AG^A0B,25,24^FDCryoDate^FS

        ^FX Notes Line
        ^FO180,850^FWB^AG^A0B,25,24^FDNotes^FS

        ^FX Dividing Line
        ^FO210,25^GB1,1765,3^FS`

          cmds += `^FX Selected Line
        ^FO225,1550^FWB^AG^A0B,25,24^FDSelected^FS
        ^FX Dividing Line
        ^FO255,25^GB1,1765,3^FS
        `

          let topPosition = 270

          for (let i = 0; i < specimens.length; i++) {
            const cryodeviceId = specimens[i].cryodeviceBarcode
            const cryoDateMilisValue = Number(specimens[i].cryoDate)
            cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${specimens[i].specimenCount}^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[specimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1090^GB20,220
          ^FO${topPosition},1090^FB220,1,1,C,0^FWB^AG^A0B,25,24^FD
          ${
            specimens[i].oocyte && specimens[i].oocyte?.maturityLevel
              ? specimens[i].oocyte.maturityLevel
              : '--'
          }^FS

          ^FX CryoDate
          ^FX^FO${topPosition},890^GB20,200
          ^FO${topPosition},890^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase()}^FS

          ^FX Notes
          ^FX^FO${topPosition},60^GB20,100
          ^FO${topPosition},60^FB845,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(specimens[i].specimenInfo, 23) || '--'
          }^FS
          `
            topPosition += 30
          }

          cmds += `^FX Dividing Line
          ^FO${topPosition},25^GB1,1765,3^FS`

          if (remainSpecimens.length !== 0) {
            topPosition += 15
            cmds += `^FX Remaining Line
            ^FO${topPosition},1385^FWB^AG^A0B,25,24^FDRemaining in CryoBeacon^FS`
            topPosition += 30
            cmds += `^FX Dividing Line
            ^FO${topPosition},25^GB1,1765,3^FS`
            topPosition += 15
          }

          for (let i = 0; i < remainSpecimens.length; i++) {
            const cryodeviceId = remainSpecimens[i].cryodeviceBarcode
            const cryoDateMilisValue = Number(remainSpecimens[i].cryoDate)
            cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[remainSpecimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1090^GB20,220
          ^FO${topPosition},1090^FB220,1,1,C,0^FWB^AG^A0B,25,24^FD
          ${
            remainSpecimens[i].oocyte && remainSpecimens[i].oocyte?.maturityLevel
              ? remainSpecimens[i].oocyte.maturityLevel
              : '--'
          }^FS

          ^FX CryoDate
          ^FX^FO${topPosition},890^GB20,200
          ^FO${topPosition},890^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase()}^FS

          ^FX Notes
          ^FX^FO${topPosition},60^GB20,100
          ^FO${topPosition},60^FB845,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(remainSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
            topPosition += 30
          }
        }

        // Cut Ticket
        cmds += '^MMC,N'
        cmds += '^XZ'
      })

      const printPayload = [cmds]

      await sendPrintJob(selectedPrinter, printPayload)

      resolve({ success: true })
    } catch (err) {
      reject({ error: true, message: `Error Printing ZPL Commands (Single Ticket) - ${err}` })
    }
  })

export const printFreezeTickets = (selectedPrinter, ticketsToDisplay, ticketIds) =>
  new Promise(async (resolve, reject) => {
    const { clinicName, siteName } = ss.getFieldSessionStorage('config')
    const embryoTypes = store.getters['specimensModule/embryoTypes']
    let cmds = PRINTER_CONSTANTS.LABEL_START
    cmds += PRINTER_CONSTANTS.PRINTER_CONFIG_DIRECT_THERMAL
    cmds += PRINTER_CONSTANTS.LABEL_END

    let ticketIndex = 0
    let { data: childrenTickets } = await getTicketApi({ ticketId: ticketIds.join(',') })
    childrenTickets = sortSpecimensByCryodeviceId(childrenTickets)

    for (const ticket of ticketsToDisplay) {
      const { visit } = ticket
      const {
        robotLocations,
        patientName,
        identificationNumber,
        specimens,
        ticketId,
        procedureName
      } = childrenTickets[ticketIndex]

      // Set Robot Location and Position Data
      const robotName = getRobotName(robotLocations)
      const cryoBeaconPosition = getCryoBeaconLocationDetails(robotLocations)

      let linePosition = 50

      // ^FO0,0^GB940,2650,3^FS
      cmds += `^XA
      ^CI28
      ^FX Tank Line
      ^FB1200,1,,
      ^FO20,200^FWB^AG^A0B,25,24^FD${robotName} - ${procedureName.toUpperCase()}^FS`

      if (cryoBeaconPosition) {
        cmds += `^FX Position Data
        ^FB1200,1,,
        ^FO${linePosition},200^FWB^AG^A0B,25,24^FD${cryoBeaconPosition}^FS
        `
        linePosition += 30
      }

      cmds += `^FX Patient Name and ID Line
      ^FB1200,1,,
      ^FO${linePosition},200^FWB^AG^A0B,25,24^FD${patientName.toUpperCase()} | ID ${identificationNumber}^FS

      ^FX Date Time Line
      ^FO15,25^FWB^AG^A0B,25,24^FD${dayjs(visit.procedureDateFrom)
        .format('DD MMM YYYY h:mm A')
        .toUpperCase()}^FS

      ^FX ${CRYOBEACON} and CryoDeviced Line
      ^FO45,25^FWB^AG^A0B,25,24^FDCryoBeacon ID ${
        isUUID(specimens[0].beaconBarcode) ? UNASSIGNED.toUpperCase() : specimens[0].beaconBarcode
      } | ${specimens.length} ${specimens.length > 1 ? 'Cryodevices' : 'Cryodevice'}^FS

      ^FX Clinic Line
      ^FO75,25^FWB^AG^A0B,25,24^FD${clinicName} - ${siteName}^FS

      ^FX Ticket Number 
      ^FO105,25^FWB^AG^A0B,25,24^FDTICKET Number: ${ticketId}^FS
      `

      const filteredSpecimens = specimens.filter((specimen) => specimen?.inSelectedTicket)
      const remainSpecimens = specimens.filter((specimen) => !specimen?.inSelectedTicket)

      let hasEgg = false
      //check if filteredSpecimens only contains both specimen
      hasEgg = hasEggSpecimens(specimens) || hasEggSpecimens(remainSpecimens)

      if (!hasEgg) {
        //const filteredSpecimens =   filteredSpecimens.filter((specimen) => specimen.specimenType == "Embryo")
        //const testremainSpecimens =   remainSpecimens.filter((specimen) => specimen.specimenType == "Embryo")

        cmds += `^FX Cryodevice ID Title Line
        ^FO180,1650^FWB^AG^A0B,25,24^FDCryodevice ID^FS

        ^FX Cryodevice id count Line
        ^FO180,1550^FWB^AG^A0B,25,24^FDCount^FS

        ^FX Type Line
        ^FO180,1400^FWB^AG^A0B,25,24^FDInfectious^FS

        ^FX Grade Line
        ^FO180,1170^FWB^AG^A0B,25,24^FDEmbryo #^FS

        ^FX Screening Status Line
        ^FO180,960^FWB^AG^A0B,25,24^FDGrade^FS

        ^FX Type Line
        ^FO180,820^FWB^AG^A0B,25,24^FDType^FS

        ^FX Cryodate Line
        ^FO180,620^FWB^AG^A0B,25,24^FDCryoDate^FS

        ^FX Biopsy Line
        ^FO180,470^FWB^AG^A0B,25,24^FDBiopsy^FS

        ^FX Bippsy Result Line
        ^FO180,360^FWB^AG^A0B,25,24^FDResult^FS

        ^FX Notes Line
        ^FO180,220^FWB^AG^A0B,25,24^FDNotes^FS

        ^FX Dividing Line
        ^FO210,25^GB1,1765,3^FS
        `
        cmds += `^FX Selected Line
        ^FO225,1550^FWB^AG^A0B,25,24^FDSelected^FS
        ^FX Dividing Line
        ^FO255,25^GB1,1765,3^FS
        `
        let topPosition = 270

        for (let i = 0; i < filteredSpecimens.length; i++) {
          const cryodeviceId = filteredSpecimens[i].cryodeviceBarcode
          const cryoDateMilisValue =
            filteredSpecimens[i].cryoDate && Number(filteredSpecimens[i].cryoDate)
          cmds += `^FX Cryodevice ID Title Line

          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD${
            SCREENING_STATUSES[filteredSpecimens[i].screeningStatus]
          }^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1040^GB20,220
          ^FO${topPosition},1040^FB220,1,1,L,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].embryo?.embryoNumber
              ? filteredSpecimens[i].embryo.embryoNumber
              : '--'
          }^FS

          ^FX Grade
          ^FX^FO${topPosition},910^GB20,100
          ^FO${topPosition},915^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].embryo?.grade ? filteredSpecimens[i].embryo?.grade : '--'
          }^FS
          `

          let embryoLabelAlt = '--'
          embryoTypes.forEach((embryoType) => {
            if (
              filteredSpecimens[i].embryo?.embryoType &&
              typeof filteredSpecimens[i].embryo?.embryoType === 'number' &&
              embryoType.id === filteredSpecimens[i].embryo?.embryoType
            ) {
              embryoLabelAlt = embryoType.name
            } else if (
              filteredSpecimens[i].embryo?.embryoType &&
              typeof filteredSpecimens[i].embryo?.embryoType === 'string' &&
              embryoType.id.toString() === filteredSpecimens[i].embryo?.embryoType
            ) {
              embryoLabelAlt = embryoType.name
            }
          })

          cmds += `
          ^FX type
          ^FX^FO${topPosition},620^GB20,250
          ^FO${topPosition},620^FB250,1,1,L,0^FWB^AG^A0B,25,24^FD${embryoLabelAlt}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},505^GB20,200
          ^FO${topPosition},505^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD${(cryoDateMilisValue
            ? dayjs.utc(cryoDateMilisValue)
            : dayjs(visit.procedureDateFrom)
          )
            .format('DDMMMYYYY')
            .toUpperCase()}^FS

          ^FX Biopsy
          ^FX^FO${topPosition},420^GB20,100
          ^FO${topPosition},420^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].embryo?.biopsy || 'No'
          }^FS

          ^FX Biopsy Result
          ^FX^FO${topPosition},340^GB20,100
          ^FO${topPosition},340^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].embryo?.biopsyResult
              ? formatLabelValue(filteredSpecimens[i], MODULAR_LABEL_BIOPSY_RESULT)
              : '--'
          }^FS

          ^FX Notes
          ^FX^FO${topPosition},30^GB20,100
          ^FO${topPosition},30^FB245,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(filteredSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
          topPosition += 30
        }

        cmds += `^FX Dividing Line
        ^FO${topPosition},25^GB1,1765,3^FS`

        if (remainSpecimens.length !== 0) {
          topPosition += 15
          cmds += `^FX Remaining Line
          ^FO${topPosition},1385^FWB^AG^A0B,25,24^FDRemaining in CryoBeacon^FS`
          topPosition += 30
          cmds += `^FX Dividing Line
          ^FO${topPosition},25^GB1,1765,3^FS`
          topPosition += 15
        }

        for (let i = 0; i < remainSpecimens.length; i++) {
          const cryodeviceId = remainSpecimens[i].cryodeviceBarcode
          const cryoDateMilisValue = Number(remainSpecimens[i].cryoDate)
          cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[remainSpecimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1040^GB20,220
          ^FO${topPosition},1040^FB220,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${
            remainSpecimens[i].embryo?.embryoNumber ? remainSpecimens[i].embryo?.embryoNumber : '--'
          }^FS

          ^FX Grade
          ^FX^FO${topPosition},910^GB20,100
          ^FO${topPosition},915^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${remainSpecimens[i].embryo?.grade ? remainSpecimens[i].embryo?.grade : '--'}^FS
          `

          let embryoLabel = '--'
          embryoTypes.forEach((embryoType) => {
            if (
              remainSpecimens[i].embryo?.embryoType &&
              typeof remainSpecimens[i].embryo?.embryoType === 'number' &&
              embryoType.id === remainSpecimens[i].embryo?.embryoType
            ) {
              embryoLabel = embryoType.name
            } else if (
              remainSpecimens[i].embryo?.embryoType &&
              typeof remainSpecimens[i].embryo?.embryoType === 'string' &&
              embryoType.id.toString() === remainSpecimens[i].embryo?.embryoType
            ) {
              embryoLabel = embryoType.name
            }
          })

          cmds += `^FX type
          ^FX^FO${topPosition},620^GB20,250
          ^FO${topPosition},620^FB250,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${embryoLabel}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},505^GB20,200
          ^FO${topPosition},505^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${dayjs.utc(cryoDateMilisValue).format('DDMMMYYYY').toUpperCase()}^FS

          ^FX Biopsy
          ^FX^FO${topPosition},420^GB20,100
          ^FO${topPosition},420^FB100,1,1,L,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].embryo?.biopsy || 'No'
          }^FS

          ^FX Biopsy Result
          ^FX^FO${topPosition},340^GB20,100
          ^FO${topPosition},340^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].embryo?.biopsyResult
              ? formatLabelValue(remainSpecimens[i], MODULAR_LABEL_BIOPSY_RESULT)
              : '--'
          }^FS

          ^FX Notes
          ^FX^FO${topPosition},30^GB20,100
          ^FO${topPosition},30^FB245,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(remainSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
          topPosition += 30
        }
      }

      if (hasEgg) {
        //const testSpecimen =   filteredSpecimens.filter((specimen) => specimen.specimenType == "Egg")
        //const remainSpecimens =   remainSpecimens.filter((specimen) => specimen.specimenType == "Egg")

        cmds += `^FX Cryodevice ID Title Line
        ^FO180,1650^FWB^AG^A0B,25,24^FDCryodevice ID^FS

        ^FX Cryodevice id count Line
        ^FO180,1550^FWB^AG^A0B,25,24^FDCount^FS

        ^FX Type Line
        ^FO180,1400^FWB^AG^A0B,25,24^FDInfectious^FS

        ^FX Grade Line
        ^FO180,1140^FWB^AG^A0B,25,24^FDMaturity Level^FS

        ^FX Cryodate Line
        ^FO180,1000^FWB^AG^A0B,25,24^FDCryoDate^FS

        ^FX Notes Line
        ^FO180,850^FWB^AG^A0B,25,24^FDNotes^FS

        ^FX Dividing Line
        ^FO210,25^GB1,1765,3^FS`

        cmds += `^FX Selected Line
        ^FO225,1550^FWB^AG^A0B,25,24^FDSelected^FS
        ^FX Dividing Line
        ^FO255,25^GB1,1765,3^FS
        `
        let topPosition = 270

        for (let i = 0; i < filteredSpecimens.length; i++) {
          const cryodeviceId = filteredSpecimens[i].cryodeviceBarcode
          const cryoDateMilisValue =
            filteredSpecimens[i].cryoDate && Number(filteredSpecimens[i].cryoDate)
          cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            filteredSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[filteredSpecimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1090^GB20,220
          ^FO${topPosition},1090^FB220,1,1,C,0^FWB^AG^A0B,25,24^FD
          ${filteredSpecimens[i].oocyte?.maturityLevel || '--'}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},890^GB20,200
          ^FO${topPosition},890^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${(cryoDateMilisValue ? dayjs.utc(cryoDateMilisValue) : dayjs(visit.procedureDateFrom))
            .format('DDMMMYYYY')
            .toUpperCase()}^FS

          ^FX Notes
          ^FX^FO${topPosition},60^GB20,100
          ^FO${topPosition},60^FB845,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(filteredSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
          topPosition += 30
        }

        cmds += `^FX Dividing Line
        ^FO${topPosition},25^GB1,1765,3^FS`

        if (remainSpecimens.length !== 0) {
          topPosition += 15
          cmds += `^FX Remaining Line
          ^FO${topPosition},1385^FWB^AG^A0B,25,24^FDRemaining in CryoBeacon^FS`
          topPosition += 30
          cmds += `^FX Dividing Line
          ^FO${topPosition},25^GB1,1765,3^FS`
          topPosition += 15
        }

        for (let i = 0; i < remainSpecimens.length; i++) {
          const cryodeviceId = remainSpecimens[i].cryodeviceBarcode
          const cryoDateMilisValue =
            remainSpecimens[i].cryoDate && Number(remainSpecimens[i].cryoDate)
          cmds += `^FX Cryodevice ID Title Line
          ^FX^FO${topPosition},1585^GB20,250
          ^FO${topPosition},1585^FB200,1,0,L,0^FWB^AG^A0B,25,24^FD${formatCryodeviceIdByLength(
            cryodeviceId
          )}^FS

          ^FX Specimen Count
          ^FX^FO${topPosition},1525^GB20,100
          ^FO${topPosition},1525^FB100,1,1,C,0^FWB^AG^A0B,25,24^FD${
            remainSpecimens[i].specimenCount
          }^FS

          ^FX Screening Status
          ^FX^FO${topPosition},1100^GB20,400
          ^FO${topPosition},1100^FB400,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${SCREENING_STATUSES[remainSpecimens[i].screeningStatus]}^FS

          ^FX Embryo #
          ^FX^FO${topPosition},1090^GB20,220
          ^FO${topPosition},1090^FB220,1,1,C,0^FWB^AG^A0B,25,24^FD
          ${remainSpecimens[i].oocyte?.maturityLevel || '--'}^FS

          ^FX CryoDate
          ^FX^FO${topPosition},890^GB20,200
          ^FO${topPosition},890^FB200,1,1,L,0^FWB^AG^A0B,25,24^FD
          ${(cryoDateMilisValue ? dayjs.utc(cryoDateMilisValue) : dayjs(visit.procedureDateFrom))
            .format('DDMMMYYYY')
            .toUpperCase()}^FS

          ^FX Notes
          ^FX^FO${topPosition},60^GB20,100
          ^FO${topPosition},60^FB845,1,1,L,0^FWB^AG^A0B,25,24^FD${
            textElipsis(remainSpecimens[i].specimenInfo, 23) || '--'
          }^FS
          `
          topPosition += 30
        }
      }

      // Cut Ticket
      cmds += '^MMC,N'
      cmds += '^XZ'
      ticketIndex++
    }

    const printPayload = [cmds]

    await sendPrintJob(selectedPrinter, printPayload)

    resolve({ success: true })
  })

const hasEggSpecimens = (specimens) => {
  return specimens.some((item) => item.specimenType === SPECIMEN_TYPE_EGG)
}
