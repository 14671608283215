<template>
  <TicketCardDetail
    :title="title"
    :patient-data="patient"
    :shared-patients="sharedPatients"
    :isErasable="isErasable"
    @onEditSpecimens="onClickEditSpecimens"
    :deleteButton="deleteButton"
    :isExport="isExport"
    :isEMRTicket="isEMRTicket"
    :is-cryo-shipper-ticket="isCryoShipperTicket"
    :ticketId="ticket.ticketId"
    :shipment-details="ticket.shipmentDetails"
    :robotTransfer="selectedTickets[0]?.robotTransfer"
    data-test="ticket-to-print__ticket-card"
  >
    <template v-slot:list>
      <TicketListDetail
        :beaconId="ticketBeaconBarcode"
        :date="date"
        :time="time"
        :headers="specimensList.headers || []"
        :shipment-details="ticket.shipmentDetails"
        :is-cryo-shipper-ticket="isCryoShipperTicket"
        :specimen-data="specimensList.data || []"
        :title="specimensList.title"
        :state="ticket.state"
        :isEMRTicket="isEMRTicket"
        :ticketProcedureType="ticket.procedureType"
        :robotLocations="ticket.robotLocations"
        :robotTransfer="ticket.robotTransfer"
        :screeningStatus="ticketScreeningStatus"
        :ticketType="ticketType"
        :ticket="ticket"
        :reprint-disabled="reprintDisabled"
        :show-ticket-action-buttons="showTicketActionButtons"
        :editDate="isDateEditable"
        @editDate="onClickEditDate"
        @complete-button-clicked="onClickEditSpecimens"
        @onClickCancel="onClickCancel"
        @review-audit-button-clicked="handleReviewAuditClick"
        @add-details-button-clicked="onAddDetailsButtonClicked"
        @edit-button-clicked="onEditButtonClicked"
      />
    </template>
  </TicketCardDetail>
</template>

<script setup lang="ts">
/**
 * To Do
 * Redesign architechture
 * This component is similar to -> src/components/TicketInfo/TicketInfo.vue
 */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import { v4 as uuidv4 } from 'uuid'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import TicketCardDetail from '@/components/TicketCardDetail/TicketCardDetail.vue'
import TicketListDetail from '@/components/TicketListDetail/TicketListDetail.vue'
import * as tableHeadersConstants from '@/constants/table-headers/ticket'
import { selectEmbryoTypeLabel } from '@/utils/'
import { fetchPatientsById } from '@/services/patients'
import * as ss from '@/config/session-storage-help'
import {
  EDIT_DATE_ROUTE,
  INVENTORY_UPDATE_ROUTE,
  SCAN_BEACON_ROUTE,
  SELECT_SPECIMENS_ROUTE,
  SELECT_SPECIMENS_TO_EXPORT_ROUTE,
  PATIENT_VIEW_ROUTE,
  SELECT_EMRBYOLOGIST_ROUTE
} from '@/constants/routes'

import {
  METHOD_FREEZE,
  TICKET_SCHEDULED,
  typeEmbryo,
  PROCEDURE_TYPE_BATCH,
  EXISTING_BEACON_STEP,
  EXISTING_BEACON_SCREENING_STATUS_POSITIVE,
  EXISTING_BEACON_SCREENING_STATUS_NEGATIVE,
  EXISTING_BEACON_SCREENING_STATUS_UNSCREENED,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EMBRYO_UPPERCASE,
  MATURITY_LEVEL_METAPHASE_II,
  PROCEDURE_TYPE_EXPORT,
  EMR_FREEZE,
  EMR_SINGLE_IMPORT,
  EMR_SOURCE_ID,
  COMPLETE_TICKET_ERROR,
  PROCEDURE_TYPE_FREEZE,
  PROCEDURE_TYPE_THAW,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  PROCEDURE_TYPE_CRYOSHIPPER
} from '@/constants'
import toast from '@/config/toast'
import { executeNextTicketStep } from '@/helpers/manageTicket'
import { generateActiveBeacons, getCryodeviceId } from '@/helpers/detailsHelper'
import { STEP_SELECTED_TICKET, STEP_HOW_MANY, STEP_DETAILS } from '@/constants/ticketSteps'
import {
  canUpdateInventory,
  isFreezeProcedure,
  isThawProcedure,
  isSingleImportProcedure,
  isExportProcedure,
  isInProgress,
  isScheduled,
  isDonationToPatientProcedure,
  isMissingCryoBeaconProcedure,
  canCompleteTicket,
  isBeaconMoveProcedure,
  isSubStatePending
} from '@/helpers/ticketState'
import { createDynamicHeaders } from '@/helpers/dynamicTableHeaders'
import { computed, onMounted, ref } from 'vue'

import { useTempCryoBeacon } from '@/composables/useTempCryoBeacon'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)

// const props = defineProps(['ticket', 'onClickCancel', 'ticketType', 'canDisableReprint'])

type Props = {
  ticket: any
  onClickCancel: any
  ticketType: string
  canDisableReprint: boolean
  showTicketActionButtons: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showTicketActionButtons: true
})

const { isUUID } = useTempCryoBeacon()

// const cryodevicesPerBeacon = ref([])
const beacons = ref<any>([])

const patient = ref<any>({})
const sharedPatientIds = ref([])
const sharedPatients = ref<any[]>([])

const router = useRouter()

const { embryoTypes } = useGetters('specimensModule')
const { selectedTickets, viewTicket } = useGetters('selectedTicketsModule')
const reprintDisabled = computed(() => {
  // May add validation to only do this for EMR tickets
  return (
    props.canDisableReprint &&
    !isSubStatePending(props.ticket) &&
    canCompleteTicket(props.ticket, viewTicket.value)
  )
})
const isDateEditable = computed(() => {
  const { ticketType } = viewTicket.value || ''
  if (
    ticketType &&
    [PROCEDURE_TYPE_BATCH.toLowerCase(), PROCEDURE_TYPE_CRYOSHIPPER.toLowerCase()].includes(
      ticketType.toLowerCase()
    )
  ) {
    return false
  }
  return (
    props.ticket?.state &&
    props.ticket.state.length > 0 &&
    props.ticket.state === TICKET_SCHEDULED &&
    !isEMRTicket.value &&
    !isBeaconMoveProcedure(props.ticket)
  )
})
const isErasable = computed(() => {
  const isFreeze = props.ticket.procedureType === PROCEDURE_TYPE_FREEZE
  const isThaw = props.ticket.procedureType === PROCEDURE_TYPE_THAW
  const isFreezeErasable = isFreeze && isScheduled(props.ticket) && !thereAreSpecimens.value
  const isThawErasable = isThaw && !isEMRTicket.value
  return isFreezeErasable || isThawErasable
})
const deleteButton = computed(() => {
  return isErasable.value && 'right'
})
const title = computed(() => {
  if (!props.ticket?.procedureName) {
    return ''
  }
  return `${
    props.ticket.procedureName.includes(typeEmbryo.egg)
      ? props.ticket.procedureName.replace(typeEmbryo.egg, typeEmbryo.replace)
      : props.ticket.procedureName || ''
  }`
})
const date = computed(() => {
  return (props.ticket.procedureTime && dayjs(props.ticket.procedureTime).format('DDMMMYYYY')) || ''
})
const time = computed(() => {
  return (props.ticket.procedureTime && dayjs(props.ticket.procedureTime).format('hh:mm A')) || ''
})
const ticketBeaconBarcode = computed(() => {
  return props.ticket.state &&
    props.ticket.beaconBarcode &&
    !(
      isEMRTicket.value &&
      props.ticket.state === TICKET_SCHEDULED &&
      props.ticket.procedureType === PROCEDURE_TYPE_FREEZE
    )
    ? props.ticket.beaconBarcode
    : '--'
})
const thereAreSpecimens = computed(() => {
  return !!(props.ticket.specimens && props.ticket.specimens.length > 0)
})
const tableHeaders = computed(() => {
  let specimenType = thereAreSpecimens.value && props.ticket.specimens[0].specimenType
  if (specimenType && props.ticket.procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
    specimenType = specimenType.toLowerCase()
    return tableHeadersConstants.specimenImportHeaders(specimenType)
  }
  if (specimenType) {
    specimenType = specimenType.toLowerCase()
    return createDynamicHeaders({
      procedureType: props.ticket.procedureType,
      specimenType,
      reprintDisabled: reprintDisabled.value
    })
  }
  return []
})
const specimensList = computed(() => {
  if (thereAreSpecimens.value) {
    return {
      title: `${selectedSpecimens().length} Cryodevice${selectedSpecimens().length > 1 ? 's' : ''}`,
      headers: tableHeaders.value,
      data: selectedSpecimens()
    }
  }
  return {}
})
const isEMRTicket = computed(() => {
  return props.ticket.source === EMR_SOURCE_ID
})

const isExport = computed(() => {
  return props.ticket.procedureType === PROCEDURE_TYPE_EXPORT && props.ticket.metadata
    ? Object.freeze({ display: true, type: props.ticket.metadata.exportReason.type })
    : Object.freeze({ display: false })
})
const ticketScreeningStatus = computed(() => {
  if (props.ticket && props.ticket.screeningStatus) {
    return props.ticket.screeningStatus
  }
  if (props.ticket && props.ticket.cryoDevice && props.ticket.cryoDevice.length) {
    return props.ticket.cryoDevice[0].screeningStatus
  }
  if (props.ticket && props.ticket.specimens && props.ticket.specimens.length) {
    return props.ticket.specimens[0].screeningStatus
  }
  return 0
})
const ticketSpecimenType = computed(() => {
  const { specimenType } = props.ticket.specimens.length && props.ticket.specimens[0]
  return specimenType === SPECIMEN_TYPE_EGG ? SPECIMEN_TYPE_OOCYTE : specimenType
})
const isScheduledDonateToPatientTicket = computed(() => {
  return isScheduled(props.ticket) && isDonationToPatientProcedure(props.ticket)
})

const isCryoShipperTicket = computed(() => {
  return props.ticket?.procedure === PROCEDURE_TYPE_CRYOSHIPPER
})

const { fetchEmbryoTypes } = useActions('specimensModule')
const { getBeacons } = useActions('beaconsModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { setPatients } = useActions('patientGroupModule')
const { setCurrentTicketStep } = useActions('newTicketModule')

const onClickEditDate = () => {
  ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')
  router.push(EDIT_DATE_ROUTE)
}
// const onUpdateIventoryClicked = () => {
//   if (canUpdateInventory(props.ticket)) {
//     const inventoryUpdate = INVENTORY_UPDATE_ROUTE(
//       props.ticket.globalPatientNumber,
//       props.ticket.ticketId
//     )
//     router.push(inventoryUpdate)
//   }
// }
const handleReviewAuditClick = () => {
  return router.push(PATIENT_VIEW_ROUTE(patient.value.globalPatientNumber))
}
const getExportTicketBeaconBarcode = () => {
  const [
    {
      metadata: {
        exportReason: {
          details: {
            metadata: { beaconBarcode }
          }
        }
      }
    }
  ] = selectedTickets.value
  return beaconBarcode
}
const handleEMRTicketsCompletion = async () => {
  switch (true) {
    case isFreezeProcedure(props.ticket) && isScheduled(props.ticket): {
      // NOTE: This had no effect on session storage and was mutating the prop, which is wrong
      // props.ticket.patient = patient.value
      ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: EMR_FREEZE,
        currentTicketStep: STEP_HOW_MANY,
        selectedCryoDeviceType: getCryodeviceId(props.ticket?.specimens[0]?.cryodeviceTypeId),
        beacons: generateActiveBeacons(),
        ticketData: {
          ...props.ticket,
          patient: patient.value
        }
      })
      router.push({ name: nextStepPath })
      break
    }
    case isSingleImportProcedure(props.ticket) && isScheduled(props.ticket):
      await completeSingleImportEMRTicket()
      break
    case isFreezeProcedure(props.ticket) && isInProgress(props.ticket):
      router.push({
        path: SELECT_EMRBYOLOGIST_ROUTE(props.ticket.ticketId),
        query: {
          procedureTime: props.ticket.procedureTime,
          performedOn: props.ticket.performedOn || null,
          embryologistId: props.ticket.embryologistId || null
        }
      })
      break
    case isScheduledDonateToPatientTicket.value && Boolean(isUUID(getExportTicketBeaconBarcode())):
      router.push(SCAN_BEACON_ROUTE)
      break
    default:
      break
  }
}
const handleRegularTicketsCompletion = async () => {
  if (!canCompleteTicket(props.ticket, viewTicket.value)) {
    return
  }
  switch (true) {
    case isThawProcedure(props.ticket):
      ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')
      router.push(SELECT_SPECIMENS_ROUTE)
      break
    case isFreezeProcedure(props.ticket):
      ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')
      // eslint-disable-next-line no-case-declarations
      const specimenType = props.ticket.procedure.includes(SPECIMEN_TYPE_EMBRYO_UPPERCASE)
        ? SPECIMEN_TYPE_EMBRYO
        : SPECIMEN_TYPE_EGG
      setPatients([patient.value, ...sharedPatients.value])
      // eslint-disable-next-line no-case-declarations
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: METHOD_FREEZE,
        currentTicketStep: STEP_SELECTED_TICKET,
        patients: [patient.value],
        specimenType,
        procedureDate: {
          procedureDateFrom: props.ticket.procedureTime,
          // NOTE: Procedure date TO to should not be used to FREEZE procedures, and this should be only for displaying purposes
          procedureDateTo: null
        }
      })

      router.push({ name: nextStepPath })
      break
    case isExportProcedure(props.ticket):
    case isDonationToPatientProcedure(props.ticket):
      // eslint-disable-next-line no-lone-blocks
      ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')
      // eslint-disable-next-line no-lone-blocks
      ss.setFieldSessionStorage('exportEditType', props.ticket.specimens[0].specimenType, 'process')
      // eslint-disable-next-line no-lone-blocks
      router.push(SELECT_SPECIMENS_TO_EXPORT_ROUTE)
      break
    case isMissingCryoBeaconProcedure(props.ticket):
      // this is a special case please refer to the point 24 on Missing CryoBeacon PRD
      // When viewing the ticket if “Complete Ticket” is hit the User will be directed to the page to reconcile the inventory.
      router.push(INVENTORY_UPDATE_ROUTE(props.ticket.globalPatientNumber, props.ticket.ticketId))
      break
    default:
      break
  }
}
const onAddDetailsButtonClicked = () => {
  switch (true) {
    case isFreezeProcedure(props.ticket) || isThawProcedure(props.ticket):
      router.push({
        path: SELECT_EMRBYOLOGIST_ROUTE(props.ticket.ticketId),
        query: {
          procedureTime: props.ticket.procedureTime,
          performedOn: props.ticket.performedOn || null,
          embryologistId: props.ticket.embryologistId || null
        }
      })
      break
    case canUpdateInventory(props.ticket, viewTicket.value):
      router.push(`/inventory-update/${props.ticket.globalPatientNumber}/${props.ticket.ticketId}`)
      break
    default:
      break
  }
}
const onEditButtonClicked = () => {
  router.push(INVENTORY_UPDATE_ROUTE(props.ticket.globalPatientNumber, props.ticket.ticketId))
}
const onClickEditSpecimens = () => {
  try {
    if (isEMRTicket.value) {
      return handleEMRTicketsCompletion()
    }
    return handleRegularTicketsCompletion()
  } catch {
    toast.error({ title: COMPLETE_TICKET_ERROR })
  }
  return null
}
const setSpecimensPerCryodeviceCount = () => {
  const specimens = [...props.ticket.specimens]
  const cryoDevicesArray = new Array(specimens.map((specimen) => Number(specimen.specimenCount)))
  return cryoDevicesArray
}
const completeSingleImportEMRTicket = async () => {
  updateBeacons()
  const updateTicketImportStatus = async () => {
    await ss.setFieldSessionStorage('selectedTicketToEdit', props.ticket, 'process')
    const specimenType = props.ticket?.specimens[0]?.specimenType
    const selectedCryoDeviceType = getCryodeviceId(props.ticket?.specimens[0]?.cryodeviceTypeId)
    const procedureDate = {
      procedureDateFrom: props.ticket.procedureTime,
      procedureDateTo: props.ticket.procedureTimeTo
    }
    await executeNextTicketStep({
      selectedMethod: EMR_SINGLE_IMPORT,
      currentTicketStep: STEP_HOW_MANY,
      selectedCryoDeviceType,
      beacons: generateActiveBeacons(),
      ticketData: props.ticket,
      patients: [patient.value],
      procedureDate,
      specimenType
    })
  }

  const shouldAddExistingBeacon = async () => {
    const [firstBeacon] = beacons.value
    const numCryodevices = firstBeacon?.cryoDevice ? firstBeacon?.cryoDevice.length : 0
    const maxCryodevicesPerBeacon = ss.getFieldSessionStorage('siteProperties', [
      'ticket.cryodevice.max-number'
    ])
    const beaconsStatus = await getScreeningStatusBeacons()
    return beaconsStatus === EXISTING_BEACON_STEP && numCryodevices <= maxCryodevicesPerBeacon
  }

  await updateTicketImportStatus()
  await shouldAddExistingBeacon()
  await setCurrentTicketStep(STEP_DETAILS)
  router.push('/details')
}
const getScreeningStatusBeacons = async () => {
  displaySpinner('Validating Patient Inventory')
  const numCryoBeacons = beacons.value.length
  const [beacon] = beacons.value
  const numCryodevices = beacon.cryoDevice.length
  const [cryoDevice] = beacon.cryoDevice
  const { screeningStatus } = cryoDevice

  if (screeningStatus) {
    const params = {
      patientId: props.ticket.globalPatientNumber,
      screeningStatus: getScreeningStatus(screeningStatus),
      numCryodevices,
      numCryoBeacons
    }

    const responseBeacons = await getBeacons(params)
    // eslint-disable-next-line no-lone-blocks
    hideSpinner()
    return responseBeacons
  }
  return false
}
const getScreeningStatus = (id) => {
  let status = ''
  switch (id) {
    case 1:
      status = EXISTING_BEACON_SCREENING_STATUS_POSITIVE
      break
    case 2:
      status = EXISTING_BEACON_SCREENING_STATUS_NEGATIVE
      break
    case 3:
      status = EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
      break
    default:
      status = EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
      break
  }
  return status
}
const updateBeacons = () => {
  const newBeacons: any[] = []
  const currentTicket = props.ticket
  let currentSpecimenPosition = 0
  const newBeaconsArray = setSpecimensPerCryodeviceCount()
  // cryodevicesPerBeacon.value = newBeaconsArray
  newBeaconsArray.forEach((beacon) => {
    const newBeacon: any = {
      beaconId: uuidv4(),
      cryoDevice: [],
      active: false,
      done: false
    }
    beacon.forEach((cryodeviceCount) => {
      const { cryodeviceBarcode } = currentTicket.specimens[currentSpecimenPosition]
      const { screeningStatus } = currentTicket.specimens[currentSpecimenPosition]
      const cryodevice: any = {
        beaconBarcode: '',
        ...(ticketSpecimenType.value === SPECIMEN_TYPE_EMBRYO && {
          embryo: {
            biopsy: 'No',
            embryoNumber: null,
            embryoType: null,
            grade: null
          }
        }),
        ...(ticketSpecimenType.value === SPECIMEN_TYPE_EGG && {
          oocyte: {
            maturityLevel: MATURITY_LEVEL_METAPHASE_II
          }
        }),
        cryodeviceBarcode,
        screeningStatus,
        specimenCount: cryodeviceCount,
        specimenId: '',
        specimenInfo: '',
        specimenType: ticketSpecimenType.value
      }
      newBeacon.cryoDevice.push(cryodevice)
      currentSpecimenPosition++
    })
    newBeacons.push(newBeacon)
  })
  newBeacons[0].active = true
  beacons.value = newBeacons
}
const selectedSpecimens = () => {
  return props.ticket.specimens
    .filter((specimen) => specimen.inSelectedTicket)
    .map((specimen) => ({
      ...specimen,
      embryoTypeLabel: selectEmbryoTypeLabel(specimen, embryoTypes.value)
    }))
}
const setPatient = async () => {
  const [firstName, ...lastName] = props.ticket.patientName?.split(' ') || ['', '']
  patient.value = {
    firstName,
    lastName: lastName?.join(' ') || '',
    fullName: props.ticket.patientName || '',
    globalPatientNumber: props.ticket.globalPatientNumber,
    identificationNumber: props.ticket.identificationNumber,
    patientDob: props.ticket.patientDob
  }
  sharedPatientIds.value = props.ticket.sharedPatientIds || []
  if (sharedPatientIds.value.length) {
    try {
      sharedPatients.value = (await fetchPatientsById(sharedPatientIds.value)).map(
        (sharedPatient) => ({
          ...sharedPatient,
          fullName: `${sharedPatient.firstName} ${sharedPatient.lastName}`
        })
      )
    } catch (e) {
      console.error('ERROR', e)
    }
  }
}

onMounted(() => {
  fetchEmbryoTypes()
  setPatient()
})
</script>
