import * as ss from '@/config/session-storage-help'
import { getScreeningStatusesApi } from '@/services/screeningStatuses'

const $ss = ss

export default {
  async fetchScreeningStatus({ commit }) {
    const screeningStatus = $ss.getFieldSessionStorage('screeningStatus', 'data')

    commit('setScreeningStatusLoading', true)
    commit('setScreeningStatusError', false)

    if (!screeningStatus.length) {
      try {
        const response = await getScreeningStatusesApi()
        const { data } = response
        commit('setScreeningStatus', data)
        $ss.setFieldSessionStorage('data', data, 'screeningStatus')
      } catch (error) {
        commit('setScreeningStatusError', true)
        commit('setScreeningStatusLoading', false)
      }
    } else {
      commit('setScreeningStatus', screeningStatus)
    }
    commit('setScreeningStatusLoading', false)
  }
}
