<template>
  <div data-test="cryos-per-beacon-form" class="flex w-2/3">
    <div class="w-6/12 h-18 pr-3">
      <input-field
        data-test="total-cryo-count"
        :inputLabel="showInputLabel ? `How many ${specimenTypeLabel} in total?` : ''"
        inputName="totalCryoCount"
        v-model.lazy="totalCryoCount"
        :inputValue="totalCryoCount"
        placeholderColor="text-black"
        labelColor="tmrw-blue"
        borderColor="tmrw-blue"
        @focus="changeInputLabelVisibility(true)"
        :inputPlaceholder="showInputLabel ? '' : `How many ${specimenTypeLabel} in total?`"
        :isDisabled="isInputDisabled"
        maxLength="2"
        isNumeric
        addPaddingBottom
      />
    </div>
    <div class="w-6/12 h-18 pr-3">
      <input-field
        data-test="embryos-per-cryo-device"
        :inputLabel="showInputLabel ? `How many ${specimenTypeLabel} per cryodevice?` : ''"
        inputName="embryosPerCryoDevice"
        v-model.lazy="embryosPerCryoDevice"
        :inputValue="embryosPerCryoDevice"
        placeholderColor="text-black"
        labelColor="tmrw-blue"
        borderColor="tmrw-blue"
        :inputPlaceholder="showInputLabel ? '' : `How many ${specimenTypeLabel} per cryodevice?`"
        :isDisabled="isInputDisabled"
        maxLength="2"
        isNumeric
        addPaddingBottom
      />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/InputField/InputField.vue'

import {
  embryoTypeValues,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGGS,
  SPECIMEN_TYPE_OOCYTE
} from '@/constants'
import * as ss from '@/config/session-storage-help'

export default {
  name: 'cryos-per-beacon-form',
  data() {
    return {
      specimenTypeEmbryo: SPECIMEN_TYPE_EMBRYO,
      specimenTypeEggs: SPECIMEN_TYPE_EGGS,
      totalCryoCount: null,
      embryosPerCryoDevice: null,
      dist: [],
      isInputDisabled: false,
      embryoTypeValues,
      showInputLabel: false
    }
  },
  emits: ['onUpdate'],
  props: {
    specimenType: {
      required: true,
      type: String
    }
  },
  methods: {
    resetCount() {
      this.embryosPerCryoDevice = 1
      this.totalCryoCount = 1
      this.isInputDisabled = false
    },
    applyCustomState(specimenTotal) {
      this.isInputDisabled = false
      this.inputType = 'text'
      this.embryosPerCryoDevice = 'Customized'
      this.totalCryoCount = specimenTotal
      this.isInputDisabled = true
    },
    totalCryoCountValidator(value) {
      // Round value in case of decimal
      const roundedCryoCount = Math.round(value)
      this.totalCryoCount = roundedCryoCount

      // totalCryoCount must be equal or more than 1
      this.totalCryoCount = roundedCryoCount < 1 ? 1 : roundedCryoCount

      // totalCryoCount must be equal or less than 100
      this.totalCryoCount = roundedCryoCount > 100 ? 100 : roundedCryoCount

      // embryosPerCryoDevice must be at least equal to totalCryoCount
      this.embryosPerCryoDevice = roundedCryoCount < this.embryosPerCryoDevice
        ? roundedCryoCount
        : this.embryosPerCryoDevice
    },
    embryosPerCryoDeviceValidator(value) {
      // Round value in case of decimal
      const roundedEmbryosPerCryo = Math.round(value)
      this.embryosPerCryoDevice = roundedEmbryosPerCryo

      // embryosPerCryoDevice must be equal or more than 1
      this.embryosPerCryoDevice = roundedEmbryosPerCryo < 1 ? 1 : roundedEmbryosPerCryo

      // embryosPerCryoDevice must be equal or less than 10
      this.embryosPerCryoDevice = roundedEmbryosPerCryo > 10 ? 10 : roundedEmbryosPerCryo

      // totalCryoCount must be at least equal to embryosPerCryoDevice
      this.totalCryoCount = roundedEmbryosPerCryo > this.totalCryoCount ? roundedEmbryosPerCryo : this.totalCryoCount
    },
    calculateDistPerBeacon(totalCryoCount, embryosPerCryoDevice) {
      const maxCryodevicesPerBeacon = ss.getFieldSessionStorage('siteProperties')['ticket.cryodevice.max-number']
      const cryoDevices = Math.ceil(totalCryoCount / embryosPerCryoDevice)
      const totalCryoDevices = []
      const totalSpecimens = embryosPerCryoDevice * cryoDevices
      let specimenCount = 0

      for (let i = 0; i < cryoDevices; i++) {
        specimenCount += embryosPerCryoDevice
        if (specimenCount > this.totalCryoCount) {
          const specimensDelta = embryosPerCryoDevice - (totalSpecimens - this.totalCryoCount)
          totalCryoDevices.push(specimensDelta)
        } else {
          totalCryoDevices.push(embryosPerCryoDevice)
        }
      }

      const dist = new Array(Math.ceil(totalCryoDevices.length / maxCryodevicesPerBeacon))
        .fill()
        .map(() => totalCryoDevices.splice(0, maxCryodevicesPerBeacon))

      this.dist = [...dist]
      this.$emit('onUpdate', dist)
      return dist // Exam. (4 - 2) = [[2,2]] || (46 - 5) = [[5,5,5,5,5,5,5,5], [5,1]]
    },
    changeInputLabelVisibility(value) {
      this.showInputLabel = value
    }
  },
  computed: {
    specimenTypeLabel() {
      return this.specimenType === SPECIMEN_TYPE_EMBRYO ? `${SPECIMEN_TYPE_EMBRYO}s` : `${SPECIMEN_TYPE_OOCYTE}s`
    }
  },
  watch: {
    totalCryoCount(newValue) {
      if (!newValue && newValue !== 0) {
        if (!this.embryosPerCryoDevice) {
          this.changeInputLabelVisibility(false)
        }
        return
      }
      this.changeInputLabelVisibility(true)
      if (newValue !== 'Customized') {
        const value = Number(newValue)
        this.totalCryoCountValidator(value)
        const embryosPerCryoDevice = Number(this.embryosPerCryoDevice)
        if (embryosPerCryoDevice > 0 && value > 0) {
          this.calculateDistPerBeacon(value, embryosPerCryoDevice)
        }
      }
    },
    embryosPerCryoDevice(newValue) {
      if (!newValue && newValue !== 0) return
      if (newValue !== 'Customized') {
        const value = Number(newValue)
        this.embryosPerCryoDeviceValidator(value)
        const totalCryoCount = Number(this.totalCryoCount)
        if (totalCryoCount > 0 && value > 0) {
          this.calculateDistPerBeacon(totalCryoCount, value)
        }
      }
    }
  },
  components: {
    InputField
  }
}
</script>
