<template>
  <div data-test="scroll-table-row__robot-locations" class="leading-1 flex">
    <img
      v-if="cryoBeaconLocation.imageSrc"
      :src="cryoBeaconLocation.imageSrc"
      :data-test="`transfer-type-${cryoBeaconLocation.transferStatus}`"
      ref="transfer-icon"
      class="transfer-icon w-10 h-auto mr-1"
    />
    <span v-if="cryoBeaconLocation.robotNumber" data-test="cryorobot-location">{{ locationName }}</span>
    <span v-else data-test="location-na">N/A</span>
  </div>
</template>

<script>
import { CRYOROBOT, DESTINATION, NON_TMRW_LOCATION } from '@/constants'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation'

export default {
  name: 'scroll-table-robot-locations',
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    options: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  computed: {
    locationName() {
      return this.cryoBeaconLocation.isTemporary  ? NON_TMRW_LOCATION : `${CRYOROBOT} ${this.cryoBeaconLocation.robotNumber}`
    },
    cryoBeaconLocation() {
      if (this.options?.isDestination) {
        const destination = this.value.find((location) => location.type === DESTINATION)
        return destination?.siteName || '--'
      }
      const locationData = getCurrentCryoBeaconLocation(this.value)
      return Object.keys(locationData).length ? locationData : {}
    }
  }
}
</script>
