<template>
  <MainContainer data-test="cancel-ticket" grid-slots="3">
    <top-header> Cancel Procedure </top-header>
    <ActionBar data-test="cancel-ticket__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          Why are you canceling this ticket?
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          ref="btn-select-reason-next"
          btnText="Cancel Ticket"
          btnType="button"
          btnStyle="primary"
          :isDisabled="isValid"
          :isLoading="isLoading"
          @click="cancelTicket"
        />
      </template>
    </ActionBar>
    <cancel-reason :isValid="getReason" />
  </MainContainer>
</template>

<script lang="ts">
import { ApiCallsFactory } from '@/factories/apiCalls/index'
import * as ss from '@/config/session-storage-help'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CancelReason from '@/components/CancelReason/CancelReason.vue'
import toast from '@/config/toast'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import {
  OTHER_VALUE,
  CANCEL_TICKET_SUCCESS_MESSAGE,
  CANCEL_TICKET_ERROR_MESSAGE,
  PROCEDURE_TYPE_BEACON_MOVE
} from '@/constants'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import { STEP_INITIAL } from '@/constants/ticketSteps'

type Router = {
  push: ({ name }: { name: string }) => void
}

interface CancelTypes {
  this: {
    $router: Router
    cancelReason: string
    constructURL: (b: string) => void
    isLoading: boolean
    appBaseConfig: {
      siteId: string
      clinicId: string
      cpId: string
      token: string
      userId: string
    }
  }
}

export default {
  name: 'select-reason-to-export',
  components: {
    TopHeader,
    ButtonDynamic,
    CancelReason,
    ActionBar,
    DynamicTitle,
    MainContainer
  },
  data() {
    return {
      cancelReason: undefined as string | undefined,
      appBaseConfig: {
        siteId: ss.getFieldSessionStorage('config', 'siteId') || null,
        clinicId: ss.getFieldSessionStorage('config', 'clinicId') || null,
        cpId: ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
        token: ss.getFieldSessionStorage('user', 'token') || null,
        userId: ss.getFieldSessionStorage('user', 'userId') || null
      } as object | any,
      isLoading: false as boolean
    }
  },
  methods: {
    async cancelTicket(this: CancelTypes['this']): Promise<void> {
      try {
        this.isLoading = true
        const activeTicket = ss.getFieldSessionStorage('process', 'selectedTicketToCancel') || null
        const { ticketId, procedureType } = activeTicket
        const url = `/tickets/${ticketId}`
        const userId = ss.getFieldSessionStorage('user', 'userId') || null
        const apiCall = new ApiCallsFactory(url, this.appBaseConfig.token)
        await apiCall.deleteData(
          this.cancelReason,
          userId,
          procedureType === PROCEDURE_TYPE_BEACON_MOVE
        )
        toast.success(CANCEL_TICKET_SUCCESS_MESSAGE)
        this.$router.push({
          name:
            procedureType === PROCEDURE_TYPE_BEACON_MOVE
              ? STEP_BIOREPOSITORY_FLIGHTBOARD
              : STEP_INITIAL
        })
      } catch (err) {
        this.isLoading = false
        toast.error({ title: CANCEL_TICKET_ERROR_MESSAGE })
      }
    },
    getReason(this: CancelTypes['this'], reason: string): void {
      this.cancelReason = reason
    }
  },
  computed: {
    isValid(this: CancelTypes['this']): Boolean {
      switch (this.cancelReason) {
        case OTHER_VALUE:
          return this.cancelReason === OTHER_VALUE
        default:
          return !this.cancelReason
      }
    }
  }
}
</script>
