/**
 * Error Handler
 * @param module reference to the module name
 * @param error  error message
 */

const ErrorHandler = (
  module: string,
  error: string
) => {
  // disable the eslint in order to be able to print the error
  // eslint-disable-next-line no-console
  console.error(`${module}: ${error}`)
}

export default ErrorHandler
