<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    data-testid="customize-beacon-distribution"
    class="
      modal-view
      flex
      items-center
      justify-center
      fixed
      left-0
      top-0
      z-10000
      w-screen
      h-screen
      bg-black bg-opacity-50
    "
  >
    <div class="bg-white p-4 w-4/12 rounded-md text-black">
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left">
            Customize {{ `${CryoBeacon} ${beaconCount + 1}` }}
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            data-test="close-button"
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="closeCustomizeModal"
          />
        </template>
      </ActionBar>
      <p class="mt-8 mb-2 text-sm text-tmrw-blue">Total Amount</p>
      <p class="w-full border-b border-tmrw-blue border-dotted pb-1 font-bold">
        {{ totalItems }} Specimens
      </p>
      <div class="flex flex-wrap bg-tmrw-green-light my-4 rounded-md p-2">
        <div class="w-1/12">&nbsp;</div>
        <div class="w-10/12">
          <p class="text-center text-tmrw-blue text-lg font-semibold">
            {{ getCyodevicesCount }} cryodevices total.
          </p>
          <p class="text-center text-tmrw-blue text-md font-semibold">
            Specimen amount per cryodevice
          </p>
        </div>
        <div class="w-1/12">&nbsp;</div>
        <div class="w-2/12">&nbsp;</div>
        <div class="flex flex-wrap w-8/12 justify-center items-center">
          <div class="w-1/4" v-for="(box, key) in items.slice(0, 4)" :key="key">
            <input-field
              :data-testid="`customize-beacon--${key}`"
              inputClass="p-2 m-2 text-center text-tmrw-blue text-3xl custom-beacon-item custom-beacon-item-numeric"
              inputType="number"
              maxLength="2"
              :inputName="`box-top-${key}`"
              borderColor="tmrw-blue"
              isNumeric
              isBlueOnWhite
              v-model="items[key]"
              :inputValue="items[key]"
              :clearValueOnClick="true"
              hasFontInconsolata
              :minNumber="0"
              :maxNumber="10"
            />
          </div>
          <div class="w-1/4" v-for="(box, key) in items.slice(4)" :key="key + 4">
            <input-field
              :data-testid="`customize-beacon--${key + 4}`"
              inputClass="p-2 m-2 text-center text-tmrw-blue text-3xl custom-beacon-item-numeric"
              inputType="number"
              maxLength="2"
              :inputName="`box-bottom-${key}`"
              borderColor="tmrw-blue"
              isNumeric
              isBlueOnWhite
              v-model="items[key + 4]"
              :inputValue="items[key + 4]"
              :clearValueOnClick="true"
              hasFontInconsolata
              :minNumber="0"
              :maxNumber="10"
            />
          </div>
        </div>
        <div class="w-2/12">&nbsp;</div>
      </div>
      <div
        v-if="isTotalItemsDifferent"
        class="w-12/12 mb-4 text-tmrw-warning"
        data-test="customize-beacon-dist__warning"
      >
        <i class="mx-2 fa fa-exclamation-triangle text-tmrw-warning" />You've changed the specimen
        count. If you hit Done, the total amount will change.
      </div>
      <div class="flex justify-between items-center mt-4">
        <button-dynamic
          btnType="button"
          :btnText="`Add new ${CryoBeacon}`"
          btnStyle="transparent-blue"
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="addNewBeacon"
        />
        <button-dynamic
          :isDisabled="isEmpty"
          data-test="done-button"
          btnType="button"
          btnText="Done"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle"
          @click="ApplyCustomDistribution"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import InputField from '@/components/InputField/InputField.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import { CRYOBEACON } from '@/constants'

export default {
  name: 'customize-beacon-dist',
  data() {
    return {
      CryoBeacon: CRYOBEACON,
      initialItems: [...this.items],
      totalSpecimensCount: 0,
      totalCryodevicesCount: 0
    }
  },
  emits: ['cancelCustomDistribution', 'applyCustomDistribution', 'addNewBeacon'],
  props: {
    items: {
      type: Array,
      required: false
    },
    beaconCount: {
      type: Number,
      required: true
    }
  },
  created() {
    // disabled the minus key event in order to to prevent negative values
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 45 || e.keyCode === 189) {
        return e.preventDefault()
      }
      return true
    })
  },
  computed: {
    initialTotalItems() {
      return this.initialItems.reduce((a, b) => Number(a) + Number(b), 0)
    },
    totalItems() {
      return this.items.reduce((a, b) => Number(a) + Number(b), 0)
    },
    isTotalItemsDifferent() {
      return this.totalItems !== this.initialTotalItems
    },
    getCyodevicesCount() {
      return this.items.reduce((totalCount, specimenCount) => totalCount + (specimenCount !== 0), 0)
    },
    isEmpty() {
      const tempItems = Array.from(this.items)
      const reducer = (accumulator, currentValue) => +accumulator + +currentValue
      return tempItems.reduce(reducer) <= 0
    }
  },
  methods: {
    closeCustomizeModal() {
      this.$emit('cancelCustomDistribution', this.initialItems)
    },
    ApplyCustomDistribution() {
      this.$emit('applyCustomDistribution')
    },
    addNewBeacon() {
      this.$emit('addNewBeacon')
    }
  },
  components: {
    ButtonDynamic,
    InputField,
    DynamicTitle,
    ActionBar
  }
}
</script>
<style lang="scss">
.custom-beacon-item-numeric {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
