<template>
  <section data-test="specimen-details__patients" class="flex flex-col">
    <loading-ui
      v-if="loadingPatients || loading"
      :modal="true"
      :message="loadingMessage"
    />
    <template v-else>
      <ActionBar
        data-test="specimen-details__patients-action-bar"
        cols-distribution="6/6"
      >
        <template v-slot:left-side>
          <dynamic-title
            title-type="h2"
            float-to="left"
            class="mb-2"
          >
            {{PATIENTS}}
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnType="button"
            btnText="Add Patient"
            showIcon
            btnStyle="secondary"
            addMarginBottom
            fontAwesomeIconClass="plus-circle"
            :isDisabled="isAtMaximumPatientsLimit"
            @click="addSharedPatient"
          />
        </template>
      </ActionBar>
      <PatientGroup
        :key="patientGroupKey"
        :patients="patients"
        :primaryPatientId="primaryPatientId"
        :canRemovePatient="true"
        :showViewPatientLink="true"
        :hideViewPatientId="patientId"
        :totalShared="totalSharedCryoDevices"
        @removePatient="handleRemovePatient"
      />
      <RemoveModal
        v-if="showRemoveModal"
        :showRemoveModal="showRemoveModal"
        :patient="selectedPatient"
        @cancelRemove="hideRemoveModal"
        @removePatient="removeSelectedPatient"
      />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  ADDED_SHARED_PATIENT,
  LOADING_PATIENTS,
  MAX_PATIENTS,
  PATIENTS,
  REMOVED_PATIENT_FROM_SPECIMEN
} from '@/constants'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import PatientGroup from '@/components/PatientGroup/PatientGroup.vue'
import toast from '@/config/toast'
import { ADD_SHARED_PATIENT_ROUTE } from '@/constants/routes'
import RemoveModal from './RemoveModal/RemoveModal.vue'

export default {
  name: 'Patients',
  props: {
    ids: {
      type: Array,
      required: true
    },
    specimenId: {
      type: [Number, String],
      required: true
    },
    cryodeviceBarcode: {
      type: [String],
      required: true
    },
    patientId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      selectedPatientId: 0,
      selectedPatient: {},
      showRemoveModal: false,
      patientGroupKey: 0,
      primaryPatientId: 0,
      LOADING_PATIENTS,
      PATIENTS
    }
  },
  async created() {
    this.primaryPatientId = Number(this.specimenDetails.cprId)
    await this.fetchPatientsWithCount(this.ids)
    if (this.sharedPatientAdded) {
      toast.success(ADDED_SHARED_PATIENT)
      this.resetSharedPatientAddedFlag()
    }
  },
  computed: {
    ...mapGetters('patientGroupModule', ['patients', 'totalSharedCryoDevices']),
    ...mapGetters('patientGroupModule', { loadingPatients: 'loading' }),
    ...mapGetters('specimensModule', ['errorMessage', 'sharedPatientAdded', 'specimenDetails']),
    ...mapGetters('specimensModule', { status: 'statusSpecimens' }),
    loadingMessage() {
      if (this.loadingPatients) return LOADING_PATIENTS
      return ''
    },
    isAtMaximumPatientsLimit() {
      return this.patients.length >= MAX_PATIENTS
    }
  },
  methods: {
    ...mapActions('specimensModule', ['removeSharedPatient', 'resetSharedPatientAddedFlag']),
    ...mapActions('patientGroupModule', ['fetchPatientsWithCount', 'removePatientFromGroup']),
    addSharedPatient() {
      const route = ADD_SHARED_PATIENT_ROUTE(this.specimenId, this.cryodeviceBarcode)
      this.$router.push(route)
    },
    selectPatient(id) {
      this.selectedPatientId = id
      this.selectedPatient = this.patients.find((patient) => patient.globalPatientNumber === id)
    },
    handleRemovePatient(id) {
      this.selectPatient(id)
      this.showRemoveModal = true
    },
    hideRemoveModal() {
      this.showRemoveModal = false
    },
    async removeSelectedPatient(reason) {
      const props = {
        specimenId: this.specimenId,
        patientId: this.selectedPatientId,
        reason
      }
      await this.removeSharedPatient(props)
      if (!this.errorMessage) {
        await this.removePatientFromGroup(this.selectedPatientId)
        toast.success(REMOVED_PATIENT_FROM_SPECIMEN)
      }
      this.hideRemoveModal()
    }
  },
  watch: {
    patients() {
      this.patientGroupKey++
    },
    status(newStatus) {
      switch (newStatus) {
        case 'loading':
          this.loading = true
          break
        case 'error':
          this.loading = false
          if (this.errorMessage) {
            toast.error({ title: this.errorMessage })
          }
          break
        default:
          this.loading = false
          break
      }
    }
  },
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    PatientGroup,
    RemoveModal
  }
}
</script>
