import pdfMake from 'pdfmake/build/pdfmake'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { VFS } from '@/assets/pdf/vfs_fonts'
import * as ss from '@/config/session-storage-help'
import { TICKET_OPEN_CAPITALS } from '@/constants'
import { LOGO_IVFOS, CALENDAR } from '@/assets/pdf/logos-base64'
import { contentParamsType, staticTypes, ticketType } from '@/helpers/printPDF/types/index'
import ErrorHandler from '../ErrorHandler/index'
import { bodyHeaders } from './static'
import { getRobotLayoutName } from '@/helpers/robotLayoutHelper'

dayjs.extend(utc)
pdfMake.vfs = VFS

/**
 * @getTableBodyObject -> Generates the table content
 * @param tableBodyItems object required
 * @returns Object
 * */

const getTableBodyObject = (tableBodyItems: object[]) => {
  if (tableBodyItems?.length) {
    const tableBodyObject = {
      style: 'tableTmrw',
      table: {
        widths: [45, 65, 50, 70, 40, 55, 55, 70],
        heights: [15],
        headerRows: 1,
        body: [bodyHeaders, ...tableBodyItems]
      },
      layout: 'lightHorizontalLines'
    }
    return tableBodyObject
  }
  return {}
}

/**
 * @pdfContent -> generates the content
 * @param user object required
 * @param config object required
 * @param today string required
 * @param ticketsScheduled array required
 * @param ticketsInProgress array required
 * @param ticketsCompleted array required
 * @returns
 * */

const pdfContent = (contentParams: contentParamsType, ticketsType: string) => {
  const { user, config, today, ticketsList } = contentParams
  return {
    pageMargins: [25, 25, 25, 25],
    content: [
      {
        alignment: 'right',
        columns: [
          {
            image: LOGO_IVFOS,
            width: 150
          },
          {
            color: '#5a5b5e',
            fontSize: 20,
            text: `${user.firstName} ${user.lastName}`
          }
        ]
      },
      '\n',
      {
        columns: [
          {
            image: CALENDAR,
            width: 8,
            height: 8
          },
          {
            text: ' ',
            width: 5,
            height: 5
          },
          {
            text: today.toUpperCase(),
            alignment: 'left',
            fontSize: 10,
            bold: true
          },
          {
            text: `${config.clinicName} | ${config.siteName}`,
            alignment: 'right',
            color: '#5a5b5e',
            fontSize: 10
          }
        ]
      },
      '\n\n',
      {
        columns: [
          {
            text: ticketsType === TICKET_OPEN_CAPITALS ? 'OPEN TICKETS' : 'COMPLETED TICKETS'
          }
        ]
      },
      '\n',
      getTableBodyObject(ticketsList)
    ],
    defaultStyle: {
      font: 'Futura'
    },
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: '#5a5b5e',
        margin: [0, 0, 0, 0]
      },
      tableTmrw: {
        fontSize: 10,
        color: '#5a5b5e',
        padding: [10, 10, 10, 10]
      },
      footerStyles: {
        fontSize: 10
      }
    }
  }
}

/**
 * @FligthBoardToPdf -> generates the PDF for the FligthBoard
 * @param data Array
 * @returns PDF content
 * */

const FligthBoardToPdf = (data: ticketType[][] | undefined, ticketsType: string) => {
  try {
    // get static data
    const user: staticTypes['user'] = ss.getFieldSessionStorage('user', null)
    const config: staticTypes['config'] = ss.getFieldSessionStorage('config', null)
    const today: Readonly<string> = dayjs().format('DD MMMM YYYY')

    // setup static references
    const ticketsData = {
      ticketsList: []
    }

    // if data is undefined
    if (!data) throw new Error(`tickets data cannot be ${data}`)

    // assign it FB data to local reference
    const totalTickets: ticketType[] = data.reduce(
      (acummulator, value) => acummulator.concat(value),
      []
    )

    // assign it values to ticketData
    totalTickets.forEach((ticket: ticketType) => {
      const getLocationString = (locationType: 'Source' | 'Destination'): string => {
        const location = ticket.robotLocations.find((loc) => loc.type === locationType)
        if (!location) return '--'
        const robotName = getRobotLayoutName(location.robotLayoutTypeId || 0)
        if (location.robotLayoutTypeId === 3) {
          return robotName
        } else {
          return `${robotName} ${location.robotNumber}`
        }
      }

      const originLocation = getLocationString('Source')
      const destinationLocation = getLocationString('Destination')

      const ticketData = [
        dayjs(ticket.procedureTime).format('DDMMMYYYY - h:mm A').toUpperCase(),
        ticket?.patientName,
        ticket?.identificationNumber,
        ticket?.patientDob ? dayjs(ticket.patientDob).format('DDMMMYYYY').toUpperCase() : '--',
        ticket?.ticketId,
        originLocation,
        destinationLocation,
        ticket?.procedureName
      ]

      // analyzes and assigns the corresponding values to each category
      return Object.assign(ticketsData.ticketsList, [...ticketsData.ticketsList, [...ticketData]])
    })

    const contentParams = {
      user,
      config,
      today,
      ticketsList: ticketsData.ticketsList
    }
    // generating the pdf content
    const content = pdfContent(contentParams, ticketsType)

    // returning the pdf
    return pdfMake.createPdf(content).open()
  } catch (error: any) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [customStack] = error.stack?.split(')')
    ErrorHandler('PrintToPdf', `\n \n ${customStack || error})`)
    throw error
  }
}

export { FligthBoardToPdf, pdfContent, getTableBodyObject }
