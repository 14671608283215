export default {
  beaconIdCheck: (state) => state.beaconIdCheck,
  beaconIdValidate: (state) => state.beaconIdValidate,
  beaconIdCheckMessage: (state) => state.beaconIdCheckMessage,
  singleBeaconReaderReads: (state) => state.singleBeaconReaderReads,
  beaconStep: (state) => state.beaconStep,
  beaconsList: (state) => state.beaconsList,
  beaconsSpecimens: (state) => state.beaconsSpecimens,
  currentBeacon: (state) => state.currentBeacon,
  isCompleted: (state) => state.isCompleted,
  beaconBarcodeNumber: (state) => state.beaconBarcodeNumber,
  scannedBeaconBarcodes: (state) => state.scannedBeaconBarcodes
}
