<template>
  <section
    class="flex justify-between items-end text-tmrw-blue-light py-3"
    :class="{ 'border-dashed border-b-1 border-tmrw-gray': showBorder }"
  >
    <!-- TODO: Validate if this element is necessary -->
    <div class="flex flex-row text-tmrw-blue-dark pb-0.5" v-if="ticketDetails.identificationNumber">
      <h1  data-test="patient-name-header" class="ml-2 font-exo text-4xl text-tmrw-blue-dark font-bold">
        {{ ticketDetails.patientName }}
      </h1>
      <span data-test="patient-id-dob" class="ml-4 text-center font-inconsolata flex items-end text-xl leading-6">
        Patient ID {{ ticketDetails.identificationNumber }} {{ formatPatientDob }}
      </span>
    </div>
    <div
      v-if="firstSpecimen"
      class="flex flex-row flex-end ml-2 font-exo text-xl text-tmrw-blue-dark font-bold items-end"
    >
      <ScreeningStatusLabel
        paddingSmall
        :id="firstSpecimen.screeningStatus"
        class="mr-6"
        wrapper-width="w-auto"
        text-style="bold"
      />
      <p data-test="cryobeacon-id-label" class="w-full py-0.5 pt-1.5">{{ CryoBeacon }} ID</p>
      <span data-test="cryobeacon-id" class="ml-2 text-lg font-normal py-0.5 pt-1.5 flex justify-center">
        {{ beaconBarcode }}
      </span>
    </div>
  </section>
</template>

<script setup>
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { CRYOBEACON } from '@/constants/index'
import { timeStampInventoryCryoDateFilter } from '@/filters/index'
import { computed, ref } from 'vue'

  const props = defineProps({
    ticketDetails: {
      type: Object,
      required: true
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: true
    }
  })

  const CryoBeacon = ref(CRYOBEACON)

  const firstSpecimen = computed(() => {
      const { ticketDetails } = props
      if (ticketDetails?.specimens && ticketDetails.specimens.length > 0) {
        return ticketDetails.specimens[0]
      }
      return null
    })
  const formatPatientDob = computed(() => props.ticketDetails.patientDob ?
    `- DOB: ${timeStampInventoryCryoDateFilter(props.ticketDetails.patientDob)}` : ''
  )
  const beaconBarcode = computed(() => firstSpecimen.value?.beaconBarcode)
</script>
