<template>
  <div class="flex">
    <Tooltip placement="bottom" :content="tooltipContent" :visible="tooltipVisible">
      <div class="toggle-container" @mouseover="onInfoIn" @mouseout="onInfoOut">
        <button
          data-shipper-visible="NOT_READY"
          @click="toggleVisible"
          class="toggle-button mr-5"
          :class="{ 'is-active': !isVisible }"
          ar
        >
          <i class="far fa-eye-slash" />
        </button>
        <button
          data-shipper-visible="READY"
          @click="toggleVisible"
          class="toggle-button"
          :class="{ 'is-active': isVisible }"
        >
          <i class="far fa-eye" />
        </button>
      </div>
    </Tooltip>

    <div>
      <button @mouseover="onInfoIn" @mouseout="onInfoOut">
        <img :src="infoCircle" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import infoCircle from '@/assets/info-circle.svg'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import useActions from '@/composables/useActions'
import toast from '@/config/toast'
import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'
import { ref } from 'vue'

const props = defineProps(['retrieveState', 'shipmentId'])

const isVisible = ref(props.retrieveState === 'READY')
const loading = ref(false)

const { updateCryoShipper } =
  useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')

const tooltipVisible = ref(false)
const tooltipContent = ref(
  'Hide/Show these Tickets from your CryoRobot’s Flight Board until they are ready?'
)

const toggleVisible = async (event) => {
  const oldVisible = isVisible.value

  try {
    if (loading.value) {
      return
    }

    tooltipVisible.value = false
    loading.value = true
    const newVisible = event.currentTarget.dataset.shipperVisible === 'READY'
    if (newVisible !== isVisible.value) {
      isVisible.value = newVisible
      await updateCryoShipper({
        retrieveState: event.currentTarget.dataset.shipperVisible,
        shipmentId: props.shipmentId
      })
    }
    loading.value = false
  } catch (err: any) {
    toast.error({ title: err.message })
    isVisible.value = oldVisible
    loading.value = false
  }
}

const onInfoIn = () => {
  tooltipVisible.value = true
}
const onInfoOut = () => {
  tooltipVisible.value = false
}
</script>
<style lang="scss" scoped>
.is-active {
  @apply bg-white;
  border: solid 1px rgba(0, 18, 46, 0.25);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
}
.toggle-container {
  @apply text-3.5xl p-1 rounded-lg mr-4;
  background-color: rgba(0, 0, 0, 0.25);
  border: solid 1.3px rgba(0, 18, 46, 0.5);
  box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.25);
}
.toggle-button {
  @apply w-20 h-9 rounded-lg;
  padding: 0.2px 6px 0.8px 6.7px;
}
</style>
