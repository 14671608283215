<template>
  <MainContainer data-test="users-form" :gridSlots="loadingData ? '2' : '4'">
    <top-header :back-btn="false">{{ pageTitle }}</top-header>
    <loading-ui v-if="loadingData" size="small" />
    <ActionBar v-if="!loadingData" data-test="users-form__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">Edit User</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnStyle="secondary"
          btnText="Cancel"
          @click="closeUser"
          addMarginRight
        />
        <button-dynamic
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="check-circle-regular"
          btnText="Done"
          :isLoading="loadingForm"
          :isDisabled="isUpdateButtonDisabled"
          @click="updateUser"
        />
      </template>
    </ActionBar>
    <div v-if="!loadingData" class="mt-2">
      <div class="rounded-md p-4 bg-white mt-4" data-test="users-form__fields-info">
        <div data-test="locked-user" v-if="isUserLocked" class="text-tmrw-error m-4">
          <i class="fas fa-lock text-lg mr-2" /> Locked User
        </div>
        <div data-test="unlocked-user" v-if="!isUserLocked" class="text-tmrw-success m-4">
          <i class="fas fa-unlock text-lg mr-2" /> Unlocked User
        </div>
        <div class="grid gap-4 p-4 sm:grid-cols lg:grid-cols-4">
          <InputField
            data-test="edit-user_login-name"
            v-model.trim="v$.form.loginName.$model"
            :inputValue="v$.form.loginName.$model"
            inputLabel="Login Name"
            inputName="Login Name"
            :hasError="formErrors && formErrorMsg.loginName"
            :isDisabled="isLoginNameReadonly"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingTop
            hasFontInconsolata
            addPaddingBottom
            maxLength="25"
          />
          <InputField
            v-model.trim="v$.form.firstName.$model"
            :inputValue="v$.form.firstName.$model"
            inputLabel="First Name"
            inputName="First Name"
            :hasError="formErrors && formErrorMsg.firstName"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingTop
            right-icon="edit-regular"
            hasFontInconsolata
            addPaddingBottom
          />
          <InputField
            v-model.trim="v$.form.lastName.$model"
            :inputValue="v$.form.lastName.$model"
            inputLabel="Last Name"
            inputName="Last Name"
            :hasError="formErrors && formErrorMsg.lastName"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            right-icon="edit-regular"
            addPaddingTop
            hasFontInconsolata
            addPaddingBottom
          />
          <InputField
            v-model.trim="v$.form.emailAddress.$model"
            :inputValue="v$.form.emailAddress.$model"
            inputLabel="Email Address"

            inputName="Email Address"
            :hasError="formErrors && formErrorMsg.emailAddress"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingTop
            hasFontInconsolata
            addPaddingBottom
            right-icon="edit-regular"
          />
          <SelectField
            v-model="v$.form.userRoleId.$model"
            :options="rolesOptions"
            label="Role"
            :error="formErrors && formErrorMsg.userRoleId"
            class="-mt-1"
            addPaddingTop
            hasFontInconsolata
            addPaddingBottom
          />
          <InputField
            v-model.trim="v$.form.clinicName.$model"
            :inputValue="v$.form.clinicName.$model"
            inputLabel="Clinic"

            inputName="Clinic"
            isDisabled
            :hasError="formErrors && formErrorMsg.clinicName"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingTop
            hasFontInconsolata
            addPaddingBottom
          />
          <InputField
            v-model.trim="v$.form.primarySite.$model"
            :inputValue="v$.form.primarySite.$model"
            inputLabel="Site"
            inputName="Site"
            isDisabled
            :hasError="formErrors && formErrorMsg.primarySite"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            addPaddingTop
            addPaddingBottom
            hasFontInconsolata
          />
        </div>
      </div>
      <div class="w-full flex flex-row mt-4" v-if="!loadingData">
        <div class="w-2/3 pr-4" data-test="automated-alerts-section">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Automated Alerts</dynamic-title>
          <div
            v-if="!loadingData"
            class="bg-white rounded-md p-4 grid grid-cols-2 grid-rows-3 mt-4"
          >
            <div :key="key" v-for="(field, key) in alerts">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @changeValue="updateEmailAlertValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
        <div class="w-1/3 pl-4" data-test="reporting-section">
          <dynamic-title titleType="h2" floatTo="none" addMarginTop>Reporting</dynamic-title>
          <div v-if="!loadingData" class="bg-white rounded-md p-4 grid grid-cols-1 mt-4">
            <div :key="key" v-for="(field, key) in reports">
              <OnOffToggle
                class="my-4"
                :value="field.enabled"
                @changeValue="updateEmailAlertValue(field.value)"
                :toggleValues="field"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'
import { required, email, alphaNum } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import toast from '@/config/toast'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { EMAIL_ALERT_LABELS, EmailAlertLabels } from '@/constants/email-alert-labels'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import InputField from '@/components/InputField/InputField.vue'
import SelectField from '@/components/CustomSelect/SelectField.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import OnOffToggle from '@/components/OnOffToggle/OnOffToggle.vue'
import { USER_TYPES } from '@/constants/index'
import { validateFields } from '@/helpers/formsFieldsValidation/index'

import { useAlertsComputed } from '../alerts-computed'
import { computed, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UsersModule } from '@/store/modules/users'
import { UpdateUserBody, UserAlert } from '@/services/user/types'

const router = useRouter()
const route = useRoute()

const loadingData = ref(true)
const loadingForm = ref(false)
const loginNameError = ref('')
const emailAddressError = ref('')

const state = reactive({
  form: {
    id: 0,
    loginName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    userRoleId: 0,
    clinicName: '',
    primarySite: ''
  }
})

const initialDataForm = ref({})
const formErrorMsg = ref({
        loginName: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        userRoleId: null,
        clinicName: null,
        primarySite: null
      })
const isLoginNameReadonly = ref(true)

const rules = computed(() => ({
    form: {
      loginName: {
        required,
        alphaNum
      },
      firstName: {
        required,
        lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
      },
      lastName: {
        required,
        lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
      },
      emailAddress: {
        required,
        email
      },
      userRoleId: {
        required,
        lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _]+$/i.test(value)
      },
      clinicName: {
        required,
        lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9 _()]+$/i.test(value)
      },
      primarySite: {
        required,
        alphaNumericHyphenBlankSpaces: (value) => /^[a-zA-Z0-9 \-_()]+$/i.test(value)
      }
    }
  }))

  const v$ = useVuelidate(rules, state)

  const { reports, alerts, userEmailAlertsFields } = useAlertsComputed()

  const { roles, user, userEmailAlerts } = useGetters<UsersModule['getters']>('usersModule')

// TODO: add type definition to authModule
const { appBaseConfig } = useGetters('authModule')


const rolesOptions = computed(() => {
      return roles.value.map(({ id, name }) => ({ value: id, label: name }))
    })
const pageTitle = computed(() => {
  return `You're viewing ${user.value.firstName} ${user.value.lastName}`
})
const isUserLocked = computed(() => {
  return user.value.activityStatus !== 'Active'
})
const formErrors = computed(() => {
      const requiredFields = [
        'loginName',
        'firstName',
        'lastName',
        'emailAddress',
        'userRoleId',
        'clinicName',
        'primarySite'
      ]
      const { form } = v$.value
      return validateFields(form, requiredFields, formErrorMsg.value)
    })

const isUpdateButtonDisabled = computed(() => {
  return (
    loadingForm.value
    || v$.value.$invalid
    || isEqual(state.form, initialDataForm.value)
  )
})


const { editUser,
      fetchUser,
      fetchUserRoles,
      editUserEmailAlerts,
      fetchUserEmailAlerts } = useActions<UsersModule['actions']>('usersModule')


const closeUser = () => {
  router.push('/users-list')
}


const updateUser = async () => {
      loginNameError.value = ''
      emailAddressError.value = ''
      const { userRoleId, ...userForm } = state.form
      const parsedUserRoleId = Number(userRoleId)
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }

      let data: UpdateUserBody = {
        ...userForm,
        activityStatus: userForm.activityStatus.toUpperCase(),
        userRoles: [{ id: parsedUserRoleId }],
        type: USER_TYPES[parsedUserRoleId]
      }

      loadingForm.value = true
      data = { ...data, id: state.form.id }

      try {
        await editUser(data)
        await fetchUser(Number(route.params.id))
        const [id] = user.value.userRoles.map((role) => role.id as number)
        state.form = { ...user.value, userRoleId: id }
        loadingForm.value = false
        initialDataForm.value = cloneDeep(state.form)
      } catch (error) {
        loadingForm.value = false
        toast.error({
          message: 'Error updating user. Please verify the changed data is correct and try again.'
        })
      }
    }
    const loadData = async () => {
      try {
        await fetchUserRoles()
        await fetchUser(Number(route.params.id))
        const userAutomatedAlerts = await fetchUserEmailAlerts(Number(route.params.id))
        setEmailAlertsValues(userAutomatedAlerts)
        formatEmailAlertFields()
        const [id] = user.value.userRoles.map((role) => role.id as number)
        state.form = { ...user.value, userRoleId: id }
        initialDataForm.value = cloneDeep(state.form)
      } catch (err: any) {
        toast.error({ title: `User edit Error: ${err.message}.` })
      } finally {
        loadingData.value = false
      }
    }
    const formatEmailAlertFields = () => {
      userEmailAlertsFields.value = userEmailAlerts.value.map((el): EmailAlertLabels => ({
        ...el,
        value: el.id,
        label: EMAIL_ALERT_LABELS.filter((label) => el.code === label.code)[0].label,
        type: EMAIL_ALERT_LABELS.filter((label) => el.code === label.code)[0].type,
        isActive: EMAIL_ALERT_LABELS.filter((label) => el.code === label.code)[0].isActive
      }))
    }
    const updateEmailAlertValue = (value) => {
      userEmailAlertsFields.value = userEmailAlertsFields.value.map((el) => ({
        ...el,
        enabled: el.id === value ? !el.enabled : el.enabled
      }))
      const filteredActiveEmailAlerts = userEmailAlertsFields.value
      editUserEmailAlerts([Number(route.params.id), filteredActiveEmailAlerts])
    }
    const setEmailAlertsValues = (alerts: UserAlert[]) => {
      userEmailAlertsFields.value = userEmailAlertsFields.value.map((alert) => ({
        ...alert,
        enabled: alerts
          .filter((currentAlert) => currentAlert.id === alert.id)
          .map((value) => value.enabled)[0]
      }))
    }

  onMounted(() => {
    state.form = {
      ...state.form,
      clinicName: appBaseConfig.value.clinicName,
      primarySite: appBaseConfig.value.siteName
    }
    loadData()
  })
</script>
