<template>
  <MainContainer gridSlots="3" class="calendar-board">
    <top-header> Date and Time </top-header>
    <div class="my-5 flex">
      <div class="w-1/2">
        <message-card color="#66A7E5">{{ headerMessage }}</message-card>
      </div>
      <div class="w-1/2">
        <button-dynamic
          btn-data-test="calendar-done-btn"
          class="calendar-done-btn"
          btn-type="button"
          btn-text="Next"
          btn-style="primary"
          show-icon
          font-awesome-icon-class="arrow-circle-right"
          :is-disabled="!isDateValid"
          :is-loading="isProcessing"
          @click="updateTicket"
        />
        <button-dynamic
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          @click="onClickBack"
          addMarginRight
        />
      </div>
    </div>
    <div class="main-container main-container--actions">
      <div class="main-container__body">
        <calendar-card
          class="calendar-component"
          ref="calendar"
          v-model="procedureDate"
          :calendar-modes="calendarModes"
          is-time-picker-visible
          :min-date="calendarMinAllowedDate"
          :max-date="calendarMaxAllowedDate"
        />
        <div class="date-time-validation-error text-white bg-tmrw-warning" v-if="!isDateValid">
          Selection invalid!
          <br />{{ invalidDateMessage }}
        </div>
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import toast from '@/config/toast'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import CalendarCard from '@/components/CalendarCard/CalendarCard.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import {
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE,
  WHO_FREEZE_DATE_CANNOT_BE_IN_FUTURE,
  WHO_FREEZE_DATE_CANNOT_BE_BEFORE_TICKET_CREATION
} from '@/constants'
import { SELECT_EMRBYOLOGIST_ROUTE } from '@/constants/routes'
import { canUpdateInventory } from '@/helpers/ticketState'

dayjs.extend(utc)
dayjs.extend(isBetween)

export default {
  name: 'calendar-view',
  data() {
    return {
      procedureDate: {},
      formattedSelectedDate: {},
      calendarMaxAllowedDate: dayjs().valueOf(),
      isProcessing: false
    }
  },
  created() {
    this.procedureDate = {
      mode: 'single',
      date: {
        start: dayjs()
      },
      time: dayjs().format('hh:mm A')
    }
  },
  async mounted() {
    await this.$nextTick()
    const minDate = dayjs(this.performedOnCalendarDate).startOf('day').valueOf()
    const currentDate = dayjs().startOf('day').valueOf()
    const todayDate = document.querySelector('.today')
    if (todayDate && minDate !== currentDate) todayDate.classList.remove('selected')
  },
  watch: {
    procedureDate(newDate) {
      const selectedDate = dayjs(newDate.date.start).startOf('day').valueOf()
      const currentDate = dayjs().startOf('day').valueOf()
      const todayDate = document.querySelector('.today')
      if (todayDate && selectedDate === currentDate) todayDate.classList.add('selected')
    }
  },
  computed: {
    ...mapGetters('calendarModule', [
      'calendarMaxDate',
      'performedOnCalendarDate',
      'ticketToUpdate'
    ]),
    ...mapGetters('selectedTicketsModule', ['selectedTickets', 'viewTicket']),
    headerMessage() {
      const ticket = this.getCurrentlyVisibleTicket(this.ticketToUpdate.ticketId)
      return `What was the date and time of the ${ticket?.procedureType.toLowerCase()}?`
    },
    calendarModes() {
      return {
        now: false,
        single: true,
        range: false
      }
    },
    selectedProcedureDateTime() {
      return this.formatAppointmentDate(this.procedureDate)
    },
    currentTicketCreatedDate() {
      const currentTicket = this.getCurrentlyVisibleTicket(this.ticketToUpdate.ticketId)
      const minDate = currentTicket?.createdAt || this.performedOnCalendarDate
      return minDate ? dayjs(minDate).startOf('minute') : dayjs().startOf('minute')
    },
    isDateValid() {
      return (
        this.selectedProcedureDateTime.isValid() &&
        this.selectedProcedureDateTime.isBetween(
          this.currentTicketCreatedDate,
          dayjs().startOf('minute'),
          null,
          '[]'
        )
      )
    },
    invalidDateMessage() {
      if (this.selectedProcedureDateTime.isAfter(dayjs())) {
        return WHO_FREEZE_DATE_CANNOT_BE_IN_FUTURE
      }

      if (this.selectedProcedureDateTime.isBefore(this.currentTicketCreatedDate)) {
        return WHO_FREEZE_DATE_CANNOT_BE_BEFORE_TICKET_CREATION
      }
      return ''
    },
    calendarMinAllowedDate() {
      return this.currentTicketCreatedDate.valueOf()
    }
  },
  methods: {
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    ...mapActions('calendarModule', [
      'setCalendarMinDate',
      'setCalendarMaxDate',
      'setPerformedOnCalendarDate',
      'setTicketToUpdate'
    ]),
    async updateTicket() {
      this.isProcessing = true
      const {
        date: { start },
        time
      } = this.procedureDate
      const [hour, meridian] = time.split(' ')
      const formatedTime = `${hour}:00 ${meridian}`
      const formatedDate = dayjs(start).format('MMM D, YYYY')
      const performedOn = dayjs(`${formatedDate} ${formatedTime}`).utc().valueOf()
      const newTicket = {
        ...this.ticketToUpdate,
        performedOn
      }

      this.setTicketToUpdate(newTicket)

      try {
        await this.updateTickets({
          tickets: [newTicket],
          ticketId: newTicket.ticketId
        })
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
        this.setCalendarMinDate(null)
        this.setCalendarMaxDate(null)
        this.setPerformedOnCalendarDate(null)

        const currentSelectedTicket = this.getCurrentlyVisibleTicket(newTicket.ticketId)
        const updateSelectedTicket = {
          ...currentSelectedTicket,
          ...newTicket
        }
        if (canUpdateInventory(updateSelectedTicket, this.viewTicket)) {
          this.$router.push(
            `/inventory-update/${updateSelectedTicket.globalPatientNumber}/${updateSelectedTicket.ticketId}`
          )
          return
        }

        this.$router.push('/selected-tickets')
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.isProcessing = false
      }
    },
    getCurrentlyVisibleTicket(ticketId) {
      return this.selectedTickets.find((ticket) => ticket.ticketId === Number(ticketId))
    },
    onClickBack() {
      this.$router.push(SELECT_EMRBYOLOGIST_ROUTE(this.ticketToUpdate.ticketId))
    },
    formatAppointmentDate(appointmentDate) {
      const {
        date: { start },
        time
      } = appointmentDate

      const [hour, meridian] = time.split(' ')
      const formatedTime = `${hour}:00 ${meridian}`
      const formatedDate = dayjs(start).format('MMM D, YYYY')
      return dayjs(`${formatedDate} ${formatedTime}`)
    }
  },
  components: {
    TopHeader,
    MessageCard,
    CalendarCard,
    ButtonDynamic,
    MainContainer
  }
}
</script>

<style scoped lang="scss">
/*
  * This css is needed because the calendar we use does not have native support for changing
  * or removing the style of the "today" selector.
  */
.calendar-component::v-deep > .calendar-date-picker > .date-picker > .calendar > .today::before,
.calendar-component::v-deep > .calendar-date-picker > .date-picker > .calendar > .today {
  @apply bg-tmrw-green;
  @apply text-black;
}

.calendar-component::v-deep
  > .calendar-date-picker
  > .date-picker
  > .calendar
  > .selected.today::before,
.calendar-component::v-deep > .calendar-date-picker > .date-picker > .calendar > .selected.today {
  @apply bg-tmrw-blue;
  @apply text-white;
}
</style>
