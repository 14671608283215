export default {
  displayModal({ commit }, params) {
    commit('modalTitle', params[0])
    commit('modalMessage', params[1])
    commit('displayModal', true)
  },
  hideModal({ commit }) {
    commit('displayModal', false)
    commit('modalTitle', '')
    commit('modalMessage', '')
  }
}
