class FeatureFlag {
  private flag: boolean | string | number

  constructor(flag: boolean | string | number) {
    this.flag = flag
  }

  public getValue(): boolean {
    switch (this.flag) {
      case true:
      case 'true':
      case 'TRUE':
      case 'True':
      case 't':
      case 'T':
      case 'yes':
      case 'YES':
      case 'Yes':
      case 'y':
      case 'Y':
      case 'on':
      case 'ON':
      case 'On':
      case '1':
      case 1:
        return true
      default:
        return false
    }
  }
}

export {
  FeatureFlag
}
