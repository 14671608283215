import dateAndTime from '@/helpers/dateAndTime'
import {
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE
} from '@/constants'

export const oocyteHeaders = [
  {
    name: 'Cryodevice ID',
    sort: false,
    key: 'cryodeviceBarcode'
  },
  {
    name: 'Count x Type',
    alignment: 'center',
    key: 'specimenCount',
    cssClasses: ['w-28'],
    sort: true,
    filters: [
      (value, item) => `${value} x ${item.oocyte.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE}`
    ]
  },
  {
    name: 'Cryodevice Type',
    sort: false,
    key: 'cryodeviceType'
  },
  {
    name: 'Maturity Level',
    sort: false,
    key: 'maturityLevel'
  },
  {
    name: 'CryoDate',
    sort: false,
    key: 'cryoDate'
  },
  {
    name: 'Donor ID',
    sort: false,
    key: 'sources[0].donorId'
  },
  {
    name: 'Shipping Source',
    sort: false,
    key: 'sources[0].shippingSourceId',
    component: 'shippingSource'
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    sort: true,
    cssClasses: ['w-32'],
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    alignment: 'center',
    sort: true,
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: '',
    key: 'sourcesValue',
    component: 'customTooltip',
    alignment: 'center',
    componentOptions: {
      alignment: 'left',
      displayDotsIcon: false,
      tooltipTableHeaders: [
        {
          title: 'Imported',
          key: 'imported'
        },
        {
          title: 'Current label ID',
          key: 'currentLabelId'
        }
      ]
    }
  }
]

export const embryoHeaders = [
  {
    name: 'Cryodevice ID',
    sort: false,
    key: 'cryodeviceBarcode'
  },
  {
    name: 'Count x Type',
    alignment: 'center',
    key: 'specimenCount',
    cssClasses: ['w-28'],
    sort: true,
    filters: [
      (value, item) => `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ]
  },
  {
    name: 'Embryo #',
    sort: false,
    key: 'embryo.embryoNumber'
  },
  {
    name: 'Grade',
    sort: false,
    key: 'embryo.grade'
  },
  {
    name: 'CryoDate',
    sort: false,
    key: 'cryoDate'
  },
  {
    name: 'Cryodevice Type',
    sort: false,
    key: 'cryodeviceType'
  },
  {
    name: 'Donor ID',
    sort: false,
    key: 'sources[0].donorId'
  },
  {
    name: 'Shipping Source',
    sort: false,
    key: 'sources[0].shippingSourceId',
    component: 'shippingSource'
  },
  {
    name: 'Biopsy',
    sort: false,
    key: 'biopsy'
  },
  {
    name: 'Result',
    sort: false,
    key: 'embryo.biopsyResult',
    cssClasses: ['truncate', 'w-32'],
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    alignment: 'center',
    sort: true,
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    sort: true,
    cssClasses: ['w-32'],
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: '',
    key: 'sourcesValue',
    component: 'customTooltip',
    alignment: 'center',
    componentOptions: {
      alignment: 'left',
      displayDotsIcon: false,
      tooltipTableHeaders: [
        {
          title: 'Imported',
          key: 'imported'
        },
        {
          title: 'Current label ID',
          key: 'currentLabelId'
        }
      ]
    }
  }
]

export const auditHeaders = [
  {
    name: 'Date & Time',
    sort: true,
    key: 'dateTime',
    filters: [
      (value) => dateAndTime.toLocalTimeZone({ procedureTime: value, ff: true })
    ]
  },
  {
    name: 'User',
    sort: true,
    key: 'fullName'
  },
  {
    name: 'Action',
    key: 'action',
    sort: true
  }
]
