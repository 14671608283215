<template>
  <section data-test="login-form" class="login h-screen w-screen flex items-center justify-center">
    <div class="flex flex-row w-5/12">
      <div class="w-1/2 flex justify-center items-center">
        <image-element
          imageSrc="cryotransporter-banner-2.png"
          altText="CryoTransporter Image"
          width="80%"
        />
      </div>
      <div class="w-1/2 flex flex-col justify-center items-center">
        <tmrw-logo @onClickAppLogo="toggleLogo" class="cursor-pointer" />
        <p data-test="login-sub-title" class="text-tmrw-green-light text-center py-4">
          {{ $t('login.specimen_management_system') }}
        </p>
        <form
          @submit.prevent="onLoginSubmit"
          method="post"
          class="flex w-full flex-col justify-center"
        >
          <input-field
            inputType="text"
            :inputLabel="username ? $t('login.username') : ''"
            inputName="username"
            v-model="username"
            :borderColor="borderColor"
            placeholderColor="tmrw-green-light"
            labelColor="tmrw-green-light"
            inputColor="text-white"
            inputPlaceholder="User Name"
            class="w-full text-lg"
            addPaddingBottom
            hasFontInconsolata
            addOpacity
            :light-background="false"
            colorErrorText="text-tmrw-green-light"
          />
          <input-field
            :inputType="inputType"
            :inputLabel="password ? $t('login.password') : ''"
            inputName="password"
            v-model="password"
            :borderColor="borderColor"
            placeholderColor="tmrw-green-light"
            labelColor="tmrw-green-light"
            inputColor="text-white"
            inputPlaceholder="Password"
            class="mt-6 w-full text-lg"
            :hasError="loginError"
            addPaddingBottom
            hasFontInconsolata
            addOpacity
            :light-background="false"
            colorErrorText="text-tmrw-green-light"
          />

          <div class="flex items-center mt-8">
            <input
              type="checkbox"
              id="showPassword"
              v-model="showPassword"
              :class="{
                'w-5 h-5 border-gray-400 rounded': true,
                'bg-transparent': !showPassword,
                'bg-white': showPassword
              }"
              class="transparent-checkbox"
            />
            <label for="showPassword" class="ml-3 text-white text-lg">Show Password?</label>
            <!-- Label styles -->
          </div>

          <button-dynamic
            :btnText="$t('login.sign_in')"
            btnType="submit"
            btnStyle="primary"
            :isDisabled="username === '' || password === ''"
            :addClasses="['my-12']"
            floatTo="none"
            :isLoading="processingLogin"
          />
        </form>
        <button-dynamic
          btnType="button"
          :btnText="$t('login.forgot_password')"
          btnStyle="transparent"
          isBgTransparent
          addMarginBottom
          isTextUnderlined
          :addClasses="['mt-12']"
          @click="onClickForgot"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import ImageElement from '@/components/ImageElement/ImageElement.vue'
import InputField from '@/components/InputField/InputField.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { RESETED_PASSWORD_SUCCESSFULLY, WELCOME_MSG } from '@/constants'
import toast from '@/config/toast'

export default {
  name: 'login',
  data() {
    return {
      colorMessage: '#66A7E5',
      username: '',
      loginError: '',
      password: '',
      showPassword: false,
      processingLogin: false,
      welcomeMessage: WELCOME_MSG
    }
  },
  created() {
    if (this.$route?.params?.resetedPassword) {
      toast.success(RESETED_PASSWORD_SUCCESSFULLY)
    }
  },
  methods: {
    ...mapActions('authModule', ['fetchLogin']),
    ...mapActions('spinnerModule', ['hideSpinner']),
    ...mapActions('appInfoModule', ['toggleAppInfoModal']),
    ...mapMutations('ticketModule', ['setUserId', 'setCollectionProtocolId']),
    ...mapActions('ticketsModule', ['resetStateFilterTabs']),
    onClickForgot() {
      this.$router.push('/forgot-password')
    },
    async onLoginSubmit() {
      try {
        this.processingLogin = true

        await this.fetchLogin({
          username: this.username,
          password: this.password
        })

        this.$mixpanel.identify(this.loggedUserInfo.userId.toString())
        this.$mixpanel.people.set({
          'first-name': this.loggedUserInfo.firstName,
          'last-name': this.loggedUserInfo.lastName,
          'clinic-name': this.appBaseConfig.clinicName,
          'site-name': this.appBaseConfig.siteName,
          'user-name': this.loggedUserInfo.loginName,
          'user-type': this.loggedUserInfo.userType,
          // eslint-disable-next-line quote-props
          $name: `${this.loggedUserInfo.firstName} ${this.loggedUserInfo.lastName}`
        })
        this.$mixpanel.people.increment('Total Logins')

        this.$router.push('/flight-board')
      } catch (error) {
        this.loginError = error.response?.data[0]?.message || error.message
      } finally {
        this.processingLogin = false
      }
    },
    toggleLogo() {
      this.toggleAppInfoModal()
    }
  },
  mounted() {
    this.resetStateFilterTabs()
    this.hideSpinner()
    if (this.isLoggedIn) {
      this.$router.push('/flight-board')
    }
  },
  computed: {
    ...mapGetters('authModule', ['isLoggedIn', 'appBaseConfig', 'loggedUserInfo']),
    borderColor() {
      return this.loginError ? 'tmrw-danger' : 'white'
    },
    inputType() {
      return this.showPassword ? 'text' : 'password'
    }
  },
  watch: {
    username() {
      this.loginError = false
    },
    password() {
      this.loginError = false
    }
  },
  components: {
    InputField,
    ButtonDynamic,
    TmrwLogo,
    ImageElement
  }
}
</script>

<style lang="scss">
.login {
  background: url('../../assets/circles-background.svg') center center repeat-x;
  background-size: 50% auto;
}
.transparent-checkbox {
  @apply border border-white outline-none cursor-pointer bg-transparent;
  appearance: none;
  -webkit-appearance: none;
}

.transparent-checkbox:checked {
  @apply bg-white relative;
}

.transparent-checkbox:checked::before {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-black;
  content: '\2714';
}
</style>
