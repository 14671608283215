

export default {
  specimens: (state) => state.specimens,
  specimenEvents: (state) => state.specimenEvents,
  specimenDetails: (state) => state.specimenDetails,
  embryoTypes: (state) => state.embryoTypes,
  cryoDevicesTypes: (state) => state.cryoDevicesTypes,
  fileUrl: (state) => state.fileUrl,
  statusSpecimens: (state) => state.status,
  summaries: (state) => state.summaries,
  error: (state) => state.error,
  errorMessage: (state) => state.errorMessage,
  sharedPatientAdded: (state) => state.sharedPatientAdded
}
