import {
  DESTINATION,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_BEACON_MOVE,
  PROCEDURE_TYPE_CRYOSHIPPER,
  ROBOT_TRANSFER_DONE,
  ROBOT_TRANSFER_IN,
  ROBOT_TRANSFER_NOT_READY,
  ROBOT_TRANSFER_OUT,
  ROBOT_TRANSFER_READY,
  SOURCE,
  TICKET_COMPLETE,
  TICKET_CRYOSHIPPER,
  TICKET_IN_PROGRESS,
  TICKET_RELOCATION,
  TICKET_SCHEDULED
} from '@/constants'
import {
  FormattedCryoShipperGrid,
  FormattedCryoShipperRobotLocation,
  FormattedCryoShipperGridTicket,
  FormattedCryoShipperTicket,
  ShipmentResponse
  // Shipment
} from '@/services/shipments/types'
import store from '@/store'
import { TicketMode } from '@/store/modules/biorepositoryTicket/types'
import { capitalizeLower } from '../stringHelper'
import { RobotLocation, Ticket } from '@/services/tickets/types'
// import tickets from '@/store/modules/tickets'

export function shouldHaveMultipleBeacons(cryobeaconsAmount: number): boolean {
  if (!cryobeaconsAmount) return false
  const SINGLE_UNITY = 1
  return cryobeaconsAmount > SINGLE_UNITY
}

export const getShipmentDetails = ({
  ticket,
  shipments
}: {
  ticket: Ticket
  shipments: ShipmentResponse[]
}): FormattedCryoShipperTicket => {
  if (ticket.procedure !== PROCEDURE_TYPE_CRYOSHIPPER) {
    return {}
  }

  const containers = store.getters['siteModule/allContainers']
  const sites = store.getters['siteModule/allSites']
  const shipmentDetails = shipments.find(
    ({ data: { shipmentId } }) => ticket.metadata.shipmentId === shipmentId
  )?.data
  if (shipmentDetails) {
    const sourceSite = sites.find((site) => site.value === Number(shipmentDetails.sourceSiteId))
    const destinationSite = sites.find(
      (container) => container.value === Number(shipmentDetails.destinationSiteId)
    )
    let robotLocations: RobotLocation[] = ticket.robotLocations || []
    if (!robotLocations.length) {
      robotLocations = buildCryoShipperRobotLocations(ticket)
    }
    const procedureTypes = {
      Batch: PROCEDURE_TYPE_BATCH_UPPERCASE,
      Relocation: PROCEDURE_TYPE_BEACON_MOVE
    }
    const formattedTransferStatus = {
      READY: ROBOT_TRANSFER_READY,
      NOT_READY: ROBOT_TRANSFER_NOT_READY,
      DONE: ROBOT_TRANSFER_DONE
    }
    return {
      robotLocations,
      shipmentDetails: {
        ...shipmentDetails,
        cryoshipperGrids: shipmentDetails.cryoshipperGrids?.map(
          (grid): FormattedCryoShipperGrid => ({
            ...grid,
            tickets: grid.tickets?.map((gridTicket): FormattedCryoShipperGridTicket => {
              return {
                ...gridTicket,
                procedure: procedureTypes[gridTicket.procedure!],
                procedureName: gridTicket.procedure,
                procedureType: procedureTypes[gridTicket.procedure!],
                screeningStatus: grid.screeningStatus,
                robotLocations: gridTicket.robotLocations?.map(
                  (location): FormattedCryoShipperRobotLocation => {
                    const container = containers.find(
                      (container) => container.containerId === Number(location.robotId)
                    )
                    const formattedType = capitalizeLower(location.type || '')
                    return {
                      ...location,
                      type: formattedType,
                      robotName: container?.robotName,
                      robotLayoutTypeId: container?.robotLayoutTypeId,
                      siteName: formattedType === SOURCE ? sourceSite.label : destinationSite.label,
                      transferStatus: formattedTransferStatus[location.transferStatus!],
                      transferType:
                        formattedType === SOURCE ? ROBOT_TRANSFER_OUT : ROBOT_TRANSFER_IN
                    }
                  }
                )
              }
            })
          })
        )
      }
    }
  }
  return {}
}

type BreadcrumbParams = {
  ticketMode?: TicketMode | null
  active?: 'location' | 'details' | 'confirm' | 'created' | 'cryobeacons' | null
}

type BreadCrumb = {
  active: boolean
  label: string
}

export const getBiorepoBreadcrumbs = ({
  active,
  ticketMode
}: BreadcrumbParams): BreadCrumb[] | null => {
  if (ticketMode === TICKET_CRYOSHIPPER) {
    return [
      { active: active === 'location', label: 'Set Locations' },
      { active: active === 'details', label: 'Edit Details' },
      { active: active === 'confirm', label: 'Confirm' },
      { active: active === 'created', label: 'CryoShipper Ticket Created' }
    ]
  } else if (ticketMode === TICKET_RELOCATION) {
    return [
      { active: active === 'location', label: 'Location' },
      { active: active === 'cryobeacons', label: 'Add CryoBeacons' },
      { active: active === 'created', label: 'Tickets Created' }
    ]
  }
  return null
}

export const getCryoShipperTransferStatus = (item, locationType) => {
  const isSameSite = item.siteId === item.metadata.sourceSiteId
  const inProgressStatus = isSameSite
    ? { origin: ROBOT_TRANSFER_READY, destination: ROBOT_TRANSFER_NOT_READY }
    : { origin: ROBOT_TRANSFER_DONE, destination: ROBOT_TRANSFER_READY }

  const stateToTransferStatus = {
    [TICKET_SCHEDULED]: {
      origin: ROBOT_TRANSFER_READY,
      destination: ROBOT_TRANSFER_NOT_READY
    },
    [TICKET_COMPLETE]: {
      origin: ROBOT_TRANSFER_DONE,
      destination: ROBOT_TRANSFER_DONE
    },
    [TICKET_IN_PROGRESS]: inProgressStatus
  }
  return stateToTransferStatus[item.state][locationType]
}

export const buildCryoShipperRobotLocations = (item) => {
  try {
    const sites = store.getters['siteModule/allSites']
    const sourceSiteName = sites.find(
      ({ value }) => value === Number(item.metadata.sourceSiteId)
    ).label
    const destinationSiteName = sites.find(
      ({ value }) => value === Number(item.metadata.destinationSiteId)
    ).label

    const { validatedAtSourceTimestamp, validatedAtDestinationTimestamp } = item.metadata
    return [
      {
        siteName: sourceSiteName,
        transferStatus: getCryoShipperTransferStatus(item, 'origin'),
        type: SOURCE,
        transferType: ROBOT_TRANSFER_OUT,
        completedAt: validatedAtSourceTimestamp
      },
      {
        siteName: destinationSiteName,
        transferStatus: getCryoShipperTransferStatus(item, 'destination'),
        type: DESTINATION,
        transferType: ROBOT_TRANSFER_IN,
        completedAt: validatedAtDestinationTimestamp
      }
    ]
  } catch (e) {
    return []
  }
}
