import {
  METHOD_NOT_SELECTED,
  METHOD_FREEZE,
  METHOD_THAW,
  METHOD_IMPORT,
  METHOD_EXPORT,
  METHOD_DONATE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  SPECIMEN_TYPE_EMBRYO
} from '@/constants'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import {
  STEP_INITIAL,
  STEP_SELECT_PROCEDURE,
  STEP_SELECT_SPECIMEN_TYPE,
  STEP_SELECT_PATIENT,
  STEP_CALENDAR,
  STEP_HOW_MANY,
  STEP_DETAILS,
  STEP_LABEL_SCAN,
  STEP_SCAN_BEACON,
  STEP_ADD_EXISTING_BEACON,
  STEP_SELECT_THAW_PROCEDURE,
  STEP_SELECT_SPECIMENS_NEW_TICKET,
  STEP_SELECT_REASON_TO_EXPORT,
  STEP_FINAL,
  STEP_BATCH_TICKET_TYPE,
  STEP_NEW_BATCH_TICKET
} from '@/constants/ticketSteps'

import { StepType, MethodType } from './types'
import {
  SpecimenType
} from '@/types'

import {
  resetTicketProcess,
  setCurrentTicketStep,
  setSelectedMethod
} from './utils'

interface ExecuteStep {
  currentTicketStep: StepType
  fromRouteName?: StepType,
  isExistingBeacon?: boolean,
  specimenType?: SpecimenType
}

function executeInitialStep({ currentTicketStep }: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_PROCEDURE:
      return STEP_INITIAL
    default:
      return STEP_INITIAL
  }
}

interface ExecuteGeneralBackStep {
  currentTicketStep: StepType
}

function executeGeneralBackStep({
  currentTicketStep
}: ExecuteGeneralBackStep): StepType {
  switch (currentTicketStep) {
    case STEP_INITIAL:
      return STEP_INITIAL
    case STEP_SELECT_PROCEDURE:
      return STEP_INITIAL
    case STEP_SELECT_SPECIMEN_TYPE:
      resetTicketProcess()
      setCurrentTicketStep(STEP_SELECT_PROCEDURE)
      setSelectedMethod(METHOD_NOT_SELECTED)
      return STEP_SELECT_PROCEDURE
    case STEP_SELECT_PATIENT:
      setCurrentTicketStep(STEP_SELECT_SPECIMEN_TYPE)
      return STEP_SELECT_SPECIMEN_TYPE
    case STEP_CALENDAR:
      setCurrentTicketStep(STEP_SELECT_PATIENT)
      return STEP_SELECT_PATIENT
    case STEP_HOW_MANY:
      setCurrentTicketStep(STEP_CALENDAR)
      return STEP_CALENDAR
    case STEP_DETAILS:
      setCurrentTicketStep(STEP_HOW_MANY)
      return STEP_HOW_MANY
    default:
      return STEP_INITIAL
  }
}

function executeFreezeStep({
  currentTicketStep,
  isExistingBeacon
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_LABEL_SCAN:
      setCurrentTicketStep(STEP_DETAILS)
      return STEP_DETAILS
    case STEP_SCAN_BEACON:
      if(isExistingBeacon) {
        setCurrentTicketStep(STEP_ADD_EXISTING_BEACON)
        return STEP_ADD_EXISTING_BEACON
      }
      setCurrentTicketStep(STEP_LABEL_SCAN)
      return STEP_LABEL_SCAN
    case STEP_ADD_EXISTING_BEACON:
      setCurrentTicketStep(STEP_LABEL_SCAN)
      return STEP_LABEL_SCAN
    default:
      return executeGeneralBackStep({
        currentTicketStep
      })
  }
}

function executeThawStep({
  currentTicketStep,
  specimenType
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_THAW_PROCEDURE:
      setCurrentTicketStep(STEP_SELECT_SPECIMEN_TYPE)
      return STEP_SELECT_SPECIMEN_TYPE
    case STEP_SELECT_SPECIMENS_NEW_TICKET:
      setCurrentTicketStep(STEP_CALENDAR)
      return STEP_CALENDAR
    case STEP_SELECT_PATIENT:
      if(specimenType === SPECIMEN_TYPE_EMBRYO) {
        setCurrentTicketStep(STEP_SELECT_THAW_PROCEDURE)
        return STEP_SELECT_THAW_PROCEDURE
      }
      setCurrentTicketStep(STEP_SELECT_SPECIMEN_TYPE)
      return STEP_SELECT_SPECIMEN_TYPE
    default:
      return executeGeneralBackStep({
        currentTicketStep
      })
  }
}

function executeImportStep({
  currentTicketStep,
  isExistingBeacon
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SCAN_BEACON:
      if(isExistingBeacon) {
        setCurrentTicketStep(STEP_ADD_EXISTING_BEACON)
        return STEP_ADD_EXISTING_BEACON
      }
      setCurrentTicketStep(STEP_DETAILS)
      return STEP_DETAILS
    case STEP_ADD_EXISTING_BEACON:
      setCurrentTicketStep(STEP_DETAILS)
      return STEP_DETAILS
    case STEP_HOW_MANY:
      setCurrentTicketStep(STEP_SELECT_PATIENT)
      return STEP_SELECT_PATIENT
    default:
      return executeGeneralBackStep({
        currentTicketStep
      })
  }
}

function executeExportStep({
  currentTicketStep
}: ExecuteStep): StepType {
  switch (currentTicketStep) {
    case STEP_SELECT_PATIENT:
      setCurrentTicketStep(STEP_SELECT_PROCEDURE)
      return STEP_SELECT_PROCEDURE
    case STEP_SELECT_SPECIMENS_NEW_TICKET:
      setCurrentTicketStep(STEP_CALENDAR)
      return STEP_CALENDAR
    case STEP_SELECT_REASON_TO_EXPORT:
      setCurrentTicketStep(STEP_SELECT_SPECIMENS_NEW_TICKET)
      return STEP_SELECT_SPECIMENS_NEW_TICKET
    default:
      return executeGeneralBackStep({
        currentTicketStep
      })
  }
}

function executeDonateStep(): StepType {
  // TODO: Check if it's correct to skip the scan beacon screen
  setSelectedMethod(METHOD_EXPORT)
  setCurrentTicketStep(STEP_SELECT_REASON_TO_EXPORT)
  return STEP_SELECT_REASON_TO_EXPORT
}

function executeBatchStep({
  currentTicketStep,
  fromRouteName
}: ExecuteStep): StepType {
  const initialStep = fromRouteName === STEP_BIOREPOSITORY_FLIGHTBOARD
    ? STEP_BIOREPOSITORY_FLIGHTBOARD
    : STEP_INITIAL

  switch (currentTicketStep) {
    case STEP_BATCH_TICKET_TYPE:
      setCurrentTicketStep(initialStep)
      return initialStep
    case STEP_NEW_BATCH_TICKET:
      setCurrentTicketStep(STEP_BATCH_TICKET_TYPE)
      return STEP_BATCH_TICKET_TYPE
    case STEP_FINAL:
      setCurrentTicketStep(STEP_NEW_BATCH_TICKET)
      return STEP_NEW_BATCH_TICKET
    default:
      return initialStep
  }
}

const TicketPreviousStepsFactory = {
  [METHOD_NOT_SELECTED]: executeInitialStep,
  [METHOD_FREEZE]: executeFreezeStep,
  [METHOD_THAW]: executeThawStep,
  [METHOD_IMPORT]: executeImportStep,
  [METHOD_EXPORT]: executeExportStep,
  [METHOD_DONATE]: executeDonateStep,
  [PROCEDURE_TYPE_BATCH_UPPERCASE]: executeBatchStep
}

interface ExecutePreviousTicketStepInput {
  selectedMethod: MethodType,
  currentTicketStep: StepType,
  fromRouteName?: StepType,
  isExistingBeacon?: boolean,
  specimenType?: SpecimenType
}

export function executePreviousTicketStep({
  selectedMethod,
  currentTicketStep,
  fromRouteName,
  isExistingBeacon,
  specimenType
}: ExecutePreviousTicketStepInput): StepType {
  // this condition takes care of cases like coming from STEP_SCAN_BEACON or
  // STEP_ADD_EXISTING_BEACON to STEP_FINAL, which makes clicking back for the
  // STEP_FINAL have multiple previous screens possibilities. By passing the
  // fromRouteName param, you can effectively overwrite the back logic
  // Ignore this for METHOD_DONATE, as we need to reset the selected method

  // TODO: Review this validation, caused issues on multiple back instances
  if (fromRouteName && selectedMethod !== METHOD_DONATE) {
    setCurrentTicketStep(fromRouteName)
    return fromRouteName
  }
  const executeTicketStep = TicketPreviousStepsFactory[selectedMethod]
  const previousStepRoutePath = executeTicketStep({
    currentTicketStep,
    fromRouteName,
    isExistingBeacon,
    specimenType
  })

  return previousStepRoutePath
}
