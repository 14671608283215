import toast from '@/config/toast'

import { addCryobeaconsTickets, searchCryobeacons } from '@/services/massCreate'
import dayjs from 'dayjs'

export const fetchSpecimens = async ({ commit }, payload) => {
  commit('statusMassCreate', 'loading')
  try {
    const response = await searchCryobeacons(payload)
    const { data } = response
    commit('cryobeaconsResult', data)
    commit('statusMassCreate', 'ok')
    return data
  } catch (error) {
    commit('statusMassCreate', 'error')
    toast.error({ title: error.message })
    throw new Error(error)
  }
}

export const addCryobeacons = async (
  { commit },
  { cryobeaconBarcodes, destinationUnitId, destinationSiteId, source, userId }
) => {
  commit('statusMassCreate', 'loading')
  try {
    const response = await addCryobeaconsTickets({
      cryobeaconBarcodes,
      destinationUnitId,
      destinationSiteId,
      procedureDateFrom: dayjs.utc().valueOf(),
      source,
      userId
    })
    const { data } = response
    commit('cryobeaconsCreated', data)
    commit('statusMassCreate', 'ok')
    return data
  } catch (error) {
    commit('statusMassCreate', 'error')
    toast.error({ title: error.message })
    throw Error(error)
  }
}

export const setCryobeaconSelected = ({ commit }, cryobeacon) => {
  commit('cryobeaconSelected', cryobeacon)
}

export const setSearchData = ({ commit }, search) => {
  commit('searchDataPersisted', search)
}

export default {
  fetchSpecimens,
  addCryobeacons,
  setCryobeaconSelected,
  setSearchData
}
