import * as ss from '@/config/session-storage-help'
import { errorsHandler } from '@/helpers/apiHelpers/errorHandlers'
import axios, { AxiosError, AxiosInstance, AxiosStatic } from 'axios'

export function axiosInterceptors(axiosInstance: AxiosStatic | AxiosInstance = axios) {
  axiosInstance.interceptors.request.use(
    (req:any) => {
      if (ss.getFieldSessionStorage('user', 'token')) {
        req.headers['x-access-token'] = ss.getFieldSessionStorage('user', 'token')
      }
      return req
    },
    (error) => Promise.reject(`No response received ${error}`)
  )

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
      const errorReason = errorsHandler(error)
      return Promise.reject(errorReason)
    }
  )

  return axiosInstance
}
