import { Beacon } from '@/types'

interface isNumCryoGreaterThanMaxCryoInput {
  beacons: Beacon[],
  maxCryodevicesPerBeacon: number
}
export const isNumCryoGreaterThanMaxCryo = ({
  beacons,
  maxCryodevicesPerBeacon
}: isNumCryoGreaterThanMaxCryoInput) => {
  const [firstBeacon] = beacons
  const numCryodevices = firstBeacon?.cryoDevice?.length || 0

  return numCryodevices > maxCryodevicesPerBeacon
}
