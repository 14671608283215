import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { timestampToUTCFilter } from '@/filters/index'
import {
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  MAX_LABEL_LENGTH
} from '@/constants'

dayjs.extend(utc)

const sourcesValueComponentOptions = {
  displayDotsIcon: false,
  tooltipTableHeaders: [
    {
      title: 'Imported',
      key: 'imported'
    },
    {
      title: 'Current label ID',
      key: 'currentLabelId'
    }
  ],
  alignment: 'left',
  imageWidth: 'w-6',
  imageHeight: 'h-6'
}

export const embryo = [
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-28', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    alignment: 'center',
    key: 'specimenCount',
    cssClasses: ['w-28'],
    sort: true,
    filters: [
      (value, item) =>
        `${value} x ${item.embryo.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO}`
    ]
  },
  {
    name: 'Embryo #',
    key: 'embryo.embryoNumber'
  },
  {
    name: 'Grade',
    key: 'embryo.grade',
    sort: true
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    sort: true
  },
  {
    name: 'CryoBeacon ID',
    cssClasses: ['w-48'],
    key: 'beaconBarcode',
    alignment: 'left',
    sort: true,
    unique: true
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    cssClasses: ['w-32'],
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: 'Biopsy',
    key: 'embryo.biopsy',
    sort: true
  },
  {
    name: 'Result',
    key: 'embryo.biopsyResult',
    sort: true,
    component: 'tooltip',
    componentOptions: {
      alignment: 'left',
      maxlength: 10
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    componentOptions: {
      maxlength: 10,
      alignment: 'left',
      displayDotsIcon: false
    }
  },
  {
    name: '',
    key: 'sourcesValue',
    component: 'customTooltip',
    alignment: 'left',
    cssClasses: ['w-12'],
    componentOptions: sourcesValueComponentOptions
  }
]

export const egg = [
  {
    name: 'Cryodevice ID',
    key: 'cryodeviceBarcode',
    cssClasses: ['w-32', 'whitespace-nowrap'],
    componentOptions: {
      alignment: 'right',
      maxLength: MAX_LABEL_LENGTH,
      ellipsis: 'start'
    },
    sort: true
  },
  {
    name: 'Count x Type',
    cssClasses: ['w-32'],
    alignment: 'center',
    key: 'specimenCount',
    filters: [
      (value, item) =>
        `${value} x ${
          item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    ],
    sort: true
  },
  {
    name: 'CryoDate',
    key: 'cryoDate',
    filters: timestampToUTCFilter,
    cssClasses: ['w-40'],
    sort: true
  },
  {
    name: 'CryoBeacon ID',
    cssClasses: ['w-48'],
    key: 'beaconBarcode',
    alignment: 'left',
    sort: true,
    unique: true
  },
  {
    name: INFECTIOUS_SCREENING_STATUS,
    key: 'screeningStatus',
    cssClasses: ['w-56'],
    component: 'screeningStatus',
    componentOptions: {
      icon: true,
      label: true,
      textColor: 'white'
    }
  },
  {
    name: 'Notes',
    key: 'specimenInfo',
    component: 'tooltip',
    cssClasses: ['w-38', 'max-w-40'],
    componentOptions: {
      alignment: 'left',
      maxlength: 10,
      displayDotsIcon: false
    }
  },
  {
    name: '',
    key: 'sourcesValue',
    component: 'customTooltip',
    alignment: 'left',
    cssClasses: ['w-12'],
    componentOptions: sourcesValueComponentOptions
  }
]
