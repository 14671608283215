<template>
  <ConfirmModal
    data-test="specimen-details__patients__remove-modal"
    :confirmLabel="REMOVE"
    :message="removePatientMessage"
    :title="REMOVE_PATIENT"
    :showModal="showRemoveModal"
    @handleConfirmModal="removePatient"
    @handleCloseModal="cancelRemove"
    :disableConfirm="reasonTooShort"
    hasChild
  >
    <InputField
      v-model.trim="reason"
      class="pt-4"
      inputPlaceholder="Reason*"
      placeholderColor="tmrw-blue"
      inputColor="text-tmrw-blue"
      borderColor="black"
      addPaddingBottom
    />
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal.vue'
import InputField from '@/components/InputField/InputField.vue'
import { REMOVE, REMOVE_PATIENT } from '@/constants'

export default {
  name: 'remove-modal',
  emits: ['cancelRemove', 'removePatient'],
  props: {
    showRemoveModal: {
      type: Boolean,
      required: true
    },
    patient: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      reason: '',
      REMOVE,
      REMOVE_PATIENT
    }
  },
  methods: {
    cancelRemove() {
      this.$emit('cancelRemove')
    },
    removePatient() {
      this.$emit('removePatient', this.reason)
    }
  },
  computed: {
    reasonTooShort() {
      return this.reason.length < 5
    },
    removePatientMessage() {
      const message = `
        Please confirm that you would like to remove the following patient:<br />
        <strong>${this.patient.fullName}.</strong><br/><br/>
        Add the reason to remove it:
      `
      return message
    }
  },
  components: {
    ConfirmModal,
    InputField
  }
}
</script>
