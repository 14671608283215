import {
  EXISTING_BEACON_SCREENING_STATUS_NEGATIVE,
  EXISTING_BEACON_SCREENING_STATUS_POSITIVE,
  EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
} from '@/constants'
import { Beacon } from '@/types'
import store from '../../store'

export const getScreeningStatus = (screeningStatus: number) => {
  switch (screeningStatus) {
    case 1:
      return EXISTING_BEACON_SCREENING_STATUS_POSITIVE
    case 2:
      return EXISTING_BEACON_SCREENING_STATUS_NEGATIVE
    case 3:
    default:
      return EXISTING_BEACON_SCREENING_STATUS_UNSCREENED
  }
}

interface getScreeningStatusBeaconsInput {
  beacons: Beacon[]
  patientId: number
}
export const getScreeningStatusBeacons = async ({
  beacons,
  patientId
}: getScreeningStatusBeaconsInput) => {
  const numCryoBeacons = beacons.length
  const [firstBeacon] = beacons
  const numCryodevices = firstBeacon.cryoDevice.length
  const [cryoDevice] = firstBeacon.cryoDevice
  const { screeningStatus } = cryoDevice

  if (screeningStatus) {
    try {
      const screeningStatusLabel = getScreeningStatus(screeningStatus)

      const params = {
        patientId,
        screeningStatus: screeningStatusLabel,
        numCryodevices,
        numCryoBeacons
      }

      const responseBeacons = await store.dispatch('beaconsModule/getBeacons', params)
      return responseBeacons
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  return false
}
