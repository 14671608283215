<template>
  <div data-test="select-export-reason" class="select-reason">
    <Reasons @dataSync="handleReasonData" />
    <div v-if="isTransferOut">
      <TransferOut @dataSync="handleReasonData" />
    </div>
    <div v-if="isShipping">
      <Shipping @dataSync="handleReasonData" />
    </div>
    <div v-if="isDonation">
      <Donation @dataSync="handleReasonData" />
    </div>
    <div v-if="isOther">
      <Other @dataSync="handleReasonData" />
    </div>
  </div>
</template>

<script setup>
import Reasons from '@/components/SelectReason/Reasons/Reasons.vue'
import TransferOut from '@/components/SelectReason/transferOut/transferOut.vue'
import Shipping from '@/components/SelectReason/Shipping/Shipping.vue'
import Donation from '@/components/SelectReason/Donation/Donation.vue'
import Other from '@/components/SelectReason/Other/Other.vue'
import {
  TRANSFER_OUT_VALUE,
  DONATION_UPPERCASE,
  OTHER_VALUE,
  SHIPPING_VALUE
} from '@/constants'

import { computed } from 'vue'

const props = defineProps({
  handleReasonData: {
    type: Function
  },
  exportType: {
    type: String
  },
  exportSubType: {
    type: String
  }
})
const isTransferOut = computed(() =>{
  return props.exportType === TRANSFER_OUT_VALUE
})
const isShipping = computed(() => {
  return props.exportType === TRANSFER_OUT_VALUE && props.exportSubType === SHIPPING_VALUE
})
const isDonation = computed(() =>{
  return props.exportType === DONATION_UPPERCASE
})
const isOther = computed(() => {
  return props.exportType === OTHER_VALUE
})

</script>
