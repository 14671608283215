<template>
  <div class="scroll-table-radio-button-field justify-center flex w-52">
    <ScreeningStatusSelect :modelValue="value" @update:modelValue="input" :allowEmpty="false" :CSSclasses="'w-52'" />
  </div>
</template>

<script>
import ScreeningStatusSelect from '@/components/ScreeningStatusSelect/ScreeningStatusSelect.vue'

export default {
  name: 'scroll-table-radio-button-field',
  emits: ['valueFromCustomFieldChanged'],
  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: ''
    },
    options: {
      type: [Object, Array],
      required: false,
      default: () => {}
    },
    columnOptions: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    input(value) {
      this.$emit('valueFromCustomFieldChanged', value, this.columnOptions, this.options)
    }
  },
  components: {
    ScreeningStatusSelect
  }
}
</script>
