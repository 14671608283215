<template>
  <div class="">
    <top-header :back-btn="false">Log of Errors captured on ivfOS</top-header>
    <section class="p-4">
      <div class="flex mt-4" v-for="(error, index) in errorLog" :key="index">
        <div class="w-6/12 bg-red-500 p-2">
          {{error.error}}
        </div>
        <div class="w-2/12 bg-red-400 p-2">
          {{error.currentPath}}
        </div>
        <div class="w-2/12 bg-red-500 p-2 text-center">
          {{error.errorTime}}
        </div>
        <div class="w-2/12 bg-red-400 p-2 text-center">
          {{error.errorTimestamp}}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import * as ss from '@/config/session-storage-help'

export default {
  name: 'printers-status',
  data() {
    return {
      errorLog: []
    }
  },
  async mounted() {
    const errorLogData = ss.getFieldSessionStorage('errorLog')
    errorLogData.reverse()
    errorLogData.forEach((error) => {
      if (typeof error.error === 'object') {
        // eslint-disable-next-line no-param-reassign
        delete (error.error.config)
      }
      this.errorLog.push(error)
    })
  },
  components: {
    TopHeader
  }
}
</script>
