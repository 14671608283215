import { createStore } from 'vuex'

import syncStateStorage from 'vuex-state-storage-sync'
import appInfoModule from '@/store/modules/appInfo'
import authModule from '@/store/modules/auth'
import beaconsModule from '@/store/modules/beacons'
import biorepoTicketModule from '@/store/modules/biorepositoryTicket'
import createPatientModule from '@/store/modules/createPatient'
import newTicketModule from '@/store/modules/newTicket'
import screeningStatusModule from '@/store/modules/screeningStatus'
import selectedTicketsModule from '@/store/modules/selectedTickets'
import specimensModule from '@/store/modules/specimens'
import ticketsModule from '@/store/modules/tickets'
import documentsModule from './modules/documents'
import patientsModule from './modules/patients'
import patientGroupModule from './modules/patientGroup'
import usersModule from './modules/users'
import spinnerModule from './modules/spinner'
import calendarModule from './modules/calendar'
import modalModule from './modules/modal'
import siteModule from './modules/site'
import dataModule from './modules/data'
import ticketModule from './modules/ticket'
import massCreateModule from './modules/massCreate'

import { RootState } from '@/store/store-types'

const modules = {
  appInfoModule,
  authModule,
  beaconsModule,
  biorepoTicketModule,
  calendarModule,
  documentsModule,
  patientGroupModule,
  createPatientModule,
  modalModule,
  newTicketModule,
  patientsModule,
  screeningStatusModule,
  selectedTicketsModule,
  specimensModule,
  spinnerModule,
  ticketsModule,
  siteModule,
  usersModule,
  dataModule,
  ticketModule,
  massCreateModule
}

export type StoreModules = typeof modules

export type StoreModuleNames = keyof StoreModules

const store = createStore<RootState>({
  modules,
  plugins: [
    syncStateStorage({
      storage: window.sessionStorage,
      key: 'app-state'
    })
  ]
})
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.vuexStore = store
}

export default store
