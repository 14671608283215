import CHECK_CIRCLE_ICON_FILE from '@/assets/check-circle-icon.svg'
import EDIT_ICON_FILE_CIRCLE from '@/assets/edit-icon-circle.svg'
import EDIT_ICON_FILE from '@/assets/edit-icon.svg'

import EDIT_ICON_SUCCESS_FILE from '@/assets/edit-icon-success.svg'

export const TIMES_CIRCLE = 'times-circle'
export const CHECK_CIRCLE_ICON = CHECK_CIRCLE_ICON_FILE
export const EDIT_ICON_CIRCLE = EDIT_ICON_FILE_CIRCLE
export const EDIT_ICON = EDIT_ICON_FILE
export const EDIT_ICON_SUCCESS = EDIT_ICON_SUCCESS_FILE
