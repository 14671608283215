import dayjs from 'dayjs'
import { printSingleLabel } from '@/modules/printer'
import { PROCEDURE_TYPE_BATCH_UPPERCASE } from '@/constants/index'
import { STEP_FINAL } from '@/constants/ticketSteps'
import { printJob, reprintConfigType } from './reprintType'
import replaceEggToOocyte from '@/helpers/replaceEggToOocyte'
import * as ss from '@/config/session-storage-help'
import { labelToPdf } from '../printPDF/labelToPdf'
import store from '@/store'

const reprintLabelConfig = ({
  selectedTickets,
  activeSlide,
  beacons,
  selectedMethod,
  selectedPatient,
  currentTicketStep
}: reprintConfigType) => {
  try {
    let activeBeacon = {}

    if (selectedTickets.length > 0) {
      // used on selected ticket view
      const {
        specimens,
        beaconBarcode,
        procedureTime,
        identificationNumber,
        patientName,
        patient
      } = selectedTickets[activeSlide - 1]
      const specimensInSelectedTicket = specimens.filter(({ inSelectedTicket }) => inSelectedTicket)

      const procedureDateFrom = dayjs(procedureTime).format('DDMMMYYYY').toUpperCase()

      // NOTE: This is the place where spliting causes actual problem, as reprint may reverse first and last name
      // NOTE: For now left a failsafe to split the name, but should never be the case that patient comes empty
      // patient property exists when creating new ticket, and now added to fetchTicketInfo
      const [firstName, lastName] = patient
        ? [patient.firstName, patient.lastName]
        : patientName.split(' ')

      if (specimens.length) {
        const type = replaceEggToOocyte(specimens[0].specimenType)
        const cryoDeviceFromSelectedTickets = specimensInSelectedTicket.map((specimen) => ({
          ...specimen,
          identificationNumber,
          procedureDateFrom,
          type,
          firstName,
          lastName
        }))

        activeBeacon = {
          active: true,
          beaconId: beaconBarcode,
          cryoDevice: cryoDeviceFromSelectedTickets,
          done: false
        }
      } else {
        // need it to complete future tickets

        const type = replaceEggToOocyte(beacons[0].cryoDevice[0].specimenType)
        // type = beacons[0].cryoDevice[0].specimenType
        const cryoDevice = beacons[0].cryoDevice.map((cryoDevice) => ({
          identificationNumber,
          procedureDateFrom,
          type,
          firstName,
          lastName,
          ...cryoDevice
        }))
        activeBeacon = { ...beacons[0], cryoDevice }
      }
    } else {
      if (
        selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE ||
        (beacons.length > 1 && currentTicketStep === STEP_FINAL)
      ) {
        // adding patient and procedure info
        const cryoDeviceFromBatch = beacons[activeSlide - 1].cryoDevice.map((cryoDevice) => {
          const procedureDateFrom = beacons[activeSlide - 1].cryoDevice[0]?.cryoDate
            ? dayjs(Number(beacons[activeSlide - 1].cryoDevice[0].cryoDate))
                .format('DDMMMYYYY')
                .toUpperCase()
            : cryoDevice.procedureDateFrom

          return {
            ...selectedPatient[activeSlide - 1],
            ...cryoDevice,
            procedureDateFrom
          }
        })

        activeBeacon = {
          active: true,
          beaconId: beacons[activeSlide - 1].beaconBarcode,
          cryoDevice: cryoDeviceFromBatch,
          done: false
        }
      } else {
        // used on labelScan, create ticket and finish ticket views
        activeBeacon = beacons.find((beacon) => beacon.active) || {}
      }
    }

    return activeBeacon
  } catch (error) {
    throw Error(`reprintLabelConfig error --> ${error}`)
  }
}

const sendPrintJob = ({
  selectedPrinter,
  activePrinters,
  activeBeacon,
  cryoDeviceReprintIndex,
  onCloseLabelModal
}: printJob) => {
  if (selectedPrinter !== 'pdf') {
    const {
      firstName,
      lastName,
      identificationNumber,
      patientId: globalPatientNumber,
      procedureDateFrom
    } = activeBeacon.cryoDevice

    const currentTicket = {
      patient: {
        firstName,
        lastName,
        identificationNumber,
        globalPatientNumber,
        selected: true
      },
      procedureDateFrom
    }

    activePrinters.forEach((printer: { uid: string | number }) => {
      if (printer.uid === selectedPrinter) {
        printSingleLabel(printer, currentTicket, activeBeacon, cryoDeviceReprintIndex).then(() => {
          onCloseLabelModal()
        })
      }
    })
  } else {
    labelToPdf('labelToReprint')
    onCloseLabelModal()
  }
}

const getBeaconData = ({ indexKey, isEMRTicket, item }) => {
  const selectedTickets: reprintConfigType['selectedTickets'] = [
    ...store.getters['selectedTicketsModule/selectedTickets']
  ]
  const activeSlide = ss.getFieldSessionStorage('process', 'activeSlideSelectedTickets') || 1

  const { beacons, selectedMethod, selectedPatient, currentTicketStep } =
    ss.getFieldSessionStorage('newTicket') || []
  if (isEMRTicket && beacons.length) {
    selectedTickets[activeSlide - 1] = {
      ...selectedTickets[activeSlide - 1],
      specimens: []
    }
  }
  const activeBeacon: any = reprintLabelConfig({
    selectedTickets,
    activeSlide,
    beacons,
    selectedMethod,
    selectedPatient,
    currentTicketStep
  })
  let cryoDeviceReprintIndex
  if (indexKey === undefined) {
    cryoDeviceReprintIndex = activeBeacon.cryoDevice?.findIndex(
      ({ cryodeviceBarcode }) => cryodeviceBarcode === item.cryodeviceBarcode
    )
  } else {
    cryoDeviceReprintIndex = indexKey
  }
  return {
    cryoDeviceReprintIndex,
    activeBeacon
  }
}

export { reprintLabelConfig, sendPrintJob, getBeaconData }
