<template>
  <div
    data-test="modal-generic"
    class="fixed top-0 left-0 w-screen h-screen justify-center items-center flex z-50 bg-black bg-opacity-50"
  >
    <div class="bg-white py-4 pl-4 shadow-md rounded-md" :class="modalWidth">
      <ActionBar data-test="modal-reload-action-bar" :cols-distribution="colsDistribution">
        <template #left-side>
          <h1 data-test="modal-generic-title" :class="[...titleClasses]">
            {{ title }}
          </h1>
        </template>
        <template #right-side>
          <button-dynamic
            data-test="modal-generic-close"
            btn-text=""
            btn-type="button"
            btn-style="close-button"
            show-icon
            no-padding
            :font-awesome-icon-class="closeIcon"
            @click="handleCancel"
          />
        </template>
      </ActionBar>
      <h2 v-if="message" data-test="modal-generic-message" :class="[...messageClasses]">
        {{ message }}
      </h2>
      <div class="flex mt-6" :class="[buttonAligment]">
        <button-dynamic
          v-if="!hideCancel"
          data-test="modal-generic-cancel"
          add-margin-right
          :btn-text="cancelLabel"
          btn-type="button"
          :btn-style="cancelButtonStyle"
          @click="handleCancel"
        />
        <button-dynamic
          data-test="modal-generic-confirm"
          add-margin-right
          :btn-text="confirmLabel"
          btn-type="button"
          :showIcon="!!confirmIcon"
          :fontAwesomeIconClass="confirmIcon"
          :btn-style="confirmButtonStyle"
          @click="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
// TODO:
// This should be added functionality based on requirements by generic modals, such as:
// Additional buttons, different header styling, other close buttons, templates, etc
// right now is very simple

import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

const emit = defineEmits(['confirm', 'cancel'])

defineProps({
  title: {
    type: String,
    required: true
  },
  titleClasses: {
    type: Array,
    required: false,
    default: () => ['text-3xl', 'font-medium']
  },
  messageClasses: {
    type: Array,
    required: false,
    default: () => ['text-2xl']
  },
  closeIcon: {
    type: String,
    required: false,
    default: 'times-circle-solid'
  },
  message: {
    type: String,
    required: false
  },
  confirmLabel: {
    type: String,
    required: false,
    default: 'Confirm'
  },
  cancelLabel: {
    type: String,
    required: false,
    default: 'Cancel'
  },
  hideCancel: {
    type: Boolean,
    required: false,
    default: false
  },
  modalWidth: {
    type: String,
    required: false,
    default: 'w-1/2'
  },
  colsDistribution: {
    type: String,
    required: false,
    default: '6/6'
  },
  confirmButtonStyle: {
    type: String,
    required: false,
    default: 'primary'
  },
  cancelButtonStyle: {
    type: String,
    required: false,
    default: 'secondary'
  },
  buttonAligment: {
    type: String,
    required: false,
    default: 'justify-end'
  },
  confirmIcon: {
    type: String,
    required: false
  }
})

const handleCancel = async () => {
  emit('cancel')
}

const handleConfirm = async () => {
  emit('confirm')
}
</script>
