<template>
  <MainContainer data-test="final-ticket-view" gridSlots="3">
    <top-header @backStep="handleClickBack"
      >Select the items to create a {{ subType }} Batch Ticket</top-header
    >
    <ActionBar data-test="final-ticket-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>&nbsp;</template>
      <template v-slot:right-side>
        <span
          v-if="selectedItems.length > 0"
          class="font-inconsolata text-2xl text-tmrw-green-light mr-6"
          data-test="selected-tickets"
          >{{ selectedItems.length }} Tickets Selected</span
        >
        <span
          v-if="selectedItems.length > 0 && subType === batchRetrieve"
          class="font-inconsolata text-2xl text-tmrw-green-light mr-6"
          data-test="selected-robot"
        >
          | Location: Cryorobot {{ robotSelected[0] }}
        </span>
        <button-dynamic
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          :hasMarginRight="true"
          :hasMarginBottom="true"
          :showIcon="true"
          fontAwesomeIconClass="check-circle"
          :isDisabled="selectedItems.length <= 1 || selectedItems.length > maxBatchLength"
          @click="constructTicketViewData"
        />
      </template>
    </ActionBar>

    <TableComponent
      v-if="!processingUpload && shouldHaveTickets(apiElements)"
      :headers="Headers"
      :procedure="subType"
      :apiElements="apiElements"
      :referenceKey="referenceKey"
      :screenigKey="screenigKey"
      :disabledKey="disabledKey"
      :procedureKey="procedureKey"
      :robotIdKey="robotIdKey"
      @sortColumn="sortColumn"
      @selectedColumn="selectedColumn"
      :sort="sort"
    />

    <div v-else class="bg-white rounded-lg rounded-tl-none h-40 flex items-center p-6">
      <span
        class="ticket-table__header bg-gradient-to-r from-tmrw-green-light to-transparent flex justify-between p-5 text-tmrw-blue-light rounded-lg"
      >
        There's no ticket available for batching
      </span>
    </div>
  </MainContainer>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import TableComponent from '@/components/TableComponent/TableComponent.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import * as $ss from '@/config/session-storage-help'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { setBatchData, resetTicketProcess } from '@/helpers/manageTicket/utils'
import {
  FILTER_FROM_TODAY,
  PROCEDURE_BATCH_RETRIEVE,
  INFECTIOUS_SCREENING_STATUS,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  CRYOBEACONS,
  PROCEDURE_TYPE_BEACON_MOVE,
  LOADING_BATCHABLE_TICKETS,
  SOURCE,
  DESTINATION,
  COMPLETE,
  ROBOT_ID,
  CURRENT_LOCATION,
  MAX_BATCH_LENGTH
} from '@/constants'
import {
  SortFactory,
  ApiFactory,
  ApiCallsFactory,
  DateFactory,
  ObjectFactory,
  ScreeningStatusFactory,
  SelectionFactory,
  RequestParams,
  PersistenceFactory,
  StatusFactory,
  SortProps
} from '@/factories'
import toast from '@/config/toast'
import { TicketsResponse, THeaders } from './Interfaces'

type composeDataType = {
  robotLocations: {
    value: [
      {
        robotNumber: number
        type: string
        robotId: string
      }
    ]
  }
  patientDob: { value: string }
  procedureTime: { value: string }
  procedureName: { value: string }
  procedureType: { value: string }
  screeningStatus: { value: number }
  state: { value: string }
  ticketId: number
  identificationNumber: number
  patientName: string
  source: { value: number }
  procedure: { value: string }
}

type batchPersistenceType = {
  batchPersistence: (
    apiElements: object[],
    selectedItems: {
      map: (params: {}) => {}
      splice: (index: number, itemsToRemove: number) => []
      push: (params: {}) => []
      length: number
    },
    robotSelected: number | object[],
    referenceKey: number,
    procedureName: string,
    disabledKey: number,
    screenigKey: number,
    procedureKey: number
  ) => object
}

export default {
  name: 'new-batch-ticket',
  components: {
    TopHeader,
    MainContainer,
    TableComponent,
    ButtonDynamic,
    ActionBar
  },
  data() {
    return {
      filter: FILTER_FROM_TODAY as string,
      sort: {} as SortFactory,
      subType: $ss.getFieldSessionStorage('newTicket', 'subType') as string,
      data: [] as ReturnType<typeof Object>,
      Headers: [
        'Date and Time',
        'Patient Name',
        'ID Number',
        'Date of Birth',
        'Ticket #',
        INFECTIOUS_SCREENING_STATUS,
        CURRENT_LOCATION,
        'Procedure',
        'Status'
      ] as THeaders,
      apiElements: [] as object[],
      composedData: [] as ReturnType<typeof Object>,
      referenceKey: 4 as number, // index of the ticketId located on apiElements Array as flag
      disabledKey: 6 as number, // index of the RobotLocation located on apiElements Array as flag
      screenigKey: 5 as number, // index of screening status located on apiElements Array as flag,
      procedureKey: 9 as number, // index of procedure name located on apiElements Array as flag
      robotIdKey: 10 as number, // index of robotIdKey located on apiElements Array as flag
      selectedItems: [] as ReturnType<typeof Object>,
      robotSelected: [] as object[] | number,
      appBaseConfig: {
        siteId: $ss.getFieldSessionStorage('config', 'siteId') || null,
        clinicId: $ss.getFieldSessionStorage('config', 'clinicId') || null,
        cpId: $ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
        token: $ss.getFieldSessionStorage('user', 'token') || null,
        userId: $ss.getFieldSessionStorage('user', 'userId') || null
      } as ReturnType<typeof Object>,
      beacons: [] as object[],
      apiCacheResponse: [] as object[],
      batchRetrieve: PROCEDURE_BATCH_RETRIEVE as string,
      processingUpload: true as boolean,
      fromRouteName: undefined,
      maxBatchLength: MAX_BATCH_LENGTH
    }
  },
  beforeRouteEnter(to, from, next) {
    // we need to account for multiple screens that route to ScanBeacon, in
    // order to be able to route back (eg.: click back button)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next((vm) => {
      // access to component instance via `vm`
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  },
  async created(): Promise<void> {
    this.displaySpinner(LOADING_BATCHABLE_TICKETS)
    // construct url
    const url = this.constructURL()
    // executing the server call
    const apiCall = new ApiCallsFactory(url, this.appBaseConfig.token)
    const apiResponse: ReturnType<typeof Object> = await apiCall.getData()
    // Hide Loader
    this.processingUpload = false
    this.hideSpinner()
    // assign it the data to a local reference
    this.data = apiResponse
    // composing object response
    const api = new ApiFactory(apiResponse)
    const response = api.compositeResponse()
    // assign it compose it response to local reference
    this.composedData = response
    // creating local view
    this.apiElementsFactory()
    this.sort = new SortFactory({ data: this.data, sortBy: 'procedureTime', direction: 'desc' })
    this.sortColumn()
  },
  methods: {
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    constructURL(): string {
      // define procedure type
      const procedureSubType = this.subType === 'Store' ? 'IN' : 'OUT'
      // get parameters by filter value
      const params = new RequestParams(this.filter)
      const response = params.getTicketsRequestParams()
      const isBiorepositoryRoute = $ss.getFieldSessionStorage('process', 'isBiorepositoryRoute')
      const moveLocationFilter = isBiorepositoryRoute
        ? `&procedure=${PROCEDURE_TYPE_BEACON_MOVE}&includeAllSites=false&skipExclusions=true`
        : ''
      // return url to execuute api call
      return `/tickets?dateFromMsUtc=${response.dateFromMsUtc}&cpId=${this.appBaseConfig.cpId}&batcheable=true&robotTransfer=${procedureSubType}${moveLocationFilter}`
    },
    selectedColumn(
      id: number,
      screeningId: number,
      robotLocation: number,
      procedure: string,
      itemRef: any,
      robotId: string
    ): object {
      this.robotSelected = robotLocation
      // @ts-ignore
      const isSelected: string =
        itemRef.currentTarget.classList[itemRef.currentTarget.classList.length - 1]
      const selectColumn = new SelectionFactory(
        isSelected,
        this.selectedItems,
        id,
        procedure,
        robotId,
        screeningId
      )
      // clone array to update the watcher
      this.selectedItems = [...selectColumn.selectedColumn()]
      // sorting response
      this.sortColumn()
      // construct view
      return this
    },
    sortColumn(sortProps: SortProps = {}): void {
      // cleaning
      this.composedData = []
      // executing sorting by key

      this.sort.sortColumn(sortProps)
      // composing object response
      const api = new ApiFactory(this.data)
      const response = api.compositeResponse()
      // assign it compose it response to local reference
      this.composedData = response
      // creating local view
      this.apiElementsFactory()
      this.selectedPersistence()
    },
    getLocationFromItem(item: composeDataType) {
      if (item.procedure.value !== PROCEDURE_TYPE_BEACON_MOVE) {
        return item.robotLocations.value[0]
      }
      const robotLocationType = item.state.value === COMPLETE ? DESTINATION : SOURCE
      return item.robotLocations.value.find(
        (robotLocation) => robotLocation.type === robotLocationType
      )
    },
    apiElementsFactory(): void {
      this.apiElements = []
      this.composedData.forEach((item: composeDataType) => {
        const location = this.getLocationFromItem(item) || item.robotLocations.value[0]
        // convert unix time to readable time
        const newProceduteTime: { procedureTime: () => string } = new DateFactory(
          item.procedureTime.value
        )
        const newPatientDateOfBirth: { patientDob: () => string } = new DateFactory(
          item.patientDob.value
        )
        const ProcedureTime: { composite: () => object } = new ObjectFactory(
          item.procedureTime,
          'value',
          newProceduteTime.procedureTime()
        )
        const PatientDOB: { composite: () => object } = new ObjectFactory(
          item.patientDob,
          'value',
          newPatientDateOfBirth.patientDob()
        )
        ProcedureTime.composite()
        PatientDOB.composite()
        const currentLocation = this.allContainersBySite.filter(
          (container) => container.containerId === Number(location.robotId)
        )[0]?.robotName
        // adding text to robotLocation
        const RobotName: { deepComposite: () => object } = new ObjectFactory(
          item.robotLocations,
          'robotNumber',
          currentLocation,
          location.robotNumber
        )
        RobotName.deepComposite()
        const RobotId: { deepComposite: () => object } = new ObjectFactory(
          item.robotLocations,
          ROBOT_ID,
          location.robotId
        )
        RobotId.deepComposite()
        // getting screening status
        if (!item.screeningStatus) {
          // eslint-disable-next-line no-param-reassign
          item.screeningStatus = { id: 'screeningStatus', value: 0 } as any
        }

        const newScreeningStatus: ScreeningStatusFactory = new ScreeningStatusFactory(
          item.screeningStatus?.value
        )
        const composingScreeningStatus: { composite: () => object } = new ObjectFactory(
          item.screeningStatus,
          'value',
          newScreeningStatus.compositeResponse(),
          item.screeningStatus?.value || 0
        )
        composingScreeningStatus.composite()
        // getting status
        const getTicketStatus: { batchStatus: () => string } = new StatusFactory(
          item.state.value,
          item.procedureName.value,
          item.source.value,
          item.procedureType.value
        )
        const TicketStatusCompose: { composite: () => object } = new ObjectFactory(
          item.state,
          'value',
          getTicketStatus.batchStatus(),
          item.state.value
        )
        TicketStatusCompose.composite()

        // composing the data to be displayed
        const tempArray = [
          item.procedureTime,
          item.patientName,
          item.identificationNumber,
          item.patientDob,
          item.ticketId,
          item.screeningStatus,
          location.robotNumber,
          item.procedureName,
          item.state,
          item.procedure,
          location.robotId
        ] as object[]
        return this.apiElements.push(tempArray)
      })
    },
    selectedPersistence(): void {
      // implementing persistence logic
      const newPersistence: batchPersistenceType | ReturnType<typeof Object> =
        new PersistenceFactory()
      newPersistence.batchPersistence(
        this.apiElements,
        this.selectedItems,
        this.robotSelected,
        this.referenceKey,
        this.subType,
        this.disabledKey,
        this.screenigKey,
        this.procedureKey,
        this.robotIdKey
      )
    },
    async constructTicketViewData() {
      try {
        this.displaySpinner('Processing Batch Tickets')
        // cleaning storage
        resetTicketProcess()
        // Set array to push Patients names for all batched tickets
        const batchPatient: {
          birthDate: string
          clinicId: number
          firstName: string
          fullName?: string
          globalPatientNumber: number
          identificationNumber: string
          lastName: string
          selected?: boolean
          siteId: number
          specimenCount: number
        }[] = []
        const batchSharedPatientIds: number[][] = []
        // getting the data for any particular ticket
        return this.selectedItems.map(async (selectedItem: TicketsResponse) => {
          // constructing the tickets url
          const url = `/tickets/${selectedItem.ticketId}?cpId=${this.appBaseConfig.cpId}`
          // creating a new instance of the promise call
          const apiCall = new ApiCallsFactory(url, this.appBaseConfig.token)
          const apiResponse: ReturnType<typeof Object> = await apiCall.getData()
          // destructuring local refs
          const {
            beaconBarcode,
            specimens,
            patientDob,
            patientName,
            identificationNumber,
            globalPatientNumber,
            robotLocations,
            inventoryUpdate,
            sharedPatientIds = []
          } = apiResponse[0]
          if (!specimens.length) {
            toast.error({ title: `Ticket ${selectedItem.ticketId} has no specimens` })
            await this.hideSpinner()
            return
          }
          // assignig data to new object
          const beacons = {
            beaconBarcode,
            cryoDevice: specimens,
            robotLocations,
            inventoryUpdate
          }
          // splitting full name to name and lastname
          const batchPatientName = patientName.split(' ')
          // setting up patient info
          batchPatient.push({
            birthDate: patientDob,
            firstName: batchPatientName[0],
            lastName: batchPatientName[1],
            globalPatientNumber,
            identificationNumber,
            clinicId: this.appBaseConfig.clinicId,
            siteId: this.appBaseConfig.siteId,
            specimenCount: specimens.length
          })
          batchSharedPatientIds.push(sharedPatientIds)
          // pushing beacons to local ref
          this.beacons.push(beacons)
          // pushing apiCache to a localref
          this.apiCacheResponse.push(apiResponse[0])
          // setting up data need it for FinalTicket View
          this.batchDataToBePesistence(this.apiCacheResponse)
          // in order to avoid backend concunrrency issues and network latency
          // we're waiting till all the api calls have been done
          // we're asserting this by using the index
          const sessionCache = await $ss.getFieldSessionStorage('batch', 'cache')
          if (sessionCache.length === this.selectedItems.length) {
            // @ts-ignore
            const nextStepPath = await executeNextTicketStep({
              selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
              currentTicketStep: this.currentTicketStep,
              specimenType: specimens[0]?.specimenType,
              patients: batchPatient,
              batchSharedPatientIds,
              // @ts-ignore
              beacons: this.beacons
            })
            await this.hideSpinner()
            return this.$router.push({ name: nextStepPath })
          }
        })
      } catch (error) {
        await this.hideSpinner()
        return error
      }
    },
    batchDataToBePesistence(apiCacheResponse: object): object {
      try {
        // getting selected id's and storing locally
        const childrens: object[] = []
        this.selectedItems.map((selectedItem: { ticketId: object }) =>
          childrens.push(selectedItem.ticketId)
        )
        // getting subType
        const procedureSubType: string = this.subType === 'Store' ? 'IN' : 'OUT'
        // creating object to persist locally
        const dataToPersist = {
          procedureSubType,
          childrens,
          userId: this.appBaseConfig.userId,
          cpId: this.appBaseConfig.cpId,
          subType: this.subType,
          cache: apiCacheResponse
        }
        // assigning the value to a local reference
        setBatchData(dataToPersist)
      } catch (error) {
        throw Error(`batchDataToBePesistence error -> ${error}`)
      }
      return this
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })
      this.$router.replace({ name: previousStepPath })
    },
    shouldHaveTickets(apiElements: Object[]): boolean {
      if (!apiElements) return false
      return !!apiElements.length
    }
  },
  computed: {
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod']),
    ...mapGetters('siteModule', ['allContainersBySite'])
  },
  watch: {
    selectedItems(newValue) {
      if (newValue.length > MAX_BATCH_LENGTH) {
        toast.error({
          message: `You cannot select more than ${MAX_BATCH_LENGTH} ${CRYOBEACONS} in a single Batch Ticket. Please unselect ${CRYOBEACONS} to continue.`
        })
      }
    }
  }
}
</script>
