/**
 *  Accepted Criteria:
 *  https://tmrworg.atlassian.net/browse/DEV-1456
 * SCHEDULED("Scheduled") -  Nothing
 * IN_PROGRESS("In Progress") - inProgress
 * COMPLETE("Complete") - Check Mark
 * Applies to both schedule and in progress
 * procedureTime last day - inProgress && red Exclamantion Mark
 * IsEmrTicket - Lock
 */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TICKET_COMPLETE } from '@/constants'

dayjs.extend(utc)

export const setTicketIcons = ({
  procedureTime,
  state,
  icons,
  isEmrTicket,
  isSingleImport,
  isBatchTicket
}) => {
  const {
    overdue, green, inProgress, lock, importCryoBeacon, batchIcon
  } = icons

  const procedureIconsArray = []
  let stateIcons = []
  let batchIconArray = []

  const isComplete = state === TICKET_COMPLETE

  // Overdue Tickets are the ones from the day before and on
  const begginingOfDay = dayjs().utcOffset(0).startOf('day').valueOf()
  const isOverDue = procedureTime < begginingOfDay && !isComplete

  // Over due
  const overDueIcon = isOverDue ? [overdue] : []

  // Procedure Icons
  if (isEmrTicket) {
    procedureIconsArray.push(lock)
  }

  if (isSingleImport) {
    procedureIconsArray.push(importCryoBeacon)
  }

  // State Icons
  switch (state) {
    case 'In Progress':
      stateIcons = [inProgress]
      break
    case 'Complete':
      stateIcons = [green]
      break
    default:
      stateIcons = []
  }

  if (isBatchTicket) {
    batchIconArray = [batchIcon]
  }

  return [...overDueIcon, ...stateIcons, ...procedureIconsArray, ...batchIconArray]
}

export default {
  setTicketIcons
}
