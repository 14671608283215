import { httpRequest } from '@/helpers/apiHelpers'
import { PostSessionApi, DeleteSessionApi } from './types'

export const deleteSessionApi = ({
  headers
}: DeleteSessionApi) => httpRequest(false).delete('/sessions', { headers })

export const postSessionApi = ({
  username,
  password
}: PostSessionApi) => httpRequest(false).post('/sessions', { username, password })

export const postRenewSessionApi = () => httpRequest(false).post('/sessions/renew')
