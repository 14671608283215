<template>
  <div
    v-if="!isHidden"
    class="scroll-table-link-field flex flex-row items-center justify-center text-tmrw-blue"
  >
    <TooltipTable
      class="scroll-table-tooltip-field"
      :headers="tooltipHeaders"
      :items="tooltipItems"
      :faIcon="iconClass"
      :displayHeaders="false"
      v-if="tooltip"
    />
    <i
      v-else
      class="mr-1"
      :class="[,
        iconClass,
        `${iconColor || 'text-tmrw-blue'}`,
      ]"
    />
    <span class="border-b border-solid border-tmrw-blue text-tmrw-blue font-bold font-exo ml-2">
      {{ label }}
    </span>
  </div>
</template>

<script>
import TooltipTable from '@/components/TooltipTable/TooltipTable.vue'

export default {
  name: 'scroll-table-icon-field',
  props: {
    /**
     * Used to pass in the "to" and the "icon" name values
     */
    value: {
      type: Object,
      default: () => ({
        icon: null,
        iconColor: null
      })
    },
    /**
     * Label that goes beside icon as part of the link as well
     */
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    isHidden() {
      return Boolean(this.value.hidden)
    },
    iconClass() {
      return this.value.iconClass
    },
    iconColor() {
      return this.value.iconColor
    },
    tooltip() {
      return this.value.tooltip
    },
    tooltipItems() {
      if (this.tooltip) {
        return [{ content: this.tooltip }]
      }
      return []
    },
    tooltipHeaders() {
      if (this.tooltip) {
        return [{ title: '', key: 'content' }]
      }
      return []
    }
  },
  components: {
    TooltipTable
  }
}
</script>
