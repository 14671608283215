import { httpRequest } from '@/helpers/apiHelpers'
import { GetCheckBeaconId, GetBeacons, GetBeaconSpecimens } from './types'

export const getCheckBeaconIdApi = ({
  containerType,
  barcode,
  cpId,
  extraParams
}: GetCheckBeaconId) => httpRequest().get(`/validations`, {
  params: {
    barcode,
    cpId,
    ...extraParams
  },
  headers: {
    'container-type': containerType
  }
})

export const getBeaconsApi = ({
  patientId,
  screeningStatus
}: GetBeacons) => httpRequest().get(`/patients/${patientId}/beacons?screeningStatus=${screeningStatus}`)

export const getBeaconSpecimens = ({
  beaconId
}: GetBeaconSpecimens) => httpRequest().get(`beacons/${beaconId}/specimens`)
