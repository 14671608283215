<template>
  <section
    class="grid p-4 h-screen"
    :class="generateGridRowClass()">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'main-container',
  props: {
    gridSlots: {
      type: String,
      default: '4',
      required: true
    }
  },
  methods: {
    generateGridRowClass() {
      switch (this.gridSlots) {
        case '1':
          return 'grid-rows-[1fr]'
        case '2':
          return 'grid-rows-[50px_1fr]'
        case '3':
          return 'grid-rows-[50px_75px_1fr]'
        case '6':
          return 'grid-rows-[50px_75px_178px_45px_264px_1fr]'
        case '7':
          return 'grid-rows-[50px_75px_85px_70px_150px_70px_1fr]'
        case '8':
          return 'grid-rows-[50px_75px_55px_1fr]'
        case 'select-patient':
          return 'grid-rows-[50px_75px_110px_0.5fr_5em]'
        case 'biorepository-select-patient':
          return 'grid-rows-[70px_50px_50px_44px_168px_auto_1fr]'
        case 'editable-table':
          return 'grid-rows-[38px_1fr]'
        case 'patients':
          return 'grid-rows-[50px_75px_164px_1fr]'
        default:
          return 'grid-rows-[50px_50px_56px_1fr]'
      }
    }
  }
}
</script>
