<template>
  <span data-test="scroll-table-shipping-source" class="font-exo text-base">
    {{ clinicName }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'scroll-table-shipping-source-field',
  props: {
    value: {
      type: String,
      default: '--',
      required: true
    }
  },
  computed: {
    ...mapGetters('patientsModule', ['externalClinics']),
    clinicName() {
      const [shippingSource] = this.externalClinics.filter(({ id }) => id === Number(this.value))
      return shippingSource?.name || '--'
    }
  }
}
</script>
