<template>
  <ScrollTableComponent
    data-test="patient-group__table"
    minHeight="h-full"
    :headers="tableHeaders"
    :footerColumns="tableFooter"
    v-model="patientsModel"
    @onToggleFieldChanged="handleFieldChange"
    @onButtonClicked="handleFieldClick"
  />
</template>

<script>
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { headers, footer } from '@/constants/patient-group/index'
import { isPrimaryPatientColumn, isRemovePatientColumn } from '@/modules/patient-group'

export default {
  name: 'PatientGroup',
  components: {
    ScrollTableComponent
  },
  emits: ['changePrimaryPatient', 'removePatient'],
  props: {
    patients: {
      type: Array,
      required: true,
      minLength: 1
    },
    primaryPatientId: {
      type: Number,
      required: false,
      default: 0
    },
    totalShared: {
      type: Object,
      default: () => ({
        embryoCount: 0,
        oocyteCount: 0,
        specimenCount: 0
      })
    },
    canChangePrimaryPatient: {
      type: Boolean,
      default: false
    },
    canRemovePatient: {
      type: Boolean,
      default: false
    },
    hidePrimaryPatient: {
      type: Boolean,
      default: false
    },
    // This is used to add the "View" link column that redirects to patient view on the table
    showViewPatientLink: {
      type: Boolean,
      default: false
    },
    // This is used to not display the "View" column for a specific patient
    hideViewPatientId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      patientsModel: {
        options: [],
        sort: {
          orderBy: 'patientName',
          direction: 'desc'
        }
      }
    }
  },
  methods: {
    changePrimaryPatient(patientId) {
      const { canChangePrimaryPatient } = this
      if (canChangePrimaryPatient) {
        this.$emit('changePrimaryPatient', patientId)
      }
    },
    addViewPatientLinkToPatients() {
      this.patientsModel.options = this.patients.map((patient) => ({
        ...patient,
        seeMore: {
          icon: 'eye',
          label: 'View',
          hidden: patient.globalPatientNumber === this.hideViewPatientId,
          to: `/patients/${patient.globalPatientNumber}`
        }
      }))
    },
    handleFieldChange({ column, fieldValue }) {
      if (isPrimaryPatientColumn(column)) {
        this.changePrimaryPatient(fieldValue)
      }
    },
    handleFieldClick({ column, value }) {
      if (isRemovePatientColumn(column)) {
        this.removePatient(value)
      }
    },
    removePatient(patientId) {
      if (this.canRemovePatient) {
        this.$emit('removePatient', patientId)
      }
    }
  },
  computed: {
    tableHeaders() {
      const headerProps = {
        changeable: this.canChangePrimaryPatient,
        canRemovePatient: this.canRemovePatient,
        showViewPatientLink: this.showViewPatientLink,
        primaryPatientId: this.primaryPatientId
      }
      let columns = headers(headerProps)
      if (this.hidePrimaryPatient) {
        columns = columns.filter((column) => !isPrimaryPatientColumn(column))
      }
      return columns
    },
    tableFooter() {
      const obj = footer(this.tableHeaders, this.totalShared)
      return obj
    }
  },
  created() {
    const { patients } = this
    this.patientsModel.options = patients
    if (this.showViewPatientLink) {
      this.addViewPatientLinkToPatients()
    }
  }
}
</script>
