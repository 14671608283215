<template>
  <loading-ui v-if="isLoading" modal message="Loading Tickets" />
  <MainContainer v-if="!isLoading" data-test="final-ticket-view" gridSlots="3">
    <top-header @backStep="handleClickBack" :isDisabled="ticketHasBeenCreated">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="final-ticket-view__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h3-bold" floatTo="left">{{ pageTitle }}</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="hasTicketIds"
          btnText="Print"
          btnType="button"
          btnStyle="secondary"
          data-test="final-ticket__print-button"
          showIcon
          fontAwesomeIconClass="print"
          addMarginRight
          @click="showPrinterModal"
        />
        <button-dynamic
          btnDataTest="final-ticket__return-to-flightboard-button"
          v-if="hasTicketIds"
          btnText="Return to Flight Board"
          btnType="button"
          btnStyle="primary"
          @click="returnToFligthBoard"
        />
        <button-dynamic
          btnDataTest="final-ticket__create-ticket-button"
          v-else
          btnText="Create Ticket"
          btnType="button"
          btnStyle="primary"
          @click="createTicket"
        />
      </template>
    </ActionBar>
    <div class="final-ticket">
      <BreadCrumbs v-if="isExport" :items="breadcrumbsItems" />
      <BreadCrumbs v-if="isFreeze || isImport" :items="getActiveBreadcrumbs" />
      <div
        v-if="isPrinterModalVisible"
        data-testid="final-ticket__printer-modal"
        class="h-screen w-screen flex justify-center items-center z-50 fixed t-0 l-0 bg-black bg-opacity-50 top-0 left-0"
      >
        <div class="relative bg-tmrw-blue p-4 rounded-lg w-100">
          <span
            class="absolute flex justify-center items-center top-0 right-0 bg-white rounded-full p-2 w-4 h-4 mt-5 mr-5 cursor-pointer"
          >
            <i @click="closePrinterModal" class="fas fa-times text-sm text-blue-600" />
          </span>
          <h3 class="font-bold text-white text-2xl mb-4">Select Printer</h3>
          <p>
            <span class="text-white">Default printer: </span>&nbsp;
            <select v-model="selectedPrinter">
              <option class="text-black" value="0">Print PDF</option>
              <option
                class="text-black"
                v-for="(printer, index) in activePrinters"
                :key="index"
                :value="printer.uid"
              >
                {{ getPrinterName(printer.name) }}
              </option>
            </select>
          </p>
          <button-dynamic
            btnText="Print"
            btnType="button"
            btnStyle="primary"
            floatTo="right"
            showIcon
            fontAwesomeIconClass="print"
            add-margin-top
            @click="sendPrintJob"
          />
        </div>
      </div>
      <div
        data-testid="final-ticket__ticket-card-container"
        class="overflow-auto box-border relative mb-4"
        v-if="ticketsToDisplay.length"
      >
        <div
          v-if="hasCryoRobotSelector"
          data-test="cryorobot-destination-selector-wrapper"
          class="mb-20"
        >
          <div class="flex w-full bg-white rounded-md py-5 px-4 mb-5 items-center">
            <!-- Dropdown will always show for batch Store, even if there is only one or none option to chose from -->
            <CustomSelect
              class="w-72"
              data-testid="batch-ticket__destination"
              dataTest="batch-ticket__destination"
              :options="destinationOptions"
              hasLargePadding
              noTemporaryContainers
              noNonTmrwLocation
              hasFontInconsolata
              :default-option-label="defaultOptionLabel"
              has-large-font
              @update:modelValue="handleSelectDestination"
              isDefaultOptionDisabled="true"
              :modelValue="defaultDestinationValue"
              :show-error="noDestinationSelected"
            />
            <p v-if="noDestinationSelected" class="text-tmrw-error font-inconsolata text-2xl mx-16">
              <i class="fa fa-exclamation-triangle" /> {{ chooseCryorobotLabel }}
            </p>
          </div>
          <dynamic-title titleType="h3-bold" floatTo="left">{{ pageSubtitle }}</dynamic-title>
        </div>
        <paginator
          :total-items-to-display="ticketsToDisplay.length"
          :max-paginators-count-per-view="maxPaginatorsCountPerView"
          :currentActivePage="currentActivePage"
          @handlePageChange="onChangeTicketToView"
          data-test="final-ticket__paginator"
        />
        <TicketToFinish
          v-for="(ticket, key) in getItemsToPrintPerPage"
          :ticket="ticket"
          :ticketId="currentTicketId"
          :key="`ticket-to-finish-${key}`"
          :newTicketData="newTicketData"
          :selectedTicketIndex="selectedTicketIndex"
        />
        <div class="mt-10" v-if="showRemainingSpecimens">
          <dynamic-title addMarginBottom titleType="h3" floatTo="left">
            Remaining in CryoBeacon
          </dynamic-title>
          <div class="flex w-full bg-white rounded-md py-5 px-10 mt-5">
            <span class="w-full">
              <InventorySpecimenTables
                data-test="ticket-detail__remaining-specimens"
                class="w-full"
                :ticketDetail="ticketDetailModel"
                :eggSpecimenHeaders="eggHeaders"
                :eggSpecimens="eggSpecimens"
                :embryoSpecimenHeaders="embryoHeaders"
                :embryoSpecimens="embryoSpecimens"
              />
            </span>
          </div>
        </div>
        <CustomizableModal
          v-if="isCustomModalOpen"
          :title="customizableModalTitle"
          :message="customizableModalMsg"
          @hideModal="hideCustomizableModal"
          :buttonsData="customizableModalButtonsData"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toast from '@/config/toast'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import TicketToFinish from '@/components/TicketToFinish/TicketToFinish.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CustomizableModal from '@/components/CustomazibleModal/CustomazibleModal.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import Paginator from '@/components/Paginator/Paginator.vue'
import { printTickets, getInstalledPrinters, setPrinterName } from '@/modules/printer'
import { selectEmbryoTypeLabel, sortSpecimensByCryodeviceId } from '@/utils'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EGGS,
  SINGLE_IMPORT_CONST,
  ZEBRA_TICKET_PRINTERS_ID,
  METHOD_FREEZE_LOWERCASE,
  METHOD_IMPORT,
  METHOD_EXPORT,
  METHOD_EXPORT_UPPER,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  DONATE_TO_PATIENT_VALUE,
  MAX_PAGINATORS_COUNT_PER_VIEW,
  METHOD_FREEZE,
  CRYOROBOT,
  ROBOT_TRANSFER_IN,
  METHOD_THAW
} from '@/constants'
import * as ss from '@/config/session-storage-help'
import { removeKeysWithNullValues } from '@/helpers/removeKeysWithNullValues'
import { getTicketApi } from '@/services/tickets'
import { fetchPatientsById } from '@/services/patients'
import { selectedTicketToPdf } from '@/helpers/printPDF/helpers'
import { executeNextTicketStep, executePreviousTicketStep, StepType } from '@/helpers/manageTicket'
import { getBreadcrumbs } from '@/helpers/newTicketHelpers'
import { useGetDestination, useValidateDestination } from '@/composables'
import { dynamicEgg, dynamicEmbryo } from '@/constants/table-headers/dynamic-headers'
import { useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { selectedTicketsParamsType } from '@/helpers/printPDF/types'

dayjs.extend(utc)

const fromRoute = inject<{
  name: string
}>('fromRoute')

const router = useRouter()

const returnToFligthBoard = async () => {
  // TODO, This needs cleanup and refactor

  ticketHasBeenCreated.value = false
  let tickets = ticketsToDisplay.value
  if (selectedMethod.value === PROCEDURE_TYPE_BATCH_UPPERCASE) {
    tickets = await ss.getFieldSessionStorage('batch', 'cache')
  }
  const nextStepPath = await executeNextTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    ticketData: tickets[0]
  })
  router.push({ name: nextStepPath })
}

const handleClickBack = () => {
  const previousStepPath = executePreviousTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value,
    fromRouteName: fromRouteName.value as StepType
  })

  router.replace({ name: previousStepPath })
}

const externalClinics = ref<any[]>([])
const isPrinterActive = ref(false)
const isLoading = ref(true)
const noDestinationSelected = ref(false)
const fromRouteName = ref<string>()
const newTicketsData = ref<any[]>([])
const ticketsToDisplay = ref<any[]>([])
const ticketsToPost = ref<any[]>([])
const ticketIds = ref<any[]>([])
const sharedPatientIds = ref<any[]>([])
const activePrinters = ref<any[]>([])
const selectedPrinter = ref('0')
const isPrinterModalVisible = ref(false)
const dynamicPageTitle = ref('')
const isCustomModalOpen = ref(false)
const customizableModalTitle = ref('Please Retry')
const chooseCryorobotLabel = ref(`Choose a ${CRYOROBOT} in order to continue`)
const customizableModalMsg = ref(
  `Unfortunately there isn’t enough space left in the single ${CRYOROBOT} for all of the selected Tickets. You can go back and select less, or return to the FlightBoard and complete the Tickets individually.`
)
const customizableModalButtonsData = ref([
  {
    btnText: 'Return to Flight Board',
    btnType: 'button',
    btnStyle: 'primary',
    action: returnToFligthBoard
  },
  {
    btnText: 'Go back',
    btnType: 'button',
    btnStyle: 'secondary',
    classes: [
      'focus:outline-none bg-white text-tmrw-blue hover:text-black mx-4 rounded-lg cursor-pointer px-4 py-2 text-lg flex justify-center items-center'
    ],
    action: handleClickBack
  }
])
const currentActivePage = ref(1)
const maxPaginatorsCountPerView = ref(MAX_PAGINATORS_COUNT_PER_VIEW)
const ticketHasBeenCreated = ref(false)
const ticketDetailModel = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})

const {
  destinationOptions,
  onSelectDestination,
  selectedDestination: selectedDestinationBatch,
  resetDestination,
  getDestinationOptions
} = useGetDestination()
const { validateUnitSpace, setUnitSpaceValidationFailed, unitSpaceValidationFailed } =
  useValidateDestination()

const { donatePatient } = useGetters('patientsModule')
const { primaryPatientId, patients } = useGetters('patientGroupModule')

const {
  currentTicketStep,
  selectedMethod,
  selectedSpecimenType,
  selectedPatient,
  procedureDateFrom,
  procedureDateTo,
  beacons,
  selectedCryoDeviceType,
  batchSharedPatientIds,
  metadata, // select-reason -> export
  robotTransfer, // cryobeacon-mixin/add-existing-beacon?
  shippingSourceId, // how-many -> import
  donorId // how-many -> import
} = useGetters('newTicketModule')

const { appBaseConfig, loggedUserInfo } = useGetters('authModule')

const { embryoTypes, specimens } = useGetters('specimensModule')

const { selectedDestination } = useGetters('ticketsModule')

const eggHeaders = computed(() => {
  return dynamicEgg({})
})
const embryoHeaders = computed(() => {
  return dynamicEmbryo({})
})

const getActiveBreadcrumbs = computed(() => {
  const currentBreadcrumbs = getBreadcrumbs({
    currentTicketStep: currentTicketStep.value,
    selectedMethod: selectedMethod.value
  })
  return currentBreadcrumbs
})
const breadcrumbsItems = computed(() => {
  return [
    {
      label: 'Specimens',
      active: false
    },
    {
      label: 'Reason',
      active: false
    },
    {
      label: 'Confirm',
      active: true
    }
  ]
})
const robotDestinationTitle = computed(() => {
  return `Choose ${CRYOROBOT} Destination`
})
const pageTitle = computed(() => {
  return hasCryoRobotSelector.value ? robotDestinationTitle.value : dynamicPageTitle.value
})
const pageSubtitle = computed(() => {
  return hasCryoRobotSelector.value ? dynamicPageTitle.value : robotDestinationTitle.value
})
const defaultOptionLabel = computed(() => {
  return `Choose ${CRYOROBOT}`
})
const isFreeze = computed(() => {
  return selectedMethod.value === METHOD_FREEZE
})
const isImport = computed(() => {
  return selectedMethod.value === METHOD_IMPORT
})
const isExport = computed(() => {
  return selectedMethod.value === METHOD_EXPORT
})
const isBatchTicket = computed(() => {
  return selectedMethod.value === PROCEDURE_TYPE_BATCH_UPPERCASE
})
const hasTicketIds = computed(() => {
  return ticketIds.value.length
})
const getItemsToPrintPerPage = computed(() => {
  const maxItemsPerPage = 1
  const currentPageToShow = currentActivePage.value * maxItemsPerPage
  const start = currentPageToShow - maxItemsPerPage
  return ticketsToDisplay.value.slice(start, currentPageToShow).map(filterBatchCryodevices)
})
const currentTicketId = computed(() => {
  return ticketIds.value.length && !isBatchTicket.value
    ? `${ticketIds.value[currentActivePage.value - 1].ticketId}`
    : ''
})

const selectedTicketIndex = computed(() => {
  return currentActivePage.value - 1
})
const newTicketData = computed(() => {
  if (isBatchTicket.value && !hasTicketIds.value) {
    return filterBatchCryodevices(ticketsToDisplay.value[selectedTicketIndex.value])
  }
  if (!newTicketsData.value || !newTicketsData.value.length) {
    return null
  }
  return newTicketsData.value[selectedTicketIndex.value]
})

const hasCryoRobotSelector = computed(() => {
  const subType = ss.getFieldSessionStorage('batch', 'procedureSubType')
  return isBatchTicket.value && !hasTicketIds.value && subType === ROBOT_TRANSFER_IN
})

const isThawProcedure = computed(() => {
  return selectedMethod.value === METHOD_THAW
})
const getSpecimensRemaining = computed(() => {
  if (isBatchTicket.value) {
    return ticketsToDisplay.value[selectedTicketIndex.value].cryoDevice.filter(
      ({ inSelectedTicket }) => !inSelectedTicket
    )
  }
  const enabledSpecimens = specimens.value.all.filter((specimen) => !specimen.disabled)
  const { beaconBarcode } = beacons.value[selectedTicketIndex.value]

  const cryoDevices = beacons.value[selectedTicketIndex.value].cryoDevice.map(
    (cryoDevice) => cryoDevice.cryodeviceBarcode
  )
  // Filter specimens from selected beacon by comparing Beacon barcode (Should be equal)
  // and cryodevice barcode (Should be different, meaning, remaining specimens)
  return enabledSpecimens
    .filter((specimen) => {
      return (
        specimen.beaconBarcode === beaconBarcode &&
        !cryoDevices.includes(specimen.cryodeviceBarcode)
      )
    })
    .map((specimen) => ({ ...specimen, disabled: true }))
})

const embryoSpecimens = computed(() => {
  const embryosModel = {
    ...ticketDetailModel.value,
    options: getSpecimensRemaining.value.filter(
      (item) => item.specimenType === SPECIMEN_TYPE_EMBRYO
    )
  }
  return embryosModel
})
const eggSpecimens = computed(() => {
  const eggsModel = {
    ...ticketDetailModel.value,
    options: getSpecimensRemaining.value.filter(
      (item) =>
        item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
    )
  }
  return eggsModel
})
const showRemainingSpecimens = computed(() => {
  // Note: Maybe is not necessary to validate procedure type, and only validate getSpecimensRemaining
  const allowRemainMethods = [PROCEDURE_TYPE_BATCH_UPPERCASE, METHOD_THAW, METHOD_EXPORT]
  return allowRemainMethods.includes(selectedMethod.value) && !!getSpecimensRemaining.value.length
})
const defaultDestinationValue = computed(() => {
  const defaultCryorobot = destinationOptions.value.find((cryorobot) => cryorobot.defaultRobot)
  return selectedDestinationBatch.value || (!isBatchTicket.value && defaultCryorobot?.value) || ''
})

const { setBeaconBarcode } = useActions('beaconsModule')
const { createTickets } = useActions('newTicketModule')
const { fetchEmbryoTypes } = useActions('specimensModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const { getExternalClinics } = useActions('patientsModule')
const { setSelectedTickets } = useActions('selectedTicketsModule')
const { applyCryoDateToCryoDevice } = useActions('ticketModule')
const filterBatchCryodevices = (ticket) => {
  return {
    ...ticket,
    cryoDevice: isBatchTicket.value
      ? ticket.cryoDevice.filter(({ inSelectedTicket }) => inSelectedTicket)
      : ticket.cryoDevice
  }
}
const updateSpecimenTypeWhenExport = () => {
  const isExport =
    ticketsToPost.value[0].procedureType &&
    ticketsToPost.value[0].procedureType.includes(METHOD_EXPORT_UPPER)

  if (isExport) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      specimenType,
      ...restOfTicketsToPost
    } = ticketsToPost.value[0]

    return restOfTicketsToPost
  }

  return ticketsToPost.value[0]
}
const updateCryodateWhenImport = () => {
  const isImport =
    ticketsToPost.value[0].procedureType &&
    ticketsToPost.value[0].procedureType.includes(PROCEDURE_TYPE_SINGLE_IMPORT)

  if (isImport) {
    const cryoDevice = ticketsToPost.value[0].cryoDevice.map((cryodevice) => ({
      ...cryodevice,
      cryoDate: dayjs.utc(cryodevice.cryoDate).valueOf()
    }))

    return { ...ticketsToPost.value[0], cryoDevice }
  }

  return ticketsToPost.value[0]
}
const getTicketLocationsAfterCreation = () => {
  ticketsToDisplay.value = ticketsToDisplay.value.map((ticket) => {
    const newData = newTicketsData.value.find(
      (finalTicket) => finalTicket.beaconBarcode === ticket.cryoDevice[0].beaconBarcode
    )
    return { ...ticket, robotLocations: newData.robotLocations }
  })
}
const createNewTickets = async (ticketsToCreate) => {
  const response: any = await createTickets(ticketsToCreate)
  ticketIds.value = response.data
  toast.success('Ticket Created Successfully')
  if (ss.getFieldSessionStorage('newBeaconsArray')) {
    ss.removeFieldSessionStorage('newBeaconsArray')
  }

  const ticketData = await getPDFticketsData()
  newTicketsData.value = ticketData
  setSelectedTickets(ticketData)
  if (isBatchTicket.value) {
    getTicketLocationsAfterCreation()
    dynamicPageTitle.value = `Your Batch Ticket has been created - #${ticketIds.value[0].ticketId}`
  } else {
    dynamicPageTitle.value = 'Your ticket has been created!'
  }
}
const createTicket = async () => {
  displaySpinner('Finishing the ticket')

  if (!isBatchTicket.value) {
    ticketsToPost.value = await buildTicketsToPost(true)
    ticketsToPost.value[0] = updateSpecimenTypeWhenExport()
    ticketsToPost.value[0] = updateCryodateWhenImport()

    try {
      await createNewTickets(ticketsToPost.value)
      resetDestination()
      ticketHasBeenCreated.value = true
    } catch (err: any) {
      toast.error({ title: err.message })

      if (err.response && err.response.data) {
        err.response.data.forEach((item) => {
          toast.error({ title: item.message })
        })
      }
    } finally {
      hideSpinner()
    }
  } else {
    // BATCH TICKETS
    try {
      noDestinationSelected.value = false
      const procedureDateFromTimestamp = dayjs().utc().valueOf() + 600
      // Compose ticket payload
      const procedureSubType = ss.getFieldSessionStorage('batch', 'procedureSubType') || ''
      const children = ss.getFieldSessionStorage('batch', 'childrens')
      const cpId = ss.getFieldSessionStorage('batch', 'cpId')
      const userId = ss.getFieldSessionStorage('batch', 'userId')
      const composedTicket: any = [
        {
          eventId: 0,
          procedureType: PROCEDURE_TYPE_BATCH_UPPERCASE,
          robotTransfer: procedureSubType,
          userId,
          cpId,
          visit: {
            procedureDateFrom: procedureDateFromTimestamp,
            procedureDateTo: procedureDateTo.value || null
          },
          source: 1,
          children
        }
      ]

      if (selectedDestinationBatch.value) {
        await validateDestination()
        composedTicket[0].unitId = selectedDestinationBatch.value
      } else {
        noDestinationSelected.value = hasCryoRobotSelector.value
        setUnitSpaceValidationFailed(false)
      }
      if (!noDestinationSelected.value && !unitSpaceValidationFailed.value) {
        await createNewTickets(composedTicket)
        ticketHasBeenCreated.value = true
      }
    } catch (err: any) {
      toast.error({ title: err.message })

      if (err.message && err.message === 'NOT_ENOUGH_SPACE') {
        isCustomModalOpen.value = true
      }
    } finally {
      hideSpinner()
    }
  }
  return this
}
const validateDestination = async () => {
  const validationPayload = {
    screeningStatus: beacons.value[0].cryoDevice[0].screeningStatus,
    containerId: selectedDestination.value
  }

  await validateUnitSpace(validationPayload)
}
const getPrinterName = (printerName) => {
  return setPrinterName(printerName)
}
const closePrinterModal = () => {
  isPrinterModalVisible.value = false
}
const showPrinterModal = () => {
  isPrinterModalVisible.value = true
}
const getUpdatedTicketData = ({
  basicData,
  currentPatient,
  currentNewBeaconsArray,
  sharedPatientIds
}) => {
  const isDonateToPatient = donatePatient.value.clinicId && donatePatient.value.siteId

  const donationMetadata = {
    exportReason: {
      type: 'DONATION',
      details: {
        metadata: {
          beaconBarcode: currentNewBeaconsArray,
          patient: donatePatient.value
        },
        subType: DONATE_TO_PATIENT_VALUE
      }
    }
  }

  const metadata = isDonateToPatient ? donationMetadata : basicData.metadata

  if (selectedMethod.value === PROCEDURE_TYPE_BATCH_UPPERCASE) {
    // For normal tickets, we are always using the same user,
    // but for batch, we can have tickets for multiple users
    const updatedBasicData = {
      ...basicData,
      patient: {
        firstName: currentPatient.firstName,
        lastName: currentPatient.lastName,
        identificationNumber: currentPatient.identificationNumber,
        birthDate: currentPatient.birthDate
      },
      sharedPatientIds,
      metadata
    }
    return updatedBasicData
  }
  if (isThawProcedure.value || isExport.value) {
    // For thaw tickets, we use the patient of the cryodevice
    const updatedBasicData = {
      ...basicData,
      globalPatientNumber: currentPatient.globalPatientNumber,
      patient: {
        firstName: currentPatient.firstName,
        lastName: currentPatient.lastName,
        identificationNumber: currentPatient.identificationNumber,
        birthDate: currentPatient.birthDate
      },
      sharedPatientIds,
      metadata
    }
    return updatedBasicData
  }

  return { ...basicData, metadata }
}
const updateBeaconBarcode = ({ basicData, currentNewBeaconsArray, newCryoDevice }) => {
  if (
    basicData.procedureType.toLowerCase() === METHOD_FREEZE_LOWERCASE ||
    basicData.procedureType === SINGLE_IMPORT_CONST
  ) {
    return {
      ...newCryoDevice,
      beaconBarcode: currentNewBeaconsArray
    }
  }
  return newCryoDevice
}
const setSpecimenDetails = (cryoDeviceItem) => {
  if (cryoDeviceItem.specimenType === SPECIMEN_TYPE_EMBRYO) {
    const embryoDetails = {
      embryo: {
        biopsy: cryoDeviceItem.biopsy || cryoDeviceItem.embryo?.biopsy,
        biopsyResult: cryoDeviceItem.biopsyResult || cryoDeviceItem.embryo?.biopsyResult,
        embryoNumber: cryoDeviceItem.embryoNumber || cryoDeviceItem.embryo?.embryoNumber,
        embryoType: cryoDeviceItem.embryoType || cryoDeviceItem.embryo?.embryoType,
        grade: cryoDeviceItem.grade || cryoDeviceItem.embryo?.grade
      }
    }

    const filteredEmbryoDetails = removeKeysWithNullValues(embryoDetails)

    return filteredEmbryoDetails
  }

  const oocyteDetails = {
    oocyte: {
      maturityLevel: cryoDeviceItem?.oocyte?.maturityLevel
    }
  }

  const filteredOocyteDetails = removeKeysWithNullValues(oocyteDetails)

  return filteredOocyteDetails
}
const setSourceDetails = () => {
  if (selectedMethod.value === METHOD_IMPORT) {
    return {
      source: {
        sourceTypeId: '1',
        shippingSourceId: shippingSourceId.value,
        donorId: donorId.value
      }
    }
  }

  return null
}
const setEmbryoTypeLabelFields = ({ additionalSpecimenFields, objectToAPI }) => {
  if (!objectToAPI) {
    const embryoTypeLabel = selectEmbryoTypeLabel(additionalSpecimenFields, embryoTypes.value)

    return embryoTypeLabel
  }

  return null
}
const buildTicketsToPost = async (objectToAPI = false) => {
  try {
    const { userId } = loggedUserInfo.value
    const { collectionProtocolId } = appBaseConfig.value

    const [{ firstName, lastName, identificationNumber, birthDate, globalPatientNumber }] =
      selectedPatient.value

    const procedureType =
      selectedMethod.value === METHOD_IMPORT
        ? SINGLE_IMPORT_CONST
        : selectedMethod.value.toUpperCase()
    const specimenType =
      selectedSpecimenType.value !== null ? getSpecimenType(selectedSpecimenType.value) : null
    const now = dayjs().valueOf()
    const getPocedureDateFrom = procedureDateFrom.value || now
    const getProcedureDateTo = procedureDateTo.value

    let basicData: any = {
      robotTransfer: robotTransfer.value,
      cpId: collectionProtocolId,
      eventId: 0,
      procedureType,
      globalPatientNumber,
      specimenAction: null,
      specimenType,
      userId,
      visit: {
        procedureDateFrom: getPocedureDateFrom,
        procedureDateTo: getProcedureDateTo
      },
      patient: {
        firstName,
        lastName,
        identificationNumber,
        birthDate
      },
      // For every new ticket created in ClinicUI the 'source' must be 1,
      // that means the ticket is not EMR
      source: 1,
      metadata: { ...metadata.value }
    }

    // Single Import procedure type needs to set the procedureDateFrom to now
    // as these tickets do not go over the calendar view
    if (procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
      basicData = {
        ...basicData,
        visit: {
          ...basicData.visit,
          procedureDateFrom: now
        }
      }
    }

    if (sharedPatientIds.value.length) {
      basicData = {
        ...basicData,
        sharedPatientIds: sharedPatientIds.value
      }
    }

    if (beacons.value?.length) {
      const additionalSourceFields = setSourceDetails()
      let currentPatients = selectedPatient.value
      let currentSharedPatients = batchSharedPatientIds.value
      if (isThawProcedure.value || isExport.value) {
        const patientsIds = beacons.value.map(({ cryoDevice: [{ cprId }] }) => cprId)
        currentSharedPatients = beacons.value.map(
          ({ cryoDevice: [{ sharedPatientIds }] }) => sharedPatientIds
        )
        currentPatients = await fetchPatientsById(patientsIds)
      }

      const newBeacons = beacons.value.map((beacon, index) => {
        const newBeaconsArray = ss.getFieldSessionStorage('newBeaconsArray')
          ? JSON.parse(ss.getFieldSessionStorage('newBeaconsArray'))
          : []

        const ticketData = getUpdatedTicketData({
          basicData,
          currentPatient: currentPatients[index],
          currentNewBeaconsArray: newBeaconsArray[index],
          sharedPatientIds: currentSharedPatients[index]
        })
        const unitId = beacon.selectedDestination

        const cryoDevice = beacon.cryoDevice.map((cryoDeviceItem) => {
          const {
            // biopsy,
            // embryoNumber,
            // embryoType,
            // embryo,
            // oocyte,
            // cryodeviceBarcodeValidation,
            freeText,
            metadata,
            ...newCryoDevice
          } = cryoDeviceItem

          const updatedNewCryoDevice = updateBeaconBarcode({
            basicData,
            currentNewBeaconsArray: newBeaconsArray[index],
            newCryoDevice
          })

          const additionalSpecimenFields = setSpecimenDetails(cryoDeviceItem)

          const additionalEmbryoTypeLabelFields = setEmbryoTypeLabelFields({
            additionalSpecimenFields,
            objectToAPI
          })

          const cryodeviceTypeId = selectedCryoDeviceType.value && selectedCryoDeviceType.value.id
          const finalMeta =
            freeText?.length > 0
              ? {
                  ...(metadata || {}),
                  metadata: {
                    ...(metadata?.metadata || {}),
                    cryolabelFreeText: freeText
                  }
                }
              : metadata
          return {
            cryoDate: procedureDateFrom.value,
            ...updatedNewCryoDevice,
            ...additionalSpecimenFields,
            ...additionalSourceFields,
            ...additionalEmbryoTypeLabelFields,
            cryodeviceTypeId,
            metadata: finalMeta
          }
        })

        return {
          cryoDevice,
          ...ticketData,
          unitId,
          robotLocations: beacon.robotLocations,
          inventoryUpdate: beacon.inventoryUpdate
        }
      })
      return newBeacons
    }

    // Scheduled - No Specimens required
    return [
      {
        ...basicData,
        cryoDevice: []
      }
    ]
  } catch (error: any) {
    throw new Error(error)
  }
}
const sendPrintJob = async () => {
  if (selectedPrinter.value === '0') {
    printPDFTicket()
    return
  }
  const activeSelectedPrinter = activePrinters.value.find(
    (printer) => printer.uid === selectedPrinter.value
  )
  if (!activeSelectedPrinter) {
    console.error('Selected printer not found')
    return
  }
  try {
    await printTickets(activeSelectedPrinter, newTicketsData.value)
    closePrinterModal()
  } catch (error) {
    console.error('Error sending print job: ', error)
  }
}
const getPDFticketsData = async () => {
  const ticketData = ticketIds.value[0].children?.length
    ? ticketIds.value[0].children
    : ticketIds.value.map((ticket) => ticket.ticketId)

  const response: any = await getTicketApi({ ticketId: ticketData.join(',') })

  return response.data
}
const printPDFTicket = async () => {
  let params: selectedTicketsParamsType
  switch (selectedMethod.value) {
    case PROCEDURE_TYPE_BATCH_UPPERCASE: {
      const sessionCache = await ss.getFieldSessionStorage('batch', 'cache')
      params = {
        selectedTickets: sortSpecimensByCryodeviceId(sessionCache || []),
        selectedTicketsToPrint: sortSpecimensByCryodeviceId(sessionCache || [])
      }
      break
    }
    default: {
      params = {
        selectedTickets: sortSpecimensByCryodeviceId(ticketsToDisplay.value || []),
        selectedTicketsToPrint: sortSpecimensByCryodeviceId(newTicketsData.value || [])
      }
      break
    }
  }

  selectedTicketToPdf(params)
  closePrinterModal()
}
const onChangeTicketToView = (page) => {
  // need it for reprint label on batch ticket
  ss.setFieldSessionStorage('activeSlideSelectedTickets', page, 'process')
  currentActivePage.value = page
}
const getSpecimenType = (specimenType) => {
  if (!specimenType || selectedMethod.value === PROCEDURE_TYPE_BATCH_UPPERCASE) return null

  if (specimenType === SPECIMEN_TYPE_EGGS || specimenType === SPECIMEN_TYPE_OOCYTE) {
    return SPECIMEN_TYPE_EGG
  }

  return specimenType.toUpperCase()
}

const hideCustomizableModal = () => {
  isCustomModalOpen.value = false
}
const handleSelectDestination = (value) => {
  onSelectDestination(value)
  noDestinationSelected.value = false
}

onMounted(async () => {
  try {
    dynamicPageTitle.value = 'Please confirm that everything is correct and press Create Ticket'
    let patientIds = []

    await applyCryoDateToCryoDevice()
    await fetchEmbryoTypes()
    externalClinics.value = (await getExternalClinics()) as any[]

    if (patients.value?.length) {
      patientIds = patients.value.map((patient) => patient.globalPatientNumber)
      patientIds = patientIds.filter((id) => id !== primaryPatientId.value)
      sharedPatientIds.value = patientIds
    }
    ticketsToDisplay.value = await buildTicketsToPost()
  } catch (err: any) {
    toast.error({ title: err.message })
  } finally {
    isLoading.value = false
  }

  if (isBatchTicket.value) {
    getDestinationOptions({ showDefault: false })
    resetDestination()
  }
  // Browser Print Setup
  const localPrinters = await getInstalledPrinters()
  localPrinters.printer?.forEach((printer) => {
    ZEBRA_TICKET_PRINTERS_ID.forEach((printerId) => {
      if (printer.name.includes(printerId)) {
        activePrinters.value.push(printer)
        isPrinterActive.value = true
        selectedPrinter.value = printer.uid
      }
    })
  })
  fromRouteName.value = fromRoute?.name
})

onBeforeUnmount(() => {
  ss.removeFieldSessionStorage('newBeaconsArray')
  ss.removeFieldSessionStorage('lastScannedBeacon')
  setBeaconBarcode(null)
})
</script>
