<template>
  <div
    class="z-20 modal-view flex items-center justify-center fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50"
    data-test="modal-print-container"
  >
    <div class="bg-white p-6 w-3/12 rounded-md text-black">
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <DynamicTitle titleType="h3-blue" floatTo="left">Select Printer</DynamicTitle>
        </template>
        <template v-slot:right-side>
          <ButtonDynamic
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="handleClose"
          />
        </template>
      </ActionBar>
      <div class="mt-8">
        <div>
          <select v-model="localSelectedPrinter" class="mb-8">
            <option value="pdf">Print PDF</option>
            <option
              v-for="(printer, index) in props.activePrinters"
              :key="index"
              :value="printer.uid"
            >
              {{ getPrinterName(printer.name) }}
            </option>
          </select>
        </div>
        <ButtonDynamic
          btnText="Print"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="print"
          floatTo="right"
          @click="sendPrintJob"
          :isLoading="props.isPrinterLoading"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

import { ref, watch } from 'vue'

import { sortSpecimensByCryodeviceId } from '@/utils'
import { selectedTicketToPdf } from '@/helpers/printPDF/helpers'
import { printTickets, setPrinterName } from '@/modules/printer'

import useActions from '@/composables/useActions'

const props = defineProps({
  selectedTickets: {
    type: Array,
    default: () => [],
    required: false
  },
  ticketsToPrint: {
    type: Array,
    default: () => [],
    required: false
  },
  activePrinters: {
    type: Array,
    default: () => [],
    required: false
  },
  selectedPrinter: {
    type: String,
    default: 'pdf',
    required: false
  },
  isPrinterLoading: {
    type: Boolean,
    default: true,
    required: false
  }
})

const { getExternalClinics } = useActions('patientsModule')
const localSelectedPrinter = ref(props.selectedPrinter)

const handleClose = () => {
  emit('closePrinterModal')
}

const emit = defineEmits(['closePrinterModal'])

const getPrinterName = (printerName) => {
  return setPrinterName(printerName)
}

const sendPrintJob = () => {
  if (localSelectedPrinter.value !== 'pdf') {
    props.activePrinters.forEach((printer) => {
      if (printer.uid === localSelectedPrinter.value) {
        const selectedTicketsToPrint = sortSpecimensByCryodeviceId(props.selectedTickets || [])
        printTickets(printer, selectedTicketsToPrint)
      }
    })
  } else {
    printPDFTicket()
  }
  handleClose()
}

const printPDFTicket = async () => {
  const externalClinics = await getExternalClinics()
  const params = {
    selectedTickets: sortSpecimensByCryodeviceId(props.selectedTickets || []),
    selectedTicketsToPrint: sortSpecimensByCryodeviceId(props.ticketsToPrint || []),
    externalClinics
  }
  return selectedTicketToPdf(params)
}

watch(
  () => props.activePrinters,
  (newVal) => {
    if (newVal?.length) {
      localSelectedPrinter.value = newVal[0].uid
    }
  },
  { immediate: true, deep: true }
)
</script>
