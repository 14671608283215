<template>
  <div
    data-test="alert-modal"
    :class="[
      'alert-modal flex items-center fixed justify-center',
      'top-0 left-0 w-full h-screen bg-black bg-opacity-50',
    ]"
    v-if="showModal">
    <div class="bg-white rounded-md text-tmrw-blue p-5 w-1/2">
      <div class="flex items-center justify-between mb-4">
        <h3 data-test="alert-modal-title"  v-if="title" class="text-3xl font-medium pr-6 text-tmrw-blue-dark">{{title}}</h3>
        <i
          @click="handleCloseModal"
          class="far fa-times-circle fa-lg cursor-pointer ml-auto"
        />
      </div>
      <div class="mb-4">
        <p data-test="alert-modal-message" class="text-xl font-medium pr-6">{{message}}</p>
        <div class="inline-block" v-if="icon">
          <i :class="`fas ${icon}`" />
        </div>
      </div>
      <div class="flex justify-end">
        <button-dynamic
          :btnText="confirmLabel || 'Ok'"
          btnType="button"
          btnStyle="primary"
          :addClasses="['text-lg', 'inline-block']"
          :btnStyleClasses="['warning', 'bold']"
          :customStyles="'clear: both;'"
          :show-icon="showIcon"
          :fontAwesomeIconClass="fontAwesomeIconClass"
          @click="handleCloseModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

export default {
  name: 'alert-modal',
  components: {
    ButtonDynamic
  },
  props: ['showModal', 'message', 'icon', 'confirmLabel', 'title', 'showIcon', 'fontAwesomeIconClass'],
  emits: ['handleCloseModal'],
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal')
    }
  }
}
</script>
