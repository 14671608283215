import { httpRequest } from '@/helpers/apiHelpers'
import { GetSiteInfoApi, GetAllSites, DefaultCryoRobotParams } from './types'

export const getSiteInfoApi = ({ siteId, clinicId, token }: GetSiteInfoApi) =>
  httpRequest(false).get(`/clinics/${clinicId}/sites/${siteId}`, {
    headers: {
      'x-access-token': token
    }
  })

export const getAllSites = ({ clinicId, token }: GetAllSites) =>
  httpRequest(false).get(`/clinics/${clinicId}/sites`, {
    headers: {
      'x-access-token': token
    }
  })

export const getAllContainers = ({
  clinicId,
  siteId,
  token,
  includeTemporary = false,
  includeNonTmrw = false,
  bySite = true
}: GetSiteInfoApi & { includeTemporary: boolean; includeNonTmrw: boolean; bySite: boolean }) => {
  const urlParams: string[] = []

  urlParams.push(`includeTemporary=${includeTemporary ? 'true' : 'false'}`)
  urlParams.push(`includeNonTmrw=${includeNonTmrw ? 'true' : 'false'}`)
  urlParams.push(`bySite=${bySite ? 'true' : 'false'}`)

  return httpRequest(false).get(
    `/clinics/${clinicId}/sites/${siteId}/containers?${urlParams.join('&')}`,
    {
      headers: {
        'x-access-token': token
      }
    }
  )
}

export const saveDefaultCryorobot = ({
  clinicId,
  siteId,
  container,
  token
}: DefaultCryoRobotParams) =>
  httpRequest(false).patch(`/clinics/${clinicId}/sites/${siteId}/containers/${container}/default`, {
    headers: {
      'x-access-token': token
    }
  })

  