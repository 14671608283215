import { httpRequest } from '@/helpers/apiHelpers'
import { GetShipmentPathParams, Shipment, ShipmentResponse, UpdateShipmentBody } from './types'
import { getGetShipment200Response } from '@/mocks/shipmentHandlers'

export const fetchShipmentById = ({ shipmentId }: GetShipmentPathParams) =>
  httpRequest().get<Shipment>(`/shipments/${shipmentId}`)

export const updateShipment = ({ shipmentId, retrieveState }: UpdateShipmentBody) =>
  httpRequest().patch<number>(`/shipments/${shipmentId}`, {
    retrieveState
  })

export const fakeFetchShipmentById = ({
  shipmentId
}: GetShipmentPathParams): Promise<ShipmentResponse> => {
  // TODO CRYOSHIPPER: Remove mock
  // eslint-disable-next-line no-console
  console.log('Mock Shipment...', shipmentId)
  const data = getGetShipment200Response(shipmentId) as Shipment
  return new Promise((resolve) =>
    resolve({
      data
    })
  )
}

export type MockUpdateResponse = {
  data: number
}

export const fakeUpdateShipment = (payload: UpdateShipmentBody): Promise<MockUpdateResponse> => {
  // eslint-disable-next-line no-console
  console.log('Updating cryoshipper ticket with payload', payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: payload.shipmentId
      })
    }, 500)
  })
}
