<template>
  <div data-test="scan-field" class="scan-field flex flex-col w-full">
    <!-- Show mode -->
    <button-dynamic
      v-if="!value && mode === SCAN_MODE_SHOW"
      data-test="scan-field__show-button"
      :btnText="label"
      btnType="button"
      btnStyle="primary"
      showIcon
      fontAwesomeIconClass="qrcode"
      @click="handleOpenModal"
    />
    <!-- End Show mode -->
    <!-- Scan Mode -->
    <button-dynamic
      v-if="mode === SCAN_MODE_SCAN && !scannedValue"
      data-test="scan-field__scan-button"
      :btnText="label"
      btnType="button"
      btnStyle="primary"
      showIcon
      fontAwesomeIconClass="qrcode"
      @click="handleOpenModal"
    />
    <!-- End Scan Mode -->
    <!-- Scan OK -->
    <div
      class="font-inconsolata font-semibold pt-2 flex items-center jusutify-start"
      v-if="scannedValue && scannedValue === value"
      data-test="scan-field__show-label"
    >
      <i class="fas fa-check-circle fa-lg text-tmrw-green mr-2" /> {{ value }}
    </div>
    <!-- End Scan OK -->
    <!-- Scan ERROR -->
    <button-dynamic
      v-if="scannedValue && scannedValue !== value"
      :btnText="scannedValue !== value ? 'Scan again' : scannedValue"
      btnType="button"
      btnStyle="error-button"
      data-test="scan-field__error-button"
      showIcon
      fontAwesomeIconClass="exclamation-circle"
      @click="handleOpenModal"
    />
    <!-- End Scan ERROR -->
    <slot />
    <!-- Modal Open -->
    <div
      v-if="scanFieldModal"
      data-test="scan-field__modal"
      class="
        bg-black bg-opacity-50
        rounded-md
        flex
        items-center
        justify-center
        w-screen
        h-screen
        fixed
        top-0
        left-0
      "
    >
      <div
        data-test="scan-field__modal-wrapper"
        class="p-4 bg-white rounded-md w-4/12"
        @keydown.esc="handleCancel"
        @keydown.enter="handleScanValidation"
      >
        <ActionBar data-test="scan-field__action-bar" colsDistribution="10/2">
          <template v-slot:left-side>
            <dynamic-title titleType="h3-blue" floatTo="left">{{ modalTitle }}</dynamic-title>
          </template>
          <template v-slot:right-side>
            <button-dynamic
              btnText=""
              btnType="button"
              btnStyle="close-button-blue"
              data-test="scan-field__close-button"
              showIcon
              fontAwesomeIconClass="times-circle"
              @click="closeScanFieldModal"
            />
          </template>
        </ActionBar>
        <input-field
          :inputPlaceholder="placeholder"
          inputName="codeType"
          placeholderColor="black"
          labelColor="tmrw-blue"
          borderColor="tmrw-blue"
          ref="temporaryValue"
          v-model="temporaryValue"
          addPaddingTop
          addPaddingBottom
          addMarginTop
          addMarginBottom
        />
        <p
          v-if="errorMessage"
          :class="[
            errorMessage ? 'opacity-100' : 'opacity-0',
            'color-tmrw-warning text-base text-left flex py-4',
          ]"
        >
          <span
            class="
              relative
              flex
              bg-white
              rounded-full
              w-4
              h-4
              justify-center
              items-center
              float-left
              mr-2
            "
          >
            <i class="text-sm fas fa-exclamation-circle text-tmrw-error" />
          </span>
          <span class="scan-field-retrieving-message" role="alert">{{ errorMessage }}</span>
        </p>
        <div class="scan-field__modal__actions mt-4">
          <button-dynamic
            btnType="button"
            btnText="Cancel"
            btnStyle="transparent-blue"
            :addClasses="['close-scan-field-modal-btn']"
            addMarginRight
            @click="handleCancel"
          />
          <button-dynamic
            data-test="scan-field__modal-confirm-button"
            btnDataTest="scan-field__modal-confirm-button"
            :isLoading="isLoading"
            btnText="Confirm"
            btnStyle="primary"
            :addClasses="['submit-scan-btn']"
            :isDisabled="v$.$invalid || !temporaryValue"
            @click="handleScanValidation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, alphaNum } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import InputField from '@/components/InputField/InputField.vue'
import { SCAN, SCAN_MODE_SCAN, SCAN_MODE_SHOW } from '@/constants'

const spaceRestrictions = (value) => value.match(/^ *$/) === null

export default {
  name: 'scan-field',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      scanFieldModal: false,
      temporaryValue: ''
    }
  },
  validations: {
    temporaryValue: {
      required,
      spaceRestrictions,
      alphaNum
    }
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: SCAN
    },
    modalTitle: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: SCAN_MODE_SHOW,
      validator: (value) => [SCAN_MODE_SHOW, SCAN_MODE_SCAN].includes(value)
    },
    indexKey: {
      type: Number,
      required: false
    },
    isForceModalClose: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    onCancel: {
      type: Function,
      required: false,
      default: () => null
    },
    onScan: {
      type: Function,
      required: false,
      default: () => null
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    scannedValue: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  created() {
    this.SCAN = SCAN
    this.SCAN_MODE_SCAN = SCAN_MODE_SCAN
    this.SCAN_MODE_SHOW = SCAN_MODE_SHOW
  },
  methods: {
    handleOpenModal() {
      this.scanFieldModal = true
      setTimeout(() => {
        // TODO: configure eslint to not throw errors on optional chaining
        /* eslint-disable-next-line */
        this.$refs.temporaryValue?.$el?.children[0].children[0].focus()

      }, 0)
    },
    closeScanFieldModal() {
      this.scanFieldModal = false
      this.temporaryValue = ''
    },
    handleCancel() {
      this.closeScanFieldModal()
      this.onCancel()
    },
    handleScanValidation() {
      const { temporaryValue, cryodevice } = this
      this.onScan({ temporaryValue, cryodevice })
    }
  },
  watch: {
    isForceModalClose(newVal) {
      if (newVal) {
        this.closeScanFieldModal()
      }
    }
  },
  components: {
    ButtonDynamic,
    InputField,
    DynamicTitle,
    ActionBar
  }
}
</script>

<style lang="scss" scoped>
.scan-field {
  &__reprint {
    margin-top: 5px;
    text-align: right;
    > button {
      @apply text-white;
      background: none !important;
      border: none;
      cursor: pointer;
      font-size: 13px;
      padding: 0 !important;
    }
  }

  .btn {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;
    max-width: 100%;
    padding: 13px 10px;
  }

  .btn-alt {
    padding: 7px 10px !important; // Magic. Do not touch.
  }

  &__scan-icon {
    height: 24px;
    margin-right: 7.5px;
    width: auto;
  }

  &__error-icon {
    height: 22px;
    margin-left: 5px;
    width: auto;
  }

  &__btn {
    box-shadow: none;
    width: 262.5px;

    &__label {
      display: flex;
      max-width: 100%;
      justify-content: center;
      align-items: center;

      > span {
        max-width: 82%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
      }

      &__image--error {
        height: 20px;
        width: auto;
      }
    }

    &__image {
      height: auto;
      left: 14px;
      margin: 0;
      position: absolute;
      top: -3px;
      width: 32px;
    }

    &__icon {
      font-size: 16px;
      line-height: 1.5;
      margin-left: 5px;
    }

    &__wrapper {
      &__image {
        height: 9.7px;
        margin: 0;
        object-fit: contain;
        width: 12.3px;
      }
    }
  }

  &__modal {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__device {
      display: flex;
      flex-flow: column;

      &:last-child {
        margin-bottom: 6px;
      }
    }

    &__actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

  }
}
</style>
