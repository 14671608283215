import pdfMake from 'pdfmake/build/pdfmake'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as ss from '@/config/session-storage-help'
import {
  CRYOBEACON,
  INVENTORY_ITEM,
  INVENTORY_ITEM_SINGULAR,
  PROCEDURE_EMBRYO_SINGLE_IMPORT,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  PROCEDURE_EGG_SINGLE_IMPORT,
  PROCEDURE_EGG_FREEZE,
  PROCEDURE_EGG_THAW,
  PROCEDURE_EMBRYO_FREEZE,
  PROCEDURE_EMBRYO_THAW,
  PROCEDURE_EGG_MOVE,
  PROCEDURE_EMBRYO_MOVE,
  PROCEDURE_EGG_REPLACE_BEACON,
  PROCEDURE_EMBRYO_REPLACE_BEACON,
  PROCEDURE_EXPORT_CRYODEVICES,
  PROCEDURE_DONATION_TO_PATIENT,
  PROCEDURE_MISSING_CRYOBEACON,
  PROCEDURE_TYPE_BEACON_MOVE,
  UNASSIGNED
} from '@/constants/index'
import {
  selectedTicketsParamsType,
  ticketType,
  externalsClinicsType,
  selectedTicketSpecimensHeadersType,
  staticTypes,
  mappingSpecimensType,
  specimenDataType
} from '@/helpers/printPDF/types/index'

import { VFS } from '@/assets/pdf/vfs_fonts'
import ErrorHandler from '@/helpers/ErrorHandler'
import {
  embryoHeaders,
  embryoSingleImportHeaders,
  embryoDataHeaders,
  embryoSingleImportDataHeaders,
  oocyteHeaders,
  oocyteDataHeaders,
  oocyteSingleImportHeaders,
  oocyteSingleImportDataHeaders,
  TMRWlogo,
  ticketHeaderDescription,
  clinicHeaderDescription,
  infectionScreeningAndShippingSourceHeader,
  embryosRemainingInCryoBeacon,
  oocytesRemainingInCryoBeacon,
  remainingInCryobeconText
} from '../static/index'
import store from '@/store'

dayjs.extend(utc)
pdfMake.vfs = VFS

pdfMake.fonts = {
  Futura: {
    normal: 'Inconsolata-Regular.ttf',
    bold: 'Inconsolata-Bold.ttf'
  }
}

/**
 * generating pdf content
 * @param ticketContent
 * @returns object[]
 */

const pdfContent = (ticketContent: {}[]) => ({
  pageOrientation: 'landscape',
  pageSize: 'Letter',
  pageMargins: [20, 20, 20, 20],
  content: [ticketContent],
  defaultStyle: {
    font: 'Futura'
  },
  styles: {
    tableHeader: {
      fontSize: 10,
      color: '#5a5b5e'
    },
    tableTmrw: {
      fontSize: 13,
      color: '#5a5b5e',
      padding: [10, 10, 10, 10]
    },
    footerStyles: {
      fontSize: 10
    },
    tableExample: {
      padding: [10, 10, 10, 10]
    }
  }
})

/**
 * validate beacon ID
 * @param cryoBeaconId string
 * @returns string
 */
const showIfValidCryoBeaconId = (cryoBeaconId: string) => (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(cryoBeaconId)
  ? UNASSIGNED
  : cryoBeaconId)

/**
 * generating cryobeacon text
 * @param ticket
 * @param params
 * @returns string
 */

const cryoBeaconText = (ticket: ticketType) => {
  const {
    specimens,
    specimens: [{ beaconBarcode }]
  } = ticket
  const inventoryItemType = specimens.length > 1 ? INVENTORY_ITEM : INVENTORY_ITEM_SINGULAR
  const cryoBeaconId = showIfValidCryoBeaconId(beaconBarcode)
  return `${CRYOBEACON} ID ${cryoBeaconId} | ${specimens.length} ${inventoryItemType}`
}

/**
 * generating shippingSource text
 * @param shippingSourceId Array
 * @param externalClinics Array
 * @returns string
 */

const shippingSourceText = (
  sources: { shippingSourceId: string }[],
  externalClinics: externalsClinicsType
) => {
  switch (sources) {
    case undefined:
      return ''
    default: {
      const [shippingId] = externalClinics.filter(({ id }) => id === Number(sources[0].shippingSourceId))
      return `Shipping Source: ${shippingId.name || '--'}`
    }
  }
}

/**
 * generating headers by procedure
 * @param procedure string
 * @param specimens Array
 * @returns string
 */

const getHeaderByTicketProcedure = ({
  procedure,
  specimens: [{ specimenType }]
}: {
  procedure: string
  specimens: { specimenType: string }[]
}) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (!procedure && !specimenType) throw procedure
    switch (procedure) {
      case PROCEDURE_EMBRYO_SINGLE_IMPORT:
      case PROCEDURE_EGG_SINGLE_IMPORT:
        return specimenType === SPECIMEN_TYPE_EMBRYO
          ? embryoSingleImportHeaders()
          : oocyteSingleImportHeaders()
      case PROCEDURE_EGG_FREEZE:
      case PROCEDURE_EGG_THAW:
      case PROCEDURE_EMBRYO_FREEZE:
      case PROCEDURE_EMBRYO_THAW:
      case PROCEDURE_EGG_MOVE:
      case PROCEDURE_EMBRYO_MOVE:
      case PROCEDURE_EGG_REPLACE_BEACON:
      case PROCEDURE_EMBRYO_REPLACE_BEACON:
      case PROCEDURE_EXPORT_CRYODEVICES:
      case PROCEDURE_DONATION_TO_PATIENT:
      case PROCEDURE_MISSING_CRYOBEACON:
      case PROCEDURE_TYPE_BEACON_MOVE:
        return specimenType === SPECIMEN_TYPE_EMBRYO ? embryoHeaders() : oocyteHeaders()
      default:
        return [
          {
            text: 'Procedure Not Supported'
          },
          ''
        ]
    }
  } catch (error) {
    throw error
  }
}

/**
 * generating specimens headers
 * @param ticketParams
 * @returns object[]
 */

const getDataByTicketProcedure = (ticketParams: selectedTicketSpecimensHeadersType) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const {
      specimenType,
      ticket: { procedure }
    } = ticketParams
    switch (procedure) {
      case PROCEDURE_EMBRYO_SINGLE_IMPORT:
      case PROCEDURE_EGG_SINGLE_IMPORT:
        return specimenType === SPECIMEN_TYPE_EMBRYO
          ? embryoSingleImportDataHeaders(ticketParams)
          : oocyteSingleImportDataHeaders(ticketParams)
      case PROCEDURE_EGG_FREEZE:
      case PROCEDURE_EGG_THAW:
      case PROCEDURE_EMBRYO_FREEZE:
      case PROCEDURE_EMBRYO_THAW:
      case PROCEDURE_EGG_MOVE:
      case PROCEDURE_EMBRYO_MOVE:
      case PROCEDURE_EGG_REPLACE_BEACON:
      case PROCEDURE_EMBRYO_REPLACE_BEACON:
      case PROCEDURE_EXPORT_CRYODEVICES:
      case PROCEDURE_DONATION_TO_PATIENT:
      case PROCEDURE_MISSING_CRYOBEACON:
      case PROCEDURE_TYPE_BEACON_MOVE:
        return specimenType === SPECIMEN_TYPE_EMBRYO ? embryoDataHeaders(ticketParams) : oocyteDataHeaders(ticketParams)
      default:
        return [
          {
            text: 'Procedure Not Supported'
          },
          ''
        ]
    }
  } catch (error) {
    throw error
  }
}

const textElipsis = (text: string, length: number) => {
  switch (text?.length > length) {
    case true:
      return `${text.substring(0, length)}...`
    default:
      return text
  }
}

/**
 *
 * @param tableParams
 * @returns
 */

const mappingSpecimens = ({
  specimens,
  embryoTypes,
  embryoLabelType,
  ticket,
  ticketData,
  reference
}: mappingSpecimensType) => {
  let specimensData: specimenDataType

  // mapping the specimens
  for (let i = 0; i < specimens.length; i++) {
    const cryodeviceId = [
      specimens[i].cryodeviceBarcode.slice(0, 8),
      ' ',
      specimens[i].cryodeviceBarcode.slice(8)
    ].join('')
    const cryoDateMilisValue = Number(specimens[i].cryoDate)

    // assign it embryo label type
    embryoTypes.map((embryoType) => {
      if (
        specimens[i].embryo
        && specimens[i].embryo.embryoType
        && typeof specimens[i].embryo.embryoType === 'number'
        && embryoType.id === specimens[i].embryo.embryoType
      ) {
        const { name } = embryoType
        Object.assign(embryoLabelType, { ...embryoLabelType, name })
        return embryoLabelType
      }
      return false
    })

    // specimens headers params object
    const ticketParams = {
      specimenType: specimens[i].specimenType,
      cryodeviceId,
      ticket,
      specimenNumber: i,
      embryoLabelType,
      cryoDateMilisValue
    }

    // generating the specimens headers data
    specimensData = getDataByTicketProcedure(ticketParams)

    // clean up previous data
    if (i === 0) {
      Object.assign(ticketData, {
        ...ticketData,
        [reference]: []
      })
    }

    // attaching the specimens headers
    Object.assign(ticketData, {
      ...ticketData,
      [reference]: [...ticketData[reference], specimensData]
    })
  }
  return ticketData
}

/**
 * generating table content
 * @param tableParams
 * @returns object[]
 */

const selectedTicketContentTable = (tableParams: {
  ticket: ticketType
  config: staticTypes['config']
  params: selectedTicketsParamsType
  externalClinics?: externalsClinicsType
}) => [
  {
    table: {
      widths: [362.5, 362.5],
      body: [
        TMRWlogo(),
        [
          // global headers
          ticketHeaderDescription(tableParams),
          clinicHeaderDescription(tableParams)
        ],
        infectionScreeningAndShippingSourceHeader(tableParams),
        getHeaderByTicketProcedure(tableParams?.ticket)
      ]
    },
    layout: {
      defaultBorder: false
    }
  }
]

/**
 * generating pdf content
 * @param params
 * @returns object[]
 */
const selectedTicketToPdf = async (params: selectedTicketsParamsType) => {
  try {
    // getting static data
    const config: staticTypes['config'] = ss.getFieldSessionStorage('config', null)
    const embryoTypes: staticTypes['embryoTypes'] = store.getters['specimensModule/embryoTypes']

    // setting up static data
    const ticketData = {
      content: [],
      specimens: [],
      embryosRemaining: [],
      oocytesRemaining: []
    }
    const ticketCount: number = params.selectedTickets.length
    let count = 0
    const embryoLabelType: { name: string } = { name: '' }

    // iterating on each ticket
    params.selectedTicketsToPrint.forEach((ticket: ticketType) => {
      // generating the table params
      const contentTableParams = {
        ticket,
        config,
        params
      }

      // generating the table content
      const tableContent = selectedTicketContentTable(contentTableParams)

      // attaching the table data
      Object.assign(ticketData, {
        ...ticketData,
        content: [...ticketData.content, ...tableContent]
      })
      // adding one to the local ticket count this is used in order to keep track of the amount of tickets that has been already reviewed
      count++

      // filter by inSelectedTicket attribute when EXPORT procedure
      const specimensOnTicket = ticket.specimens.filter((specimen) => specimen.inSelectedTicket)

      // mapping the specimens
      for (let i = 0; i < specimensOnTicket.length; i++) {
        const cryodeviceId = specimensOnTicket[i].cryodeviceBarcode
        const cryoDateMilisValue = Number(specimensOnTicket[i].cryoDate)

        // assign it embryo label type
        embryoTypes.map((embryoType) => {
          if (
            specimensOnTicket[i].embryo
            // @ts-ignore
            && specimensOnTicket[i].embryo.embryoType
            // @ts-ignore
            && typeof specimensOnTicket[i].embryo.embryoType === 'number'
            // @ts-ignore
            && embryoType.id === specimensOnTicket[i].embryo.embryoType
          ) {
            const { name } = embryoType
            Object.assign(embryoLabelType, { ...embryoLabelType, name })
            return embryoLabelType
          }
          return false
        })

        // specimens headers params object
        const ticketParams: any = {
          specimenType: specimensOnTicket[i].specimenType,
          cryodeviceId,
          ticket,
          specimenNumber: i,
          embryoLabelType,
          cryoDateMilisValue
        }

        // generating the specimens headers data
        const selectedSpecimensHeaders = getDataByTicketProcedure(ticketParams)

        // attaching the specimens headers
        Object.assign(ticketData, {
          ...ticketData,
          specimens: [...ticketData.specimens, selectedSpecimensHeaders]
        })
      }

      // remaining in beacon
      const specimensRemaining = ticket.specimens.filter(
        ({ inSelectedTicket }) => !inSelectedTicket
      )

      const remainingEmbryos = specimensRemaining.filter(
        ({ specimenType }) => specimenType === SPECIMEN_TYPE_EMBRYO
      )

      const remainingOocytes = specimensRemaining.filter(
        ({ specimenType }) => specimenType === SPECIMEN_TYPE_EGG
      )

      mappingSpecimens({
        // @ts-expect-error
        specimens: remainingEmbryos,
        embryoTypes,
        embryoLabelType,
        ticket,
        ticketData,
        reference: 'embryosRemaining'
      })

      mappingSpecimens({
        // @ts-expect-error
        specimens: remainingOocytes,
        embryoTypes,
        embryoLabelType,
        ticket,
        ticketData,
        reference: 'oocytesRemaining'
      })

      const remainingInCryoBeaconText = remainingEmbryos.length > 0 || remainingOocytes.length > 0 ? remainingInCryobeconText() : ''

      const embryosRemaining = remainingEmbryos.length > 0
        ? [embryosRemainingInCryoBeacon(), embryoHeaders(0), ...ticketData.embryosRemaining]
        : ''

      const oocytesRemaining = remainingOocytes.length > 0
        ? [oocytesRemainingInCryoBeacon(), oocyteHeaders(0), ...ticketData.oocytesRemaining]
        : ''

      // attaching specimens to content
      Object.assign(ticketData, {
        ...ticketData,
        content: [
          ...ticketData.content,
          ...ticketData.specimens,
          ...remainingInCryoBeaconText,
          ...embryosRemaining,
          ...oocytesRemaining
        ]
      })

      // getting ticket count
      if (count < ticketCount) {
        // attaching pagination & new specimens headers
        Object.assign(ticketData, {
          ...ticketData,
          content: [
            ...ticketData.content,
            {
              text: '',
              pageBreak: 'after'
            }
          ],
          specimens: []
        })
      }
    })

    // generating the pdf content
    const content = pdfContent(ticketData.content)
    // returning the pdf
    return pdfMake.createPdf(content).open()
  } catch (error) {
    throw ErrorHandler('selectedTicketToPdf', `\n \n ${error})`)
  }
}

export {
  pdfContent,
  showIfValidCryoBeaconId,
  cryoBeaconText,
  shippingSourceText,
  getHeaderByTicketProcedure,
  getDataByTicketProcedure,
  textElipsis,
  selectedTicketContentTable,
  selectedTicketToPdf
}
