<template>
  <section class="ticket-table">
    <!-- Header specimen list table -->
    <div
      class="ticket-table__header bg-gradient-to-r from-tmrw-green-light to-transparent flex justify-between mt-4 py-2 px-4 text-tmrw-blue-dark"
      v-if="isTicketDataVisible"
    >
      <section class="flex justify-start w-8/12">
        <div class="ticket-table__title flex flex-col mr-6" v-if="robotLocations?.length">
          <p data-test="current-location-title" class="text-xs font-semibold">CURRENT LOCATION</p>
          <div class="flex items-center mt-1 leading-3">
            <img data-test="robotTransferImageIn" :src="robotTransferImage()" class="w-14" />
            <p
              data-test="curent-location-type"
              class="ml-2 font-bold text-lg font-inconsolata leading-5"
            >
              {{ cryorobotTitle }}<br /><span class="text-sm">{{
                ticket.metadata.locationDetails || ''
              }}</span>
            </p>
          </div>
        </div>
        <!-- Cryodevice Amount / 2 Cryodevices -->
        <div class="flex">
          <div
            v-if="beaconId"
            data-test="ticket-list-beaconID"
            class="ticket-table__beacon-id flex flex-col pr-8"
          >
            <p data-test="cryobeacon-id-title" class="text-xs font-semibold">
              {{ CryoBeacon.toUpperCase() }} ID
            </p>
            <section>
              <span
                data-test="cryobeacon-id"
                class="mt-1 font-bold text-xl font-inconsolata leading-7"
                >{{ showIfValidCryoBeaconId(beaconId) }}</span
              >
              <span
                v-if="isDonateToPatient && newBeaconsArray"
                class="mt-2 font-bold text-xl font-inconsolata leading-7"
                >-> {{ newBeaconsArray[selectedTicketIndex] }}</span
              >
            </section>
          </div>
          <div
            v-if="isSourceLocationSet"
            class="ticket-table__beacon-id flex flex-col mr-5"
            data-test="ticket-list-current-location"
          >
            <p class="text-xs font-semibold">CURRENT LOCATION</p>
            <p class="mt-1 font-bold text-xl font-inconsolata leading-7">
              {{ sourceLocation.robotName }}
            </p>
          </div>
          <div
            v-if="isDestinationSet"
            class="ticket-table__beacon-id flex flex-col"
            data-test="ticket-list-destination"
          >
            <p class="text-xs font-semibold">DESTINATION</p>
            <p class="mt-1 font-bold text-xl font-inconsolata leading-7">
              {{ destinationLocation.robotName }}
            </p>
          </div>
          <div
            v-if="screeningStatus"
            data-test="ticket-list-screening-status"
            class="ticket-table__beacon-id flex flex-col ml-4"
          >
            <p class="text-xs font-semibold">{{ infectiousScreeningStatus }}</p>
            <ScreeningStatusLabel
              class="scroll-table-screening-status text-lg"
              icon
              label
              textColor="tmrw-blue-dark font-semibold"
              iconColor="tmrw-blue-dark"
              align="left"
              paddingSmall
              :id="Number(screeningStatus) || 0"
            />
          </div>
        </div>
      </section>
      <TicketButtonsWrapper
        v-if="showTicketActionButtons"
        :ticket="ticket"
        @complete-button-clicked="onCompleteButtonClicked"
        @add-details-button-clicked="onAddDetailsButtonClicked"
        @review-audit-button-clicked="onShouldReviewAuditButton"
        @edit-button-clicked="onEditButtonClicked"
        @remove-from-batch-button-clicked="onRemoveFromBatchButtonClicked"
        @cancel-button-clicked="onCancelTicket"
      />
    </div>
    <!-- Specimens list table -->
    <div
      v-if="specimensData?.length"
      data-test="ticket-list-specimens-list-table"
      id="ticket-list-specimens-list-table"
      class="flex flex-col relative mt-6"
    >
      <!-- content -->
      <span
        v-if="!specimensData.length"
        class="p-1"
        id="ticket-list-cryodevice-count"
        data-test="ticket-list-cryodevice-count"
        >{{ emptyCryodevices }}</span
      >
      <InventorySpecimenTables
        data-test="ticket-table__editable__list"
        class="w-full"
        :ticketDetail="ticketDetailModel"
        :eggSpecimenHeaders="EGG_HEADERS"
        :eggSpecimens="eggSpecimens"
        :embryoSpecimenHeaders="EMBRYO_HEADERS"
        :embryoSpecimens="embryoSpecimens"
      />
    </div>
  </section>
</template>

<script>
import inCryorobot from '@/assets/in-cryorobot.svg'
import outCryorobot from '@/assets/out-cryorobot.svg'
import {
  CRYOBEACON,
  EMPTY_CRYODEVICES,
  INFECTIOUS_SCREENING_STATUS,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_OOCYTE,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  PROCEDURE_TYPE_BEACON_MOVE
} from '@/constants'
import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import TempCryoBeaconMixin from '@/mixins/TempCryoBeaconMixin'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import { ApiCallsFactory } from '@/factories/index'
import * as ss from '@/config/session-storage-help'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation/index'
import TicketButtonsWrapper from '@/components/TicketButtonsWrapper/TicketButtonsWrapper.vue'
import { isTicketCancellable } from '@/helpers/ticketState'
import { TICKETS_API_ENDPOINT } from '@/constants/routes'
import { createDynamicHeadersFromSpecimens } from '@/helpers/dynamicTableHeaders'
import { STEP_INITIAL } from '@/constants/ticketSteps'
import toast from '@/config/toast'

export default {
  name: 'ticket-list',
  emits: [
    'onClickCancel',
    'add-details-button-clicked',
    'complete-button-clicked',
    'review-audit-button-clicked',
    'edit-button-clicked'
  ],
  props: {
    beaconId: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    headers: {
      required: true,
      type: Array
    },
    specimensData: {
      required: true,
      type: Array
    },
    isEMRTicket: {
      required: false,
      type: Boolean
    },
    state: {
      required: false,
      type: String
    },
    ticketProcedureType: {
      required: false,
      type: String
    },
    robotLocations: {
      required: false,
      type: Array
    },
    robotTransfer: {
      required: false,
      type: String
    },
    screeningStatus: {
      type: [String, Number],
      required: false
    },
    isCancellable: {
      type: [Object, Boolean],
      default: () => ({ value: false })
    },
    ticketType: {
      type: String,
      required: false
    },
    isDonateToPatient: {
      type: Boolean,
      required: false
    },
    selectedTicketIndex: {
      type: Number,
      required: false
    },
    ticket: {
      type: Object,
      required: false
    },
    newTicketData: {
      type: Object,
      required: false
    },
    destinationLocation: {
      type: Object,
      required: false
    },
    sourceLocation: {
      type: Object,
      required: false
    },
    showTicketActionButtons: {
      type: Boolean,
      default: true
    },
    patientsTooltip: {
      type: Boolean,
      default: false
    },
    reprintDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      infectiousScreeningStatus: INFECTIOUS_SCREENING_STATUS,
      ticketDetailModel: {
        sort: {
          orderBy: 'cryoDate',
          direction: 'desc'
        },
        options: []
      },
      CryoBeacon: CRYOBEACON,
      emptyCryodevices: EMPTY_CRYODEVICES,
      inCryorobot,
      outCryorobot,
      newBeaconsArray: null,
      appBaseConfig: {
        siteId: ss.getFieldSessionStorage('config', 'siteId') || null,
        clinicId: ss.getFieldSessionStorage('config', 'clinicId') || null,
        cpId: ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
        token: ss.getFieldSessionStorage('user', 'token') || null,
        userId: ss.getFieldSessionStorage('user', 'userId') || null
      },
      inCryorobotIcon: inCryorobot,
      outCryorobotIcon: outCryorobot,
      EGG_HEADERS: [],
      EMBRYO_HEADERS: [],
      batchData: [],
      firstTicketData: null
    }
  },
  mixins: [TempCryoBeaconMixin],
  computed: {
    cryorobotTitle() {
      return getCurrentCryoBeaconLocation(this.robotLocations).location
    },
    isTicketDataVisible() {
      return this.robotLocations?.length || this.beaconId || this.screeningStatus
    },
    embryoSpecimens() {
      const embryosModel = {
        ...this.ticketDetailModel,
        options: this.specimensData.filter((item) => item.specimenType === SPECIMEN_TYPE_EMBRYO)
      }
      return embryosModel
    },
    eggSpecimens() {
      const eggsModel = {
        ...this.ticketDetailModel,
        options: this.specimensData.filter(
          (item) =>
            item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
        )
      }
      return eggsModel
    },
    isSourceLocationSet() {
      return !!this.sourceLocation?.robotName
    },
    isDestinationSet() {
      return !!this.destinationLocation?.robotName
    }
  },
  watch: {
    specimensData(newValue) {
      if (newValue) {
        this.ticketDetailModel = {
          ...this.ticketDetailModel,
          options: newValue
        }
        // Reprint Label --> need it on dynamic headers
        if (this.ticket.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) {
          const [ticketData] = this.batchData.filter(
            ({ specimens }) => specimens[0].cryodeviceBarcode === newValue[0].cryodeviceBarcode
          )
          const { embryoHeaders, oocyteHeaders } = createDynamicHeadersFromSpecimens({
            ticket: ticketData,
            patientsTooltip: this.patientsTooltip,
            reprintDisabled: this.reprintDisabled
          })

          this.EMBRYO_HEADERS = embryoHeaders
          this.EGG_HEADERS = oocyteHeaders
        }
      }
    }
  },
  mounted() {
    this.ticketDetailModel = {
      ...this.ticketDetailModel,
      options: this.specimensData
    }
    if (ss.getFieldSessionStorage('newBeaconsArray')) {
      this.newBeaconsArray = JSON.parse(ss.getFieldSessionStorage('newBeaconsArray'))
    }

    this.firstTicketData = this.ticket

    if (this.ticket?.procedureType === PROCEDURE_TYPE_BATCH_UPPERCASE) {
      this.batchData = ss.getFieldSessionStorage('batch', 'cache')
      const [firstTicket] = this.batchData
      this.firstTicketData = {
        ...this.ticket,
        ...firstTicket
      }
    }

    const { embryoHeaders, oocyteHeaders } = createDynamicHeadersFromSpecimens({
      ticket: this.firstTicketData,
      patientsTooltip: this.patientsTooltip,
      reprintDisabled: this.reprintDisabled
    })

    this.EMBRYO_HEADERS = embryoHeaders
    this.EGG_HEADERS = oocyteHeaders
  },
  methods: {
    getLastScannedBeacon() {
      return ss.getFieldSessionStorage('lastScannedBeacon')
    },
    robotTransferImage() {
      return getCurrentCryoBeaconLocation(this.robotLocations).imageSrc
    },
    onCancelTicket() {
      const isCancelable = isTicketCancellable(this.ticket)
      if (isCancelable) {
        const { ticketId } = this.ticket
        this.$emit('onClickCancel', { action: 'reason-screen', ticketId })
      }
    },
    async onRemoveFromBatchButtonClicked() {
      try {
        const { ticketId, procedureType } = this.ticket
        const { token } = this.appBaseConfig
        const remove = new ApiCallsFactory(TICKETS_API_ENDPOINT(ticketId), token)
        await remove.deleteData()
        toast.success('Ticket successfully removed from batch!')
        return this.$router.push({
          name:
            procedureType === PROCEDURE_TYPE_BEACON_MOVE
              ? STEP_BIOREPOSITORY_FLIGHTBOARD
              : STEP_INITIAL
        })
      } catch (err) {
        toast.error({ title: err.message })
      }
    },
    onAddDetailsButtonClicked() {
      this.$emit('add-details-button-clicked')
    },
    onCompleteButtonClicked() {
      this.$emit('complete-button-clicked')
    },
    onShouldReviewAuditButton() {
      this.$emit('review-audit-button-clicked')
    },
    onEditButtonClicked() {
      this.$emit('edit-button-clicked')
    }
  },
  components: {
    InventorySpecimenTables,
    ScreeningStatusLabel,
    TicketButtonsWrapper
  }
}
</script>
