<template>
  <div>
    <div class="flex bg-white p-6 " :class="[arePatientsFound ? 'rounded-t-lg' : 'rounded-lg']">
      <div class="w-1/2">
        <patients-search-field
          type="text"
          name="search-patient-component"
          :hasError="shouldShowErrorMessage"
          isBlueOnWhite
          @handlePatientsSearch="searchPatientList"
        />
      </div>
      <div class="w-1/2 flex justify-end items-center">
        <button-dynamic
          v-if="allowCreatePatients"
          btnType="button"
          btnText="Add a New Patient"
          btnStyle="transparent-blue"
          addUnderline
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="openModalWindow"
        />
      </div>
    </div>

    <loading-ui size="small" class="my-24" v-if="processing && !arePatientsFound" />

    <div v-if="arePatientsFound" class="bg-white pb-4 pl-4 pr-4 pt-0 rounded-b-lg">
      <ScrollTableComponent
        minHeight="h-full"
        v-model="patientsModel"
        :headers="headers"
        :selectionMode="'radio'"
      />
    </div>
    <modal-new-patient
      :isModalOpen="isNewPatientModalOpen"
      @closeModalWindow="closeModalWindow"
      @fetchPatients="handlePatientsSearch"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import PatientsSearchField from '@/components/SearchField/PatientsSearchField.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ModalNewPatient from '@/views/ModalNewPatient/ModalNewPatient.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import { PATIENT_NOT_FOUND_ERROR } from '@/constants'
import toast from '@/config/toast'
import { ALPHANUM_DASHES, ALPHANUM_SPECIAL_CHARACTERS } from '@/constants/regular-expressions'

// TODO: Add donation to CY tests

export default {
  name: 'search-patient',
  data() {
    return {
      showSchedule: false,
      globalPatientNumber: '',
      searchSubmitted: false,
      processing: false,
      noPatients: false,
      isNewPatientModalOpen: false,
      patientsFound: [],
      patientsModel: {
        sort: {
          orderBy: 'globalPatientNumber',
          direction: 'desc'
        },
        options: [],
        hasLoaded: false
      },
      bulkType: 'patients',
      isPatientSearchSubmitted: false,
      patientNotFoundErrorMessage: PATIENT_NOT_FOUND_ERROR,
      allowCreatePatients: this.$isSiteEmrEnabled?.allowCreatePatients,
      hasValidSearchParm: false
    }
  },
  props: {
    canImportPatient: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['syncData'],
  mounted() {
    this.setPatientsEmpty()
  },
  computed: {
    ...mapGetters('specimensModule', ['specimens']),
    ...mapGetters('authModule', ['appBaseConfig']),
    ...mapGetters('patientsModule', ['donatePatient']),
    noFoundPatient() {
      return this.searchSubmitted && this.noPatients && !this.processing
    },
    showTable() {
      return !!this.patientsFound.length
    },
    headers() {
      return [
        {
          name: 'Patient Name',
          key: 'fullName',
          cssClasses: ['w-64'],
          sort: true
        },
        {
          name: 'Patient ID',
          key: 'identificationNumber',
          cssClasses: ['w-64'],
          unique: true,
          sort: true
        },
        {
          name: 'Date of Birth',
          key: 'birthDate',
          cssClasses: ['w-64'],
          sort: true,
          filters: [
            (value) => dayjs(value).format('DDMMMYYYY'),
            (value) => value.toUpperCase()
          ]
        },
        {
          name: 'Total Cryodevice Count',
          key: 'specimenCount',
          cssClasses: ['w-64'],
          sort: true
        }
      ]
    },
    shouldShowErrorMessage() {
      return this.isPatientSearchSubmitted && (!this.arePatientsFound || !this.hasValidSearchParm)
    },
    arePatientsFound() {
      return !!this.patientsModel.options.length
    }
  },
  methods: {
    ...mapActions('createPatientModule', ['createPatient']),
    ...mapActions('patientsModule', [
      'fetchPatients',
      'searchPatients',
      'setPatients',
      'setDonatePatient',
      'setPatientsEmpty'
    ]),
    openModalWindow() {
      this.isNewPatientModalOpen = true
    },
    closeModalWindow() {
      this.isNewPatientModalOpen = false
    },
    selectPatient() {
    },
    patientDate(date) {
      return dayjs(date)
        .format('DDMMMYYYY')
        .toUpperCase()
    },
    handlePatientsSearch(searchPatientBy, searchPatientValue) {
      this.searchSubmitted = true
      this.noPatients = false
      this.searchPatientList(searchPatientBy, searchPatientValue)
    },
    validatePatientSearchParameters(params, searchPatientValue) {
      const regex = params.lastName ? ALPHANUM_SPECIAL_CHARACTERS : ALPHANUM_DASHES
      return (regex).test(searchPatientValue)
    },
    async searchPatientList(searchPatientBy, searchPatientValue) {
      try {
        await this.setDonatePatient(null)
        this.$emit('syncData')
        const { sort } = this.patientsModel
        let options = []
        // Clear Patients List
        this.patientsModel = {
          sort,
          options,
          hasLoaded: false
        }
        // Show loader
        this.processing = true
        // Set search submitted as false before executing search.
        this.isPatientSearchSubmitted = false

        let params
        switch (searchPatientBy) {
          // Search by Patient Identification Number
          case 'identificationNumber':
            params = { identificationNumber: searchPatientValue }
            break
          // Search by Patient Last Name
          case 'lastName':
            params = { lastName: searchPatientValue }
            break
          default:
            params = { identificationNumber: searchPatientValue }
        }

        this.hasValidSearchParm = this.validatePatientSearchParameters(params, searchPatientValue)
        if (!this.hasValidSearchParm) {
          return
        }

        const { data: allFoundPatients } = await this.searchPatients(params)
        // Filters out external patients if canImportPatient is false (only true on freeze and import tickets)
        const foundPatients = this.canImportPatient ? allFoundPatients : allFoundPatients.filter(patient => !patient.external)

        if (foundPatients.length) {
          // Set new array for Patients List
          const patientsList = []
          // Loop through patients object to add Full Name of patient.
          foundPatients.forEach((patient) => {
            const {
              firstName,
              lastName
            } = patient
            const fullName = `${firstName} ${lastName}`
            patientsList.push({
              ...patient,
              fullName
            })
          })

          options = patientsList
          this.patientsModel = {
            sort,
            options,
            hasLoaded: true
          }
        }
      } catch (err) {
        toast.custom('error', 'error', err)
      } finally {
        // Remove loader from UI.
        this.processing = false
        // Set search submitted as true after completing search and array composition.
        this.isPatientSearchSubmitted = true
      }
    }
  },
  watch: {
    patientsModel(newValue, oldValue) {
      if (newValue.options !== oldValue.options) {
        const selectedPatient = this.patientsModel.options.filter(
          (option) => option.selected
        )
        if (selectedPatient.length) {
          this.globalPatientNumber = selectedPatient[0].globalPatientNumber
          this.setDonatePatient(selectedPatient[0])
          this.$emit('syncData')
        }
      }
    }
  },
  components: {
    PatientsSearchField,
    ButtonDynamic,
    LoadingUi,
    ModalNewPatient,
    ScrollTableComponent
  }
}
</script>
