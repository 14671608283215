class SpecimensFactory {
  private specimens: object[]

  constructor(specimens: object[]) {
    this.specimens = specimens
  }

  filterBySpecimenType(type: string) {
    try {
      const filteredSpecimens = this.specimens.filter((specimen: ReturnType<typeof Object>) => (
        specimen.specimenType === type
      ))
      return filteredSpecimens
    } catch (error: any) {
      throw new Error(error)
    }
  }

  getSpecimensCount(): number {
    let count: number = 0
    try {
      this.specimens.map((specimen: ReturnType<typeof Object>) => {
        count += +specimen.specimenCount
        return count
      })
    } catch (error: any) {
      throw new Error(error)
    }
    return count
  }
}

export {
  SpecimensFactory
}
