<template>
  <div class="c19 doc-content">
    <p class="c4">
      <span class="c2 c0">August 1, 2022</span>
    </p>
    <p class="c4 c10">
      <span class="c2 c0" />
    </p>
    <p class="c4 c10">
      <span class="c2 c0" />
    </p>
    <p class="c4">
      <span class="c8 c0">PLEASE READ THESE </span><span class="c0 c8">LICENCE</span
      ><span class="c11 c8 c0">&nbsp;TERMS CAREFULLY</span>
    </p>
    <p class="c4">
      <span class="c0">Your practice (</span><span class="c8 c0">Practice</span
      ><span class="c0">) has entered into an agreement with us TMRW Life Sciences (</span
      ><span class="c8 c0">Licensor, us</span><span class="c0">&nbsp;or </span
      ><span class="c8 c0">we</span
      ><span class="c0"
        >) which includes a licence to access our proprietary ivfOS Software, a cryogenic specimen
        management software, training and related software, including a training application
        (collectively, the</span
      ><span class="c8 c0">&nbsp;Software</span
      ><span class="c0"
        >), together with printed materials and electronic documentation used to support the
        Software (</span
      ><span class="c8 c0">Documentation</span><span class="c2 c0">). </span>
    </p>
    <p class="c4">
      <span class="c0"
        >As an employee or contractor of the Practice, the practice has granted you access to the
        Software and Documentation and by accepting the terms of this licence (</span
      ><span class="c8 c0">Licence</span><span class="c0">) you (</span
      ><span class="c8 c0">Licensee</span><span class="c0">&nbsp;or </span
      ><span class="c8 c0">you</span><span class="c2 c0">) agree to be bound by its terms. </span>
    </p>
    <p class="c4">
      <span class="c2 c0"
        >We license use of the Software and Documentation to you on the basis of this Licence. We do
        not sell the Software or Documentation to you. We remain the owners of the Software and
        Documentation at all times.
      </span>
    </p>
    <p class="c15">
      <span class="c8 c0">OPERATING SYSTEM REQUIREMENTS:</span
      ><span class="c0"
        >&nbsp;THIS SOFTWARE REQUIRES THE MINIMUM HARDWARE, SOFTWARE, NETWORK AND ENVIRONMENTAL
        REQUIREMENTS PROVIDED BY THE LICENSOR IN THEIR STATEMENT OF WORK AGREED WITH YOUR
        PRACTICE</span
      ><span class="c22">.</span>
    </p>
    <p class="c18">
      <span class="c11 c8 c0">AGREED TERMS</span>
    </p>
    <ol class="c9 lst-kix_o1o7m8qb0f8q-0 start" start="1">
      <li class="c6 li-bullet-0">
        <span class="c11 c8 c0">Grant and scope of licence, minor changes and updates</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_o1o7m8qb0f8q-1 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >We grant you a non-exclusive, non-transferable, non-sublicensable licence to use the
          Software and the Documentation on the terms of this Licence.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >The Licence above may not be sub-licensed, assigned or otherwise transferred to any other
          person without our prior consent.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c0"
          >Subject to your compliance with the terms of this Licence, the Licence shall continue for
          so long as</span
        ><span class="c8 c0">&nbsp;</span
        ><span class="c0"
          >you have been granted access to the Software by your Practice and for so long as the
          Practice has a licence to access the Software.</span
        ><span class="c11 c8 c0">&nbsp;</span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >By installing and using the Software, you represent and warrant that you work for your
          Practice and have been granted access to the Software by your Practice, pursuant to the
          agreement between us and the Practice.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c0"
          >Your Practice is responsible for informing us immediately if you cease to be employed by
          them or are otherwise no longer </span
        ><span class="c0">authorised</span
        ><span class="c2 c0"
          >&nbsp;by the Practice to access the Software, in which case we will terminate your access
          to the Software. If we terminate your access to the Software in accordance with the terms
          of this Licence, you will no longer be able to access the Software.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >You may receive and use any free supplementary software code or update of the Software
          incorporating &quot;patches&quot; and corrections of errors as may be provided by the
          Licensor from time to time (but see condition 1.7); and use any Documentation in support
          of the use permitted hereunder and make one copy of the Documentation as are reasonably
          necessary for its lawful use.</span
        >
      </li>
      <li class="c13 li-bullet-0">
        <span class="c2 c0"
          >We may update or require you to update the Software, provided that the Software shall
          always match the description of it that we provided to you before you bought it.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_o1o7m8qb0f8q-0" start="2">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">Use of the Software</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_o1o7m8qb0f8q-1 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c2 c0">You shall:</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_o1o7m8qb0f8q-2 start" start="1">
      <li class="c16 li-bullet-0">
        <span class="c0">provide the Licensor with all necessary </span
        ><span class="c0">co-operation</span
        ><span class="c2 c0">&nbsp;in relation to the terms of this Licence and the Software;</span>
      </li>
      <li class="c16 li-bullet-0">
        <span class="c2 c0">follow any reasonable instructions given to you by us;</span>
      </li>
      <li class="c16 li-bullet-0">
        <span class="c2 c0"
          >not use the Software contrary to any restrictions stated in the terms of this Licence, or
          otherwise in a way that is not expressly permitted by the terms of this Licence;</span
        >
      </li>
      <li class="c16 li-bullet-0">
        <span class="c2 c0"
          >comply with all applicable laws and regulations with respect to your activities under the
          terms of this Licence; and</span
        >
      </li>
      <li class="c17 li-bullet-0">
        <span class="c0">use your best </span><span class="c0">endeavours</span
        ><span class="c0">&nbsp;to prevent any </span><span class="c0">unauthorised</span
        ><span class="c2 c0">&nbsp;access to, or use of, the Software.</span>
      </li>
    </ol>
    <p class="c4 c25">
      <span class="c2 c0"
        >2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except as expressly set out in this
        Licence or as permitted by any local law, you undertake:</span
      >
    </p>
    <ol class="c9 lst-kix_b68w4a1o9sr1-1 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >not to copy the Software or Documentation, except where such copying is incidental to
          normal use of the Software or where it is necessary for the purpose of back-up or
          operational security;</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >not to rent, lease, loan, translate, merge, adapt, vary, alter or modify, the whole or
          any part of the Software or Documentation nor permit the Software or any part of it to be
          combined with, or become incorporated in, any other programs;</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >not to disassemble, de-compile, reverse engineer or create derivative works based on the
          whole or any part of the Software nor attempt to do any such things;</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0"
          >to keep all copies of the Software secure and to maintain accurate and up-to-date records
          of the number and locations of all copies of the Software; and</span
        >
      </li>
    </ol>
    <p class="c20">
      <span class="c2 c0"
        >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to include our copyright notice on all
        entire and partial copies of the Software in any form.</span
      >
    </p>
    <ol class="c9 lst-kix_7hh9dfc45078-0 start" start="1">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">Intellectual property rights</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_7hh9dfc45078-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >You acknowledge that all intellectual property rights in the Software and the
          Documentation throughout the world belong to us, that rights in the Software are licensed
          (not sold) to you on behalf of the Practice, and that you have no intellectual property
          rights in, or to, the Software or the Documentation other than the right to use the
          Software and the Documentation in accordance with the terms of this Licence.</span
        >
      </li>
      <li class="c13 li-bullet-0">
        <span class="c2 c0"
          >You acknowledge that you have no right to have access to the Software in source code form
          other than as expressly provided in this Licence.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_7hh9dfc45078-0" start="2">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">Term and Termination</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_7hh9dfc45078-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >This Licence continues for as long as the Practice has a licence to use the Software and
          has not notified us that you may be not access the Software.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >For the avoidance of any doubt, your right to access the Software will immediately
          terminate on termination of your Practice&rsquo;s licence to access the Software.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >We may, without prejudice to any other rights and remedies, terminate this Licence
          immediately by written notice to you at any time.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c2 c0">Upon termination for any reason:</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_7hh9dfc45078-2 start" start="1">
      <li class="c16 li-bullet-0">
        <span class="c2 c0">all rights granted to you under this Licence shall cease;</span>
      </li>
      <li class="c16 li-bullet-0">
        <span class="c2 c0">you must cease all activities authorised by this Licence; and</span>
      </li>
      <li class="c23 li-bullet-0">
        <span class="c2 c0"
          >you must immediately delete or remove the Software from all computer equipment in your
          possession and immediately destroy or return to us (at our option) all copies of the
          Software then in your possession, custody or control and, in the case of destruction,
          certify to us that you have done so.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_7hh9dfc45078-0" start="3">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">Confidential Information</span>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >You shall treat any information relating to us or our business as strictly confidential
        (&ldquo;</span
      ><span class="c8 c0">Confidential Information</span
      ><span class="c2 c0"
        >&rdquo;) except to the extent that such information can be proved to be within the public
        domain at the time of disclosure other than as a result of any breach of this Licence. You
        shall not disclose such information to any third party nor use it for any purpose except as
        expressly permitted by the terms of this Licence or as is strictly necessary for the
        performance of your obligations set out in the same terms. &nbsp;You shall not make any
        announcements relating to the existence of any relationship between us in connection with
        the terms of this Licence without the express written consent of both us and your Practice.
      </span>
    </p>
    <ol class="c9 lst-kix_382g8fe35h5b-0 start" start="1">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">Communications between us</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_382g8fe35h5b-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c0">
          If you wish to contact us in writing, or if any condition in this Licence requires you to
          give us notice in writing, you can send this to us by pre-paid post to TMRW Life Sciences,
          Inc. at
        </span>

        <span class="c2 c0">
          {{TMRW_ADDRESS}}&nbsp; or by email to the Data Protection Office at privacy@tmrw.org. We
          will confirm receipt of this by contacting you in writing, normally by email.</span
        >
      </li>
      <li class="c13 li-bullet-0">
        <span class="c2 c0"
          >If we have to contact you or give you notice in writing, we will do so by email or by
          pre-paid post to the address you provide or confirm to us.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_382g8fe35h5b-0" start="2">
      <li class="c12 li-bullet-0">
        <span class="c11 c8 c0">How we may use your personal information </span>
      </li>
    </ol>
    <p class="text-left c1">
      <span class="c2 c0"
        >We are required to provide you with certain information about who we are, how we process
        your personal data and for what purposes and your rights in relation to your personal data
        and how to exercise them. This information is provided in our
        <a class="link-privacy text-blue-600 underline" @click="handleLinkClick">Privacy Policy</a>
        and it is important that you read that information.</span
      >
    </p>
    <ol class="c9 lst-kix_3yx584spl2fy-0 start" start="1">
      <li class="c12 li-bullet-0">
        <span class="c8 c0 c11">Other important terms</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_3yx584spl2fy-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >We may transfer our rights and obligations under these terms to another organisation. We
          will always tell you in writing if this happens and we will ensure that the transfer will
          not affect your rights under the contract.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >You may only transfer your rights or your obligations under this Licence to another
          person if we agree in writing.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c2 c0"
          >Each of the conditions of these terms operates separately. If any court or relevant
          authority decides that any of them are unlawful, the remaining conditions will remain in
          full force and effect.</span
        >
      </li>
      <li class="c13 li-bullet-0">
        <span class="c0 c2"
          >If we do not insist immediately that you do anything you are required to do under these
          terms, or if we delay in taking steps against you in respect of your breaking this
          contract, that will not mean that you do not have to do those things and it will not
          prevent us taking steps against you at a later date.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_3yx584spl2fy-0" start="2">
      <li class="c6 li-bullet-0">
        <span class="c11 c8 c0"
          >Which laws apply to this contract and where you may bring legal proceedings.
        </span>
      </li>
    </ol>
    <p class="c1">
      <span class="c2 c0"
        >These terms are governed by and construed in accordance with, the laws of the State of New
        York, United States of America, without regard to the conflict of laws or provisions thereof
        to the extent such principles or rules would require or permit the application of the laws
        of any jurisdiction other than those of the State of New York.
      </span>
    </p>
    <p class="c10 c24">
      <span class="c2 c3" />
    </p>
  </div>
</template>
<script>
import {TMRW_ADDRESS} from '@/constants';
export default {
  data() {
    return {
      TMRW_ADDRESS,
    };
  },
  name: 'TermsConditionsDoc',
  props: {
    handleLinkClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.link-privacy {
  cursor: pointer;
}
ol.lst-kix_7hh9dfc45078-3.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-3 0;
}
.lst-kix_b68w4a1o9sr1-6 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-6;
}
.lst-kix_382g8fe35h5b-0 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-0;
}
.lst-kix_7hh9dfc45078-7 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-7;
}
.lst-kix_3yx584spl2fy-3 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-3;
}
ol.lst-kix_o1o7m8qb0f8q-8.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-8 0;
}
ol.lst-kix_o1o7m8qb0f8q-5.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-5 0;
}
.lst-kix_7hh9dfc45078-6 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-6;
}
.lst-kix_b68w4a1o9sr1-1 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-1, lower-latin) '. ';
}
.lst-kix_b68w4a1o9sr1-7 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-7;
}
.lst-kix_3yx584spl2fy-2 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-2;
}
ol.lst-kix_3yx584spl2fy-7.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-7 0;
}
.lst-kix_b68w4a1o9sr1-0 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-0, decimal) '. ';
}
ol.lst-kix_o1o7m8qb0f8q-0 {
  list-style-type: none;
}
.lst-kix_o1o7m8qb0f8q-0 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-0;
}
ol.lst-kix_o1o7m8qb0f8q-1 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-2.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-2 0;
}
ol.lst-kix_382g8fe35h5b-5.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-5 0;
}
ol.lst-kix_7hh9dfc45078-0.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-0 0;
}
ol.lst-kix_b68w4a1o9sr1-8 {
  list-style-type: none;
}
.lst-kix_3yx584spl2fy-0 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-0;
}
ol.lst-kix_o1o7m8qb0f8q-6 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-0 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-7 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-1 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-8 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-2 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-3 {
  list-style-type: none;
}
.lst-kix_b68w4a1o9sr1-8 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-8;
}
ol.lst-kix_7hh9dfc45078-6.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-6 0;
}
ol.lst-kix_o1o7m8qb0f8q-2 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-4 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-3 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-5 {
  list-style-type: none;
}
ol.lst-kix_b68w4a1o9sr1-6.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-6 0;
}
ol.lst-kix_o1o7m8qb0f8q-4 {
  list-style-type: none;
}
.lst-kix_b68w4a1o9sr1-5 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-5;
}
ol.lst-kix_b68w4a1o9sr1-6 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-0 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-0, decimal) '. ';
}
ol.lst-kix_o1o7m8qb0f8q-5 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-2.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-2 0;
}
ol.lst-kix_b68w4a1o9sr1-7 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-4.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-4 0;
}
ol.lst-kix_7hh9dfc45078-8.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-8 0;
}
ol.lst-kix_o1o7m8qb0f8q-3.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-3 0;
}
.lst-kix_382g8fe35h5b-1 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-1, decimal) '. ';
}
.lst-kix_382g8fe35h5b-3 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-3, decimal) '. ';
}
.lst-kix_o1o7m8qb0f8q-7 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-7;
}
ol.lst-kix_7hh9dfc45078-1.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-1 0;
}
.lst-kix_382g8fe35h5b-0 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-0, decimal) '. ';
}
.lst-kix_382g8fe35h5b-4 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-4, lower-latin) '. ';
}
.lst-kix_382g8fe35h5b-3 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-3;
}
.lst-kix_382g8fe35h5b-8 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-8;
}
.lst-kix_7hh9dfc45078-4 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-4;
}
.lst-kix_382g8fe35h5b-2 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-2, lower-roman) '. ';
}
.lst-kix_7hh9dfc45078-3 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-3;
}
ol.lst-kix_3yx584spl2fy-2.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-2 0;
}
.lst-kix_b68w4a1o9sr1-4 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-4;
}
.lst-kix_382g8fe35h5b-2 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-2;
}
.lst-kix_382g8fe35h5b-8 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-8, lower-roman) '. ';
}
ol.lst-kix_b68w4a1o9sr1-0.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-0 0;
}
ol.lst-kix_b68w4a1o9sr1-7.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-7 0;
}
.lst-kix_o1o7m8qb0f8q-1 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-1;
}
.lst-kix_382g8fe35h5b-6 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-6, decimal) '. ';
}
.lst-kix_382g8fe35h5b-7 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-7, lower-latin) '. ';
}
.lst-kix_382g8fe35h5b-5 > li:before {
  content: '' counter(lst-ctn-kix_382g8fe35h5b-5, lower-roman) '. ';
}
ol.lst-kix_7hh9dfc45078-2.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-2 0;
}
.lst-kix_b68w4a1o9sr1-4 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-4, lower-latin) '. ';
}
.lst-kix_b68w4a1o9sr1-6 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-6, decimal) '. ';
}
.lst-kix_3yx584spl2fy-4 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-4;
}
.lst-kix_3yx584spl2fy-1 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-1;
}
ol.lst-kix_382g8fe35h5b-3.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-3 0;
}
.lst-kix_b68w4a1o9sr1-5 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-5, lower-roman) '. ';
}
ol.lst-kix_o1o7m8qb0f8q-4.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-4 0;
}
.lst-kix_b68w4a1o9sr1-2 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-2, lower-roman) '. ';
}
ol.lst-kix_3yx584spl2fy-1.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-1 0;
}
.lst-kix_b68w4a1o9sr1-3 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-3, decimal) '. ';
}
ol.lst-kix_3yx584spl2fy-8.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-8 0;
}
.lst-kix_3yx584spl2fy-1 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-1, decimal) '. ';
}
.lst-kix_b68w4a1o9sr1-8 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-8, lower-roman) '. ';
}
ol.lst-kix_b68w4a1o9sr1-1.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-1 0;
}
.lst-kix_3yx584spl2fy-7 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-7;
}
.lst-kix_3yx584spl2fy-0 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-0, decimal) '. ';
}
.lst-kix_b68w4a1o9sr1-7 > li:before {
  content: '' counter(lst-ctn-kix_b68w4a1o9sr1-7, lower-latin) '. ';
}
.lst-kix_3yx584spl2fy-5 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-5, lower-roman) '. ';
}
.lst-kix_3yx584spl2fy-7 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-7, lower-latin) '. ';
}
.lst-kix_3yx584spl2fy-8 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-8;
}
ol.lst-kix_3yx584spl2fy-0.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-0 0;
}
.lst-kix_3yx584spl2fy-2 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-2, lower-roman) '. ';
}
.lst-kix_3yx584spl2fy-6 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-6, decimal) '. ';
}
.lst-kix_3yx584spl2fy-3 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-3, decimal) '. ';
}
.lst-kix_7hh9dfc45078-1 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-1;
}
.lst-kix_382g8fe35h5b-5 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-5;
}
.lst-kix_b68w4a1o9sr1-1 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-1;
}
.lst-kix_3yx584spl2fy-4 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-4, lower-latin) '. ';
}
ol.lst-kix_b68w4a1o9sr1-8.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-8 0;
}
.lst-kix_o1o7m8qb0f8q-5 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-5;
}
.lst-kix_382g8fe35h5b-6 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-6;
}
ol.lst-kix_b68w4a1o9sr1-2.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-2 0;
}
.lst-kix_o1o7m8qb0f8q-4 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-4;
}
.lst-kix_b68w4a1o9sr1-0 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-0;
}
ol.lst-kix_b68w4a1o9sr1-5.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-5 0;
}
ol.lst-kix_382g8fe35h5b-6.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-6 0;
}
ol.lst-kix_7hh9dfc45078-7.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-7 0;
}
.lst-kix_7hh9dfc45078-0 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-0;
}
.lst-kix_3yx584spl2fy-8 > li:before {
  content: '' counter(lst-ctn-kix_3yx584spl2fy-8, lower-roman) '. ';
}
ol.lst-kix_3yx584spl2fy-6.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-6 0;
}
.lst-kix_b68w4a1o9sr1-2 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-2;
}
.lst-kix_o1o7m8qb0f8q-3 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-3;
}
ol.lst-kix_o1o7m8qb0f8q-6.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-6 0;
}
.lst-kix_o1o7m8qb0f8q-6 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-6;
}
ol.lst-kix_3yx584spl2fy-3.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-3 0;
}
ol.lst-kix_3yx584spl2fy-0 {
  list-style-type: none;
}
ol.lst-kix_3yx584spl2fy-1 {
  list-style-type: none;
}
ol.lst-kix_3yx584spl2fy-5.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-5 0;
}
.lst-kix_7hh9dfc45078-1 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-1, decimal) '. ';
}
.lst-kix_7hh9dfc45078-3 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-3, decimal) '. ';
}
.lst-kix_o1o7m8qb0f8q-0 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-0, decimal) '. ';
}
ol.lst-kix_b68w4a1o9sr1-3.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-3 0;
}
.lst-kix_3yx584spl2fy-5 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-5;
}
.lst-kix_b68w4a1o9sr1-3 > li {
  counter-increment: lst-ctn-kix_b68w4a1o9sr1-3;
}
.lst-kix_7hh9dfc45078-2 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-2, lower-latin) '. ';
}
.lst-kix_o1o7m8qb0f8q-3 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-3, decimal) '. ';
}
ol.lst-kix_382g8fe35h5b-3 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-4 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-1 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-5 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-5, lower-roman) '. ';
}
.lst-kix_o1o7m8qb0f8q-2 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-2, lower-latin) '. ';
}
ol.lst-kix_382g8fe35h5b-2 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-7 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-8 {
  list-style-type: none;
}
ol.lst-kix_382g8fe35h5b-5 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-4 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-4, lower-latin) '. ';
}
.lst-kix_o1o7m8qb0f8q-1 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-1, decimal) '. ';
}
ol.lst-kix_382g8fe35h5b-6 {
  list-style-type: none;
}
.lst-kix_3yx584spl2fy-6 > li {
  counter-increment: lst-ctn-kix_3yx584spl2fy-6;
}
ol.lst-kix_382g8fe35h5b-1.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-1 0;
}
ol.lst-kix_o1o7m8qb0f8q-0.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-0 0;
}
.lst-kix_o1o7m8qb0f8q-8 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-8;
}
ol.lst-kix_7hh9dfc45078-5.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-5 0;
}
.lst-kix_o1o7m8qb0f8q-6 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-6, decimal) '. ';
}
.lst-kix_o1o7m8qb0f8q-8 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-8, lower-roman) '. ';
}
ol.lst-kix_382g8fe35h5b-8.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-8 0;
}
ol.lst-kix_382g8fe35h5b-0 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-7.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-7 0;
}
.lst-kix_7hh9dfc45078-6 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-6, decimal) '. ';
}
.lst-kix_o1o7m8qb0f8q-7 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-7, lower-latin) '. ';
}
ol.lst-kix_3yx584spl2fy-8 {
  list-style-type: none;
}
ol.lst-kix_3yx584spl2fy-6 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-7 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-7, lower-latin) '. ';
}
ol.lst-kix_3yx584spl2fy-7 {
  list-style-type: none;
}
.lst-kix_o1o7m8qb0f8q-4 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-4, lower-latin) '. ';
}
ol.lst-kix_3yx584spl2fy-4 {
  list-style-type: none;
}
ol.lst-kix_3yx584spl2fy-5 {
  list-style-type: none;
}
ol.lst-kix_3yx584spl2fy-2 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-8 > li:before {
  content: '' counter(lst-ctn-kix_7hh9dfc45078-8, lower-roman) '. ';
}
ol.lst-kix_3yx584spl2fy-3 {
  list-style-type: none;
}
.lst-kix_o1o7m8qb0f8q-5 > li:before {
  content: '' counter(lst-ctn-kix_o1o7m8qb0f8q-5, lower-roman) '. ';
}
ol.lst-kix_7hh9dfc45078-8 {
  list-style-type: none;
}
.lst-kix_382g8fe35h5b-4 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-4;
}
.lst-kix_382g8fe35h5b-7 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-7;
}
ol.lst-kix_7hh9dfc45078-6 {
  list-style-type: none;
}
.lst-kix_382g8fe35h5b-1 > li {
  counter-increment: lst-ctn-kix_382g8fe35h5b-1;
}
ol.lst-kix_b68w4a1o9sr1-4.start {
  counter-reset: lst-ctn-kix_b68w4a1o9sr1-4 0;
}
ol.lst-kix_7hh9dfc45078-7 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-2 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-2;
}
ol.lst-kix_3yx584spl2fy-4.start {
  counter-reset: lst-ctn-kix_3yx584spl2fy-4 0;
}
ol.lst-kix_7hh9dfc45078-4.start {
  counter-reset: lst-ctn-kix_7hh9dfc45078-4 0;
}
ol.lst-kix_382g8fe35h5b-0.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-0 0;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_o1o7m8qb0f8q-2 > li {
  counter-increment: lst-ctn-kix_o1o7m8qb0f8q-2;
}
ol.lst-kix_382g8fe35h5b-7.start {
  counter-reset: lst-ctn-kix_382g8fe35h5b-7 0;
}
ol.lst-kix_7hh9dfc45078-0 {
  list-style-type: none;
}
ol.lst-kix_7hh9dfc45078-1 {
  list-style-type: none;
}
ol.lst-kix_o1o7m8qb0f8q-1.start {
  counter-reset: lst-ctn-kix_o1o7m8qb0f8q-1 0;
}
ol.lst-kix_7hh9dfc45078-4 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-5 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-5;
}
ol.lst-kix_7hh9dfc45078-5 {
  list-style-type: none;
}
.lst-kix_7hh9dfc45078-8 > li {
  counter-increment: lst-ctn-kix_7hh9dfc45078-8;
}
ol.lst-kix_7hh9dfc45078-2 {
  list-style-type: none;
}
ol.lst-kix_7hh9dfc45078-3 {
  list-style-type: none;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c23 {
  margin-left: 108pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 21pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c13 {
  margin-left: 72pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 21pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c17 {
  margin-left: 108pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 23pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c5 {
  margin-left: 72pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 17pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c16 {
  margin-left: 108pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 17pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c7 {
  margin-left: 72pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 23pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c6 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 21pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c12 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 23pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c20 {
  margin-left: 79pt;
  padding-top: 0pt;
  text-indent: -21pt;
  padding-bottom: 9pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c1 {
  margin-left: 27pt;
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c22 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7.5pt;
  font-family: 'Times New Roman';
  font-style: normal;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Arial';
  font-style: normal;
}
.c15 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c18 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 5pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c11 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Arial';
  font-style: normal;
}
.c19 {
  background-color: #ffffff;
  padding: 20pt 20pt 72pt 20pt;
}
.c25 {
  margin-left: 54pt;
  text-indent: -27pt;
}
.c9 {
  padding: 0;
  margin: 0;
}
.c21 {
  vertical-align: super;
  font-size: 5.5pt;
}
.c8 {
  font-weight: 700;
}
.c14 {
  background-color: #ffff0b;
}
.c3 {
  font-size: 11pt;
}
.c10 {
  height: 11pt;
}
.c0 {
  font-size: 8.5pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
