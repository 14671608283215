<template>
  <div
    v-if="shouldShowModal"
    class="w-screen h-screen bg-black fixed bg-opacity-50 top-0 left-0 flex items-center justify-center z-100"
  >
    <div class="bg-white rounded-lg w-5/12 p-4">
      <h3 class="text-tmrw-blue-dark text-2xl font-bold mb-4">
        Discard the
        {{ CryoBeacon }}
      </h3>
      <div class="text-tmrw-blue-dark">
        <p class="my-2">{{ discardBeaconMessage }}</p>
        <p class="my-2">Do not re-use the {{ CryoBeacon }}.</p>
        <p class="my-2">This ticket will be marked as complete.</p>
      </div>
      <div class="flex items-center justify-between mt-4">
        <button-dynamic
          btn-text="Cancel"
          btn-type="button"
          btn-style="secondary"
          @click="handleCancel"
        />
        <button-dynamic
          btn-text="Confirm"
          btn-type="button"
          btn-style="primary"
          @click="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { CRYOBEACON } from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'

const CryoBeacon = ref(CRYOBEACON)
const emit = defineEmits(['confirm-clicked', 'cancel-clicked'])

defineProps({
  shouldShowModal: {
    type: Boolean,
    default: false
  },
  discardBeaconMessage: {
    type: String,
    required: true
  }
})

const handleConfirm = () => {
  emit('confirm-clicked')
}
const handleCancel = () => {
  emit('cancel-clicked')
}
</script>
