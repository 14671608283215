import { useStore } from 'vuex'

import { StoreModuleNames } from '@/store'
import { StoreActions } from '@/store/store-types'

const useActions = <T = any>(namespace:StoreModuleNames | null = null) => {
  const store: any = useStore()
  const mapName = (action) => {
    if (!namespace) {
      return action
    } else {
      return action.replace(`${namespace}/`, '')
    }
  }



  return Object.fromEntries(
    Object.keys(store._actions)
      .filter((action) => {
        if (namespace) {
          return action.includes(`${namespace}/`)
        }
        return true
      })
      .map((action) => [
        mapName(action),
        (value) => store.dispatch(action, value)
      ])
  ) as StoreActions<T>
}

export default useActions
