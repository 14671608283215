<template>
  <div data-test="scroll-table-row__source-site" class="leading-1 flex">
    <img
      v-if="sourceRobotData().locationIcon"
      data-test="relocation-source-icon"
      :src="sourceRobotData().locationIcon"
      class="transfer-icon w-10 h-auto mr-1"
      alt="Location Icon" /> {{ sourceRobotData().robotNumber }}
  </div>
</template>

<script>
import {
  TEMPORARY,
  CRYOROBOT,
  NON_TMRW_LOCATION,
  LAYOUT_WAVE
} from '@/constants'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation/index'

export default {
  name: 'scroll-table-source-site-field',
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    sourceRobotData() {
      const currentCryoBeaconLocation = getCurrentCryoBeaconLocation(this.value)
      const locationIcon = currentCryoBeaconLocation.imageSrc
      const robotNumber = currentCryoBeaconLocation.robotNumber
      const robotName = currentCryoBeaconLocation.robotLayoutTypeId === LAYOUT_WAVE
        ? NON_TMRW_LOCATION
        // @ts-ignore
        : robotNumber === TEMPORARY ? TEMPORARY : `${CRYOROBOT} ${robotNumber}`
      return {
        robotNumber: robotName,
        locationIcon
      }
    }
  }
}
</script>
