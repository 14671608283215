<template>
  <div data-test="scroll-table-row__destination-site" class="leading-1 flex">
    {{ destinationSiteName() }}
  </div>
</template>

<script>
import { DESTINATION } from '@/constants'

export default {
  name: 'scroll-table-robot-locations',
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    destinationSiteName() {
      const destination = this.value.find((location) => location.type === DESTINATION)
      return destination?.siteName || '--'
    }
  }
}
</script>
